import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { validateAccessDialog } from './not-empty.validator';
import { TravelPolicyService } from '../../../../services/travel-policy/travel-policy.service';
import { UsersService } from '../../../../services/profile/users.service';

@Component({
  selector: 'app-access-dialog',
  templateUrl: './access-dialog.component.html',
  styleUrls: [ './access-dialog.component.scss' ]
})
export class AccessDialogComponent implements OnInit{
  loggedUser: any;
  isDisabled: boolean = false;
  isValid: boolean;
  query: any;
  rolesList: any[] = [];
  userList: any[] = [];
  orderForm = new FormGroup({
    radioButton: new FormControl('default', Validators.required),
    authorization_roles: new FormControl({value : [], disabled: this.data.blockSelect}),
    authorization_users: new FormControl({value : [], disabled: this.data.blockSelect})
  }, { validators: validateAccessDialog });


  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private travelPolicyService: TravelPolicyService,
              private usersService: UsersService
  ) {}

  get radio(): any {
    return this.orderForm.get('radioButton');
  }

  get authorization_roles(): any {
    return this.orderForm.get('authorization_roles');
  }

  get authorization_users(): any {
    return this.orderForm.get('authorization_users');
  }

  ngOnInit(): void {
    this.loggedUser = JSON.parse(localStorage.getItem('fl_profile'));
    this.query = {
      options: {
        viewAll: false,
      },
      sort: 'createdAt',
      limit: 999,
      skip: 0,
      order: 'desc',
      text: '',
    };
    this.usersService.getUsersListCp(this.query, this.loggedUser.company._id).subscribe(response => {
      //sorted usersList by surname
      this.userList = response.list.filter((user:any)=> !user.group?._id).sort((a, b) => a.surname > b.surname ? 1 : -1);
    });
    this.travelPolicyService.getGroupsList().subscribe(response => {
      this.rolesList = response.data;
    });
    this.orderForm.patchValue({
      radioButton: this.data.policy.authorization,
      authorization_roles: this.data.policy.authorization_roles,
      authorization_users: this.data.policy.authorization_users
    });
    this.isDisabled = this.data.blockSelect;
    if (!this.isDisabled) {
      this.orderForm.get('authorization_roles').enable();
      this.orderForm.get('authorization_users').enable();
    }
    this.onSelectedRadio();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const res = {
      radioButton: this.orderForm.get('radioButton').value,
      authorization_roles: this.orderForm.get('authorization_roles').value,
      authorization_users: this.orderForm.get('authorization_users').value,
    };
    this.isValid = this.orderForm.invalid;
    this.dialogRef.close(res);
  }

  onSelectedRadio(): void {
    this.isDisabled = this.radio.value == 'default';
    if (this.radio.value === 'default') {
      this.isValid = false;
      this.orderForm.patchValue({authorization_roles: [], authorization_users: []});
    } else {
      this.isValid = this.orderForm.invalid;
    }
  }
}
