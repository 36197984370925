import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FlightService} from '../../../../../../../services/flight/flight.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {DossierService} from '../../../../../../../services/dossier/dossier.service';
import {TranslateService} from '@ngx-translate/core';
import * as $ from 'jquery';
import {IatacompleteService} from '../../../../../../../services/iatacomplete/iatacomplete.service';
import {PriceService} from '../../../../../../../services/price/price.service';
import {animate, group, style, transition, trigger} from '@angular/animations';
import {Tariffes} from '../../../../../../../classes/flights/tariffes';
import {BottomsheetComponent} from '../../bottomsheet/bottomsheet.component';
import {FarerulesComponent} from '../../../farerules/farerules.component';
import { TemplateDialogComponent } from '../../searchresults/template-dialog/template-dialog.component';

@Component({
  selector: 'app-recodialog',
  templateUrl: './recodialog.component.html',
  styleUrls: ['./recodialog.component.scss'],
  animations: [
    trigger('listItemAnimation', [
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '!' }))]),
        // although group is useless here (since we have only one animation)
        // i like to leave it anyway just in case i want to add another
        // parallel animation in the future
      ]),
      transition(':leave', [
        style({ height: '!', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '0px' }))]),
      ]),
    ]),
  ]
})
export class RecodialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  selections: any;
  loadingTar: boolean;
  showFares: boolean;
  isB2B: boolean;
  private readonly notifier: NotifierService;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RecodialogComponent>,
    public dialog: MatDialog,
    private flightService: FlightService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    notifierService: NotifierService,
    private dossierService: DossierService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    public iataComplete: IatacompleteService,
    public priceservice: PriceService,

  ) {
    console.log('this.data => ', this.data);
    this.isB2B = JSON.parse(localStorage.getItem('isB2B'));
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.showFares = false;
    for (let i = 0; i < this.data.recommendation.itineraries.length; i++) {
      this.changeSelection(this.data.recommendation.ref, i, this.data.recommendation.itineraries[i].sections[0].ref);
    }
    /*
    if (this.data.recommendation.negotiated) {
      this.askFares(this.data.recommendation.ref);
    }
    */
    this.askFares(this.data.recommendation.ref);
    this.data.passport = this.iataComplete.returnCarrierOPTPassport(this.data.recommendation.marketingCarrier, this.data.recommendation.itinew)
    // this.searchDates(this.data.recommendation.ref);
  }

  opeSect(it, value) {
    $('#collapse' + it + value).collapse('toggle');
    $('#itinerary' + it + value).collapse('toggle');
  }

  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  returnZeroTime(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  selectionFunction(ref, iti, refIti) {
    try {
      return (this.data.selections[ref][iti] === refIti);
    } catch (err) {
      console.log('err => ', err);
    }

  }

  changeSelection(ref, iti, refIti) {
    if (this.data.selections[ref][iti] === refIti) { return; } else { this.data.selections[ref][iti] = refIti; }
  }

  calculateFlightTime(departure, arrival) {
    departure = new Date(departure).getTime();
    arrival = new Date(arrival).getTime();
    const flightTime = arrival - departure;
    const duration = this.returnTime(flightTime);
    return duration;
  }

  calculateMargin(a, b, c) {
    return Math.round(((a - b - c) * 100) / 100);
  }

  otherday(value, other) {
    return value !== other;
  }

  askFares(ref) {
    this.loadingTar = true;
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(this.data.recommendation));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.data.searchResults.itineraries[i].sections[this.data.selections[ref][i] - 1];
    }
    this.data.recommendation.rates = [];
    console.log('recommendation =>', recommendation);
    recommendation.options = this.data.request.options;
    this.flightService.askFares(recommendation).subscribe((res: Tariffes[]) => {
      console.log('res => ', res);
      this.data.recommendation.rates = res;
      for (let rate = 0; rate < this.data.recommendation.rates.length; rate++) {
        if (rate === 0) {
          this.data.recommendation.rates[rate].selected = true;
          this.data.recommendation.tariff = this.data.recommendation.rates[rate];
        } else {
          this.data.recommendation.rates[rate].selected = false;
        }
      }
      this.loadingTar = false;
      this.data.recommendation.fare = this.data.recommendation.rates[0].fare;
      console.log('this.tariffes => ', res);
      if (!this.data.recommendation.negotiated) {
        this.showFares = true;
      }
    }, error => {
      this.notifier.notify('error', error);
      this.loadingTar = false;
      console.log('error => ', error);
    });
  }

  toggleShowFare() {
    this.showFares = ! this.showFares;
  }

  checkPolicy(tariff:any) :boolean {
    let isBlock = false;
    if((this.data.isSbt||this.data.isB2B) && tariff.arrayOfAlerts?.length > 0){
      tariff.arrayOfAlerts.forEach((a:any) => a.block ? isBlock = a.block : null );
    }
    return isBlock;
  }

  openDialog(ref) {
    console.log('this.travelers =>', this.data.passengers);
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(this.data.recommendation));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.data.searchResults.itineraries[i].sections[this.data.selections[ref][i] - 1];
    }
    recommendation.options = this.data.request.options;
    recommendation.company = this.data.company;
    console.log('this.selections =>', this.selections);
    const dialogRef = this.dialog.open(TemplateDialogComponent, {
      data: {
        request: this.data.request,
        recommendation,
        passengers: this.data.passengers,
        searchResults: this.data.searchResults,
        selections: this.data.selections,
        dialog: this.dialog,
        passPort: this.iataComplete.returnCarrierOPTPassport(recommendation.marketingCarrier, recommendation.itineraries),
        mainComponent: this.data.mainComponent,
        appComponent: this.data.appComponent,
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }

  openBottomSheet(fare) {
    this.bottomSheet.open(BottomsheetComponent, {
      data: {fare},
    });
  }

  /**
   * Ask fare rules (penalties) for selected fare
   */
  openDialogRules(tariff, ref) {
    console.log('this.travelers =>', this.data.passengers);
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(this.data.recommendation));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.data.searchResults.itineraries[i].sections[this.data.selections[ref][i] - 1];
    }
    recommendation.options = this.data.request.options;
    recommendation.company = this.data.company;
    recommendation.passengers = this.data.passengers;
    recommendation.tariff = tariff;
    console.log('this.selections =>', this.selections);
    const dialogRef = this.dialog.open(FarerulesComponent, {
      data: {
        tariff,
        recommendation,
        loggedUser: this.data.mainComponent.loggedUser,
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }

  selectFare(f) {
    // tslint:disable-next-line:prefer-for-of
    for (let rate = 0; rate < this.data.recommendation.rates.length; rate++) {
      this.data.recommendation.rates[rate].selected = false;
    }
    this.data.recommendation.rates[f].selected = true;
    this.data.recommendation.fare = this.data.recommendation.rates[f].fare;
    this.data.recommendation.tariff = this.data.recommendation.rates[f];
    if (this.data.recommendation.rates[f].negotiated) {
      this.data.recommendation.negotiated = true;
    } else {
      this.data.recommendation.negotiated = false;
    }
    if (this.data.recommendation.rates[f].passengers.ADT) {
      this.data.recommendation.paxFare.adt = this.data.recommendation.rates[f].passengers.ADT.fare;
    }
    if (this.data.recommendation.rates[f].passengers.CHD) {
      this.data.recommendation.paxFare.chd = this.data.recommendation.rates[f].passengers.CHD.fare;
    }
    if (this.data.recommendation.rates[f].passengers.INF) {
      this.data.recommendation.paxFare.inf = this.data.recommendation.rates[f].passengers.INF.fare;
    }
  }
  /*
  searchDates(ref) {
    const recommendation = JSON.parse(JSON.stringify(this.data.recommendation));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.data.searchResults.itineraries[i].sections[this.data.selections[ref][i] - 1];
    }
    if (this.data.recommendation.itineraries.length > 1) {
      this.data.request.sections[0].options.date = new Date(recommendation.itineraries[0].flights[0].departure.date);
      this.data.request.sections[1].options.date = new Date(recommendation.itineraries[1].flights[0].departure.date);
    } else {
      this.data.request.sections[0].options.date = new Date(recommendation.itineraries[0].flights[0].departure.date);
    }
    const newSearch = JSON.parse(JSON.stringify(this.data.request));
    newSearch.options.calendar = false;
    console.log('newSearch =>', newSearch);

    this.dialogRef.close({event: newSearch});
    // this.formComponent.startSearch(undefined);
  } */

  searchDates(ref) {
    const recommendation = JSON.parse(JSON.stringify(this.data.recommendation));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.data.searchResults.itineraries[i].sections[this.data.selections[ref][i] - 1];
    }
    if (this.data.recommendation.itineraries.length > 1) {
      this.data.request.sections[0].options.date = new Date(recommendation.itineraries[0].flights[0].departure.date);
      this.data.request.sections[1].options.date = new Date(recommendation.itineraries[1].flights[0].departure.date);
    } else {
      this.data.request.sections[0].options.date = new Date(recommendation.itineraries[0].flights[0].departure.date);
    }
    const newSearch = JSON.stringify(this.data.request);
    console.log('newSearch =>', newSearch);
    // localStorage.removeItem('searchForm');
    const url = this.router.serializeUrl(this.router.createUrlTree(['/flight/search'], { queryParams: { searchCalendar: newSearch } }));
    window.open(url, '_blank');
    // this.formComponent.startSearch(undefined);
  }
}
