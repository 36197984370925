<div class="padding20">
  <custom-title></custom-title>
  <div class="text-right mb-3">
    <a href="https://www.trenitalia.com/it/informazioni/condizioni_generaliditrasporto.html" target="_blank">{{'TRAIN_MODULE.TRENIT_CONDITION'|translate}}</a>
  </div>
  <ng-container *ngIf="trainTravel else goBackTmpl" class="d-flex justify-content-between">
    <div class="row pt-4">
      <div class="col-12">
        <div class="">
          <div class="custoTit">
            {{'SERVICES' | translate}}
          </div>
          <div class="row">
            <div class="col-12 col-md-5 mb-3">
              <train-selected-booking-summary [arrivalStation]="currentSearch?.arrival_station.name" [currentTravel]="trainTravel"
                                              [departureStation]="currentSearch?.departure_station.name"></train-selected-booking-summary>
            </div>
            <div class="col-12 col-md-7">
              <app-passenger-cards [editable]="false" [passengers]="trainTravel.travellers" [travel]="trainTravel" style="width: 100%;"></app-passenger-cards>
              <!--
              <mat-card class="h-100 maxHeigthCard">
                <mat-card-header class="justify-content-center">
                  <mat-card-title class="text-center"> {{'PASSENGERS' | translate}}</mat-card-title>
                </mat-card-header>
                <div class="row">
                  <app-passenger-cards [editable]="false" [passengers]="trainTravel.travellers" [travel]="trainTravel" style="width: 100%;">
                  </app-passenger-cards>
                </div>
              </mat-card>
              -->
            </div>
            <div class="col-12 mb-4 pb-4">
              <mat-card appearance="outlined" class="cardMar">
                <mat-card-header class="justify-content-center">
                  <mat-card-title class="text-center"> {{'Accesori' | translate}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <ng-container *ngIf="trainTravel.evaluatedTravel">
                    <div class="row" style="margin-top: 10px;">
                      <div class="col-12 col-md-6 mb-4">
                        <div class="row align-items-center">
                          <div class="col-sm-6">
                            <div class="" *ngFor="let pax of trainTravel.travellers">
                              {{pax.surname}} {{pax.name}}
                              <span *ngIf="pax.seat">
                                <strong>{{paxSeatNumber(pax.seat)}}</strong> vagone  <strong>{{paxWagonNumber(pax.seat)}}</strong>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="buttonrowBis">
                              <button class="mb-2" mat-stroked-button (click)="toggleSeatSelectionModal(true)"
                                      [disabled]="!allTravellersSet() || !trainTravel.evaluatedTravel.allowedServices.seatSelection" color="primary">
                                {{'TRAIN_MODULE.SEAT_SELECTION.TITLE'|translate}}
                                <span class="material-icons"> event_seat </span>
                              </button>
                            </div>
                            <div *ngIf="seatCharge > 0" class="col-auto my-2" matTooltip="{{ 'TRAIN_MODULE.TRENIT.SEAT_PRICE' | translate }}">
                              <span class="material-icons">error_outline</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 mb-3">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="">
                              <mat-form-field class="discount-code w-100">
                                <mat-label>{{'TRAIN_MODULE.TRENIT.DISCOUNT_CODE'|translate}}</mat-label>
                                <input [(ngModel)]="discountCode" [disabled]="discountCodeApplied" matInput type="text">
                                <span [ngSwitch]="discountCodeApplied" matSuffix>
                                  <span *ngSwitchCase="true"> <mat-icon>done</mat-icon></span>
                                  <span *ngSwitchCase="false">
                                    <mat-icon matTooltip="{{ applyDiscountCodeErrorMessage | translate}}">error</mat-icon>
                                  </span>
                                  <span *ngSwitchDefault></span>
                                </span>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="buttonrowBis">
                              <button mat-stroked-button (click)="applyDiscountCode(discountCode)" [disabled]="discountCodeApplied" color="primary"
                                      type="button">
                                {{'TRAIN_MODULE.TRENIT.DISCOUNT_CODE.APPLY' | translate}}
                                <ng-container *ngIf=" !(applyDiscountCode$ | async) else applyDiscountCodeLoadingTpl">
                                  <mat-icon class="h-auto">arrow_right_alt</mat-icon>
                                </ng-container>
                                <ng-template #applyDiscountCodeLoadingTpl>
                                  <mat-icon class="h-auto spin">autorenew</mat-icon>
                                </ng-template>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <mat-divider class="mt-2 mb-2"></mat-divider>
                    <div class="col-12 mt-2 pt-4" style="margin-bottom: 30px; margin-top: 30px;">
                      <div class="d-flex justify-content-center">
                        <div class="text-center font-weight-bold mx-2">{{ 'TRAIN_MODULE.TRENIT.OFFER' | translate }}</div>
                        <div class="text-center font-weight-bold">{{ travelPrice | number:'.2-2'}} €</div>
                      </div>
                      <div *ngIf="seatCharge > 0" class="d-flex justify-content-center">
                        <div class="text-center font-weight-bold mx-2">{{ 'TRAIN_MODULE.TRENIT.SEAT_PRICE' | translate }}</div>
                        <div class="text-center font-weight-bold">{{ seatCharge | number:'.2-2'}} €</div>
                      </div>
                      <div *ngIf="discount !== 0" class="d-flex justify-content-center">
                        <div class="text-center font-weight-bold mx-2">{{'TRAIN_MODULE.TRENIT.DISCOUNT_CODE' | translate}}</div>
                        <div class="text-center font-weight-bold">{{ discount | number:'.2-2'}} €</div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <div class="text-center font-weight-bold mx-2">{{'TRAIN_MODULE.TOTAL_PRICE' | translate}}:</div>
                        <div class="text-center font-weight-bold">{{ seatCharge + discount + travelPrice | number:'.2-2'}} €</div>
                      </div>
                    </div>
                    <div class="row wordWrap d-flex justify-content-center">
                      <mat-checkbox (change)="acceptTermsAndConditions($event.checked)" [checked]="termsAccepted">
                        {{'TRAIN_MODULE.TRENIT.TERMS_AND_CONDITIONS.1' | translate}}
                        <a href="https://www.trenitalia.com/it/informazioni/condizioni_generaliditrasporto.html" target="_blank">
                          {{'TRAIN_MODULE.TRENIT.TERMS_AND_CONDITIONS.2' | translate}}
                        </a>
                        {{'TRAIN_MODULE.TRENIT.TERMS_AND_CONDITIONS.3' | translate}}
                        <a href="https://www.trenitalia.com/it/html/trenitalia/Informativa_sulla_privacy.html" target="_blank">
                          {{'TRAIN_MODULE.TRENIT.TERMS_AND_CONDITIONS.4' | translate}}
                        </a>
                      </mat-checkbox>
                    </div>
                  </ng-container>

                </mat-card-content>
                <mat-card-actions class="d-flex justify-content-between">
                  <div style="margin:auto;" class="buttonrow">
                    <button (click)="goBack($event)" mat-raised-button type="button">
                      <mat-icon>arrow_left_alt</mat-icon>
                      {{'TRAIN_MODULE.BACK' | translate}}
                    </button>
                    <button (click)="makeReservation()" [disabled]="!stepCompleted() || (createReservationLoading$ | async)" color="primary" mat-raised-button>
                      {{'TRAIN_MODULE.FORM.BOOK' | translate}}
                      <ng-container *ngIf=" !(createReservationLoading$ | async) else createReservationLoadingTpl">
                        <mat-icon>arrow_right_alt</mat-icon>
                      </ng-container>
                      <ng-template #createReservationLoadingTpl>
                        <mat-icon class="spin">autorenew</mat-icon>
                      </ng-template>
                    </button>
                  </div>

                </mat-card-actions>
              </mat-card>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #goBackTmpl>
    <a class="mat-elevation-z2" mat-button routerLink="/trains/search" type="button">
      {{'TRAIN_MODULE.NEW_SEARCH' | translate}}
      <mat-icon>search</mat-icon>
    </a>
  </ng-template>

  <div aria-hidden="true" aria-labelledby="seatSelectionModal" class="modal fade" data-backdrop="static" id="seatSelectionModal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" style="top:70px">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cancelPnrModalTitle">{{'TRAIN_MODULE.SEAT_SELECTION.TITLE'|translate}}</h5>
          <button (click)="toggleSeatSelectionModal()" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <ng-container *ngIf=" !(seatSelection$ | async) else seatMapLoading">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab *ngFor="let layout of seatSelection"
                       label="{{getStationFromCatalog(layout.query.solutionSegment.start.id)}} -> {{getStationFromCatalog(layout.query.solutionSegment.end.id)}}">
                <div *ngIf="!layout.transportUnits else seatLayout" class="alert alert-warning">
                  <mat-icon>warning</mat-icon>
                  <span>{{ 'TRAIN_MODULE.TRENIT.ERRORS.evaluateTravelSolutionInvalidSelection' | translate }}</span>
                </div>
                <ng-template #seatLayout>
                  <train-seat-map (selectedSeatEvent)="selectedSeats[layout.query.solutionSegment.idXml] = $event" [passengers]="trainTravel.travellers"
                                  [prevSelectedSeats]="selectedSeats[layout.query.solutionSegment.idXml] || {}" [transportUnits]="layout.transportUnits">
                  </train-seat-map>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </ng-container>
          <ng-template #seatMapLoading>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </ng-template>
          <div class="text-center buttonrow">
            <button (click)="toggleSeatSelectionModal()" mat-raised-button type="button">{{'TRAIN_MODULE.ABORT'|translate}}</button>
            <button (click)="confirmSelectSeat()" color="primary" [disabled]="!selectedSeats" mat-raised-button type="button">
              {{'TRAIN_MODULE.SEAT_SELECTION.CONFIRM_SELECTION'|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
