<div id="searchCard" class="searchForm mb-5 collapse show">
  <mat-card appearance="outlined" class="search-card carsearch">
    <mat-card-header>
      <div mat-card-avatar class="search-header-image"></div>
      <mat-card-title>{{'CAR_RENT_SEARCH'|translate}}</mat-card-title>
      <mat-card-subtitle>{{'FILL_FORM'|translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <div class="mb-2">
        <mat-slide-toggle [(ngModel)]="sameLocation" (ngModelChange)="checkChange($event)" class="example-margin" [checked]="sameLocation">
          {{'PICK_UP_LOCATION'|translate}} === {{'RETURN_LOCATION'|translate}}
        </mat-slide-toggle>
      </div>

      <div class="row searchContainer">
        <div class="col-12 d-flex justify-content-end">
          <div class="formTopButtons">
            <div class="formTopAction"  (click)="toggleOptions()">
            <span class="material-icons">
              tune
            </span>
            </div>
            <div class="formTopAction reset" (click)="resetSearchForm()" matTooltip="{{'CLEAR_FORM'|translate}}">
            <span class="material-icons">
              refresh
            </span>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div id="collapseOptions" class="row newStandardFields collapse">
            <div class="col-md-2 col-6" >
              <mat-form-field class="full-width">
                <mat-label>Codici Corporate</mat-label>
                <mat-select multiple [(ngModel)]="selectedLoyaltyCodes" name="cabin">
                  <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option *ngFor="let loyaltyCode of loyaltyCodes" [value]="loyaltyCode" >{{ loyaltyCode.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>


      <div class="row lightbg">

        <div [ngClass]="{'col-md-6 col-sm-12 col-12':sameLocation,'col-md-3 col-sm-6 col-6':!sameLocation}">
          <mat-form-field class="full-width">
            <mat-label>
              {{'PICK_UP_LOCATION'|translate}} <span *ngIf="sameLocation">{{'E'|translate}} {{'RETURN_LOCATION'|translate}}</span>
            </mat-label>
            <input matInput class="googleauto begin" (ngModelChange)="addList('begin')" name="carBeginzone" required id="begin"
                   placeholder="Ex. ROME" [(ngModel)]="request.begin.display">
            <button *ngIf="request.begin.display" matSuffix mat-icon-button aria-label="Clear" (click)="clearmodel('begin')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div *ngIf="!sameLocation" class="col-md-3 col-sm-6 col-6">
          <mat-form-field class="full-width">
            <mat-label>{{'RETURN_LOCATION'|translate}}</mat-label>
            <input matInput class="googleauto begin" (ngModelChange)="addList('end')" name="carBeginzone" required id="end"
                   placeholder="Ex. ROME" [(ngModel)]="request.end.display">
            <button *ngIf="request.end.display" matSuffix mat-icon-button aria-label="Clear" (click)="clearmodel('end')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

          <div class="col-md-3 col-sm-6 col-6">
            <mat-form-field class="full-width">
              <!--
              <input matInput readonly [min]="today" (dateChange)="setEndDate()" [matDatepicker]="begin" placeholder="{{'PICK_UP_DATE'|translate}}" (click)="begin.open()" [(ngModel)]="request.begin.date">
              <mat-datepicker-toggle matSuffix [for]="begin"></mat-datepicker-toggle>
              <mat-datepicker touchUi #begin></mat-datepicker> -->

              <input matInput readonly (click)="picker.open()" [ngxMatDatetimePicker]="picker" placeholder="{{'PICK_UP_DATE'|translate}}"
                     [(ngModel)]="request.begin.date" [min]="today" (dateChange)="setEndDate()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker> <!-- touchUi -->
                <ngx-mat-datepicker-actions>
                  <button class="applyDateBtn" ngxMatDatepickerApply mat-stroked-button (click)="setEndDate()">
                    <mat-icon> done </mat-icon>
                  </button>
                </ngx-mat-datepicker-actions>
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <mat-form-field class="full-width">
            <input matInput readonly (click)="end.open()" [ngxMatDatetimePicker]="end" placeholder="{{'RETURN_CAR_DATE'|translate}}"
                   [(ngModel)]="request.end.date" [min]="request.begin.date">
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #end> <!-- touchUi -->
              <ngx-mat-datepicker-actions>
                <button class="applyDateBtn" ngxMatDatepickerApply mat-stroked-button>
                  <mat-icon> done </mat-icon>
                </button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
      </div>
      <!--<div class="row lightbg" *ngIf="formOptions">
        <div class="col-md-2 col-6" >
          <mat-form-field class="full-width">
            <mat-label>Codici Corporate</mat-label>
            <mat-select multiple [(ngModel)]="selectedLoyaltyCodes" name="cabin">
              <mat-option value="">&#45;&#45; {{'NO_PREFERENCE'|translate}} &#45;&#45;</mat-option>
              <mat-option *ngFor="let loyaltyCode of loyaltyCodes" [value]="loyaltyCode" >{{ loyaltyCode.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>-->
      <div *ngIf="!loading"  class="buttonrow text-right" >
        <!--<button mat-stroked-button (click)="toggleOptions()">{{'SHOW_OPTIONS'|translate}}</button>-->
        <!--<button class="newButtonPrimary" [disabled]="invalid()" (click)="startSearch()">{{'SEARCH'|translate}}</button> -->
        <button class="newButton" [disabled]="invalid()" (click)="startSearch()">{{'SEARCH'|translate}}</button>
        <!--<button mat-stroked-button (click)="resetSearchForm()">{{'RESET'|translate}}</button>-->
      </div>
      <div *ngIf="loading" class="text-right">
        <div class="mb-2">{{'LOADING'|translate}}</div>
        <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="loading" class="nowloading padding20 text-center">
  {{'LOADING'|translate}}
  <div class="currentForm">
    <p>
      {{request.begin.display}} <mat-icon>arrow_right_alt</mat-icon> {{request.end.display}}
    </p>
  </div>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>
