import {Component, OnInit,} from '@angular/core';
import {GroupsService} from '../../groups.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {throwError} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {AddGroupComponent} from "../add-group/add-group.component";
import {error} from "protractor";

@Component({
  selector: 'app-all-roles',
  templateUrl: './all-groups.component.html',
  styleUrls: ['./all-groups.component.scss'],
})
export class AllGroupsComponent implements OnInit{

  groups : any;
  groupNames: any[] = [];

  constructor(
    private groupsService : GroupsService,
    private snackBar : MatSnackBar,
    private dialog: MatDialog,
  ){ }

  ngOnInit() : void{
    this.getData();
  }

  /*
   getData(): Observable<any> {
   return this.groupsService.groups$.pipe(tap(res => console.log(res)), catchError((err: any) => {
   this.snackBar.open(err.message, 'Close');
   return throwError(err);
   }));
   } */

  getData(){
    this.groupsService.getGroups().subscribe(response => {
      this.groups = response;
      this.getAllGroupsNames()
    }, error => {
      console.error('Error => ', error);
      return throwError(error);
    });
  }

  handleDelete(id : string) : void{
    this.groupsService.deleteGroup(id).subscribe(result => {
        this.snackBar.open('Group deleted successfully', 'Close');
        this.getData();
      },
      error => {
        return throwError(error);
      },
    );
  }

  openGroupDialog(event?:any){
    let dialogRef = this.dialog.open(AddGroupComponent, {
      width: '300px',
      maxWidth: '300px',
      data : {
        group : event !== '' ? event : {},
        groupNames: this.groupNames,
        updating: event !== ''
      },
    })

    dialogRef.afterClosed().subscribe((res:any)=> {
      /*console.log('result',res)*/
      this.getData()
    }, error=> {
      console.error('Error => ', error);
    })
  }

  getAllGroupsNames(){
    this.groupNames = []
    this.groups.data.forEach((g:any) => {
      this.groupNames.push(g.name)
    })
  }

}
