<div class="modal fade" id="PrintModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="PrintModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'PRINT'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-2" *ngIf="!reservation.lowCost && (reservation.status === 'issued' || reservation.status === 'flown')">
          <mat-checkbox class="example-margin" [(ngModel)]="showTicket">{{'SHOW_TICKET'|translate}}</mat-checkbox>
        </div>
        <div class="mb-2">
          <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="showNotes">{{'SHOW_NOTES'|translate}}</mat-checkbox>
          </section>
        </div>
        <div >
          <div *ngIf="showNotes">
            <mat-form-field class="full-width">
              <mat-label>{{'NOTES'|translate}}</mat-label>
              <textarea matInput placeholder="Ex. Check-in at 10:00 A.M." [(ngModel)]="reservationNotes">

            </textarea>
            </mat-form-field>
          </div>
          <div class="" *ngIf="mainComponent.loggedUser.company.type === 'A' && (!mainComponent.appcomponent.isSbt && !mainComponent.appcomponent.isB2B)">
            <hr/>
            <section class="example-section">
              <mat-checkbox class="example-margin" [(ngModel)]="showPrice">{{'SHOW_PRICE'|translate}}</mat-checkbox>
            </section>
            <hr/>
            <mat-checkbox class="example-margin" [(ngModel)]="changePrice">{{'CHANGE_PRICE'|translate}}</mat-checkbox>
          </div>

          <div *ngIf="changePrice && mainComponent.loggedUser.company.type === 'A' && (!mainComponent.appcomponent.isSbt && !mainComponent.appcomponent.isB2B)">
            <mat-form-field class="full-width">
              <mat-label>{{'NEW_PRICE'|translate}}</mat-label>
              <input matInput type="number" [(ngModel)]="priceChange"/>
            </mat-form-field>
            <div>
              <div class="" *ngIf="reservation.negotiated">
                <span *ngIf="isLowPrice(priceservice.returnPrice(reservation.fare.publicAmount - (reservation.fare.fees||0)))">
                  <span class="material-icons">warning_amber</span> {{'PRICE_IS_LOWER'|translate}}
                </span>
              </div>
              <div class="" *ngIf="!reservation.negotiated && !reservation.fareNdc">
                <span *ngIf="isLowPrice(priceservice.returnPrice(reservation.fare.amount))">
                  <span class="material-icons">warning_amber</span> {{'PRICE_IS_LOWER'|translate}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6 text-right">
            <button mat-button (click)="print()">
              {{'PRINT'|translate}} <mat-icon>print</mat-icon>
            </button>
          </div>
        </div>

        <hr/>
        <div class="row">
          <div class="col-12">
            Preview
            <div class="padding20" style="overflow-x:auto;">
              <div *ngIf="!loading && reservation" id="toPrint">
                <div style="margin:0 10px;">
                  <div class="row">
                    <div class="col-4">
                      <img class="companyImage" [src]="reservation.company.image"/>
                    </div>

                    <div class="col-4"></div>

                    <div class="col-4">
                      <div class="mb-1">
                        <mat-icon class="mr-2">store</mat-icon>
                        {{reservation.company.name}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">call</mat-icon>
                        {{reservation.company.phoneNumber}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">forward_to_inbox</mat-icon>
                        {{reservation.company.email}}
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <h5 class="mb-3">{{'QUOTATION_DATE'|translate}}: {{dateNow()|date:'dd/MM/yyyy - HH:mm'}}
                    <div *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && !reservation.lowCost" class="d-inline-block" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                      <span class="" *ngFor="let locator of reservation.carriersLocators; let l = last">{{locator.carrier}}: {{locator.locator}} <span *ngIf="!l"> | </span></span>
                    </div>
                    <div *ngIf="reservation.carriersLocators && reservation.carriersLocators.length > 0 && reservation.lowCost && reservation.status != 'init'" class="" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                      <span>{{reservation.carriersLocators[0].locator}}</span>
                    </div>
                  </h5>
                  <div class="row">

                    <div class="col-3">
                      <div *ngIf="reservation.marketingCarrier" class="mb-1">{{'VECTOR'|translate}}</div>
                      <div *ngIf="reservation.marketingCarrier" class="reservationimage mb-1">
                        <img src="https://application.flyleo.it/assets/img/airline/icon/{{reservation.marketingCarrier}}.png"> {{iataComplete.returnCarrier(reservation.marketingCarrier)}}
                      </div>
                      <div class="mb-1" *ngIf="reservation.status==='issued'">{{'ISSUED_ON'|translate}}: {{reservation.issuedOn|date:'dd/MM/yyyy'}}</div>
                    </div>
                    <div class="col-3">
                      <div class="mb-1">{{'PASSENGERS'|translate}}</div>
                      <div *ngIf="reservation.passengers.length>0">
                        <div *ngFor="let passenger of reservation.passengers" class="listpnrsinglepasseng">
                          <span *ngIf="passenger.surname">{{passenger.surname}}</span> <span *ngIf="passenger.name"> {{passenger.name}}</span> ({{passenger.type}})
                          <div *ngIf="reservation.status==='issued' && !reservation.lowCost">
                            <span class="d-block">TKT n°: {{passenger.ticket}} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 farePart" *ngIf="!reservation.lowCos && reservation.tariff">
                      <div class="mb-1">{{'FARE'|translate}}</div>
                      <div class="mb-2">
                        <span *ngFor="let class of reservation.tariff.details;let l = last;">
                          <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
                        </span>
                      </div>
                      <div class="printFare">
                        <div class="infoBag">
                          <div *ngIf="reservation.tariff.passengers.ADT">
                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_ADULT'|translate}}:
                            <span *ngFor="let bag of reservation.tariff.passengers.ADT.baggage; let i = index;let l = last">
                            <span *ngIf="l && i != 0"> - </span>
                              {{bag.allowance}}
                              <span *ngIf="bag.allowance < 10">
                                  BAG
                              </span>
                              <span *ngIf="bag.allowance >= 10">
                                Kg
                              </span>
                            </span>
                          </div>
                          <div *ngIf="reservation.tariff.passengers.CHD && reservation.tariff.passengers.CHD.baggage.length>0">
                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_CHILD'|translate}}:
                            <span *ngFor="let bag of reservation.tariff.passengers.CHD.baggage; let i = index;let l = last">
                              <span *ngIf="l && i != 0"> - </span>
                              {{bag.allowance}}
                              <span *ngIf="bag.allowance < 10">
                                  BAG
                              </span>
                              <span *ngIf="bag.allowance >= 10">
                                Kg
                              </span>
                            </span>
                          </div>
                          <div *ngIf="reservation.tariff.passengers.INF && reservation.tariff.passengers.INF.baggage.length>0">
            <span class="material-icons">
              work
            </span>
                            {{'BAG_FOR_INFANT'|translate}}:
                            <span *ngFor="let bag of reservation.tariff.passengers.INF.baggage; let i = index;let l = last">
              <span *ngIf="l && i != 0"> - </span>
                              {{bag.allowance}}
                              <span *ngIf="bag.allowance < 10">
                                  BAG
                              </span>
                              <span *ngIf="bag.allowance >= 10">
                                Kg
                              </span>
            </span>
                          </div>
                        </div>
                        <div class="infoTar">
          <span *ngIf="!reservation.tariff.refundable">
            <span class="material-icons">
              cancel
            </span>
            {{'NO_REFOUND'|translate}}
          </span>
                          <span *ngIf="reservation.tariff.refundable">
            <span class="material-icons">
              check_circle
            </span> {{'YES_REFOUND'|translate}}
          </span>

                        </div>
                      </div>
                    </div>
                    <div class="col-3" *ngIf="!reservation.negotiated && (!changePrice || !priceChange) && !reservation.lowCost && showPrice">
                      <div class="mb-1">{{'PRICE_DETAILS_PAX'|translate}}</div>
                      <div class="row">
                        <div class="col-3">ADT</div>
                        <div class="col-9">
                          Total: {{reservation.paxFare.adt.amount|number:'.2-2'}}€<br/>
                          Tax (included): {{reservation.paxFare.adt.tax|number:'.2-2'}}€
                        </div>
                      </div>
                      <div class="row" *ngIf="reservation.paxFare.chd && reservation.paxFare.chd.amount">
                        <div class="col-3">CHD</div>
                        <div class="col-9">
                          Total: {{reservation.paxFare.chd.amount|number:'.2-2'}}€<br/>
                          Tax (included): {{reservation.paxFare.chd.tax|number:'.2-2'}}€
                        </div>
                      </div>
                      <div class="row" *ngIf="reservation.paxFare.inf && reservation.paxFare.inf.amount">
                        <div class="col-3">INF</div>
                        <div class="col-9">
                          Total: {{reservation.paxFare.inf.amount|number:'.2-2'}}€<br/>
                          Tax (included): {{reservation.paxFare.inf.tax|number:'.2-2'}}€
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row backPrint" [ngClass]="{'mb-2':!l}" *ngFor="let itinerary of reservation.itineraries;let l = last;let i = index;">

                  <div class="col-12">
                    <table class="table table-bordered">
                      <tr>
                        <td class="infoIti" colspan="4">
                          <b>{{iataComplete.returnAirport(itinerary.flights[0].departure.location)}}</b> <sup>{{itinerary.flights[0].departure.location}}</sup>
                          <span class="material-icons">
                arrow_right_alt
              </span>
                          <b>{{iataComplete.returnAirport(itinerary.flights[itinerary.flights.length-1].arrival.location)}}</b> <sup>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</sup>
                          <div class="maincabin">
                            <span *ngIf="reservation.cabins[i] === 'Y'">Economic</span>
                            <span *ngIf="reservation.cabins[i]=='M'">Economic standard</span>
                            <span *ngIf="reservation.cabins[i]=='W'">Economic premium</span>
                            <span *ngIf="reservation.cabins[i]=='C'">Business</span>
                            <span *ngIf="reservation.cabins[i]=='F'">First</span>
                          </div>
                          <div>
                <span class="material-icons" style="font-size: 16px;margin-right: 5px">
                  flight_takeoff
                </span>
                            <b>{{itinerary.flights[0].departure.date|date:'dd/MM/yyyy'}}
                              -
                              {{returnZeroTime(itinerary.flights[0].departure.hours)}}:{{returnZeroTime(itinerary.flights[0].departure.minutes)}}</b>
                            <span class="material-icons" style="font-size: 16px;margin-left: 10px">
                  schedule
                </span>
                            {{'FLIGHT_TIME'|translate}}: <b>{{returnMilli(itinerary.flyingTime)}}</b>
                          </div>
                        </td>
                      </tr>
                      <ng-container *ngFor="let flight of itinerary.flights;let f = index; let l = last">
                        <tr>
                          <td style="width:10%">
                            <img class="operatingImage" src="https://application.flyleo.it/assets/img/airline/icon/{{flight.operatingCarrier}}.png">
                          </td>
                          <td style="width:30%">
                            <div>
                              {{flight.departure.date|date:'dd/MM/yyyy'}} -
                              {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}}
                            </div>
                            {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                          </td>
                          <td style="width:30%">
                            <div>N° {{flight.operatingCarrier}} {{flight.number}}</div>
                            <div *ngIf="reservation.tariff">BookingClass: {{reservation.tariff.bookingClasses[f]}}</div>
                          </td>
                          <td style="width:30%">
                            <div>
                              {{flight.arrival.date|date:'dd/MM/yyyy'}} -
                              {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}}
                            </div>
                            {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="text-center" *ngIf="flight.detail.technicalStop && flight.detail.technicalStop.from > 0">
                            <span class="material-icons iconTech">
                              local_gas_station
                            </span>
                            <span class="vertical">
                              {{'TECHNICAL_STOP_IN'|translate}} {{flight.detail.technicalStop.location}} - {{'DURATION'|translate}}: {{convertMilliseconds(flight.detail.technicalStop)}} - {{'ARRIVAL'|translate}}:{{returnDate(flight.detail.technicalStop.from) | date:'dd MMM - HH:mm'}} - {{'DEPARTURE'|translate}}: {{returnDate(flight.detail.technicalStop.to) | date:'dd MMM - HH:mm'}}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="text-center" *ngIf="!l">
                            {{'STOP'|translate}} in {{iataComplete.returnAirport(flight.arrival.location)}} <sup>{{flight.arrival.location}}</sup> -
                            {{'WAITING'|translate}} {{calculateWaiting(flight.arrival.dateTime, itinerary.flights[f+1].departure.dateTime)}}
                          </td>
                        </tr>
                      </ng-container>

                    </table>
                  </div>

                </div>

                <div style="margin:0 10px" class="fakeTable">
                  <div class="mb-1">
                    <div class="row" *ngFor="let flight of flights; let fl = index;let l = last">
                      <!--
                      <div class="col-8 mb-1" *ngIf="fl == 0">
                        {{'SEATS_SELECTION'|translate}}
                      </div>
                      -->
                      <div class="col-7 text-right">
                        {{iataComplete.returnAirport(flight.departure.location)}} <span class="material-icons">arrow_right_alt</span> {{iataComplete.returnAirport(flight.arrival.location)}}
                      </div>
                      <div class="col-5 text-right">
                        <div *ngFor="let pax of reservation.passengers">
                          {{pax.surname}} {{pax.name}} |
                        </div>
                      </div>
                      <div class="col-12"><hr/></div>
                    </div>
                  </div>
                  <div class="row mb-1" *ngIf="(!changePrice || !priceChange) && showPrice">
                    <div class="col-7">
                    </div>
                    <div class="col-5 text-right">
                      <hr/>
                    </div>
                  </div>
                  <!--
                  <div class="row mb-1">
                    <div class="col-7"></div>
                    <div class="col-5 text-right">
                      <div class="recoprice">
                        {{'TAX'|translate}} {{reservation.fare.tax|number:'.2-2'}}€
                      </div>
                    </div>
                  </div>
                  -->
                  <div class="row mb-2">
                    <div class="col-7"></div>
                    <div class="col-5 text-right" *ngIf="(!changePrice || !priceChange) && showPrice">
                      <!-- <hr/> -->
                      <div *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt">
                        <div class="recoprice" *ngIf="!reservation.negotiated">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                          <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                          <span *ngIf="!reservation.fare.currency">€</span>
                        </div>
                        <div class="recoprice" *ngIf="reservation.negotiated && !priceservice.isNegoHidden()">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (reservation.fare.fees||0))|number:'.2-2'}}€
                        </div>
                        <div class="recoprice" *ngIf="reservation.negotiated && priceservice.isNegoHidden()">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
                        </div>
                      </div>
                      <div *ngIf="mainComponent.appcomponent.isB2B || mainComponent.appcomponent.isSbt">
                        <div class="recoprice" *ngIf="reservation.negotiated">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
                        </div>
                        <div class="recoprice" *ngIf="reservation.negotiated">
                          {{'DISCOUNT'|translate}} {{reservation.fare.fees|number:'.2-2'}}€
                        </div>
                        <div class="recoprice" *ngIf="!reservation.negotiated">
                          {{'FLIGHT'|translate}} {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                          <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                          <span *ngIf="!reservation.fare.currency">€</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-5 text-right" *ngIf="changePrice && priceChange">
                      <hr/>
                      {{'FLIGHT'|translate}} {{priceChange|number:'.2-2'}}
                      <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                      <span *ngIf="!reservation.fare.currency">€</span>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-7"></div>
                    <div class="col-5 text-right">
                      <hr *ngIf="showPrice"/>
                      <div class="font-weight-bold" *ngIf="(!changePrice || !priceChange) && showPrice">
                        <div class="recoprice" *ngIf="!reservation.negotiated">
                          {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount)|number:'.2-2'}}
                          <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                          <span *ngIf="!reservation.fare.currency">€</span>
                        </div>
                        <div *ngIf="!mainComponent.appcomponent.isB2B && !mainComponent.appcomponent.isSbt && reservation.negotiated">
                          <div class="recoprice" *ngIf="reservation.negotiated && !priceservice.isNegoHidden()">
                            {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.amount + (reservation.fare.fees||0))|number:'.2-2'}}€
                          </div>
                          <div class="recoprice" *ngIf="reservation.negotiated && priceservice.isNegoHidden()">
                            {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount)|number:'.2-2'}}€
                          </div>
                        </div>
                        <div *ngIf="(mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) && reservation.negotiated">
                          {{'TOTAL'|translate}} {{priceservice.returnPrice(reservation.fare.publicAmount - reservation.fare.fees)|number:'.2-2'}}€
                        </div>
                      </div>
                      <div class="font-weight-bold" *ngIf="changePrice && priceChange">
                        {{'TOTAL'|translate}} {{priceChange|number:'.2-2'}}
                        <span *ngIf="reservation.fare.currency">{{reservation.fare.currency}}</span>
                        <span *ngIf="!reservation.fare.currency">€</span>
                      </div>

                    </div>
                  </div>
                </div>

                <div *ngIf="reservationNotes && showNotes">
                  <hr/>
                  <p>{{'NOTES'|translate}}</p>
                  {{reservationNotes}}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button mat-button data-dismiss="modal">
          {{'CLOSE'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>
