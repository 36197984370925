import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {FlightService} from '../../../../../services/flight/flight.service';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'app-farerules',
  templateUrl: './farerules.component.html',
  styleUrls: ['./farerules.component.scss']
})
export class FarerulesComponent implements OnInit {
  rules = [];
  originalRulesResponse: any;
  originalRulesAmaResponse: any;
  loadingFare = true;
  currentTab: number;
  newRules: any = {
    "session": {
        "SessionId": "012XN2CUAZ",
        "SequenceNumber": "3",
        "SecurityToken": "3JXL6TI9AZQH2GJ9L378N9W3U"
    },
    "pricingRecord": [
        [
            {
                "descripionCategory": "Acquisto anticipato",
                "numberCategory": "5",
                "nameCategory": "ADVANCE_PURCHASE",
                "dateInfo": [
                    {
                        "language": "IT",
                        "freeText": "Ultima data di emissione biglietto dopo la prenotazione: 10/07/2024 1052",
                        "infoType": "LTR",
                        "textType": "NO_SPECIFIC_SITUATION"
                    }
                ],
                "mnrMonLoc": [],
                "mnrRestriAppLoc": [],
                "mnrFCInfoGrp": [
                    {
                        "type": "FC",
                        "value": "1"
                    },
                    {
                        "type": "FC",
                        "value": "2"
                    }
                ]
            },
            {
                "descripionCategory": "Soggiorno minimo",
                "numberCategory": "6",
                "nameCategory": "MINIMUM_STAY",
                "dateInfo": [
                    {
                        "language": "IT",
                        "freeText": "Il viaggio deve cominciare dopo il: 14/07/2024 da Heathrow",
                        "infoType": "MIS",
                        "textType": "NO_SPECIFIC_SITUATION"
                    }
                ],
                "mnrMonLoc": [],
                "mnrRestriAppLoc": [],
                "mnrFCInfoGrp": [
                    {
                        "type": "FC",
                        "value": "2"
                    }
                ]
            },
            {
                "descripionCategory": "Soggiorno massimo",
                "numberCategory": "7",
                "nameCategory": "MAXIMUM_STAY",
                "dateInfo": [],
                "mnrMonLoc": [],
                "noCategoryRestriction": {
                    "freeTextDetails": {
                        "textSubjectQualifier": "ZZZ",
                        "language": "IT",
                        "source": "ZZZ",
                        "encoding": "7"
                    },
                    "freeText": "Soggiorno massimo: nessuna restrizione"
                },
                "mnrRestriAppLoc": [],
                "mnrFCInfoGrp": [
                    {
                        "type": "FC",
                        "value": "1"
                    },
                    {
                        "type": "FC",
                        "value": "2"
                    }
                ]
            },
            {
                "descripionCategory": "Condizioni di scambio",
                "numberCategory": "31",
                "nameCategory": "VOLUNTARY_CHANGES",
                "dateInfo": [
                    {
                        "language": "IT",
                        "freeText": "Le nuove date del viaggio devono essere precedenti a: 10/07/2025",
                        "infoType": "BDV",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Le nuove date del viaggio devono essere precedenti a: 10/07/2025",
                        "infoType": "ADV",
                        "textType": "AFTER_DEPARTURE"
                    }
                ],
                "mnrMonLoc": [
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDI",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BDC",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDM",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 60.00 EUR",
                        "infoType": "BDT",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDF",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDG",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDH",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDL",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDU",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDX",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNI",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BNC",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNM",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BNT",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNF",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNG",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNH",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNL",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNU",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNX",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADI",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ADC",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADM",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 60.00 EUR",
                        "infoType": "ADT",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADF",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADG",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADH",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADL",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADU",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADX",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANI",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ANC",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANM",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ANT",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANF",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANG",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANH",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANL",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANU",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANX",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    }
                ],
                "mnrRestriAppLoc": [
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "FFT",
                        "textType": "NO_SPECIFIC_SITUATION"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "RVA",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: consentita con limitazioni",
                        "infoType": "BDA",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "WAI",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "BNR",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: non consentita",
                        "infoType": "BNA",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "BNW",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "ADR",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: non consentita",
                        "infoType": "ADA",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "ADW",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "ANR",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: non consentita",
                        "infoType": "ANA",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "ANW",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    }
                ],
                "mnrFCInfoGrp": [
                    {
                        "type": "FC",
                        "value": "1"
                    }
                ]
            },
            {
                "descripionCategory": "Condizioni di scambio",
                "numberCategory": "31",
                "nameCategory": "VOLUNTARY_CHANGES",
                "dateInfo": [
                    {
                        "language": "IT",
                        "freeText": "Le nuove date del viaggio devono essere precedenti a: 10/07/2025",
                        "infoType": "BDV",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Le nuove date del viaggio devono essere precedenti a: 10/07/2025",
                        "infoType": "ADV",
                        "textType": "AFTER_DEPARTURE"
                    }
                ],
                "mnrMonLoc": [
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDI",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BDC",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDM",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 60.00 EUR",
                        "infoType": "BDT",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDF",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDG",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDH",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDL",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDU",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "BDX",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNI",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BNC",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNM",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BNT",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNF",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNG",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNH",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNL",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni* tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNU",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNX",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADI",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ADC",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "ADM",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 60.00 EUR",
                        "infoType": "ADT",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "ADF",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "ADG",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADH",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADL",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADU",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 60.00 EUR / 60.00 EUR (ai tassi di cambio di oggi 60.00 EUR / 60.00 EUR)",
                        "infoType": "ADX",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANI",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riconvalida massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ANC",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANM",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di riemissione massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ANT",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANF",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANG",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANH",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANL",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per sostituzioni tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANU",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale per riemissione biglietto tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANX",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    }
                ],
                "mnrRestriAppLoc": [
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "FFT",
                        "textType": "NO_SPECIFIC_SITUATION"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "RVA",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: consentita con limitazioni",
                        "infoType": "BDA",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "WAI",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "BNR",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: non consentita",
                        "infoType": "BNA",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "BNW",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "ADR",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: consentita con limitazioni",
                        "infoType": "ADA",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "ADW",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Modifiche*: non applicabile (vedere condizioni di riemissione)",
                        "infoType": "ANR",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Riemissione: non consentita",
                        "infoType": "ANA",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "ANW",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    }
                ],
                "mnrFCInfoGrp": [
                    {
                        "type": "FC",
                        "value": "2"
                    }
                ]
            },
            {
                "descripionCategory": "Condizioni di rimborso",
                "numberCategory": "33",
                "nameCategory": "VOLUNTARY REFUNDS",
                "dateInfo": [],
                "mnrMonLoc": [
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDM",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di rimborso massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BDT",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDF",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDG",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BDX",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNM",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di rimborso massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "BNT",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNF",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNG",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "BNX",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADM",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di rimborso massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ADT",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADF",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADG",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ADX",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANM",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale di rimborso massima per l'intero biglietto: 0.00 EUR",
                        "infoType": "ANT",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANF",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANG",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Penale tra: 0.00 EUR / 0.00 EUR (ai tassi di cambio di oggi 0.00 EUR / 0.00 EUR)",
                        "infoType": "ANX",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    }
                ],
                "mnrRestriAppLoc": [
                    {
                        "language": "IT",
                        "freeText": "",
                        "infoType": "FFT",
                        "textType": "NO_SPECIFIC_SITUATION"
                    },
                    {
                        "language": "IT",
                        "freeText": "Rimborso: non permesso",
                        "infoType": "BDA",
                        "textType": "BEFORE_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Rimborso: non consentito",
                        "infoType": "BNA",
                        "textType": "BEFORE_DEPARTURE_NO_SHOW"
                    },
                    {
                        "language": "IT",
                        "freeText": "Rimborso: non permesso",
                        "infoType": "ADA",
                        "textType": "AFTER_DEPARTURE"
                    },
                    {
                        "language": "IT",
                        "freeText": "Rimborso: non permesso",
                        "infoType": "ANA",
                        "textType": "AFTER_DEPARTURE_NO_SHOW"
                    }
                ],
                "mnrFCInfoGrp": [
                    {
                        "type": "FC",
                        "value": "1"
                    },
                    {
                        "type": "FC",
                        "value": "2"
                    }
                ]
            }
        ]
    ]
}
  miniToShow : any[] = [
    'BDT',
    'BNT',
    'ADT',
    'ANT',
    'WAI',
    'RVA',
    'BDA',
    'BDV',
    'BNW',
    'BNR',
    'BNA',
    'BNV',
    'ADW',
    'ADR',
    'ADA',
    'ADV',
    'ANW',
    'ANR',
    'ANA',
    'ANV',
    'MSC',
    'MIS'
  ]
  showFull = false;
  showFullNew = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private flightService: FlightService,
  ) {
    this.currentTab = 0;
  }

  ngOnInit(): void {
    this.rules.length = this.data.tariff.details.length;
    /* if (!this.data.flights) {
      this.askFareRules(this.data.tariff.details[this.currentTab], this.data.id);
    } else {
      this.askFareRulesFromSearch(this.data.tariff.details[this.currentTab], this.data.flights);
    } */
    // this.askFaresRules(this.data.tariff);
    this.penaltiesFromSearch();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.currentTab = tabChangeEvent.index;
    /*
    if (!this.data.flights) {
      this.askFareRules(this.data.tariff.details[this.currentTab], this.data.id);
    } else {
      this.askFareRulesFromSearch(this.data.tariff.details[this.currentTab], this.data.flights);
    } */
    // this.askFaresRules(this.data.tariff);
  }

  askFaresRules(tariff) {
    this.flightService.askFaresRules(tariff).subscribe((res) => {
      this.loadingFare = false;
      // this.rules[this.currentTab] = res;
      this.rules = res.rules;
      console.log('res rules => ', this.rules);
    }, error => {
      this.loadingFare = false;
      console.error('Error => ', error);
    });
  }

  askFareRules(fare, id) {
    this.loadingFare = true;
    this.flightService.askFareRules(fare, id).subscribe((res) => {
      this.loadingFare = false;
      this.rules[this.currentTab] = res;
      console.log('res rules => ', this.rules);
    }, error => {
      this.loadingFare = false;
      console.error('Error => ', error);
    });
  }

  askFareRulesFromSearch(fare, flights) {
    this.loadingFare = true;
    this.flightService.askFareRulesFromSearch(fare, flights).subscribe((res) => {
      this.loadingFare = false;
      this.rules[this.currentTab] = res;
      console.log('res rules => ', this.rules);
    }, error => {
      this.loadingFare = false;
      console.error('Error => ', error);
    });
  }

  toText(toSet) {
    toSet = toSet.replace(new RegExp('\n', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('\r', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('cancellations', 'ig'), '<span class="orangecolor"><b>CANCELLATIONS</b></span>');
    toSet = toSet.replace(new RegExp('cancellation', 'ig'), '<span class="orangecolor"><b>CANCELLATION</b></span>');
    toSet = toSet.replace(new RegExp('CHANGES', 'ig'), '<span class="orangecolor"><b>CHANGES</b></span>');
    toSet = toSet.replace(new RegExp('PE.PENALTIES', 'ig'), '<span class="orangecolor"><b>sPE.PENALTIES</b></span>');
    return toSet;
  }

  penaltiesFromSearch(){
    this.loadingFare = true;
    let requestBody = {
      _id: '',
    }
    if(this.data.recommendation){
      requestBody = this.data.recommendation;
    } else if(this.data.pnr){
      this.data.pnr.tariff = this.data.tariff;
      requestBody = this.data.pnr
    }
    if((this.data.isGat && !this.data.loggedUser.role.superAdmin) || this.data.loggedUser.company._id == '5e2715022aeeb50011f53f5d'){
      this.flightService.penaltiesFromSearch(requestBody).subscribe((res) => {
        this.loadingFare = false;
        this.rules = res.message;
        console.log('res rules => ', this.rules);
      }, error => {
        this.loadingFare = false;
        console.error('Error => ', error);
      });
    } else {
      this.askMiniRules(requestBody);
    }
  }

  askMiniRules(requestBody:any){
    if(requestBody._id){
      delete requestBody._id;
    }
    this.flightService.penaltiesFromSearchMini(requestBody).subscribe((res) => {
      this.loadingFare = false;
      // this.rules = res.rules;
      this.originalRulesResponse = res;
      this.originalRulesAmaResponse = JSON.parse(JSON.stringify(this.originalRulesResponse));


      // this.rules = this.alaborateRules(this.rules);
      this.originalRulesResponse.pricingRecord.forEach((rules:any) => {
        rules = this.alaborateRules(rules);
      })


      this.originalRulesResponse.pricingRecord.forEach((record, z) => {
        record.forEach((element: any, i) => {
          // element.dateInfo = this.sortedRulesDate(i);
          // element.mnrMonLoc = this.sortedRulesmnrMonLoc(i);
          // element.mnrRestriAppLoc = this.sortedRulesmnrRestriAppLoc(i);
          console.log('element.nameCategory ', element.nameCategory)
          if(element.nameCategory == 'ADVANCE_PURCHASE'){
            record.splice(i, 1);
          }
        })
        record.forEach((element: any, i) => {
          if(element.nameCategory == 'MINIMUM_STAY'){
            record.splice(i, 1);
          }
        })
        record.forEach((element: any, i) => {
          if(element.nameCategory == 'MAXIMUM_STAY'){
            record.splice(i, 1);
            // this.originalRulesResponse.splice(i, 1);
          }
        })
      });

      if(this.originalRulesResponse){
        // this.rules = this.rules.reverse();
        this.originalRulesResponse.pricingRecord.forEach((rule:any) => {
          // rule = rule.reverse();
        })
        // this.originalRulesResponse = this.originalRulesResponse.reverse();
      }

      console.log('res rules => ', this.rules);

    }, error => {
      this.loadingFare = false;
      console.error('Error => ', error);
    });
  }

  alaborateRules(rules:any){
    rules.forEach((element, i) => {
      const arrayDate = [];
      element.dateInfo.forEach(el => {
        if(this.todisplay(el.infoType)){
          arrayDate.push(el);
        }
      });
      element.dateInfo = arrayDate;
      if(element.dateInfo.length > 0){
        element.dateInfo = this.sortedRulesDate(element, i);
      }

      const arraymnrRestriAppLoc = [];
      element.mnrRestriAppLoc.forEach(el => {
        if(this.todisplay(el.infoType)){
          arraymnrRestriAppLoc.push(el);
        }
      });
      element.mnrRestriAppLoc = arraymnrRestriAppLoc;
      if(element.mnrRestriAppLoc.length > 0){
        element.mnrRestriAppLoc = this.sortedRulesmnrRestriAppLoc(element, i);
      }

      const arraymnrMonLoc = [];
      element.mnrMonLoc.forEach(el => {
        if(this.todisplay(el.infoType)){
          arraymnrMonLoc.push(el);
        }
      });
      element.mnrMonLoc = arraymnrMonLoc;
      if(element.mnrMonLoc.length > 0){
        element.mnrMonLoc = this.sortedRulesmnrMonLoc(element, i);
      }

      element.mnrRestriAppLoc.forEach(el => {
        if(el.infoType == 'BDA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BDT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'BDA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BDT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

        if(el.infoType == 'BNA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'BNA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

        if(el.infoType == 'ANA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'ANA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

        if(el.infoType == 'ADA' && el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ADT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
        } else if(el.infoType == 'ADA' && !el.freeText.includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ADT');
          if(element.numberCategory == '31'){
            element.mnrMonLoc[find].freeText = 'Riemissione consentita - ' + element.mnrMonLoc[find].freeText;
          } else if (element.numberCategory == '33'){
            element.mnrMonLoc[find].freeText = 'Rimborso consentito - ' + element.mnrMonLoc[find].freeText;
          }
        }

      });

      element.mnrMonLoc.forEach((el:any) => {
        if(element.numberCategory == '31' && el.infoType == 'BNT' && el.freeText.toLowerCase().includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
          if (rules[i-1]){
            let prevElement = rules[i-1];
            const findP = prevElement.mnrMonLoc.findIndex(m => m.infoType == 'ANT');
            if(findP != -1){
              prevElement.mnrMonLoc[findP].freeText = el.freeText;
            }
          }
        }
        if(element.numberCategory == '31' && el.infoType == 'ANT' && el.freeText.toLowerCase().includes('non')){
          const find = element.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
          if(find != -1){
            element.mnrMonLoc[find].freeText = el.freeText;
          }
          if (rules[i-1]){
            let prevElement = rules[i-1];
            const findP = prevElement.mnrMonLoc.findIndex(m => m.infoType == 'BNT');
            if(findP != -1){
              prevElement.mnrMonLoc[findP].freeText = el.freeText;
            }
          }
        }

      })

    });
    return rules;
  }

  sortedRulesDate(element,i) {
    return element.dateInfo.sort((a, b) => b.textType.localeCompare(a.textType));
  }

  sortedRulesmnrMonLoc(element,i){
    return element.mnrMonLoc.sort((a, b) => b.textType.localeCompare(a.textType));
  }

  sortedRulesmnrRestriAppLoc(element,i){
    return element.mnrRestriAppLoc.sort((a, b) => b.textType.localeCompare(a.textType));
  }
  todisplay(rule:any){
    return this.miniToShow.includes(rule)
  }

  toTextMini(toSet) {
    toSet = toSet.replace(new RegExp('\n', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('\r', 'g'), '<br/>');
    toSet = toSet.replace(new RegExp('consentito', 'ig'), '<span class="greencolor">consentito</span>');
    toSet = toSet.replace(new RegExp('consentita', 'ig'), '<span class="greencolor">consentita</span>');
    toSet = toSet.replace(new RegExp('non <span class="greencolor">consentito</span>', 'ig'), '<span class="redcolor">non consentito</span>');
    toSet = toSet.replace(new RegExp('non permesso', 'ig'), '<span class="redcolor">non permesso</span>');
    toSet = toSet.replace(new RegExp('non <span class="greencolor">consentita</span>', 'ig'), '<span class="redcolor">non consentita</span>');
    toSet = toSet.replace(new RegExp('non applicabile', 'ig'), '<span class="redcolor">non applicabile</span>');
    toSet = toSet.replace(new RegExp('CHANGES', 'ig'), '<span class="orangecolor"><b>CHANGES</b></span>');
    toSet = toSet.replace(new RegExp('PE.PENALTIES', 'ig'), '<span class="orangecolor"><b>sPE.PENALTIES</b></span>');
    return toSet;
  }
}