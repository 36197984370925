<div class="pagetitle">
  {{'POLICY.ALL_GROUPS'|translate}}
</div>
<div class="padding20">
  <mat-card>
    <div class="d-flex align-self-center justify-content-end buttonrowBis w-100 py-1 px-2">
      <button mat-stroked-button color="primary" (click)="openGroupDialog('')">{{'POLICY.NEW_GROUP'|translate}}</button>
    </div>

    <app-roles-table (updateNameDialog)="openGroupDialog($event)" (deleted)="handleDelete($event)" *ngIf="groups; else loading" [data]="groups.data">
    </app-roles-table>
  </mat-card>
</div>

<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

