import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-policy-dialog',
  templateUrl: './delete-policy-dialog.component.html',
  styleUrls: ['./delete-policy-dialog.component.scss']
})
export class DeletePolicyDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {
  }

}
