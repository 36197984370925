<table *ngIf="data; else noData" mat-table [dataSource]="dataSource" class="w-100 policiesList" matSort>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> {{'NAME'|translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef> {{'COMPANY'|translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.company.name}} </td>
  </ng-container>


  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex align-self-center justify-content-end">
        <button class="miniIcons" (click)="updateNameDialog.emit(element)" mat-icon-button>
          <mat-icon>edit</mat-icon>
        </button>
        <button class="miniIcons" (click)="handleDelete(element)" color="warn" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

<ng-template #noData>
  <div class="no-data-container">
    <h2>{{'NO_DATA_AVAILABLE'|translate}}</h2>
  </div>
</ng-template>
