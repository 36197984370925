<div class="singleRate">
  <div class="row">
    <div class="col-lg-3 col-2 d-flex align-items-center justify-content-center">
      <div class="text-center">
        <div class="roomIcon">
          <span class="material-icons" *ngIf="isRoomOnly(room.ratePlans[0].mealPlan)"> bed </span>
          <span class="material-icons" *ngIf="!isRoomOnly(room.ratePlans[0].mealPlan)"> restaurant_menu </span>
        </div>
        <div class="ratePlan">
          {{room.ratePlans[0].mealPlan}}
        </div>
      </div>
    </div>
    <div class="col-md-9 col-10">
      <div class="row py-3">
        <div class="col-md-8 col-12">
          <div class="" *ngFor="let rate of room.roomRates, let ind = index;" > <!--*ngFor="let rate of room.roomRates, let ind = index;"-->
            <div class="smalltext mb-1" *ngIf="!isSbt && !isGat">
              {{returnProvider(room.chain)}}
            </div>
            <div class="smalltext mb-1" *ngIf="isSbt || isGat">
              <div *ngIf="checkVoucher(room.chain)">Prepaid Voucher</div>
            </div>
            <div class="rateText mb-1">
              <span *ngIf="room.corporateFare" class="d-block greentxt"><b>{{'CORPORATE'|translate}}</b></span>
              {{rate.text}}
            </div>
            <p class="mb-1 smalltext" *ngIf="!isBooking(room.chain)"> {{'HOTEL.TOURIST_TAX'|translate}} </p>
            <div class="dividerRow"></div>
            <div class="mb-2">
              <span class="maxGuest">{{'MAX_GUESTS'|translate}}: </span>
              <span class="guestNum">
                {{guests.length}}
                <span *ngIf="guests.length>1"><b>{{'PERSONS'|translate}}</b></span>
                <span *ngIf="guests.length === 1"><b>{{'PERSON'|translate}}</b></span>
                </span>
            </div>
          </div>
          <div *ngIf="!isFreeCancellationBis()" class="noRefound">
            <div *ngFor="let plan of room.ratePlans">
              <span class="material-icons text-danger"> highlight_off </span>
              {{'HOTEL.NO_REFOUND'|translate}}
            </div>
          </div>

          <div *ngIf="isFreeCancellationBis()" class="refound">
            <div *ngFor="let plan of room.ratePlans">
              <div *ngFor="let penal of plan.penalty; let l = last;" class="penalty">
                <span class="material-icons greentxt"> task_alt </span>
                <span>{{'HOTEL.YES_REFOUND'|translate}}</span>
                <span *ngIf="penal.absoluteDeadLine">
                  {{'TILL'|translate}} {{removeHr(penal.absoluteDeadLine)|date:'dd/MM/yyyy - HH:mm'}}
                </span>
                <div class="refound">
                  <span class="material-icons"> delete_outline </span>
                  {{'CANCELLATION'|translate}} {{'DAL'|translate}}
                  <span *ngIf="penal.absoluteDeadLine"> {{penal.absoluteDeadLine|date:'dd/MM/yyyy'}}: </span>
                  <span *ngIf="penal.amount && !l">
                  <b> {{penal.amount|number:'.2-2'}} {{room.currency}}</b>
                  <span *ngIf="room.currency != 'EUR'">
                    <br/>({{convertPrice(penal.amount, room.currency)|number:'.2-2'}}€)
                  </span>
                </span>
                  <span *ngIf="penal.amount && l">
                  <b> {{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}</b>
                  <span *ngIf="room.currency != 'EUR'">
                    <br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)
                  </span>
                </span>

                  <span *ngIf="!penal.amount">
                  <b>{{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}</b>
                  <span *ngIf="room.currency != 'EUR'">
                    <br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)
                  </span>
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mt-md-0 mt-3 d-flex align-items-md-center justify-content-end">
          <div *ngFor="let rate of room.roomRates, let ind = index;">
            <div class="text-right">
              <div *ngFor="let rate of room.roomRates, let ind = index;" class="roomPrice">
                <span>{{priceService.returnPriceHotel(rate.amount)|number:'.2-2'}}</span>
                <span *ngIf="room.currency == 'EUR'">€</span>
                <span *ngIf="room.currency != 'EUR'" class="material-icons">arrow_right_alt</span>
                <span *ngIf="room.currency != 'EUR'">{{convertPrice(rate.amount, room.currency)|number:'.2-2'}} EUR</span>
                <span class="nights" *ngIf="numberOfNights > 1; else night">/ {{numberOfNights}} {{'NIGHTS'|translate}}</span>
                <ng-template #night>
                  <span class="nights">/ {{numberOfNights}} {{'NIGHT'|translate}}</span>
                </ng-template>
              </div>
            </div>
            <button mat-raised-button color="primary" class="showRoomsBtn mt-2" (click)="selectRoomFare(rate, ind)" [disabled]="checkIfBlock()">
              {{'SELECT_ROOM'|translate}} <mat-icon iconPositionEnd class="material-icons "> keyboard_arrow_right </mat-icon>
            </button>
            <app-policy-icon-inmodal class="ml-2" [isB2B]="isB2B" [isSbt]="isSbt" [arrayOfAlerts]="allAlerts(room)"></app-policy-icon-inmodal>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
