<div class="row">
  <div class="col px-2">
    <mat-form-field appearance="outline" class="groupSelect">
      <mat-label>{{'POLICY.SELECT_GROUP'|translate}}</mat-label>
      <mat-select [(ngModel)]="selectedRole" (selectionChange)="selectFromGroup.emit($event)">
        <mat-option *ngFor="let role of rolesList" [value]="role._id">{{role.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="ml-2 iconButtons" mat-icon-button matTooltip="{{'CLEAR'|translate}}" (click)="selectFromGroup.emit({value: 'clear'});selectedRole=''">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div class="row py-2">
  <div class="col-12">
    <div class="usersSelected p-2">
      <p>{{'POLICY.USERS_SELECTED'|translate}}</p>
      <p *ngIf="selection.selected.length == 0">{{'POLICY.NO_USER_SELECTED'|translate}}</p>
      <div *ngIf="selection.selected.length > 0" class="row">
        <!--<label>Users</label>-->
        <div *ngFor="let user of selection.selected" class="col-auto mb-1 mr-1 chipItem d-flex align-items-center">
          <span class="mx-2"> {{user.name}} {{user.surname}} </span>
          <button mat-icon-button (click)="remove(user)" class="miniIcons">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col px-2 saveGroupButton">
    <button mat-raised-button color="primary" (click)="updateUserRoles()">{{'SAVE'|translate}}</button>
  </div>
</div>
