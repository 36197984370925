<div class="mb-3">{{'B2C_SETTINGS'|translate}}</div>
<hr/>
<div class="row">
    <div class="col-lg-8 mb-2" *ngIf="b2cSettings">
        <div class="mb-3">Gestione pagine pubbliche</div>
        <div class="mb-3">
            <div [ngClass]="{'currentTabB2c' : currentTab == tab}" class="d-inline-block me-2 singleTabB2c" *ngFor="let tab of tabs" (click)="currentTab = tab">
                {{tab|translate}}
            </div>
        </div>

        <div class="mb-2 pr-4">
            <ng-container *ngIf="currentTab == 'about'">
                <div class="">
                    <div class="mb-3">{{'ABOUT_PAGE'|translate}}</div>
                    <div class="small mb-3">
                      Pagina chi siamo. Inserisci qui il testo che vuoi far visualizzare ai tuoi clienti nella pagina "chi siamo"
                    </div>
                    <angular-editor [(ngModel)]="b2cSettings.b2cAbout" [config]="editorConfig"></angular-editor>
                </div>
            </ng-container>

            <ng-container *ngIf="currentTab == 'where'">
                <div class="mb-3">{{'WHERE'|translate}}</div>
            </ng-container>

            <ng-container *ngIf="currentTab == 'contacts'">
              <div class="mb-3">{{'SEDI'|translate}}</div>
              <div class="small mb-3">Aggiungi le sedi ed i contatti della tua agenzia</div>
              <div class="singleWhere" *ngFor="let contact of b2cSettings.b2cWhere; let i = index; let l = last">
                <div class="small mb-2 row">
                  <div class="col-6">Sede {{i+1}}</div>
                  <div class="col-6 text-right">
                    <span (click)="removeWhere(i)" class="pointer">Rimuovi sede X</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6">
                    <input class="customInput w-100 mb-1" [(ngModel)]="contact.email" placeholder="Email" type="email"/>
                  </div>
                  <div class="col-6">
                    <input class="customInput w-100 mb-1" [(ngModel)]="contact.phone" placeholder="Telefono" type="number"/>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12 mb-2">
                    <input class="customInput w-100 mb-1" [(ngModel)]="contact.text" placeholder="Indirizzo" type="text"/>
                  </div>
                  <div class="col-6">
                    <input class="customInput w-100 mb-1" [(ngModel)]="contact.latitude" placeholder="latitudine" type="text"/>
                  </div>
                  <div class="col-6">
                    <input class="customInput w-100 mb-1" [(ngModel)]="contact.longitude" placeholder="longitudine" type="text"/>
                  </div>

                </div>
                <!--<hr *ngIf="b2cSettings.b2cWhere.length > 0 && !l"/> -->
              </div>
              <div class="buttonrow text-right">
                <button (click)="addWhere()" color="primary" mat-button>
                  <span>Aggiungi sede</span>
                </button>
              </div>

            </ng-container>
        </div>

    </div>

    <div class="col-lg-4 mb-2" *ngIf="b2cSettings">
        <div class="mb-3">Schema colori</div>
        <div class="mb-2">
            <div class="small mb-2">Colore primario</div>
            <input type="color" [(ngModel)]="b2cSettings.primaryColor"/>
        </div>
        <div class="mb-2">
            <div class="small mb-2">Colore secondario</div>
            <input type="color" [(ngModel)]="b2cSettings.SecondaryColor"/>
        </div>
        <hr/>
        <div class="mb-2">
            <div class="mb-3">Indirizzo b2c</div>
            <div class="fakeInput">
                https://<span class="text-success">{{cpSetting.b2cLink}}</span><span class="ml-1">.b2ctravel</span><span class="ml-1">.it</span>
            </div>
        </div>
    </div>
</div>
<hr/>
<div class="text-right">
  <button (click)="save()" color="primary" mat-button>
    <span>Salva</span>
  </button>
</div>
