<div class="row buttonrow mb-3">
  <div class="col-md-6 align-self-center">
    <button mat-raised-button (click)="toggleSearch()">
      <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
      <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
    </button>
    <button (click)="toggleFilters()" mat-raised-button>
      {{'OPEN_FILTERS'|translate}}
    </button>
  </div>
  <div *ngIf="filteredRes && searchResults.cars" class="col-md-6 text-right align-self-center">
    <span>{{'SATISFY_FILTER'|translate}} {{filteredRes.length}} /</span> {{searchResults.cars.length}} {{'RESULTS_FOUND'|translate}}
  </div>

</div>

<div class="filterRecom text-right">
  <button (click)="toggleSearch()" mat-mini-fab color="primary" aria-label="Search">
    <mat-icon>search</mat-icon>
  </button>
  <button (click)="toggleFilters()" mat-mini-fab color="primary" aria-label="Search">
    <mat-icon>tune</mat-icon>
  </button>
</div>


<div class="card padding10 mb-3" *ngFor="let car of obs | async" [scrollTop]="100">
  <div class="row singleStepSr">
    <div class="col-md-4 col-sm-12 text-center align-self-center">
      <img class="carImage" *ngIf="car.images[0]!='https://none'" src="{{car.images[0]}}"/>
      <div class="carImage" *ngIf="car.images[0]=='https://none'">
        <img class="carImage" src="/assets/img/cars/nophoto.png"/>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 ">
      <div class="middleCarCol">
        <div class="carModel mb-2">
          <div>{{car.type}}</div>
          <div><b>{{car.model}}</b> <sup> {{legend.CATHEGORY[car.type[0]]}}</sup></div>
        </div>
        <div class="veicleIcons">

          <span class="singleIcon">
            <img *ngIf="car.type[1]=='B' || car.type[1]=='C' || car.type[1]=='D'" src="assets/img/cars/doors.svg"/>
            {{legend.TYPE[car.type[1]]}} <span *ngIf="car.type[1]=='B' || car.type[1]=='C' || car.type[1]=='D'">{{'DOORS'|translate}}</span>
          </span>

          <span class="singleIcon" *ngIf="car.type[2]=='A' || car.type[2]=='B' || car.type[2]=='D'">
            <img src="assets/img/cars/transmission.svg"/>
            <span matTooltip="{{'AUTOMATIC_TRANSMISSION'|translate}}">{{'AUTOMATIC_TRANSMISSION'|translate}}</span>
          </span>
          <span class="singleIcon" *ngIf="car.type[2]=='C' || car.type[2]=='M' || car.type[2]=='N'">
            <img src="assets/img/cars/transmission.svg"/>
            <span matTooltip="{{'MANUAL_TRANSMISSION'|translate}}">{{'MANUAL_TRANSMISSION'|translate}}</span>
          </span>

          <span class="singleIcon" *ngIf="returnAir(car.type[3])">
            <img src="assets/img/cars/airconditioning.svg"/>
            <span matTooltip="{{'AIR_CONDITIONING'|translate}}">{{'AIR_CONDITIONING'|translate}}</span>
          </span>
          <span class="singleIcon">
            <img src="assets/img/cars/fuel.png"/>
            {{legend.FUEL_AND_AC[car.type[3]]}}
          </span>

        </div>
        <hr/>
        <div class="carLocations">
          <div class="carLocation">
            <div class="dateAtLocation">
              {{returnDate(car.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
            </div>
            <span class="material-icons"> gps_fixed </span>
            {{car.pickUp}}
          </div>
          <div class="rotateArrow">
            <span class="material-icons"> arrow_right_alt </span>
          </div>
          <div class="carLocation">
            <span class="material-icons"> gps_fixed </span>
            {{car.dropOff}}
            <div class="dateAtLocation">
              {{returnDate(car.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 align-self-center">
      <div class="padding10">
        <img src="{{car.companyImage}}"/>
        <hr/>
        <p><b>{{'PRICE'|translate}}</b></p>
        <div class="" *ngFor="let rate of car.rates">
          {{'POLICY.CAR_.'+rate.type|uppercase|translate}}: {{rate.amount|number:'.2-2'}}€
        </div>
        <hr/>
        <div class="buttonrow">
          <button [disabled]="checkPolicy(car)" mat-stroked-button color="primary" (click)="toggleSingleCar(car)">{{'BOOK'|translate}}</button>
          <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt" [arrayOfAlerts]="car.arrayOfAlerts"></app-policy-alerts-icon>
        </div>
      </div>

    </div>
  </div>
</div>

<mat-paginator *ngIf="searchResults && searchResults.cars" [length]="searchResults.cars.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               (page)="pageChanged($event)">
</mat-paginator>

<div class="modal fade" id="singleCarModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="singleCarModal" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document"> <!-- modal-dialog-scrollable -->

    <div class="modal-content" *ngIf="currentCar">
      <div class="modal-header">
        <h5 class="modal-title" id="addGuestModalTitle">
         {{currentCar.model}} <sup>{{legend.CATHEGORY[currentCar.type[0]]}}</sup>
        </h5>
        <button type="button" class="close" (click)="toggleSingleCar(null)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false">
          <mat-tab label="{{'CAR_DETAILS'|translate}}">
            <div class="padding10">
              <div class="row singleStepSr">
                <div class="col-md-6 col-sm-12 text-center align-self-center">
                  <div id="map"></div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div>
                    <img class="carImage" *ngIf="currentCar.images[0]!='https://none'" src="{{currentCar.images[0]}}"/>
                    <div class="carImage" *ngIf="currentCar.images[0]=='https://none'">
                      <img class="carImage" src="/assets/img/cars/nophoto.png"/>
                    </div>
                  </div>
                  <div class="middleCarCol">
                    <div class="carModel mb-2">
                      <div>{{currentCar.type}}</div>
                      <div>
                        <b>{{currentCar.model}}</b> <sup> {{legend.CATHEGORY[currentCar.type[0]]}}</sup>
                      </div>
                    </div>
                    <div class="veicleIcons">

                      <span class="singleIcon">
                        <img *ngIf="currentCar.type[1]=='B' || currentCar.type[1]=='C' || currentCar.type[1]=='D'" src="assets/img/cars/doors.svg"/>
                        {{legend.TYPE[currentCar.type[1]]}}
                        <span *ngIf="currentCar.type[1]=='B' || currentCar.type[1]=='C' || currentCar.type[1]=='D'">{{'DOORS'|translate}}</span>
                      </span>

                      <span class="singleIcon" *ngIf="currentCar.type[2]=='A' || currentCar.type[2]=='B' || currentCar.type[2]=='D'">
                        <img src="assets/img/cars/transmission.svg"/>
                        <span matTooltip="{{'AUTOMATIC_TRANSMISSION'|translate}}">{{'AUTOMATIC_TRANSMISSION'|translate}}</span>
                      </span>
                      <span class="singleIcon" *ngIf="currentCar.type[2]=='C' || currentCar.type[2]=='M' || currentCar.type[2]=='N'">
                        <img src="assets/img/cars/transmission.svg"/>
                        <span matTooltip="{{'MANUAL_TRANSMISSION'|translate}}">{{'MANUAL_TRANSMISSION'|translate}}</span>
                      </span>

                      <span class="singleIcon" *ngIf="returnAir(currentCar.type[3])">
                        <img src="assets/img/cars/airconditioning.svg"/>
                        <span matTooltip="{{'AIR_CONDITIONING'|translate}}">{{'AIR_CONDITIONING'|translate}}</span>
                      </span>

                      <span class="singleIcon">
                        <img src="assets/img/cars/fuel.png"/>
                        {{legend.FUEL_AND_AC[currentCar.type[3]]}}
                      </span>
                    </div>
                    <hr/>
                    <div class="carLocations">
                      <div class="carLocation">
                        <div class="dateAtLocation">
                          {{returnDate(currentCar.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                        </div>
                        <span class="material-icons"> gps_fixed </span>
                        {{currentCar.pickUp}}
                      </div>
                      <div class="rotateArrow">
                        <span class="material-icons"> arrow_right_alt </span>
                      </div>
                      <div class="carLocation">
                        <span class="material-icons"> gps_fixed </span>
                        {{currentCar.dropOff}}
                        <div class="dateAtLocation">
                          {{returnDate(currentCar.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{'SERVICES'|translate}}">
            <div class="padding10">
              <ol class="carServices">
                <li class="singleCarService" *ngFor="let service of currentCar.services">
                  <span *ngIf="service.type">{{types[service.type]}} </span>
                  <span *ngIf="service.period">{{period[service.period]}} </span>
                  <span *ngIf="service.text">{{service.text}} </span>
                  <span *ngIf="service.amount"> {{service.amount|number:'.2-2'}}€</span>
                </li>
              </ol>
            </div>

          </mat-tab>
          <mat-tab label="{{'DRIVERS'|translate}}">
            <div class="padding10">
              <div class="pt-2 mb-3">
                {{'DRIVERS_INFO_TEXT'|translate}}
              </div>
              <div class="row">
                <div class="col-lg-6 mb-3" *ngFor="let tra of currentDrivers;let i = index">
                  <div class="fakefield mb-2">
                    {{'TYPE'|translate}}: <span> {{tra.type|translate}}</span>
                  </div>
                  <div class="fakefield mb-2">
                    {{'NAME'|translate}}:
                    <span *ngIf="tra.name"> {{tra.name}}</span>
                    <span *ngIf="!tra.name"> _ _ _ _</span>
                  </div>
                  <div class="fakefield mb-2">
                    {{'SURNAME'|translate}}:
                    <span *ngIf="tra.surname"> {{tra.surname}}</span>
                    <span *ngIf="!tra.surname"> _ _ _ _</span>
                  </div>
                  <div class="fakefield mb-2">
                    {{'PHONE'|translate}}:
                    <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
                    <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
                  </div>
                  <div class="fakefield mb-2">
                    {{'EMAIL'|translate}}:
                    <span *ngIf="tra.email"> {{tra.email}}</span>
                    <span *ngIf="!tra.email"> _ _ _ _</span>
                  </div>
                  <div class="buttonrow">
                    <button mat-stroked-button *ngIf="!tra.name || !tra.surname" (click)="toggleDialog(i)">{{'ADD_DRIVER'|translate}}</button>
                    <button mat-stroked-button *ngIf="tra.name || tra.surname" (click)="removeTraveler(i)">{{'REMOVE_DRIVER'|translate}}</button>
                    <button mat-stroked-button *ngIf="tra.name || tra.surname" (click)="toggleEditDialog(i)">{{'EDIT_DRIVER'|translate}}</button>
                    <button mat-stroked-button *ngIf="!tra.name && !tra.surname"  (click)="loadTravelersList(i)">{{'LOAD_DRIVER'|translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <hr/>

      <div class="text-right buttonrow" *ngIf="!booking">
        <button type="button" mat-button (click)="toggleSingleCar(null)">{{'CLOSE'|translate}}</button>
        <button type="button" [disabled]="!travelerController()" mat-stroked-button color="primary" (click)="bookReservation()">{{'BOOK'|translate}}</button>
      </div>
      <div *ngIf="booking">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-add [newTraveler]="currentDrivers[currentTraveler]" [parent]="'searchResults'" (close)="closeEvent($event)"
                 (setTraveler)="travelerSet($event, currentTraveler)">
        </app-add>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="listTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="loadTravelers">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-travelerslist [newTraveler]="currentDrivers[currentTraveler]" [passengers]="currentDrivers" [page]="'searchResults'"
                           (close)="closeListEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)">
        </app-travelerslist>
        <hr/>
        <div class="buttonrow text-right">
          <button mat-stroked-button (click)="closeListEvent($event)">{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="EditTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
        <app-edittraveler (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"
                          [currentTraveler]="currentDrivers[currentTraveler]" [parent]="'searchResults'">
        </app-edittraveler>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!filteredRes || (searchResults.cars && searchResults.cars.length==0) || (filteredRes && filteredRes.length==0)"
     class="card padding10 text-center">
  {{'NO_CARS_FOUNDED'|translate}}<br/>
  {{'PLEASE_TRY_USING_DIFFERENT_CRITERIA'|translate}}
</div>

<div class="filterSearch">

  <div class="nameFilter">
    <mat-form-field>
      <mat-label>{{'MODEL_NAME'|translate}} </mat-label>
      <input matInput (change)="doSort()" [(ngModel)]="searchName">
    </mat-form-field>
  </div>
  <hr/>
  <div class="providerFilter">
    <div class="mb-2">{{'PROVIDER'|translate}}</div>
    <div class="singleProvider" *ngFor="let provider of carsProviders" (click)="manageProviders(provider)">
      <span class="material-icons" *ngIf="!isProviderSelected(provider)"> check_box_outline_blank </span>
      <span class="material-icons" style="color:cornflowerblue;" *ngIf="isProviderSelected(provider)"> check_box </span>
      {{provider}}
    </div>
  </div>
  <hr/>
  <div class="providerFilter">
    <div class="mb-2">{{'CAR_TYPE'|translate}}</div>
    <div class="singleProvider" *ngFor="let carType of carsTypes" (click)="manageTypes(carType)">
      <span class="material-icons" *ngIf="!isTypeSelected(carType)"> check_box_outline_blank </span>
      <span class="material-icons" style="color:cornflowerblue;" *ngIf="isTypeSelected(carType)"> check_box </span>
      {{legend.TYPE[carType]}}
    </div>
  </div>

  <hr/>
  <div class="providerFilter">
    <div class="mb-2">{{'CATEGORY_TYPE'|translate}}</div>
    <div class="singleProvider" *ngFor="let category of categoryTypes" (click)="manageCategoryTypes(category)">
      <span class="material-icons" *ngIf="!isCategoryTypeSelected(category)"> check_box_outline_blank </span>
      <span class="material-icons" style="color:cornflowerblue;" *ngIf="isCategoryTypeSelected(category)"> check_box </span>
      {{legend.CATHEGORY[category]}}
    </div>
  </div>

  <hr/>
  <div class="providerFilter">
    <div class="mb-2">{{'GEAR_TYPE'|translate}}</div>
    <div class="singleProvider" *ngFor="let gear of gearTypes" (click)="manageGearTypes(gear)">
      <span class="material-icons" *ngIf="!isGearTypeSelected(gear)"> check_box_outline_blank </span>
      <span class="material-icons" style="color:cornflowerblue;" *ngIf="isGearTypeSelected(gear)"> check_box </span>
      {{legend.GEAR_TYPE[gear]}}
    </div>
  </div>

  <hr/>
  <div class="providerFilter">
    <div class="mb-2">{{'FUEL_AND_AC'|translate}}</div>
    <div class="singleProvider" *ngFor="let fuel of fuelacTypes" (click)="manageFuelacTypes(fuel)">
      <span class="material-icons" *ngIf="!isFuelacTypeSelected(fuel)"> check_box_outline_blank </span>
      <span class="material-icons" style="color:cornflowerblue;" *ngIf="isFuelacTypeSelected(fuel)"> check_box </span>
      {{legend.FUEL_AND_AC[fuel]}}
    </div>
  </div>
</div>
<div (click)="toggleFilters()" class="filtersearchBackdrop"></div>
