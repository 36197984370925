import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TravelPolicyFormModule} from './travel-policy-form/travel-policy-form.module';

import {RouterModule} from '@angular/router';
import {PolicyComponent} from './policy.component';
import {MatButtonModule} from '@angular/material/button';
import {CreatePolicyDialogComponent} from './create-policy-dialog/create-policy-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {DeletePolicyDialogComponent} from './delete-policy-dialog/delete-policy-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {DefaultUserComponent} from './default-user/default-user.component';
import {MatListModule} from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SharedModule} from '../../modules/shared/shared.module';
import {CustomCommonModule} from "../../modules/common/custom-common.module";
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from "@angular/material/paginator";

/*
const routes: Routes = [
  {
    path: 'policies',
    component: PolicyComponent
  },
  {
    path: 'conditions/:policyId',
    component: TravelPolicyFormComponent
  }
];*/

@NgModule({
  declarations: [PolicyComponent, CreatePolicyDialogComponent, DeletePolicyDialogComponent, DefaultUserComponent],
    imports: [
        MatListModule,
        CommonModule,
        // [RouterModule.forChild(routes)],
        TravelPolicyFormModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        RouterModule,
        MatCardModule,
        MatAutocompleteModule,
        SharedModule,
        CustomCommonModule,
        MatTableModule,
        MatPaginatorModule,
    ]
})
export class PolicyModule { }
