<mat-dialog-content class="mat-typography py-2">
  <div class="mb-1">
    In queste date:
    <span class="mr-1" *ngFor="let section of data.request.sections; let l = last">
      {{section.options.date| date:'dd/MM/yyyy'}}
      <span class="material-icons ml-1 mr-1" *ngIf="!l">arrow_right_alt</span>
    </span>
  </div>
  <!--
  <div class="mb-3">
    Tariffa più economica fornita da {{iataComplete.returnCarrier(data.recommendation.marketingCarrier)}}
  </div>
  -->
  <div class="isRecom">
    <div class="priceDetails">
      <div class="row negotiatedRow" *ngIf="data.recommendation.negotiated && !priceservice.isNegoHidden() && !isB2B">
        <div class="col-12 text-right">
          <div>{{'NEGOTIATED_FARE'|translate}}</div>
          <span *ngIf="data.recommendation.fare.publicAmount">
            {{'PUBLIC_FARE'|translate}}: {{data.recommendation.fare.publicAmount|number:'.2-2'}}€ |
          </span>
          <span *ngIf="data.recommendation.fare.publicAmount" class="">
            {{'NET_FARE'|translate}}: {{data.recommendation.fare.amount|number:'.2-2'}}€ |
          </span>
          <span *ngIf="!data.isGat">{{'FLYLEO_FEE'|translate}}: {{data.recommendation.fare.fees|number:'.2-2'}}€ | </span>
          <span *ngIf="data.isGat">{{'FLYGAT_FEE'|translate}}: {{data.recommendation.fare.fees|number:'.2-2'}}€ | </span>
          <span *ngIf="data.recommendation.fare.publicAmount" class="">
            {{'NET_DISCOUNT'|translate}}: {{calculateMargin(data.recommendation.fare.publicAmount,data.recommendation.fare.amount, data.recommendation.fare.fees)|number:'.2-2'}}€
          </span>
        </div>
      </div>
      <div class="row negotiatedRow" *ngIf="data.recommendation.negotiated && isB2B">
        <div class="col-12 text-right">
          <div>{{'DISCOUNT_FARE'|translate}}</div>
        </div>
      </div>

      <div class="smalltext mb-2" *ngIf="iataComplete.carriersOptions[data.recommendation.marketingCarrier] && !data.isB2B && !data.isSbt">
        <div *ngIf="iataComplete.carriersOptions[data.recommendation.marketingCarrier].blockIssuance">
          L'emissione per questo vettore ha un costo aggiuntivo di {{iataComplete.carriersOptions[data.recommendation.marketingCarrier].feePerPax| number:'.2-2'}}€ per passeggero.<br/>
          Non è possibile emettere manualmente questa prenotazione, potrai contattare la bigliettaria per richiederne l'emissione dopo la prenotazione.
          <div>Il costo aggiuntivo è di {{(data.request.passengers.adt + data.request.passengers.chd + data.request.passengers.inf) * iataComplete.carriersOptions[data.recommendation.marketingCarrier].feePerPax| number:'.2-2'}}€</div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="text-center" *ngIf="loadingTar">
            {{'REAL_TIME_PRICING'|translate}}
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <div *ngIf="!showFares" @listItemAnimation>
            <div *ngFor="let fare of data.recommendation.rates">
              <div *ngIf="fare.selected">
                {{'SELECTED_FARE'|translate}}:
                <span *ngFor="let class of fare.details;let l = last;">
                  <span>{{class.name||class.tariffClass}}</span>
                  <span *ngIf="!l"> + </span>
                </span>
                <div class="bagInfo">
                  <div *ngIf="fare.passengers.ADT">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_ADULT'|translate}}:
                    <span *ngFor="let bag of fare.passengers.ADT.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                  </div>
                  <div *ngIf="fare.passengers.CHD">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_CHILD'|translate}}:
                    <span *ngFor="let bag of fare.passengers.CHD.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                  </div>
                  <div *ngIf="fare.passengers.INF">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_INFANT'|translate}}:
                    <span *ngFor="let bag of fare.passengers.INF.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 text-right pnrActionPart buttonrow">
          <div class="mb-2">
            <img [matTooltip]="iataComplete.returnCarrier(data.recommendation.marketingCarrier)" class="recoCarrier"
                 src="assets/img/airline/icon/{{data.recommendation.marketingCarrier}}.png"/>
            <span *ngIf="!data.recommendation.fare.publicAmount" class="price">
              {{priceservice.returnPrice(data.recommendation.fare.amount)|number:'.2-2'}} €
            </span>
            <span *ngIf="!isB2B && data.recommendation.fare.publicAmount">
              <span *ngIf="data.recommendation.fare.publicAmount && priceservice.isNegoHidden()" class="price">
                {{priceservice.returnPrice(data.recommendation.fare.publicAmount)|number:'.2-2'}} €
              </span>
              <span *ngIf="data.recommendation.fare.publicAmount && !priceservice.isNegoHidden()" class="price">
                {{priceservice.returnPrice(data.recommendation.fare.amount + data.recommendation.fare.fees)|number:'.2-2'}} €
              </span>
            </span>
            <span *ngIf="isB2B && data.recommendation.fare.publicAmount">
              <span class="price">
                {{priceservice.returnPrice(data.recommendation.fare.publicAmount - data.recommendation.fare.fees)|number:'.2-2'}} €
              </span>
            </span>
          </div>
          <div class="buttonrow">
            <button mat-stroked-button *ngIf="(!data.isB2B && !data.isSbt) && !data.recommendation.lowCost && data.recommendation.paxFare && !data.userSettings.useMarkup" [matMenuTriggerFor]="perPax">
              <span class="material-icons"> euro </span>
              {{'PER_PASSENGERS'|translate}}
            </button>
            <mat-menu #perPax="matMenu">
              <div mat-menu-item *ngIf="!data.recommendation.lowCost && data.recommendation.paxFare && !data.userSettings.useMarkup && data.recommendation.paxFare.adt">
                {{'ADULT'|translate}}:
                Tot: {{data.recommendation.paxFare.adt.amount|number:'.2-2'}}€ |
                Tax: {{data.recommendation.paxFare.adt.tax|number:'.2-2'}}€
              </div>
              <div mat-menu-item *ngIf="!data.recommendation.lowCost && data.recommendation.paxFare && !data.userSettings.useMarkup && data.recommendation.paxFare.chd">
                {{'CHILDREN'|translate}}:
                Tot: {{data.recommendation.paxFare.chd.amount|number:'.2-2'}}€ |
                Tax: {{data.recommendation.paxFare.chd.tax|number:'.2-2'}}€
              </div>
              <div mat-menu-item *ngIf="!data.recommendation.lowCost && data.recommendation.paxFare && !data.userSettings.useMarkup && data.recommendation.paxFare.inf">
                {{'INFANT'|translate}}:
                Tot: {{data.recommendation.paxFare.inf.amount|number:'.2-2'}}€ |
                Tax: {{data.recommendation.paxFare.inf.tax|number:'.2-2'}}€
              </div>
            </mat-menu>
            <!--
            <button mat-stroked-button color="primary" *ngIf="!data.recommendation.rates" (click)="askFares(data.recommendation.ref)">{{'ASK_TARIFFES'|translate}}</button>

            <button mat-stroked-button (click)="searchDates(data.recommendation.ref)">{{'SEARCH_THIS_DATES_FARES'|translate}}</button>
            -->
            <button mat-stroked-button (click)="toggleShowFare()" *ngIf="data.recommendation.rates && showFares">
              <span class="material-icons"> flight_takeoff </span>
              {{'SELECT_FLIGHTS'|translate}}
            </button>

            <button mat-stroked-button (click)="toggleShowFare()" *ngIf="data.recommendation.rates && data.recommendation.rates.length>0 && !showFares">
              <span class="material-icons"> local_offer </span>
              {{'SELECT_FARES'|translate}}
            </button>
            <button [disabled]="checkPolicy(data.recommendation.tariff)" mat-raised-button color="primary"
                    *ngIf="data.recommendation.rates && data.recommendation.rates.length > 0" (click)="openDialog(data.recommendation.ref)">
              {{'PLACE_RESERVATION'|translate}}
            </button>
            <app-policy-icon-inmodal [isB2B]="data.isB2B" [isSbt]="data.isSbt" [arrayOfAlerts]="data.recommendation.arrayOfAlerts"></app-policy-icon-inmodal>
          </div>
        </div>
      </div>
    </div>

    <div class="faresResults" *ngIf="showFares" @listItemAnimation>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let fare of data.recommendation.rates;let f = index;">
          <div class="uiFare" [ngClass]="{'selectedRate':fare.selected}">
            <span class="material-icons absoluteCheck" *ngIf="fare.selected"> radio_button_checked </span>
            <span (click)="selectFare(f)" class="pointer material-icons absoluteCheck" *ngIf="!fare.selected"> radio_button_unchecked </span>
            <div class="fareNameNew">
              <span *ngFor="let class of fare.details;let l = last;">
                <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
              </span>
              <app-policy-icon-inmodal [isB2B]="data.isB2B" [isSbt]="data.isSbt" [arrayOfAlerts]="fare.arrayOfAlerts"></app-policy-icon-inmodal>
            </div>
            <div class="dialogFareNew">
              <div class="bagInfo">
                <div *ngIf="fare.passengers.ADT">
                  <span class="material-icons"> work </span>
                  {{'BAG_FOR_ADULT'|translate}}:
                  <span *ngFor="let bag of fare.passengers.ADT.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                    {{bag.allowance}}
                    <span *ngIf="bag.allowance < 10"> BAG </span>
                  <span *ngIf="bag.allowance >= 10"> Kg </span>
                </span>
                </div>
                <div *ngIf="fare.passengers.CHD">
                  <span class="material-icons"> work </span>
                  {{'BAG_FOR_CHILD'|translate}}:
                  <span *ngFor="let bag of fare.passengers.CHD.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                    {{bag.allowance}}
                    <span *ngIf="bag.allowance < 10"> BAG </span>
                  <span *ngIf="bag.allowance >= 10"> Kg </span>
                </span>
                </div>
                <div *ngIf="fare.passengers.INF">
                  <span class="material-icons"> work </span>
                  {{'BAG_FOR_INFANT'|translate}}:
                  <span *ngFor="let bag of fare.passengers.INF.baggage; let i = index;let l = last">
                  <span *ngIf="l && i != 0"> - </span>
                    {{bag.allowance}}
                    <span *ngIf="bag.allowance < 10"> BAG </span>
                  <span *ngIf="bag.allowance >= 10"> Kg </span>
                </span>
                </div>
              </div>
              <div class="bottomActions">
              <span *ngIf="!fare.refundable">
                <span class="material-icons greytxt"> cancel </span>
                {{'NO_REFOUND'|translate}}
              </span>
                <span *ngIf="fare.refundable">
                <span class="material-icons greentxt"> check_circle </span> {{'YES_REFOUND'|translate}}
              </span>
                <span class="bottomInfoBtn" (click)="openBottomSheet(fare)"> {{'FARE_INFO'|translate}} </span>
                <span class="bottomInfoBtn" (click)="openDialogRules(fare, data.recommendation.ref)"> {{'PENALTIES'|translate}} </span>
                <span class="material-icons yellow-text" matTooltip="{{'NEGOTIATED_FARE'|translate}}" *ngIf="fare.negotiated && !isB2B"> star </span>
                <span class="material-icons yellow-text" matTooltip="{{'DISCOUNT_FARE'|translate}}" *ngIf="fare.negotiated && isB2B"> star </span>
              </div>
              <div class="farePrice" (click)="selectFare(f)">
              <span *ngIf="!fare.negotiated" class="">
                {{priceservice.returnPrice(fare.fare.amount)|number:'.2-2'}} €
              </span>
                <span *ngIf="!isB2B && fare.negotiated">
                <span *ngIf="fare.negotiated && priceservice.isNegoHidden()" class="">
                  {{priceservice.returnPrice(fare.fare.publicAmount)|number:'.2-2'}} €
                </span>
                <span *ngIf="fare.negotiated && !priceservice.isNegoHidden()" class="">
                  {{priceservice.returnPrice(fare.fare.amount + fare.fare.fees)|number:'.2-2'}} €
                </span>
              </span>
                <span *ngIf="isB2B && fare.negotiated">
                <span class="">
                  <del>{{priceservice.returnPrice(fare.fare.publicAmount)|number:'.2-2'}} €</del>
                  <span class="material-icons"> arrow_right_alt </span>
                  {{priceservice.returnPrice(fare.fare.publicAmount - fare.fare.fees)|number:'.2-2'}} €
                </span>
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="travelDetails" *ngIf="!showFares" @listItemAnimation>
      <div class="mb-3" *ngFor="let itinerary of data.recommendation.itineraries; let i = index;let la = last;">
        <div [ngClass]="{'nextExist':i<data.recommendation.itineraries.length && i!=data.recommendation.itineraries.length-1}">
          <!--
          <div class="introItinerary">
            <p *ngIf="itinerary.sections.length>0" >
              {{data.searchResults.itineraries[i].sections[0].flights[0].departure.date|date:'EEE dd MMM yyyy'}}
            </p>
            {{iataComplete.returnAirport(data.searchResults.itineraries[i].sections[0].flights[0].departure.location)}}
            <sup>{{data.searchResults.itineraries[i].sections[0].flights[0].departure.location}}</sup>
            <mat-icon>arrow_right_alt</mat-icon>
            {{iataComplete.returnAirport(data.searchResults.itineraries[i].sections[0].flights[data.searchResults.itineraries[i].sections[0].flights.length-1].arrival.location)}}
            <sup>{{data.searchResults.itineraries[i].sections[0].flights[data.searchResults.itineraries[i].sections[0].flights.length-1].arrival.location}}</sup>
            <div class="flightCabin marginbottom10">
              <span *ngIf="data.recommendation.cabins[i] === 'Y'">Economic</span>
              <span *ngIf="data.recommendation.cabins[i]=='M'">Economic standard</span>
              <span *ngIf="data.recommendation.cabins[i]=='W'">Economic premium</span>
              <span *ngIf="data.recommendation.cabins[i]=='C'">Business</span>
              <span *ngIf="data.recommendation.cabins[i]=='F'">First</span>
            </div>
          </div> -->

          <div class="row" *ngFor="let section of itinerary.sections; let s = index; let z = last">
            <div class="col-12 collapse show" id="itinerary{{i}}{{s}}">
              <div class="row">
                <div class="col-sm-1 d-sm-inline-block d-none text-center">
                  <img [matTooltip]="iataComplete.returnCarrier(data.recommendation.marketingCarrier)" class="marketingcarrierimage"
                       src="assets/img/airline/icon/{{data.recommendation.marketingCarrier}}.png"/>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(data.searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.location)}}<br/>
                  {{data.searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.dateTime|date:'dd/MM/yyyy - HH:mm':'+0000'}}
                  <!--
                  {{returnZeroTime(data.searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.hours)}}:
                  {{returnZeroTime(data.searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.minutes)}}
                  -->
                </div>
                <div class="col-3">
                  <div>
                    {{returnTime(data.searchResults.itineraries[i].sections[section.ref-1].flyingTime)}}
                  </div>
                  <div>
                    <span *ngIf="data.searchResults.itineraries[i].sections[section.ref-1].flights.length>1">
                      {{data.searchResults.itineraries[i].sections[section.ref-1].flights.length-1}} {{'STOPS'|translate}}
                    </span>
                    <span *ngIf="data.searchResults.itineraries[i].sections[section.ref-1].flights.length==1"> {{'NON_STOPS'|translate}} </span>
                  </div>
                </div>
                <div class="col-3">
                  {{iataComplete.returnAirport(data.searchResults.itineraries[i].sections[section.ref-1].flights[data.searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.location)}}<br/>
                  {{data.searchResults.itineraries[i].sections[section.ref-1].flights[data.searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.dateTime|date:'dd/MM/yyyy - HH:mm':'+0000'}}
                  <!--
                  {{returnZeroTime(data.searchResults.itineraries[i].sections[section.ref-1].flights[data.searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.hours)}}
                  {{returnZeroTime(data.searchResults.itineraries[i].sections[section.ref-1].flights[data.searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.minutes)}}
                  -->
                </div>
                <div class="col-3 col-sm-2">
                  <button mat-icon-button (click)="opeSect(i,s)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <mat-checkbox class="marginleft5 align-bottom"
                                [disabled]="selectionFunction(data.recommendation.ref,i,itinerary.sections[s].ref)"
                                (ngModelChange)="changeSelection(data.recommendation.ref,i,itinerary.sections[s].ref)"
                                [ngModel]="(data.recommendation) ? selectionFunction(data.recommendation.ref,i,itinerary.sections[s].ref) : false">
                    <span>{{'SELECT'|translate}}</span>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="col-12 collapse issection" id="collapse{{i}}{{s}}">
              <div class="row" *ngFor="let flight of data.searchResults.itineraries[i].sections[section.ref-1].flights; let f = index;let fl = last;">
                <div class="col-12 mb-2" *ngIf="f===0">
                  <div class="row">
                    <div class="col-1 text-center">
                      <img class="marketingcarrierimage" src="assets/img/airline/icon/{{data.recommendation.marketingCarrier}}.png"/>
                    </div>
                    <div class="col-9 align-self-center">
                      <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                      <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                      <div class="md-subhead">{{returnTime(data.searchResults.itineraries[i].sections[section.ref-1].flyingTime)}}</div>
                    </div>
                    <div class="col-2">
                      <button mat-icon-button (click)="opeSect(i,s)">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                      <mat-checkbox class="marginleft5 align-bottom"
                                    [disabled]="selectionFunction(data.recommendation.ref,i,itinerary.sections[s].ref)"
                                    (ngModelChange)="changeSelection(data.recommendation.ref,i,itinerary.sections[s].ref)"
                                    [ngModel]="(data.recommendation) ? selectionFunction(data.recommendation.ref,i,itinerary.sections[s].ref) : false">
                        <span>{{'SELECT'|translate}}</span>
                      </mat-checkbox>
                    </div>
                  </div>
                  <hr/>
                </div>
                <div class="col-md-1 text-center">
                  <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                </div>
                <div class="col-md-11">
                  <div class="flightdet">
                    <p class="headingseg">
                      {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                      {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                    </p>
                    <p class="headingdetails">
                      <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                      <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
                    </p>
                    <p class="headingseg">
                      <span class="redt" *ngIf="otherday(data.searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.date | date:'dd',flight.arrival.date | date:'dd')">
                        {{flight.arrival.date | date:'dd MMM yyyy'}} -
                      </span>
                      {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                      {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                    </p>
                    <p class="fldetails">
                      {{flight.operatingCarrier}} {{flight.number}}
                      <span *ngIf="flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                      <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
                    </p>
                  </div>
                  <div class="scalepause" *ngIf="!fl">
                    <span>
                      <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                      <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} <!-- ({{flight.waitingTime}}ms) --> </span>
                    </span>
                    <span class="changeairport" *ngIf="flight.arrival.location != data.searchResults.itineraries[i].sections[section.ref-1].flights[f+1].departure.location">
                      {{'CHANGE'|translate}}:
                      {{data.searchResults.itineraries[i].sections[section.ref-1].flights[f+1].departure.location}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="!z">
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="searchDates(data.recommendation.ref)">{{'SEARCH_THIS_DATES'|translate}}</button>
  <button mat-button mat-dialog-close>{{'CLOSE'|translate}}</button>
</mat-dialog-actions>
