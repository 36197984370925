export const environment = {
  production: false,
  apiUrl: 'https://api.flyleo.it/api/v2/',
  apiroot: 'http://localhost:3000/api/v1/',
  isB2b: true,
  isSbt: false,
  isTest: false,
  isGat: false,
  apiTrain: 'https://api.flyleo.it/api/v2/',
  manifestSrc: 'manifests/b2bmanifest.webmanifest',
  apiB2c: 'https://api.b2ctravel.it/api/v2/b2c/'
};
