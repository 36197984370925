import { Component, OnInit, Input } from '@angular/core';
import {MainComponent} from '../../../../mainmodule/main/main.component';
import {PriceService} from '../../../../../services/price/price.service';
import {FlightService} from '../../../../../services/flight/flight.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {IatacompleteService} from '../../../../../services/iatacomplete/iatacomplete.service';
import { HttpserviceService } from '../../../../../services/httpservice.service';

@Component({
  selector: 'app-print-reservation',
  templateUrl: './print-reservation.component.html',
  styleUrls: ['./print-reservation.component.scss']
})
export class PrintReservationComponent implements OnInit {

  @Input() reservation: any;
  @Input() passengers: any;
  @Input() fares: any;
  @Input() mainComponent: any;

  showTicket: boolean;
  showNotes: any;
  reservationNotes: any;
  showPrice: boolean;
  changePrice: boolean;
  priceChange: number;
  loading: boolean;
  flights: any[];
  canBookFare: any;

  constructor(
    public priceservice: PriceService,
    private flightService: FlightService,
    private snackBar: MatSnackBar,
    public iataComplete: IatacompleteService,
    private httpservice: HttpserviceService,
  ) {
    this.showTicket = false;
    this.showNotes = '';
    this.reservationNotes = '';
    this.showPrice = true;
    this.changePrice = false;
    this.priceChange = null;
    this.loading = true;
  }

  ngOnInit(): void {
    this.loading = false;
    this.reservation.passengers = this.passengers;
    if (this.reservation.tariff && this.reservation.tariff.negotiated) {
      this.reservation.negotiated = true;
    } else {
      this.reservation.negotiated = false;
    }
    console.log('RESERVATION TO PRINT => ', this.reservation);
    this.reservation.company.image = this.httpservice.oldApiUrl + 'static/company/' + this.reservation.company._id + '.png?time=' + new Date().getTime();
  }



  isLowPrice(value) {
    return this.priceChange < value;
  }

dateNow(){
    return new Date();
}

  print(): void {
    let printContents;
    let popupWin;
    printContents = document.getElementById('toPrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=900px,width=1200px');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
          <title>Flight-Ticket</title>
          <style>
            #toPrint{
                font-size:12px;
                padding:10px;
                border:1px solid #ccc;
                background-color:#fff;
                max-width:1000px;
                margin:auto;
            }
            .companyImage{
              max-height:85px;
              max-width:200px;
            }
            .reservationimage img{
                width:30px;
                display:inline-block;
                border-radius:50%;
                border:1px solid #ccc;
                margin:0 5px 0 0;
                vertical-align: middle;
            }
            .material-icons{
                vertical-align: middle;
            }
            .pnrdeparture{
              font-size:11px;
              color:#999;
            }
            table td{
                width:25%;
            }
            table td.infoIti{
              background-color:#eee;
            }
            .operatingImage{
                width:30px;
                border-radius: 50%;
                border:1px solid #ccc;
            }
            .backPrint{
                padding:10px;
            }
            .maincabin{
              font-size: 10px;
              text-transform:uppercase;
            }
            .infoTar .material-icons, .infoBag .material-icons{
                font-size:18px;
                margin-right:5px;
                vertical-align: middle;;
            }
            .fakeTable{
              border:1px solid #ccc;
              padding:10px;
              font-size:11px;
            }
            .fakeTable hr{
              margin:5px 0;
            }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }


  prnRetrieve() {
    this.flightService.pnrRetrieve(this.reservation._id).subscribe((res) => {
      console.log('Retrieve => ', res);
      this.flights = res.flights;
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  calculateWaiting(arrival, departure) {
    const a = new Date(arrival).getTime();
    const b = new Date(departure).getTime();
    return this.returnTime(b - a);
  }

  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }

  returnZeroTime(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }


  returnMilli(data) {
    return this.returnTime(new Date(data).getTime());
  }

  returnDate(value){
    const date = new Date(value)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const final = new Date(date.getTime() + userTimezoneOffset);
    return final;
  }

  convertMilliseconds(det) {
    const ms = det.to - det.from;
    // Un'ora ha 3,600,000 millisecondi
    const hours = Math.floor(ms / 3600000);
    // Un minuto ha 60,000 millisecondi
    const minutes = Math.floor((ms % 3600000) / 60000);

    // Ritorna la durata in formato 'ore minuti'
    return `${hours}h ${minutes}m`;
  }

  checkTechnicalStops(flights){
    let count = 0;
    flights.forEach((f:any) => {
      if(f.detail.technicalStop && f.detail.technicalStop.from > 0){
        count += 1;
      }
    });
    return count;
  }
}
