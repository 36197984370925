<custom-title></custom-title>
<div class="padding20">
<!--
  <app-searchform *ngIf="showForm" (updatelow)="resultChangeLow($event)" (updatelowNDC)="resultChangeLowNDC($event)" (updateTo)="resultChangeTo($event)" (update)="resultChange($event)" (reset)="createForm()" (updateReq)="requestChange($event)" (updateSelTo)="resultSelToChange($event)" (updateSel)="resultSelChange($event)" (updateSelLow)="resultSelChangeLow($event)" (updateSelLowNDC)="resultSelChangeLowNDC($event)" [selectionsLow]="selectionsLow" [selectionsLowNDC]="selectionsLowNDC" [selectionsTo]="selectionsTo" [searchResultsTo]="searchResultsTo" [selections]="selections" [searchResults]="searchResults" [request]="request" [singleSection]="singleSection"></app-searchform>

-->

  <app-newsearchform *ngIf="showForm"
    (updatelow)="resultChangeLow($event)"
    (updatendcama)="resultChangeNdcAma($event)"
    (updatelowNDC)="resultChangeLowNDC($event)"
    (updateTo)="resultChangeTo($event)"
    (update)="resultChange($event)"
    (reset)="createForm()"
    (updateReq)="requestChange($event)"
    (updateSelTo)="resultSelToChange($event)"
    (updateSel)="resultSelChange($event)"
    (updateSelLow)="resultSelChangeLow($event)"
    (updateSelNdcAma)="resultSelChangeNdcAma($event)"
    (updateSelLowNDC)="resultSelChangeLowNDC($event)"
    [selectionsLow]="selectionsLow"
    [selectionsLowNDC]="selectionsLowNDC"
    [selectionsTo]="selectionsTo"
    [searchResultsTo]="searchResultsTo"
    [selectionsNdcAma]="selectionsNdcAma"
    [searchResultsNdcAma]="searchResultsNdcAma"
    [selections]="selections"
    [searchResults]="searchResults"
    [request]="request"
    [singleSection]="singleSection">
  </app-newsearchform>



  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [color]="'accent'" selectedIndex="0" *ngIf="((searchResults && searchResults.itineraries ) || (searchResultsLow && searchResultsLow.itineraries) || (searchResultsLowNDC && searchResultsLowNDC.itineraries)) && !request.options.calendar">
    <mat-tab *ngIf="searchResults && searchResults.itineraries">
      <ng-template mat-tab-label>
        Linea&nbsp;
        <span class="ml-2" *ngIf="!searchResults || !searchResults.itineraries"> Loading...</span>
        <span *ngIf="searchResults && searchResults.itineraries">
          <span>{{'FROM'|translate}}</span>
          <span class="ml-2 mr-2" *ngIf="!searchResults.recommendations[0].negotiated">
            {{searchResults.recommendations[0].fare.amount|number:'.2-2'}}€
          </span>
          <span class="ml-2 mr-2" *ngIf="searchResults.recommendations[0].negotiated">
            {{searchResults.recommendations[0].fare.publicAmount || searchResults.recommendations[0].fare.amount |number:'.2-2'}}€
          </span>
          <!-- <span class="smalltext"> ({{searchResults.recommendations.length}})</span> -->
        </span>
      </ng-template>
      <div *ngIf="searchResults && searchResults.itineraries && !appComponent.stepSearch && iataComplete.carriers && !request.options.calendar">
        <app-searchresults [lowCost]="false" [selections]="selections" [searchResults]="searchResults" [request]="request"></app-searchresults>
      </div>
      <div style="overflow-x:hidden;" *ngIf="searchResults && searchResults.itineraries && appComponent.stepSearch && iataComplete.carriers && !request.options.calendar">
        <app-stepsearchresultscp [selections]="selections" [searchResults]="searchResults" [request]="request"></app-stepsearchresultscp>
      </div>
    </mat-tab>

    <mat-tab *ngIf="searchResultsNdcAma && searchResultsNdcAma.itineraries">
      <ng-template mat-tab-label>
        Ndc Amadeus&nbsp;
        <span class="ml-2" *ngIf="!searchResultsNdcAma || !searchResultsNdcAma.itineraries"> Loading...</span>
        <span *ngIf="searchResultsNdcAma && searchResultsNdcAma.itineraries">
          <span>{{'FROM'|translate}}</span>
          <span class="ml-2 mr-2"> {{searchResultsNdcAma.recommendations[0].fare.amount|number:'.2-2'}}€ </span>
          <!-- <span class="smalltext"> ({{searchResultsTo.recommendations.length}})</span> -->
        </span>
      </ng-template>
      <div *ngIf="request && searchResultsNdcAma && searchResultsNdcAma.itineraries && iataComplete.carriers && !request.options.calendar">
        <app-searchresults [tourOp]="false" [lowCost]="false" [ndcAma]="true" [selections]="selectionsNdcAma" [searchResults]="searchResultsNdcAma" [request]="request"></app-searchresults>
      </div>
    </mat-tab>

    <mat-tab *ngIf="searchResultsTo && searchResultsTo.itineraries">
      <ng-template mat-tab-label>
        Tour Operator&nbsp;
        <span class="ml-2" *ngIf="!searchResultsTo || !searchResultsTo.itineraries"> Loading...</span>
        <span *ngIf="searchResultsTo && searchResultsTo.itineraries">
          <span>{{'FROM'|translate}}</span>
          <span class="ml-2 mr-2" *ngIf="!searchResultsTo.recommendations[0].negotiated">
            {{searchResultsTo.recommendations[0].fare.amount|number:'.2-2'}}€
          </span>
          <span class="ml-2 mr-2" *ngIf="searchResultsTo.recommendations[0].negotiated">
            {{searchResultsTo.recommendations[0].fare.publicAmount || searchResultsTo.recommendations[0].fare.amount |number:'.2-2'}}€
          </span>
          <!-- <span class="smalltext"> ({{searchResultsTo.recommendations.length}})</span> -->
        </span>
      </ng-template>
      <div *ngIf="searchResultsTo && searchResultsTo.itineraries && iataComplete.carriers && !request.options.calendar">
        <app-searchresults [tourOp]="true" [lowCost]="false" [selections]="selectionsTo" [searchResults]="searchResultsTo" [request]="request"></app-searchresults>
      </div>
    </mat-tab>

    <mat-tab *ngIf="searchResultsLow && searchResultsLow.itineraries && searchResultsLow.itineraries.length > 0">
      <ng-template mat-tab-label>
        LowCost {{'FROM'|translate}}
        <span class="ml-2 mr-2">
          <span *ngIf="!appComponent.isB2B && !appComponent.isSbt && !appComponent.isGat">
            {{searchResultsLow.recommendations[0].fare.amount+6|number:'.2-2'}}
          </span>
          <span *ngIf="appComponent.isB2B || appComponent.isSbt || appComponent.isGat">
            {{searchResultsLow.recommendations[0].fare.amount+6|number:'.2-2'}}
          </span>
          <span *ngIf="searchResultsLow.recommendations[0].fare.currency === 'EUR'"> €</span>
         <span *ngIf="searchResultsLow.recommendations[0].fare.currency !== 'EUR'">
           {{searchResultsLow.recommendations[0].fare.currency}}
         </span>
        </span>
        <!-- <span class="smalltext"> ({{searchResultsLow.recommendations.length}})</span> -->
      </ng-template>
      <div *ngIf="searchResultsLow && searchResultsLow.itineraries && iataComplete.carriers && !request.options.calendar">
        <app-searchresults [lowCost]="true" [selections]="selectionsLow" [searchResults]="searchResultsLow" [request]="request"></app-searchresults>
      </div>
    </mat-tab>
    <mat-tab *ngIf="searchResultsLowNDC && searchResultsLowNDC.itineraries && searchResultsLowNDC.itineraries.length > 0">
      <ng-template mat-tab-label>
        NDC {{'FROM'|translate}}
        <span class="ml-2 mr-2"> {{searchResultsLowNDC.recommendations[0].fare.amount|number:'.2-2'}}
          <span *ngIf="searchResultsLowNDC.recommendations[0].fare.currency === 'EUR'">€</span>
         <span *ngIf="searchResultsLowNDC.recommendations[0].fare.currency !== 'EUR'">{{searchResultsLowNDC.recommendations[0].fare.currency}}</span>
        </span>
        <!-- <span class="smalltext"> ({{roundNum(searchResultsLowNDC.recommendations.length/3)}})</span> -->
      </ng-template>
      <div *ngIf="searchResultsLowNDC && searchResultsLowNDC.itineraries && iataComplete.carriers && !request.options.calendar">
        <app-searchresults [lowCost]="true" [selections]="selectionsLowNDC" [searchResults]="searchResultsLowNDC" [request]="request" [ndc]="true"></app-searchresults>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="searchResults && searchResults.itineraries && iataComplete.carriers && request.options.calendar">
    <app-calendarresults (updateSearch)="startNewSearch($event)" [selections]="selections" [searchResults]="searchResults" [request]="request"></app-calendarresults>
  </div>
</div>
