import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hotel-card',
  templateUrl: './hotel-card.component.html',
  styleUrls: ['./hotel-card.component.scss']
})
export class HotelCardComponent implements OnInit {
  productDetails:any;
  @Input() search:any;
  constructor() { }

  ngOnInit(): void {
    this.productDetails = this.search.productDetails;
    console.log(this.search);
  }


}
