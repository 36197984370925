<div *ngIf="bookingDb" class="row">
  <div *ngIf="bookingDb else reservationLoading" class="col-12 col-lg-12 my-2" style="margin-bottom: 15px;">
    <!--<strong>{{title | translate}}</strong>-->
    <div *ngFor="let segment of bookingDb.itineraries; let i = index" class="row train-ticket mt-4">
      <div class="col-12 my-0 mx-0 train-ticket-header">
        <mat-icon>train</mat-icon>
        <span>{{'TRAIN_MODULE.AFTERSALE.TICKET' | translate}}</span>
      </div>
      <div class="col-12 col-lg-8 train-ticket-itinerary ">
        <div class="row mb-1">
          <div class="col">
            <div class="row mb-1">
              <div class="col text-left">
                {{'TRAIN_MODULE.TRAIN_DEPARTURE' | translate}}
                <h6>{{segment?.from.station}}</h6>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col text-left">
                {{'DATE' | translate}}
                <h6 *ngIf="segment?.from.date else noData">{{(segment?.from.date | date:'dd/MM/yyyy')}}</h6>
              </div>
              <div class="col ">
                {{'TIME' | translate}}
                <h6 *ngIf="segment?.from.date else noData">{{(segment?.from.date | date:'HH:mm')}}</h6>
              </div>
            </div>
          </div>
          <div class="col ml-3">
            <div class="row mb-1">
              <div class="col text-left">
                {{'TRAIN_MODULE.TRAIN_ARRIVAL' | translate}}
                <h6>{{segment?.to.station}}</h6>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col text-left" style="border-left: thin lightgray">
                {{'DATE' | translate}}
                <h6 *ngIf="segment?.to.date else noData">{{(segment?.to.date | date:'dd/MM/yyyy')}}</h6>
              </div>
              <div class="col ">
                {{'TIME' | translate}}
                <h6 *ngIf="segment?.to.date else noData">{{(segment?.to.date | date:'HH:mm')}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div *ngIf="segment.trainClassification" class="col text-left">
            {{'TRAIN_MODULE.TRAIN' | translate}}
            <h6>{{segment.trainClassification}} {{segment.trainNumber}}</h6>
          </div>
          <div class="col ">
            {{'TRAIN_MODULE.CABIN' | translate}}
            <h6>{{segment.bookingClass}}</h6>
          </div>
          <div class="col ">
            {{'TRAIN_MODULE.TRENIT.OFFER' | translate}}
            <h6>{{segment.fareType}}</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-4 train-ticket-pax">
        <div class="mb-2">{{'TRAIN_MODULE.PASSENGERS' | translate}}</div>
        <div *ngFor="let pax of bookingDb.travellers" class="row">
          <div class="col-12">
            <div class="row" style="font-weight: 500">
              - {{pax.doc?.name | uppercase }} {{pax.doc?.surname | uppercase}}
            </div>
            <div *ngIf="pax.seats && pax.seats[i]" class="row pl-2">
              <div class="col">
                {{ 'TRAIN_MODULE.TRAIN' | translate }}
                <h6>{{ pax.seats[i].wagon }}</h6>
              </div>
              <div class="col">
                {{ 'SEAT' | translate }}
                <h6>{{ pax.seats[i].raw }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noData><h6> _ </h6></ng-template>
    <div class="text-right p-2">
      {{'TRAIN_MODULE.TOTAL_PRICE' | translate}}
      <span class="totalPrice">{{bookingDb.ratedPrice| number:'.2-2'}} €</span>
    </div>
  </div>
  <ng-template #reservationLoading>
    <mat-card-content>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
  </ng-template>
</div>
