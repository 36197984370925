<custom-title></custom-title>
<div class="maincontent">
  <div class="padding20">
    <mat-card appearance="outlined" class="p-3">
      <div class="create-policy">
        <div class="">
          <div class="">
            <div class="row">
              <div class="col-6 d-flex align-items-center justify-content-start">
                <div *ngIf="!defaultUserLoading">
                  <span style="font-size: 20px!important;font-weight: bolder"> Default user: </span>
                  <span style="font-size: 18px!important;"> {{defaultUser ? (defaultUser.name + ' ' + defaultUser.surname) : 'NO DEFAULT USER ASSIGNED'}} </span>
                </div>
                <span *ngIf="defaultUserLoading"> Default user: Loading...</span>
                <button class="miniIcons ml-2" mat-icon-button color="primary" (click)="openSelectDefaultUserDialog()">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
              <div class="col-6 d-flex align-self-center justify-content-end buttonrowBis">
                <button (click)="createPolicyDialog()" color="primary"  mat-stroked-button> {{'POLICY.NEW_POLICY'|translate}} </button>
              </div>
            </div>
          </div>
          <div>
            <div class="create-policy__spinner">
              <mat-spinner *ngIf="isLoading"></mat-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <!-- Nuova Tabella Unificata con quella dei Gruppi -->
              <!--{{policyList | json}}-->
              <table *ngIf="policyList.length > 0; else noData" mat-table [dataSource]="dataSource" class="w-100 policiesList">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> {{'NAME'|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef> {{'ROLE'|translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.group.name}} </td>
                </ng-container>

                <ng-container matColumnDef="buttons">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <div class="d-flex align-self-center justify-content-end">
                      <button class="miniIcons" matTooltip="{{'POLICY.EDIT'|translate}}" (click)="editPolicy(element._id, i)" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button class="miniIcons" matTooltip="{{'POLICY.CONFIG'|translate}}" [routerLink]="['/policy/conditions', element._id]" mat-icon-button>
                        <mat-icon >settings</mat-icon>
                      </button>
                      <button class="miniIcons" color="warn" matTooltip="{{'POLICY.DELETE'|translate}}" (click)="deletePolicy(element._id, i)" mat-icon-button>
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </ng-container>
            <mat-paginator [pageSizeOptions]="[5,10]"></mat-paginator>

            <ng-template #noData>
              <div class="no-data-container">
                <h2>No data found.</h2>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

