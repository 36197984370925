import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersGroupBulkComponent } from './users-group-bulk/users-group-bulk.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SelectedUsersGroupComponent } from './selected-users-group/selected-users-group.component';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {CdkTableModule} from '@angular/cdk/table';
import {MatCardModule} from "@angular/material/card";
import {TranslateModule} from "@ngx-translate/core";
import {CustomCommonModule} from "../common/custom-common.module";
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [
    UsersGroupBulkComponent,
    SelectedUsersGroupComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatSortModule,
    MatCardModule,
    FormsModule,
    TranslateModule,
    CustomCommonModule,
    MatTooltipModule
  ]
})
export class UsersGroupBulkModule { }
