<form [formGroup]="form">
  <div class=""><!--form-container-->
    <div class="d-flex align-self-center justify-content-center">
      <mat-form-field appearance="outline">
        <mat-label>{{'NAME'|translate}}</mat-label>
        <input matInput formControlName="name" (input)="checkIfNameExist()">
        <mat-error *ngIf="form.get('name').hasError('required')">
          {{'POLICY.GROUP_REQUIRED_'|translate}} <strong>{{'POLICY.REQUIRED'|translate}}</strong>
        </mat-error>
        <mat-error *ngIf="form.get('name').hasError('nameAlreadyExist')">
          {{'POLICY.GROUP_ALREADY_EXIST'|translate}}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="buttonrowBis d-flex align-self-center justify-content-end">
      <button mat-stroked-button (click)="close.emit()">{{'POLICY.CANCEL'|translate}}</button>
      <button *ngIf="!updating" [disabled]="checkErrors()" mat-raised-button color="primary" (click)="handleSubmit()">{{'SAVE'|translate}} </button>
      <button *ngIf="updating" [disabled]="checkErrors()" mat-raised-button color="primary" (click)="handleUpdate()">{{'UPDATE'|translate}}</button>
    </div>
  </div>
</form>
