
<div *ngIf="loading"  class="padding20">
  <div class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>
</div>

<div class="padding20" *ngIf="!loading && !hotelInfos && loadedFav">
  <div class="mb-3">Struttura non disponibile in queste date</div>
  <div class="searchCard mb-2">
    <div class="searchTitle">
      Cerca in altre date
    </div>
    <hr/>
    <div class="formContent">
      <!--
        <div class="mb-1">
          <div class="searchLabel">Tipo di camera</div>
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select>
                <mat-option *ngFor="let room of filteredRoomList; let i = index" [value]="i">
                <span class="text-uppercase small">
                  {{room.amount|number:'.2-2'}} {{room.currency}} - {{room.roomRates[0].text | slice:0:60}}..
                </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        -->

      <div class="mb-1">
        <div class="searchLabel">Quando</div>
        <mat-form-field appearance="outline" class="full-width">

          <mat-date-range-input [rangePicker]="picker" [min]="today">
            <input [(ngModel)]="hotelForm.checkIn" matStartDate placeholder="checkin">
            <input [(ngModel)]="hotelForm.checkOut" matEndDate placeholder="checkout">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div>
        <div class="searchLabel mb-1">Ospiti</div>
        <button (click)="toggleGuestsModal()" class="smallmenu watchFare" mat-button>
          {{'ROOMS'|translate}}: {{hotelForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
        </button>
      </div>
      <hr/>
      <div class="text-center">
        <button (click)="createGuests(true)" class="bookingButton">
          Cerca
          <span class="ml-2 material-icons"> send </span>
        </button>
      </div>

    </div>
  </div>
</div>

<div class="padding20">

  <div *ngIf="!loading && hotelInfos && loadedFav">
    <div class="rowButtonHt mb-3">
      <button class="tabButton" (click)="setActualTab('INFOS')" [ngClass]="{'currentBtnTab':actualTab === 'INFOS'}">
        <span class="material-icons"> apartment </span>
      </button>
      <button class="tabButton" (click)="setActualTab('ROOMS')" [ngClass]="{'currentBtnTab':actualTab === 'ROOMS'}">
        {{'ROOMS'|translate}}
      </button>
      <button class="tabButton" (click)="setActualTab('GUESTS')" [ngClass]="{'currentBtnTab':actualTab === 'GUESTS'}" *ngIf="fareSelected">
        {{'GUESTS'|translate}}
      </button>

      <button class="tabButton" (click)="setActualTab('RIEPILOGO')" *ngIf="fareSelected && travelerController()" [ngClass]="{'currentBtnTab':actualTab === 'RIEPILOGO'}">
        {{'RIEPILOGO'|translate}}
      </button>
    </div>
  </div>

  <div class="row" *ngIf="!loading && hotelInfos && loadedFav">

    <div class="col-xl-8 col-lg-12 mb-2">

      <div class="hotelCard mb-2" *ngIf="actualTab == 'RIEPILOGO' && fareSelected && travelerController()">
        <div *ngIf="requoting">
          <div class="expandRow" [ngClass]="{showInfos:'mb-3'}">{{'RIEPILOGO'|translate}}</div>
          <hr/>
          <div class="text-center">
            <p>Attendi un istante.</p>
            <p>Riquotazione in corso....</p>
          </div>
        </div>

        <div *ngIf="!requoting">
          <div class="expandRow" [ngClass]="{showInfos:'mb-3'}">{{'RIEPILOGO'|translate}}</div>
          <hr/>
          <div>
            <app-room-selected [guests]="guests" [isGat]="appComponent.isGat" [isSbt]="appComponent.isSbt" [isB2B]="appComponent.isB2B"
                               [hotel]="roomSelected.hotel" [room]="roomSelected" [searchRes]="hotelRes" [search]="hotelForm">
            </app-room-selected>
          </div>

          <div>
            <div class="selectedGuests">
              <hr/>
              <div *ngFor="let tra of guests;let i = index" class="mb-2">
                <div class="row">
                  <div class="col-2 guestsIcon text-center">
                    <span class="material-icons"> perm_identity </span>
                    <div class="guestType">
                      {{tra.type|translate}}
                    </div>
                  </div>
                  <div class="col-10 guestsInfo">
                    <div class="guestName mb-2">{{tra.name}} {{tra.surname}}</div>
                    <div class="smalltext mb-1">
                      <span class="material-icons mr-2">phone</span> {{tra.phoneNumber}}
                    </div>
                    <div class="smalltext">
                      <span class="material-icons mr-2">email</span> {{tra.email}}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <hr/>
          <div class="text-center">
            <button class="backBtn mr-3" (click)="setActualTab('GUESTS')">
              <span class="material-icons"> keyboard_arrow_left </span>
              {{'MANAGE_GUESTS'|translate}}
            </button>

            <button class="showRoomsBtn mr-3" (click)="bookHotel()">
              {{'CONFIRM_PROCEED'|translate}}
              <span class="material-icons"> arrow_right_alt </span>
            </button>
          </div>
        </div>

      </div>

      <div class="hotelCard mb-2" *ngIf="allHotels.length > 0 && actualTab == 'ROOMS'">
        <!-- <div class="expandRow" [ngClass]="{showInfos:'mb-3'}">{{'ROOMS'|translate}}</div> -->

        <div *ngIf="roomSelected">
          <app-room-selected [guests]="guests" [isGat]="appComponent.isGat" [isSbt]="appComponent.isSbt" [isB2B]="appComponent.isB2B"
                             [hotel]="roomSelected.hotel" [room]="roomSelected" [searchRes]="hotelRes" [search]="hotelForm"></app-room-selected>
          <div class="mb-3"></div>
          <div class="text-center">

            <button class="backBtnBlue mr-3" (click)="setActualTab('INFOS')">
              <span class="material-icons"> keyboard_arrow_left </span>
              {{'INFOS'|translate}}
            </button>

            <button class="changeRoomBtn mr-3" (click)="removeRoomSelection()">
              {{'CHANGE_ROOM'|translate}}
              <span class="material-icons"> sync_alt </span>
            </button>

            <button class="showRoomsBtn" (click)="setActualTab('GUESTS')">
              {{'MANAGE_GUESTS'|translate}}
              <span class="material-icons"> arrow_right_alt </span>
            </button>
          </div>

        </div>

        <div *ngIf="!roomSelected">
          <div>
            <div (click)="reloadFilteredRooms()" class="row" style="margin:0;">

              <div *ngIf="!appComponent.isSbt && !appComponent.isGat" class="col-md-4">
                <div class="providerFilter">
                  <div class="mb-2">{{'PROVIDER'|translate}}</div>
                  <div (click)="setProvider(provider)" *ngFor="let provider of hotelProviders" class="singleProvider">
                    <span *ngIf="!isCProviderSelected(provider)" class="material-icons"> check_box_outline_blank </span>
                    <span *ngIf="isCProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                    {{returnProvider(provider)}}
                  </div>
                </div>
              </div>

              <div *ngIf="appComponent.isSbt && mainComponent.loggedUser.role.superAdmin" class="col-md-4">
                <div class="providerFilter">
                  <div class="mb-2">{{'PROVIDER'|translate}}</div>
                  <div (click)="setProvider(provider)" *ngFor="let provider of hotelProviders" class="singleProvider">
                    <span *ngIf="!isCProviderSelected(provider)" class="material-icons"> check_box_outline_blank </span>
                    <span *ngIf="isCProviderSelected(provider)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                    {{returnProvider(provider)}}
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="providerFilter">
                  <div class="mb-2">{{'REFOUND'|translate}}</div>
                  <div (click)="setRefound(true)" class="singleProvider">
                    <span *ngIf="!isRefoundSelected(true)" class="material-icons"> check_box_outline_blank </span>
                    <span *ngIf="isRefoundSelected(true)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                    {{'YES_REFOUND'|translate}}
                  </div>
                  <div *ngIf="corporateToFilter" (click)="setRefound(false)" class="singleProvider">
                    <span *ngIf="!isRefoundSelected(false)" class="material-icons"> check_box_outline_blank </span>
                    <span *ngIf="isRefoundSelected(false)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                    {{'NO_REFOUND'|translate}}
                  </div>

                  <div (click)="setCorporate()" *ngIf="corporateToFilter" class="singleProvider greentxt">
                    <span *ngIf="!corporateFilter" class="material-icons"> check_box_outline_blank </span>
                    <span *ngIf="corporateFilter" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                    {{'CORPORATE'|translate}}
                  </div>

                </div>
              </div>

              <div class="col-md-4">
                <div class="providerFilter">
                  <div class="mb-2">{{'BOARD'|translate}}</div>
                  <div (click)="setMeal(meal)" *ngFor="let meal of mealsTypes" class="singleProvider">
                    <span *ngIf="!isMealSelected(meal)" class="material-icons"> check_box_outline_blank </span>
                    <span *ngIf="isMealSelected(meal)" class="material-icons" style="color:cornflowerblue;"> check_box </span>
                    {{meal}}
                  </div>
                </div>
              </div>
            </div>
            <hr/>
          </div>
          <div class="text-center selectRoomBanner">
            <div class="mb-2">
              <span class="material-icons"> done </span>
              <span class="yellow">{{'FOUNDED'|translate}}</span> {{allRooms.length}} {{'TARIFFES'|translate}}
            </div>
            <div class="" *ngIf="filteredRoomList.length < allRooms.length">
              <span class="material-icons"> center_focus_weak </span>
              <span class="yellow">{{'SATISFY_FILTER'|translate}}</span> {{filteredRoomList.length}} {{'TARIFFES'|translate}}
            </div>

            <!--
            <button class="showRoomsBtn" (click)="openRoomsList(this.allHotels[0])">
              {{'SELECT_ROOM'|translate}}
              <span class="material-icons">
                arrow_right_alt
              </span>
            </button>
            -->
          </div>

        </div>
      </div>

      <div class="hotelCard mb-2" *ngIf="allHotels.length > 0 && actualTab == 'ROOMS' && !roomSelected">
        <div class="expandRow" [ngClass]="{showInfos:'mb-3'}">{{'ROOMS'|translate}}</div>
        <hr/>
        <div class="roomsContainer">
          <div *ngFor="let room of filteredRoomList; let i = index">
            <app-single-room-v3 [corporates]="listCorporate" [guests]="guests" [isGat]="appComponent.isGat" [isSbt]="appComponent.isSbt"
                                [isB2B]="appComponent.isB2B" (update)="roomSelect($event)" [hotel]="room.hotel" [i]="i" [room]="room"
                                [searchRes]="hotelRes" [search]="hotelForm"></app-single-room-v3>
          </div>
        </div>
      </div>

      <div class="hotelCard" *ngIf="allHotels.length > 0 && actualTab == 'GUESTS'">
        <div class="expandRow">{{'GUESTS'|translate}}</div>
        <hr/>
        <div *ngIf="roomSelected" class="row">
          <div *ngFor="let tra of guests;let i = index" class="col-lg-6 mb-3">
            <div class="fakefield mb-2">
              {{'TYPE'|translate}}:
              <span> {{tra.type|translate}}</span>
            </div>
            <div class="fakefield mb-2">
              {{'NAME'|translate}}:
              <span *ngIf="tra.name"> {{tra.name}}</span>
              <span *ngIf="!tra.name"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'SURNAME'|translate}}:
              <span *ngIf="tra.surname"> {{tra.surname}}</span>
              <span *ngIf="!tra.surname"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'PHONE'|translate}}:
              <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
              <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'EMAIL'|translate}}:
              <span *ngIf="tra.email"> {{tra.email}}</span>
              <span *ngIf="!tra.email"> _ _ _ _</span>
            </div>

            <div class="buttonrow">
              <button (click)="toggleDialog(i)" *ngIf="!tra.name || !tra.surname" mat-stroked-button>{{'ADD_GUEST'|translate}}</button>
              <button (click)="removeTraveler(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'REMOVE_GUEST'|translate}}</button>
              <button (click)="toggleEditDialog(i)" *ngIf="tra.name || tra.surname" mat-stroked-button>{{'EDIT_GUEST'|translate}}</button>
              <button (click)="loadTravelersList(i)" *ngIf="!tra.name && !tra.surname" mat-stroked-button>{{'LOAD_TRAVELER'|translate}}</button>
            </div>

          </div>
        </div>
        <hr/>
        <div>
          <div class="text-center">

            <button class="backBtn mr-3" (click)="setActualTab('ROOMS')">
              <span class="material-icons"> keyboard_arrow_left </span>
              {{'CHANGE_ROOM'|translate}}
            </button>

            <button *ngIf="travelerController()" class="showRoomsBtn mr-3" (click)="setActualTab('RIEPILOGO')">
              {{'RIEPILOGO'|translate}}
              <span class="material-icons"> arrow_right_alt </span>
            </button>
          </div>
          <div class="pt-2 text-danger" *ngIf="!travelerController()">
            {{'INSERT_GUESTS_DETAILS'|translate}}
          </div>
        </div>
      </div>

      <div class="hotelImages" *ngIf="allImages && allImages.length > 0 && actualTab == 'INFOS'">

        <div class="containerG">
          <lightgallery *ngIf="allImages.length>0" [settings]="settings">

            <a class="gallery-item img-responsive" [ngClass]="{'img-res2': i === 3}"
               [attr.data-sub-html]="allHotels[0].name + ' '+ (i + 1) + ' of ' + allImages.length"
               [attr.data-src]="image" *ngFor="let image of allImages; let i = index"
               [style.background-image]="'url(' + image + ')'">
               <!-- <img class="imgResponsive" [ngClass]="{'img-res2': i === 3}" alt="{{allHotels[0].name}}" [src]="image" /> -->
            </a>

            <div *ngIf="allImages.length>=6" class="moreImage" [attr.data-src]="allImages[5]">
              Altre {{allImages.length-5}} foto
            </div>

          </lightgallery>
        </div>
      </div>

      <div class="mb-3 text-center" *ngIf="allHotels.length > 0 && actualTab == 'INFOS'">
        <button class="showRoomsBtn mr-3" (click)="setActualTab('ROOMS')">
          {{'SELECT_ROOM'|translate}}
          <span class="material-icons"> arrow_right_alt </span>
        </button>
      </div>

      <div class="hotelCard" *ngIf="allHotels.length > 0 && actualTab == 'INFOS'">

        <div class="expandRow" [ngClass]="{showInfos:'mb-3'}">{{'INFOS'|translate}}</div>
        <div>
          <hr/>
          <div *ngFor="let hotel of allHotels">
            <div class="descriptionSingle" *ngFor="let info of hotel.info.descriptions">
              <div class="descriptionInfoTitle mb-1">
                <span *ngIf="!info.additionalDetail">{{info.infoType}}</span>
                <span *ngIf="info.additionalDetail">{{info.additionalDetail}}</span>
              </div>
              <div class="descriptionText" [innerHTML]="info.text"></div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-xl-4 col-lg-12">

      <div class="searchCard mb-3">
        <div class="searchTitle">
          {{allHotels[0].name}}
        </div>
        <hr/>
        <div class="formContent">
          <div class="mb-2" *ngFor="let contact of allHotels[0].contactInfos">
            <div *ngFor="let phone of contact.phoneNumbers">
              {{phone.type}}: {{phone.number}}
            </div>
          </div>

          <div class="hAddress" *ngIf="allHotels[0].address">
            <div class="row actionButs">
              <div class="col-md-9">
                {{allHotels[0].address.city}}, {{allHotels[0].address.zip}}, {{allHotels[0].address.line}}
              </div>
              <div class="col-md-3 text-right">

                <span *ngIf="!isFavorite(form.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)"
                      class="material-icons" style="color: red;" (click)="addToFavorites(allHotels[0])">
                  favorite_border
                </span>
                <span *ngIf="isFavorite(form.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)"
                      class="material-icons" style="color: red;" (click)="removeToFavorites(allHotels[0])">
                  favorite
                </span>

                <span (click)="openDialogMap(allHotels[0])" class="material-icons pointer" style="color:#4d79f6"> location_on </span>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="searchCard mb-2">
        <div class="searchTitle">
          Cerca in altre date
        </div>
        <hr/>
        <div class="formContent">
          <!--
            <div class="mb-1">
              <div class="searchLabel">Tipo di camera</div>
              <div>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select>
                    <mat-option *ngFor="let room of filteredRoomList; let i = index" [value]="i">
                    <span class="text-uppercase small">
                      {{room.amount|number:'.2-2'}} {{room.currency}} - {{room.roomRates[0].text | slice:0:60}}..
                    </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            -->

          <div class="mb-1">
            <div class="searchLabel">Quando</div>
            <mat-form-field appearance="outline" class="full-width">

              <mat-date-range-input [rangePicker]="picker" [min]="today">
                <input [(ngModel)]="hotelForm.checkIn" matStartDate placeholder="checkin">
                <input [(ngModel)]="hotelForm.checkOut" matEndDate placeholder="checkout">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <div>
            <div class="searchLabel mb-1">Ospiti</div>
            <button (click)="toggleGuestsModal()" class="smallmenu watchFare" mat-button>
              {{'ROOMS'|translate}}: {{hotelForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
            </button>
          </div>
          <hr/>
          <div class="text-center">
            <button (click)="createGuests(true)" class="bookingButton">
              Cerca
              <span class="ml-2 material-icons"> send </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="addTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-add [hotel]="true" (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [newTraveler]="guests[currentTraveler]"
                 [parent]="'searchResults'"></app-add>
      </div>

    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="listTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div *ngIf="loadTravelers" class="modal-content">

      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-travelerslist (close)="closeListEvent($event)" [hotel]="true" (setTraveler)="travelerSet($event, currentTraveler)"
                           [newTraveler]="guests[currentTraveler]" [page]="'searchResults'" [passengers]="guests"></app-travelerslist>
        <hr/>
        <div class="buttonrow text-right">
          <button (click)="closeListEvent($event)" mat-stroked-button>{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" data-backdrop="static" id="EditTravelerModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
        <app-edittraveler [hotel]="true" (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"
                          [currentTraveler]="guests[currentTraveler]" [parent]="'searchResults'"></app-edittraveler>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="true" aria-labelledby="addGuestModal" class="modal fade" data-backdrop="static" id="addGuestModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addGuestModalTitle">
          {{'TARIFFES'|translate}}: {{hotelForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
        </h5>
        <button (click)="toggleGuestsModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div *ngFor="let room of hotelForm.occupancy; let i = index;" class="mb-3 col-lg-6">
            <div class="singleRoom mb-2">
              <div class="roomTitle">
                <span class="material-icons">hotel</span> {{'ROOM'|translate}} {{i + 1}}
              </div>
              <!-- ROOM OPTIONS
              <div class="roomOptions">
                <div class="row">
                  <div class="col-12">{{'ROOM_OPTIONS'|translate}}</div>
                  <div class="col-6">
                    <mat-form-field class="full-width">
                      <mat-label>{{'ROOM_CATEORIES'|translate}}</mat-label>
                      <mat-select multiple [(ngModel)]="room.roomCategory">
                        <mat-option *ngFor="let cat of roomCategories" [value]="cat.code">{{cat.name|translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-6">
                    <mat-form-field class="full-width">
                      <mat-label>{{'BED_TYPE'|translate}}</mat-label>
                      <mat-select multiple [(ngModel)]="room.bedTypes">
                        <mat-option *ngFor="let bed of bedTypes" [value]="bed.code">{{bed.name|translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                </div>
              </div>
              -->
              <hr/>
              <div class="guestsActions">
                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'ADULTS'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button (click)="removeGuestType(i, 10)" [disabled]="returnAdt(i) === 0 || (returnOver(i) === 0 && returnAdt(i) === 1)"
                            aria-label="Minus" class="text-center" mat-icon-button>
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnAdt(i)}}
                    <button (click)="addGuestType(i, 10)" aria-label="Plus" class="text-center" mat-icon-button>
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                <!--
                <hr/>

                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'OVER_65'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button [disabled]="returnOver(i) === 0 || (returnAdt(i) === 0 && returnOver(i) === 1)" class="text-center" mat-icon-button aria-label="Minus" (click)="removeGuestType(i, 2)">
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnOver(i)}}
                    <button class="text-center" mat-icon-button aria-label="Plus" (click)="addGuestType(i, 2)">
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                -->
                <hr/>
                <div class="row">
                  <div class="col-5 align-self-center">
                    {{'CHILDREN'|translate}}
                  </div>
                  <div class="col-7 text-right">
                    <button [disabled]="returnChd(i) === 0" class="text-center" mat-icon-button aria-label="Minus" (click)="removeGuestType(i, 5)">
                      <mat-icon style="margin:0;">remove</mat-icon>
                    </button>
                    {{returnChd(i)}}
                    <button class="text-center" mat-icon-button aria-label="Plus" (click)="addGuestType(i, 5)">
                      <mat-icon style="margin:0;">add</mat-icon>
                    </button>
                  </div>
                </div>
                <div *ngIf="returnChd(i) > 0">
                  <hr style="margin:10px 0"/>
                  <p>{{'HOW_OLD_ARE_CHILDREN'|translate}}</p>
                  <div *ngFor="let guest of room.guests; let g = index">
                    <div *ngIf="guest.code === 5">
                      <mat-form-field>
                        <mat-label>{{'CHILD_AGE'|translate}}</mat-label>
                        <mat-select [(ngModel)]="guest.age">
                          <mat-option [value]="0">0 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="1">1 {{'YEAR_OLD'|translate}}</mat-option>
                          <mat-option [value]="2">2 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="3">3 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="4">4 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="5">5 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="6">6 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="7">7 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="8">8 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="9">9 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="10">10 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="11">11 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="12">12 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="13">13 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="14">14 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="15">15 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="16">16 {{'YEARS_OLD'|translate}}</mat-option>
                          <mat-option [value]="17">17 {{'YEARS_OLD'|translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              </div>
              <hr *ngIf="hotelForm.occupancy.length>1"/>
              <div class="buttonrow text-right smallActionButtons">
                <button (click)="removeRoom(i)" *ngIf="hotelForm.occupancy.length>1" mat-stroked-button>
                  {{'REMOVE_ROOM'|translate}}
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <hr/>

      <div class="text-right">
        <!-- <button type="button" mat-button (click)="addRoom()">{{'ADD_ROOM'|translate}}</button> -->
        <button (click)="toggleGuestsModal()" mat-button type="button">{{'CLOSE'|translate}}</button>
      </div>

    </div>
  </div>
</div>
