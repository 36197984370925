<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-8">
      <h2 class="" mat-dialog-title>
        {{'PLACE_RESERVATION'|translate}}
      </h2>
    </div>
    <div class="col-4 text-right" *ngIf="!data.recommendation.lowCost">
      <button mat-button  (click)="togglePrint(data)" matTooltip="{{'PRINT'|translate}}">
        {{'PRINT' |translate}} <mat-icon>print</mat-icon>
      </button>
    </div>
    <div class="col-12 text-right" *ngIf="data.carrierOpt && data.carrierOpt.disableVoid">
      <div class="text-danger d-block" style="font-size:12px;">
        Ti ricordiamo che per cancellare l'emissione di questo vettore dovrai contattare la biglietteria.
      </div>
    </div>
  </div>
  <p *ngIf="data.recommendation.lowCost">{{'LOADING'|translate}}</p>
  <mat-progress-bar mode="buffer" *ngIf="data.recommendation.lowCost"></mat-progress-bar>
  <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight [(selectedIndex)]="selectedIndex" [hidden]="data.recommendation.lowCost">
    <mat-tab label="{{'ITINERARY'|translate}}">
      <div class="travelDetails">
        <div class="mb-3" *ngFor="let itinerary of data.recommendation.itineraries; let i = index;let la = last;">
          <div [ngClass]="{'nextExist':i<data.recommendation.itineraries.length && i!=data.recommendation.itineraries.length-1}">
            <div class="introItinerary">
              <p *ngIf="itinerary.flights.length>0" >
                {{itinerary.flights[0].departure.date|date:'EEE dd MMM yyyy'}}
              </p>
              {{itinerary.flights[0].departure.location}}
              <mat-icon>arrow_right_alt</mat-icon>
              {{itinerary.flights[itinerary.flights.length-1].arrival.location}}
              <div class="flightCabin marginbottom10">
                <span *ngIf="data.recommendation.cabins[i] === 'Y'">Economic</span>
                <span *ngIf="data.recommendation.cabins[i]=='M'">Economic standard</span>
                <span *ngIf="data.recommendation.cabins[i]=='W'">Economic premium</span>
                <span *ngIf="data.recommendation.cabins[i]=='C'">Business</span>
                <span *ngIf="data.recommendation.cabins[i]=='F'">First</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 collapse show" id="itinerary{{i}}">
                <div class="row">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center">
                    <img class="marketingcarrierimage" src="assets/img/airline/icon/{{data.recommendation.marketingCarrier}}.png"/>
                  </div>
                  <div class="col-3">
                    {{itinerary.flights[0].departure.location}}<br/>
                    {{itinerary.flights[0].departure.dateTime|date:'dd/MM/yyyy'}} -
                    {{returnZeroTime(itinerary.flights[0].departure.hours)}}:
                    {{returnZeroTime(itinerary.flights[0].departure.minutes)}}
                  </div>
                  <div class="col-3">
                    <div>
                      <span *ngIf="itinerary.flights.length>1">
                        {{itinerary.flights.length-1}} {{'STOPS'|translate}}
                      </span>
                      <span *ngIf="itinerary.flights.length==1">
                      {{'NON_STOPS'|translate}}
                      </span>
                      <span *ngIf="checkTechnicalStops(itinerary.flights)">
                        <span *ngIf="checkTechnicalStops(itinerary.flights)>1">- {{checkTechnicalStops(itinerary.flights)}}</span>
                        <span *ngIf="checkTechnicalStops(itinerary.flights)>1">
                          - <span class="material-icons iconTech"> local_gas_station </span>
                          <span class="vertical">{{'TECHNICAL_STOPS'|translate}}</span>
                        </span>
                        <span *ngIf="checkTechnicalStops(itinerary.flights)==1">
                          - <span class="material-icons iconTech"> local_gas_station </span>
                          <span class="vertical">{{'TECHNICAL_STOP'|translate}}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col-3">
                    {{itinerary.flights[itinerary.flights.length-1].arrival.location}}<br/>
                    {{itinerary.flights[itinerary.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy'}} -
                    {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.hours)}}
                    {{returnZeroTime(itinerary.flights[itinerary.flights.length-1].arrival.minutes)}}
                  </div>
                  <div class="col-3 col-sm-2 buttonrow">
                    <button mat-stroked-button (click)="opeSect(i)">
                      {{'DETAILS'|translate}} <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 collapse issection" id="collapse{{i}}">
                <div class="row" *ngFor="let flight of itinerary.flights; let f = index;let fl = last;">
                  <div class="col-12 mb-2" *ngIf="f===0">
                    <div class="row">
                      <div class="col-1 text-center">
                        <img class="marketingcarrierimage" src="assets/img/airline/icon/{{data.recommendation.marketingCarrier}}.png"/>
                      </div>
                      <div class="col-9 align-self-center">
                        <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                        <div class="md-subhead">{{returnTime(itinerary.flyingTime)}}</div>
                      </div>
                      <div class="col-2 buttonrow">
                        <button mat-stroked-button (click)="opeSect(i)">
                          {{'DETAILS'|translate}} <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  <div class="col-md-1 text-center">
                    <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-md-11">
                    <div class="flightdet">
                      <p class="headingseg">
                        {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{flight.departure.location}}
                      </p>
                      <p class="headingdetails">
                        {{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}
                        <span *ngIf="flight.departure.terminal"> - Terminal: {{flight.departure.terminal}}</span>
                      </p>
                      <p class="fldetails">
                        <span *ngIf="flight.detail.technicalStop && flight.detail.technicalStop.from > 0">
                          <span class="material-icons iconTech"> local_gas_station </span>
                          <span class="vertical"> {{'TECHNICAL_STOP_IN'|translate}} {{flight.detail.technicalStop.location}} </span><br/>
                          <span class="d-block pl-4">
                            {{'DURATION'|translate}}: {{convertMilliseconds(flight.detail.technicalStop)}}<br/>
                            {{'ARRIVAL'|translate}}:{{returnDate(flight.detail.technicalStop.from) | date:'dd MMM - HH:mm'}} -
                            {{'DEPARTURE'|translate}} {{returnDate(flight.detail.technicalStop.to) | date:'dd MMM - HH:mm'}}
                          </span>

                        </span>
                      </p>
                      <p class="headingseg">
                        <span class="redt" *ngIf="otherday(flight.departure.date | date:'dd',flight.arrival.date | date:'dd')"> {{flight.arrival.date | date:'dd MMM yyyy'}} - </span>
                        {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{flight.arrival.location}} ({{flight.arrival.location}})
                      </p>
                      <p class="fldetails">
                        {{flight.operatingCarrier}} {{flight.number}}
                        <span *ngIf="flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                        <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
                      </p>
                    </div>
                    <div class="scalepause" *ngIf="!fl">
                      <span>
                        <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                        <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} </span>
                      </span>
                      <span class="changeairport" *ngIf="flight.arrival.location != itinerary.flights[f+1].departure.location">
                        {{'CHANGE'|translate}}: {{itinerary.flights[f+1].departure.location}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'TARIFFES'|translate}}">
      <div class="padding15" *ngIf="data.recommendation.rates">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12" *ngFor="let fare of data.recommendation.rates;let f = index;">
            <div class="uiFare" [ngClass]="{'selectedRate':fare.selected}">
              <span class="material-icons absoluteCheck" *ngIf="fare.selected"> radio_button_checked </span>
              <span (click)="selectFare(f)" class="pointer material-icons absoluteCheck" *ngIf="!fare.selected"> radio_button_unchecked </span>
              <div class="fareNameNew">
                <span *ngFor="let class of fare.details;let l = last;">
                  <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
                </span>
                <app-policy-alerts-icon [isB2B]="data.appComponent.isB2B" [isSbt]="data.appComponent.isSbt" [arrayOfAlerts]="fare.arrayOfAlerts"></app-policy-alerts-icon>
              </div>
              <div class="dialogFareNew">
                <div class="bagInfo">
                  <div *ngIf="fare.passengers.ADT">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_ADULT'|translate}}:
                    <span *ngFor="let bag of fare.passengers.ADT.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                      </span>
                  </div>
                  <div *ngIf="fare.passengers.CHD && fare.passengers.CHD.baggage.length>0">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_CHILD'|translate}}:
                    <span *ngFor="let bag of fare.passengers.CHD.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                  </div>
                  <div *ngIf="fare.passengers.INF && fare.passengers.INF.baggage.length>0">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_INFANT'|translate}}:
                    <span *ngFor="let bag of fare.passengers.INF.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                  </div>
                </div>
                <div class="bottomActions">
                  <span *ngIf="!fare.refundable">
                      <span class="material-icons greytxt"> cancel </span> {{'NO_REFOUND'|translate}}
                  </span>
                  <span *ngIf="fare.refundable">
                    <span class="material-icons greentxt"> check_circle </span> {{'YES_REFOUND'|translate}}
                  </span>
                  <span class="bottomInfoBtn" (click)="openBottomSheet(fare)"> {{'FARE_INFO'|translate}} </span>
                  <span class="bottomInfoBtn gatPenalties" *ngIf="data.appComponent.isGat && data.mainComponent.loggedUser.role.superAdmin"
                        (click)="openDialogRules(fare)">
                    {{'FARE_RULES'|translate}}
                  </span>
                  <span class="bottomInfoBtn flyPenalties" *ngIf="!data.appComponent.isGat && data.mainComponent.loggedUser.role.superAdmin"
                        (click)="openDialogRules(fare)">
                    {{'FARE_RULES'|translate}}
                  </span>
                  <span class="bottomInfoBtn" *ngIf="!data.mainComponent.loggedUser.role.superAdmin"
                        (click)="openDialogRules(fare)">
                    {{'FARE_RULES'|translate}}
                  </span>
                  <span class="material-icons yellow-text" matTooltip="Negotiated fare {{fare.fare.amount + fare.fare.fees|number:'.2-2'}}"
                        *ngIf="fare.negotiated && !isB2B">
                    star
                  </span>
                  <span class="material-icons yellow-text" matTooltip="Discount fare" *ngIf="fare.negotiated && isB2B">
                    star
                  </span>
                </div>
                <div class="farePrice" (click)="selectFare(f)" *ngIf="!fare.negotiated">
                  {{priceService.returnPrice(fare.fare.amount)|number:'.2-2'}}€
                </div>
                <div class="farePrice" (click)="selectFare(f)" *ngIf="fare.negotiated && isB2B">
                  <del>{{priceService.returnPrice(fare.fare.publicAmount)|number:'.2-2'}}€</del>
                  <span class="material-icons"> arrow_right_alt </span>
                  {{priceService.returnPrice(fare.fare.publicAmount - fare.fare.fees)|number:'.2-2'}}€
                </div>
                <div class="farePrice" (click)="selectFare(f)" *ngIf="fare.negotiated && priceService.isNegoHidden() && !isB2B">
                  {{priceService.returnPrice(fare.fare.publicAmount)|number:'.2-2'}}€
                </div>
                <div class="farePrice" (click)="selectFare(f)" *ngIf="fare.negotiated && !priceService.isNegoHidden() && !isB2B">
                  {{priceService.returnPrice(fare.fare.amount + fare.fare.fees)|number:'.2-2'}}€
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="padding15" *ngIf="data.recommendation.faresAma">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12" *ngFor="let offer of data.recommendation.faresAma.pricedOffer">
            <div class="uiFare" [ngClass]="{'selectedRate':offer.selected}">

          <span class="material-icons absoluteCheck" *ngIf="offer.selected" >
            radio_button_checked
          </span>

              <span class="pointer material-icons absoluteCheck" (click)="slectNdcAmaFare(offer, data.recommendation)">
            radio_button_unchecked
          </span>

              <div class="fareNameNew">
            <span *ngFor="let journey of offer.journeyArray; let lastJourney = last;">

                <span>{{journey.details.tarif.name}}</span>
                <span *ngIf="!lastJourney"> + </span>

            </span>
              </div>

              <div class="dialogFareNew">
                <div class="bagInfo">
                  <div *ngIf="offer.baggage.adt">

                            <span class="material-icons">
                              work
                            </span>
                    {{'BAG_FOR_ADULT'|translate}}: {{offer.baggage.adt.qty}}
                  </div>
                  <div *ngIf="offer.baggage.chd">

                            <span class="material-icons">
                              work
                            </span>
                    {{'BAG_FOR_CHILD'|translate}}: {{offer.baggage.chd.qty}}
                  </div>
                  <div *ngIf="offer.baggage.inf">

                            <span class="material-icons">
                              work
                            </span>
                    {{'BAG_FOR_INFANT'|translate}}: {{offer.baggage.inf.qty}}
                  </div>
                </div>

                <div class="bottomActions">
                          <span *ngIf="!offer.refundable">
                            <span class="material-icons greytxt">
                              cancel
                            </span>
                            {{'NO_REFOUND'|translate}}
                          </span>
                  <span *ngIf="offer.refundable">
                            <span class="material-icons greentxt">
                              check_circle
                            </span> {{'YES_REFOUND'|translate}}
                          </span>
                  <span class="bottomInfoBtn" (click)="openInfoNdcAma(offer)">
                            {{'FARE_INFO'|translate}}
                          </span>


                </div>
                <div class="farePrice" (click)="slectNdcAmaFare(offer, data.recommendation)">
                          <span *ngIf="!offer.negotiated" class="">
                            {{priceservice.returnPrice(offer.totalPrice.amount)|number:'.2-2'}} {{offer.totalPrice.currency}}
                          </span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>


    </mat-tab>
    <mat-tab label="{{'PASSENGERS'|translate}}">
      <div class="padding15">
        <div class="row buttonrow">
          <div *ngIf="data.passPort" class="col-12 mb-3">
            <p class="text-danger mb-2">{{'SEX_AGE_FOR_VECTOR'|translate}}</p>
          </div>
          <div class="col-lg-6 mb-4" *ngFor="let tra of data.passengers;let i = index">
            <div class="mb-3">
              <button mat-stroked-button *ngIf="!tra.name || !tra.surname" (click)="toggleDialog(i)">{{'ADD_TRAVELER'|translate}}</button>
              <button mat-stroked-button *ngIf="(tra.name || tra.surname) && !data.appComponent.isSbt" (click)="removeTraveler(i)">{{'REMOVE_TRAVELER'|translate}}</button>
              <button mat-stroked-button *ngIf="tra.name || tra.surname" (click)="toggleEditDialog(i)">{{'EDIT_TRAVELER'|translate}}</button>
              <button mat-stroked-button *ngIf="!tra.name && !tra.surname"  (click)="loadTravelersList(i)">{{'LOAD_TRAVELER'|translate}}</button>
            </div>
            <div class="fakefield mb-2">
              {{'TYPE'|translate}}:
              <span> {{tra.type|translate}}</span>
            </div>
            <div class="fakefield mb-2">
              {{'NAME'|translate}}:
              <span *ngIf="tra.name else noData"> {{tra.name}}</span>
              <div class="text-danger d-block smalltext" *ngIf="!tra.name">
                {{'MANDATORY_FIELD'|translate}}
              </div>
            </div>
            <div class="fakefield mb-2">
              {{'SURNAME'|translate}}:
              <span *ngIf="tra.surname else noData"> {{tra.surname}}</span>
              <div class="text-danger d-block smalltext" *ngIf="!tra.surname">
                {{'MANDATORY_FIELD'|translate}}
              </div>
            </div>
            <div class="fakefield mb-2">
              {{'PHONE'|translate}}:
              <span *ngIf="tra.phoneNumber else noData"> {{tra.phoneNumber}}</span>
              <div class="text-danger d-block smalltext" *ngIf="data.passPort && !tra.phoneNumber">
                {{'MANDATORY_FIELD'|translate}}
              </div>
            </div>
            <div class="fakefield mb-2">
              {{'EMAIL'|translate}}:
              <span *ngIf="tra.email else noData"> {{tra.email}}</span>
              <div class="text-danger d-block smalltext" *ngIf="data.passPort && !tra.email">
                {{'MANDATORY_FIELD'|translate}}
              </div>
            </div>
            <div class="fakefield mb-2" *ngIf="data.passPort || tra.type === 'CHD' || tra.type === 'INF'">
              {{'BIRTHDAY'|translate}}:
              <span *ngIf="tra.birthday else noData"> {{tra.birthday| date:'dd/MM/yyyy'}}</span>
              <div class="text-danger d-block smalltext" *ngIf="data.passPort && !tra.birthday || (tra.type === 'CHD' && !tra.birthday) || (tra.type === 'INF' && !tra.birthday)">
                {{'MANDATORY_FIELD'|translate}}
              </div>
            </div>
            <div class="fakefield mb-2" *ngIf="data.passPort || tra.type === 'CHD' || tra.type === 'INF' ">
              {{'SEX'|translate}}:
              <span *ngIf="tra.sex else noData"> {{tra.sex}}</span>
              <div class="text-danger d-block smalltext" *ngIf="data.passPort && !tra.sex || (tra.type === 'CHD' && !tra.sex) || (tra.type === 'INF' && !tra.sex)">
                {{'MANDATORY_FIELD'|translate}}
              </div>
            </div>
            <div class="text-danger smalltext" *ngIf="tra.document && tra.document.nationality">
              <div *ngIf="!validCountry(tra.document.nationality)">
                <span class="material-icons smalerIcon"> warning_amber </span>
                {{'NATIONALITY'|translate}} {{'INVALID'|translate}}
              </div>
            </div>
            <div class="text-danger smalltext" *ngIf="tra.document && tra.document.issuingCountry">
              <div *ngIf="!validCountry(tra.document.issuingCountry)">
                <span class="material-icons smalerIcon"> warning_amber </span>
                {{'ISSUING_COUNTRY'|translate}} {{'INVALIDM'|translate}}
              </div>
            </div>
            <hr/>
            <div *ngIf="tra.frequentFlyers">
              <div class="mb-2">{{'FREQUENT_FLYER'|translate}}</div>
              <div *ngIf="tra.frequentFlyers" class="mb-2">
                <div *ngIf="tra.frequentFlyers.length == 0">
                  {{'NO_FREQUENT_FLYER'|translate}}
                </div>
              </div>
              <div *ngIf="tra.frequentFlyers.length > 0" class="mb-4 buttonrow">
                <div *ngFor="let freq of tra.frequentFlyers; let f = index;">
                  <span class="buttonfq" (click)="selectFq(i,f)" [ngClass]="{'selFq': freq.selected}">
                    <span class="mr-2" style="vertical-align:middle">
                        <img *ngIf="freq.iata && freq.iata.length == 2" class="freqLogo" src="assets/img/airline/icon/{{freq.iata.toUpperCase()}}.png"/>
                    </span>
                    {{freq.number}}
                  </span>
                </div>
              </div>
            </div>
            <ng-template #noData> _ _ _ _</ng-template>
            <ng-container *ngIf="tra.surname" #afterPaxSelected>
              <mat-select  [(ngModel)]="tra.wheelchair" placeholder="{{'NO_WHEELCHAIR' | translate}}">
                <mat-option [value]="null" selected>{{'NO_WHEELCHAIR' | translate}}</mat-option>
                <mat-option *ngFor="let wheelOption of WHEELCHAIR_OPTIONS" [value]="wheelOption">{{ wheelOption | translate }}</mat-option>
              </mat-select>
              <hr/>
            </ng-container>
            <button mat-stroked-button *ngIf="!tra.name || !tra.surname" (click)="toggleDialog(i)">{{'ADD_TRAVELER'|translate}}</button>
            <button mat-stroked-button *ngIf="(tra.name || tra.surname) && !data.appComponent.isSbt" (click)="removeTraveler(i)">{{'REMOVE_TRAVELER'|translate}}</button>
            <button mat-stroked-button *ngIf="tra.name || tra.surname" (click)="toggleEditDialog(i)">{{'EDIT_TRAVELER'|translate}}</button>
            <button mat-stroked-button *ngIf="!tra.name && !tra.surname"  (click)="loadTravelersList(i)">{{'LOAD_TRAVELER'|translate}}</button>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="data.recommendation.company.customFields && data.recommendation.company.customFields!=''" label="{{'SIAP_CUSTOM_FIELDS'|translate}}">
      <div class="padding15">
        <div class="row mt-4">
          <div class="col-md-6" *ngFor="let field of customFields; let i = index">
            <div class="mb-5 relativeCont" [ngClass]="{'reqBorder':!field.isOptional && !field.fieldValue}">
              <div class="labelField" *ngIf="field.fieldType != 'radioButton' && field.fieldType !='checkbox'">
                <label for="field{{field.id}}">{{field.fieldName}} <sup>({{field.siapField}})</sup></label>
              </div>
              <div *ngIf="field.fieldType == 'text'">
                <input type="text" id="field{{i}}" class="customInput w-100" [(ngModel)]="field.fieldValue">
              </div>
              <div *ngIf="field.fieldType == 'select'" >
                <select class="customSelect w-100" id="field{{i}}" [(ngModel)]="field.fieldValue">
                  <option value="">-- Select an option --</option>
                  <option *ngFor="let option of field.fieldOptions" [value]="option.value">{{option.label}}</option>
                </select>
              </div>
              <div *ngIf="field.fieldType == 'radioButton'">
                <div class="fakeContOpt">
                  <div class="d-inline-block mr-3 labelCm">{{field.fieldName}} <sup>({{field.siapField}})</sup></div>
                  <div class="d-inline-block">
                    <div class="d-inline-block" *ngFor="let radio of field.fieldOptions; let o = index">
                      <input class="vertical" [(ngModel)]="field.fieldValue" [value]="radio.value" (click)="setRadioValue(field, radio)" type="radio" name="flexRadioDefault{{i+o}}" id="flexRadioDefault{{i+o}}">
                      <label class="labelCm ml-2 mr-2" for="flexRadioDefault{{i+o}}"> {{radio.label}} </label>
                    </div>
                  </div>
                </div>
              </div>
              <!--
              <div *ngIf="field.fieldType == 'checkbox'">
                  <div class="fakeContOpt">
                      <div class="d-inline-block mr-3">{{field.fieldName}}</div>
                      <div class="d-inline-block">
                          <div class="d-inline-block" *ngFor="let check of field.fieldOptions; let o = index">
                              <input [(ngModel)]="check.value" class="" type="checkbox" name="flexCheckDefault{{i+'_'+o}}" id="flexCheckDefault{{i+'_'+o}}">
                              <label class="ml-2 mr-2" for="flexCheckDefault{{i+'_'+o}}">
                                  {{check.label}}
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
              -->
              <div class="pt-2 small text-danger requiredHint" *ngIf="!field.isOptional && !field.fieldValue">
                {{'REQUIRED_FIELD'|translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="buttonrow cmAction text-right">
          <button mat-raised-button color="primary" [disabled]="validFields()" (click)="saveFieldsToPnr()" class="">{{'SAVE'|translate}}</button>
        </div>
      </div>
      <hr/>
    </mat-tab>
  </mat-tab-group>

</mat-dialog-content>
<mat-dialog-actions class="px-4 py-2" align="end" *ngIf="!data.recommendation.lowCost">
  <div *ngIf="loadingLink">
    {{'ARRANGE_RESERVATION'|translate}}
    <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>
  </div>
  <div *ngIf="!data.passengers[0].phoneNumber" class="text-danger small py-1 d-block w-100">
    {{'INSERT_FIRST_PAX_PHONE'|translate}}
  </div>
  <div *ngIf="checkLengthPassengers()" class="text-warning small d-block w-100">
    <div matTooltip="{{'PASSENGERS NAME TOO LONG'|translate}}" class="mr-2">
      <span class="material-icons d-inline-block mr-2"> info </span>
      <span style="vertical-align: middle;" class="d-inline-block">{{'PAX_WARNING'|translate}}</span>
    </div>
  </div>
  <div class="text-danger small d-block w-100 pt-2" *ngIf="validFields() || !fieldSaved">
    <span>Richiesti campi aggiuntivi obbligatori</span>
    <span (click)="selectedIndex = 4" class="iconButt vertical material-icons">arrow_right_alt</span>
  </div>
  <button *ngIf="!loadingLink" mat-button mat-dialog-close>{{'CLOSE'|translate}}</button>
  <button *ngIf="!loadingLink" mat-button [disabled]="!travelerController() || !data.passengers[0].phoneNumber || (validFields() || !fieldSaved)"
          (click)="setTravelers()">
    {{'PLACE_RESERVATION'|translate}}
  </button>
</mat-dialog-actions>

<div class="modal fade" id="addTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <!--
        <app-add [passPort]="data.passPort" [trDate]="data.recommendation.itineraries[data.recommendation.itineraries.length-1].flights[data.recommendation.itineraries[data.recommendation.itineraries.length-1].flights.length-1].departure.date" [newTraveler]="data.passengers[currentTraveler]" [parent]="'searchResults'" (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"></app-add>
        -->
        <app-add [passPort]="data.passPort" [trDate]="data.recommendation.itineraries[0].flights[0].departure.date" [newTraveler]="data.passengers[currentTraveler]"
                 [parent]="'searchResults'" (close)="closeEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)">
        </app-add>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="listTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content" *ngIf="loadTravelers">
      <div *ngIf="currentTraveler!=undefined" class="modal-body">
        <app-travelerslist
          [trDate]="data.recommendation.itineraries[0].flights[0].departure.date"
          [newTraveler]="data.passengers[currentTraveler]" [passengers]="data.passengers" [page]="'searchResults'" (close)="closeListEvent($event)"
          (setTraveler)="travelerSet($event, currentTraveler)">
        </app-travelerslist>
        <!--
        <app-travelerslist
          [trDate]="data.recommendation.itineraries[data.recommendation.itineraries.length-1].flights[data.recommendation.itineraries[data.recommendation.itineraries.length-1].flights.length-1].departure.date"
          [newTraveler]="data.passengers[currentTraveler]" [passengers]="data.passengers" [page]="'searchResults'" (close)="closeListEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)">
        </app-travelerslist>
        -->
        <hr/>
        <div class="buttonrow text-right">
          <button mat-stroked-button (click)="closeListEvent($event)">{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="EditTravelerModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div *ngIf="currentTraveler!=undefined && editingTraveler" class="modal-body">
        <app-edittraveler [trDate]="data.recommendation.itineraries[0].flights[0].departure.date" [passPort]="data.passPort"
                          (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)"
                          [currentTraveler]="data.passengers[currentTraveler]" [parent]="'searchResults'">
        </app-edittraveler>
        <!--
        <app-edittraveler [trDate]="data.recommendation.itineraries[data.recommendation.itineraries.length-1].flights[data.recommendation.itineraries[data.recommendation.itineraries.length-1].flights.length-1].departure.date" [passPort]="data.passPort" (close)="closeEditEvent($event)" (setTraveler)="travelerSet($event, currentTraveler)" [currentTraveler]="data.passengers[currentTraveler]" [parent]="'searchResults'"></app-edittraveler>
        -->
      </div>
    </div>
  </div>
</div>
<app-print-reservation *ngIf="!data.recommendation.lowCost" [mainComponent]="data.mainComponent" [fares]="data.tariff" [reservation]="data.recommendation" [passengers]="data.passengers"></app-print-reservation>
