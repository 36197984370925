import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccessDialogComponent } from './access-dialog/access-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TravelPolicyService } from '../../../services/travel-policy/travel-policy.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-travel-policy-form',
  templateUrl: './travel-policy-form.component.html',
  styleUrls: ['./travel-policy-form.component.scss']
})
export class TravelPolicyFormComponent implements OnInit {

  orderForm: any;
  items: FormArray;
  travelPolicyObj: any;
  checked: boolean = true;
  slideToggle: any[] = [];
  currentPolicyItems: any[] = [];
  currentPolicy: any;
  conditionSelected: any[] =[]
  isMobile: boolean = false;
  loading: boolean = false;
  private policyIdFromRoute: string;
  conditionOptionList: any[] = [
    "FLIGHT_PRICE",
    "FLIGHT_PRICE_NDC-LOW",
    //"FLIGHT_PRICE_LOW",
    "FLIGHT_CABIN",
    "HOTEL_PRICE",
    "HOTEL_STARS",
    "TRAIN_PRICE",
    "TRENITALIA_CLASS",
    "ITALO_CLASS",
    "CAR_PRICE",
    "TRANSFER_PRICE",
    /*"TRAIN_DESTINATION"*/
  ];
  optionSettings: any = {
    'FLIGHT_PRICE': {
      operators: {
        equal: false,
        notEqual: false,
        greaterThan: true,
        equalOrLess:  false,
      },
      value: 'price',
      defaultOp: '>='
    },
    "FLIGHT_PRICE_NDC-LOW" : {
      operators: {
        equal: false,
        notEqual: false,
        greaterThan: true,
        equalOrLess:  false,
      },
      value: 'price',
      disabled: 'TO_AUTHORIZE',
      defaultOp: '>='
    },
    /*
    "FLIGHT_PRICE_LOW" : {
      operators: {
        equal: false,
        notEqual: false,
        greaterThan: true,
        equalOrLess:  false,
      },
      value: 'price',
      disabled: 'TO_AUTHORIZE',
      defaultOp: '>='
    },
    */
    'FLIGHT_CABIN': {
      operators: {
        equal: true,
        notEqual: true,
        greaterThan: false,
        equalOrLess:  false,
      },
      value: 'cabin',
      defaultOp: '=='
    },
    'HOTEL_PRICE': {
      operators: {
        equal: false,
        notEqual: false,
        greaterThan: true,
        equalOrLess:  false,
      },
      value: 'price',
      defaultOp: '>='
    },
    'HOTEL_STARS': {
      operators: {
        equal: true,
        notEqual: false,
        greaterThan: false,
        equalOrLess:  false,
      },
      value: 'stars',
      defaultOp: '==',
      min: 1,
      max: 5
    },
    'TRAIN_PRICE': {
      operators: {
        equal: false,
        notEqual: false,
        greaterThan: true,
        equalOrLess:  false,
      },
      value: 'price',
      defaultOp: '>='
    },
    'TRENITALIA_CLASS': {
      operators: {
        equal: true,
        notEqual: true,
        greaterThan: false,
        equalOrLess:  false,
      },
      value: 'train_class',
      defaultOp: '=='
    },
    'ITALO_CLASS': {
      operators: {
        equal: true,
        notEqual: true,
        greaterThan: false,
        equalOrLess:  false,
      },
      value: 'train_class',
      defaultOp: '=='
    },
    'CAR_PRICE': {
      operators: {
        equal: false,
        notEqual: false,
        greaterThan: true,
        equalOrLess:  false,
      },
      value: 'price',
      defaultOp: '>='
    },
    'TRANSFER_PRICE': {
      operators: {
        equal: false,
        notEqual: false,
        greaterThan: true,
        equalOrLess: false,
      },
      value: 'price',
      defaultOp: '>='
    },
    /*'TRAIN_DESTINATION': {
      operators: {
        equal: true,
        notEqual: true,
        greaterThan: true,
        lessThan: false,
      },
      value: 'price'
    }*/
  };
  cabinOptions: any[] = [
    {name: 'First', value: 'F'},
    {name: 'Business', value: 'C'},
    {name: 'Full Economic', value: 'Y'},
    {name: 'Economic Premium', value: 'W'},
    {name: 'Economic Standard', value: 'M'},
  ];
  trainClasses: any = {
    'ITALO_CLASS': [
      'Low Cost',
      'Economy',
      'Flex'
    ],
    'TRENITALIA_CLASS': [
      "EXECUTIVE",
      "BUSINESS",
      "BUSINESS AREA SILENZIO",
      "PREMIUM",
      "STANDARD"
      /*"BASE",
      "C.Argento",
      "C. Verde",
      "Global Pass",
      "Economy",
      "Super Economy",
      "SPECIALE EVENTI"*/
    ]
  };
  toggleSetting: any = [
    'TO_BLOCK',
    'TO_AUTHORIZE',
    'TO_NOTIFY'
  ]
  operatorMap: any = {
    '>=': 'EQUAL_OR_GREATER',
    '<=': 'EQUAL_OR_LESS',
    '==': 'EQUAL',
    '!=': 'NOT_EQUAL'
  }
  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.isMobile = width.toString() <= '568px';
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private policyService: TravelPolicyService,
    private router: Router
  ) {}

  get isAuthorized(): AbstractControl {
    return this.items.get('isAuthorized').value;
  }

  get type(): AbstractControl {
    return this.items.get('type');
  }

  ngOnInit(): void {
    this.getRouterParam();
    this.orderForm = this.formBuilder.group({items: this.formBuilder.array([])});
  }

  getRouterParam(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.policyIdFromRoute = routeParams.get('policyId');
    this.fetchPolicy(this.policyIdFromRoute);
  }

  fetchPolicy(id:string): void {
    this.policyService.show(id).subscribe(policy => {
      this.loading = true;
      this.currentPolicy = policy.data;
      this.initPolicy();
    });
  }

  initPolicy(): void {
    this.currentPolicyItems = this.currentPolicy ? this.currentPolicy.configuration : [];
    if (this.currentPolicyItems.length === 0) {
      this.orderForm = this.formBuilder.group({
        items: this.formBuilder.array([])
      });
      this.items = this.orderForm.get('items') as UntypedFormArray;
      this.addItem();
    } else {
      this.orderForm = this.formBuilder.group({
        items: this.formBuilder.array([])
      });
      this.items = this.orderForm.get('items') as UntypedFormArray;
      this.setInitItems();
    }
    this.loading = false;
  }

  createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      type: [this.conditionOptionList[this.returnFirstUnselected()], Validators.required],
      operator: [this.optionSettings[this.conditionOptionList[this.returnFirstUnselected()]].defaultOp, Validators.required],
      value: ['' , Validators.required],
      isAuthorized: false,
      isNotify: false,
      userType: 'default',
      isDefault: 1
    });
  }

  addItem(): void {
    this.currentPolicyItems.push({
      conditions: {
        type: '',
        operator: '',
        value: 0,
      },
      isNotify: false,
      isAuthorized: false,
      type: 'default',
      isDefault: 1,
      authorization_users: [],
      authorization_roles: []
    });
    this.items.push(this.createItem());
    console.log(this.items)
    this.slideToggle[this.items.length-1] = 'TO_BLOCK';
  }

  createExistingItems(policyItem:any): FormGroup {
    return this.formBuilder.group({
      type: [policyItem.conditions.type, Validators.required],
      operator: [policyItem.conditions.operator, Validators.required],
      value: [policyItem.conditions.value, Validators.required],
      isAuthorized: policyItem.isAuthorized,
      isNotify: policyItem.isNotify,
      userType: policyItem.type
    });
  }

  setInitItems(): void {
    this.currentPolicyItems.forEach((item, index) => {
      this.items.push(this.createExistingItems(item));
      this.formBuilder.group({
        items: item
      });
      this.isToAuthorize(index, item);
      this.conditionSelected.push(item.conditions.type);
      if (item.conditions.value === this.optionSettings['HOTEL_STARS'].max  && item.conditions.type === 'HOTEL_STARS') this.checkStars(index)
      else this.optionSettings['HOTEL_STARS'].operators.greaterThan = true;
    });
  }

  addAccess(id:number): void {
    let userPolicy = {
      authorization: this.currentPolicyItems[id].type ? this.currentPolicyItems[id].type : 'default',
      authorization_users: this.currentPolicyItems[id].authorization_users ? this.currentPolicyItems[id].authorization_users : [],
      authorization_roles: this.currentPolicyItems[id].authorization_roles ? this.currentPolicyItems[id].authorization_roles : []
    };
    let dialogRef = this.matDialog.open(AccessDialogComponent, {
      width: '500px',
      minWidth: '350px',
      maxHeight: '100%',
      data: {
        id : id,
        policy: userPolicy,
        blockSelect: userPolicy.authorization == 'default'
      }
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result && result.radioButton !== undefined){
        this.currentPolicyItems[id].type = result.radioButton;
        this.items.at(id).patchValue({userType: result.radioButton, isDefault: result.radioButton === 'default' ? 1 : 0});
        this.currentPolicyItems[id].authorization_users = result.authorization_users;
        this.currentPolicyItems[id].authorization_roles = result.authorization_roles;
      }
    })
  }

  deletePolicy(i: number): void {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '280px',
      data: {
        action: 'deleteCondition',
        item: this.items.value[i]
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.slideToggle.splice(i, 1);
        this.conditionSelected.splice(i, 1);
        this.items.removeAt(i);
        this.currentPolicyItems = this.items.value;
      }
    });
  }

  onSubmit(): void {
    this.travelPolicyObj = this.items.controls.map(item => item.value);
    if (this.orderForm.valid) {
      this.currentPolicyItems.map((item, index) => {
        const conditions = {
          type: this.travelPolicyObj[index].type,
          operator: this.travelPolicyObj[index].operator,
          value: this.travelPolicyObj[index].value
        };
        item.isAuthorized = this.travelPolicyObj[index].isAuthorized;
        item.isNotify = this.travelPolicyObj[index].isNotify;
        item.type = this.travelPolicyObj[index].userType;
        item.type == 'default' ? item.isDefault = 1 : item.isDefault = 0;
        if ((!item.isAuthorized && !item.isNotify) || item.isDefault == 1 ) {
          delete item.authorization_roles
          delete item.authorization_users
        }
        delete item.userType;
        delete item.operator;
        delete item.value;
        return item.conditions = conditions;
      });
      this.currentPolicy.configuration = this.currentPolicyItems;
      this.policyService.updateInPolicy(this.currentPolicy, this.policyIdFromRoute).subscribe(() => {});
      this.snackBar.open('Travel policy has been saved!', 'Success', {
        duration: 2000,
      });
      this.router.navigate(['/policies']).then();
    } else {
      this.snackBar.open('Check the invalid field!', 'Failed', {
        duration: 2000
      });
    }
  }

  checkStars(index: number){
    if (this.items.controls[index]['value'].value >= this.optionSettings['HOTEL_STARS'].max) {
      this.optionSettings['HOTEL_STARS'].operators.greaterThan = false;
      this.items.at(index).patchValue({operator : '=='});
    } else {
      this.optionSettings['HOTEL_STARS'].operators.greaterThan = true;
    }
  }

  isToAuthorize(index: number, item: any, update?: boolean, value?: string){
    if (item != ''){
      if (item.isAuthorized && !item.isNotify){
        this.slideToggle[index] = 'TO_AUTHORIZE';
      } else if (!item.isAuthorized && item.isNotify){
        this.slideToggle[index] = 'TO_NOTIFY';
      } else if(!item.isAuthorized && !item.isNotify){
        this.slideToggle[index] = 'TO_BLOCK';
      }
    } else if (item === '' && update){
      if (value === 'TO_BLOCK'){
        this.items.at(index).patchValue({isAuthorized : false, isNotify: false, userType: 'default'});
      } else if (value === 'TO_AUTHORIZE'){
        this.items.at(index).patchValue({isAuthorized : true, isNotify: false, userType: 'default'});
      } else if (value === 'TO_NOTIFY'){
        this.items.at(index).patchValue({isAuthorized : false, isNotify: true, userType: 'default'});
      }
    }
  }

  updateSelected(opt: any){
    if (!this.checkIfSelected(opt)){
      this.conditionSelected.push(opt)
      if (this.conditionSelected.length > this.items.controls.length) this.conditionSelected.splice(this.items.controls.length - 1, 1);
      if (opt === 'TRENITALIA_CLASS' || opt === 'ITALO_CLASS'){
        const i = this.conditionSelected.findIndex((i:any)=> i === opt);
        if (i !== -1) this.items.at(i).patchValue({operator : '=='});
      } else {
        this.items.at(this.conditionSelected.length - 1).patchValue({operator: this.optionSettings[opt].defaultOp});
      }
    }
  }

  checkIfSelected(opt: any){
    return this.conditionSelected.findIndex(i => i === opt) !== -1;
  }

  returnFirstUnselected(){
    let firstIndex = this.conditionOptionList.length-1;
    this.conditionOptionList.forEach((opt:any, index: number)=> {
      if (this.conditionSelected.findIndex(i => i === opt) === -1){
        if (index <= firstIndex) firstIndex =  index;
      }
    })
    return firstIndex;
  }
}
