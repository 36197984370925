import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { MatStepperModule } from '@angular/material/stepper';
import { SearchflightComponent } from './components/flight/searchflight/searchflight.component';
import { SearchformComponent } from './components/flight/searchflight/searchform/searchform.component';
import {SearchresultsComponent} from './components/flight/searchflight/searchresults/searchresults.component';
import { BottomsheetComponent } from './components/flight/searchflight/bottomsheet/bottomsheet.component';
import { SinglepnrComponent } from './components/flight/singlepnr/singlepnr.component';
import { StepsearchresultsComponent } from './components/flight/searchflight/stepsearchresults/stepsearchresults.component';
import { FaresComponent } from './components/flight/searchflight/fares/fares.component';
import { TravelersModule } from '../travelers/travelers.module';
import { TstComponent } from './components/flight/singlepnr/tst/tst.component';
import { AncillaryComponent } from './components/flight/singlepnr/ancillary/ancillary.component';
import { SeatmapComponent } from './components/flight/singlepnr/seatmap/seatmap.component';
import { SeatmapLowCostComponent } from './components/flight/singlepnr/seatmapLowCost/seatmapLowCost.component';
import { AirportsComponent } from './components/flight/searchflight/airports/airports.component';
import { ReservationlistComponent } from './components/flight/reservationlist/reservationlist.component';
import { FopsModule } from '../fops/fops.module';
import { CarriersComponent } from './components/flight/searchflight/carriers/carriers.component';
import { FarerulesComponent } from './components/flight/farerules/farerules.component';
import { DossierModule } from '../dossier/dossier.module';
import { CurrentselectionComponent } from './components/flight/searchflight/stepsearchresults/currentselection/currentselection.component';
import { CalendarresultsComponent } from './components/flight/searchflight/calendarresults/calendarresults.component';
import { RecodialogComponent } from './components/flight/searchflight/calendarresults/recodialog/recodialog.component';
import {
    DialogLowCostConfirm,
    FinalizelowcostComponent,
} from './components/flight/finalizelowcost/finalizelowcost.component';
import { LuggagesComponent } from './components/flight/finalizelowcost/luggages/luggages.component';
import { ChecklowcostpaymentComponent } from './components/flight/finalizelowcost/checklowcostpayment/checklowcostpayment.component';
import { DirectiveModuleModule } from '../directive/directive-module.module';
import { HistoryComponent } from './components/flight/history/history.component';
import { OrderflightsComponent } from './components/flight/singlepnr/orderflights/orderflights.component';
import { BottomlowcostComponent } from './components/flight/searchflight/bottomlowcost/bottomlowcost.component';
import { CustomCommonModule } from '../common/custom-common.module';
import { AutocompleteCmComponent } from './components/autocomplete-cm/autocomplete-cm.component';
import { PrintReservationComponent } from './components/flight/print-reservation/print-reservation.component';
import { RequiredParametersComponent } from '../../components/shared/required-parameters/required-parameters.component';
import { SearchInformationModule } from '../../components/search-information/search-information.module';
import { PnrhistoryComponent } from './components/flight/singlepnr/pnrhistory/pnrhistory.component';
import { RefundDialogComponent } from './components/flight/singlepnr/refund-dialog/refund-dialog.component';
import { SearchresultsNDCComponent } from './components/flight/searchflight/searchresults-ndc/searchresults-ndc.component';
import { ChangeListComponent } from './components/flight/postBooking/change-list/change-list.component';
import { ChangesFormComponent } from './components/flight/postBooking/changeDatesAndFlights/changes-form/changes-form.component';
import { AirportPnrComponent } from './components/flight/postBooking/changeDatesAndFlights/airport-pnr/airport-pnr.component';
import { ChangedSearchresultComponent } from './components/flight/postBooking/changed-searchresult/changed-searchresult.component';
import { DialogLowCostConfirmChanges } from './components/flight/postBooking/changed-searchresult/changed-searchresult.component';
import { TfFareRulesComponent } from '../../components/shared/tf-fare-rules/tf-fare-rules.component';
import { PnratcComponent } from './components/flight/singlepnr/pnratc/pnratc.component';
import { SearchformatcComponent } from './components/flight/searchflight/searchformatc/searchformatc.component';
import { TemplateDialogComponent } from './components/flight/searchflight/searchresults/template-dialog/template-dialog.component';
import { TemplateDialogAtcComponent } from './components/flight/searchflight/searchresults/template-dialog-atc/template-dialog-atc.component';
import { MoneydetailPipe } from './components/flight/searchflight/searchresults/template-dialog-atc/moneydetail.pipe';
import { NewsearchformComponent } from './components/flight/searchflight/newsearchform/newsearchform.component';
import { NdcfarerulesComponent } from './components/flight/ndcfarerules/ndcfarerules.component';


@NgModule({
  declarations: [
    SearchflightComponent,
    SearchformComponent,
    SearchresultsComponent,
    BottomsheetComponent,
    StepsearchresultsComponent,
    SinglepnrComponent,
    FaresComponent,
    TstComponent,
    AncillaryComponent,
    SeatmapComponent,
    SeatmapLowCostComponent,
    AirportsComponent,
    ReservationlistComponent,
    CarriersComponent,
    FarerulesComponent,
    CurrentselectionComponent,
    CalendarresultsComponent,
    RecodialogComponent,
    FinalizelowcostComponent,
    LuggagesComponent,
    ChecklowcostpaymentComponent,
    DialogLowCostConfirm,
    DialogLowCostConfirmChanges,
    HistoryComponent,
    OrderflightsComponent,
    BottomlowcostComponent,
    AutocompleteCmComponent,
    PrintReservationComponent,
    RequiredParametersComponent,
    PnrhistoryComponent,
    RefundDialogComponent,
    PnrhistoryComponent,
    SearchresultsNDCComponent,
    ChangeListComponent,
    ChangesFormComponent,
    AirportPnrComponent,
    ChangedSearchresultComponent,
    TfFareRulesComponent,
    PnratcComponent,
    SearchformatcComponent,
    TemplateDialogComponent,
    TemplateDialogAtcComponent,
    MoneydetailPipe,
    NewsearchformComponent,
    NdcfarerulesComponent,
    // FopsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TravelersModule,
    FopsModule,
    DossierModule,
    DirectiveModuleModule,
    CustomCommonModule,
    SearchInformationModule,
    MatStepperModule
  ],
  exports: [
    ReservationlistComponent,
    AirportsComponent,
    CarriersComponent,
    MatStepperModule,
    ChangeListComponent
  ],
  providers: [
    SearchformComponent,
    AirportPnrComponent,
    TfFareRulesComponent
  ],
})
export class FlightModule{}

