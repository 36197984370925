import { Component, OnInit, Input } from '@angular/core';
import {Airports} from '../../../../classes/airports';
import {FlightService} from '../../../../../../services/flight/flight.service';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';

@Component({
  selector: 'app-airports',
  templateUrl: './airports.component.html',
  styleUrls: ['./airports.component.scss']
})
export class AirportsComponent implements OnInit {
  @Input() index: number;
  @Input() parentForm: any;
  @Input() isTransit: boolean;
  fromtext: string;
  totext: string;
  airports: Airports[];
  loading: boolean;
  constructor(
    private flightService: FlightService,
    private iataComplete: IatacompleteService,
  ) {
    this.loading = false;
    this.fromtext = '';
    this.totext = '';
  }

  ngOnInit(): void {
    this.setAirports();
  }

  searchAirport(value) {
    delete this.airports;
    if (value.length >= 2 ) {
      this.getAirportList(value);
    }
  }

  getAirportList(value) {
    delete this.airports;
    this.flightService.getAirportsList(value).subscribe((res:any) => {
      this.loading = false;

      this.airports = [];
      res.results.forEach(r=>{
        this.airports.push({name:r.name || r.cityName, iatacity : r.cityCode, iata : r.iataCode, isCity : true});
        r.airports.forEach(a=>{
          this.airports.push({name: a.name || a.detailedName.split(':')[1] ,iatacity : a.cityCode, iata : a.iataCode});
        })
      })
      console.log('this.airports => ', this.airports);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clearFrom() {
    this.fromtext = '';
    delete this.airports;
    if (!this.isTransit) {
      this.parentForm.sections[this.index].from.value = '';
      this.parentForm.sections[this.index].from.display = '';
      this.parentForm.sections[this.index].fromLegend = [];
      this.parentForm.sections[this.index].from = [];
    } else {
      this.parentForm.sections[this.index].options.viaAirport.value = '';
      this.parentForm.sections[this.index].options.viaAirport.display = '';
      this.parentForm.sections[this.index].options.viaAirport = []
    }
  }

  clearTo() {
    delete this.airports;
    this.totext = '';
    this.parentForm.sections[this.index].to.value = '';
    this.parentForm.sections[this.index].to.display = '';
    this.parentForm.sections[this.index].toLegend = [];
    this.parentForm.sections[this.index].to = [];
  }

  getFrom(value) {
    delete this.airports;
    const index = value.indexOf('-');
    const iata = value.substr(0, index);
    const indexCity = value.lastIndexOf('_');
    const iataCity = value.substr(indexCity + 1);
    if (!this.isTransit) {
      this.parentForm.sections[this.index].from = [iata];
      this.parentForm.sections[this.index].fromLegend = [iataCity];
    } else {
      this.parentForm.sections[this.index].options.viaAirport = [iata];
    }
    this.iataComplete.createToCompleteForm(iata);
    // this.fromtext = '';
  }

  getTo(value) {
    delete this.airports;
    const index = value.indexOf('-');
    const iata = value.substr(0, index);
    const indexCity = value.lastIndexOf('_');
    const iataCity = value.substr(indexCity + 1);
    this.parentForm.sections[this.index].to = [iata];
    this.parentForm.sections[this.index].toLegend = [iataCity];
    this.iataComplete.createToCompleteForm(iata);
    // this.totext = '';
  }

  selectFirst(value) {
    if (value === 'fromtext' && this.airports) {
      this.fromtext = this.airports[0].iata + ' - ' + this.airports[0].name;
      if (!this.isTransit) {
        this.parentForm.sections[this.index].from = [this.airports[0].iata];
        this.parentForm.sections[this.index].fromLegend = [this.airports[0].iatacity];
      } else {
        this.parentForm.sections[this.index].options.viaAirport = [this.airports[0].iata];
        this.fromtext = this.airports[0].iata + ' - ' + this.airports[0].name;
      }
      this.iataComplete.createToCompleteForm(this.airports[0].iata);
    } else if (value === 'totext' && this.airports) {
      this.parentForm.sections[this.index].to = [this.airports[0].iata];
      this.parentForm.sections[this.index].toLegend = [this.airports[0].iatacity];
      this.iataComplete.createToCompleteForm(this.airports[0].iata);
      this.totext = this.airports[0].iata + ' - ' + this.airports[0].name;
    }
    delete this.airports;
    // this.fromtext = '';
    // this.totext = '';
  }

  setAirports(){
    if(!this.isTransit && this.parentForm.sections[this.index].from.length > 0){
      this.fromtext = this.parentForm.sections[this.index].from[0];
    }
    if(!this.isTransit && this.parentForm.sections[this.index].to.length > 0){
      this.totext = this.parentForm.sections[this.index].to[0];
    }
    if(this.isTransit && this.parentForm.sections[this.index].options.viaAirport.length > 0){
      this.fromtext = this.parentForm.sections[this.index].options.viaAirport[0];
    }
  }

}
