import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: [ './product-info-dialog.component.scss' ]
})
export class ProductInfoDialogComponent implements OnInit {

  search: any;
  productDetails: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dialog : MatDialog,
    private dialogRef : MatDialogRef<ProductInfoDialogComponent>
  ) {}

  ngOnInit(): void {
    this.search = this.data.search;
    this.productDetails = this.data.search.productDetails;
    /*console.log(this.data)*/
  }

  confirmDialog(){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '300px',
      minWidth: '300px',
      autoFocus: false,
      data: {
        action: 'deleteSearch'
      }
    });
    dialogRef.afterClosed().subscribe((res:any)=> {
      if(res) this.dialogRef.close({delete: true, close: false, continueBook: false});
    })
  }
}
