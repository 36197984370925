import { Injectable } from '@angular/core';
import { HttpserviceService } from '../httpservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SearchRes } from '../../classes/flights/search-res';
import { Tariffes } from '../../classes/flights/tariffes';
import { Airport } from '../../classes/flights/autocomplete';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class FlightService extends HttpserviceService {
  flightUrl: string;
  oldFlightUrl: string;
  temporaryLowCostUrl = 'http://localhost:3000/api/v2/flight/';
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.flightUrl = this.apiUrl + 'flight/';
    this.oldFlightUrl = this.oldApiUrl + 'flight/';
  }

   sendFlightSearch(data): Observable<SearchRes> {
    const searchUrl = this.flightUrl + 'search';
    return this.http
      .post<SearchRes>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  sendFlightSearchTo(data): Observable<SearchRes> {
    const searchUrl = this.flightUrl + 'search';
    return this.http
      .post<SearchRes>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false))
      );
  }

  sendFlightSearchNdcAma(data){
    //return this.http.get<any>('assets/data/ama-ndc.json');
    const endPointAma = 'http://192.168.22.45:3000';
    // return this.http.get<any>('assets/data/ama-ndc.json');
    // const searchUrl = endPointAma + '/flight/search';

    const searchUrl = endPointAma + '/api/v2/flight/searchNdc';
    return this.http
      .post<SearchRes>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );

  }

  requestTariffNdcAma(fare){
    //return this.http.get<any>('assets/data/ama-ndc-tariff.json');
    const endPointAma = 'http://192.168.22.45:3000';
    const searchUrl = endPointAma + '/api/v2/flight/ndc/ndcOfferPrice';
    return this.http
      .post<SearchRes>(searchUrl, fare, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
    // return this.http.get<any>('assets/data/ama-ndc-tariff.json');
    // const searchUrl = this.flightUrl + 'searchndcama';
  }

 createOrderNdcAma(reco){
    //return this.http.get<any>('assets/data/ama-ndc-service.json');
    const endPointAma = 'http://192.168.22.45:3000';
    const searchUrl = endPointAma + '/api/v2/flight/ndc/createOffer';
    return this.http
      .post<SearchRes>(searchUrl, reco, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
    //
    // const searchUrl = this.flightUrl + 'searchndcama';
  }

  requestServiceNdcAma(data){
    //return this.http.get<any>('assets/data/ama-ndc-service.json');
    const endPointAma = 'http://192.168.22.45:3000';
    const searchUrl = endPointAma + '/api/v2/flight/ndc/serviceList';
    return this.http
      .post<SearchRes>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
    //
    // const searchUrl = this.flightUrl + 'searchndcama';
  }

  askFlightHistory(data): Observable<any> {
    const searchUrl = this.flightUrl + 'search?limit=' + data.limit + '&skip=' + data.skip;
    return this.http
      .get<any>(searchUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  askFares(data): Observable<Tariffes[]> {
    const faresUrl = this.flightUrl + 'search/tariffs';
    return this.http
      .post<Tariffes[]>(faresUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false))
      );
  }

  askFaresRules(data): Observable<any> {
    const faresUrl = this.flightUrl + 'search/tariff/rules';
    return this.http
      .post<any>(faresUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  generateReservation(data, fastIssue=false): Observable<any> {
    let linkUrl = this.flightUrl + 'pnr';
    if(fastIssue) linkUrl += '?fastIssue=true';
    return this.http
      .post<any>(linkUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getReservation(data): Observable<any> {
    const getPnrUrl = this.flightUrl + 'pnr/' + data;
    return this.http
      .get<any>(getPnrUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getReservationHistory(data): Observable<any> {
    const getPnrUrl = this.flightUrl + 'pnr/' + data + '/history';
    return this.http
      .get<any>(getPnrUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getReservationsList(data): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const getPnrListUrl = this.flightUrl + 'pnr?limit=' + data.limit + '&skip=' + data.skip + '&sort=' + data.sort + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<any>(getPnrListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  cancelReservation(data): Observable<any> {
    const deletePnrUrl = this.flightUrl + 'pnr/' + data;
    return this.http
      .delete<any>(deletePnrUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  pnrRetrieve(data): Observable<any> {
    const getRetrieveUrl = this.flightUrl + 'pnr/' + data + '/retrieve';
    return this.http
      .get<any>(getRetrieveUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTst(data): Observable<any> {
    const getTstUrl = this.flightUrl + 'pnr/' + data + '/cryptic/tst';
    return this.http
      .get<any>(getTstUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getRetrieveCryptic(data): Observable<any> {
    const getCrypticUrl = this.flightUrl + 'pnr/' + data + '/cryptic/retrieve';
    return this.http
      .get<any>(getCrypticUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTstHistory(data): Observable<any> {
    const getTstHistoryUrl = this.flightUrl + 'pnr/' + data + '/cryptic/history';
    return this.http
      .get<any>(getTstHistoryUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTsm(data): Observable<any> {
    const getTsmUrl = this.flightUrl + 'pnr/' + data + '/cryptic/tsm';
    return this.http
      .get<any>(getTsmUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getAncillary(data): Observable<any> {
    const getAncillaryUrl = this.flightUrl + 'pnr/' + data + '/ancillaries';
    return this.http
      .get<any>(getAncillaryUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getSeatMap(data): Observable<any> {
    const getSeatMapUrl = this.flightUrl + 'pnr/' + data + '/seatmap';
    return this.http
      .get<any>(getSeatMapUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  saveSeatMap(data, reservationId): Observable<any> {
    const saveSeatMapUrl = this.flightUrl + 'pnr/' + reservationId + '/seatmap';
    return this.http
      .post<any>(saveSeatMapUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  removeSeat(data, reservationId): Observable<any> {
    const removeSeatMapUrl = this.flightUrl + 'pnr/' + reservationId + '/seatmap?seat=' + data.name + '&flight=' + data.flight;
    return this.http
      .delete<any>(removeSeatMapUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  saveAncillaries(data, reservationId): Observable<any> {
    const linkUrl = this.flightUrl + 'pnr/' + reservationId + '/ancillaries';
    return this.http
      .post<any>(linkUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  removeAncillaries(data): Observable<any> {
    const linkUrl = this.flightUrl + 'pnr/' + data.reservationId + '/ancillaries?type=' + data.type + '&pax=' + data.ref;
    return this.http
      .delete<any>(linkUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getAirportsList(data): Observable<Airport> {
    const airportListUrl =this.apiUrl + 'iatabase/airport/search?q=' + data;
    return this.http
      .get<Airport>(airportListUrl, this.setHttpOptions())
      .pipe(
        retry(2),
        catchError(err => this.handleError(err))
      );
  }

  getCarriersList(data): Observable<any> {
    const airlinesListUrl = this.apiUrl + 'iatabase/airline/search' + '?q=' + data;
    return this.http
      .get<any>(airlinesListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  splitReservation(data, reservationId): Observable<any> {
    const splitUrl = this.flightUrl + 'pnr/' + reservationId + '/split';
    return this.http
      .post<any>(splitUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getReservationTariffs(data): Observable<Tariffes[]> {
    const reservationTariffsUrl = this.flightUrl + 'pnr/' + data + '/tariffs';
    return this.http
      .get<Tariffes[]>(reservationTariffsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setReservationTariff(data, id): Observable<Tariffes[]> {
    const reservationTariffsUrl = this.flightUrl + 'pnr/' + id + '/changeTariff';
    return this.http
      .post<Tariffes[]>(reservationTariffsUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  askFareRules(data, id): Observable<any> {
    const fareRulesUrl = this.flightUrl + 'pnr/' + id + '/tariff/notes?tariffClass=' + data.tariffClass + '&refs=[' + data.refs + ']';
    return this.http
      .get<any>(fareRulesUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  askFareRulesFromSearch(data, flights): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const fareRulesUrl = this.flightUrl + 'search/tariff/notes?tariffClass=' + data.tariffClass + '&refs=[' + data.refs + ']' + '&flights=' + JSON.stringify(flights);
    return this.http
      .get<any>(fareRulesUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  rePriceReservation(id, data): Observable<any> {
    const bookUrl = this.flightUrl + 'pnr/' + id + '/rePrice';
    return this.http
      .post<any>(bookUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false))
      );
  }

  bookReservation(id, data): Observable<any> {
    const bookUrl = this.flightUrl + 'pnr/' + id + '/book';
    return this.http
      .post<any>(bookUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  bookReservationNDC(data): Observable<any> {
    const endPointAma = 'http://192.168.22.45:3000';
    const bookUrl = endPointAma + '/api/v2/flight/ndc/ndcPayment';
    // const bookUrl = bookhUrl;
    return this.http
      .post<any>(bookUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  cancelReservationNdc(data): Observable<any> {
    const endPointAma = 'http://192.168.22.45:3000';
    const deleteUrl = endPointAma + '/api/v2/flight/ndc/void';
    return this.http
      .post<any>(deleteUrl,data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  cancelReservationNdcRes(data): Observable<any> {
    const endPointAma = 'http://192.168.22.45:3000';
    const deleteUrl = endPointAma + '/api/v2/flight/ndc/cancel';
    return this.http
      .post<any>(deleteUrl,data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  confirm3dsPayment(pnr, body) {
    const check3dsUrl = this.flightUrl + 'pnr/' + pnr + '/3ds';
    return this.http
               .post<any>(check3dsUrl, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  confirm3dsPaymentLowCost(pnr, body) {
    // const check3dsUrl = this.temporaryLowCostUrl + 'pnr/' + pnr + '/lowCost/3ds';
    const check3dsUrl = this.flightUrl + 'pnr/' + pnr + '/lowCost/3ds';
    return this.http
      .post<any>(check3dsUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  sendByMail(id, data): Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/' + id + '/send';
    return this.http
      .post<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  saveNotes(id, data): Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/' + id + '/notes';
    return this.http
      .post<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  sendFlightSearchLowCost(data): Observable<SearchRes> {
    const searchUrl = this.flightUrl + 'search/lowCost';
    // const searchUrl = this.temporaryLowCostUrl + 'search';
    return this.http
      .post<SearchRes>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false, 'NO_LOW_COST_FOUND_FOR_REQUESTED_ITINERARY'))
      );
  }

  sendFlightSearchLowCostNDC(data): Observable<SearchRes> {
    const searchUrl = this.flightUrl + 'search/lowCost?ndc=true';
    // const searchUrl = this.temporaryLowCostUrl + 'search/lowCost?ndc=true';
    return this.http
               .post<SearchRes>(searchUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err, false, 'NO_LOW_COST_FOUND_FOR_REQUESTED_ITINERARY'))
               );
  }

  // this.flightUrl +
  // 'http://192.168.2.207:3000/'

  processDetLowCost(data): Observable<any> {
    const searchUrl = this.flightUrl + 'search/lowCost/select';
    // const searchUrl = this.temporaryLowCostUrl + 'selectFligths';
    return this.http
      .post<any>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  processTermsLowCost(data, id): Observable<any> {
    // const searchUrl = this.temporaryLowCostUrl + 'processTerms';
    const searchUrl = this.flightUrl + 'pnr/' + id + '/lowCost/checkPrice';
    return this.http
      .post<any>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  processCheckBook(data, id): Observable<any> {
    // const searchUrl = this.temporaryLowCostUrl + 'startBooking';
    const searchUrl = this.flightUrl + 'pnr/' + id + '/lowCost/book';
    return this.http
      .post<any>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  processConfirm3DS(data, id): Observable<any> {
    // const searchUrl = this.temporaryLowCostUrl + 'startBooking';
    const searchUrl = this.flightUrl + 'pnr/' + id + '/lowCost/3ds';
    return this.http
               .post<any>(searchUrl, data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  checkBooking(data, id): Observable<any> {
    const searchUrl = this.flightUrl + 'pnr/' + id + '/lowCost/cardVerification';
    // const searchUrl = this.temporaryLowCostUrl + 'cardVerified';
    return this.http
      .post<any>(searchUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCorporateCode(_id) : Observable<any>{
    return this.http
               .get<any>(`${this.apiUrl}profile/company/${_id}/corporate/flight`, this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  getBlockedRates() : Observable<any>{
    return this.http
               .get<any>(`${this.flightUrl}manage`, this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  postBlockedRates(obj) : Observable<any>{
    return this.http
               .post<any>(`${this.flightUrl}manage/${obj._id}`, obj, this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  getAuthorizationFroCarrier(iata) : Observable<any>{
    return this.http
               .get<any>(`${this.flightUrl}airline/setup?iata=${iata}`, this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  getSingleHistory(data): Observable<any> {
    const getSingleHistoryUrl = this.flightUrl + 'search/' + data;
    return this.http
      .get<any>(getSingleHistoryUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  reorderPnrFligths(id, data): Observable<any> {
    const postUrl = this.flightUrl + 'pnr/' + id + '/itineraries';
    return this.http
      .post<any>(postUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  removeWheelchair(pnr: string, paxIndex: any){
    const removeWheelchair = this.flightUrl + 'pnr/' + pnr + '/passengers/wheelchair/' + paxIndex;
    return this.http
               .delete<any>(removeWheelchair, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  addWheelchair(pnr, paxIndex : any, wheelchair: string){
    const addWheelchair = this.flightUrl + 'pnr/' + pnr + '/passengers/wheelchair/' + paxIndex;
    const body = { type: wheelchair };
    return this.http
               .post<any>(addWheelchair, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  addFq(pnrId, paxIndex: any, fq: any) {
    const addFq = this.flightUrl + 'pnr/' + pnrId + '/passengers/frequentFlyer/' + paxIndex;
    const body = {
      number: fq.number,
      iata: fq.iata,
    };
    return this.http
      .post<any>(addFq, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  deleteFq(pnrId, paxIndex: any) {
    const delFq = this.flightUrl + 'pnr/' + pnrId + '/passengers/frequentFlyer/' + paxIndex;
    return this.http
      .delete<any>(delFq, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  postReservationTasf(data): Observable<any> {
    const tasfPnrUrl = this.flightUrl + 'pnr/' + data + '/tasf';
    return this.http
      .post<any>(tasfPnrUrl, {}, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  saveApprovaId(id, data): Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/' + id;
    return this.http
      .put<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getTransactions(locator){
    const txUrl = this.oldApiUrl +  'v3/transaction/' + locator;
    return this.http
               .get<any>(txUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err, false))
               );
  }

  penaltiesFromPnr(data){
    const sendUrl = this.flightUrl+ 'pnr/' + data + '/tariff/rules';
    // const sendUrl = this.temporaryLowCostUrl + 'postBooking/book';
    const body = {id:data};
    return this.http
      .post<any>(sendUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  penaltiesFromPnrMini(data){
    const sendUrl = this.flightUrl+ 'pnr/' + data + '/tariff/minirules';
    // const sendUrl = this.temporaryLowCostUrl + 'postBooking/book';
    const body = {id:data};
    return this.http
      .post<any>(sendUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  penaltiesFromSearch(data){
    const sendUrl = this.flightUrl+ 'search/tariff/rules';
    return this.http
      .post<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  penaltiesFromSearchMini(data){
    console.log('minirules');
    const sendUrl = this.flightUrl+ 'search/tariff/minirules';
    return this.http
      .post<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  openRefund(data):Observable<any> {
    const sendUrl = this.flightUrl+ 'pnr/' + data.id + '/atc/refund';
    // const sendUrl = this.temporaryLowCostUrl + 'postBooking/book';
    return this.http
      .post<any>(sendUrl,data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, false))
      );
  }

  patchRefund(data):Observable<any> {
    const sendUrl = this.flightUrl+ 'pnr/' + data.id + '/atc/refund';
    // const sendUrl = this.temporaryLowCostUrl + 'postBooking/book';
    return this.http
      .patch<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getAvailableChanges(id):Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/'+ id +'/lowCost/postBooking/'+ id;
    //const sendUrl = this.temporaryLowCostUrl  + 'pnr/'+ id +'/lowCost/postBooking/'+ id;
    return this.http
               .post<any>(sendUrl,{}, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  cancelTFRequest(id):Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/'+ id +'/lowCost/postBooking/'+ id +'/cancel';
    // const sendUrl = this.temporaryLowCostUrl + 'pnr/'+ id +'/lowCost/postBooking/'+ id +'/cancel';
    return this.http
               .post<any>(sendUrl,{}, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  startChangeBooking(data):Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/'+ data[0].id +'/lowCost/postBooking/'+ data[0].id +'/makingChanges';
    // const sendUrl = this.temporaryLowCostUrl  + 'pnr/'+ data[0].id +'/lowCost/postBooking/'+ data[0].id +'/makingChanges';
    return this.http
               .post<any>(sendUrl,data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  processDetailsChangesBook(data):Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/'+ data.pnrId +'/lowCost/postBooking/processDetails';
    // const sendUrl = this.temporaryLowCostUrl + 'pnr/'+ data.pnrId +'/lowCost/postBooking/processDetails';
    return this.http
               .post<any>(sendUrl,data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  processTermsChangesBook(data):Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/'+ data.pnrId +'/lowCost/postBooking/processTerms';
    // const sendUrl = this.temporaryLowCostUrl + 'pnr/'+ data.pnrId +'/lowCost/postBooking/processTerms';
    return this.http
               .post<any>(sendUrl,data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  startBookingFlow(data):Observable<any> {
    const sendUrl = this.flightUrl+ 'pnr/'+ data.id +'/lowCost/postBooking/book';
    // const sendUrl = this.temporaryLowCostUrl + 'pnr/'+ data.id +'/lowCost/postBooking/book';
    return this.http
               .post<any>(sendUrl,data, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err))
               );
  }

  availableAtc(data):Observable<any> {
    const sendUrl = this.flightUrl + 'airline/' + data + '/atc';
    return this.http
      .get<any>(sendUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setFlightCostCenter(id, data):Observable<any> {
    const sendUrl = this.flightUrl + 'pnr/' + id + '/costCenter';
    // const sendUrl = this.temporaryLowCostUrl + 'postBooking/book';
    return this.http
      .post<any>(sendUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }
  // pnr/id/costCenter body{costCenter:'blablabla'}

  // ATC
    getHistory(pnrId : string){
        const historyUrl = this.flightUrl + 'pnr/' + pnrId + '/history';
        return this.http
                   .post<any>(historyUrl, {}, this.setHttpOptions())
                   .pipe(
                       retry(0),
                       catchError(err => this.handleError(err))
                   );
    }

    getEligibility(pnrId : string){
        const eligibilityUrl = this.flightUrl + 'pnr/' + pnrId + '/atc/eligibility';
        return this.http
                   .get<any>(eligibilityUrl, this.setHttpOptions())
                   .pipe(
                       retry(0),
                       catchError(err => this.handleError(err))
                   );
    }

    sendFlightSearchATC(pnrId, data): Observable<SearchRes> {
        const eligibilityUrl = this.flightUrl + 'pnr/' + pnrId + '/atc/eligibility';
        return this.http
                   .patch<SearchRes>(eligibilityUrl, data, this.setHttpOptions())
                   .pipe(
                       retry(0),
                       catchError(err => this.handleError(err))
                   );
    }

    airRebookATC(pnrId, data) {
        const eligibilityUrl = this.flightUrl + 'pnr/' + pnrId + '/atc/eligibility';
        return this.http
                   .post<any>(eligibilityUrl, data, this.setHttpOptions())
                   .pipe(
                       retry(0),
                       catchError(err => this.handleError(err))
                   );
    }

    confirmReissue(pnrId, data) {
        const eligibilityUrl = this.flightUrl + 'pnr/' + pnrId + '/atc/reissue';
        return this.http
                   .post<any>(eligibilityUrl, data, this.setHttpOptions())
                   .pipe(
                       retry(0),
                       catchError(err => this.handleError(err))
                   );
    }

    confirmChangesTF(data) {
      const confirmPath = this.flightUrl + 'cron/confirmTFChanges';
      //const confirmPath = this.temporaryLowCostUrl + 'cron/confirmTFChanges';

      return this.http
        .post<any>(confirmPath, data, this.setHttpOptions())
        .pipe(
          retry(0),
          catchError(err=>this.handleError(err))
        )
    }

}
