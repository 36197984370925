import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {HttpserviceService} from '../../../services/httpservice.service';
import {Router} from '@angular/router';
import {SnackErrorComponent} from '../../../modules/errorNotification/snackerror/snack-error.component';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchesService extends HttpserviceService {
  private policyEndpointBis = 'policy/';

  constructor(
    public router: Router,
    public httpClient: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, httpClient, snackComponent);
  }

  getAll(): Observable<any> {
    const path = this.policyEndpointBis + 'search';
    return this.http
      .get<any>(this.apiUrl + path, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  getSingleSearch(id:string): Observable<any> {
    const path = this.policyEndpointBis + 'search/';
    return this.http
      .get<any>(this.apiUrl + path + id, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
  }

  deleteSearch(id:string): Observable<any> {
    const path = this.policyEndpointBis + 'search/';
    return this.http
      .delete<any>(this.apiUrl + path + id, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err)),
      );
    // return this.http.delete(`${this.url}/${id}`);
  }
}
