import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CorporateCode} from '../../../../../companies/components/companiescompo/company/corporate-codes/corporate-codes.component';
import {Airports} from '../../../../../flight/classes/airports';
import {Subject} from 'rxjs';
import {ApiService} from '../../../../../../services/api.service';
import {HotelService} from '../../../../hotel.service';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {CompaniesService} from '../../../../../../services/profile/companies.service';
import {Router} from '@angular/router';
import {FlightService} from '../../../../../../services/flight/flight.service';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';
import * as $ from 'jquery';
import {AppComponent} from '../../../../../../app.component';
declare var google : any;
@Component({
  selector: 'app-search-form-v3',
  templateUrl: './search-form-v3.component.html',
  styleUrls: ['./search-form-v3.component.scss']
})
export class SearchFormV3Component implements OnInit, OnDestroy {
  filters: any;
  /**
   *  Input for hotel Search form
   */
  @Input() hotelSearch;
  /**
   *  Input for singleOccupancy
   */
  @Input() singleOccupancy;
  /**
   *  Input for search result
   */
  @Input() hotelRes;
  @Input() hotelDb;

  @Input() hotelResV2;
  @Input() favorites;
  /**
   *  Output for search result update
   */
    // tslint:disable-next-line:no-output-rename
  @Output('update') change : EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('updateFilters') changeFilters : EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('updatev2') changev2 : EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-native no-output-rename
  @Output('reset') resetForm : EventEmitter<any> = new EventEmitter<any>();
  /**
   *  object used for push guest in room guests array
   */
  guest = { code: 10, quantity: 1 };
  /**
   *  set hotel form hotelRating
   */
  hotelRating = [
    { code: 1, name: 'ONE_STAR' },
    { code: 2, name: 'TWO_STAR' },
    { code: 3, name: 'THREE_STAR' },
    { code: 4, name: 'FOUR_STAR' },
    { code: 5, name: 'FIVE_STAR' },
  ];
  /**
   *  local variable for room categories
   */
  roomCategories = [
    { name: 'Budget room', code: 'I' },
    { name: 'Business room', code: 'B' },
    { name: 'Comfort room', code: 'G' },
    { name: 'Deluxe room', code: 'D' },
    { name: 'Duplex', code: 'X' },
    { name: 'Executive room', code: 'E' },
    { name: 'Concierge/Executive Suite', code: 'C' },
    { name: 'Family room', code: 'F' },
    { name: 'Junior Suite/Mini Suite', code: 'S' },
    { name: 'Penthouse', code: 'P' },
    { name: 'Residential apartment', code: 'R' },
    { name: 'Standard', code: 'M' },
    { name: 'Studio', code: 'L' },
    { name: 'Superior', code: 'S' },
    { name: 'Villa', code: 'V' },
    { name: 'Unknown', code: '*' },
  ];
  /**
   *  local variable for room bed types
   */
  bedTypes = [
    { name: 'Double', code: 'D' },
    { name: 'King size', code: 'K' },
    { name: 'Pull-out', code: 'P' },
    { name: 'Queen-size', code: 'Q' },
    { name: 'Single', code: 'S' },
    { name: 'Twin', code: 'T' },
    { name: 'Water bed', code: 'W' },
  ];
  /**
   *  local variable for loading status
   */
  loading : boolean;
  /**
   *  local variable for today
   */
  today = new Date();
  showCheckOut = true;
  loadingSecondo : boolean;

  selected : any;
  filtering : any = [];
  hotelsList : any;
  hotels : any;
  hotelText : string;
  currentLoad : number;
  counter : any;
  starSelections : string[];
  corporates : CorporateCode[];
  totalCount = 0;
  searchType: any;
  types: string[] = ['interest', 'citycode', 'name'];
  text: string;
  airports: Airports[];
  editing: any;
  currentEditing: any;
  keywords: any = [];
  editingName: string;
  protected ngUnsubscribe : Subject<void> = new Subject<void>();
  private isDestroyed = false;
  searchFavorite = false;
  favoriteCode: string[] = [];
  filteredOptions: any;
  filterValue: string = ''
  othersOptionsOpen: boolean = false;
  /**
   * The constructor
   * @params apiService
   * @params hotelService
   */
  constructor(
    private apiService : ApiService,
    private hotelService : HotelService,
    public mainComponent : MainComponent,
    private api : CompaniesService,
    private router: Router,
    private flightService: FlightService,
    private iataComplete: IatacompleteService,
    public appComponent: AppComponent,
  ){
    this.loading = false;
    this.currentLoad = 0;
    this.searchType = 'interest'
    this.text = '';
    // this.children = {age: '', };
  }

  /**
   *  Fire the guests count in current search form
   */
  ngOnInit() : void{
    if (this.hotelSearch.occupancy[0].guests.length < 1){
      this.addGuestType(0, 10);
    }
    this.getCorporates();
    // this.hotelSearch.ratePlans=[];
    /*
     this.api.getCorporateCode(
     'hotel',
     this.mainComponent.loggedUser.company._id,
     ).subscribe(data => this.corporates = data.list);
     */

    if(this.favorites && this.favorites.length > 0){
      this.favorites.sort((a, b) => a.city.localeCompare(b.city))
    }

    this.setOptionsFav();
    setTimeout(() => {
      this.addList();
    }, 200);

  }

  getCorporates(){
    this.api.getCorporateCode(
      'hotel',
      this.mainComponent.loggedUser.company._id,
    ).subscribe(data => this.corporates = data.list);
  }

  ngOnDestroy() : void{
    this.isDestroyed = true;
  }

  starChanged(){
    console.log('1 this.hotelSearch.options.stars ', this.hotelSearch.options.stars);
    if (this.hotelSearch.options.stars.length < 3){
      this.starSelections = this.hotelSearch.options.stars;
    } else{
      this.hotelSearch.options.stars = this.starSelections;
    }
    console.log('1 this.starSelections ', this.starSelections);
    console.log('2 this.hotelSearch.options.stars ', this.hotelSearch.options.stars);
  }

  clearFrom(){
    this.hotelText = '';
    delete this.hotelsList;
    delete this.selected;
  }

  selectHotel(value){
    console.log('entro => ', value);
    this.selected = value;
    this.hotelSearch.hotels = [];
    this.selected.codes.forEach(c => {
      this.hotelSearch.hotels.push(c);
    });
    delete this.hotelsList;
    console.log('this.hotelSearch => ', this.hotelSearch);
    console.log('this.selected) => ', this.selected);
    this.hotelDb = [];
    this.hotelDb.push(this.selected);
    // this.clearFrom();
    // this.hotelText = '';
  }

  isValidForm(){
    if (this.searchType === 'name'){
      this.hotelSearch.iata = undefined;
      if (this.hotelSearch.hotels.length > 0){
        return true;
      }
    } else if(this.searchType === 'interest'){
      this.hotelSearch.iata = undefined;
      if (this.hotelSearch.destination.display){
        return true;
      }
    } else {
      if (this.hotelSearch.iata){
        return true;
      }
    }
  }

  isValidQuoteForm(){
    return !!this.favoriteCode;
  }

  selectFirst(){
    if (this.hotelsList){
      this.hotelSearch.hotels = [];
      this.selected = this.hotelsList[0];
      this.selected.codes.forEach(c => {
        this.hotelSearch.hotels.push(c);
      });
      this.hotelDb = [];
      this.hotelDb.push(this.selected);
      delete this.hotelsList;
    }
    // this.clearFrom();
  }

  searchHotel(value){
    delete this.hotelsList;
    this.clearFrom();
    if (value.length >= 2){ this.getHotelsList(value); }
  }

  getHotelsList(value){
    this.hotelSearch.options.stars = [];
    this.clearFrom();
    this.hotelService.sendHotelName(value).subscribe((res) => {
      this.loading = false;
      this.hotelsList = res;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  checkChange(event){
    this.hotelSearch.nameSearch = event;
    this.clearmodel();
  }

  resetSearchForm(){
    localStorage.removeItem('searchHotelRes');
    localStorage.removeItem('searchHotelForm');
    localStorage.removeItem('hotels2');
    this.clearmodel();
    this.resetForm.emit();
    this.addGuestType(0, 10);
  }

  /**
   *  Add room to search form and fire guests count function
   */
  addRoom(){
    const occupancy = JSON.parse(JSON.stringify(this.singleOccupancy));
    this.hotelSearch.occupancy.push(occupancy);
    this.addGuest(this.hotelSearch.occupancy.length - 1);
    this.returnTotalGuests();
  }

  /**
   *  Open modal to manage rooms and guest
   */
  toggleGuestsModal(){
    $('#addGuestModal').modal('toggle');
  }

  /**
   *  Remove room from search form rooms using index
   */
  removeRoom(value){
    this.hotelSearch.occupancy.splice(value, 1);
  }

  /**
   *  Add adult for index room
   */
  addGuest(value){
    const guest = JSON.parse(JSON.stringify(this.guest));
    this.hotelSearch.occupancy[value].guests.push(guest);
  }

  strictMode(event){
    this.hotelSearch.strict = event;
  }

  selectCache(event){
    this.hotelSearch.cache = event;
  }

  /**
   *  Listener for destination input field, implement google place autocomplete and set destination display, coordinates and radius
   */
  addList(){
    const input = document.getElementById('pac-input') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length === 0){
        return;
      } else{
        console.log('place => ', places);
        console.log('-> lat => ', places[0].geometry.location.lat());
        console.log('-> lng => ', places[0].geometry.location.lng());

        this.hotelSearch.destination = {
          zoneId: places[0].place_id,
          display: places[0].formatted_address,
          coordinates: [places[0].geometry.location.lng(), places[0].geometry.location.lat()],
          radius: this.hotelSearch.destination.radius,
        };

      }
    });
  }

  /**
   *  Reset model for destination: display, coordinates and radius
   */
  clearmodel(){
    this.hotelSearch.hotels = [];
    this.hotelSearch.destination = {
      zoneId: '',
      display: '',
      coordinates: [],
      radius:1,
    };
    this.hotelSearch.options.stars = [3, 4, 5];
  }

  /**
   *  Collapse toggle to open search options
   */
  toggleOptions(justOpen = false){
    if ((justOpen && !this.othersOptionsOpen) || !justOpen){
      $('#collapseOptions').collapse('toggle');
      this.othersOptionsOpen = !this.othersOptionsOpen;
    }
    //$('#formOptions').collapse('toggle');
  }

  changeType(event){
    if (event === 'name'){
      this.hotelSearch.nameSearch = true;
      delete this.hotelSearch.destination;
    } else if(event === 'citycode'){
      this.hotelSearch.nameSearch = false;
      delete this.hotelSearch.destination;
    } else if (event === 'interest') {
      this.hotelSearch.nameSearch = false;
      this.hotelSearch.destination = {
        zoneId: '',
        display: '',
        coordinates: [],
        radius:1,
      };
    }
  }

  /**
   *  Reset option type to empty array
   */
  resetOptionType(value){
    this.hotelSearch.options[value] = [];
  }

  startCounter(){
    this.currentLoad = 0;
    this.counter = setInterval(() => {
      this.currentLoad = this.currentLoad + 1;
      if (this.currentLoad >= 100){
        this.stopCounter();
      }
    }, 200);
  }

  stopCounter(){
    clearInterval(this.counter);
  }

  /**
   *  Start hotel search
   */

  pushRoom(resp){
    resp.hotels.forEach(hotel => {
      hotel.roomsV2 = [];
      hotel.rooms.forEach(room => {
        if (hotel.roomsV2.length <= hotel.rooms.length){
          resp.rooms[room].index = room;
          hotel.roomsV2.push(resp.rooms[room]);
          if (!hotel.hotelReference){
            hotel.hotelReferenceFull = resp.rooms[room].referenceID;
            hotel.hotelReference = resp.rooms[room].referenceID.split('.')[0];

          }
        }
      });
      hotel.roomsV2.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
    });
    return resp;
  }

  /**
   *  Add guest to single room
   */
  addGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
      age: 12,
    };
    if (type !== 5){
      delete newGuest.age;
    }
    const index = this.hotelSearch.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    if (index === - 1 || type === 5){
      this.hotelSearch.occupancy[i].guests.push(newGuest);
    } else{
      this.hotelSearch.occupancy[i].guests[index].quantity = this.hotelSearch.occupancy[i].guests[index].quantity + newGuest.quantity;
    }
    console.log(this.hotelSearch.occupancy[i].guests);
  }

  /**
   *  Remove guest from single room
   */
  removeGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
    };
    const index = this.hotelSearch.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    this.hotelSearch.occupancy[i].guests[index].quantity = this.hotelSearch.occupancy[i].guests[index].quantity - newGuest.quantity;
    if (this.hotelSearch.occupancy[i].guests[index].quantity === 0){
      this.hotelSearch.occupancy[i].guests.splice(index, 1);
    }
    console.log(this.hotelSearch.occupancy);
  }

  /**
   *  Return adults count
   */
  returnAdt(i){
    if (this.hotelSearch.occupancy[i].guests.find(r => r.code === 10)){
      return this.hotelSearch.occupancy[i].guests.find(r => r.code === 10).quantity;
    } else{
      return 0;
    }
  }

  /**
   *  Return over 65 count
   */
  returnOver(i){
    if (this.hotelSearch.occupancy[i].guests.find(r => r.code === 2)){
      return this.hotelSearch.occupancy[i].guests.find(r => r.code === 2).quantity;
    } else{
      return 0;
    }
  }

  /**
   *  Return children count
   */
  returnChd(i){
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.hotelSearch.occupancy[i].guests.length; c ++){
      if (this.hotelSearch.occupancy[i].guests[c].code === 5){
        quantity = quantity + 1;
      }
    }
    return quantity;
  }

  /**
   *  Calculate guests in search form
   */
  returnTotalGuests() : number{
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.hotelSearch.occupancy.length; i ++){
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < this.hotelSearch.occupancy[i].guests.length; c ++){
        quantity = quantity + this.hotelSearch.occupancy[i].guests[c].quantity;
      }
    }
    return quantity;
  }

  setCheckOutDate(){
    const checkin = new Date(this.hotelSearch.checkIn).getTime();
    const checkout = new Date(this.hotelSearch.checkOut).getTime();
    if (checkin > checkout){
      this.showCheckOut = false;
      this.hotelSearch.checkOut = new Date(new Date(checkin).setDate(new Date(this.hotelSearch.checkIn).getDate() + 1));
      setTimeout(() => {
        this.showCheckOut = true;
      }, 100);
    }
    console.log('this.hotelSearch.checkOut => ', this.hotelSearch.checkOut);
  }

  startSearch(): void {
    this.totalCount = 0;
    delete this.hotelSearch.time;
    this.startCounter();
    localStorage.removeItem('hotels2');
    delete this.hotelRes;
    delete this.filters;
    this.change.emit(this.hotelRes);
    this.changeFilters.emit(this.filters);
    this.loading = true;
    this.hotelSearch.checkIn = new Date(new Date(this.hotelSearch.checkIn).setHours(6));
    this.hotelSearch.checkOut = new Date(new Date(this.hotelSearch.checkOut).setHours(6));
    this.hotelSearch.id = null;
    // localStorage.setItem('HotelForm2', JSON.stringify(this.hotelSearch));
    if (!this.hotelSearch.nameSearch){
      this.callApiSearch();
    } else {
      this.StarthotelSearch();
    }
  }

  quote(){
    // console.log(this.favoriteCode);
    // tslint:disable-next-line:max-line-length
    const url = this.router.serializeUrl(this.router.createUrlTree(['/hotel/quotation'], { queryParams: { code: [this.favoriteCode], checkIn: this.hotelSearch.checkIn, checkOut: this.hotelSearch.checkOut, occupancy: JSON.stringify(this.hotelSearch.occupancy) } }));
    // console.log(url);
    window.open(url, '_self');
    // tslint:disable-next-line:max-line-length
    // this.router.navigate(['/hotel/quotation'], { queryParams: { code, checkIn: this.hotelRes.form.checkIn, checkout: this.hotelRes.form.checkOut, occupancy: JSON.stringify(this.hotelRes.form.occupancy) } });
  }

  StarthotelSearch(){
    // this.startCounter();
    if (this.hotelRes){
      delete this.hotelRes;
      this.change.emit(this.hotelRes);
    }
    // this.loading = true;
    this.hotelSearch.checkIn = new Date(new Date(this.hotelSearch.checkIn).setHours(6));
    this.hotelSearch.checkOut = new Date(new Date(this.hotelSearch.checkOut).setHours(6));
    delete this.hotelSearch.destination;
    this.quote();
    /*
    this.hotelService.sendHotelSearch(this.hotelSearch).subscribe(resp => {
      this.loading = false;
      this.hotelRes = resp;
      this.hotelRes = this.pushRoom(resp);
      for (let i = 0; i < this.hotelDb.length; i ++){
        const dbHotel = this.hotelDb[i];
        for (let sh = 0; sh < resp.hotels.length; sh ++){
          const finded = dbHotel.codes.findIndex(r => r === resp.hotels[sh].hotelCode);
          if (dbHotel.referenceID === resp.hotels[sh].hotelReference){
            if (!this.hotelDb[i].hotels){
              this.hotelDb[i].hotels = [];
            }
            resp.hotels[sh].session = resp.session;
            this.hotelDb[i].hotels.push(resp.hotels[sh]);
          }
        }
      }
      this.changev2.emit(this.hotelDb);
      this.change.emit(this.hotelRes);
    }, error => {
      console.error(error);
      this.loading = false;
    });
    */

  }

  callApiSearch():void {
    this.hotelSearch.nameSearch = false;
    this.hotelService.sendHotelSearch_V3(this.hotelSearch).subscribe(resp => {
      this.loading = false;
      this.hotelRes = resp;
      this.change.emit(this.hotelRes);
    }, error => {
      console.error(error);
      this.loading = false;
    });
  }

  callFilters(id:any): void {
    this.hotelService.getFiltersSearch_V2(id).subscribe(resp => {
      this.filters = resp;
      if (this.filters.rating) {
        this.filters.rating.sort((a, b) => a - b);
        // this.seletedfilters = JSON.parse(JSON.stringify(this.filters));
      }
      // localStorage.setItem('hotels2Filters', JSON.stringify(this.filters));
      this.changeFilters.emit(this.filters);
    });
  }

  searchAirport(value) {
    delete this.airports;
    if (value.length >= 2 ) {
      this.getAirportList(value);
    }
  }

  getAirportList(value) {
    delete this.airports;
    this.flightService.getAirportsList(value).subscribe((res:any) => {
      this.loading = false;

      this.airports = [];
      res.results.forEach(r=>{
        this.airports.push({name:r.name || r.cityName, iatacity : r.cityCode, iata : r.iataCode, isCity : true, id: r.id});
        r.airports.forEach(a=>{
          this.airports.push({name: a.name || a.detailedName.split(':')[1] ,iatacity : a.cityCode, iata : a.iataCode, id:a.id});
        })
      });
      console.log('this.airports => ', this.airports);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clearForm() {
    this.hotelSearch.iata = '';
    this.text = '';
    delete this.airports;
  }

  getFrom(value) {
    this.text = value.iataCode || value.iatacity + ' ' + value.name;
    this.hotelSearch.iata = value.iata || value.iatacity;
  }

  selectFirstCity() {
    if (this.airports) {
      this.hotelSearch.iata = this.airports[0].iata || this.airports[0].iatacity;
      this.text = this.airports[0].iata || this.airports[0].iatacity + ' ' + this.airports[0].name;
    }
    delete this.airports;
  }

  setOptionsFav(){
    this.filteredOptions = JSON.parse(JSON.stringify(this.favorites))
  }

  filterFav(event) {
    console.log(event);
    this.filteredOptions = [];
    this.filterValue = event.toLowerCase();
    this.favorites.forEach((f:any) => {
      if(f.name.toLowerCase().includes(this.filterValue) || f.city.toLowerCase().includes(this.filterValue)){
        this.filteredOptions.push(f);
      };
    })
    console.log('this.favorites', this.favorites)
  }

}
