import {
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  of,
} from 'rxjs';
import {
  debounceTime, filter,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  GetReservationRequest,
  SearchCarnetBookingRequest,
  SearchTrainsResponse,
  TrainReservation,
  TrainReservationList,
  TrenitTraveller,
} from '../../../classes/train.models';
import { TrainMainComponent } from '../../common/train-main/train-main.component';
import { TrainService } from '../../../train.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {MainComponent} from "../../../../mainmodule/main/main.component";

/**
 * main component for handling search parametrs
 */
@Component({
  selector: 'train-use-carnets',
  styleUrls: ['./train-use-carnets.component.scss'],
  templateUrl: './train-use-carnets.component.html',
})
export class TrainUseCarnetsComponent extends TrainMainComponent{
  @Output() toggle = new EventEmitter<any>();

  // Autocomplete
  isCarnetLoading = false;
  filteredCarnetList : TrainReservationList;

  // Form
  formVisible = true;
  searchForm : UntypedFormGroup;
  travellers : TrenitTraveller[] = [];
  departureStartDate = new Date();

  // Search
  searching$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchResults : SearchTrainsResponse;

  /**
   * @param fb FormBuilder
   * @param service TrainService instance for http cals
   * @param router
   * @param titleService
   * @param translate
   * @param router
   * @param mainComponent
   */
  constructor(
    private fb : UntypedFormBuilder,
    private service : TrainService,
    public router : Router,
    public titleService : Title,
    public translate : TranslateService,
    private mainComponent: MainComponent
  ){
    super(router, titleService, translate);
  }

  ngOnInit() : void{
    this.initSearchForm();
  }

  ngAfterViewInit(){
    this.searchForm.patchValue(this.currentSearch);
  }

  toggleSearchForm(event?){
    this.toggle.emit(event);
  }

  removePax(index : number){
    this.travellers.splice(index, 1);
  }

  initSearchForm(){
    this.searchForm = this.fb.group({
      departure_date: [undefined, [Validators.required]],
      carnetId: [undefined, Validators.required],
    });
    this.initCarnetSearch('carnetId');
  }

  resetForm(){
    delete this.searchResults;
    delete this.currentSearch;
    localStorage.removeItem('CURRENT_SEARCH')
    localStorage.removeItem('TRAIN_TRAVEL')
    /*super.updateCurrentSearch();*/
    this.initSearchForm();
  }

  displayCarnetFn(carnet : TrainReservation) : string{ return (carnet) ? carnet.pnr : ''; };

  getCarnets(){
    return (this.filteredCarnetList) ? this.filteredCarnetList?.reservations : [];
  }

  /**
   * @param $event Submit event
   * send the search params to the server
   */
  onSubmit($event?) : void{
    delete this.searchResults;
    delete this.currentSearch;
    super.updateCurrentSearch();
    const searchTrainRequest = this.searchForm.getRawValue();
    const req : SearchCarnetBookingRequest = {
      carnetId: searchTrainRequest.carnetId?.pnr,
      departure_date: searchTrainRequest.departure_date,
    };
    this.searching$.next(true);
    this.service.searchTrainsForCarnet(req).subscribe((response : SearchTrainsResponse) => {
      this.searchResults = response;
      this.currentSearch = searchTrainRequest;
      super.updateCurrentSearch();
      const traveller = response.travellers;
      traveller._id = searchTrainRequest.carnetId?.travellers[0].doc._id;
      this.trainTravel = {
        ...this.trainTravel,
        travellers: [traveller],
      };
      super.updateTrainTravel();
      this.searching$.next(false);
    }, _ => this.searching$.next(false));
  }

  searchCarnets($event : MouseEvent){
    const prevValue = this.searchForm.get('carnetId').value;
    if (!prevValue){
      this.searchForm.get('carnetId').setValue(' ');
    }
  }

  private initCarnetSearch(field : string) : void{
    const req = new GetReservationRequest();
    req.carnets = true;
    this.searchForm.get(field).valueChanges.pipe(
      debounceTime(500),
      skipWhile((val) => val.toString() === '[object Object]' || val.toString() === ''),
      tap(() => this.isCarnetLoading = true),
      switchMap((value) => (typeof value === 'object' || !!this.filteredCarnetList) ? of([null]) : this.service.getReservationList(req)),
    ).subscribe((res : TrainReservationList) => {
      if (!this.filteredCarnetList){
        this.filteredCarnetList = this.filterForUser(res);
      }
      this.isCarnetLoading = false;
    }, _ => {
      this.isCarnetLoading = false;
      this.initCarnetSearch('carnetId');
    });
  }

  filterForUser(result:any){
    if (!this.mainComponent.loggedUser.role.superAdmin){
      const filtered = result.reservations.filter((r:any)=> r.travellers[0].doc._id === this.mainComponent.loggedUser._id);
      result.reservations = filtered.length > 0 ? filtered : [];
      return result
    } else return result
  }
}
