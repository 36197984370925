import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NewTraveller} from '../../../../../classes/travelers/newTraveller';
import {TravelersService} from '../../../../../services/travelers/travelers.service';
import * as CU from '../../../../../../assets/json/countries.json';
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  @Input() parent: any;
  @Input() newTraveler: any;
  @Input() fromReservation: boolean;
  @Input() trDate: any;
  @Input() infantMaxAge: number = 2;
  @Input() childMaxAge: number = 12;
  @Input() passPort: boolean;
  // tslint:disable-next-line:no-output-rename
  @Output('refreshList') change: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename no-output-native
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('setTraveler') setTraveler: EventEmitter<any> = new EventEmitter<any>();
  startDate = new Date(1980, 0, 1);
  today = new Date();
  suggestionList: any;
  loading: boolean;
  ageError: boolean;
  countriesSl: any;
  @Input() hotel?: any;
  constructor(
    private travelersService: TravelersService,
  ) {
    if (!this.newTraveler) {
      this.newTraveler = new NewTraveller();
    }
    this.ageError = false;
  }

  ngOnInit(): void {
    this.setNewTraveler();
    this.countriesSl = CU;
    console.log('countriesSl ',  this.countriesSl);
  }

  setNewTraveler(): void {
    this.newTraveler = {
      name: '',
      surname: '',
      phoneNumber: '',
      email: '',
      birthday: undefined,
      sex: '',
      type: (this.parent === 'searchResults') ? this.newTraveler.type || '' : '',
      saved: true,
      costCenter: [],
      addressBook: '',
      document: {
        type: 'PAS',
        expiryDate: undefined,
        nationality: '',
        number: '',
        issuingCountry: '',
      },
    }
  }

  returnType(age) {
    /*
    const ageMap = {};
    [...Array(this.infantMaxAge).keys()].forEach(age => ageMap[age] = 'INF');
    [...Array(this.childMaxAge-this.infantMaxAge).keys()].map(i => i + this.infantMaxAge+1).forEach(age => ageMap[age] = 'CHD');
    return ageMap[age] || 'ADT';*/
    if(!this.hotel){
      if (age <= 11 && age >= 2) {return 'CHD'; }
      if (age < 2) {return 'INF'; }
      if (age > 11) {return 'ADT'; }
    } else {
      if (age <= 17) {return 'CHD'; }
      if (age > 17) {return 'ADT'; }
    }
  }

  calcAge(): number {
    const birthDate = new Date(this.newTraveler.birthday);
    let age = this.today.getFullYear() - birthDate.getFullYear();
    const m = this.today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && this.today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.newTraveler.type = this.returnType(age);
    return age;
  }

  suggestion(event) {
    if (this.parent !== 'travelersList') {
      if (event.length > 3) {
        delete this.suggestionList;
        this.travelersService.getSuggestedTraveler(event).subscribe((res) => {
          // this.loading = false;
          this.suggestionList = res;
          console.log('this.suggestionList => ', this.suggestionList);
        }, error => {
          this.loading = false;
          console.error('Error => ', error);
        });
      } else {
        delete this.suggestionList;
      }
    }
  }

  selectSuggested(pax) {
    this.newTraveler = pax;
    delete this.suggestionList;
    console.log('this.newTraveler =>', this.newTraveler);
    if (!this.newTraveler.birthday) {
      this.newTraveler.type = 'ADT';
    } else {
      this.calcAge();
    }
    this.setTraveler.emit(this.newTraveler);
    this.close.emit();
  }

  closeModal(): void {
    this.setNewTraveler();
    delete this.suggestionList;
    this.close.emit();
  }

  saveTraveler(): void {
    this.loading = true;
    if(this.newTraveler.document && this.newTraveler.document.expiryDate !== ''){
      const dateInNumber =  new Date(this.newTraveler.document.expiryDate).setHours(5,0,0,0);
      const newDate = new Date(dateInNumber);
      this.newTraveler.document.expiryDate = newDate;
    }
    if (!this.newTraveler.email) { delete this.newTraveler.email; }
    if (this.newTraveler.birthday) {
      this.newTraveler.birthday = new Date(new Date(this.newTraveler.birthday).setHours(6));
    }
    this.travelersService.addTraveler(this.newTraveler).subscribe((res) => {
      this.loading = false;
      if (this.parent === 'travelersList') {
        this.change.emit();
      }
      if (this.parent === 'searchResults' || this.parent === 'trainCompany') {
        this.setTraveler.emit({
          ...this.newTraveler,
          ...res,
          addressBook: res._id,
        });
      }
      this.setNewTraveler();
      this.close.emit();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  namesControll(value){
    const regex = /^[a-zA-Z\d\-\_ ]+$/; // "
    return regex.test(value);
  }

  travelerController() {

    if (this.newTraveler.type === 'INF' || this.newTraveler.type === 'CHD'){
      return (!this.newTraveler.name || !this.newTraveler.surname || !this.newTraveler.birthday || this.ageError);
    } else if(this.newTraveler.name && this.newTraveler.surname && !this.newTraveler.email){
      return true
    }else {
      return (!this.newTraveler.name || !this.newTraveler.surname || this.ageError);
    }
  }

  newCalcAge(): number {
    let date = new Date();
    if (this.trDate) {
      date = new Date(this.trDate);
    } else {
      date = this.today;
    }
    const birthDate = new Date(this.newTraveler.birthday);
    let age = date.getFullYear() - birthDate.getFullYear();
    const m = date.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && date.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age);
    return age;
  }

  dateEvent(event) {
    this.ageError = false;
    const date = new Date(event.value);
    if (this.newTraveler.type) {
      const age = this.newCalcAge();
      const type = this.returnType(age);
      if (type !== this.newTraveler.type) {
        this.ageError = true;
      }
    }
    console.log(date);
  }

  phoneChange(event){
    const regex = /[0-9]/g;
    const found = event.match(regex);
    if(!found) {
      this.newTraveler.phoneNumber = '';
    } else if(event.length !== found.length){
        this.newTraveler.phoneNumber = '';
      }
  }
}
