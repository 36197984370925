<h1 mat-dialog-title>
  {{'POLICY.DELETE'|translate}}
</h1>
<div mat-dialog-content>
  <h4 class="text-center">{{'POLICY.DELETE_ITEM'|translate}}  {{'POLICY.THE_POLICY'|translate}}</h4>
  <h4 class="text-center">{{ data.name + '?' }}</h4>
</div>
<div mat-dialog-actions class="buttonrowBis d-flex justify-content-end align-self-center">
  <button mat-raised-button [mat-dialog-close]="false">{{'POLICY.CANCEL'|translate}}</button>
  <button mat-raised-button [mat-dialog-close]="true" color="warn">{{'CONFIRM'|translate}}</button>
</div>
