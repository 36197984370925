import { Injectable } from '@angular/core';
import { HttpserviceService } from '../httpservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CompaniesList } from '../../classes/companieslist';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import {Fops} from '../../modules/companies/classes/fops';
import {Company} from '../../classes/company';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends HttpserviceService {
  profileUrl: string;
  uploadUrl: string;
  constructor(
    public router: Router,
    public http: HttpClient,
    public snackComponent: SnackErrorComponent,
  ) {
    super(router, http, snackComponent);
    this.profileUrl = this.apiUrl;
    this.uploadUrl = this.oldApiUrl;
  }

  getCompaniesList(data): Observable<CompaniesList> {
    // tslint:disable-next-line:max-line-length
    const companiesListUrl = this.profileUrl + 'profile/company/list' + '?sort=' + data.sort + '&limit=' + data.limit + '&skip=' + data.skip + '&order=' + data.order + '&text=' + data.text + '&options=' + JSON.stringify(data.options);
    return this.http
      .get<CompaniesList>(companiesListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCompaniesAuto(data): Observable<CompaniesList> {
    const airportListUrl = this.profileUrl + 'profile/autocomplete/companies?q=' + data;
    return this.http
      .get<CompaniesList>(airportListUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getOperative(data): Observable<any> {
    const companyUrl = this.profileUrl + 'siap-ws/company/get-company-data';
    return this.http
      .post<any>(companyUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCompany(id): Observable<Company> {
    const companyUrl = this.profileUrl + 'profile/company/' + id;
    return this.http
      .get<Company>(companyUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCpSettings(id): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/' + id + '/services';
    return this.http
      .get<any>(companyUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  postCpSettings(id, data): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/' + id + '/services';
    return this.http
      .post<any>(companyUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  postB2cSettings(data): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/b2c/settings';
    return this.http
      .post<any>(companyUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  putB2cSettings(data): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/b2c/settings';
    return this.http
      .put<any>(companyUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getB2cSettings(data): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/b2c/settings?id=' + data;

    return this.http
      .get<any>(companyUrl, this.setHttpOptionsNoAuth())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  updateCompany(id, data): Observable<Company> {
    const updateCompanyUrl = this.profileUrl + 'profile/company/' + id;
    return this.http
      .post<Company>(updateCompanyUrl, data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, true, 'AN_ERROR_OCCURRED'))
      );
  }

  uploadImage(id, data) {
    const uploadUrl = this.uploadUrl + 'static/company/' + id;
    //const uploadUrl = this.profileUrl + 'static/company/' + id;
    return this.http
      .post(uploadUrl, data, this.setHttpOptionsImage())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  // FORM OF PAYMENTS

  getFops(id, user?): Observable<Fops[]> {
    let fopsUrl = this.profileUrl + 'profile/company/' + id + '/fops';
    if(user){
      fopsUrl = this.profileUrl + 'profile/user/' + id + '/fops';
    }
    return this.http
      .get<Fops[]>(fopsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  deleteFop(company, indexes, user?) {
    const body = {
      indexes,
    };
    let deleteMethodUrl = this.profileUrl + 'profile/company/' + company + '/fops';
    if(user){
      deleteMethodUrl = this.profileUrl + 'profile/user/' + company + '/fops';
    }
    return this.http
      .delete(deleteMethodUrl, this.setHttpOptionsWithBody(body))
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getMethods(id, notification = true): Observable<any> {
    const methodsUrl = this.profileUrl + 'profile/company/' + id + '/paymentMethod?details=true';
    return this.http
      .get<any>(methodsUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, notification))
      );
  }

  saveMethod(id, method) {
    const saveMethodUrl = this.profileUrl + 'profile/company/' + id + '/paymentMethod';
    return this.http
      .put(saveMethodUrl, method, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  deleteMethod(company, id) {
    const deleteMethodUrl = this.profileUrl + 'profile/company/' + company + '/paymentMethod/' + id;
    return this.http
      .delete(deleteMethodUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  saveFop(id, fop, user?) {
    let saveFopUrl = this.profileUrl + 'profile/company/' + id + '/fops';
    if(user){
      saveFopUrl = this.profileUrl + 'profile/user/' + id + '/fops';
    }
    return this.http
      .put(saveFopUrl, fop, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  setToken(id, token) {
    const saveTokenUrl = this.profileUrl + 'profile/company/' + id + '/pin';
    return this.http
      .post(saveTokenUrl, token, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err, true, 'AN_ERROR_OCCURRED'))
      );
  }

  getCorporateCode(srv:string,_id) : Observable<any>{
    return this.http
               .get<any>(`${this.profileUrl}profile/company/${_id}/corporate/${srv}`, this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  saveCorporateCode(srv:string,_id, corporate) : Observable<any>{
    return this.http
               .put<any>(`${this.profileUrl}profile/company/${_id}/corporate/${srv}`, corporate,this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  deleteCorporateCode(srv:string,_id, corporateId) : Observable<any>{
    return this.http
               .delete<any>(`${this.profileUrl}profile/company/${_id}/corporate/${srv}/${corporateId}`,this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  updateCorporateCode(srv:string,_id, corporate) : Observable<any>{
    return this.http
               .post<any>(`${this.profileUrl}profile/company/${_id}/corporate/${srv}/${corporate._id}`,corporate, this.setHttpOptions())
               .pipe(retry(0), catchError(err => this.handleError(err)));
  }

  getIncentives(): Observable<any> {
    const incentiveUrl = this.profileUrl + 'profile/company/incentive';
    return this.http
      .get<any>(incentiveUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  editInc(obj): Observable<any> {
    const incentiveUrl = this.profileUrl + 'profile/company/incentive/' + obj._id;
    return this.http
      .put<any>(incentiveUrl,obj, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  addInc(obj): Observable<any> {
    const incentiveUrl = this.profileUrl + 'profile/company/incentive/';
    return this.http
      .put<any>(incentiveUrl,obj, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  getCompanyRemark(id): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/customRemarks?company=' + id;
    return this.http
      .get<Company>(companyUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  postCompanyRemark(body): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/customRemarks';
    return this.http
      .post<Company>(companyUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  putCompanyRemark(body): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/customRemarks';
    return this.http
      .post<Company>(companyUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  deleteCompanyRemark(id): Observable<any> {
    const companyUrl = this.profileUrl + 'profile/company/customRemarks?company=' + id;
    return this.http
      .delete<Company>(companyUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
      );
  }

  //customFields
  getCustomFields(id): Observable<any>{
    const companyUrl = this.profileUrl + 'profile/company/' + id + '/customFields';
    return this.http
      .get<Company>(companyUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  deleteCustomFields(id): Observable<any>{
    const companyUrl = this.profileUrl + 'profile/company/' + id + '/customFields';
    return this.http
      .delete<Company>(companyUrl, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  postCustomFields(body, id): Observable<any>{
    const companyUrl = this.profileUrl + 'profile/company/' + id + '/customFields';
    return this.http
      .post<Company>(companyUrl, body, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
}


