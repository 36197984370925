import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FlightService } from 'src/app/services/flight/flight.service';

@Component({
  selector: 'app-ndcfarerules',
  templateUrl: './ndcfarerules.component.html',
  styleUrls: ['./ndcfarerules.component.scss']
})
export class NdcfarerulesComponent implements OnInit {
  rules = ['CANCEL_AFTERDEPARTURE', 'CANCEL_BEFOREDEPARTURE', 'CHANGE_AFTERDEPARTURE', 'CHANGE_BEFOREDEPARTURE'];
  loadingFare = true;
  currentTab: number;
  penalties: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private flightService: FlightService,
  ) { }

  ngOnInit(): void {

    if(this.data.ndcPnr){
      console.log('offer => ', this.data.offer);

      this.data.offer.details.forEach((d:any) => {
        const entity = {
          name: d.name,
          rules: [],
        }
        this.penalties.push(entity);
      })
      this.data.services.forEach((p:any) => {
        const parts = p.split('|');
        const index = parts[0];
        const rule = {
          descId: parts[1],
          descText: parts[2]
        }
        this.penalties[index].rules.push(rule)
      })
      console.log('penalties => ', this.penalties);
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.currentTab = tabChangeEvent.index;
  }

  isRule(service){
    return this.rules.includes(service.descId);
  }
}
