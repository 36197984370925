import {Component, OnInit, Input, Inject, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {SearchRes} from '../../../../../../classes/flights/search-res';
import * as $ from 'jquery';
import {Tariffes} from '../../../../../../classes/flights/tariffes';
import {FlightService} from '../../../../../../services/flight/flight.service';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NewTraveller} from '../../../../../../classes/travelers/newTraveller';
import {MainComponent} from '../../../../../mainmodule/main/main.component';
import {CompaniesService} from '../../../../../../services/profile/companies.service';
import {animate, group, style, transition, trigger} from '@angular/animations';
import * as fareData from './ratesDetailsSupportedCompany.json';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {PriceService} from '../../../../../../services/price/price.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {IatacompleteService} from '../../../../../../services/iatacomplete/iatacomplete.service';
import {DossierService} from '../../../../../../services/dossier/dossier.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../../../../../app.component';
import {TravelersService} from '../../../../../../services/travelers/travelers.service';
import {FarerulesComponent} from '../../farerules/farerules.component';
import {BottomlowcostComponent} from '../bottomlowcost/bottomlowcost.component';
import { RateDetails } from './RatesDetails';
// const ListSupportedRatesDetails = import ('./ratesDetailsSupportedCompany.json');
import * as CU from '../../../../../../../assets/json/countries.json';
import { mark } from '@angular/compiler-cli/src/ngtsc/perf/src/clock';
import { any } from 'codelyzer/util/function';
import {TemplateDialogComponent} from './template-dialog/template-dialog.component';
import {TemplateDialogAtcComponent} from './template-dialog-atc/template-dialog-atc.component';
import { NdcfarerulesComponent } from '../../ndcfarerules/ndcfarerules.component';
import {HelperFunctionsService} from "../../../../../../services/helper/helper-functions.service";
@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.scss'],
  animations: [
    trigger('listItemAnimation', [
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '!' }))]),
        // although group is useless here (since we have only one animation)
        // i like to leave it anyway just in case i want to add another
        // parallel animation in the future
      ]),
      transition(':leave', [
        style({ height: '!', overflow: 'hidden' }),
        group([animate('250ms ease-out', style({ height: '0px' }))]),
      ]),
    ]),
  ]
})
export class SearchresultsComponent implements OnInit, OnDestroy {
  @Input() atc: any;
  @Input() searchResults: SearchRes;
  @Input() selections: any;
  @Input() request: any;
  @Input() lowCost: boolean;
  @Input() ndcAma: boolean = false;
  @Input() ndc: boolean;
  @Input() tourOp: boolean;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  obs: Observable<any>;
  dataSource: MatTableDataSource<any>;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10];
  pageEvent: PageEvent;
  loadingTar: boolean;
  currentLoad: any;
  error: any;
  company: any;
  carriersAvailable: string[];
  carrierToFilter: string[];
  minPrice: number;
  maxPrice: number;
  maxPriceModel: number;
  minPriceModel: number;
  allPrice: number[];
  travellers: NewTraveller[];
  newTraveler: NewTraveller;
  filteredRes: any;
  currentPage = 0;
  requestedPage = 1;
  paginatorLength: number;
  availableStops: any[];
  selectedStops: any[];
  filterTime = ['no_preference', 'morning', 'afternoon', 'evening', 'night'];
  defaultFilter = [];
  filterTimeMin = [];
  filterTimeMax = [];
  cacheId: string;
  ListSupportedRatesDetails = [];
  reservation:{};
  showNotes:boolean;
  reservationNotes:any;
  filterActive = [];
  fareFIlter = [];

  /**
   *  Local variable notifier service
   */
  private readonly notifier: NotifierService;
  formvisible: boolean;
  traLow: any;
  availableLowTarifs:any = [];
  sharedTarifs:any = [];
  private pnrId : string | undefined;
  ndcSelection: any;
  constructor(
    private flightService : FlightService,
    private companyService : CompaniesService,
    private bottomSheet : MatBottomSheet,
    private newBottonSheet: MatDialog,
    public dialog : MatDialog,
    public mainComponent : MainComponent,
    private router : Router,
    notifierService : NotifierService,
    public priceservice : PriceService,
    private changeDetectorRef : ChangeDetectorRef,
    public iataComplete : IatacompleteService,
    public appComponent : AppComponent,
    private route : ActivatedRoute,
    private helper: HelperFunctionsService
  ){
    this.ListSupportedRatesDetails = [

    ];
    this.notifier = notifierService;
    this.travellers = [];
    this.carriersAvailable = [];
    this.carrierToFilter = [];
    this.allPrice = [];
    this.minPrice = 0;
    this.maxPriceModel = 0;
    this.minPriceModel = 0;
    this.maxPrice = 0;
    this.availableStops = [];
    this.selectedStops = [];
    this.reservation = {};
    this.fareFIlter = [];
    this.route.queryParams.subscribe(params => {
      if(params.cacheId) this.cacheId = params.cacheId;
    });
    this.route.params.subscribe(params => {
      this.pnrId = params.id;
    });
    this.traLow = {supplier:'ryanair',Feature:[{Type:'SmallCabinBag',Label:'',Options:[[{Provision:'Bundled',Dimensions:'40 x 20 x 25 cm'}]]},{Type:'LargeCabinBag',Label:'',Options:[[{Provision:'Bundled',SupplierClass:'Flexi Plus',Weight:'10 Kg',Dimensions:'55 x 40 x 20 cm'}],[{Provision:'Bundled',SupplierClass:'Regular',Weight:'10 Kg',Dimensions:'55 x 40 x 20 cm'}]]},{Type:'SpeedyBoarding',Label:'',Options:[[{Provision:'Bundled',SupplierClass:'Flexi Plus'}],[{Provision:'Bundled',SupplierClass:'Regular'}]]},{Type:'Seat',Label:'Standard Seat',Options:[[{Provision:'Bundled',SupplierClass:'Regular'}],[{Provision:'Bundled',SupplierClass:'Plus'}],[{Provision:'Bundled',SupplierClass:'Flexi Plus'}]]},{Type:'Seat',Label:'Front Seat',Options:[[{Provision:'Bundled',SupplierClass:'Flexi Plus'}]]},{Type:'Seat',Label:'Extra Legroom Seat',Options:[[{Provision:'Bundled',SupplierClass:'Flexi Plus'}]]},{Type:'Product',Label:'Free airport check-in',Options:[[{Provision:'Bundled',SupplierClass:'Plus'}],[{Provision:'Bundled',SupplierClass:'Flexi Plus'}]]},{Type:'Product',Label:'Flexible tickets',Options:[[{Provision:'Bundled',SupplierClass:'Flexi Plus'}]]},{Type:'Product',Label:'2 x 20kg Check-in Bags',Options:[[{Provision:'Bundled',SupplierClass:'Family Plus',ChargeModel:'PerBooking'}]]},{Type:'Product',Label:'Security Fast Track',Options:[[{Provision:'Bundled',SupplierClass:'Flexi Plus'}]]}]};
    // this.elaborateLow(this.traLow);
    this.showNotes = false;
    this.reservationNotes = '';
  }
  /**
   * Set original price to every recommendation
   * Set available stops in filter
   * Set available number of travelers
   */

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    console.log('request =>', this.request);
    if (this.lowCost) {
      if(this.searchResults.infoRates){
        this.elaborateLow(this.searchResults.infoRates);
      }
      else{
        console.log('Non ci sono le descrizioni tariffe');
      }
    }
    const fareElement = {
      marketing: undefined,
      fares: []
    };

    for (let i = 0; i < this.searchResults.recommendations.length; i++) {
      this.searchResults.recommendations[i].originalPrice = this.searchResults.recommendations[i].fare.amount;
      if(this.searchResults.recommendations[i].lowCost && this.lowCost) {
        const element = this.fareFIlter.find( el => el.marketing === this.searchResults.recommendations[i].marketingCarrier);
        if(!element){
          let fareElement = { marketing: undefined,fares: [] };
          fareElement.marketing = this.searchResults.recommendations[i].marketingCarrier;
          for(const c of this.searchResults.recommendations[i].cabins){
            const fare = {
              tarif: c.split('=')[1].split('*')[0],
              selected: true
            };
            const exist = fareElement.fares.find(ele => fare.tarif === ele.tarif);
            if(!exist)
              fareElement.fares.push(fare);
          }
          this.fareFIlter.push(fareElement);
          fareElement = {marketing: undefined, fares: []};
        } else {
          for(const c of this.searchResults.recommendations[i].cabins){
            const fare = {
              tarif: c.split('=')[1].split('*')[0],
              selected: true
            };
            if(!element.fares.find(el => el.tarif === fare.tarif))
              element.fares.push(fare);
          }
        }
      }
      // console.log('fareFilter -> '+ JSON.stringify(this.fareFIlter))
    }
    if(this.ndcAma){
      this.searchResults.recommendations.forEach((r:any, i) => {
        this.returnCurrentOffer(r, i)
      })
    }

    const stops = [];
    for (let i = 0; i < this.searchResults.itineraries.length; i++) {
      this.defaultFilter.push('no_preference');
      this.filterTimeMin.push(0);
      this.filterTimeMax.push(24);
      this.availableStops.push(stops);

      for (let s = 0; s < this.searchResults.itineraries[i].sections.length; s ++) {
        const sect = this.searchResults.itineraries[i].sections[s];
        if (!this.availableStops[i].includes(sect.flights.length - 1)) {
          this.availableStops[i].push(sect.flights.length - 1);
        }
      }
      this.availableStops[i].sort( (a, b) => b - a);
    }
    for (let i = 0; i < this.searchResults.itineraries.length; i++) {
      this.selectedStops.push(this.availableStops[i][0]);
    }
    // this.availableStops.sort( (a, b) => b - a);
    // this.selectedStops = this.availableStops[0];
    console.log('this.availableStops', this.availableStops);
    console.log('this.selectedStops', this.selectedStops);

    const totalPax = this.request.passengers.adt + this.request.passengers.chd + this.request.passengers.inf;
    for (let i = 0; i < this.request.passengers.adt; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'ADT';
      this.travellers.push(traveler);
    }
    for (let i = 0; i < this.request.passengers.chd; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'CHD';
      this.travellers.push(traveler);
    }
    for (let i = 0; i < this.request.passengers.inf; i++) {
      const traveler = new NewTraveller();
      traveler.type = 'INF';
      this.travellers.push(traveler);
    }

    this.getCompany();
    if(this.ndcAma){
      this.manageNdcAmaCarrier()
    }
    this.pushCarrier();

  }

  manageNdcAmaCarrier(){
    this.searchResults.recommendations.forEach((r:any, i:number) => {
      console.log('r.marketingCarrier 1 => ', r.marketingCarrier);
      r.itineraries.forEach((iti:any, it:number) => {
        console.log('r.marketingCarrier 2 => ', r.marketingCarrier);
        iti.sections.forEach((s:any) => {
          r.marketingCarrier = this.searchResults.itineraries[it].sections[s.ref-1].flights[0].marketingCarrier;
          console.log('r.marketingCarrier 3 => ', r.marketingCarrier);
        })

      })
    })

    //searchResults.itineraries[i].sections[section.ref-1].flights[0].marketingCarrier
  }

  ngOnDestroy(): void {
    delete this.atc;
    delete this.searchResults;
    delete this.selections;
    delete this.request;
    delete this.lowCost;
    delete this.ndc;
    delete this.tourOp;
    delete this.obs;
    delete this.dataSource;
    delete this.carriersAvailable;
    delete this.carrierToFilter;
    delete this.minPrice;
    delete this.maxPrice;
    delete this.maxPriceModel;
    delete this.minPriceModel;
    delete this.allPrice;
    delete this.travellers;
    delete this.newTraveler;
    delete this.filteredRes;
    delete this.currentPage;
    delete this.requestedPage;
    delete this.paginatorLength;
    delete this.availableStops;
    delete this.selectedStops;
    delete this.filterTime;
    delete this.defaultFilter;
    delete this.filterTimeMin;
    delete this.filterTimeMax;
    delete this.cacheId;
    delete this.ListSupportedRatesDetails;
    delete this.reservation;
    delete this.showNotes;
    delete this.reservationNotes;
    delete this.filterActive;
    delete this.fareFIlter;
    console.log('cancellato');
  }
  /**
   * Get current user company informations
   */
  getCompany() {
    this.companyService.getCompany(this.mainComponent.loggedUser.company._id).subscribe((res) => {
      this.company = res;
    });
  }

  goToPage(pageNumber){
    this.paginatorLength = Math.ceil(this.paginator.length/this.pageSize);
    this.paginator.pageIndex = pageNumber;
    this.paginator.page.next({
      pageIndex: pageNumber,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    })
  }

  returnPagesNumber(){
    const value = (this.paginator) ? Math.ceil(this.paginator.length/this.pageSize) : 0;
    return value;
  }

  returnPageLength(){
    const value = (this.paginator) ? this.paginator.length/this.pageSize : 0;
    return Array(
      Math.ceil(
        value
      )
    )
  }

  isLufthansaGroup(marketing){
    if( marketing === 'OS' || marketing === 'LH' || marketing === 'LX' || marketing === 'SN' || marketing === 'EW') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Toggle search form visibility
   */
  toggleForm() {
    this.formvisible = ! this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }
  /**
   * Expand selected flight section
   */
  opeSect(rec, it, value) {
    $('#collapse' + rec + it + value).collapse('toggle');
    $('#itinerary' + rec + it + value).collapse('toggle');
  }

  /**
   * Convert millisecond to dd:hh:mm
   */
  returnTime(t) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(t / cd);
    let h = Math.floor( (t - d * cd) / ch);
    let m = Math.round( (t - d * cd - h * ch) / 60000);
    const pad = ((n) => n < 10 ? '0' + n : n);
    if ( m === 60 ) {
      h++;
      m = 0;
    }
    if ( h === 24 ) {
      d++;
      h = 0;
    }
    if (d > 0) {
      return [d + 'g ' + pad(h) + 'h', pad(m) + 'm'].join(':');
    } else {
      return [pad(h) + 'h', pad(m) + 'm'].join(':');
    }

  }
  /**
   * Return value with 0 before or after (for time)
   */
  returnZeroTime(value) {
    if (typeof value !== 'string') {
      if (value < 10) {
        return '0' + value;
      } else {
        return value;
      }
    } else {
      return value;
    }

  }
  /**
   * Check if flight is selected
   */
  selectionFunction(ref, iti, refIti) {
    try {

      return (this.selections[ref][iti] === refIti);
    } catch (err) {
      // console.log('err => ', err);
    }

  }


  /**
   * Change current flights selections
   */
  changeSelection(ref, iti, refIti) {
    if (this.selections[ref][iti] === refIti) { return; } else { this.selections[ref][iti] = refIti; }
  }

  changeSelectionAma(rec, r, ref, iti, refIti) {
    if (this.selections[ref][iti] === refIti) {
      this.returnCurrentOffer(rec, r);
      console.log(this.selections);
       return;
    } else {
      this.selections[ref][iti] = refIti;
      console.log(this.selections);
      this.returnCurrentOffer(rec, r);
    }

  }
  /**
   * Return itinerary total time
   */
  calculateFlightTime(departure, arrival) {
    departure = new Date(departure).getTime();
    arrival = new Date(arrival).getTime();
    const flightTime = arrival - departure;
    const duration = this.returnTime(flightTime);
    return duration;
  }
  /**
   * Calculate margin between public amount and negotiated amount
   */
  calculateMargin(a, b, c) {
    return Math.round(((a - b - c) * 100) / 100);
  }

  calculateGat(a, b) {
    return Math.round(((a - b ) * 100) / 100);
  }

  /**
   * Ask fares for selected recommendation and flights
   */
  askFares(ref, r, expand) {
    if (!this.filteredRes[r].rates) {
      this.currentLoad = r;
      this.filteredRes[r].rates = [];
      this.loadingTar = true;
      // tslint:disable-next-line:no-shadowed-variable
      const originalRec = this.searchResults.recommendations.find(r => r.ref === ref);
      const recommendation = JSON.parse(JSON.stringify(originalRec));
      for (let i = 0; i < recommendation.itineraries.length; i++) {
        recommendation.itineraries[i] = this.searchResults.itineraries[i].sections[this.selections[ref][i] - 1];
      }
      // console.log('recommendation =>', recommendation);
      recommendation.options = JSON.parse(JSON.stringify(this.request.options));
      if (this.tourOp){
        recommendation.options.tourOperator = true;
      }
      if (this.atc) {
        recommendation.reissue = true;
        const refs = this.atc?.itineraries.flatMap(it => it.flights).flatMap(f => f.ref);
        recommendation.tariff = {
          details: [{refs}]
        }
        recommendation.rates = [{
          details: [{refs}],
          selected: true,
          passengers: this.atc?.tariff.passengers
        }]
        const iof = this.searchResults.recommendations.indexOf(originalRec);
        this.searchResults.recommendations[iof] = recommendation;
        this.filteredRes[r].rates = recommendation.rates;
        delete this.currentLoad;
        this.loadingTar = false;
    } else {
        this.flightService.askFares(recommendation).subscribe((res: Tariffes[]) => {
          // console.log('res askFares=> ', res);
          delete this.currentLoad;
          if(this.filteredRes && this.filteredRes[r]){
            this.filteredRes[r].rates = res;
            for (let rate = 0; rate < this.filteredRes[r].rates.length; rate++) {
              if (rate === 0) {
                this.filteredRes[r].rates[rate].selected = true;
                this.filteredRes[r].tariff = this.filteredRes[r].rates[rate];
                /*
                if (this.filteredRes[r].rates[rate].fare.publicAmount) {
                  this.filteredRes[r].rates[rate].negotiated = true;
                  recommendation.negotiated = true;
                }
                */
              } else {
                this.filteredRes[r].rates[rate].selected = false;
              }
              if(this.tourOp && (this.filteredRes[r].rates[rate].publicAmount < this.filteredRes[r].rates[rate].amount) || !this.filteredRes[r].rates[rate].publicAmount){
                this.filteredRes[r].rates[rate].publicAmount = this.filteredRes[r].rates[rate].amount;
              }
            }
            this.loadingTar = false;
            this.filteredRes[r].fare = this.filteredRes[r].rates[0].fare;
            localStorage.removeItem('searchRES');
            localStorage.setItem('searchRES', JSON.stringify(this.searchResults));

            if (!this.filteredRes[r].negotiated || this.filteredRes[r].expand) {
              this.filteredRes[r].showFares = true;
            }
            if (expand) {
              this.filteredRes[r].showFares = false;
            }
            if (!this.filteredRes[r].rates[0].negotiated && !this.tourOp) {
              this.filteredRes[r].negotiated = false;
            }
          }


        }, error => {
          // this.notifier.notify('error', error.errorMessage);
          delete this.currentLoad;
          this.loadingTar = false;
          this.error = error;
          console.log('error => ', error);
        });
      }
    }

  }

  /**
   * Change view for single recommendation from flights to fares
   */
  toggleShowFare(r) {
    this.filteredRes[r + this.currentPage].showFares = ! this.filteredRes[r + this.currentPage].showFares;
  }
  /**
   * Open bottomsheet to display fare information
   */
  selectFare(f, r) {
    // tslint:disable-next-line:prefer-for-of
    for (let rate = 0; rate < this.filteredRes[r].rates.length; rate++) {
      this.filteredRes[r].rates[rate].selected = false;

    }
    this.filteredRes[r].rates[f].selected = true;
    this.filteredRes[r].fare = this.filteredRes[r].rates[f].fare;

    this.filteredRes[r].tariff = this.filteredRes[r].rates[f];
    if(this.tourOp && (this.filteredRes[r].fare.publicAmount < this.filteredRes[r].fare.amount) || !this.filteredRes[r].fare.publicAmount){
      this.filteredRes[r].fare.publicAmount = this.filteredRes[r].fare.amount;
    }


    // console.log('this.reco => ', this.filteredRes[r]);
    if (this.filteredRes[r].rates[f].negotiated) {
      this.filteredRes[r].negotiated = true;
    } else if (this.tourOp) {
      /*this.filteredRes[r].negotiated = this.filteredRes[r].negotiated;*/
    } else {
      this.filteredRes[r].negotiated = false;
    }
    if (this.filteredRes[r].rates[f].passengers.ADT) {
      this.filteredRes[r].paxFare.adt = this.filteredRes[r].rates[f].passengers.ADT.fare;
    }
    if (this.filteredRes[r].rates[f].passengers.CHD) {
      this.filteredRes[r].paxFare.chd = this.filteredRes[r].rates[f].passengers.CHD.fare;
    }
    if (this.filteredRes[r].rates[f].passengers.INF) {
      this.filteredRes[r].paxFare.inf = this.filteredRes[r].rates[f].passengers.INF.fare;
    }
    console.log('this.filteredRes[r] => ', this.filteredRes[r]);
    console.log('this.filteredRes[r].rates[f] => ', this.filteredRes[r].rates[f]);
    if(this.filteredRes[r].rates[f].arrayOfAlerts){
      this.filteredRes[r].arrayOfAlerts = this.filteredRes[r].rates[f].arrayOfAlerts
    }
  }
  /**
   * Open bottomsheet to display fare information
   */
  openBottomSheet(fare) {
    this.bottomSheet.open(BottomsheetComponent, {
      data: {fare},
    });
  }

  openBottomSheetLow(cabins, mk, reco) {
    if(typeof reco === 'string') reco = { name: reco, description: ''};
    if(!Array.isArray(reco)) reco = [reco];
    let info;
    if(Array.isArray(cabins) && cabins.length === 2){
      info = this.getRatesData(mk, reco, cabins.length);
    } else {
      info = this.getRatesData(mk, reco);
    }

    this.newBottonSheet.open(BottomlowcostComponent, {
      data: {cabins, mk, reco,info},
      panelClass: 'customLowCostStyle',
      autoFocus: false
    });
    console.log('per aprire il modale prende questi parametri ' + JSON.stringify(cabins), mk, reco);
  }
  /**
   * Check if flight is in a different date of departure
   */
  otherday(value, other) {
    return value !== other;
  }

  getRatesData(marketing, tarif, tarifNum = 1){
    const dataList = {...fareData};
    const moreRet: any =[];
    let toRet:any = {};
    for(let y=0; y< tarifNum; y++) {
      toRet = {};
      for(let i=0; i< dataList.length; i++){
        if(dataList[i].marketingCarrier.toUpperCase() === marketing.toUpperCase()){
          toRet.supplier = dataList[i].supplier;
          for(const tar of dataList[i].tarifs){
            if(tar.name.toUpperCase() === (tarif[y].name.toUpperCase() ||'')){
              toRet.tarif = tar;
            }
          }
          moreRet.push(toRet);
        }
      }
    }
    return moreRet;
  }
  /**
   * Open dialog for selected recommendation
   * create const recommendation including flight from itineraries array
   */
  openDialog(ref, r, routingId, cacheId, rates, ratesAma?, fareNdc?, tariff?, fare?, paxFare?) {
    console.log('this.travelers =>', this.travellers);
    const request = JSON.parse(JSON.stringify(this.request));
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(this.searchResults.recommendations.find(r => r.ref === ref)));
    console.log('RECOMMENDATION => ', recommendation);
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.searchResults.itineraries[i].sections[this.selections[ref][i] - 1];
    }
    if(this.tourOp){
      request.options.tourOperator = true;
     }
    if(tariff){
      recommendation.tariff = tariff;
      recommendation.penalties = tariff.penalties;
    }

    recommendation.options = request.options;
    recommendation.company = this.company;
    recommendation.rates = rates;
    if (recommendation.rates) {
      const sel = recommendation.rates.findIndex(t => t.selected);
      recommendation.tariff = rates[sel];
    }
    if(ratesAma){
      recommendation.faresAma = ratesAma;
      recommendation.fareNdc = fareNdc;
      recommendation.fare = fare;
      recommendation.paxFare = paxFare;
      // recommendation.tariff.fare.currency = recommendation.fare.currency;
      if(fareNdc.expirationTimeLimit){
        recommendation.timeLimit = fareNdc.expirationTimeLimit
      } else {
        recommendation.timeLimit = fareNdc.expirationDateTime
      }

    }
    recommendation.fare.options = request.options;
    console.log('this.selections =>', this.selections);
    const dialogComponent = this.atc ? TemplateDialogAtcComponent : TemplateDialogComponent;
    const dialogRef = this.dialog.open(dialogComponent, {
      data: {
        loggedUser: this.mainComponent.loggedUser,
        lowCost: this.lowCost,
        request: request,
        routingId,
        recommendation,
        passengers: this.travellers,
        searchResults: this.searchResults,
        selections: this.selections,
        dialog: this.dialog,
        cacheId,
        passPort: this.iataComplete.returnCarrierOPTPassport(recommendation.marketingCarrier, recommendation.itineraries),
        mainComponent: this.mainComponent,
        carrierOpt:this.iataComplete.returnCarrierOPT(recommendation.marketingCarrier),
        appComponent: this.appComponent,
        pnrId: this.pnrId,
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  /**
   * Toggle filters visibility
   */
  toggleFilters() {
    $('.filterSearch').toggleClass('openfilters');
    $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
  }

  /**
   * Add carrier available carriers filter
   */
  pushCarrier() {
    for (const recommendation of this.searchResults.recommendations) {
      if (!this.carriersAvailable.includes(recommendation.marketingCarrier)) {
        this.carriersAvailable.push(recommendation.marketingCarrier);
      }
      this.allPrice.push(recommendation.fare.amount);
    }
    // tslint:disable-next-line:only-arrow-functions
    this.allPrice.sort((n1, n2) => n1 - n2);
    this.minPrice = Math.floor(this.allPrice[0]);
    this.minPriceModel = Math.floor(this.allPrice[0]);
    this.maxPriceModel = Math.ceil(this.allPrice[this.allPrice.length - 1]);
    this.maxPrice = Math.ceil(this.allPrice[this.allPrice.length - 1]);
    console.log('this.carriersAvailable => ', this.carriersAvailable);
    this.doSort();
  }
  /*
  Add carrier to carriers array filters and refresh data source with dosort function
   */
  filterCarrier(carrier) {
    if (!this.carrierToFilter.includes(carrier)) {
      this.carrierToFilter.push(carrier);
    } else {
      this.carrierToFilter = this.carrierToFilter.filter(r => r !== carrier);
    }
    if(this.carrierToFilter.length > 0 ) {
      if(!this.filterActive.includes('carrierFilter')){
        this.filterActive.push('carrierFilter');
      }
    } else {
      const  idx = this.filterActive.indexOf('carrierFilter');
      if (idx !== -1) {
        this.filterActive.splice(idx, 1);
      }
    }
    this.setFareFilterInvisible(this.carrierToFilter);
    this.doSort();
  }

  setFareFilterInvisible(carrierToFilter){
    console.log(carrierToFilter);
    console.log(JSON.stringify(this.fareFIlter));
    for(const c of carrierToFilter){
      for(const f of this.fareFIlter){
        if(f.marketing !== c){
          const marketing = f.marketing;
          $('#'+marketing).css('visibility', 'hidden')
        }
      }

    }
  }


  /*
  Check if carrier is selected for filter
   */
  isIncludedCarr(carrier) {
    return this.carrierToFilter.includes(carrier);
  }
  /*
    Check if reccomendation is in carrier filter
     */
  isInCarrierFilter(carrier) {
    if (this.carrierToFilter.length === 0) {
      return true;
    } else {
      return this.carrierToFilter.includes(carrier);
    }
  }
  /**
   * Check if reccomendation is in price range
   */
  isInPriceRange(price) {
    if (price >= this.minPriceModel && price <= this.maxPriceModel) { return true; }
  }

  filterStopsAndTime(i) {
    const rec = JSON.parse(JSON.stringify(this.searchResults.recommendations[i]));

    for (let iti = 0; iti < rec.itineraries.length; iti++) {
      for (let s = 0; s < rec.itineraries[iti].sections.length; s++) {
        const section = rec.itineraries[iti].sections[s];
        if (this.searchResults.itineraries[iti].sections[section.ref - 1].flights.length - 1 > this.selectedStops[iti]) {
          rec.itineraries[iti].sections[s].hidden = true;
        }  else {
          rec.itineraries[iti].sections[s].hidden = false;
        }
        const flightHour = this.searchResults.itineraries[iti].sections[section.ref - 1].flights[0].departure.hours;
        rec.itineraries[iti].sections[s].hiddenT = (flightHour < this.filterTimeMin[iti] || flightHour > this.filterTimeMax[iti]);
      }
    }
    if (rec.lowCost && this.lowCost) {
      for (let c = 0; c < rec.cabins.length; c++) {
        if (!rec.cabins[c].name){
          const name = this.returnLowTariffName(rec.cabins[c]);
          if(!this.isSupported(rec.marketingCarrier)){
            // if (rec.marketingCarrier !== 'FR') {
            rec.cabins[c] = {
              name,
              description: this.returnLowTariffDescription(rec.cabins[c])
            };
          } else {
            const i = this.getIndex(rec.marketingCarrier);
            if (i === - 1){
              rec.cabins[c] = {
                name,
                services: [],
              };
            }
            else{
              // let i = this.availableLowTarifs.findIndex(ele => ele.supplier === "ryanair") //
              rec.cabins[c] = {
                name,
                services: (this.availableLowTarifs[i][name]) ? this.availableLowTarifs[i][name] : [],
              };
            }
          }
        }
      }
    }
    return rec;
  }

  isSupported(mark){
    for(let i=0; i<this.ListSupportedRatesDetails.length; i++){
      if(mark == this.ListSupportedRatesDetails[i].marketingCarrier){
        return true;
      }
    }
    return false;
  }

  getIndex(marketingCarrier){
    for(let i=0; i<this.ListSupportedRatesDetails.length; i++){
      if(marketingCarrier == this.ListSupportedRatesDetails[i].marketingCarrier){
        return this.availableLowTarifs.findIndex(ele => ele.supplier == this.ListSupportedRatesDetails[i].supplier);
      }
    }
  }

  changeStops(event, i) {
    let different = false;
    if (this.selectedStops[i] !== this.availableStops[i][0]) {
      different = true;
      if(!this.filterActive.includes('stopsFilter'+i)){
        this.filterActive.push('stopsFilter'+i);
      }
    }
    if(!different){
      const  idx = this.filterActive.indexOf('stopsFilter'+i);
      if (idx !== -1) {
        this.filterActive.splice(idx, 1);
      }
    }
    this.selectedStops[i] = event;
    this.doSort();
  }

  someFilterSelected() {
    let filters = 0;
    if (this.carrierToFilter.length > 0) {
      filters += 1;
    }
    if (this.minPriceModel !== this.minPrice || this.maxPriceModel !== this.maxPrice) {
      filters += 1;
    }
    for (let i = 0; i < this.selectedStops.length; i++) {
      if (this.selectedStops[i] !== this.availableStops[i][0]) {
        filters += 1;
      }
    }
    for (let i = 0; i < this.defaultFilter.length; i++) {
      if (this.defaultFilter[i] !== 'no_preference') {
        filters += 1;
      }
    }
    return filters;
  }

  /**
   * Set current page and close fare banners
   */
  pageChanged(event) {
    this.scrollTop();
    /*console.log('paginator event', event);*/
    this.currentPage = event.pageIndex * event.pageSize;
    this.requestedPage = event.pageIndex + 1;
    this.closeBanner();
  }
  /**
   * Scroll page to top
   */
  scrollTop() {
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  /**
   * Set time filter
   */
  changeTimeFilter(event, i) {
    if(this.defaultFilter[i] !== 'no_preference'){
      if(!this.filterActive.includes('hourFilter'+i)){
        this.filterActive.push('hourFilter'+i);
      }
    } else {
      const  idx = this.filterActive.indexOf('hourFilter'+i);
      if (idx !== -1) {
        this.filterActive.splice(idx, 1);
      }
    }

    this.defaultFilter[i] = event;
    if (this.defaultFilter[i] === 'no_preference') {
      this.filterTimeMin[i] = 0;
      this.filterTimeMax[i] = 24;
    } else if (this.defaultFilter[i] === 'morning') {
      this.filterTimeMin[i] = 6;
      this.filterTimeMax[i] = 12;
    } else if (this.defaultFilter[i] === 'afternoon') {
      this.filterTimeMin[i] = 12;
      this.filterTimeMax[i] = 18;
    } else if (this.defaultFilter[i] === 'evening') {
      this.filterTimeMin[i] = 18;
      this.filterTimeMax[i] = 24;
    } else {
      this.filterTimeMin[i] = 0;
      this.filterTimeMax[i] = 6;
    }
    this.doSort();
  }
  /**
   * Check if flight is in time filter
   */
  isInFilterTime(section) {
    if (section.flights[0].departure.hours >= this.filterTimeMin && section.flights[0].departure.hours <= this.filterTimeMax) {
      return !(section.flights[0].departure.hours === this.filterTimeMax && section.flights[0].departure.minutes > 0);
    }
  }


  /**
   * START pagination after received results or filtering
   */
  doSort() {
    if(this.minPriceModel !== this.minPrice || this.maxPriceModel !== this.maxPrice) {
      if(!this.filterActive.includes('priceFilter')){
        this.filterActive.push('priceFilter');
      }
    } else {
      const  idx = this.filterActive.indexOf('priceFilter');
      if (idx !== -1) {
        this.filterActive.splice(idx, 1);
      }
    }
    console.log('START PAGINATION');
    this.filteredRes = [];
    console.log('this.selectedStops => ', this.selectedStops);

    for (let i = 0; i < this.searchResults.recommendations.length; i++) {
      // const reco = this.searchResults.recommendations[i];
      // const reco = this.searchResults.recommendations[i];

      const reco = this.filterStopsAndTime(i);
      let recoPassed = true;
      // tslint:disable-next-line:prefer-for-of
      for (let r = 0; r < reco.itineraries.length; r++) {
        const index = reco.itineraries[r].sections.find(s => s.hidden === false);
        const indexT = reco.itineraries[r].sections.find(s => s.hiddenT === false);
        if (index && indexT) {
          reco.itineraries[r].passedI = true;
          // recoPassed = false;
        } else {
          reco.itineraries[r].passedI = false;
        }
      }
      recoPassed = reco.itineraries.every(v => v.passedI === true);

      if (recoPassed && this.isInPriceRange(reco.originalPrice) && this.isInCarrierFilter(reco.marketingCarrier)) {
        if(!this.lowCost){
          this.filteredRes.push(reco);
        } else {
          if(this.isInFareFilter(reco)){
            this.filteredRes.push(reco);
          }

        }
      }
    }



    this.currentPage = 0;
    this.dataSource = new MatTableDataSource<any>(this.filteredRes);
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.paginator.firstPage();
    this.obs = this.dataSource.connect();
    this.launchFareBanner();
    this.closeBanner();
  }

  isInFareFilter(reco:any) {
    let flag = true;
    const supplierIndex = this.fareFIlter.findIndex(ele => {
      return  ele.marketing === reco.marketingCarrier
    });
    if (supplierIndex  < 0 ){
      // se non trova la compagnia non filtra
      return false;
    } else {
      const tarifs = this.fareFIlter[supplierIndex];
      for (const f of tarifs.fares){
        flag = true;
        for (const c of reco.cabins){
          const exist = tarifs.fares.find(ele => ele.tarif === c.name && ele.selected)
          if (!exist) flag = false;
        }
        return flag;
      }
    }
  }

  /**
   * Ask fare for all negotiated recommendations
   */
  launchFareBanner() {
    for (let r = 0; r < this.filteredRes.length; r++) {
      if (this.filteredRes[r].negotiated || this.atc) {
        if (!this.filteredRes[r].rates) {
          this.askFares(this.filteredRes[r].ref, r, false);
        }
      }
    }
  }
  /**
   * Close fare banner after negotiated fare request
   */
  closeBanner() {
    for (let r = 0; r < this.filteredRes.length; r++) {
      if (this.filteredRes[r].showFares) {
        this.filteredRes[r].showFares = false;
      }
    }
  }
  /**
   * Ask fare rules (penalties) for selected fare
   */
  openDialogRules(tariff, ref, r, routingId, cacheId, rates) {
    console.log('this.travelers =>', this.travellers);
    // tslint:disable-next-line:no-shadowed-variable
    const recommendation = JSON.parse(JSON.stringify(this.searchResults.recommendations.find(r => r.ref === ref)));
    for (let i = 0; i < recommendation.itineraries.length; i++) {
      recommendation.itineraries[i] = this.searchResults.itineraries[i].sections[this.selections[ref][i] - 1];
    }
    recommendation.options = this.request.options;
    recommendation.company = this.company;
    recommendation.rates = rates;
    if (recommendation.rates) {
      recommendation.tariff = tariff;
    }
    recommendation.passengers = this.travellers;
    console.log('this.selections =>', this.selections);
    const dialogRef = this.dialog.open(FarerulesComponent, {
      data: {
        tariff,
        recommendation,
        loggedUser: this.mainComponent.loggedUser,
        isGat: this.appComponent.isGat
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }

  returnLowTariffName(value) {
    const index = value.split('*');
    if (index[0].split('=')[1] !== ' ') {
      return index[0].split('=')[1];
    } else {
      return index[2].split('=')[1];
    }
  }

  returnLowTariffDescription(value) {
    if(value.includes('*')){
      const index = value.split('*');
      return index[1].split('=')[1];
    } else return '';

  }

  extractOptions(feature, element){
    let tarifs ='';

    for(let x=0; x<feature.Options.length; x++){
      for(let y=0; y<feature.Options[x].length; y++){
        if(feature.Options[x][y].SupplierClass){
          this.checkSupplier(feature.Options[x][y].SupplierClass, element);
          const obj:RateDetails = new RateDetails(feature.Type, feature.Label,feature.Options[x][0]);
          // obj.name = feature.Type;
          // obj.label = feature.Label;
          tarifs = feature.Options[x][y].SupplierClass;
          delete feature.Options[x][y].SupplierClass;
          if(feature.Options[x][y].Provision) delete feature.Options[x][y].Provision;
          element[tarifs].push(obj);
        }
        else{
          this.sharedTarifs.push(feature);
        }
      }
    }
  }

  checkSupplier(supplier, element){
    if(!element.hasOwnProperty(supplier)){
      element[supplier] = [];
    }
  }

  addSharedTarifProperty(supplier){
    if(supplier.supplier ==='ryanair'){
      supplier.Value = [];
    }
    for(let i=0; i<this.sharedTarifs.length; i++){
      for(const tarif in supplier){
        if(tarif !== 'supplier'){
          const obj:RateDetails = new RateDetails(
            this.sharedTarifs[i].Type,
            this.sharedTarifs[i].Label,
            this.sharedTarifs[i].Options[0][0]
          );

          // obj.name = this.sharedTarifs[i].Type;
          // obj.label = this.sharedTarifs[i].Label;
          // obj.description.push(this.sharedTarifs[i].Options[0][0]);
          supplier[tarif].push(obj);
        }
      }
    }
  }

  elaborateLow(traLow) {
    try {
      for(let i=0; i<traLow.length; i++){
        this.availableLowTarifs.push({});
        this.availableLowTarifs[i].supplier = traLow[i].supplier;
        for(let j=0; j<traLow[i].Feature.length; j++){
          this.extractOptions(traLow[i].Feature[j], this.availableLowTarifs[i]);
        }
        this.addSharedTarifProperty(this.availableLowTarifs[i]);
        console.log(this.availableLowTarifs);
        this.sharedTarifs = [];
      }
    } catch(e){
      console.error(e);
    }
  }

  checkPolicyBlock(reco:any,tariff:any) :boolean {
    let ifBlock = false;
    if((this.appComponent.isSbt || this.appComponent.isB2B) && tariff.arrayOfAlerts?.length > 0){
      reco.arrayOfAlerts.forEach((r:any)=>  r.block ? ifBlock = true : null );
      tariff.arrayOfAlerts.forEach((a:any) => a.block ? ifBlock = true : null);
    }
    return ifBlock;
  }
  checkPolicyBlockLow(tariff:any) :boolean {
    let ifBlock = false;
    if((this.appComponent.isSbt||this.appComponent.isB2B) && tariff.arrayOfAlerts?.length > 0 && tariff.lowCost){
      tariff.arrayOfAlerts.forEach((a:any) => a.block ? ifBlock = true : null );
    }
    return ifBlock;
  }

  updateFareFilter(e, fare){
    fare.selected = e;
    console.log('filters =>', this.fareFIlter);
    this.doSort();
  }

  isIncludedTariff(rec:any, ref:any, r:any){

    let included = false;
    rec.offer.forEach((element:any) => {
      console.log(element)
      if(element.offerRef == ref && element.segments == this.selections[r+1]){
        return true;
      }
    });
  }

  returnCurrentOffer(recommendation,indice):any{
    let found = false;
    if(recommendation && recommendation.offer){
      recommendation.offer.forEach((o:any) => {
        if(this.searchResults.offersGroup && this.searchResults.offersGroup.offers){
          this.searchResults.offersGroup.offers.forEach((oG:any) => {
            if (Number(oG.offerDetails.reference) === o.offerRef && JSON.stringify(o.segments) == JSON.stringify(this.selections[indice+1])){
              recommendation.currentOffer = oG;
              found = true;
            }
          })
        }

      });

    }
    if(!found){
      delete recommendation.currentOffer;
    }

  }

  selectionss(r){
    console.log('selections ', this.selections[r+1]);
  }

  askFaresAmaNdc(recommendation, r){
    this.currentLoad = r;
    this.filteredRes[r].rates = [];
    this.loadingTar = true;
    const req = {
      "agencyID": "unused",
      "pseudoCityID": "unused",
      "pax": [],
      "uniqueOfferReference": recommendation.currentOffer.offerDetails.uniqueOfferReference,
      "marketingCarrier" : recommendation.marketingCarrier,
      "shoppingResponseRefID": this.searchResults.offersGroup.response.applicationDetails.internalId,
    }

    if(!Array.isArray(recommendation.currentOffer.offerItems)){
      recommendation.currentOffer.offerItems = [recommendation.currentOffer.offerItems];
    }
    recommendation.currentOffer.offerItems.forEach((offer:any) => {
      if(!Array.isArray(offer.paxReference)){
        offer.paxReference = [offer.paxReference];
      }
      offer.paxReference.forEach((p: any) => {
        if(!Array.isArray(p.traveller)){
          p.traveller = [p.traveller];
        }
        p.traveller.forEach((t:any) => {
          const pax = {
            "paxId": "T"+t.ref,
            "ptc": p.ptc,
            "offerItemRefID":offer.offerItemDetails.offerItemId
          }
          req.pax.push(pax);
        })
      });
    })

    console.log('recommendation.currentOffer => ', recommendation.currentOffer);
    console.log('req => ', req);
    if(recommendation.faresAma){
      delete recommendation.faresAma;
    }
    this.flightService.requestTariffNdcAma(req).subscribe((res: any) => {

      // console.log('res askFares=> ', res);
      delete this.currentLoad;
      this.loadingTar = false;
      recommendation.faresAma = res;
      recommendation.fareNdc = recommendation.faresAma.pricedOffer[0];
      recommendation.faresAma.pricedOffer[0].selected = true;
      this.returnNdcBagPax(recommendation);
      console.log('res => ', recommendation.faresAma );
      recommendation.showFares = true;
      this.slectNdcAmaFare(recommendation.faresAma.pricedOffer[0], recommendation);
    }, error => {
      // this.notifier.notify('error', error.errorMessage);
      delete this.currentLoad;
      this.loadingTar = false;
      this.error = error;

    });
  }

  hideFareNdc(reco){
    reco.showFares = false;
  }

  returnNdcAmaClass(id, response){
    let detail = '';
    const find = response.classList.findIndex(c => c.tarif.class == id );
    if (find!=-1){
      detail = response.classList[find];
      //offer.services = offer.services.concat(response.classList[find].services)
    }

    return detail;
  }

  slectNdcAmaFare(offer, reco){
    reco.faresAma.pricedOffer.forEach((f:any) => {f.selected = false;})
    offer.selected = true;
    reco.fareNdc = offer
    // paxFare / amount / tax
    let totalTax = 0;
    offer.offerItem.forEach((item:any) => {
      item.fareDetails.forEach((detail:any) => {
        totalTax += detail.price.taxes.total.amount;
        let find = -1;
        if(!Array.isArray(detail.passengerRefs)){
          find = offer.paxList.findIndex(p => p.id == detail.passengerRefs);
        } else {
          find = offer.paxList.findIndex(p => detail.passengerRefs.includes(p.id));
        }
        if(find > -1){
          if(offer.paxList[find].ptc.toLowerCase() == 'ch' || offer.paxList[find].ptc.toLowerCase() == 'ccn'){
            reco.paxFare.chd.amount = detail.price.baseAmount.amount + detail.price.taxes.total.amount;
            reco.paxFare.chd.tax = detail.price.taxes.total.amount;
          } else {
            reco.paxFare[offer.paxList[find].ptc.toLowerCase()].amount = detail.price.baseAmount.amount + detail.price.taxes.total.amount;
            reco.paxFare[offer.paxList[find].ptc.toLowerCase()].tax = detail.price.taxes.total.amount;
          }
        }
      })
    })
    reco.fare = {
      amount : offer.totalPrice.amount,
      tax : totalTax,
      currency: offer.totalPrice.currency,
    }
    let passengers = [];
    let p: any;
    for(p in reco.paxFare){
      const pax = {
        type: p,
        refs: reco.paxFare[p].refs,
      }
      passengers.push(pax);
    }
    reco.tariff = this.helper.transformJson(offer, passengers);
    reco.tariff.fare = reco.fare;
    reco.penalties = reco.tariff.penalties;
    console.log('reco => ', reco);
  }



  returnNdcBagPax(reco){
    const passengers = JSON.parse(JSON.stringify(this.request.passengers));

    reco.faresAma.pricedOffer.forEach((r:any) => {
      r.paxList = reco.faresAma.paxList;
      r.refundable = false;
      const baggage = {
        adt: '',
        chd: '',
        inf: '',
      };
      if(passengers.chd == 0){
        delete baggage.chd;
      }
      if(passengers.inf == 0){
        delete baggage.inf;
      }
      if(r.baggageAllowance){
        r.baggageAllowance.forEach((b:any) => {
          if(!Array.isArray(b.paxRefId)){
            b.paxRefId = [b.paxRefId];
          }
          b.paxRefId.forEach((p:any) => {
            const find = reco.faresAma.paxList.findIndex(pax => pax.id == p);
            if(find != -1){
              if(b.baggageAllowanceId.weightAllowance){
                baggage[reco.faresAma.paxList[find].ptc.toLowerCase()] = {
                  type: b.baggageAllowanceId?.weightAllowance.uniteCode +  '',
                  qty: (Number(b.baggageAllowanceId?.weightAllowance.maximunWeightMeasure)) || 0
                };
              } else {
                baggage[reco.faresAma.paxList[find].ptc.toLowerCase()] = {
                  type: b.baggageAllowanceId?.type || '',
                  qty: Number(b.baggageAllowanceId?.pieceAllowed?.totalQty) || 0
                };
              }

            }
          })
        })
      }

      r.journeyArray = r.JourneyPriceClass.flatMap(obj => Object.values(obj));
      r.journeyArray.forEach((j:any) => {
        j.details = this.returnNdcAmaClass(j.PriceClassRefID, reco.faresAma);
        j.details.services.forEach((s:any) => {
          if((s.descId == 'CANCEL_AFTERDEPARTURE' || s.descId == 'CANCEL_BEFOREDEPARTURE') && s.descText.toLowerCase().includes('yes')){
            r.refundable = true;
          }
        })
      })
      r.baggage = baggage;
      console.log(r);
    })

  }

  openInfoNdcAma(offer){
    console.log('services => ', offer.journeyArray);
    const dialogRef = this.dialog.open(NdcfarerulesComponent, {
      data: {
        services: offer.journeyArray,
      },
      disableClose: true,
      width: '100%',
      maxWidth: '960px',
      // height: '100%',
      maxHeight: '900px',
    });
  }





  returnDate(value){
    const date = new Date(value)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const final = new Date(date.getTime() + userTimezoneOffset);
    return final;
  }

  convertMilliseconds(det) {
    const ms = det.to - det.from;
    // Un'ora ha 3,600,000 millisecondi
    const hours = Math.floor(ms / 3600000);
    // Un minuto ha 60,000 millisecondi
    const minutes = Math.floor((ms % 3600000) / 60000);

    // Ritorna la durata in formato 'ore minuti'
    return `${hours}h ${minutes}m`;
  }

  checkTechnicalStops(flights){
    let count = 0;
    flights.forEach((f:any) => {
      if(f.detail.technicalStop && f.detail.technicalStop.from > 0){
        count += 1;
      }
    });
    return count;
  }
}
