import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceService } from "../../../services/price/price.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-single-room-v4',
  templateUrl: './single-room-v4.component.html',
  styleUrls: ['./single-room-v4.component.scss']
})
export class SingleRoomV4Component implements OnInit{
  @Input() hotel : any;
  @Input() searchRes : any;
  @Input() search : any;
  @Input() room : any;
  @Input() i : any;
  @Input() isSbt: any;
  @Input() isB2B: any;
  @Input() isGat: any;
  @Input() guests: any;
  @Input() corporates: any[] = [];
  numberOfNights : any;
  // tslint:disable-next-line:no-output-rename
  @Output('update') change : EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public priceService: PriceService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.calculateNights();
  }

  calculateNights(){
    const oneDay = 1000 * 60 * 60 * 24
    const date1 = new Date(this.search.checkIn);
    const date2 = new Date(this.search.checkOut)
    if (date1.getMonth() === 11 && date1.getDate() > 25)
      date2.setFullYear(date2.getFullYear() + 1)
    const Result = Math.round(date2.getTime() - date1.getTime()) / (oneDay);
    this.numberOfNights = Math.floor(Result)
    console.log('this.numberOfNights =>', this.numberOfNights);
  }

  selectRoomFare(rate, ind){
    // console.log('this.room', this.room);
    const room = {
      hotel: this.hotel,
      index: this.i,
      room: this.room,
      fare: rate,
      rateIndex: ind,
    };
    this.change.emit(room);
  }

  convertPrice(value, currency) : number{
    if (this.searchRes.currencyConversions){
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.searchRes.currencyConversions.length; i ++){
        if (this.searchRes.currencyConversions[i].local === currency){
          return this.priceService.returnPriceHotel(value * this.searchRes.currencyConversions[i].rate);
        }
      }
    } else{
      return this.priceService.returnPriceHotel(value);
    }
  }

  checkVoucher(value){
    if (value.includes('AMADEUS') || value.includes('HotelBeds')) {
      return true;
    } else {
      return false
    }
  }

  corporate(text){
    return text.includes('CORPORATE') || text.includes('corporate');
  }

  returnProvider(value:string): string{
    if (!this.isGat && value.includes('AMADEUS')){
      return this.translate.instant('TARIFFES') + ' LEONARDO TRAVEL';
    }
    return value;
  }

  isBooking(value:string){
    return !!value.includes('BOOKING');
  }

  isRoomOnly(value?:string){
    if(value && value.toLowerCase().includes('only')){
      return true;
    }
    return false;
  }

  removeHr(date){
    date = new Date(date);
    date = date.setHours(date.getHours() - 4);
    return date;
  }

  isFreeCancellationBis(){
    return this.room.ratePlans[0].penalty[0] && this.room.ratePlans[0].penalty[0].absoluteDeadLine;
  }

  isCorporate(rate){
    let corporate = false;
    if(rate.code == '42E'){
      console.log('rate => ', rate);
      console.log('this.corporates => ', this.corporates);
    }
    const ind = this.corporates.findIndex(c => c.code == rate.code);
    if(ind > -1){
      corporate = true;
    }
    return corporate;
  }

  checkIfBlock():boolean{
    let isBlock = false;
    if ((this.isB2B || this.isSbt) && this.room.arrayOfAlerts.length > 0){
      this.room.arrayOfAlerts.forEach((arr:any)=> arr.block ?  isBlock = arr.block : null );
      this.room.hotel.arrayOfAlerts.forEach((hotel:any) => hotel.block ? isBlock = hotel.block : null)
    }
    return isBlock;
  }

  allAlerts(room:any){
    return [...room.arrayOfAlerts, ...room.hotel.arrayOfAlerts];
  }
}
