import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VehicleCardComponent} from './vehicle-card.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from "@angular/material/expansion";
import {SharedModule} from "../../../modules/shared/shared.module";


@NgModule({
    declarations: [VehicleCardComponent],
    exports: [
        VehicleCardComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatTooltipModule,
        MatExpansionModule,
        SharedModule
    ]
})
export class VehicleCardModule { }
