<h2 mat-dialog-title>
    {{'FARE_RULES'|translate}}
    <!--
    <span *ngFor="let class of data.services;let l = last;">
      <span> {{class.details.tarif.name}}</span> <span *ngIf="!l"> + </span>
    </span>
    -->
  </h2>
  <mat-dialog-content class="mat-typography">

    <mat-tab-group (selectedTabChange)="tabChanged($event)" *ngIf="!data.ndcPnr">

      <mat-tab *ngFor="let class of data.services; let i = index;">
        <ng-template mat-tab-label>{{class.details.tarif.name}}</ng-template>
        <div class="padding10">
            <div [ngClass]="{'mb-2': isRule(service)}" *ngFor="let service of class.details.services">
                <div [innerHTML]="service.descText" *ngIf="isRule(service)"></div>
            </div>
            <hr/>
            <div [ngClass]="{'mb-2': !isRule(service)}" *ngFor="let service of class.details.services">
                <div [innerHTML]="service.descText" *ngIf="!isRule(service)"></div>
            </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group (selectedTabChange)="tabChanged($event)" *ngIf="data.ndcPnr">

      <mat-tab *ngFor="let class of penalties; let i = index;">
        <ng-template mat-tab-label>{{class.name}}</ng-template>
        <div class="padding10">
          <div [ngClass]="{'mb-2': isRule(service)}" *ngFor="let service of class.rules">
            <div [innerHTML]="service.descText" *ngIf="isRule(service)"></div>
          </div>
          <hr/>
          <div [ngClass]="{'mb-2': !isRule(service)}" *ngFor="let service of class.rules">
            <div [innerHTML]="service.descText" *ngIf="!isRule(service)"></div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'CLOSE'|translate}}</button>
  </mat-dialog-actions>
