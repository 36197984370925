import {Component, Input, OnInit} from '@angular/core';
import {
  LoyaltyCode,
  Traveler,
} from '../../../../classes/travelers/singletraveler';

@Component({
  selector: 'traveller-loyalty-codes',
  templateUrl: 'traveller-loyalty-codes.component.html',
  styleUrls: ['traveller-loyalty-codes.component.scss']
})
export class TravellerLoyaltyCodesComponent implements OnInit {
  @Input() traveller: Traveler;
  loyaltyTypes = [{ product: LoyaltyCode.TRENITALIA, type:'CARTAFRECCIA_BASE' }];
  loyaltyProducts = [ LoyaltyCode.TRENITALIA ];

  constructor(
  ) { }

  ngOnInit(): void {
    if (!this.traveller.loyaltyCodes) this.traveller.loyaltyCodes = [];
  }

  add(){
    this.traveller.loyaltyCodes.push(new LoyaltyCode());
  }

  delete(index:number){
    this.traveller.loyaltyCodes.splice(index, 1);
  }

  getLoyaltyTypes(product: string, thisType: string){
    const alreadyPickedTypes = this.traveller.loyaltyCodes.filter(lt => lt.product === product).map(lc => {return lc.type});
    return this.loyaltyTypes.filter(lt => lt.product === product).filter(lt => lt.type === thisType || !alreadyPickedTypes.includes(lt.type));
  }

}
