import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { SnackErrorComponent } from '../modules/errorNotification/snackerror/snack-error.component';

import * as pack from '../../../package.json';
import {OpenAPI} from '../sdk/hotel';

export const randomID = () => '_' + Math.random().toString(36).substr(2, 12) + Math.random().toString(36).substr(2, 12);

@Injectable({
  providedIn: 'root'
})
export class HttpserviceService {
  isTest: boolean;
  apiUrl: string;
  oldApiUrl: string;
  basePath: string;
  appType: string;
  isB2b: boolean;
  isSbt: boolean;
  isGat: boolean;
  app = pack;
  snackBar: SnackErrorComponent;

  constructor(
    public router: Router,
    public http: HttpClient,
    public snackErrorComponent: SnackErrorComponent,
    // private authService:AuthenticationService
  ) {
    this.snackBar = snackErrorComponent;
    this.router = router;
    this.http = http;
    this.isTest = environment.isTest;
    this.appType = 'flyleo';
    this.isB2b = environment.isB2b;
    this.isSbt = environment.isSbt;
    this.isGat = environment.isGat;
    if (environment.isB2b) {
      this.appType = 'B2B';
    }
    if (environment.isSbt) {
      this.appType = 'Suite 4 Travel';
    }
    if (environment.isGat) {
      this.appType = 'flyGATTINONI';
    }
    if (!this.isTest && !this.isSbt && !this.isGat) {
      this.apiUrl = 'https://api.flyleo.it/api/v2/'; // api.v2.stage.flyleo.it/
      this.oldApiUrl = 'https://api.flyleo.it/api/';
      this.basePath = 'https://api.flyleo.it/v2/';
    } else if (!this.isTest && !this.isSbt && this.isGat) {
      this.apiUrl = 'https://api.flygattinoni.it/api/v2/'; // api.v2.stage.flygattinoni.it/
      this.oldApiUrl = 'https://api.flygattinoni.it/api/';
      this.basePath = 'https://api.flygattinoni.it/v2/';
    } else if (!this.isTest && this.isSbt && !this.isGat) {
      this.apiUrl = 'https://api.s4t.flygattinoni.it/api/v2/'; // api.v2.stage.flygattinoni.it/
      this.oldApiUrl = 'https://api.s4t.flygattinoni.it/api/';
      this.basePath = 'https://api.s4t.flygattinoni.it/v2/';
    }
    if (this.isTest) {
      this.apiUrl = '  https://api.stage.flyleo.it/api/v2/';
      this.oldApiUrl = 'https://api.stage.flyleo.it/';
      this.basePath = 'https://api.stage.flyleo.it/v2/';
    }

  }

    private static getSession(){
        let session = localStorage.getItem('fl_session_id');
        if (!session){
            session = randomID();
            localStorage.setItem('fl_session_id', session);
        }
        return session;
    }

  initSdKV3(){
    if (!this.isTest && !this.isSbt && !this.isGat) {
      OpenAPI.BASE = 'https://api.flyleo.it'
    } else if (!this.isTest && !this.isSbt && this.isGat) {
      OpenAPI.BASE = 'https://api.flygattinoni.it'
    } else if (!this.isTest && this.isSbt && !this.isGat) {
      OpenAPI.BASE = 'https://api.s4t.flygattinoni.it'
    }
    if(this.isTest){
      OpenAPI.BASE = 'https://api.stage.flyleo.it'
    }
    // OpenAPI.BASE = 'http://localhost:3000';
    OpenAPI.TOKEN = async () => `Bearer ${localStorage.getItem('fl_access_token')}`;
  }

  setHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('fl_access_token'),
        'x-app-version': this.app.version,
        'x-app-session': HttpserviceService.getSession(),
      })
    };
  }

  setHttpOptionsNoAuth() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-app-version': this.app.version,
        'x-app-session': HttpserviceService.getSession(),
      })
    };
  }

  setHttpOptionsDownload(fileName) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: 'Bearer ' + localStorage.getItem('fl_access_token'),
        'x-app-version': this.app.version,
        'x-app-session': HttpserviceService.getSession(),
        'Content-Disposition': `attachment; filename="${fileName}"`
      }),
      responseType: 'blob' as 'json'
    };
  }

  setHttpOptionsWithParams(param) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('fl_access_token'),
        'x-app-version': this.app.version,
        'x-app-session': HttpserviceService.getSession(),
      }),
      params: {options: param},
    };
  }
  setHttpOptionsWithBody(body) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('fl_access_token'),
        'x-app-version': this.app.version,
        'x-app-session': HttpserviceService.getSession(),
      }),
      body,
    };
  }

  setHttpOptionsImage() {
    return {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('fl_access_token'),
        'x-app-version': this.app.version,
        'x-app-session': HttpserviceService.getSession(),
      })
    };
  }

  setApiurl() {
    // return this.apiUrl;
    return this.basePath;
  }

  setApiUrlImage() {
    // return this.apiUrl;
    return this.oldApiUrl;
  }
  /*
  returnIsB2b() {
    return this.isB2b;
  }
  */

  // Handle API errors
  handleError(error: HttpErrorResponse, showSnackBar: boolean = true, customMsg?: string, errorType?: string) {
    let message : any = '';
    if (error.error instanceof ErrorEvent) {
      const msg = error.error.message;
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', msg);
      message = msg;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.errorMessage}`);
      if (error.error.errorMessage === 'Token is Expired') {
        this.router.navigate(['/login']);
      }
      if (error.error.errorMessage?.includes('conversations')) {
        showSnackBar = false;
        // this.snackBar.openSnackBar('Allineamento di sistema, ti preghiamo di riprovare tra 30 secondi.', errorType);
      }
      if (error.error.errorMessage?.includes('Inactive')) {
        showSnackBar = false;
      }
      message = error.error;
    }
    if (showSnackBar && !error.error?.isTrusted){
      console.log('entro qui')
      const msg = customMsg ? customMsg : (message.errorMessage) ? message.errorMessage : JSON.stringify(message);
      this.snackBar.openSnackBar(msg, errorType);
    }

    // return an observable with a user-facing error message
    return throwError(message);
  }
}
