import {Component, OnInit, Input, ChangeDetectorRef, ViewChild} from '@angular/core';
import 'bootstrap';
import * as $ from 'jquery';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {observableToBeFn} from 'rxjs/internal/testing/TestScheduler';
import {NewTraveller} from '../../../../../classes/travelers/newTraveller';
import {CarService} from '../../../../../services/car/car.service';
import {DossierService} from '../../../../../services/dossier/dossier.service';
import {Router} from '@angular/router';
import {AppComponent} from '../../../../../app.component';
import {TravelersService} from '../../../../../services/travelers/travelers.service';
declare var google: any;

@Component({
  selector: 'app-carsearchresults',
  templateUrl: './carsearchresults.component.html',
  styleUrls: ['./carsearchresults.component.scss']
})
export class CarsearchresultsComponent implements OnInit {
  @Input() searchResults;
  legend: any;
  /**
   *  local variable for map
   */
  map: any;
  types: any;
  period: any;
  currentCar: any;
  currentTraveler: number;
  loadTravelers: boolean;
  booking: boolean;
  allMarkers = [];
  sarce: any;
  /**
   * Local variable for angular paginator
   */
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /**
   * Local variable for array paginator
   */
  obs: Observable<any>;
  /**
   * Local variable for paginator datasource
   */
  dataSource: MatTableDataSource<any>;
  resultsLength: any;
  pageSize = 10;
  /**
   * Local variable for angular paginator page size options
   */
  pageSizeOptions: number[] = [5, 10, 15, 20];
  formvisible: boolean;
  filteredRes: any[];
  currentPage: number;
  searchName: string;
  providersToFilter: any[];
  carsProviders: any[];
  carsTypes: any[];
  carsTypesToFilter: any[];
  categoryCodeToFilter: any[];
  categoryTypes: any[];
  gearCodeToFilter: any[];
  gearTypes: any[];
  fuelacCodeToFilter: any[];
  fuelacTypes: any[];
  currentDrivers = [];
  editingTraveler: boolean;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private carService: CarService,
    private dossierService: DossierService,
    private router: Router,
    public appComponent: AppComponent,
    private travelersService: TravelersService,
  ) {
    this.legend = {
      CATHEGORY: {
        D: 'Compact Elite',
        E: 'Economy',
        F: 'Fullsize',
        G: 'Fullsize Elite',
        H: 'Economy Elite',
        I: 'Intermediate',
        J: 'Intermediate Elite',
        M: 'Mini',
        N: 'Mini Elite',
        P: 'Premium',
        R: 'Standard Elite',
        S: 'Standard',
        U: 'Premium Elite',
        W: 'Luxury Elite',
        X: 'Special',
        O : 'Oversize',
        L : 'Luxury',
        C : 'Compact',

      },
      TYPE: {
        B: '2-3',
        C: '2-4',
        D: '4-5',
        E: 'Coupe',
        F: 'SUV',
        G: 'Crossover',
        H: 'Motor Home',
        J: 'Open Air All T errain',
        K: 'Commercial Van/Truck',
        L: 'Limousine/ Sedan',
        M: 'Monospace',
        N: 'Roadster',
        P: 'Pick up Regular/ Extended Cab - 2 Portee',
        Q: 'Pick up Double Cab - 4 Portee M',
        R: 'Recreational Vehicle',
        S: 'Sport',
        T: 'Convertible',
        V: 'Furgone trasporto persone',
        W: 'Wagon/Estate',
        X: 'Special',
        Y: '2 Wheel Vehicle',
        Z: 'Offerta Speciale'
      },
      GEAR_TYPE: {
        A: 'Automatico non specificato',
        B: 'Automatico 4WD',
        C: 'Manuale',
        D: 'Automatico AWD',
        M: 'Manuale non specificato',
        N: 'Manuale 4WD'
      },
      FUEL_AND_AC: {
        A: 'Hydrogen ',
        B: 'Hydrogen ',
        C: 'Electric Plus (Distanza ≥ 250mi/400km) Aria Condizionata',
        D: 'Diesel ',
        E: 'Electric (Distanza < 250mi/400km) Aria Condizionata',
        F: 'Multi carburante ',
        H: 'Hybrid ',
        I: 'Hybrid Plug In ',
        L: 'LPG/Compressed Gas ',
        M: 'Multi carburante ',
        N: 'Carburante non specificato ',
        Q: 'Diesel ',
        R: 'Carburante non specificato ',
        S: 'LPG/Compressed Gas ',
        U: 'Ethanol ',
        V: 'Benzina ',
        X: 'Ethanol ',
        Z: 'Benzina '
      }
    };
    this.formvisible = true;
    this.types = {
      1: 'Drop charge/ One Way Fee',
      2: 'Extra day',
      3: 'Extra week',
      4: 'Extra hour',
      5: 'Extra month',
      6: 'Per mile',
      7: 'Per kilometer',
      8: 'Free miles',
      9: 'Free kilometers',
      10: 'Adult',
      11: 'Child',
      12: 'Employee',
      13: 'Special equipment',
      44: 'Theft Protection(TP)',
      45: 'Tax',
      107: 'CDW (Collision damage waiver)',
      108: 'Surcharge',
      COL: 'Collection Charge',
      COV: 'Coverage Policy',
      CPN: 'Coupon',
      DEL: 'Delivery',
      NSF: 'No Show Fee',
      TAC: 'Total of Additional Charges',
    };
    this.period = {
      '001': 'Per day',
      '002': 'Per week',
      '003': 'Per month',
      '004': 'Per rental',
      '005': 'Per hour',
      '006': 'Per minute',
      AMP: 'And Month Period',
      AYP: 'And Year Period',
      BMP: 'Between Month Period',
      BP: 'Between Period',
      BYP: 'Between Year Period',
      EXS: 'Excess',
      FDP: 'From Day Period',
      FKD: 'From Kilometer Distance',
      FMD: 'From Miles Distance',
      FMP: 'From Month Period',
      FP: 'From Period',
      FWP: 'From Week Period',
      LBY: 'Liability',
      MAX: 'Maximum value',
      MND: 'Maximum Number of Days',
      PK: 'Per Kilometer',
      PM: 'Per Miles',
      PMD: 'Per Modification',
      PMV: 'Per Movment',
      PWE: 'Per Week End',
      TDP: 'To Day Period',
      TKD: 'To Kilometer Distance',
      TMD: 'To Miles Distance',
      TMP: 'To Month Period',
      TP: 'To Period',
      TWP: 'To Week Period',
    };
    this.searchName = '';
    this.providersToFilter = [];
    this.filteredRes = [];
    this.carsProviders = [];
    this.carsTypes = [];
    this.carsTypesToFilter = [];
    this.categoryCodeToFilter = [];
    this.categoryTypes = [];
    this.gearCodeToFilter = [];
    this.gearTypes = [];
    this.fuelacCodeToFilter = [];
    this.fuelacTypes = [];
  }

  ngOnInit(): void {
    this.searchResults.cars.forEach( car => {
      if (!this.carsProviders.includes(car.companyName)) {
        this.carsProviders.push(car.companyName);
      }
      if (!this.carsTypes.includes(car.type[1])) {
        this.carsTypes.push(car.type[1]);
      }
      if (!this.categoryTypes.includes(car.type[0])) {
        this.categoryTypes.push(car.type[0]);
      }
      if (!this.gearTypes.includes(car.type[2])) {
        this.gearTypes.push(car.type[2]);
      }
      if (!this.fuelacTypes.includes(car.type[3])) {
        this.fuelacTypes.push(car.type[3]);
      }
      if (!car.model) {
        car.model = 'NOT_SPECIFIED';
      }
    });
    this.doSort();
    this.resultsLength = this.searchResults.cars.length;
    this.toggleSearch();
  }

  pageChanged(event) {
    setTimeout(() => {
      this.scrollTop();
    }, 200);
  }

  scrollTop() {
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  toggleSearch() {
    this.formvisible = ! this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  toggleFilters() {
    $('.filterSearch').toggleClass('openfilters');
    $('.filtersearchBackdrop').toggleClass('openfiltersearchBackdrop');
  }

  toggleSingleCar(car) {
    if (car) {
      this.currentCar = car;
      setTimeout(() => {
        this.initMap();
      }, 200);
      console.log('this.currentCar => ', this.currentCar);
      if ( this.currentDrivers.length === 0) {
        this.addDriver();
      }

      if (localStorage.getItem('sarceDossier')) {
        this.getTravelerCid();
      }
      if (localStorage.getItem('dossierPassengers')) {
        this.currentDrivers = JSON.parse(localStorage.getItem('dossierPassengers'));
        this.getTravelerDossier();
      }
    } else {
      delete this.currentCar;
      this.allMarkers = [];
    }
    $('#singleCarModal').modal('toggle');
  }

  getTravelerCid() {
    console.log('this.sarce.cid => ', this.sarce.cid);
    this.travelersService.getTravelerCid(this.sarce.cid).subscribe((res: any) => {
      res.type = 'ADT';
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  getTravelerDossier() {
    this.travelersService.getTraveler(this.currentDrivers[0]._id).subscribe((res: any) => {
      if (!res.type) {
        res.type = 'ADT';
      }
      this.travelerSet(res, 0);
    }, error => {
      console.error('res => ', error);
    });
  }

  initMap(): void {
    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      // tslint:disable-next-line:max-line-length
      center: { lat: this.currentCar.company.rentalLocations[0].coordinates.latitude, lng: this.currentCar.company.rentalLocations[0].coordinates.longitude },
      zoom: 12,
    });
    const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    let myLatlng;
    // tslint:disable-next-line:max-line-length
    myLatlng = new google.maps.LatLng(this.currentCar.company.rentalLocations[0].coordinates.latitude, this.currentCar.company.rentalLocations[0].coordinates.longitude);
    const marker = new google.maps.Marker({
      position: myLatlng,
      label: 'Up',
      title: 'Punto di ritiro ' + this.currentCar.company.name,
      description: this.currentCar.company.rentalLocations[0].address,
    });
    const infoWindow = new google.maps.InfoWindow({
      content: '<p style="font-size:12px;">' + marker.title + '</p><p style="font-size:12px;">' + marker.description + '</p>',
    });
    marker.addListener('click', ( ) => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    marker.setMap(this.map);
    this.allMarkers.push(marker);
    if (this.currentCar.company.rentalLocations[1]) {
      this.createMarker();
      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      };
      console.log(this.allMarkers[0]);
      const line = new google.maps.Polyline({
        path: [
          // tslint:disable-next-line:max-line-length
          { lat: this.currentCar.company.rentalLocations[0].coordinates.latitude, lng: this.currentCar.company.rentalLocations[0].coordinates.longitude },
          // tslint:disable-next-line:max-line-length
          { lat: this.currentCar.company.rentalLocations[1].coordinates.latitude, lng: this.currentCar.company.rentalLocations[1].coordinates.longitude },
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: this.map,
      });
    }
    const bounds = new google.maps.LatLngBounds();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.allMarkers.length; i++) {
      bounds.extend(this.allMarkers[i].getPosition());
    }
    // this.map.fitBounds(bounds);
  }

  createMarker(): void {
    const infoWindow = new google.maps.InfoWindow({
      // tslint:disable-next-line:max-line-length
      content: '<p style="font-size:12px;"> Punto di restituzione' + this.currentCar.company.name + '</p><p style="font-size:12px;">' + this.currentCar.company.rentalLocations[1].address + '</p>',
    });
    const marker = new google.maps.Marker({
      // tslint:disable-next-line:max-line-length
      position: {lat: this.currentCar.company.rentalLocations[1].coordinates.latitude, lng: this.currentCar.company.rentalLocations[1].coordinates.longitude},
      label: 'Off',
      map: this.map,
      // label: labels[i % labels.length],
    });
    marker.addListener('click', ( ) => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    this.allMarkers.push(marker);
    return marker;
  }

  addDriver(): void {
    const traveler = new NewTraveller();
    const tr = JSON.parse(JSON.stringify(traveler));
    tr.type = 'ADT';
    this.currentDrivers.push(tr);
  }

  returnAir(value): boolean {
    return  value === 'A' ||
            value === 'C' ||
            value === 'D' ||
            value === 'E' ||
            value === 'H' ||
            value === 'I' ||
            value === 'L' ||
      value === 'M' ||
      value === 'R' ||
      value === 'U' ||
      value === 'V';
  }

  returnDate(value): Date {
    return new Date(value);
  }

  checkName(value): boolean  {
    if ( this.searchName === '' || value.toLowerCase().includes(this.searchName.toLowerCase())) {
      return true;
    }
  }

  checkProvider(value): boolean  {
    if (this.providersToFilter.length === 0 || this.providersToFilter.includes(value)) {
      return true;
    }
  }

  checkType(value): boolean  {
    if (this.carsTypesToFilter.length === 0 || this.carsTypesToFilter.includes(value)) {
      return true;
    }
  }

  checkCategoryType(value): boolean  {
    if (this.categoryCodeToFilter.length === 0 || this.categoryCodeToFilter.includes(value)) {
      return true;
    }
  }

  checkGearType(value): boolean  {
    if (this.gearCodeToFilter.length === 0 || this.gearCodeToFilter.includes(value)) {
      return true;
    }
  }

  checkFuelacType(value): boolean  {
    if (this.fuelacCodeToFilter.length === 0 || this.fuelacCodeToFilter.includes(value)) {
      return true;
    }
  }

  manageProviders(value): void {
    const index = this.providersToFilter.indexOf(value);
    if (index > -1) {
      this.providersToFilter.splice(index, 1);
    } else {
      this.providersToFilter.push(value);
    }
    this.doSort();
  }

  manageTypes(value): void  {
    const index = this.carsTypesToFilter.indexOf(value);
    if (index > -1) {
      this.carsTypesToFilter.splice(index, 1);
    } else {
      this.carsTypesToFilter.push(value);
    }
    this.doSort();
  }

  manageCategoryTypes(value): void  {
    const index = this.categoryCodeToFilter.indexOf(value);
    if (index > -1) {
      this.categoryCodeToFilter.splice(index, 1);
    } else {
      this.categoryCodeToFilter.push(value);
    }
    this.doSort();
  }

  manageGearTypes(value): void  {
    const index = this.gearCodeToFilter.indexOf(value);
    if (index > -1) {
      this.gearCodeToFilter.splice(index, 1);
    } else {
      this.gearCodeToFilter.push(value);
    }
    this.doSort();
  }

  manageFuelacTypes(value): void  {
    const index = this.fuelacCodeToFilter.indexOf(value);
    if (index > -1) {
      this.fuelacCodeToFilter.splice(index, 1);
    } else {
      this.fuelacCodeToFilter.push(value);
    }
    this.doSort();
  }

  isProviderSelected(value): boolean {
    return this.providersToFilter.includes(value);
  }

  isTypeSelected(value): boolean {
    return this.carsTypesToFilter.includes(value);
  }

  isCategoryTypeSelected(value): boolean {
    return this.categoryCodeToFilter.includes(value);
  }

  isGearTypeSelected(value): boolean {
    return this.gearCodeToFilter.includes(value);
  }

  isFuelacTypeSelected(value): boolean {
    return this.fuelacCodeToFilter.includes(value);
  }

  doSort(): void {
    this.filteredRes = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.searchResults.cars.length; i++) {
      const car = this.searchResults.cars[i];
      // tslint:disable-next-line:max-line-length
      if (car.model && this.checkName(car.model) && this.checkProvider(car.companyName) && this.checkType(car.type[1]) && this.checkCategoryType(car.type[0]) && this.checkGearType(car.type[2]) && this.checkFuelacType(car.type[3])) {
        this.filteredRes.push(car);
      }
    }
    this.currentPage = 0;
    this.dataSource = new MatTableDataSource<any>(this.filteredRes);
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.paginator.firstPage();
    this.obs = this.dataSource.connect();
  }

  // travelers dialogs

  toggleDialog(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  removeTraveler(i) {
    Object.keys(this.currentDrivers[i]).forEach(k => delete this.currentDrivers[i][k]);
    if ( this.currentDrivers.length === 1) {
      this.currentDrivers[0].type = 'ADT';
    }
  }

  toggleEditDialog(currentTraveler) {
    this.editingTraveler = true;
    this.currentTraveler = currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  loadTravelersList(currentTraveler) {
    this.currentTraveler = currentTraveler;
    $('#listTravelerModal').modal('toggle');
    this.loadTravelers = true;
  }

  travelerSet(event, i) {
    this.currentDrivers[i] = event;
    const z = 1;
    this.currentDrivers[i].ref = i + z + 1;
    console.log('this.data.passengers => ', this.currentDrivers);
    delete this.currentTraveler;
    // $('#addTravelerModal').modal('toggle');
  }

  closeEvent(event) {
    delete this.currentTraveler;
    $('#addTravelerModal').modal('toggle');
  }

  closeListEvent(event) {
    delete this.currentTraveler;
    $('#listTravelerModal').modal('toggle');
  }

  closeEditEvent(event) {
    this.editingTraveler = false;
    delete this.currentTraveler;
    $('#EditTravelerModal').modal('toggle');
  }

  travelerController() {
    const isValidTraveler = (value) => !(!value.name || !value.surname || !value.phoneNumber || value.type !== 'ADT');
    return this.currentDrivers.every(isValidTraveler);
  }

  checkPolicy(car:any) :boolean {
    let isBlock = false;
    if((this.appComponent.isSbt || this.appComponent.isB2B) && car.arrayOfAlerts?.length > 0){
      car.arrayOfAlerts.forEach((a:any)=> a.block ? isBlock = a.block : null);
    }
    return isBlock;
  }

  // car init generation

  bookReservation(): void {
    this.booking = true;
    const body = {
      arrayOfAlerts: this.currentCar.arrayOfAlerts,
      car: this.currentCar,
      rentCompany: this.currentCar.company,
      passengers: this.currentDrivers,
      b2b: false,
    };
    if (this.appComponent.isB2B) {
      body.b2b = true;
    }
    delete body.car.company;
    console.log('body', body);
    this.carService.generateReservation(body).subscribe(resp => {
      this.booking = false;
      console.log(resp);

      if ( localStorage.getItem('fl_dossierId') ) {
        const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
        const bodyD = {
          cars: [resp._id]
        };
        this.dossierService.addCarsToDossier(dossierId, bodyD).subscribe((res) => {
          console.log('res => ', res);
        }, error => {
          console.error('res => ', error);
        });
      }

      this.router.navigate(['/car-rent/reservations/' + resp._id]);
    }, error => {
      alert(error);
      this.booking = false;
    });
  }

}
