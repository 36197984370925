<custom-title></custom-title>
<div class="padding20">
  <mat-card class="padding20">
    <app-selected-users-group [selection]="selection" (removeSelection)="removeSelection($event)"
                              (roleUpdated)="getUserList();emptySelection()" (selectFromGroup)="selectGroupUsers($event)">
    </app-selected-users-group>
    <hr>
    <div class="row my-3">
      <div class="col-12 col-md-4 col-lg-3 searchBox mb-2 mb-md-0">
        <mat-form-field class="w-100" appearance="outline" (keyup.enter)="applyFilter($event)">
          <mat-label>Cerca per nome, cognome, email</mat-label>
          <input matInput type="text" [(ngModel)]="value">
          <button class="miniIcons" *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="resetFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="" style="font-size: 10px!important;">
          <mat-hint>Digita la tua ricerca e premi INVIO</mat-hint>
        </div>
        <hr class="d-block d-md-none">
      </div>
      <div class="col-12">
        <mat-table #table [dataSource]="dataSource" matSort>
          <!-- checkbox header -->
          <ng-container matColumnDef="select">
            <mat-header-cell class="d-flex justify-self-start py-1" *matHeaderCellDef>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <mat-checkbox color="primary" (change)="$event ? selectAllUsers() : null" [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                    <span class="d-none d-md-inline"> {{'POLICY.USER_SELECT_ALL'|translate}} </span>
                  </mat-checkbox>
                  <span class="d-inline d-md-none">{{'POLICY.USER_SELECT_ALL'|translate}}</span>
                </div>
                <div class="col-12 col-lg-6">
                  <mat-checkbox color="primary" (change)="$event ? toggleSelectedPageUsers() : null" [checked]="selection.hasValue() && isCurrentPageSelected()"
                                [indeterminate]="selection.hasValue() && !isCurrentPageSelected()">
                    <span class="d-none d-md-inline"> {{'POLICY.USER_SELECT_PAGE'|translate}} </span>
                  </mat-checkbox>
                  <span class="d-inline d-md-none"> {{'POLICY.USER_SELECT_PAGE'|translate}} </span>
                </div>
              </div>
            </mat-header-cell>
            <mat-cell class="w-100p" *cdkCellDef="let row">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{'NAME'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{ row.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="surname">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{'SURNAME'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{ row.surname }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{'EMAIL'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{ row.email }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="group">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{'POLICY.GROUP'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <span *ngIf="row?.group?.name; else notAssigned">
                {{row?.group?.name}}
              </span>
              <ng-template #notAssigned>
                <span>
                  {{'POLICY.GROUP_NOT_ASSIGNED'|translate}}
                </span>
              </ng-template>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
    <!-- table pagination -->
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator> <!--showFirstLastButtons-->
  </mat-card>
</div>
