
<div class="position-relative">
  <!--
  <div class="fixBanner">
    A causa di interventi di manutenzione straordinaria, il servizio hotel non è disponibile in questo momento.<br/>
    Vi invitiamo a inoltrare la vostra richiesta scrivendo a:<br/><br/>

    <a href="mailto:biglietteria@leonardotravel.it ">biglietteria@leonardotravel.it</a>.<br/><br/>

    Grazie.
  </div>
  -->

  <div class="fcont">
    <div class="searchForm mb-5 collapse show" id="searchCard">
      <mat-card class="search-card searchHotel">
        <mat-card-header>
          <div class="search-header-image" mat-card-avatar></div>
          <mat-card-title>{{'HOTEL_SEARCH'|translate}}</mat-card-title>
          <mat-card-subtitle>{{'FILL_FORM'|translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="mb-3">
            <mat-slide-toggle
              [(ngModel)]="searchFavorite"
              [checked]="searchFavorite"
              class="example-margin">
              {{'SEARCH_BY_FAVORITE'|translate}}
            </mat-slide-toggle>
            <!--
            <mat-slide-toggle
              (ngModelChange)="checkChange($event)"
              [(ngModel)]="hotelSearch.nameSearch"
              [checked]="hotelSearch.nameSearch"
              class="example-margin">
              {{'SEARCH_BY_NAME'|translate}}
            </mat-slide-toggle>
            -->
            <!--
            <div class="mb-2">Tipo di ricerca:</div>
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group mb-2"
              [(ngModel)]="searchType" (ngModelChange)="changeType($event)">
              <mat-radio-button class="example-radio-button" *ngFor="let type of types" [value]="type">
                {{type|translate}}
              </mat-radio-button>
            </mat-radio-group>
            -->
            <div class="mb-2 mt-2" *ngIf="searchType === 'interest' && !searchFavorite">
          <span class="material-icons mr-1" style="font-size:20px">
            info
          </span>
              {{'interest_explaination'|translate}}
            </div>

          </div>


          <div class="row searchContainer">
            <div class="col-12 d-flex justify-content-end">
              <div class="formTopButtons">
                <div class="formTopAction" [hidden]="searchFavorite" [ngClass]="{'ActiveOption': othersOptionsOpen}" (click)="toggleOptions()">
            <span class="material-icons">
              tune
            </span>
                </div>
                <div class="formTopAction reset" [hidden]="searchFavorite" (click)="resetSearchForm()" matTooltip="{{'CLEAR_FORM'|translate}}">
            <span class="material-icons">
              refresh
            </span>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div id="collapseOptions" class="row newStandardFields collapse">
                <div class="col-md-3 col-6">
                  <mat-form-field class="full-width">
                    <mat-label>{{'HOTEL_RATING'|translate}}</mat-label>
                    <mat-select [(ngModel)]="hotelSearch.options.stars" multiple>
                      <!-- <mat-option [value]="undefined" (click)="resetOptionType('stars')">{{'NO_PREFERENCE'|translate}}</mat-option> -->
                      <mat-option *ngFor="let star of hotelRating" [disabled]="hotelSearch.options.stars.length >= 3 && !hotelSearch.options.stars.includes(star.code)"
                                  [value]="star.code">{{star.name|translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div *ngIf="mainComponent.loggedUser.role.superAdmin" class="col-md-3 col-6">
                  <mat-form-field class="full-width">
                    <mat-label>{{'HOTEL_CHAIN'|translate}}</mat-label>
                    <input [(ngModel)]="hotelSearch.chain" matInput type="text">
                  </mat-form-field>
                </div>

                <div *ngIf="mainComponent.loggedUser.role.superAdmin" class="col-md-2 col-6">
                  <mat-slide-toggle
                    (ngModelChange)="strictMode($event)" [(ngModel)]="hotelSearch.strict" [checked]="hotelSearch.strict"
                    class="example-margin">
                    strict
                  </mat-slide-toggle>

                </div>

                <div *ngIf="mainComponent.loggedUser.role.superAdmin" class="col-md-2 col-6">
                  <mat-form-field class="full-width">
                    <mat-label>Cache</mat-label>
                    <mat-select (ngModelChange)="selectCache($event)" [(ngModel)]="hotelSearch.cache">
                      <mat-option [value]="'Live'">Live</mat-option>
                      <mat-option [value]="'LessRecent'">LessRecent</mat-option>
                      <mat-option [value]="'VeryRecent'">VeryRecent</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-2 col-6">
                  <mat-form-field class="full-width">
                    <mat-label>Codici Corporate</mat-label>
                    <mat-select [(ngModel)]="hotelSearch.ratePlans[0]" name="cabin">
                      <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                      <mat-option *ngFor="let corporate of corporates" [value]="corporate.code">{{ corporate.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>


          <div class="row lightbg">

            <div *ngIf="searchType == 'name'" class="col-md-5 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'HOTEL_NAME'|translate}}</mat-label>
                <input (ngModelChange)="searchHotel($event)" [(ngModel)]="hotelText" [matAutocomplete]="hotelComplete" [ngModelOptions]="{standalone: true}"
                       aria-label="Name" matInput placeholder="{{'HOTEL_NAME'|translate}}">
                <button (click)="clearFrom()" *ngIf="hotelText" aria-label="Clear" mat-button mat-icon-button matSuffix type="button">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #hotelComplete="matAutocomplete" (closed)="selectFirst()">
                  <mat-option (click)="selectHotel(hotel)" *ngFor="let hotel of hotelsList" [value]="hotel.name">
                <span style="font-size:12px;">
                  {{hotel.name}}
                  <span style="font-size:11px;">({{hotel.city}} | {{hotel.address}} <span *ngIf="hotel.rating"> | {{hotel.rating}} stars</span>)</span>
                </span>
                  </mat-option>
                </mat-autocomplete>

              </mat-form-field>
            </div>

            <!-- start hotel form -->

            <div *ngIf="searchType == 'citycode'" class="col-md-5 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="{{'CITY'|translate}}"
                  aria-label="Departure"
                  (ngModelChange)="searchAirport($event)"
                  [(ngModel)]="text"
                  [matAutocomplete]="airp"
                >
                <button
                  type="button"
                  *ngIf="text"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearForm()"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete
                  #airp="matAutocomplete"
                  (optionSelected)="getFrom($event.option.value)"
                  (closed)="selectFirstCity()"
                >
                  <mat-option
                    style="height:40px;"
                    *ngFor="let airport of airports"
                    [value]="airport"
                    [ngStyle]="{'background-color': (airport.isCity) ? '#d3d3d330' : '', 'text-transform':'capitalize'}"
                  >
                    <small *ngIf="!airport.isCity">&nbsp;&nbsp;&nbsp;</small><small>{{airport.name}}</small> <small *ngIf="!airport.isCity"> ({{airport.iata}})</small> <small *ngIf="airport.isCity"> (All)</small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div *ngIf="searchType == 'interest'" class="col-md-3 col-sm-6 col-6" [ngClass]="{'d-none':searchFavorite}">
              <mat-form-field class="full-width">
                <mat-label>{{'DESTINATION'|translate}}</mat-label>
                <input [(ngModel)]="hotelSearch.destination.display" class="googleauto" id="pac-input" matInput name="hotelzone" placeholder="luogo di interesse: es, colosseo" required>
                <button (click)="clearmodel()" *ngIf="hotelSearch.destination.display" aria-label="Clear" mat-button mat-icon-button matSuffix>
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div [hidden]="!searchFavorite" *ngIf="favorites" class="col-md-5 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'FAVORITES'|translate}}</mat-label>
                <mat-select [(ngModel)]="favoriteCode">
                  <div class="searchField">
                    <input placeHolder="Cerca" [(ngModel)]="filterValue" (ngModelChange)="filterFav($event)" type="text" class="complete w-100">
                  </div>
                  <mat-option *ngFor="let fav of filteredOptions" [value]="fav.codes">
                    {{fav.city}} - {{fav.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <div *ngIf="searchType == 'interest'" [hidden]="searchFavorite" class="col-md-2 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <mat-label>RADIUS</mat-label>
                <mat-select [(ngModel)]="hotelSearch.destination.radius">
                  <mat-option [value]="0.05">Esatta</mat-option>
                  <mat-option [value]="0.25">250 m</mat-option>
                  <mat-option [value]="0.5">500 m</mat-option>
                  <mat-option [value]="1">1 Km</mat-option>
                  <mat-option [value]="2">2 Km</mat-option>
                  <mat-option [value]="3">3 Km</mat-option>
                  <mat-option [value]="5">5 Km</mat-option>
                  <mat-option [value]="10">10 Km</mat-option>
                  <mat-option [value]="15">15 Km</mat-option>
                  <mat-option [value]="20">20 Km</mat-option>
                  <mat-option [value]="25">25 Km</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3 col-sm-6 col-6">
              <button (click)="toggleGuestsModal()" class="smallmenu watchFare" mat-button>
                <!-- {{'ROOMS'|translate}}: {{hotelSearch.occupancy.length}} | --> {{'GUESTS'|translate}}: {{returnTotalGuests()}}
              </button>
            </div>

            <div class="col-md-2 col-sm-6 col-6">
              <mat-form-field class="full-width">
                <input (click)="checkin.open()" (dateChange)="setCheckOutDate()" [(ngModel)]="hotelSearch.checkIn" [matDatepicker]="checkin" [min]="today" matInput placeholder="{{'CHECK_IN'|translate}}"
                       readonly>
                <mat-datepicker-toggle [for]="checkin" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #checkin></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2 col-sm-6 col-6">
              <mat-form-field *ngIf="showCheckOut" class="full-width">
                <input (click)="checkout.open()" [(ngModel)]="hotelSearch.checkOut" [matDatepicker]="checkout" [min]="hotelSearch.checkIn" matInput placeholder="{{'CHECK_OUT'|translate}}" readonly>
                <mat-datepicker-toggle [for]="checkout" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #checkout></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <!-- end hotel form -->

          <div class="row collapse lightbg" id="formOptions">
            <div class="col-md-3 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'HOTEL_RATING'|translate}}</mat-label>
                <mat-select [(ngModel)]="hotelSearch.options.stars" multiple>
                  <!-- <mat-option [value]="undefined" (click)="resetOptionType('stars')">{{'NO_PREFERENCE'|translate}}</mat-option> -->
                  <mat-option *ngFor="let star of hotelRating" [value]="star.code"
                              [disabled]="hotelSearch.options.stars.length >= 3 && !hotelSearch.options.stars.includes(star.code)">
                    {{star.name|translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="mainComponent.loggedUser.role.superAdmin" class="col-md-3 col-6">
              <mat-form-field class="full-width">
                <mat-label>{{'HOTEL_CHAIN'|translate}}</mat-label>
                <input [(ngModel)]="hotelSearch.chain" matInput type="text">
              </mat-form-field>
            </div>

            <div *ngIf="mainComponent.loggedUser.role.superAdmin" class="col-md-2 col-6">
              <mat-slide-toggle (ngModelChange)="strictMode($event)" [(ngModel)]="hotelSearch.strict" [checked]="hotelSearch.strict" class="example-margin">
                strict
              </mat-slide-toggle>
            </div>

            <div *ngIf="mainComponent.loggedUser.role.superAdmin" class="col-md-2 col-6">
              <mat-form-field class="full-width">
                <mat-label>Cache</mat-label>
                <mat-select (ngModelChange)="selectCache($event)" [(ngModel)]="hotelSearch.cache">
                  <mat-option [value]="'Live'">Live</mat-option>
                  <mat-option [value]="'LessRecent'">LessRecent</mat-option>
                  <mat-option [value]="'VeryRecent'">VeryRecent</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2 col-6">
              <mat-form-field class="full-width">
                <mat-label>Codici Corporate</mat-label>
                <mat-select [(ngModel)]="hotelSearch.ratePlans[0]" name="cabin">
                  <mat-option value="">-- {{'NO_PREFERENCE'|translate}} --</mat-option>
                  <mat-option *ngFor="let corporate of corporates" [value]="corporate.code">{{ corporate.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
          <div *ngIf="!loading" class="buttonrow text-right">
            <!--<button [hidden]="searchFavorite" (click)="toggleOptions()" mat-stroked-button>{{'SHOW_OPTIONS'|translate}}</button>-->
            <!-- <button mat-stroked-button  [disabled]="!hotelSearch.destination.display" (click)="StarthotelSearch()">{{'SEARCH'|translate}}</button> -->
            <button *ngIf="!searchFavorite" class="newButton" (click)="startSearch()" [disabled]="!isValidForm()" >{{'SEARCH'|translate}}</button>
            <!-- <button mat-stroked-button [disabled]="!isValidForm()" (click)="newHotelSearch()">{{'SEARCH'|translate}}</button> -->
            <!--<button [hidden]="searchFavorite" (click)="resetSearchForm()" mat-stroked-button>{{'RESET'|translate}}</button>-->
            <button [hidden]="!searchFavorite" class="newButton" (click)="quote()" [disabled]="!isValidQuoteForm()">{{'REQUEST_QUOTE'|translate}}</button>
          </div>

          <div *ngIf="loading" class="text-right">
            <div class="mb-2">{{'LOADING'|translate}}</div>
            <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
          </div>

        </mat-card-content>
      </mat-card>
    </div>

    <div aria-hidden="true" aria-labelledby="addGuestModal" class="modal fade" data-backdrop="static" id="addGuestModal" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">

        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addGuestModalTitle">
              <!-- {{'ROOMS'|translate}}: {{hotelSearch.occupancy.length}} | --> {{'GUESTS'|translate}}: {{returnTotalGuests()}}
            </h5>
            <button (click)="toggleGuestsModal()" class="close" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div *ngFor="let room of hotelSearch.occupancy; let i = index;" class="mb-3 col-lg-6">
                <div class="singleRoom mb-2">
                  <div class="roomTitle">
                    <span class="material-icons">hotel</span> {{'ROOM'|translate}} <!-- {{i + 1}} -->
                  </div>
                  <!-- ROOM OPTIONS
                  <div class="roomOptions">
                    <div class="row">
                      <div class="col-12">{{'ROOM_OPTIONS'|translate}}</div>
                      <div class="col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'ROOM_CATEORIES'|translate}}</mat-label>
                          <mat-select multiple [(ngModel)]="room.roomCategory">
                            <mat-option *ngFor="let cat of roomCategories" [value]="cat.code">{{cat.name|translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-6">
                        <mat-form-field class="full-width">
                          <mat-label>{{'BED_TYPE'|translate}}</mat-label>
                          <mat-select multiple [(ngModel)]="room.bedTypes">
                            <mat-option *ngFor="let bed of bedTypes" [value]="bed.code">{{bed.name|translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                    </div>
                  </div>
                  -->
                  <hr/>
                  <div class="guestsActions">
                    <div class="row">
                      <div class="col-5 align-self-center">
                        {{'ADULTS'|translate}}
                      </div>
                      <div class="col-7 text-right">
                        <button (click)="removeGuestType(i, 10)" [disabled]="returnAdt(i) === 0 || (returnOver(i) === 0 && returnAdt(i) === 1)" aria-label="Minus" class="text-center" mat-icon-button>
                          <mat-icon style="margin:0;">remove</mat-icon>
                        </button>
                        {{returnAdt(i)}}
                        <button (click)="addGuestType(i, 10)" aria-label="Plus" class="text-center" mat-icon-button>
                          <mat-icon style="margin:0;">add</mat-icon>
                        </button>
                      </div>
                    </div>
                    <!--
                    <hr/>

                    <div class="row">
                      <div class="col-5 align-self-center">
                        {{'OVER_65'|translate}}
                      </div>
                      <div class="col-7 text-right">
                        <button [disabled]="returnOver(i) === 0 || (returnAdt(i) === 0 && returnOver(i) === 1)" class="text-center" mat-icon-button aria-label="Minus" (click)="removeGuestType(i, 2)">
                          <mat-icon style="margin:0;">remove</mat-icon>
                        </button>
                        {{returnOver(i)}}
                        <button class="text-center" mat-icon-button aria-label="Plus" (click)="addGuestType(i, 2)">
                          <mat-icon style="margin:0;">add</mat-icon>
                        </button>
                      </div>
                    </div>
                    -->
                    <hr *ngIf="!appComponent.isSbt && !appComponent.isB2B && !appComponent.isGat"/>
                    <div class="row" *ngIf="!appComponent.isSbt && !appComponent.isB2B && !appComponent.isGat">
                      <div class="col-5 align-self-center">
                        {{'CHILDREN'|translate}}
                      </div>
                      <div class="col-7 text-right">
                        <button [disabled]="returnChd(i) === 0" class="text-center" mat-icon-button aria-label="Minus" (click)="removeGuestType(i, 5)">
                          <mat-icon style="margin:0;">remove</mat-icon>
                        </button>
                        {{returnChd(i)}}
                        <button class="text-center" mat-icon-button aria-label="Plus" (click)="addGuestType(i, 5)">
                          <mat-icon style="margin:0;">add</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div *ngIf="returnChd(i) > 0">
                      <hr style="margin:10px 0"/>
                      <p>{{'HOW_OLD_ARE_CHILDREN'|translate}}</p>
                      <div *ngFor="let guest of room.guests; let g = index">
                        <div *ngIf="guest.code === 5">
                          <mat-form-field>
                            <mat-label>{{'CHILD_AGE'|translate}}</mat-label>
                            <mat-select [(ngModel)]="guest.age">
                              <mat-option [value]="0">0 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="1">1 {{'YEAR_OLD'|translate}}</mat-option>
                              <mat-option [value]="2">2 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="3">3 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="4">4 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="5">5 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="6">6 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="7">7 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="8">8 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="9">9 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="10">10 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="11">11 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="12">12 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="13">13 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="14">14 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="15">15 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="16">16 {{'YEARS_OLD'|translate}}</mat-option>
                              <mat-option [value]="17">17 {{'YEARS_OLD'|translate}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                  </div>
                  <hr *ngIf="hotelSearch.occupancy.length>1"/>
                  <div class="buttonrow text-right smallActionButtons">
                    <button (click)="removeRoom(i)" *ngIf="hotelSearch.occupancy.length>1" mat-stroked-button>
                      {{'REMOVE_ROOM'|translate}}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <hr/>

          <div class="text-right">
            <!-- <button type="button" mat-button (click)="addRoom()">{{'ADD_ROOM'|translate}}</button> -->
            <button (click)="toggleGuestsModal()" mat-button type="button">{{'CLOSE'|translate}}</button>
          </div>

        </div>
      </div>
    </div>
    <div class="row" *ngIf="loading"> <!--  *ngIf="loading" -->

      <!--
      <div class="col-lg-3">
        <app-banner-slide></app-banner-slide>
      </div>
      -->
      <!--
      <div class="col-lg-9">
        <div class="nowloading padding20 text-center">
          {{'LOADING'|translate}}
          <div class="currentForm mb-2">
          </div>
          <mat-progress-spinner [value]="currentLoad"
                                class="example-margin"
                                mode="determinate"
                                style="margin:auto;display: block;">
          </mat-progress-spinner>
          {{currentLoad}}%
        </div>
      </div>


      -->
    </div>
    <!--
    <div *ngIf="loadingSecondo" class="mb-2">
      <div class="mb-2 text-right">
        <span>{{'VERIFING'|translate}}: {{hotelDb.length}} {{'HOTELS'|translate}}</span>
      </div>
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
    -->

    <div *ngIf="loadingSecondo" class="">
      <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>


