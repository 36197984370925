<div *ngIf="arrayOfAlerts && (isB2B || isSbt)" class="tooltipContainer" [ngStyle]="{'margin-right': isHotel ? '3px' : '0'}"
     appTooltip [tooltipTitle]="tooltip" placement="top"> <!--appComponent. appComponent. -->
  <mat-icon *ngIf="arrayOfAlerts?.length === 0" style="color:green;" [ngClass]="{'policyIconHotel':isHotel}"
            class="policyIcon material-icons-outlined">
    check
  </mat-icon>
  <mat-icon color="warn" *ngIf="arrayOfAlerts?.length > 0" [ngClass]="{'policyIconHotel':isHotel}"
            class="policyIcon material-icons-outlined">
    warning
  </mat-icon>
  <div class="custom-tooltip" #tooltip>
    <div *ngIf="arrayOfAlerts?.length === 0">
      <span style="color: #3F51B5">{{'POLICY.NO_RESTRICTIONS'|translate}}</span>
    </div>
    <div style="display: block" *ngFor="let alert of arrayOfAlerts">
      <span [ngStyle]="{ 'color' : (alert?.block === false)? '#3F51B5' : '#F44336' }">{{alert?.message}}</span>
    </div>
  </div>
</div>
