import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: [ './note-modal.component.scss' ]
})
export class NoteModalComponent implements OnInit {

  note = new UntypedFormControl('', Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NoteModalComponent>
  ) {
  }

  ngOnInit(): void {
  }

  closeDialog(confirmed: boolean): void {
    if (!confirmed) {
      this.dialogRef.close({confirmed, note: this.note.value});
      return;
    } else this.dialogRef.close({confirmed, note: this.note.value, type: this.data.type});
  }
}
