<div *ngIf="!isDeleteCondition && !isDeleteSearch">
  <h1 mat-dialog-title>
    {{'POLICY.DELETE_GROUP'|translate}}
  </h1>
  <div mat-dialog-content class="dialogActionContent">
    <h4 class="text-center">{{data.message|translate}}  {{'POLICY.THE_GROUP'|translate}}</h4>
    <h4 class="text-center">{{ data.groupName + '?' }}</h4>
  </div>
  <div mat-dialog-actions class="buttonrowBis d-flex justify-content-end align-self-center">
    <button mat-raised-button [mat-dialog-close]="false">{{'POLICY.CANCEL'|translate}}</button>
    <button mat-raised-button [mat-dialog-close]="true" color="warn">{{'CONFIRM'|translate}}</button>
  </div>
</div>
<div *ngIf="isDeleteCondition && !isDeleteSearch">
  <h1 mat-dialog-title>
    {{'POLICY.DELETE_CONFIG'|translate}}
  </h1>
  <div mat-dialog-content class="dialogActionContent">
    <span class="w-100 m-auto">
      {{'POLICY.DELETE_ITEM'|translate}} {{'POLICY.THE_CONFIG'|translate}} : {{data.item.type|translate}} ?
    </span>
  </div>
  <div mat-dialog-actions class="buttonrowBis d-flex justify-content-end align-self-center">
    <button mat-raised-button [mat-dialog-close]="false">{{'POLICY.CANCEL'|translate}}</button>
    <button mat-raised-button [mat-dialog-close]="true" color="warn">{{'CONFIRM'|translate}}</button>
  </div>
</div>
<div *ngIf="isDeleteSearch && !isDeleteCondition">
  <h1 mat-dialog-title>
    {{'POLICY.DELETE_SEARCH'|translate}}
  </h1>
  <div mat-dialog-content class="dialogActionContent">
    {{'POLICY.DELETE_ITEM'|translate}} {{'POLICY.THE_SEARCH'|translate}} ?
  </div>
  <div mat-dialog-actions class="buttonrowBis d-flex justify-content-end align-self-center" >
    <button mat-raised-button [mat-dialog-close]="false">{{'POLICY.CANCEL'|translate}}</button>
    <button mat-raised-button [mat-dialog-close]="true" color="warn">{{'CONFIRM'|translate}}</button>
  </div>
</div>
<div *ngIf="is3DS">
  <h3 style="text-align: center" mat-dialog-title>{{data.message}}</h3>
  <div mat-dialog-content></div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-raised-button [mat-dialog-close]="false">{{'POLICY.CANCEL'|translate}}</button>
    <button mat-raised-button [mat-dialog-close]="true" color="warn">Ok</button>
  </div>
</div>
