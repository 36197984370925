import {Component, ElementRef, HostListener, Input, IterableDiffers, OnInit} from '@angular/core';
import { MainComponent } from '../../../../../mainmodule/main/main.component';
import { Router } from '@angular/router';
import { PriceService } from '../../../../../../services/price/price.service';
import * as $ from 'jquery';
import { HotelService } from '../../../../hotel.service';
import { MapInfoDialogComponent } from '../../../../map-info-dialog/map-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MapResultsDialogComponent } from '../../../../map-results-dialog/map-results-dialog.component';
import { AppComponent } from '../../../../../../app.component';
import { HotelQuotationV4Component } from "../../../../hotel-quotation-v4/hotel-quotation-v4.component";
import {TravelPolicyService} from "../../../../../../services/travel-policy/travel-policy.service";
import {UsersService} from "../../../../../../services/profile/users.service";
@Component({
  selector: 'app-search-results-v3',
  templateUrl: './search-results-v3.component.html',
  styleUrls: ['./search-results-v3.component.scss']
})
export class SearchResultsV3Component implements OnInit {
  @Input() hotelForm : any;
  @Input() hotelRes: any;
  @Input() favorites: any;
  iterableDiffer : any;
  clonedForm: any;
  results: any;
  formvisible = true;
  loading = true;
  numberOfNights : number;
  limit = 9;
  sorting = 'asc';
  loadMore = false;
  showMap = false;
  markers : any;
  map : any;
  searchName : string;
  changeCount = 0;
  clusterMarker : any;
  resultsLength = 0;
  totalPages = 0;
  currentPage = 1;
  firstItem = 0;
  lastItem = 9;
  grid = true;
  fareRequest: any;
  fareCount = 0;
  availableStar: any[] = [];
  starFilter: any[] = [];
  availableCatFilter: any[] = [];
  categoriesFilter: any[] = [];
  textSearch = '';
  onlyWithPrice = true;
  minPriceFound = 10000;
  minPriceFilter = 0;
  maxPriceFilter = 100000;
  maxPriceFound = 0;
  maxDistance = 0;
  minDistance = 10000;
  maxDistanceFilter = 0;
  minDistanceFilter = 0;
  ordering = 'distance';
  availableFavFilter = false;
  favFilter = false;
  count: any;
  remaining = 0;
  windowInnerWidth : any;
  closeExpand : boolean = false;
  travelPolicy: any;

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(event : Event | number) {
    this.windowInnerWidth = event;
    if (this.windowInnerWidth > 992) this.closeExpand = false
  }

  constructor(
    private iterableDiffers : IterableDiffers,
    public mainComponent : MainComponent,
    public appComponent : AppComponent,
    private router: Router,
    public priceService: PriceService,
    private hotelService : HotelService,
    public dialog: MatDialog,
    private travelPolicyService: TravelPolicyService,
    private usersService: UsersService,
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.formvisible = true;
    this.showMap = false;
    this.formvisible = true;
    this.markers = [];
    this.searchName = '';
  }

  ngOnInit(): void {
    this.windowInnerWidth = window.innerWidth;
    console.log('this.windowInnerWidth', this.windowInnerWidth);
    this.clonedForm = JSON.parse(JSON.stringify(this.hotelForm));
    this.fareRequest = {
      checkIn: this.clonedForm.checkIn,
      checkOut: this.clonedForm.checkOut,
      guests: this.clonedForm.occupancy[0].guests,
      hotels: [],
    };
    if(this.mainComponent.opened){
      this.mainComponent.toggleSidenav();
    }
    this.results = JSON.parse(JSON.stringify(this.hotelRes));
    this.totalPages = Math.ceil((this.results.list.length-1) / this.limit);

    this.count = setInterval(() => {
      this.remaining = this.remaining + 1;
      $('.loaderStatus').css('width',(this.remaining)+'%');
      if(this.remaining === 100) {
        clearInterval(this.count);
      }
    }, 200);
    this.getUserPolicy()
    console.log('fareRequest => ', this.fareRequest);
    this.completeFareRequest();
    console.log('favorites => ', this.favorites);
  }

  completeFareRequest(){
    this.fareCount = 0;
    this.fareRequest.hotels = [];
    this.results.list.forEach((r:any) => {
      this.fareRequest.hotels.push(r.hotelCode);
      if(this.minDistance > (r.distanceKm*1000)){
        this.minDistance = Math.round(r.distanceKm *1000);
        this.minDistanceFilter = Math.round(r.distanceKm *1000);
      }
      if(this.maxDistance < (r.distanceKm*1000)){
        this.maxDistance = Math.round(r.distanceKm *1000);
        this.maxDistanceFilter = Math.round(r.distanceKm *1000);
      }
    });
    setTimeout(() => {
      this.callFares();
    }, 15000);
  }

  newFareRequest(){
    this.fareRequest.hotels = [];
    this.hotelRes.list.forEach((r:any) => {
      if(!r.amount){
        this.fareRequest.hotels.push(r.hotelCode);
      }
    });
    this.callFares();
  }

  callFares(){
    this.hotelService.fareHotel_V3(this.fareRequest).subscribe(resp => {
      console.log('fares => ', resp);
      this.arrangeHotels(resp);
      this.fareCount = this.fareCount + 1;
      if(this.fareCount <= 2){
        setTimeout(() => {
          this.newFareRequest();
        }, 5000);
      }
    }, error => {
      console.error(error);
    });
  }

  arrangeHotels(res){
    res.rates.forEach((rs:any)=> {
      const originalIndex = this.hotelRes.list.findIndex((h:any) => h.hotelCode === rs.code);
      if(originalIndex > -1){
        this.hotelRes.list[originalIndex].amount = rs.price;
      }
      const newIndex = this.results.list.findIndex((h:any) => h.hotelCode === rs.code);
      if(newIndex > -1){
        this.results.list[newIndex].amount = rs.price;
      }
      if(this.minPriceFound > rs.price){
        this.minPriceFound = Math.trunc(rs.price - 1 );
        this.minPriceFilter = Math.trunc(rs.price - 1 );
      }
      if(this.maxPriceFound < rs.price){
        this.maxPriceFound = Math.trunc(rs.price + 1 );
        this.maxPriceFilter = Math.trunc(rs.price + 1 );
      }
    });
    this.createHotelFilters();
    if(this.fareCount === 0){
      this.toggleSearch();
    }
    this.loading = false;
  }

  noImg(hotel){
    if(!hotel.images){
      return true;
    }
    if(hotel.images){
      if (hotel.images.length === 0){
        return true;
      }
    }
  }

  starCounter(i : string){
    try{
      const s = parseFloat(i);
      return new Array(s);
    } catch (e) {
      return [];
    }
  }

  toggleSearch(){
    this.formvisible = !this.formvisible;
    $('#searchCard').collapse('toggle');
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  switchGrid(value){
    this.grid = value;
  }

  prevPage(){
    this.currentPage = this.currentPage - 1
    if (this.currentPage === 1){
      this.firstItem = 0;
      this.lastItem = 9;
    } else {
      this.firstItem = ((this.currentPage-1) * this.limit) + 1;
      this.lastItem = (this.currentPage * this.limit) + 1;
    }
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  nextPage(){
    this.firstItem = (this.limit * (this.currentPage)) + 1;
    this.lastItem = (this.currentPage * this.limit) + 9 + 1;
    this.currentPage = this.currentPage + 1;
    $('.mat-sidenav-content').animate({ scrollTop: 0 }, 300);
  }

  returnImg(hotel){
    let img = '';
    if(hotel.images){
      if (hotel.images.length > 0) {
        img = hotel.images[0];
      } else {
        img = 'assets/img/placeholder-image.png';
      }
    } else {
      img = 'assets/img/placeholder-image.png';
    }
    return img;
  }

  createHotelFilters(){
    this.hotelRes.list.forEach((h:any) => {
      const findStar = this.availableStar.findIndex((s:any) => s === h.rating);
      if (findStar === -1){
        this.availableStar.push(h.rating);
        this.starFilter.push(h.rating);
      }
      if(this.isFavorite(h.hotelCode)){
        this.availableFavFilter = true;
      }
      if(h.category){
        const findCat = this.availableCatFilter.findIndex((c:any) => c === h.category);
        if (findCat === -1){
          this.availableCatFilter.push(h.category);
          this.categoriesFilter.push(h.category);
        }
      } else {
        if(!this.availableCatFilter.includes('other')){
          this.availableCatFilter.push('other');
          this.categoriesFilter.push('other');
        }
      }
    })
    this.filterHotel();
  }

  filterHotel(){
    this.results.list = [];
    this.hotelRes.list.forEach((h:any)=>{
      // tslint:disable-next-line:max-line-length
      if (this.includeCategory(h.category) && this.includeStar(h.rating) && this.includeText(h.name) && this.onlyWithPriceFilter(h) && this.includePrice(h.amount) && this.inDistanceFilter(h.distanceKm) && this.isInFavFilter(h.hotelCode)){
        this.results.list.push(h);
      }
    })
    this.totalPages = Math.ceil((this.results.list.length-1) / this.limit);
    this.sort(this.ordering);
  }

  isInFavFilter(code:string){
    if(this.favFilter){
      const index = this.favorites.findIndex((f:any) => f.code === code);
      return index !== -1;
    }
    return true;
  }

  includePrice(amount:number){
    if(this.onlyWithPrice && (amount >= this.minPriceFilter && amount <= this.maxPriceFilter)){
      return true;
    } else if (!this.onlyWithPrice){
      return true;
    }
    return false;
  }

  inDistanceFilter(distance:number){
    distance = Math.round(distance *1000);
    if(distance >= this.minDistanceFilter && distance <= this.maxDistanceFilter){
      return true;
    }
    return false;
  }

  onlyWithPriceFilter(hotel){
    if (this.onlyWithPrice && hotel.amount){
      return true;
    } else if(!this.onlyWithPrice){
      return true;
    }
    return false;
  }

  toggleWithPrice(){
    this.onlyWithPrice = ! this.onlyWithPrice;
    if(!this.onlyWithPrice){
      this.ordering = 'distance';
    }
    this.filterHotel();
  }

  toggleFavorites(){
    this.favFilter = ! this.favFilter;
    this.filterHotel();
  }

  includeStar(value){
    const find = this.starFilter.findIndex((s:any) => s === value);
    if (find !== -1){
      return true;
    }
    return false;
  }

  includedInStarFilter(value){
    const find = this.starFilter.findIndex((s:any) => s === value);
    if (find !== -1){
      return true;
    }
    return false;
  }

  elaborateStarFilter(rating){
    const index = this.starFilter.findIndex((s:any) => s === rating);
    if (index !== -1 && this.starFilter.length > 1){
      this.starFilter.splice(index, 1);
    } else {
      this.starFilter.push(rating);
    }
    this.filterHotel();
  }

  includeCategory(value?){
    if(value){
      const find = this.categoriesFilter.findIndex((c:any) => c === value);
      if (find !== -1){
        return true;
      }
      return false;
    } else {
      if (this.categoriesFilter.includes('other')){
        return true;
      }
      return false;
    }
    return false;
  }

  includedInCategoriesFilter(value){
    const find = this.categoriesFilter.findIndex((c:any) => c === value);
    if (find !== -1){
      return true;
    }
    return false;
  }

  elaborateCatFilter(category){
    const index = this.categoriesFilter.findIndex((c:any) => c === category);
    if (index !== -1 && this.categoriesFilter.length > 1){
      this.categoriesFilter.splice(index, 1);
    } else {
      this.categoriesFilter.push(category);
    }
    this.filterHotel();
  }

  includeText(name){
    if(name.toLowerCase().includes(this.textSearch.toLowerCase())){
      return true;
    };
    return false;
  }

  changeSorting(value:string){
    this.sorting = value;
    this.sort(this.ordering);
  }

  jumpToFirstPage(){
    this.firstItem = 0;
    this.lastItem = 9;
    this.currentPage = 1;
  }

  changePrice(event:any){
    this.filterHotel();
  }

  sort(event:any){
    this.ordering = event;
    if (this.ordering === 'price'){
      if(this.sorting === 'asc'){
        this.results.list = this.results.list.sort( (a, b) => {  return a.amount - b.amount;  });
      } else {
        this.results.list = this.results.list.sort( (a, b) => {  return b.amount - a.amount;  });
      }
    }
    if (this.ordering === 'rating'){
      if(this.sorting === 'asc'){
        this.results.list = this.results.list.sort( (a, b) => {  return a.rating - b.rating;  });
      } else {
        this.results.list = this.results.list.sort( (a, b) => {  return b.rating - a.rating;  });
      }
    }
    if (this.ordering === 'distance'){
      if(this.sorting === 'asc'){
        this.results.list = this.results.list.sort( (a, b) => {  return a.distanceKm - b.distanceKm;  });
      } else {
        this.results.list = this.results.list.sort( (a, b) => {  return b.distanceKm - a.distanceKm;  });
      }
    }
    if(this.currentPage > 1 && this.fareCount > 2){
      this.jumpToFirstPage();
    }
  }

  convertDistance(distance: number){
    if(distance < 1){
      return (Math.round(distance * 1000)) + ' mt';
    } else {
      return (Math.round(distance * 10) / 10) + ' km'
    }
  }

  changeDistance(event:any){
    this.filterHotel();
  }

  quote(code: any, rating?: any){
    // tslint:disable-next-line:max-line-length
    if (!(window.matchMedia('(display-mode: standalone)').matches)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/hotel/quotation'], { queryParams: { code, checkIn: this.clonedForm.checkIn, checkOut: this.clonedForm.checkOut, occupancy: JSON.stringify(this.clonedForm.occupancy),rating :  rating ? rating : 0} }));
      window.open(url, '_blank');
    } else {
      const dialogRef = this.dialog.open(HotelQuotationV4Component, {
        width : '1000px',
        maxWidth : '100%',
        height : '900px',
        maxHeight : '100%',
        data : {
          mainComponent: this.mainComponent,
          appComponent: this.appComponent,
          queryParams: { code, checkIn: this.clonedForm.checkIn, checkOut: this.clonedForm.checkOut, occupancy: JSON.stringify(this.clonedForm.occupancy) },
          rating: rating ? rating : undefined
        }
      })

      dialogRef.afterClosed().subscribe((res?:any)=>{
        if (res?.respId) this.router.navigate(['/hotel/reservations/' + res.respId]);
      })
    }
    // tslint:disable-next-line:max-line-length
    // this.router.navigate(['/hotel/quotation'], { queryParams: { code, checkIn: this.clonedForm.checkIn, checkout: this.clonedForm.checkOut, occupancy: JSON.stringify(this.clonedForm.occupancy) } });
  }

  openDialogAllMap(){
    const dialogRef = this.dialog.open(MapResultsDialogComponent, {
      width: '1200px',
      maxWidth: '100%',
      minHeight:'400px',
      maxHeight: '100%',
      data: {
        hotels: this.results.list,
        form: this.clonedForm,
      },
    });
  }

  openDialogMap(hotel:any){
    const dialogRef = this.dialog.open(MapInfoDialogComponent, {
      width: '800px',
      maxWidth: '100%',
      minHeight:'400px',
      maxHeight: '100%',
      data: {
        hotel,
        form: this.clonedForm,
      },
    });
  }

  returnTotalGuests() : number{
    let quantity = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.clonedForm.occupancy.length; i ++){
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < this.clonedForm.occupancy[i].guests.length; c ++){
        quantity = quantity + this.clonedForm.occupancy[i].guests[c].quantity;
      }
    }
    return quantity;
  }

  addToFavorites(hotel:any){
    this.hotelService.addFavorites_V3(hotel.hotelCode).subscribe((res:any) => {
      console.log('this.favorites => ', hotel);
      hotel.code = hotel.hotelCode;
      this.favorites.push(hotel);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  isFavorite(value:string){
    return this.hotelService.isFavorite(this.favorites, value);
  }

  removeToFavorites(hotel:any){
    this.hotelService.removeFavorites_V3(hotel.hotelCode).subscribe((res:any) => {
      console.log('this.favorites => ');
      const index = this.favorites.findIndex((f:any) => f.code === hotel.hotelCode);
      if(index !== -1){
        this.favorites.splice(index,1);
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  /*getUserPolicy2(){
    if ((this.appComponent.isB2B || this.appComponent.isSbt) && this.mainComponent.loggedUser.company.services.policy){
      const query = {
        options: {
          viewAll: false,
        },
        sort: 'createdAt',
        limit: 9999,
        skip: 0,
        order: 'desc',
        text: '',
      };
      this.usersService.getUsersListCp(query ,this.mainComponent.loggedUser.company._id).subscribe((res:any)=> {
        let policyUser = res.list.filter((user:any)=> user.group && user._id == this.mainComponent.loggedUser._id)[0];
        if (policyUser){
          this.travelPolicyService.getPoliciesList().subscribe((res:any)=> {
            const policy = res.data.filter((p: any) => p.group._id === policyUser.group._id)[0];
            if (policy && policy.configuration?.length > 0 && policy.configuration.filter((t:any)=> t.conditions.type === 'HOTEL_STARS')) {
              this.travelPolicy = policy.configuration.filter((t: any) => t.conditions.type === 'HOTEL_STARS')[0];
            }
          })
        }
      })
    }
  }*/

  getUserPolicy(){
    if ((this.appComponent.isB2B || this.appComponent.isSbt) && this.mainComponent.loggedUser.company.services.policy){
      this.travelPolicyService.getPoliciesList().subscribe((res:any)=> {
        const policy = res.data.filter((p: any) => p.group._id === this.mainComponent.user.group)[0];
        if (policy && policy.configuration?.length > 0 && policy.configuration.filter((t:any)=> t.conditions.type === 'HOTEL_STARS')) {
          this.travelPolicy = policy.configuration.filter((t: any) => t.conditions.type === 'HOTEL_STARS')[0];
        }
      })
    }
  }

  checkIfOutOfPolicyStars(rating:any){
    if ((this.appComponent.isB2B || this.appComponent.isSbt) && this.mainComponent.loggedUser.company.services.policy && this.travelPolicy && rating > 0){
      let isOutOfPolicy:boolean;
      const authorizedHalfMsg = 'Request authorization for a hotel with stars';
      const notAuthorizedHalfMsg = 'Can\'t book a hotel with stars';
      let message = '';
      if (this.travelPolicy.conditions.operator == '>=' && this.travelPolicy.conditions.value >= rating){
        if (!this.travelPolicy.isAuthorized) message = notAuthorizedHalfMsg + ' equal or greater than ' + this.travelPolicy.conditions.value + ' stars.'
        else message = authorizedHalfMsg + ' equal or greater than ' + this.travelPolicy.conditions.value + ' stars.'
      } else if (this.travelPolicy.conditions.operator == '<=' && this.travelPolicy.conditions.value <= rating){
        if (!this.travelPolicy.isAuthorized) message = notAuthorizedHalfMsg + ' equal or smaller than ' + this.travelPolicy.conditions.value + ' stars.'
        else message = authorizedHalfMsg + ' equal or smaller than ' + this.travelPolicy.conditions.value + ' stars.'
      } else if (this.travelPolicy.conditions.operator == '==' && this.travelPolicy.conditions.value == rating){
        if (!this.travelPolicy.isAuthorized) message = notAuthorizedHalfMsg + ' equal to ' + this.travelPolicy.conditions.value + ' stars.'
        else message = authorizedHalfMsg + ' equal to ' + this.travelPolicy.conditions.value + ' stars.'
      } else if (this.travelPolicy.conditions.operator == '==' && this.travelPolicy.conditions.value != rating){
        if (!this.travelPolicy.isAuthorized) message = notAuthorizedHalfMsg + ' not equal to ' + this.travelPolicy.conditions.value + ' stars.'
        else message = authorizedHalfMsg + ' not equal to ' + this.travelPolicy.conditions.value + ' stars.'
      }
      this.travelPolicy.message = message;
      this.travelPolicy.block = (!this.travelPolicy.isAuthorized && !this.travelPolicy.isNotify);
      return [this.travelPolicy]
    } else return [];
  }
}
