import {Component, Input, OnInit} from '@angular/core';
import {StatisticService} from '../../../../services/statistic/statistic.service';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {CompaniesService} from '../../../../services/profile/companies.service';
import {AppComponent} from '../../../../app.component';
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  currentTab: string;
  availableTabs: any[];
  availableSections : any = {};
  @Input() chartmode: string;
  chartOptions: any;
  loadedPie = false;
  year: number;
  currentYear: number;
  loading: boolean;
  companytext: string;
  company: any;
  currentYears = [];
  allYears = [];
  years = [];
  totalyears: number;
  startingYear = 2017;
  fromGeneral: Date = new Date(new Date().setDate(1));
  toGeneral: Date = new Date();
  today = new Date();
  generalStats: any;
  segments: any[];
  companiesList: any;
  pnrsStats: any;
  imagePath = 'assets/img/themes/Logo-Flyleo-blue.png';
  negoPerc = 0;
  otherPerc = 0;
  flightRoi= 0;
  constructor(
    private statsService: StatisticService,
    public mainComponent: MainComponent,
    private companiesService: CompaniesService,
    public appComponent: AppComponent,
  ) {
    this.year = new Date().getFullYear();
    this.currentYear = new Date().getFullYear();
    this.totalyears = (this.currentYear - this.startingYear) + 1;
    this.years = [];
    this.availableTabs = [];
    let i = 0;
    while (i < this.totalyears) {
      const anno = this.startingYear + i;
      this.years.push(anno);
      i++;
    }
    this.currentTab = 'FLIGHT';
  }

  ngOnInit(): void {
    if(this.appComponent.isGat){
      this.imagePath = 'assets/images/gattinonilogo1.png';
    }
    this.getGeneralStats(null);
    this.getPnrsStats(null);
    this.calculateRoi();
  }

  buildPie(){
    this.chartOptions = {
      series: [this.generalStats.flight.pnr, this.generalStats.flight.linked],
      labels: ['Pnr Emessi', 'Pnr Da emettere'],
      chart: {
        type: 'polarArea'
      },
      stroke: {
        colors: ['#fff']
      },
      fill: {
        opacity: 0.8
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
    this.loadedPie = true;
  }

  getPnrsStats(value): void{
    this.pnrsStats = {};
    if (!value) {
      this.loading = true;
    }
    const options = {
      from: new Date(new Date(this.fromGeneral).setHours(6)),
      to: new Date(new Date(this.toGeneral).setHours(6)),
      company: null,
    };
    this.statsService.getPnrs(JSON.stringify(options)).subscribe((res) => {
      this.loading = false;
      if (this.appComponent.isGat) {
        res.data.forEach(a => {
          this.pnrsStats[a._id] = {...a};
        });
      } else {
        res.data.forEach(a => {
          this.pnrsStats[a._id] = {...a};
        });
      }
      console.log('pnrsStats => ', this.pnrsStats);
      //this.calculateRoi();
    }, error => {
      console.error('Error => ', error);
      this.loading = false;
    });
  }

  getGeneralStats(value): void {
    console.log('fromGeneral => ', this.fromGeneral);
    if (!value) {
      this.loading = true;
    }
    const options = {
      from: new Date(new Date(this.fromGeneral).setHours(6)),
      to: new Date(new Date(this.toGeneral).setHours(6)),
      company: null,
    };
    console.log(options);
    if (this.company || this.company === '') {
      options.company = this.company._id;
    }
    this.statsService.getGeneral(JSON.stringify(options)).subscribe((res) => {
      this.generalStats = {};
      this.availableTabs = [];
      if (this.appComponent.isGat) {
        res.data.forEach(a => {
          this.generalStats[a._id] = {...a};
        });
      } else {
        res.data.forEach(a => {
          this.availableTabs.push(a._id.toUpperCase());
          this.availableTabs.sort();
          if (a._id.toUpperCase() === 'HOTEL') this.availableSections[a._id.toString().toUpperCase()] = 'HOTEL.NAME';
          else this.availableSections[a._id.toString().toUpperCase()] = a._id.toUpperCase();
          this.generalStats[a._id] = {...a};
        });
      }
      console.log('Error => ', this.generalStats);
      this.loading = false;
      this.elaboratePerc();
      this.buildPie();
      //this.calculateRoi();
    }, error => {
      console.error('Error => ', error);
      this.loading = false;
    });
  }

  setTab(value) {
    this.currentTab = value;
  }

  countTotal(type, element) {
    const total = this.generalStats.linked[element] + this.generalStats.issued[element] + this.generalStats.flown[element];
    return Math.round((100 * this.generalStats[type][element]) / total);
  }

  searchCompany(value : any) {
    delete this.companiesList;
    this.clearFrom();
    if (value.length >= 2) {
      this.getCompaniesList(value);
    }
  }

  getCompaniesList(value : any) {
    this.clearFrom();
    this.companiesService.getCompaniesAuto(value).subscribe((res) => {
      this.loading = false;
      this.companiesList = res;
      console.log('this.companiesList => ', this.companiesList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  selectFirst() {
    if ( this.companiesList ) {
      this.company = this.companiesList[0];
      console.log('company => ', this.company);
    }
    this.clearFrom();
  }

  selectComp(value) {
    console.log('entro => ', value);
    this.company = value;
    this.clearFrom();
    this.companytext = '';
  }

  clearFrom() {
    /*this.companytext = '';*/
    // delete this.company;
    delete this.companiesList;
  }

  removeCompany(id) {
    this.company = '';
    this.clearFrom()
  }

  addHeaderFooter(doc, totalPages) {
    const header = 'Report ' + this.appComponent.appName;
    const footer = 'Pdf generato in data ' + new Date().toLocaleDateString('it-IT') + ' dal sistema ' + this.appComponent.appName;
    // Carica l'immagine dal percorso in "assets"
    const logo = new Image();
    logo.src = this.imagePath;
    const width = 100;
    const aspectRatio = logo.width / logo.height;
    const height = width / aspectRatio;
    doc.addImage(logo, 'JPEG', 10, 10, width, height);
    // Disegna l'immagine dell'intestazione
    // doc.addImage(logo, 'JPEG', 10, 10, 50, 50);
    // Disegna testo dell'intestazione
    doc.setFontSize(14);
    doc.setTextColor(100);
    // doc.text(header, 70, 35, { align: 'left' });
    // Disegna testo del piè di pagina
    doc.setFontSize(8);
    doc.setTextColor(100);
    doc.text(footer, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
  }

  downloadPDF() {
    const options = {
      filename: this.appComponent.appName +'_report_' + this.fromGeneral.toLocaleDateString('it-IT') + '-' +this.toGeneral.toLocaleDateString('it-IT') + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
      margin: [100, 20],
    };

    const element = document.getElementById('printpdf');
    html2pdf().from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        // Aggiungi l'intestazione e il piè di pagina
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          this.addHeaderFooter(pdf, totalPages);
        }
        // Salva il file PDF
        pdf.save(options.filename);
      });
  }

  elaboratePerc(){
    this.negoPerc = Math.round((this.generalStats.flight.negotiated / this.generalStats.flight.pnr) *100) ;
    this.otherPerc = 100 - this.negoPerc;
  }

  calculateRoi(){
    if(this.generalStats && this.pnrsStats){
      this.flightRoi = Math.round((this.generalStats.flight.pnr / this.pnrsStats.flight.pnr) * 100)
    }
  }
}
