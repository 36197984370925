<div mat-dialog-title *ngIf="search.status" class="badge text-white text-center my-2 mx-3"
     [ngClass]="{'badge-success': search.status=='approved', 'badge-danger': search.status == 'declined', 'badge-warning': search.status == 'pending'}">
    <span class="h-auto">{{search.status|uppercase|translate}}</span>
</div>
<h1 mat-dialog-title class="badge badge-warning text-center text-white" *ngIf="!search.status">
  {{'PENDING'|translate}}
</h1>
<mat-dialog-content style="color: #000!important;">

  <app-hotel-card *ngIf="search.ref == 'Hotel'" [search]="search"></app-hotel-card>
  <app-flight-card *ngIf="search.ref == 'Flight'" [search]="search"></app-flight-card>
  <app-vehicle-card *ngIf="search.ref == 'Car'" [search]="search"></app-vehicle-card>
  <app-train-card *ngIf="search.ref == 'TrainReservation'" [search]="search"></app-train-card>

</mat-dialog-content>
<mat-dialog-actions class="buttonrowBis d-flex align-self-center justify-content-end">
  <button mat-raised-button color="warn" (click)="confirmDialog()"> <!-- *ngIf="search.status == 'declined'"-->
    {{'DELETE'|translate}}
  </button>
  <button mat-stroked-button [mat-dialog-close]="{delete: false, close: true, continueBook: false}">
    {{'CLOSE'|translate}}
  </button>
  <button mat-raised-button class="bg-success text-white" [mat-dialog-close]="{delete: false, close: false, continueBook: true}"
          *ngIf="search.status == 'approved'">
    {{'POLICY.CONTINUE_BOOKING'|translate}}
  </button>
</mat-dialog-actions>
