import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AllSearchesComponent} from './containers/all-searches/all-searches.component';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import { SearchCardModule } from 'src/app/components/shared/search-card/search-card.module';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {CustomCommonModule} from "../../modules/common/custom-common.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [AllSearchesComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    SearchCardModule,
    MatTableModule,
    MatSortModule,
    CustomCommonModule,
    MatPaginatorModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule
  ],
    exports:[
      AllSearchesComponent
    ],
})
export class SearchesModule {
}
