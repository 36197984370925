<div class="padding20">
  <custom-title></custom-title>
  <div class="text-right mb-3">
      <a href="https://www.trenitalia.com/it/informazioni/condizioni_generaliditrasporto.html" target="_blank">
        {{'TRAIN_MODULE.TRENIT_CONDITION'|translate}}
      </a>
  </div>
  <mat-card *ngIf="trainTravel">
    <mat-card-content>
      <mat-card-title class="mb-2">{{'TRAIN_MODULE.CONFIRM_BOOKING' | translate}}</mat-card-title>
      <train-checkout-booking-summary *ngIf="trainTravel.reservatedTravel.bookingDb?._id" [bookingDb]="trainTravel.reservatedTravel.bookingDb"
                                      [title]="'TRAIN_MODULE.ARRANGE_RESERVATION'">
      </train-checkout-booking-summary>
      <hr/>
      <div class="row mb-2">
        <div class="col-lg-6">
          <div>@ {{'TRAIN_MODULE.TRENIT.SEND_NOTIFICATION' | translate}}</div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-lg-6">
          <mat-slide-toggle [(ngModel)]="notifyTraveller">
            {{'TRAIN_MODULE.TRENIT.SEND_NOTIFICATION.MAIL' | translate}}
          </mat-slide-toggle>
        </div>
        <div class="col-lg-6">
          <mat-slide-toggle [(ngModel)]="notifyTravellerSms">
            {{'TRAIN_MODULE.TRENIT.SEND_NOTIFICATION.SMS' | translate}}
          </mat-slide-toggle>
          <ng-container *ngIf="notifyTravellerSms">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'PHONE'|translate}}</mat-label>
              <input pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$" matInput [ngModel]="phoneNumber">
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <hr/>
      <!--          COST CENTER-->
      <div class="row mb-4">
        <app-sel-centro-di-costo [reservationId]="trainTravel.reservatedTravel.bookingDb._id" [costCenter]="costCenter"></app-sel-centro-di-costo>
      </div>
      <div class="buttonrow text-center">
        <hr/>
        <button (click)="goBack($event)" *ngIf="!isModal" mat-raised-button mat-button type="button">
          <mat-icon>arrow_left_alt</mat-icon>
          {{'TRAIN_MODULE.BACK' | translate}}
        </button>
        <app-policy-approval-button [reservation]="trainTravel.reservatedTravel.bookingDb" [type]="'train'" [arrayOfAlerts]="arrayOfAlerts()"
                                    [isBookCompleted]="completed" (buyAction)="completePayment()"></app-policy-approval-button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
