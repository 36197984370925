import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrainCardComponent} from './train-card.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from "@angular/material/expansion";
import {SharedModule} from "../../../modules/shared/shared.module";


@NgModule({
    declarations: [TrainCardComponent],
    exports: [
        TrainCardComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatExpansionModule,
    SharedModule,
  ]
})
export class TrainCardModule { }
