import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../../../services/profile/users.service';
import { TravelPolicyService } from '../../../services/travel-policy/travel-policy.service';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-default-user',
  templateUrl: './default-user.component.html',
  styleUrls: ['./default-user.component.scss']
})
export class DefaultUserComponent implements OnInit {
  @ViewChild('users') selectionList: MatSelectionList;
  loading: boolean = false;
  text: string;
  userList: any[] = [];
  userToSet: any;

  constructor(
    private usersService: UsersService,
    private travelPolicyService: TravelPolicyService,
    public dialogRef: MatDialogRef<DefaultUserComponent>,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
  ) {}


  ngOnInit(): void {}

  searchUser(value:any) {
    if (value.length >= 2 ) {
      this.getUsersList(value);
    } else this.userToSet = {};
  }

  getUsersList(value:any) {
    delete this.userToSet;
    this.usersService.getUsersComplete(value).subscribe((res) => {
      this.loading = false;
      this.userList = res.sort((a:any, b:any) => a.surname > b.surname ? 1 : -1);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clear() {
    this.text = '';
    delete this.userToSet;
    delete this.userList;
  }

  getUser(value:any) {
    this.userToSet = {};
    this.text = value.surname + ' ' + value.name + ' -> ' + value.company.name;
    this.userToSet = value;
  }

  selectFirst() {
    if (!this.userToSet){
      this.userToSet = this.userList[0];
      this.text = this.userToSet.surname + ' ' + this.userToSet.name + ' -> ' + this.userToSet.company.name;
    }
  }

  saveDefaultUser(): void {
    this.travelPolicyService.saveDefaultUser(this.userToSet._id).subscribe(() => {
        this.dialogRef.close();
      }, error => {
        this.snackBar.open(error.message, 'Close');
      }
    );
  }

}
