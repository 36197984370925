import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
    CompleteReservationReq,
    CompleteReservationResponse,
    TrainTravel,
} from '../../classes/train.models';
import { TrainMainComponent } from '../common/train-main/train-main.component';
import { TrainService } from '../../train.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DossierService } from '../../../../services/dossier/dossier.service';

@Component({
    selector: 'train-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent extends TrainMainComponent{

  constructor(
    private service : TrainService,
    public router : Router,
    public titleService : Title,
    public translate : TranslateService,
    public dossierService : DossierService,
  ){
    super(router, titleService, translate);
  }
  @Output() nextStepEvent = new EventEmitter<any>();
  @Output() previousStepEvent = new EventEmitter<string>();
  @Input() isModal = false;
  public completeOrderLoading$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public notifyTraveller = true;
  notifyTravellerSms = false;
  completed: boolean = false;

  phoneNumber : any;
  costCenter: string | undefined;

  override onInit(){
    super.onInit();
    this.setPhoneNumber();
  }

  setPhoneNumber(){
    const t0 = this.trainTravel.travellers[0] || this.trainTravel.reservatedTravel.bookingDb.travellers[0]?.doc;
    this.phoneNumber = t0?.phoneNumber;
  }

  /**
   * On completion of the service it redirects to the recap page
   */
  public completePayment() : void{
    const carnetId = this.trainTravel.reservatedTravel.carnetId;
    const request = new CompleteReservationReq(this.trainTravel.reservatedTravel.travel, this.notifyTraveller, carnetId, this.costCenter, this.phoneNumber);
    this.completeOrderLoading$.next(true);
    this.service.completeOrder(request).subscribe((action : CompleteReservationResponse) => {
      this.completeOrderLoading$.next(false);
      this.trainTravel = new TrainTravel();
      this.completed = true;
      this.updateTrainTravel();
      this.addDossier(action.bookingDb);
      this.router.navigate(['trains', 'completed', action.bookingDb.pnr]);
    }, error => {
      this.completed = false;
      this.completeOrderLoading$.next(false)
      console.error(error)
    });
  }

  public goBack($event) : void{
    if (this.isAftersale()){
      this.previousStepEvent.emit('passengers');
    } else{
      const carnetId = this.trainTravel.reservatedTravel.carnetId;
      if (!!carnetId){
        this.router.navigate(['trains', 'search']);
      } else{
        this.router.navigate(['trains', 'passengers']);
      }
    }
  }

  private addDossier(bookingDb : any){
    if (localStorage.getItem('fl_dossierId')){
      const dossierId = JSON.parse(localStorage.getItem('fl_dossierId'));
      const body = {
        trains: [bookingDb._id],
      };
      this.dossierService.addTrainsToDossier(dossierId, body).subscribe((resp) => {
        console.log('res => ', resp);
      }, error => {
        console.error('res => ', error);
      });
    }
  }

  arrayOfAlerts = () => {
    const arrayOfAlerts = [];
    this.trainTravel.arrayOfAlerts.forEach((e:any) => Array.isArray(e) ? e.forEach(ee => arrayOfAlerts.push(ee)) : e != undefined ? arrayOfAlerts.push(e) : null);
    return arrayOfAlerts;
  };

}
