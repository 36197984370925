import {NgModule} from '@angular/core';

import {HotelCardComponent} from './hotel-card.component';
import {PipesModule} from '../../pipes/pipes.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SharedModule} from "../../../modules/shared/shared.module";


@NgModule({
  declarations: [ HotelCardComponent ],
  exports: [
    HotelCardComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule,
    MatExpansionModule,
    MatTooltipModule,
    SharedModule
  ]
})
export class HotelCardModule { }
