<div class="pagetitle">
  Configura Travel Policy
</div>
<div class="padding20">
  <mat-card class="padding20">
    <div class="row">
      <div class="col-6 d-flex justify-content-start align-self-center">
        <div *ngIf="currentPolicy">{{'POLICY.CONFIGURE'|translate}} <strong>{{currentPolicy.name}}</strong> Policy</div>
      </div>
      <div class="col-6 d-flex justify-content-end align-self-center buttonrowBis">
        <button mat-stroked-button  (click)="addItem()">{{'POLICY.ADD_NEW'|translate}}</button>
      </div>
    </div>
    <form [formGroup]="orderForm">
      <div formArrayName="items">
        <div *ngFor="let item of items?.controls ; let i = index;" [ngClass]="{'pb-3' : items?.controls.length-1 === i}" class="row pt-3 pb-2 px-3"
             [formGroupName]="i" style="background-color: rgba(128,128,128,0.05)">
          <div class="col-6 col-md-4 col-lg-2 d-flex align-self-center justify-content-center">
            <mat-form-field appearance="fill" class="fieldsTravelPolicy">
              <mat-label>{{'POLICY.CONDITION'|translate}}</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let option of conditionOptionList; let j = index"  [value]="option"
                            (click)="updateSelected(option)">
                  <span> {{option|translate}} </span> <!-- [disabled]="checkIfSelected(option)" -->
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6 col-md-4 col-lg-2 d-flex align-self-center justify-content-center">
            <mat-form-field appearance="fill" class="fieldsTravelPolicy" *ngIf="!item.get('type').value.toString().includes('PRICE'); else onlyOneOp">
              <mat-label>{{'POLICY.OPERATOR'|translate}}</mat-label>
              <mat-select formControlName="operator">
                <mat-option [disabled]="!(optionSettings[item.get('type').value.toString()].operators.greaterThan)" [value]="'>='">
                  {{'POLICY.OPERATORS.EQUAL_OR_GREATER'|translate}}
                </mat-option>
                <mat-option [disabled]="!(optionSettings[item.get('type').value.toString()].operators.equal)" [value]="'=='">
                  {{'POLICY.OPERATORS.EQUAL'|translate}}
                </mat-option>
                <mat-option [disabled]="!(optionSettings[item.get('type').value.toString()].operators.notEqual)" [value]="'!='">
                  {{'POLICY.OPERATORS.NOT_EQUAL'|translate}}
                </mat-option>
                <mat-option [disabled]="!(optionSettings[item.get('type').value.toString()].operators.equalOrLess)" [value]="'<='">
                  {{'POLICY.OPERATORS.EQUAL_OR_LESS'|translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-template #onlyOneOp>
              <mat-form-field>
                <mat-label>{{'POLICY.OPERATOR'|translate}}</mat-label>
                <mat-select formControlName="operator"> <!--[disabled]="getDisabledSelect(i)"-->
                  <mat-option [value]="optionSettings[item.get('type').value.toString()].defaultOp">
                    {{'POLICY.OPERATORS.' + operatorMap[optionSettings[item.get('type').value.toString()].defaultOp]|translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </div>
          <div class="col-6 col-md-4 col-lg-2 d-flex align-self-center justify-content-center">
            <!-- different types of input based on the condition that you want to use -->
            <mat-form-field appearance="fill" class="fieldsTravelPolicy" *ngIf="optionSettings[item.get('type').value.toString()].value === 'price'">
              <mat-label>{{optionSettings[item.get('type').value.toString()].value|translate}} &euro;</mat-label>
              <input matInput type="number" formControlName="value" placeholder="Item description">
            </mat-form-field>
            <mat-form-field appearance="fill" class="fieldsTravelPolicy" *ngIf="optionSettings[item.get('type').value.toString()].value === 'cabin'">
              <mat-label>{{'POLICY.SELECT_CABIN_CLASS'|translate}}</mat-label>
              <mat-select multiple formControlName="value">
                <mat-option *ngFor="let cabin of cabinOptions" [value]="cabin.value">{{cabin.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="fieldsTravelPolicy" *ngIf="optionSettings[item.get('type').value.toString()].value === 'train_class'">
              <mat-label>{{'TRAIN_CLASS'|translate}}</mat-label>
              <mat-select multiple formControlName="value">
                <mat-option *ngFor="let tClass of trainClasses[item.get('type').value]" [value]="tClass">{{tClass}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="fieldsTravelPolicy" *ngIf="optionSettings[item.get('type').value.toString()].value === 'stars'">
              <mat-label>{{ optionSettings[item.get('type').value.toString()].value|translate }}</mat-label>
              <input type="number" matInput formControlName="value" placeholder="Star number" max="5" min="1" (input)="checkStars(i)">
            </mat-form-field>
          </div>
          <div class="col-6 col-md-4 col-lg-2 d-flex d-md-none align-self-center justify-content-center">
            <button mat-icon-button matTooltip="{{'POLICY.DELETE'|translate}}" color="warn" (click)="deletePolicy(i)"
                    *ngIf="i !== 0  || orderForm.get('items').length > 1" class="iconButtons">
              <mat-icon>delete </mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'POLICY.CONFIG'|translate}}" (click)="addAccess(i)" [matTooltipDisabled]="!(item.get('isAuthorized').value)"
                    [disabled]="slideToggle[i] === 'TO_BLOCK'" class="iconButtons">
              <mat-icon>settings</mat-icon>
            </button>
          </div>
          <div class="col-12 col-md-8 col-lg-4 d-flex align-items-center justify-content-center">
            <mat-radio-group [ngModelOptions]="{standalone : true}" [(ngModel)]="slideToggle[i]" class="d-flex align-self-center">
              <mat-radio-button class="mx-2" *ngFor="let value of toggleSetting" [value]="value"  (change)="isToAuthorize(i, '', true, value)"
                                [disabled]="optionSettings[item.get('type').value.toString()].disabled === value">
                {{value|translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-md-4 col-lg-2 d-none d-md-flex align-self-center justify-content-center">
            <button mat-icon-button matTooltip="{{'POLICY.DELETE'|translate}}" color="warn" (click)="deletePolicy(i)"
                    *ngIf="i !== 0  || orderForm.get('items').length > 1" class="iconButtons">
              <mat-icon>delete </mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'POLICY.CONFIG'|translate}}" (click)="addAccess(i)" [matTooltipDisabled]="!(item.get('isAuthorized').value)"
                    [disabled]="slideToggle[i] === 'TO_BLOCK'" class="iconButtons">
              <mat-icon>settings</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="buttonrowBis text-right">
        <button (click)="onSubmit()" mat-raised-button color="primary">{{'SAVE'|translate}}</button>
      </div>
    </form>
  </mat-card>
</div>
