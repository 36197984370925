import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';

import { UntypedFormControl, UntypedFormGroup,Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { UsersGroupBulkService } from '../services/users-group-bulk.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsersService} from '../../../services/profile/users.service';
import {UsersList} from '../../../classes/userlist';
import {MainComponent} from '../../mainmodule/main/main.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
@Component({
  selector: 'app-users-group-bulk',
  templateUrl: './users-group-bulk.component.html',
  styleUrls: ['./users-group-bulk.component.scss']
})
export class UsersGroupBulkComponent implements OnInit, AfterViewInit {

  rolesList = [];
  isRole = true;
  isValid;

  orderForm = new UntypedFormGroup({
    radioButton: new UntypedFormControl(1),
    group: new UntypedFormControl('',Validators.required),
    user: new UntypedFormControl('',Validators.required)
  });

  userList = [];
  query: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() dataset: Array<any> = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['select', 'name', 'surname', 'email', 'group'];
  value = '';

  constructor(
    private usersService: UsersService,
    private usersGroupBulkService: UsersGroupBulkService,
    private snackBar: MatSnackBar,
    private mainComponent: MainComponent,
  ) {
    this.query = {
      options: {
        viewAll: false,
      },
      sort: 'createdAt',
      limit: 9999,
      skip: 0,
      order: 'desc',
      text: '',
    };
  }

  get radio(): any {
    return this.orderForm.get('radioButton');
  }

  get group(): any {
    return this.orderForm.get('group');
  }

  get user(): any {

    return this.orderForm.get('user');
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.dataset);
    this.dataSource.paginator = this.paginator;
    this.getUserList();

    this.usersGroupBulkService.rolesList().pipe(take(1)).subscribe(res => {
      this.rolesList = res.data;
    });

    this.onSelectedRadio();
  }

  getUserList(): void {
    console.log('this.query => ', this.query);
    this.usersService.getUsersListCp(this.query, this.mainComponent.loggedUser.company._id).subscribe((res: UsersList) => {
      this.userList = res.list;
      this.dataset = res.list;
      this.dataSource = new MatTableDataSource<any>(this.dataset);
      this.paginator.pageIndex = 0;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.filterPredicate = function (user , filter:string ): boolean {

        // Custom filter since group.name is a sublevel information
        return (!!user.group && (user.group.name.trim().toLocaleLowerCase().startsWith(filter.trim().toLocaleLowerCase())))
          || (user.name.trim().toLocaleLowerCase().startsWith(filter.trim().toLocaleLowerCase()))
          || (user.surname.trim().toLocaleLowerCase().startsWith(filter.trim().toLocaleLowerCase()))
          || (user.email.trim().toLocaleLowerCase().startsWith(filter.trim().toLocaleLowerCase()));
      }
    }, error => {
      console.error('Error => ', error);
    });
  }

  onSubmit(): void {
    this.isValid = this.orderForm.invalid;
    if(this.orderForm.valid){
      this.usersGroupBulkService.assignGroup(this.orderForm.value).pipe(take(1)).subscribe((res)=>{
          this.snackBar.open('Users Assigned Successfully', 'Close');
          this.orderForm.reset();
          console.log(this.orderForm);
        },
        error => {
          this.snackBar.open(error.message, 'Close');
        }
      );
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  selectAllUsers(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  /** Make table sortable. */
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  /** Apply search filters. */
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  resetFilter(){
    this.value = ''
    this.dataSource.filter = '';
  }

  /** When user is being deselected from the select list component. */
  removeSelection(user): void {
    this.selection.toggle(user);
  }


  /** Toggle current page selected users selection. */
  toggleSelectedPageUsers(): void {
    const currentPageUsers = this.dataSource.filteredData.filter((users, index) => {
      return index >= (this.paginator.pageSize * this.paginator.pageIndex) &&
        index < (this.paginator.pageSize * this.paginator.pageIndex + this.paginator.pageSize);
    });

    if (this.isCurrentPageSelected()) {
      currentPageUsers.forEach(row => this.selection.deselect(row));
    } else {
      currentPageUsers.forEach(row => this.selection.select(row));
    }

  }


  /** Check if current page is selected. */
  isCurrentPageSelected(): boolean {

    const currentPageUsers = this.dataSource.filteredData.filter((users, index) => {
      return index >= (this.paginator.pageSize * this.paginator.pageIndex) &&
        index < (this.paginator.pageSize * this.paginator.pageIndex + this.paginator.pageSize);
    });
    const ids = currentPageUsers.map(user => user._id);
    const selectedFiltered = this.selection.selected.filter((userSel) => ids.indexOf(userSel._id) !== -1);

    return selectedFiltered.length === this.paginator.pageSize;
  }


  /** Empty the list of user selections and also filter values. */
  emptySelection(): void {
    this.selection.clear();
    this.value = null;
  }

  onSelectedRadio(): void {
    this.isRole = this.radio.value === '1';
    if (this.radio.value === '1') {
      this.isValid = false;
    } else {
      this.isValid = this.orderForm.invalid;
    }
  }

  selectGroupUsers(e: any){
    if (e.value !== 'clear'){
      const groupId = e.value;
      this.selection.clear()
      this.dataset.forEach((d:any)=> {
        if (d.group?._id === groupId) this.selection.select(d);
      })
    } else{
      this.selection.clear()
    }
  }
}
