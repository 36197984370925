<ng-container *ngIf="shouldCheckPolicy() else buyButton">
  <ng-container *ngIf="!reservation.approvalId && !isToNotify()">
    <button (click)="requestAuthorization()" [disabled]="alreadyRequested" color="primary" mat-raised-button>
      {{'REQUEST_AUTHORIZATION'|translate}}
      <span class="material-icons" *ngIf="!(loading$ | async) else loadingSpinner"> arrow_right_alt </span>
    </button>
    <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt" [arrayOfAlerts]="arrayOfAlerts"></app-policy-alerts-icon>
  </ng-container>
  <ng-container *ngIf="reservation.approvalId && !isToNotify()">
    <ng-container *ngIf="!search || search.status !== 'approved' || !transferApproved() else buyButton">
      <div class="mb-1 pt-2 smalltext">
        <button class="iconButtons" mat-icon-button (click)="getInfo()" matTooltip="Aggiorna richiesta">
          <mat-icon> refresh </mat-icon>
        </button>
      </div>
      <div *ngIf="!transferApproved()" class="pt-2 mb-1 smalltext text-danger">
        Trasferta non ancora autorizzata
      </div>
      <div *ngIf="search && search.status === 'pending'" class="mb-1 smalltext text-warning">
        {{'POLICY.AUTHORIZATIONS_SENT'|translate}}
      </div>

      <div *ngIf="search" class="smalltext">
        <div class="mb-1 pt-2">
          {{'POLICY.RECEIVED_REQUEST.STATUS'|translate}}
          <span *ngIf="search.status === 'pending'" class="text-warning">{{search.status|uppercase|translate}}</span>
          <span *ngIf="search.status === 'approved'" class="text-success">{{search.status|uppercase|translate}}</span>
          <span *ngIf="search.status === 'declined'" class="text-danger">{{search.status|uppercase|translate}}</span>
        </div>

        <div *ngIf="search.status === 'declined'">
          <div *ngFor="let auth of search.userAuthorisations">
            <ng-container *ngIf="auth.status === 'declined'">
              <div>
                {{'POLICY.POLICY_NOTE'|translate}} <span class="text-danger">{{auth.note}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isToNotify()">
    <button (click)="buyAndNotify()" color="primary" matTooltip="{{'POLICY.WILL_NOTIFY'|translate}}" mat-raised-button>
      {{'POLICY.BUY_AND_NOTIFY'|translate}}
      <span class="material-icons" *ngIf="!(loading$ | async) else loadingSpinner"> arrow_right_alt </span>
    </button>
  </ng-container>

</ng-container>

<ng-template #buyButton>
  <div class="d-flex align-self-center justify-content-center">
    <button (click)="takeAction()" color="primary" mat-raised-button
            [disabled]="disabled || (loading$ | async) || (shouldCheckPolicy() && !!search && search.status !== 'approved')">
      {{'BUY_RESERVATION'|translate}}
      <span class="material-icons" *ngIf="!(loading$ | async) else loadingSpinner"> arrow_right_alt </span>
    </button>
    <div *ngIf="(appComponent.isSbt || appComponent.isB2B) && search" class="smalltext">
      <div class="mb-1 pt-2">
        {{'POLICY.RECEIVED_REQUEST.STATUS'|translate}}
        <span *ngIf="search.status === 'pending'" class="text-warning">{{search.status|uppercase|translate}}</span>
        <span *ngIf="search.status === 'approved'" class="text-success">{{search.status|uppercase|translate}}</span>
        <span *ngIf="search.status === 'declined'" class="text-danger">{{search.status|uppercase|translate}}</span>
      </div>

      <div *ngIf="search.status === 'declined'">
        <div *ngFor="let auth of search.userAuthorisations">
          <ng-container *ngIf="auth.status === 'declined'">
            <div>
              {{'POLICY.POLICY_NOTE'|translate}} <span class="text-danger">{{auth.note}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <mat-icon class="spin">autorenew</mat-icon>
</ng-template>
