import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TravelersService} from '../../../../../services/travelers/travelers.service';
import {Traveler} from '../../../../../classes/travelers/singletraveler';
// @ts-ignore
import * as data from '../../../../../../assets/data/country.json';
import * as CU from '../../../../../../assets/json/countries.json';
@Component({
  selector: 'app-edittraveler',
  templateUrl: './edittraveler.component.html',
  styleUrls: ['./edittraveler.component.scss']
})
export class EdittravelerComponent implements OnInit {
  @Input() parent: any;
  @Input() currentTraveler: any;
  @Input() fromReservation: boolean;
  // tslint:disable-next-line:no-output-rename
  @Output('refreshList') change: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename no-output-native
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-rename
  @Output('setTraveler') setTraveler: EventEmitter<any> = new EventEmitter<any>();
  @Input() passPort: boolean;
  @Input() trDate: any;
  @Input() hotel?: any;
  loading: boolean;
  traveler: Traveler;
  countries: any  = (data  as  any).default;
  nations: any  = (data  as  any).default;
  filterCountries: any;
  filterIssuing: any;
  countriesSl: any;
  ageError = false;
  today = new Date();
  constructor(
    private travelersService: TravelersService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.countriesSl = CU;
    console.log(this.currentTraveler)
    this.getTraveler();
    console.log('this.traveler => ', this.traveler);
  }

  requiredDocument(): boolean{
    if(this.traveler.document.number && this.traveler.document.number.length > 0){
      console.log('true number')
    } else {
      console.log('false number')
    }
    return this.traveler.document.number && this.traveler.document.number.length > 0;
  }

  getTraveler() {
    let id;
    if (this.parent === 'travelersList' || this.parent === 'pnrPage') {
      id = this.currentTraveler._id;
    } else if (!this.currentTraveler.addressBook) {
      id = this.currentTraveler._id;
    } else {
      id = this.currentTraveler._id;
    }
    this.travelersService.getTraveler(id).subscribe((res) => {
      this.loading = false;
      this.traveler = res;
      // this.filterStates(this.traveler.document.nationality);
      console.log('this.traveler => ', this.traveler);
      this.traveler.type = this.currentTraveler.type;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  filterStates(value: string) {
    const filterValue = value.toLowerCase();
    this.filterCountries = JSON.parse(JSON.stringify(this.countries.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0)));
  }

  filteringIssuing(value: string) {
    const issuingValue = value.toLowerCase();
    this.filterIssuing = JSON.parse(JSON.stringify(this.nations.filter(state => state.name.toLowerCase().indexOf(issuingValue) === 0)));
  }

  saveTraveler() {
    this.loading = true;
    if (this.traveler.birthday) {
      this.traveler.birthday = new Date(new Date(this.traveler.birthday).setHours(6));
    }
    if (this.traveler.document && this.traveler.document.expiryDate) {
      this.traveler.document.expiryDate = new Date(new Date(this.traveler.document.expiryDate).setHours(6));
    }
    this.traveler.loyaltyCodes = this.traveler.loyaltyCodes.filter(lc => !!lc.type && !!lc.product && !!lc.code);
    this.travelersService.editTraveler(this.traveler).subscribe((res) => {
      this.loading = false;
      if (this.parent === 'travelersList') {
        this.getTraveler();
        this.change.emit();
      }
      if (this.parent === 'searchResults' || (this.parent === 'pnrPage' || this.parent === 'trainCompany')) {
        this.setTraveler.emit({...this.traveler, addressBook: this.traveler._id});
        this.close.emit();
      }
      console.log('this.traveler => ', this.traveler);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  isInvalidForm() {
    let invalid = false;
    if (this.traveler.birthday && !this.traveler.sex) {
      invalid = true;
    }
    // tslint:disable-next-line:max-line-length
    if (this.traveler.document.number && (!this.traveler.sex || !this.traveler.birthday || !this.traveler.document.expiryDate || !this.traveler.document.nationality || !this.traveler.document.issuingCountry)) {
      invalid = true;
    }
    if(
      this.traveler.document.nationality && !this.validCountry(this.traveler.document.nationality) ||
      this.traveler.document.issuingCountry && !this.validCountry(this.traveler.document.issuingCountry)
    ){
      invalid = true;
    }
    if (this.traveler.name && this.traveler.surname && !this.traveler.email) invalid = true;
    return invalid;
  }

  closeModal() {
    this.close.emit();
  }

  validCountry(value): boolean {
    return this.countriesSl.countries.some(e => e.code === value);
    // return this.countriesSl.countries.filter(e => e.code === value).length > 0;
  }

  phoneChange(event){
    const regex = /[0-9]/g;
    const found = event.match(regex);
    if(!found) {
      this.traveler.phoneNumber = '';
    } else if(event.length !== found.length) {
        this.traveler.phoneNumber = '';
      }
  }

  namesControll(value){
    const regex = /^[a-zA-Z\d\-\_ ]+$/; // "
    return regex.test(value);
  }

  dateEvent(event) {
    this.ageError = false;
    const date = new Date(event.value);
    if (this.traveler.type) {
      const age = this.newCalcAge();
      const type = this.returnType(age);
      if (type !== this.traveler.type) {
        this.ageError = true;
      }
    }
    console.log(date);
  }

  calcAge(): number {
    const birthDate = new Date(this.traveler.birthday);
    let age = this.today.getFullYear() - birthDate.getFullYear();
    const m = this.today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && this.today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.traveler.type = this.returnType(age);
    return age;
  }


  newCalcAge(): number {
    let date = new Date();
    if (this.trDate) {
      date = new Date(this.trDate);
    } else {
      date = new Date();
    }
    const birthDate = new Date(this.traveler.birthday);
    let age = date.getFullYear() - birthDate.getFullYear();
    const m = date.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && date.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age);
    return age;
  }

  returnType(age) {
    if(!this.hotel){
      if (age <= 11 && age >= 2) {return 'CHD'; }
      if (age < 2) {return 'INF'; }
      if (age > 11) {return 'ADT'; }
    } else {
      if (age <= 17) {return 'CHD'; }
      if (age > 17) {return 'ADT'; }
    }
  }
}
