<mat-card appearance="outlined" style="margin-top: 10px;">
  <mat-card-content>
    <div>
      <ng-container *ngIf="currentTrip=='ForwardJourney' else returnHeader">
        <div class="row mb-3 lightbg">
          <div class="col-auto align-self-center">
            <span class="material-icons">train</span>
          </div>
          <div class="col-auto align-self-center font-weight-bold">
            [ {{'TRAIN_MODULE.FORWARDJOURNEY'| translate| uppercase}} ]
          </div>
          <div class="col-auto align-self-center">
            <p>{{currentSearch.departure_station?.name || currentSearch.carnetId?.itineraries[0].from.station}}</p>
          </div>
          <div class="col-auto align-self-center">
            <span class="material-icons">arrow_right_alt</span>
          </div>
          <div class="col-auto align-self-center">
            <p>{{currentSearch.arrival_station?.name || currentSearch.carnetId?.itineraries[0].to.station}}</p>
          </div>
          <div class="col-lg-auto buttonrow justify-content-end" *ngIf="trainTravel.italo?.booking && trainTravel.italo?.booking[currentTrip]?.FareSellKey">
            <button (click)="selectPassengers()" class="mat-elevation-z2" color="primary" mat-button> {{'TRAIN_MODULE.SELECT_FARE' | translate}}
              <mat-icon>arrow_right_alt</mat-icon>
            </button>
          </div>
          <div class="col-lg-auto buttonrow justify-content-end" *ngIf="selectedJourney">
            <button (click)="carnetBooking(selectedJourney)" class="mat-elevation-z2" color="primary" mat-button>
              {{'TRAIN_MODULE.SELECT_FARE' | translate}}
              <mat-icon *ngIf="!(evaluateTravelLoading$ | async) else spinIcon">arrow_right_alt</mat-icon>
              <ng-template #spinIcon>
                <mat-icon class="spin">autorenew</mat-icon>
              </ng-template>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #returnHeader>
        <div class="row mb-3 lightbg">
          <div class="col-auto align-self-center">
            <button (click)="backToForwardJourney($event)" class="mat-elevation-z2" color="primary" mat-button>
              <mat-icon>arrow_left_alt</mat-icon>
              {{'TRAIN_MODULE.BACK' | translate}}
            </button>
          </div>
          <div class="col-auto align-self-center">
            <span class="material-icons">train</span>
          </div>
          <div class="col-auto align-self-center font-weight-bold">
            [ {{'TRAIN_MODULE.RETURNJOURNEY'| translate| uppercase}} ]
          </div>
          <div class="col-auto align-self-center">
            <p>{{currentSearch?.arrival_station.name}}</p>
          </div>
          <div class="col-auto align-self-center">
            <span class="material-icons">arrow_right_alt</span>
          </div>
          <div class="col-auto align-self-center">
            <p>{{currentSearch?.departure_station.name}}</p>
          </div>
          <div class="col-lg-auto buttonrow justify-content-end" *ngIf="trainTravel.italo?.booking && trainTravel.italo?.booking[currentTrip]?.FareSellKey">
            <button (click)="selectPassengers()" class="mat-elevation-z2" color="primary" mat-button>
              {{'TRAIN_MODULE.SELECT_FARE' | translate}}
              <mat-icon>arrow_right_alt</mat-icon>
            </button>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-container *ngIf="searchResults[currentTrip].length>0 else searchWithNoResults">
      <div id="">
        <div class="tableMat">
          <!-- header tabella -->
          <div class="row">
            <div class="col-4 col-md-2 col-lg-2 text-center">
              Compagnia
            </div>
            <div class="col-4 col-md-2 col-lg-2 text-center">
              {{'TRAIN_MODULE.TRAIN_DEPARTURE' | translate}} <span class="material-icons">arrow_right_alt</span>
            </div>
            <div class="d-none d-sm-none d-md-block text-center col-4 col-md-2 col-lg-2">
              <span class="material-icons">schedule</span> {{'TRAIN_MODULE.JOURNEY_LENGTH' | translate}}
            </div>
            <div class="text-center col-4 col-md-3 col-lg-2">
              <span class="material-icons">arrow_right_alt</span> {{'TRAIN_MODULE.TRAIN_ARRIVAL' | translate}}
            </div>
            <div class="d-none d-sm-none d-md-block text-center col-4 col-md-2 col-lg-2">
              {{'TRAIN_MODULE.TRAIN_SEGMENT' | translate}}
            </div>
            <div class="d-none d-sm-none d-md-none d-lg-block text-center col-md-2 col-lg-2">
              {{'Info' | translate}}
            </div>
          </div>
          <!-- body della tabella -->
          <div class="mt-2 mt-md-3 mt-lg-4" >
            <mat-accordion class="pt-2 pb-2 mt-2 mb-2">
              <mat-expansion-panel hideToggle *ngFor="let s of searchResults[currentTrip]; let i = index" class="py-2 my-2" (closed)="hasBeenSelected=false">
                <mat-expansion-panel-header (click)="loadJourneyDetail(s)" class="pt-2 pb-2 mt-2 mb-2">
                  <div class="col-4 col-md-2 col-lg-2 text-center pt-2 pb-2 align-self-center">
                    <img [src]="getTrenitLogo(undefined, s)" width="100">
                  </div>
                  <div class="col-4 col-md-2 col-lg-2 text-center align-self-center">
                    <b>{{s.departureDate | date:'HH:mm'}}</b> | <span style="font-size:10px;">{{s.departureDate | date:'dd/MM/yyyy'}}</span>
                    <div class="d-none d-sm-none d-md-block smalltext">
                      {{s.departureStation}}
                    </div>
                  </div>
                  <div class="d-none d-sm-none d-md-block text-center col-4 col-md-2 col-lg-2 align-self-center">
                    {{s.journeyLength | date:'HH':"+0000" }}h {{s.journeyLength | date:'mm':"+0000" }}m
                  </div>
                  <div class="text-center col-4 col-md-3 col-lg-2 align-self-center">
                    <b>{{s.arrivalDate | date:'HH:mm'}}</b> |
                    <span style="font-size:10px;">{{s.arrivalDate | date:'dd/MM/yyyy'}}</span>
                    <div class="d-none d-sm-none d-md-block smalltext">
                      {{s.arrivalStation}}
                    </div>
                  </div>
                  <div class="d-none d-sm-none d-md-block text-center col-4 col-md-2 col-lg-2 align-self-center">
                    <div *ngIf="s.segments.length == 1" class="">
                      {{'TRAIN_MODULE.NON_STOPS'|translate}}
                    </div>
                    <div *ngIf="s.segments.length == 2" class="">
                      {{s.segments.length - 1}} {{'TRAIN_MODULE.TRAIN_STOP'|translate}}
                    </div>
                    <div *ngIf="s.segments.length > 2" class="">
                      {{s.segments.length - 1}} {{'TRAIN_MODULE.TRAIN_STOPS'|translate}}
                    </div>
                    <ng-container>
                      <div *ngFor="let item of s.segments; let l = last" [ngClass]="{'mb-2':l}" class="smalltext">
                        {{item.trainClassification | translate}} N° {{ item.trainNumber }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="d-none d-sm-none d-md-none d-lg-block text-center col-md-2 col-lg-2 align-self-center">
                    <a>{{'TRAIN_MODULE.DETAILS' | translate}}</a>
                  </div>
                </mat-expansion-panel-header>
                <div class="row" *ngIf="!hasBeenSelected && !toggleSelected && !isTrenitalia(i, s)">
                  <div class="col-12 col-md-6">
                    <div class="col-12 mx-2">
                      <p>Seleziona una tariffa per tutti i viaggiatori</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-content-start mt-2">
                    <div class="col-12" >
                      <button mat-raised-button color="primary" class="mr-2" [ngClass]="(paxButtonSelected) ? '' : ''"
                              *ngFor="let t of trainTravel.italo?.booking.travellers">
                        {{t.name}} {{t.surname}}
                      </button>
                    </div>
                  </div>
                  <div class="col-6 d-flex justify-content-end mt-2">
                    <p class="px-4 d-none d-sm-none d-lg-block">{{'TRAIN_MODULE.TOGGLE_VIEW'|translate}}</p>
                    <button class="d-none d-sm-none d-lg-block" [class]="{visualizationSelected:!toggleSelected}" (click)="toggleStructure()" style="border: 1px solid #dee2e6; border-bottom-left-radius: 5px; border-top-left-radius: 5px; border-right: none;  ">
                      <mat-icon>grid_on</mat-icon>
                    </button>
                    <button class="d-none d-sm-none d-lg-block" [class]="{visualizationSelected:toggleSelected}" (click)="toggleStructure()" style="border: 1px solid #dee2e6; border-bottom-right-radius: 5px; border-top-right-radius: 5px;border-left: none;">
                      <mat-icon>list</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="row" *ngIf="hasBeenSelected && !toggleSelected">
                  <div class="col-12 col-md-6">
                    <div class="col-12 mx-2">
                      <p>{{italoSelectedClass?.value || italoSelectedClass}} - {{italoSelectedFare?.value || italoSelectedFare}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <!--                  Italo-->
                  <ng-container *ngIf="this.isItalo(undefined, s) && (expandedElement === s)">
                    <!--                  Italo TableView -->
                    <ng-container *ngIf="!toggleSelected">
                      <table class="d-none d-lg-block table table-striped table-sm mb-4 mt-4 text-center table-italo-detail">
                        <tr>
                        <tr>
                          <td class="col-6 produtClass header-table-border">{{s.trainNumber}}</td>
                          <th *ngFor="let prod of productClass | keyvalue:productClassOriginalOrder" class="produtClass align-top header-table-border"
                              [matTooltip]="returnItaloDescription(prod['value'])">
                            {{ prod['value'] | translate}}
                          </th>
                        </tr>
                        <tr *ngFor="let cls of classOfService">
                          <th class="align-middle row-table-init col-6" [matTooltip]="returnItaloDescription(cls)">
                            <div >{{cls}}</div>
                          </th>
                          <ng-container *ngFor="let prod of productClass | keyvalue:productClassOriginalOrder">

                            <ng-container *ngIf="s.faresMatrix[cls]!==undefined && s.faresMatrix[cls][prod.key] as fare else esaurito">
                              <td *ngIf="!policyBlock(faresAlerts[fare.FareSellKey]); else noClick"
                                (click)="selectItaloSolution(s,fare, policyBlock(faresAlerts[fare.FareSellKey]), cls, prod)" class="align-middle produtClass"
                                  [class]="{
                                  solutionBox:true ,
                                  solutionBoxSelected: trainTravel.italo?.booking && trainTravel.italo.booking[currentTrip].FareSellKey===fare.FareSellKey,
                                  soldOut: policyBlock(faresAlerts[fare.FareSellKey])}">
                                {{fare.FullFarePrice}} €
                                <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                        [arrayOfAlerts]="faresAlerts[fare.FareSellKey]">
                                </app-policy-alerts-icon>
                              </td>
                              <ng-template #noClick>
                                <td class="align-middle produtClass soldOut solutionBox align-middle" >
                                  <div>{{fare.FullFarePrice}} €</div>
                                  <div>
                                  </div>
                                  <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                          [arrayOfAlerts]="faresAlerts[fare.FareSellKey]">
                                  </app-policy-alerts-icon>
                                </td>
                              </ng-template>
                            </ng-container>
                            <ng-template #esaurito>
                              <td class="solutionBox soldOut align-middle">
                                {{'TRAIN_MODULE.SOLD_OUT' | translate}}
                              </td>
                            </ng-template>
                          </ng-container>
                        </tr>
                      </table>
                      <!-- aggiungo il bottone per proseguire a selezionare il ritorno -->
                      <div class="row">
                        <div class="col-12 d-flex justify-content-end"
                             *ngIf="trainTravel.italo?.booking && trainTravel.italo?.booking[currentTrip]?.FareSellKey">
                          <button (click)="selectPassengers()" mat-raised-button color="primary" class="mr-2" [ngClass]="(paxButtonSelected) ? '' : ''">
                            {{'TRAIN_MODULE.SELECT_FARE' | translate}}
                            <mat-icon>arrow_right_alt</mat-icon>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                    <!--                  Italo ListView -->
                    <ng-container *ngIf="toggleSelected">
                      <div class="mt-4 pt-4">
                        <!--
                        <div class="row mb-4">
                          <div class="col-12 mb-2">
                            {{'TRAIN_MODULE.SELECT_TARIFF'|translate}}
                          </div>
                        </div>
                        -->
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>{{'Tariffa'|translate}}</mat-label>
                              <mat-select  [(ngModel)]="italoSelectedClass" >
                                <mat-optgroup>
                                  <mat-option *ngFor="let class of classOfService | keyvalue:productClassOriginalOrder" [value]="class"
                                              (click)="setItaloClassSelection(class, i)" [ngClass]="{'disabled': true}">
                                    {{class['value']}}
                                  </mat-option>
                                </mat-optgroup>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-12" *ngIf="italoSelectedClass">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>{{'Service'|translate}}</mat-label>
                              <mat-select [(ngModel)]="italoSelectedFare">
                                <mat-optgroup>
                                  <mat-option *ngFor="let class of productClass | keyvalue:productClassOriginalOrder; let j= index"
                                              [disabled]="!checkIfSoldOutItalo(s, class)" [value]="class" [ngClass]="{'disabled': policyFareBlock(class, searchResults[currentTrip][i])}"
                                              (click)="setItaloFareSelection(class, s)">
                                    {{class['value']}}
                                    <span>
                                      <span *ngIf="italoSelectedClass && s.faresMatrix[italoSelectedClass.value]; else esaurito ">
                                        <span *ngIf="checkIfSoldOutItalo(s, class); else esaurito">
                                        - {{s.faresMatrix[italoSelectedClass.value][class['key']]?.FullFarePrice + ' €' || 'esaurito'}}
                                        </span>
                                      </span>
                                      <ng-template #esaurito>
                                        <span> - esaurito </span>
                                      </ng-template>
                                      <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                              [arrayOfAlerts]="returnAlertsItalo(class, searchResults[currentTrip][i])">
                                      </app-policy-alerts-icon>
                                    </span>
                                  </mat-option>
                                </mat-optgroup>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex justify-content-end mb-4">
                            <button *ngIf="trainTravel.italo?.booking && trainTravel.italo?.booking[currentTrip]?.FareSellKey" (click)="selectPassengers()"
                                    mat-raised-button color="primary" class="mr-2" [ngClass]="(paxButtonSelected) ? '' : ''"
                                    [disabled]="policyFareBlock(italoSelectedFare, searchResults[currentTrip][i]) || !checkIfSoldOutItalo(s, italoSelectedFare)">
                              {{'TRAIN_MODULE.SELECT_FARE' | translate}}
                              <mat-icon>arrow_right_alt</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <!--                  Trenitalia-->
                  <ng-container *ngIf="isTrenitalia(undefined, s) && (expandedElement === s)">
                    <mat-tab-group *ngIf="!(loadingDetail$ | async) else trenitaliaLoading" [selectedIndex]="selectedIndex" mat-stretch-tabs="false"
                                   mat-align-tabs="start">
                      <mat-tab *ngFor="let segment of sortedSegments; let i = index;" disabled label="{{segment.trainClassification}} {{segment?.trainNumber}}">
                        <div class="padding5">
                          <div class="row my-2 d-block alert isVenice alert-warning" *ngIf="isVenice(segment.arrivalStation) && i+1 === sortedSegments.length">
                            <span>{{'IS_VENICE_ALERT' | translate}}</span>
                            <span><a href="{{'IS_VENICE_URL' | translate}}" target="new">{{'HERE' | translate}}</a>.</span>
                          </div>
                        </div>
                        <ng-container *ngIf="segment.services.length > 0 && segment.services[0].services?.length > 0 else trenitNoSolutions">
                          <div class="padding10">
                            <div class="row mb-3 lightbg">
                              <div class="row col-md-2 col-lg-3 align-self-center">
                                <span class="material-icons col-2"> train </span>
                                <div class="col-6">
                                  {{segment.trainClassification}}
                                </div>
                                <div class="col-4 smalltext">
                                  N° {{segment.trainNumber}}
                                </div>
                              </div>
                              <hr/>
                              <div class="col-12 col-lg-3 align-self-center">
                                <p>{{segment.departureDate | date:'dd/MM/yyyy - HH:mm'}}</p>
                                <p>{{segment.departureStation?.name}}</p>
                              </div>
                              <div class="col-12 col-lg-3 align-self-center">
                                <p>{{segment.journeyLength | date:'HH':"+0000" }}h {{segment.journeyLength | date:'mm':"+0000" }}m</p>
                              </div>
                              <div class="col-12 col-lg-3 align-self-center">
                                <p>{{segment.arrivalDate | date:'dd/MM/yyyy - HH:mm'}}</p>
                                <p>{{segment.arrivalStation?.name}}</p>
                              </div>
                            </div>
                            <hr/>
                            <div class="row mt-2 mb-4">
                              <div class="col-6 align-self-center">
                                {{'TRAIN_MODULE.SELECT_TARIFF'|translate}}
                              </div>
                              <ng-container class="col-6 d-flex align-content-end" *ngIf="trainTravel.selectedTravelSolution.returnTravelSolution else fwdTrainInfo">
                                <train-info [travelSolution]="trainTravel.selectedTravelSolution.returnTravelSolution"></train-info>
                              </ng-container>
                              <ng-template #fwdTrainInfo class="col-6 d-flex align-content-end">
                                <train-info [travelSolution]="trainTravel.selectedTravelSolution"></train-info>
                              </ng-template>
                            </div>
                            <ng-container *ngIf="!toggleSelected">
                              <div class="row">
                                <div class="col-10 mb-2">
                                  <!-- Traveller Name-->
                                  <button *ngFor="let pax of segment.services; index as i" mat-raised-button class="mr-2 mt-sm-2" color="primary"
                                          [ngClass]="(paxButtonSelected) ? '' : ''" (click)="setTravellerInTable(pax.paxId, segment)">
                                    {{ getTraveller(pax)?.surname }} {{ getTraveller(pax)?.name }}
                                    <sup> ({{getTraveller(pax)?.type}})</sup>
                                  </button>
                                </div>
                                <div class="col-2 d-flex flex-row-reverse">
                                  <span class="" *ngIf="searchBaseResponse.segments.length > 1 && i!=searchBaseResponse.segments.length-1"
                                        matTooltip="{{getEligibilityMessage()}}">
                                    <button (click)="changeTab(i+1)" [disabled]="getDisabledOffer()" mat-raised-button color="primary" class="mr-2"
                                            [ngClass]="(paxButtonSelected) ? '' : ''">
                                      {{'TRAIN_MODULE.FORM.NEXT' | translate}}
                                      <mat-icon>chevron_right</mat-icon>
                                    </button>
                                  </span>
                                  <span *ngIf="i == searchBaseResponse.segments.length-1" matTooltip="{{getEligibilityMessage()}}">
                                    <button (click)="evaluateTravelSolution()" [disabled]="getDisabledOffer() || (evaluateTravelLoading$ | async)"
                                        mat-raised-button class="mr-2" color="primary" [ngClass]="(paxButtonSelected) ? '' : ''">
                                      {{'TRAIN_MODULE.FORM.NEXT' | translate}}
                                      <ng-container
                                        *ngIf=" !(evaluateTravelLoading$ | async) else evaluateTravelLoadingTpl">
                                        <mat-icon>arrow_right_alt</mat-icon>
                                      </ng-container>
                                      <ng-template #evaluateTravelLoadingTpl>
                                        <mat-icon class="spin">autorenew</mat-icon>
                                      </ng-template>
                                    </button>
                                  </span>
                                </div>
                                <div class="col-12" *ngFor="let pax of segment.services; index as i">
                                  <div class="col-12 mt-2" *ngIf="selectedClass[i]?.displayName && selectedOffer[i]?.displayName">
                                    {{ getTraveller(pax)?.surname }} {{ getTraveller(pax)?.name }} ->
                                    {{selectedOffer[i]?.displayName}} -  {{selectedClass[i]?.displayName}}
                                  </div>
                                </div>
                              </div>
                              <!--{{segment|json}}-->
                            </ng-container>
                            <div *ngFor="let pax of segment.services; index as i" class="row mt-2">
                              <!--                  Trenitalia TableView -->
                              <ng-container *ngIf="!toggleSelected && selectedTravellerId===pax.paxId">
                                <table class="d-none d-lg-block table table-striped table-sm mb-4 mt-4 text-center table-italo-detail">
                                  <tr>
                                  <tr>
                                    <th class="col-12 produtClass header-table-border">{{segment.trainNumber}}</th>
                                    <th *ngFor="let trainClass of classesForTable">
                                      {{trainClass.displayName}}
                                    </th>
                                  </tr>
                                  <tr *ngFor="let trainOffersForClass of offerForTable; let i = index">
                                    <th class="align-middle row-table-init col-12 produtClass pointer"
                                        (click)="openTrenitInfoPopup(trainOffersForClass.displayName)"
                                        [ngClass]="{'corporateBg': isCorporate(trainOffersForClass.displayName)}" >
                                      <div matTooltip="{{trainOffersForClass.description}}">
                                        {{trainOffersForClass.displayName}}
                                        <span *ngIf="trainOffersForClass.requiresParams" matTooltip="requires extra params">*</span>
                                      </div>
                                    </th>
                                    <ng-container *ngFor="let trainOffer of trainOffersForClass.classes; let classIndex = index">
                                      <ng-container *ngIf="!!trainOffer as fare else esaurito">
                                        <td class="align-middle produtClass" (click)="selectOfferInTable(segment, trainOffer, classIndex)"
                                            *ngIf="!policyBlock(faresAlerts[trainOffer.id]); else noClick"
                                            [ngClass]="{
                                              'corporateBg': isCorporate(trainOffersForClass.displayName), solutionBox:true ,
                                              solutionBoxSelected: isOfferSelectedInTable(segment, trainOffer, classIndex),
                                              soldOut: trainOffer?.eligibility.level !== 'ELIGIBLE'}">
                                          <div>{{trainOffer.price.amount | currency:trainOffer.price.currency}}</div>
                                          <div>
                                           <!-- <small>{{'TRAIN_MODULE.AFTERSALE.AVAILABLEAMOUNT'|translate}} {{trainOffer.available}}</small> -->
                                          </div>
                                          <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                                  [arrayOfAlerts]="faresAlerts[trainOffer.id]">
                                          </app-policy-alerts-icon>
                                        </td>
                                      </ng-container>
                                      <ng-template #noClick>
                                        <td class="align-middle produtClass soldOut solutionBox align-middle" >
                                          <div>{{trainOffer.price.amount | currency:trainOffer.price.currency}}</div>
                                          <div>
                                          </div>
                                          <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                                  [arrayOfAlerts]="faresAlerts[trainOffer.id]">
                                          </app-policy-alerts-icon>
                                        </td>
                                      </ng-template>
                                      <ng-template #esaurito>
                                        <td class="solutionBox soldOut align-middle">
                                          {{'TRAIN_MODULE.SOLD_OUT' | translate}}
                                        </td>
                                      </ng-template>
                                    </ng-container>
                                  </tr>
                                </table>
                              </ng-container>
                              <!--                  Trenitalia ListView -->
                              <ng-container *ngIf="toggleSelected">
                                <!-- Traveller Name-->
                                <div class="col-12 align-self-center">
                                  {{ getTraveller(pax)?.surname }} {{ getTraveller(pax)?.name }}
                                  <sup> ({{getTraveller(pax)?.type}})</sup>
                                </div>
                                <!-- Class Selection-->
                                <div class="col-12 align-self-center">
                                  <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'TRAIN_MODULE.CABIN'|translate}}</mat-label>
                                    <mat-select [(ngModel)]="selectedClass[i]" placeholder="Service">
                                      <mat-optgroup *ngFor="let group of pax.services" [label]="group.name">
                                        <mat-option [disabled]="checkClassBlock(serviceClass.displayName)" (click)="setOffer(serviceClass, segment.idXml, i)"
                                                    *ngFor="let serviceClass of group.services" [value]="serviceClass">
                                          {{serviceClass.displayName}}
                                          <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                                  [arrayOfAlerts]="classAlerts[serviceClass.displayName] || []">
                                          </app-policy-alerts-icon>
                                        </mat-option>
                                      </mat-optgroup>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <!-- Offer Selection-->
                                <div *ngIf="selectedClass[i]" class="col-12 align-self-center">
                                  <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'TRAIN_MODULE.TARIFF'|translate}}</mat-label>
                                    <span matSuffix *ngIf="selectedOfferId[i]">
                                    <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                            [arrayOfAlerts]="faresAlerts[selectedClass[i][selectedOfferId[i]].id]">
                                    </app-policy-alerts-icon>
                                  </span>
                                    <mat-select placeholder="Select tariff" [(ngModel)]="selectedOffer[i]">
                                      <mat-optgroup *ngFor="let groupOffer of selectedClass[i].offers" [label]="groupOffer.name">
                                        <mat-option (click)="selectTrenitaliaOffer(segment.idXml, offer, i)" *ngFor="let offer of groupOffer.offers"
                                                    [value]="offer" [disabled]="isOfferDisabled(offer) || policyBlock(faresAlerts[offer.id])"
                                                    [ngClass]="{'corporateBg': isCorporate(getOfferDisplayText(offer))}">
                                          {{ getOfferDisplayText(offer) }}
                                          <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                                                  [arrayOfAlerts]="faresAlerts[offer.id] || []">
                                          </app-policy-alerts-icon>
                                        </mat-option>
                                      </mat-optgroup>
                                    </mat-select>
                                  </mat-form-field>
                                  <a (click)="openTrenitInfoPopup(selectedOfferId[i])" *ngIf="selectedOfferId" style="cursor: pointer">
                                    <mat-icon matTooltip="{{ selectedClass[i][selectedOfferId[i]]?.description | translate}}"> info </mat-icon>
                                  </a>
                                </div>
                              </ng-container>
                            </div>
                            <div class="row mb-3">
                              <div class="col-12 d-flex justify-content-between flex-row-reverse">
                                <span *ngIf="searchBaseResponse.segments.length > 1 && i!=searchBaseResponse.segments.length-1"
                                      matTooltip="{{getEligibilityMessage()}}">
                                  <button (click)="changeTab(i+1)" [disabled]="getDisabledOffer()" mat-raised-button color="primary"
                                          class="mr-2" [ngClass]="(paxButtonSelected) ? '' : ''">
                                    {{'TRAIN_MODULE.FORM.NEXT' | translate}}
                                    <mat-icon>chevron_right</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="i == searchBaseResponse.segments.length-1" matTooltip="{{getEligibilityMessage()}}">
                                <button (click)="evaluateTravelSolution()" [disabled]="getDisabledOffer() || (evaluateTravelLoading$ | async)"
                                        mat-raised-button class="mr-2" color="primary" [ngClass]="(paxButtonSelected) ? '' : ''">
                                  {{'TRAIN_MODULE.FORM.NEXT' | translate}}
                                  <ng-container *ngIf=" !(evaluateTravelLoading$ | async) else evaluateTravelLoadingTpl">
                                    <mat-icon>arrow_right_alt</mat-icon>
                                  </ng-container>
                                  <ng-template #evaluateTravelLoadingTpl>
                                    <mat-icon class="spin">autorenew</mat-icon>
                                  </ng-template>
                                </button>
                              </span>
                                <button (click)="changeTab(i-1)" *ngIf="searchBaseResponse.segments.length > 1 && i > 0" mat-raised-button
                                        color="primary" class="mr-2 " [ngClass]="(paxButtonSelected) ? '' : ''">
                                  <mat-icon>chevron_left</mat-icon>
                                  {{'TRAIN_MODULE.BACK' | translate}}
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </mat-tab>
                    </mat-tab-group>
                    <ng-template #trenitNoSolutions>
                      <p>{{'TRAIN_MODULE.TRENIT.NO_SOLUTIONS_AVAILABLE' | translate}}</p>
                    </ng-template>
                    <ng-template #trenitaliaLoading>
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-template>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #searchWithNoResults>
      {{'TRAIN_MODULE.NO_RESULTS' | translate}}
    </ng-template>
  </mat-card-content>
</mat-card>

<div class="filterSearch">
  <div class="carrierFilter">
    <div class="mb-1"><label>{{'Compagnia treno'|translate}}</label></div>
    <span class="singleFilterCarrier" *ngFor="let res of companyFilter; let i = index" [ngClass]="{'greenborder':res.selected}" (click)="filterCarrier(res, i)">
      <img [src]="getTrenitLogoByCPName(res)" width="100" class="filterLogo">
    </span>
  </div>
  <hr/>
  <mat-accordion *ngIf="hasTrenitalia()" class="mb-2">
    <p>{{'Tipi di treno'|translate}}</p>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-description class="justify-content-between">
          <img class="filterCarrier filterImg" [src]="getTrenitLogoByCPName({ company: 'TRENITALIA'})"/>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="pt-2 pb-2">
        <div class="mb-1" *ngFor="let train of trainType">
          <mat-checkbox [(ngModel)]="train.selected" (ngModelChange)="filterCarrier(null, null)">
            {{train.classification}}
          </mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <hr/>
  <div class="fareFilter">
    <div class="mb-1"><label></label></div>
  </div>
</div>
<div (click)="toggleFilters()" class="filtersearchBackdrop"></div>
