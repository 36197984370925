<div class="singleRate">
  <div class="row">

    <div class="col-md-2 text-center">
      <div class="roomIcon">
        <span class="material-icons" *ngIf="isRoomOnly(room.ratePlans[0].mealPlan)"> bed </span>
        <span class="material-icons" *ngIf="!isRoomOnly(room.ratePlans[0].mealPlan)"> restaurant_menu </span>
      </div>
      <div class="ratePlan">
        {{room.ratePlans[0].mealPlan}}
      </div>
    </div>

    <div class="col-md-7">
      <div class="" *ngFor="let rate of room.roomRates, let ind = index;">
        <div class="smalltext mb-1" *ngIf="!isSbt && !isGat">
          {{returnProvider(room.chain)}}
        </div>
        <div class="smalltext mb-1" *ngIf="isSbt || isGat">
          <div *ngIf="checkVoucher(room.chain)">Prepaid Voucher</div>
        </div>
        <div class="rateText mb-1">
          <span *ngIf="room.corporateFare" class="d-block greentxt"><b>{{'CORPORATE'|translate}}</b></span>
          {{rate.text}}
        </div>
        <p class="mb-1 smalltext" *ngIf="!isBooking(room.chain)">
          Tassa di soggiorno non inclusa
        </p>
        <div class="dividerRow"></div>
        <div class="mb-2">
          <span class="maxGuest">{{'MAX_GUESTS'|translate}}: </span>
          <span class="guestNum">
            {{guests.length}}
            <span *ngIf="guests.length>1">{{'PERSONS'|translate}}</span>
            <span *ngIf="guests.length === 1">{{'PERSON'|translate}}</span>
          </span>
        </div>

      </div>
    </div>

    <div class="col-md-3 text-right">
      <div *ngFor="let rate of room.roomRates, let ind = index;" class="roomPrice">
        <span>{{priceService.returnPriceHotel(rate.amount)|number:'.2-2'}}</span>
        <span *ngIf="room.currency == 'EUR'">€</span>
        <span *ngIf="room.currency != 'EUR'" class="material-icons">arrow_right_alt</span>
        <span *ngIf="room.currency != 'EUR'">{{convertPrice(rate.amount, room.currency)|number:'.2-2'}} EUR</span>
        <span class="nights">/ {{numberOfNights}} {{'NIGHTS'|translate}}</span>
      </div>
    </div>
  </div>

  <div class="" *ngFor="let rate of room.roomRates, let ind = index;">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-7 mb-2">
        <div *ngIf="!isFreeCancellationBis()" class="noRefound">
          <div *ngFor="let plan of room.ratePlans">
            <span class="material-icons text-danger"> highlight_off </span>
            {{'NO_REFOUND'|translate}}
          </div>
        </div>

        <div *ngIf="isFreeCancellationBis()" class="refound">
          <div *ngFor="let plan of room.ratePlans">
            <div *ngFor="let penal of plan.penalty; let l = last;" class="penalty">
              <span class="material-icons greentxt"> task_alt </span>
              <span>{{'YES_REFOUND'|translate}}</span>
              <span *ngIf="penal.absoluteDeadLine"> {{'TILL'|translate}} {{removeHr(penal.absoluteDeadLine)|date:'dd/MM/yyyy - HH:mm'}} </span>
              <div class="refound">
                <span class="material-icons"> delete_outline </span>
                {{'CANCELLATION'|translate}} {{'DAL'|translate}}
                <span *ngIf="penal.absoluteDeadLine"> {{penal.absoluteDeadLine|date:'dd/MM/yyyy'}}: </span>
                <span *ngIf="penal.amount && !l"><b> {{penal.amount|number:'.2-2'}} {{room.currency}}</b>
                  <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(penal.amount, room.currency)|number:'.2-2'}}€)</span>
                </span>
                <span *ngIf="penal.amount && l"><b> {{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}</b>
                  <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                </span>
                <span *ngIf="!penal.amount"><b>{{room.roomRates[0].amount|number:'.2-2'}} {{room.currency}}</b>
                  <span *ngIf="room.currency != 'EUR'"><br/>({{convertPrice(room.roomRates[0].amount, room.currency)|number:'.2-2'}}€)</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 text-right">
        <button class="showRoomsBtn" (click)="selectRoomFare(rate, ind)" [disabled]="checkIfBlock()">
          {{'SELECT_ROOM'|translate}}
          <span class="material-icons"> arrow_right_alt </span>
        </button>
        <app-policy-alerts-icon [arrayOfAlerts]="allAlerts(room)"></app-policy-alerts-icon>
        <!--<div class="pt-2" *ngIf="isB2B || isSbt">
          <div *ngIf="(room.hotel.arrayOfAlerts && room.hotel.arrayOfAlerts.length > 0) || (room.arrayOfAlerts && room.arrayOfAlerts.length > 0);
          else noAlert"> &lt;!&ndash; (mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) &ndash;&gt;
            <div class="verySmall text-danger pointer" (click)="room.showPolicy =! room.showPolicy">
              Out of policy
              <span *ngIf="!room.showPolicy" class="smalIcon material-icons ml-1 pointer">expand_more</span>
              <span *ngIf="room.showPolicy" class="smalIcon material-icons ml-1 pointer">expand_less</span>
            </div>
            <div *ngIf="room.showPolicy">
              <div *ngIf="room.arrayOfAlerts && room.arrayOfAlerts.length > 0">
                <div class="verySmall text-danger" *ngFor="let alert of room.arrayOfAlerts">
                  <span class="smalIcon material-icons mr-1">warning_amber</span> {{alert.message}}
                </div>
              </div>
              <div *ngIf="room.hotel.arrayOfAlerts && room.hotel.arrayOfAlerts.length > 0">
                <div class="verySmall text-danger" *ngFor="let alert of room.hotel.arrayOfAlerts">
                  <span class="smalIcon material-icons mr-1">warning_amber</span> {{alert.message}}
                </div>
              </div>
            </div>

          </div>
          <ng-template  #noAlert>
            <div class="text-right"> &lt;!&ndash; (mainComponent.appcomponent.isSbt || mainComponent.appcomponent.isB2B) &ndash;&gt;
              <div class="verySmall text-success">
                <span class="smalIcon material-icons mr-1">check</span> in policy</div>
            </div>
          </ng-template>
        </div>-->
      </div>
    </div>
  </div>

</div>
