import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {DossierService} from '../../../../services/dossier/dossier.service';
import {HttpserviceService} from '../../../../services/httpservice.service';
import {PriceService} from '../../../../services/price/price.service';
import {CustomAuthService} from '../../../../services/customAuth/custom-auth.service';
import {AdmserviceService} from '../../../../services/adminserv/admservice.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MainComponent} from '../../../mainmodule/main/main.component';
import {AppComponent} from '../../../../app.component';
import {CarService} from '../../../../services/car/car.service';
import {CompaniesService} from '../../../../services/profile/companies.service';
import {UsersService} from '../../../../services/profile/users.service';
import {catchError, take} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {TravelPolicyService} from '../../../../services/travel-policy/travel-policy.service';
import {SearchInformationService} from '../../../../components/search-information/services/search-information.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CostcenterService} from '../../../../services/costcenter/costcenter.service';
import {CostCenterDialogComponent} from '../../../shared/cost-center-dialog/cost-center-dialog.component';
import {MatDialog} from '@angular/material/dialog';
declare var google: any;
@Component({
  selector: 'app-carreservation',
  templateUrl: './carreservation.component.html',
  styleUrls: ['./carreservation.component.scss']
})
export class CarreservationComponent implements OnInit {
  legend: any;
  map: any;
  deleting: boolean;
  numberOfDays: number;
  /**
   *  Local variable for flight reservation
   */
  reservation: any;
  /**
   *  Local variable for loading status
   */
  loading: boolean;
  /**
   *  Local variable for current tab
   */
  currentTab: string;
  /**
   *  Local variable for tabs array
   */
  tabs: any[];
  param: any;
  pnrId: string;
  booking: boolean;
  allMarkers = [];
  types: any;
  period: any;
  fops: any;
  methods: any;
  availablePayments = 0;
  search: any;
  transferId: any;
  policyTransfer: any;
  costCenter: any[];
  loadingCenters: boolean = false;
  updating: boolean = false;
  booked: boolean = false;
  constructor(
    public mainComponent: MainComponent,
    public appComponent: AppComponent,
    private dossierService: DossierService,
    private httpservice: HttpserviceService,
    public priceservice: PriceService,
    public customAuth: CustomAuthService,
    private admServ: AdmserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private carService: CarService,
    private companyService: CompaniesService,
    private usersService: UsersService,
    private policyService: TravelPolicyService,
    private searchInformationService: SearchInformationService,
    private snackBar: MatSnackBar,
    private costCenterService:CostcenterService,
    public dialog: MatDialog,
  ) {
    this.legend = {
      CATHEGORY: {
        D: 'Compact Elite',
        E: 'Economy',
        F: 'Fullsize',
        G: 'Fullsize Elite',
        H: 'Economy Elite',
        I: 'Intermediate',
        J: 'Intermediate Elite',
        M: 'Mini',
        N: 'Mini Elite',
        P: 'Premium',
        R: 'Standard Elite',
        S: 'Standard',
        U: 'Premium Elite',
        W: 'Luxury Elite',
        X: 'Special',
        O : 'Oversize',
        L : 'Luxury',
        C : 'Compact',

      },
      TYPE: {
        B: '2-3',
        C: '2-4',
        D: '4-5',
        E: 'Coupe',
        F: 'SUV',
        G: 'Crossover',
        H: 'Motor Home',
        J: 'Open Air All T errain',
        K: 'Commercial Van/Truck',
        L: 'Limousine/ Sedan',
        M: 'Monospace',
        N: 'Roadster',
        P: 'Pick up Regular/ Extended Cab - 2 Portee',
        Q: 'Pick up Double Cab - 4 Portee M',
        R: 'Recreational Vehicle',
        S: 'Sport',
        T: 'Convertible',
        V: 'Furgone trasporto persone',
        W: 'Wagon/Estate',
        X: 'Special',
        Y: '2 Wheel Vehicle',
        Z: 'Offerta Speciale'
      },
      GEAR_TYPE: {
        A: 'Automatico non specificato',
        B: 'Automatico 4WD',
        C: 'Manuale',
        D: 'Automatico AWD',
        M: 'Manuale non specificato',
        N: 'Manuale 4WD'
      },
      FUEL_AND_AC: {
        A: 'Hydrogen ',
        B: 'Hydrogen ',
        C: 'Electric Plus (Distanza ≥ 250mi/400km) Aria Condizionata',
        D: 'Diesel ',
        E: 'Electric (Distanza < 250mi/400km) Aria Condizionata',
        F: 'Multi carburante ',
        H: 'Hybrid ',
        I: 'Hybrid Plug In ',
        L: 'LPG/Compressed Gas ',
        M: 'Multi carburante ',
        N: 'Carburante non specificato ',
        Q: 'Diesel ',
        R: 'Carburante non specificato ',
        S: 'LPG/Compressed Gas ',
        U: 'Ethanol ',
        V: 'Benzina ',
        X: 'Ethanol ',
        Z: 'Benzina '
      }
    };
    this.types = {
      1: 'Drop charge/ One Way Fee',
      2: 'Extra day',
      3: 'Extra week',
      4: 'Extra hour',
      5: 'Extra month',
      6: 'Per mile',
      7: 'Per kilometer',
      8: 'Free miles',
      9: 'Free kilometers',
      10: 'Adult',
      11: 'Child',
      12: 'Employee',
      13: 'Special equipment',
      44: 'Theft Protection(TP)',
      45: 'Tax',
      107: 'CDW (Collision damage waiver)',
      108: 'Surcharge',
      COL: 'Collection Charge',
      COV: 'Coverage Policy',
      CPN: 'Coupon',
      DEL: 'Delivery',
      NSF: 'No Show Fee',
      TAC: 'Total of Additional Charges',
    };
    this.period = {
      '001': 'Per day',
      '002': 'Per week',
      '003': 'Per month',
      '004': 'Per rental',
      '005': 'Per hour',
      '006': 'Per minute',
      AMP: 'And Month Period',
      AYP: 'And Year Period',
      BMP: 'Between Month Period',
      BP: 'Between Period',
      BYP: 'Between Year Period',
      EXS: 'Excess',
      FDP: 'From Day Period',
      FKD: 'From Kilometer Distance',
      FMD: 'From Miles Distance',
      FMP: 'From Month Period',
      FP: 'From Period',
      FWP: 'From Week Period',
      LBY: 'Liability',
      MAX: 'Maximum value',
      MND: 'Maximum Number of Days',
      PK: 'Per Kilometer',
      PM: 'Per Miles',
      PMD: 'Per Modification',
      PMV: 'Per Movment',
      PWE: 'Per Week End',
      TDP: 'To Day Period',
      TKD: 'To Kilometer Distance',
      TMD: 'To Miles Distance',
      TMP: 'To Month Period',
      TP: 'To Period',
      TWP: 'To Week Period',
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.param = params.id;
      this.getPnrId(); // reset and set based on new parameter this time
    });
  }

  generateTabs(): void {
    this.currentTab = 'CAR_INFOS';
    this.tabs = [
      {
        name: 'CAR_INFOS',
        active: true,
      },
      {
        name: 'DRIVERS',
        active: false,
      },
      {
        name: 'SERVICES',
        active: false,
      }
    ];
    if (this.reservation.status === 'init') {
      const tab = {
        name: 'PAYMENT',
        active: false,
      };
      this.tabs.push(tab);
      if(this.availablePayments === 1){
        console.log('entro fop linked');
        this.reservation.creditcard = this.fops[0].indexes.lowcost;
        const find = this.tabs.findIndex(r => r.name === 'PAYMENT');
        this.tabs.splice(find, 1);
      }
    }
    if (this.reservation.status === 'issued' && this.mainComponent.loggedUser.role.superAdmin) {
      const tab = {
        name: 'PNR',
        active: false,
      };
      this.tabs.push(tab);
    }
    this.setTab(0);
  }
  returnAir(value) {
    return  value === 'A' ||
      value === 'C' ||
      value === 'D' ||
      value === 'E' ||
      value === 'H' ||
      value === 'I' ||
      value === 'L' ||
      value === 'M' ||
      value === 'R' ||
      value === 'U' ||
      value === 'V';
  }

  getPnrId() {
    this.pnrId = this.route.snapshot.paramMap.get('id');
    console.log('this.pnrId => ', this.pnrId);
    this.getReservation(this.pnrId);
  }

  getFops(){
    this.companyService.getFops(this.reservation.company._id).subscribe((res) => {
      this.loading = false;
      this.fops = res;
      this.availablePayments = this.availablePayments + this.fops.length;
      this.getMethods();
    }, error => {
      this.loading = false;
      this.getMethods();
      // this.notifier.notify('error', error);
      console.error('Error => ', error);
    });
  }
  getMethods(){
    this.companyService.getMethods(this.reservation.company._id, false).subscribe((res) => {
      this.loading = false;
      this.methods = res;
      this.availablePayments = this.availablePayments + this.methods.length;
      this.generateTabs();
    }, error => {
      this.loading = false;
      this.generateTabs();
      console.error('Error => ', error);
    });
  }

  getReservation(id) {
    this.loading = true;
    this.currentTab = '';
    this.carService.getReservation(id).subscribe((res) => {
      this.loading = false;
      this.reservation = res;
      if(this.reservation.status === 'init'){
        this.getFops();
      } else {
        this.generateTabs();
      }
      if (!res.company.image) {
      this.getCompany(res.company._id);
      }
      if (!res.user.name) {
        this.getUser(res.user);
      }
      this.calculateDays();
      this.setAddresses();
      this.getCpSettings();
      if(!this.checkPolicy()) { /* && this.reservation.approvalId*/
        this.getInfo()
      }
      if(!this.checkPolicy() && this.reservation.paper){
        this.getTransfer();
      }
      if(this.checkPolicy() && (this.appComponent.isSbt || this.appComponent.isB2B) && this.reservation.paper && this.mainComponent.loggedUser.company.services.policy){
        this.getTransfer();
      }
      // this.generateTabs();
      console.log('this.reservation => ', this.reservation);
      if(this.appComponent.isB2B || this.appComponent.isSbt || this.mainComponent.loggedUser.role.superAdmin){
        console.log('costo');
        this.getCostsCenters();
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCostsCenters(){
    this.costCenter = [];
    this.loadingCenters = true;
    const query = {
      sort: 'createdAt',
      limit: 1000,
      skip: 0,
      order: 'asc',
      text: '',
    };
    this.costCenterService.getCostCenterList(query, this.reservation.company._id).subscribe((res: any) => {
      this.loadingCenters = false;
      this.costCenter = res.list;
      console.log('this.costCenter', this.costCenter);
    }, error => {
      this.loadingCenters = false;
      console.error('Error => ', error);
    });
  }

  openCostCenterDialog() {
    const dialogRef = this.dialog.open(CostCenterDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result){
        console.log(result);
        this.reservation.costCenter = result;
        this.getCostsCenters();
        this.updateCostCenter();
      }
    });
  }

  updateCostCenter(){
    this.updating = true;
    const body = {
      costCenter: this.reservation.costCenter,
    }
    this.carService.setCarCostCenter(this.reservation._id, body).subscribe((res: any) => {
      this.updating = false;
      console.log('this.costCenter', this.costCenter);
    }, error => {
      this.updating = false;
      console.error('Error => ', error);
    });
  }

  getTransfer(){
    this.policyService.getTransfer(this.reservation.paper).subscribe((res) => {
      console.log('res reprice => ', res);
      this.policyTransfer = res.data;
      this.transferId = res.data._id;
      console.log('transfer => ', res);
    }, error => {

      console.error('Error => ', error);
    });
  }

  getInfo(){
    this.searchInformationService.getSearch(this.reservation.approvalId).subscribe((res) => {
      console.log('res reprice => ', res);
      this.search = res.data[0];
      this.returnSApproval();
    }, error => {

      console.error('Error => ', error);
    });
  }

  returnSApproval(){
    this.search.status = 'pending';
    const declined = this.search.userAuthorisations.find(x => x.status === 'declined');
    if (!!declined) {
      this.search.status = 'declined';
    }

    const approved = this.search.userAuthorisations.every(x => x.status === 'approved');
    if (!!approved) {
      this.search.status = 'approved';
    }
    // this.search.status = 'pending';
  }

  transferApproved(){
    let valid = true;
    if (this.policyTransfer && this.reservation.approvalId && this.policyTransfer.arrayOfAlerts.length>0){
      if(this.policyTransfer.authorisations) {
        valid = false;
        const approved = this.policyTransfer.authorisations.every(x => x.status === 'approved');
        if (!!approved) {
          this.policyTransfer.status = 'approved';
          valid = true;
        }
      } else {
        valid = false;
      }
    }
    // console.log(' trf');
    return valid;
  }

  onPaymentTabChanged(event) {
    delete this.reservation.creditcard;
  }

  getCpSettings() {
    this.companyService.getCpSettings(this.reservation.company._id).subscribe((res) => {
      this.reservation.company.services = res;
      // this.loading = false;
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  setAddresses(): void {
    this.reservation.car.pickUp = this.reservation.rentCompany.rentalLocations[0].address;
    if (this.reservation.rentCompany.rentalLocations[1]) {
      this.reservation.car.dropOff = this.reservation.rentCompany.rentalLocations[1].address;
    } else {
      this.reservation.car.dropOff = this.reservation.rentCompany.rentalLocations[0].address;
    }
  }

  getCompany(id): void {
    this.companyService.getCompany(id).subscribe((res) => {
      this.reservation.company = res;
      // tslint:disable-next-line:max-line-length
      this.reservation.company.image = this.httpservice.oldApiUrl + 'static/company/' + this.reservation.company._id + '.png?time=' + new Date().getTime();
      console.log('reservation => ', this.reservation.company);
    });
  }

  getUser(id): void {
    this.usersService.getUser(id).subscribe((res) => {
      this.reservation.user = res;
      if (this.reservation.user) {
        // tslint:disable-next-line:max-line-length
        this.reservation.user.image = this.httpservice.oldApiUrl + 'static/user/' + this.reservation.user._id + '.png?time=' + new Date().getTime();
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  calculateDays() {
    const date1 = new Date(this.reservation.car.pickUpInfo.date);
    const date2 = new Date(this.reservation.car.dropOffInfo.date);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    this.numberOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  returnDate(value) {
    return new Date(value);
  }

  toggleRemoveDossierModal() {
    $('#RemoveDossierModal').modal('toggle');
  }

  confirmDossierRemove() {
    const body = {
      cars: [this.pnrId]
    };
    this.dossierService.removeCarsFromDossier(this.reservation.paper, body).subscribe((res) => {
      console.log('res => ', res);
      this.getReservation(this.pnrId);
    }, error => {
      console.error('res => ', error);
    });
    $('#RemoveDossierModal').modal('toggle');
  }

  toggleAddtoTravel() {
    if (!this.mainComponent.dossierId) {
      $('#travelListModal').modal('toggle');
    } else {
      this.addCars(this.pnrId);
    }
  }
  addCars(id) {
    const body = {
      cars: [id]
    };
    this.dossierService.addCarsToDossier(this.mainComponent.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.getReservation(this.pnrId);
    }, error => {
      console.error('res => ', error);
    });
  }

  closeAddtoTravel() {
    $('#travelListModal').modal('toggle');
    this.getReservation(this.pnrId);
  }

  setTab(index) {
    // tslint:disable-next-line:prefer-for-ofq prefer-for-of
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
    if (index === 0) {
      setTimeout(() => {
        this.initMap();
      }, 200);
    }
  }

  initMap(): void {
    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      // tslint:disable-next-line:max-line-length
      center: { lat: this.reservation.rentCompany.rentalLocations[0].coordinates.latitude, lng: this.reservation.rentCompany.rentalLocations[0].coordinates.longitude },
      zoom: 12,
    });
    const image = {
      url: 'assets/img/maps/car-pin-up.png',
      scaledSize: new google.maps.Size(20, 27), // scaled size
    };
    let myLatlng;
    // tslint:disable-next-line:max-line-length
    myLatlng = new google.maps.LatLng(this.reservation.rentCompany.rentalLocations[0].coordinates.latitude, this.reservation.rentCompany.rentalLocations[0].coordinates.longitude);
    const marker = new google.maps.Marker({
      position: myLatlng,
      // label: 'Up',
      title: 'Punto di ritiro ' + this.reservation.rentCompany.name,
      description: this.reservation.rentCompany.rentalLocations[0].address,
      icon: image,
    });
    const infoWindow = new google.maps.InfoWindow({
      content: '<p style="font-size:12px;">' + marker.title + '</p><p style="font-size:12px;">' + marker.description + '</p>',
    });
    marker.addListener('click', ( ) => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    marker.setMap(this.map);
    this.allMarkers.push(marker);
    if (this.reservation.rentCompany.rentalLocations[1]) {
      this.createMarker();
      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        geodesic: true,
        strokeColor: '#4b5ccd',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      };
      console.log(this.allMarkers[0]);
      const line = new google.maps.Polyline({
        path: [
          // tslint:disable-next-line:max-line-length
          { lat: this.reservation.rentCompany.rentalLocations[0].coordinates.latitude, lng: this.reservation.rentCompany.rentalLocations[0].coordinates.longitude },
          // tslint:disable-next-line:max-line-length
          { lat: this.reservation.rentCompany.rentalLocations[1].coordinates.latitude, lng: this.reservation.rentCompany.rentalLocations[1].coordinates.longitude },
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#4b5ccd',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: this.map,
      });
      const bounds = new google.maps.LatLngBounds();
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.allMarkers.length; i++) {
        bounds.extend(this.allMarkers[i].getPosition());
      }
      // this.map.fitBounds(bounds);
    }

  }

  createMarker(): void {
    const infoWindow = new google.maps.InfoWindow({
      // tslint:disable-next-line:max-line-length
      content: '<p style="font-size:12px;"> Punto di restituzione ' + this.reservation.rentCompany.name + '</p><p style="font-size:12px;">' + this.reservation.rentCompany.rentalLocations[1].address + '</p>',
    });
    const image = {
      url: 'assets/img/maps/car-pin-up.png',
      scaledSize: new google.maps.Size(20, 27), // scaled size
    };
    const marker = new google.maps.Marker({
      // tslint:disable-next-line:max-line-length
      position: {lat: this.reservation.rentCompany.rentalLocations[1].coordinates.latitude, lng: this.reservation.rentCompany.rentalLocations[1].coordinates.longitude},
      // label: 'Off',
      map: this.map,
      icon: image,
      // label: labels[i % labels.length],
    });
    marker.addListener('click', ( ) => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });
    this.allMarkers.push(marker);
    return marker;
  }

  checkPolicy() :boolean {
    if ((this.appComponent.isSbt || this.appComponent.isB2B) && this.reservation.arrayOfAlerts?.length > 0 && this.mainComponent.loggedUser.company.services.policy){
      return false // !tariff.arrayOfAlerts.every(a => a.block === false)
    }
    return true;
  }

  isToNotify(){
    let isNotify = false;
    if(this.appComponent.isSbt || this.appComponent.isB2B && this.reservation.arrayOfAlerts?.length > 0){
      this.reservation.arrayOfAlerts.forEach((r:any)=> isNotify = r.policy.isNotify ? r.policy.isNotify : false);
    }
    return isNotify
  }

  requestAuthorization(): void {
    const travelers:any = [];
    const dossier = this.transferId || undefined;
    travelers.push(this.reservation.user._id);
    this.policyService.createVehicleSearch(this.reservation, travelers, dossier).pipe(catchError(err => {
      this.snackBar.open(err.message, 'Error', {
        duration: 2000,
      });
      // this.isLoading = false;
      return throwError(err);
    })).subscribe(res => {
      this.saveApprovalId(res.data._id);
      this.pushAuth(res, dossier);
      this.getReservation(this.pnrId);
    });
  }

  pushAuth(res: any, dossier: any, isNotify: boolean = false){
    const authBody = this.policyService.createCarAuthorization(this.reservation, res.data.searchId, dossier);
    authBody.forEach((auth:any) => {
      this.policyService.postAuthorization(auth).pipe(take(1), catchError(err => {
        this.snackBar.open(err.message, 'Error', {
          duration: 2000,
        });
        return throwError(err);
      })).subscribe(authRes => {
        let msg = '';
        if(isNotify) msg = 'POLICY.NOTIFICATION_SENT';
        else msg = 'POLICY.AUTHORIZATIONS_SENT';
        this.snackBar.open( this.appComponent.translate.instant(msg), 'Close', {duration: 2000});
      });
    });
  }

  bookAndNotify(){
    const travelers:any = [];
    const dossier = this.transferId || undefined;
    travelers.push(this.reservation.user._id);
    this.policyService.createVehicleSearch(this.reservation, travelers, dossier).pipe(catchError(err => {
      this.snackBar.open(err.message, 'Error', {
        duration: 2000,
      });
      return throwError(err);
    })).subscribe(res => {
      this.saveApprovalId(res.data._id);
      this.bookCar();
      if (this.booked){
        this.pushAuth(res, dossier);
        this.getReservation(this.pnrId);
      }
    });
  }

  saveApprovalId(value: string){
    const data = {
      approvalId: value,
    };
    this.carService.saveApprovaId(this.reservation._id, data).subscribe((res) => {
      console.log('res => ', res);
      this.getReservation(this.pnrId)
      // this.getPnr(this.reservation._id);
    }, error => {
      console.error('Error => ', error);
    });
  }

  bookCar() {
    this.booking = true;
    const body = {
      cc: this.reservation.creditcard,
    };
    // body.cc = this.reservation.creditcard;
    this.carService.bookReservation(this.reservation._id, body).subscribe((res) => {
      console.log('res book => ', res);
      this.booking = false;
      this.booked = true;
      /*this.getReservation(this.pnrId);*/
    }, error => {
      this.booked = false
      this.booking = false;
      console.error('Error => ', error);
    });
  }

  toggleDeleteModal(): void {
    $('#deletePnrModal').modal('toggle');
  }

  deleteReservation(): void {
    this.deleting = true;
    this.carService.deleteReservation(this.reservation._id).subscribe((res) => {
      console.log('res book => ', res);
      this.deleting = false;
      this.toggleDeleteModal();
      this.getReservation(this.pnrId);
    }, error => {
      this.deleting = false;
      this.toggleDeleteModal();
      console.error('Error => ', error);
    });
  }

  togglePrintCar(): void {
    $('#PrintModalCar').modal('toggle');
  }

  print(): void {
    let printContents;
    let popupWin;
    printContents = document.getElementById('toPrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=900px,width=1200px\'');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
          <title>Car-Ticket</title>
          <style>
            #toPrint{
                font-size:12px;
                padding:10px;
                border:1px solid #ccc;
                background-color:#fff;
            }
            .carImage{
              display: block;
              margin:auto;
              width:100%;
              max-width: 250px;
            }
            .veicleIcons img{
                max-width:15px;
                margin-right: 4px;
                display: inline-block;
                vertical-align:middle;
              }
              .veicleIcons .singleIcon{
                display: inline-block;
                margin:0 10px 5px 0;
              }
            .carLocation .material-icons{
                font-size:14px;
                margin-right:5px;
                vertical-align: middle;
              }
            .rotateArrow .material-icons{
                margin-left:-5px;
                transform: rotate(90deg);
              }
            .companyImage{
              max-height:40px;
              max-width:200px;
            }
            .reservationimage img{
                width:30px;
                display:inline-block;
                border-radius:50%;
                border:1px solid #ccc;
                margin:0 5px 0 0;
                vertical-align: middle;
            }
            .material-icons{
                vertical-align: middle;
            }
            .pnrdeparture{
              font-size:11px;
              color:#999;
            }
            table td{
                width:25%;
            }
            .operatingImage{
                width:30px;
                border-radius: 50%;
                border:1px solid #ccc;
            }
            .backPrint{
                padding:10px;
            }
            .maincabin{
              font-size: 10px;
              text-transform:uppercase;
            }
            .infoTar .material-icons, .infoBag .material-icons{
                font-size:18px;
                margin-right:5px;
                vertical-align: middle;;
            }
            .fakeTable{
              border:1px solid #ccc;
              padding:10px;
            }
            .fakeTable hr{
              margin:5px 0;
            }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

}
