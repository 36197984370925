<div class="padding20">
  <custom-title></custom-title>
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div *ngIf="!loading && reservation" class="cardPnr marginbottom10">
    <div class="padding20">
      <div class="row">
        <div class="col-md-4 align-self-center">
          <div class="reservationimage">
            <img alt="TRENITALIA_LOGO" [src]="getTrenitLogo(reservation)" style="margin:0 0 20px;height:50px;width:auto;border-radius:0;">
            <div style="text-transform:uppercase;">{{reservation.carrier}}</div>
          </div>
          <div class="reservationPrice marginbottom5">
            <div class="recoprice">
              {{'TRAIN_MODULE.TOTAL'|translate}}:
              <span *ngIf="!!reservation.cancellation" class="old-price">{{reservation.ratedPrice|number:'.2-2'}}€</span>
              <span *ngIf="!!reservation.cancellation else originalTravelPrice"> {{reservation.cancellation.penalty}}€</span>
              <ng-template #originalTravelPrice><span> {{reservation.ratedPrice|number:'.2-2'}}€</span></ng-template>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center mb-2">
          <span [class]="reservation.status" class="pnrNewStatus">
            <span *ngIf="reservation.status=='RESERVED'" class="smalIcon material-icons">done</span>
            <span *ngIf="reservation.status=='COMPLETED'" class="smalIcon material-icons">task_alt</span>
            <span *ngIf="reservation.status=='EXPIRED'" class="smalIcon material-icons">close</span>
            <span *ngIf="reservation.status=='REFUNDED'" class="smalIcon material-icons">close</span>
            <span *ngIf="reservation.status=='CANCELLED'" class="smalIcon material-icons">close</span>
            {{reservation.status|translate}}

            <div *ngIf="reservation.cancellation" class="variationpnr">
              <mat-icon>warning</mat-icon>
            </div>
          </span>

          <div class="smalltext">{{'ISSUED_ON'|translate}}: {{reservation.issuedDate|date:'dd/MM/yyyy'}}</div>
          <div class="smalltext">{{'UPDATED_AT'|translate}}: {{reservation.updatedAt|date:'dd/MM/yyyy'}}</div>

        </div>
        <div class="col-md-4 align-self-center companydetails">
          <div><a routerLink="/companies/list/{{reservation.company?._id}}">{{reservation.company?.name}}</a></div>
          <div>
            <mat-icon>subdirectory_arrow_right</mat-icon>
            <a routerLink="/users/list/{{reservation.user?._id}}">{{reservation.user?.surname}} {{reservation.user?.name}}</a>
          </div>
          <div *ngIf="reservation.status=='COMPLETED' && reservation.costCenter" class="mt-1">
            {{'COST_CENTER'|translate}}: {{reservation.costCenter}}
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-md-4 align-self-center mb-2">

        </div>
        <div class="col-md-4 align-self-center mb-2">
          <div *ngIf="reservation.travellers.length>0">
            <div *ngFor="let passenger of reservation.travellers" class="listpnrsinglepasseng">
              <span *ngIf="passenger.doc?.surname">{{passenger.doc?.surname}}</span> <span
              *ngIf="passenger.doc?.name"> {{passenger.doc?.name}}</span> <sup> ({{passenger.type}})</sup>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center mb-2 pnrActionsButtons">
          <button *ngIf="reservation.paper" [matMenuTriggerFor]="dossierMenu" mat-icon-button matTooltip="{{'DOSSIER'|translate}}">
            <mat-icon>folder</mat-icon>
          </button>
          <mat-menu #dossierMenu="matMenu">
            <button mat-menu-item routerLink="/dossier/list/{{reservation.paper}}">
              {{'GO_TO_DOSSIER'|translate}}
            </button>
            <button (click)="toggleRemoveDossierModal()" mat-menu-item>
              {{'REMOVE_FROM_DOSSIER'|translate}}
            </button>
          </mat-menu>

          <button *ngIf="!deleteErrorMessage && ['RESERVED','PREPARED'].includes(this.reservation.status)" (click)="toggleDeleteModal()"
                  color="warn" mat-icon-button matTooltip="{{'TRAIN_MODULE.DELETE_RESERVATION'|translate}}" class="miniIcons">
            <mat-icon>delete</mat-icon>
          </button>

          <!--<button (click)="toggleAddtoTravel()" *ngIf="!reservation.paper && reservation.status!=='REFUNDED' && reservation.status!=='CANCELLED'"
                  mat-icon-button
                  matTooltip="{{'ADD_TO_FOLDER'|translate}}">
            <mat-icon>create_new_folder</mat-icon>
          </button>

            <button *ngIf="isItalo()" color="info" mat-icon-button class="btn-disabled"
                    matTooltip="{{'TRAIN_MODULE.EMAILED_TICKET'|translate}}">
                <mat-icon>file_download</mat-icon>
            </button>
          <button *ngIf="!isItalo()" (click)="downloadPdf()" color="info" mat-icon-button
                  matTooltip="{{'TRAIN_MODULE.DOWNLOAD_TICKET'|translate}}" [disabled]="downloadingPdf">
            <mat-icon [class]="(downloadingPdf ? 'spin':'')">
              {{ !downloadingPdf ? 'file_download' : 'autorenew'}}
            </mat-icon>
          </button>-->
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && reservation" class="newPnrPagination">
    <!--<div (click)="setTab(i)" *ngFor="let tab of tabs; let i = index" [ngClass]="{'active':tab.active, 'hidden':!tab.enabled}"
         class="tabButton">
      <mat-icon *ngIf="tab.name==='TRAINS'">train</mat-icon>
      <mat-icon *ngIf="tab.name==='PASSENGERS'">directions_walk</mat-icon>
      <mat-icon *ngIf="tab.name==='AFTERSALE'">card_travel</mat-icon>
      <mat-icon *ngIf="tab.name==='AUDIT'">list</mat-icon>
      <mat-icon *ngIf="tab.name==='SHOPPING_CART'">shopping_cart</mat-icon>
      <span class="tabname"> {{tab.name|translate}}</span>
      class="singleNewVoice"
    </div>-->
    <div (click)="setTab(i)" *ngFor="let tab of tabs; let i = index" [ngClass]="{'activeNew':tab.active, 'hidden':!tab.enabled}" class="tabNewButton">
      {{tab.name|translate}}
    </div>
  </div>

  <div class="newTabHeader" *ngIf="!loading && reservation">
    <div class="row">
      <div class="col-6 align-self-center">
        {{currentTab | translate}}
      </div>
      <div class="col-6 align-self-center d-flex justify-content-end">
        <button (click)="toggleAddtoTravel()" *ngIf="!reservation.paper && reservation.status!=='REFUNDED' && reservation.status!=='CANCELLED'"
                mat-icon-button matTooltip="{{'ADD_TO_FOLDER'|translate}}" class="miniIcons">
          <mat-icon>create_new_folder</mat-icon>
        </button>

        <button *ngIf="isItalo()" mat-icon-button class="btn-disabled miniIcons" matTooltip="{{'TRAIN_MODULE.EMAILED_TICKET'|translate}}">
          <mat-icon>file_download</mat-icon>
        </button>
        <button *ngIf="!isItalo()" (click)="downloadPdf()" mat-icon-button matTooltip="{{'TRAIN_MODULE.DOWNLOAD_TICKET'|translate}}"
                [disabled]="downloadingPdf" class="miniIcons">
          <mat-icon [class]="(downloadingPdf ? 'spin':'')">
            {{ !downloadingPdf ? 'file_download' : 'autorenew'}}
          </mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && reservation" class="card marginbottom20 padding10">
    <div *ngIf="currentTab=='PASSENGERS'">
      <!--{{'TRAIN_MODULE.PASSENGERS'|translate}}
      <hr/>-->
      <div>
        <div class="row buttonrow">
          <div *ngFor="let tra of reservation.travellers;let i = index" class="col-lg-6 mb-3">
            <p>{{tra.type|translate}} {{i + 1}}</p>
            <div class="fakefield mb-2">
              {{'NAME'|translate}}:
              <span *ngIf="tra.doc?.name"> {{tra.doc?.name}}</span>
              <span *ngIf="!tra.doc?.name"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'SURNAME'|translate}}:
              <span *ngIf="tra.doc?.surname"> {{tra.doc?.surname}}</span>
              <span *ngIf="!tra.doc?.surname"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'PHONE'|translate}}:
              <span *ngIf="tra.doc?.phoneNumber"> {{tra.doc?.phoneNumber}}</span>
              <span *ngIf="!tra.doc?.phoneNumber"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'EMAIL'|translate}}:
              <span *ngIf="tra.doc?.email"> {{tra.doc?.email}}</span>
              <span *ngIf="!tra.doc?.email"> _ _ _ _</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='TRAINS'">
      <!--{{'TRAINS'|translate}}
      <hr/>-->
      <!--
      <div class="row newItemPnr" *ngFor="let itinerary of reservation.itineraries;let l = last" [ngClass]="{'mb-2':!l}">
        <div class="col-5">
          {{itinerary.from.date|date:'dd/MM/yyyy - HH:mm': '+0000'}}
          <br/>
          {{itinerary.from.station}}
        </div>
        <div class="col-2 align-self-center">
          <span class="material-icons">arrow_right_alt</span>
        </div>
        <div class="col-5">
          {{itinerary.to.date|date:'dd/MM/yyyy - HH:mm':"+0000"}}
          <br/>
          {{itinerary.to.station}}
        </div>
      </div> -->

      <div class="travelDetails">
        <div *ngFor="let itinerary of reservation.itineraries; let i = index;let la = last;" class="mb-3">
          <div [ngClass]="{'nextExist':i<reservation.itineraries.length && i!=reservation.itineraries.length-1}">
            <div class="introItinerary">
              <div class="row my-2 alert alert-warning isVenice d-block" *ngIf="isVenice(itinerary.to)">
                <span>{{'IS_VENICE_ALERT' | translate}}</span>
                <span><a href="{{'IS_VENICE_URL' | translate}}" target="new">{{'HERE' | translate}}</a>.</span>
              </div>
              {{itinerary.from.station}}
              <mat-icon>arrow_right_alt</mat-icon>
              {{itinerary.to.station}}
              <div class="flightCabin marginbottom10">
                <span>{{'CABIN'|translate}}: {{itinerary.bookingClass}} {{'FARE'|translate}}
                  : {{itinerary.fareType}}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-12 collapse show" id="itinerary{{i}}">
                <div class="row">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center align-self-center">
                    <span class="material-icons">train</span>
                  </div>
                  <div class="col-3 align-self-center">
                    {{itinerary.from.station}}
                    <br/>
                    {{itinerary.from.date|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <div>{{returnTime(itinerary.flyingTime)}}</div> -->
                    <div *ngIf="itinerary.trainNumber">
                      {{itinerary.trainAcronym}} {{itinerary.trainClassification}} [{{itinerary.trainNumber}}]
                    </div>
                    <div *ngIf="itinerary.from.date">
                      {{calculateTime(itinerary.from.date, itinerary.to.date)}}
                    </div>
                    <div *ngIf="!itinerary.from.date">
                      <mat-icon>arrow_right_alt</mat-icon>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    {{itinerary.to.station}}
                    <br/>
                    {{itinerary.to.date|date:'dd/MM/yyyy - HH:mm'}}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="currentTab=='AFTERSALE' && !!this.reservation">
      <!--{{'TRAIN_MODULE.AFTERSALE.TAB_TITLE'|translate}}
      <hr/>-->
      <ng-container *ngIf="!(loadingAllowedOperations$ | async) else trenitaliaLoading">
        <ng-container *ngIf="reservation.carrier==='TRENITALIA'">
          <train-travel-solution-detail [catalog]="allowedOperations.catalog" (selectedTravelSolutionEvent)="setTravelSolutionTab($event)"
                                        [travel]="allowedOperations.travel" [aftersale]="allowedOperations.postSaleDetails"></train-travel-solution-detail>
          <div class="buttonrow">
            <train-change-traveller (reloadEvent)="reload()" [allowedOperations]="allowedOperations" [isDisabled]="isAftersaleDisabled('TRAVELLER_CHANGE')"
                                    [pnr]="pnr" [selectedTravelSolution]="selectedTravelSolution"></train-change-traveller>

            <train-change-service (reloadEvent)="reload()" [catalog]="allowedOperations.catalog"
                                  [isDisabled]="isAftersaleDisabled('TRAVEL_CHANGE') || isEconomy(reservation)" [pnr]="pnr"
                                  [selectedTravelSolution]="selectedTravelSolution"></train-change-service>

            <train-change-date *ngIf="!isEconomy(reservation)" (reloadEvent)="reload()" [isDisabled]="isAftersaleDisabled('BOOKING_CHANGE')" [pnr]="pnr"
                               [selectedTravelSolution]="selectedTravelSolution"></train-change-date>
            <train-change-economy *ngIf="isEconomy(reservation)" (reloadEvent)="reload()" [isDisabled]="isAftersaleDisabled('TRAVEL_CHANGE')" [pnr]="pnr"
                                  [allowedOperations]="allowedOperations" [travellers]="reservation.travellers" [selectedTravelSolution]="selectedTravelSolution"></train-change-economy>

            <train-change-new-search (reloadEvent)="reload()" [isDisabled]="isAftersaleDisabled('TRAVEL_CHANGE') || isEconomy(reservation)"
                                     [pnr]="pnr" [allowedOperations]="allowedOperations" [travellers]="reservation.travellers"
                                     [selectedTravelSolution]="selectedTravelSolution"></train-change-new-search>

            <train-refund (reloadEvent)="reload()" [allowedOperations]="allowedOperations" [catalog]="allowedOperations.catalog"
                          [isDisabled]="isAftersaleDisabled('REFUND')"
                          [pnr]="pnr" [selectedTravelSolution]="selectedTravelSolution"></train-refund>

            <train-cancel (reloadEvent)="reload()" [isDisabled]="reservation.status !== 'COMPLETED' || !isFreeCancellationBis()" [pnr]="pnr"></train-cancel>
          </div>
        </ng-container>
        <ng-container *ngIf="reservation.carrier==='ITALO'">
          <div class="col-lg-6 mb-3">
            <div class="mb-2">
              {{'PNR'|translate}}: <span *ngIf="pnr else emptyInformation"> {{pnr}}</span>
            </div>
            <div class="mb-2">
              {{'STATUS'|translate}}:
              <span *ngIf="allowedOperations.travel.booking.Booking.BookingStatus else emptyInformation">
                {{allowedOperations.travel.booking.Booking.BookingStatus | italo}}
              </span>
            </div>
            <div class="mb-2" *ngFor="let p of allowedOperations.travel.booking.Booking.Payments">
              {{'PAYMENT_STATUS'|translate}}: <span *ngIf="p.BookingPaymentStatus else emptyInformation">
              {{p.BookingPaymentStatus | italo:'payment'}}</span>
            </div>
          </div>
          <train-checkout-booking-summary [bookingDb]="reservation" [title]="''"></train-checkout-booking-summary>
        </ng-container>
        <ng-template #emptyInformation>
          <span> _ _ _ _</span>
        </ng-template>
      </ng-container>
      <ng-template #trenitaliaLoading>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>
    </div>

    <div *ngIf="currentTab=='AUDIT' && !!this.reservation">
      <!--{{'TRAIN_MODULE.AUDIT.TAB_TITLE'|translate}}
      <hr/>-->
      <div *ngIf="!(loadingAuditTransactions$ | async) else trenitaliaLoading" class="row">
        <table class="table table-bordered index-table w-100">
          <tbody></tbody>
          <tr>
            <td>{{'DATE' | translate}}</td>
            <td>{{'TRAIN_MODULE.AUDIT.ACTION' | translate}}</td>
            <td>{{'TRAIN_MODULE.AUDIT.PAID_AMOUNT' | translate}}</td>
            <td>{{'TRAIN_MODULE.AUDIT.REFUNDED_AMOUNT' | translate}}</td>
            <td>{{'TRAIN_MODULE.AUDIT.AMOUNT' | translate}}</td>
          </tr>
          <tr *ngFor="let tx of auditTransactions">
            <td>{{ tx.createdAt | date:'dd/MM/yyyy - HH:mm'}}</td>
            <td>{{ tx.action | translate }}</td>
            <td>{{ tx.amountPaid || 0 | number: '.2-2'}}€</td>
            <td>{{ tx.amountRefunded || 0 | number: '.2-2'}}€</td>
            <td>{{ (tx.amountPaid || 0) - (tx.amountRefunded || 0) | number: '.2-2'}}€</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ 'TOTAL' | translate}}</td>
            <td> {{getTotalTransactions() | number:'.2-2'}}€</td>
          </tr>
        </table>
      </div>
      <ng-template #trenitaliaLoading>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>
    </div>

    <div *ngIf="currentTab=='SHOPPING_CART'">
      <!--{{'TRAIN_MODULE.SHOPPING_CART'|translate}}-->
      <ng-container *ngIf="reservation.carrier === 'TRENITALIA'; else isItaloCheckout">
        <ng-container *ngIf="!(loadingShoppingCart$ | async) else trenitaliaLoading">
          <train-checkout [isModal]="true" (nextStepEvent)="getReservation(pnr)"></train-checkout>
        </ng-container>
        <ng-template #trenitaliaLoading>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-template>
      </ng-container>
      <ng-template #isItaloCheckout>
        <app-checkout [approvalId]="reservation.approvalId"></app-checkout>
      </ng-template>
    </div>
  </div>

  <div *ngIf="reservation && reservation.paper" aria-hidden="true" aria-labelledby="RemoveDossierModal" class="modal fade" data-backdrop="static"
       id="RemoveDossierModal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" style="top:70px">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="RemoveDossierModalTitle">{{'TRAIN_MODULE.REMOVE_FROM_DOSSIER'|translate}} ?</h5>
          <button (click)="toggleRemoveDossierModal()" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">{{'TRAIN_MODULE.CONFIRM_REMOVE_DOSSIER'|translate}}</div>
        </div>

        <hr/>

        <div class="text-right">
          <button (click)="toggleRemoveDossierModal()" mat-button
                  type="button">{{'TRAIN_MODULE.ABORT'|translate}}</button>
          <button (click)="confirmDossierRemove()" mat-button
                  type="button">{{'TRAIN_MODULE.CONFIRM'|translate}}</button>
        </div>

      </div>
    </div>
  </div>

  <div aria-hidden="true" aria-labelledby="travelListModal" class="modal fade" data-backdrop="static" id="travelListModal"
       role="dialog" tabindex="-1">
    <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addGuestModalTitle">
            {{'ADD_TO_FOLDER'|translate}}
          </h5>
          <button (click)="toggleAddtoTravel()" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="reservation">
          <app-dossierlist (close)="closeAddtoTravel()" [idTrain]="reservation._id" page="train"></app-dossierlist>
        </div>

        <hr/>

        <div class="text-right buttonrow">
          <button (click)="toggleAddtoTravel()" mat-button type="button">{{'CLOSE'|translate}}</button>
        </div>

      </div>
    </div>
  </div>

</div>


<div aria-hidden="true" aria-labelledby="deletePnrModal" class="modal fade" data-backdrop="static" id="deletePnrModal"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-sm" role="document" style="top:70px">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deletePnrModalTitle">{{'TRAIN_MODULE.DELETE_RESERVATION_QUESTION'|translate}}
          ?</h5>
        <button (click)="toggleDeleteModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="!deleteErrorMessage else successDialog">
          <ng-container *ngIf="(deleteReservation$ | async)">
            <div class="mb-2">{{'TRAIN_MODULE.LOADING'|translate}}</div>
            <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
          </ng-container>
        </ng-container>
        <ng-template #successDialog>
          <mat-dialog-content>
            <ng-container *ngIf="deleteErrorMessage else successMessage">
              <mat-icon>warning</mat-icon>
              {{ deleteErrorMessage | translate}}
            </ng-container>
            <ng-template #successMessage>
              {{'TRAIN_MODULE.RESERVATION_DELETED' | translate}}
            </ng-template>
          </mat-dialog-content>
        </ng-template>

        <div *ngIf="!deleteErrorMessage" class="text-right">
          <button (click)="toggleDeleteModal()" mat-button type="button">{{'TRAIN_MODULE.ABORT'|translate}}</button>
          <button (click)="deleteReservation()" mat-button type="button">{{'TRAIN_MODULE.DELETE_RESERVATION'|translate}}</button>
        </div>
      </div>

    </div>
  </div>
</div>
