<div class="dialog padding10">
  <h1 mat-dialog-title class="px-2 d-flex align-items-center">
    {{'POLICY.CONFIG'|translate}}
  </h1>
  <div class="dialog__form" [formGroup]="orderForm">
    <mat-dialog-content class="px-3 py-0">
      <div class="">
        <mat-radio-group class="" (change)="onSelectedRadio()" formControlName="radioButton" aria-label="Select an option">
          <mat-radio-button color="primary" class="dialog__radio" value="default">
            <span style="font-size: 14px!important;"> {{'POLICY.DEFAULT_USER'|translate}} </span>
          </mat-radio-button>
          <mat-radio-button color="primary" class="dialog__radio" value="standard">
             <span style="font-size: 14px!important;"> {{'POLICY.USER_SELECTED'|translate}} </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label style="font-size: 12px!important;">{{'POLICY.SELECT_GROUPS'|translate}}</mat-label>
            <mat-select (selectionChange)="onSelectedRadio()" [disabled]="isDisabled" formControlName="authorization_roles" multiple>
              <mat-option *ngFor="let role of rolesList" [value]="role._id">{{role.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label style="font-size: 12px!important;">{{'POLICY.SELECT_USERS'|translate}}</mat-label>
            <mat-select (selectionChange)="onSelectedRadio()" [disabled]="isDisabled" formControlName="authorization_users" multiple>
              <mat-option *ngFor="let user of userList" [value]="user._id">{{user.surname}} {{user.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <mat-error style="font-size: 14px!important;" *ngIf="orderForm.errors?.empty && radio.value!=='default'">{{'POLICY.SELECT_GROUP_OR_USER'|translate}}</mat-error>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="buttonrowBis">
        <button mat-raised-button (click)="onNoClick()">{{'CLOSE'|translate}}</button>
        <button (click)="onSubmit()" mat-raised-button color="primary" [disabled]="isValid" autofocus>{{'SAVE'|translate}}</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
