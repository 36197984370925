<div class="padding20">
  <div class="form-header-container">
    <div *ngIf="!updating">{{'POLICY.NEW_GROUP'|translate}}</div>
    <div *ngIf="updating">{{'POLICY.UPDATE_GROUP_NAME'|translate}}</div>
  </div>
  <div class="pt-4">
    <div>
      <app-role-form (updated)="handleUpdate($event)" (close)="dialogRef.close()" (submitted)="handleSubmit($event)" [groupsNames]="data.groupNames"
                     [groupName]="data.group.name" [updating]="updating">
      </app-role-form>
    </div>
  </div>
</div>


