import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AllGroupsComponent} from './containers/all-groups/all-groups.component';
import {RouterModule} from '@angular/router';
import {GroupsTableComponent} from './components/groups-table/groups-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {AddGroupComponent} from './containers/add-group/add-group.component';
import {GroupFormComponent} from './components/group-form/group-form.component';
import {EditGroupComponent} from './containers/edit-group/edit-group.component';
import {ConfirmationDialogModule} from '../shared/confirmation-dialog/confirmation-dialog.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from "@angular/material/card";
import {CustomCommonModule} from "../../modules/common/custom-common.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {SharedModule} from "../../modules/shared/shared.module";

@NgModule({
  declarations: [AllGroupsComponent, GroupsTableComponent, AddGroupComponent, GroupFormComponent, EditGroupComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    ConfirmationDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    CustomCommonModule,
    TranslateModule,
    MatSortModule,
    MatPaginatorModule,
    SharedModule,
  ]
})
export class GroupsModule {
}
