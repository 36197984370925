import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { SearchRes } from '../../../../../classes/flights/search-res';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../../../../app.component';
import {FlightRequest} from '../../../classes/flightrequest';
import {IatacompleteService} from '../../../../../services/iatacomplete/iatacomplete.service';
import {SearchformComponent} from './searchform/searchform.component';
import {ActivatedRoute} from '@angular/router';
import {FlightService} from '../../../../../services/flight/flight.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
/**
 * Component container for flight search form and results
 */
@Component({
  selector: 'app-searchflight',
  templateUrl: './searchflight.component.html',
  styleUrls: ['./searchflight.component.scss']
})
export class SearchflightComponent implements OnInit, OnDestroy {
  @ViewChild(SearchformComponent)
  currentTabLabel: string;
  showForm: boolean;
  /**
   *  Local variable notifier service
   */
  private readonly notifier: NotifierService;
  /**
   *  Local variable for flight form request
   */
  request: FlightRequest;
  /**
   *  Local variable for single section of itineraries request
   */
  singleSection: any;
  /**
   *  Local variable for flight search results
   */
  searchResults: SearchRes;

  /**
   *  Local variable for flight search results
   */
  searchResultsTo: SearchRes;
  /**
   *  Local variable for flight search results
   */
  searchResultsLow: SearchRes;
  /**
   *  Local variable for flight search results
   */
  searchResultsLowNDC: SearchRes;

  searchResultsNdcAma: SearchRes;
  /**
   *  Local variable for today date
   */
  today: Date;
  /**
   *  Local variable to store flight selections
   */
  selections: {};
  /**
   *  Local variable to store flight selections
   */
  selectionsTo: {};
  /**
   *  Local variable to store lowcost flight selections
   */
  selectionsLow: {};

  selectionsNdcAma: {};
  /**
   *  Local variable to store lowcost flight selections
   */
  selectionsLowNDC: {};
  /**
   *  Local variable for tariff loading status
   */
  loadingTar: boolean;
  /**
   *  Local variable for search type (step or not)
   */
  searchType: string;
  /**
   *  Local variable to know if search results had to be step or not
   */
  b2bClient: boolean;
  /**
   *  Local list for get airports name
   */
  airportsToComplete: string[] = [];
  cacheId: string;
  selectedIndex = 0;
  currentTab: any;
  currentTabName = 'linea';
  /**
   * The "constructor"
   *
   * @params {notifierService} notifierService
   * @params {translate} TranslateService
   * @params {appComponent} AppComponent
   */
  constructor(
    notifierService: NotifierService,
    /**
     *  Local variable for TranslateService
     */
    public translate: TranslateService,
    /**
     *  Local variable for AppComponent
     */
    public appComponent: AppComponent,
    /**
     *  service for airports iata code full name
     */
    public iataComplete: IatacompleteService,
    private searchForm: SearchformComponent,
    private route: ActivatedRoute,
    private flightService: FlightService,
    private snackBar: MatSnackBar,
  ) {
    this.notifier = notifierService;
    this.searchResults = new SearchRes();
    this.request = new FlightRequest();
    this.today = new Date();
    this.showForm = false;
    this.createForm();

    /*
    Single section of search form
     */
    this.singleSection = {
      from: [],
      to: [],
      fromLegend: [],
      toLegend: [],
      options: {
        date: '',// new Date(),
        minDate: this.today,
        departureTime: '',
        cabin: '',
        viaAirport: [],
        expandAdvance: false,
        flightType: '',
        timeSlot: '',
      }
    };
    /*
    this.b2bClient = this.appComponent.isSbt;
    if ( this.b2bClient ) {
      this.searchType = 'step';
    } else {
      this.searchType = 'standard';
    }
    */

  }

  changeTabName(name){
    this.currentTabName = name;
    console.log('this.currentTabName => ', this.currentTabName);
  }

  startNewSearch(event) {
    this.showForm = false;
    delete this.searchResults;
    console.log('event => ', event);
    this.request = event.event;
    this.request.options.calendar = 'no_calendar';
    setTimeout(() => {
      this.showForm = true;
    }, 300);
    // this.searchForm.toggleForm();
    // this.searchForm.startSearch(undefined, this.request);
  }

  /*
  Function that create request obj for search form
   */

  createForm() {
    this.request = {
      searchType: 'roundtrip',
      sections: [],
      passengers: {
        adt: 1,
        chd: 0,
        inf: 0,
      },
      options: {
        carrierIdentification: [],
        corporate: '',
        cabin: '',
        carrierQualifier: '',
        flightType: '',
        resident: false,
        refundable: false,
        family: false,
        etnic: false,
        calendar: false,
        alliance: false,
        tourOperator: false,
        priceToBeat: null,
        maxTimeConnections: null,
      },
    };
    this.showForm = true;
  }

  /**
   *  Local function watching for search result change
   */
  resultChange(event) {
    console.log('change results event');
    this.searchResults = event;
    if (this.searchResults && this.searchResults.itineraries) {
      // this.notifier.notify('success', this.translate.instant('SEARCH_GOOD'));
      this.createToComplete();
    }
  }

  resultChangeTo(event) {
    console.log('change resultsTo event');
    this.searchResultsTo = event;
    if (this.searchResultsTo && this.searchResultsTo.itineraries) {
      // this.notifier.notify('success', this.translate.instant('SEARCH_GOOD'));
      this.createToComplete();
    }
  }

  /*
  function watching for search results changes
   */
  resultChangeLow(event) {
    console.log('CAlled Result ChangeLow')
    this.searchResultsLow = event;
    if (this.searchResultsLow && this.searchResultsLow.itineraries) {
      // this.notifier.notify('success', this.translate.instant('SEARCH_GOOD'));
      this.createToComplete();
    }
  }

  resultChangeLowNDC(event) {
    this.searchResultsLowNDC = event;
    if (this.searchResultsLowNDC && this.searchResultsLowNDC.itineraries) {
      // this.notifier.notify('success', this.translate.instant('SEARCH_GOOD'));
      this.createToComplete();
    }
  }

  resultChangeNdcAma(event) {
    this.searchResultsNdcAma = event;
    if (this.searchResultsNdcAma && this.searchResultsNdcAma.itineraries) {
      // this.notifier.notify('success', this.translate.instant('SEARCH_GOOD'));
      this.createToComplete();
    }
  }

  /*
  pass to iata service search results
  iata service store carriers name and airports name
   */
  createToComplete(): void {
    if (this.searchResults && this.searchResults.itineraries) {
      console.log('test compile');
      this.iataComplete.createToComplete(this.searchResults);
    }
    if (this.searchResultsLow && this.searchResultsLow.itineraries) {
      this.iataComplete.createToComplete(this.searchResultsLow);
    }
    if(this.searchResultsLowNDC){
      if(this.searchResultsLowNDC.itineraries){
        if(this.searchResultsLowNDC.itineraries.length > 0){
          this.iataComplete.createToComplete(this.searchResultsLowNDC);
        }
      }
    }
    if(this.searchResultsNdcAma && this.searchResultsNdcAma.itineraries){
      if(this.searchResultsNdcAma.itineraries.length > 0){
        this.iataComplete.createToComplete(this.searchResultsNdcAma);
      }
    }
  }

  /**
   *  Local function watching for search result selection change
   */
  resultSelChange(event) {
    this.selections = event;
    if (this.selections) {
      // this.notifier.notify('success', this.translate.instant('SELECTIONS_GOOD'));
    }
  }

  resultSelToChange(event) {
    this.selectionsTo = event;
    if (this.selectionsTo) {
      // this.notifier.notify('success', this.translate.instant('SELECTIONS_GOOD'));
    }
  }

  /*
  function watching for low cost search results changes
   */
  resultSelChangeLow(event) {
    this.selectionsLow = event;
  }

  resultSelChangeNdcAma(event) {
    this.selectionsNdcAma = event;
  }

  resultSelChangeLowNDC(event) {
    this.selectionsLowNDC = event;
  }

  /**
   *  Local function watching for search form change
   */
  requestChange(event) {
    this.request = event;
  }

  /**
   *  If local storage contains search form and search results load storage objects
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['cacheId']) {
        this.cacheId = params['cacheId'];
        this.getSingleHistory();
      }
    });
    if ( localStorage.getItem('searchRES') ) {
      this.searchResults = JSON.parse(localStorage.getItem('searchRES'));
      console.log('this.searchResults => ', this.searchResults);
      this.selections = {};
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.searchResults.recommendations.length; i++) {
        // tslint:disable-next-line:max-line-length
        this.selections[this.searchResults.recommendations[i].ref] = this.searchResults.recommendations[i].itineraries.map(r => r.sections[0].ref);
      }
      console.log('this.selections => ', this.selections);
      this.createToComplete();
    }
    if ( localStorage.getItem('searchForm') ) {
      this.request = JSON.parse(localStorage.getItem('searchForm'));
      this.request.sections.forEach( sect => {
        sect.from.forEach( iata => {
          this.iataComplete.createToCompleteForm(iata);
        });
        sect.to.forEach( iata => {
          this.iataComplete.createToCompleteForm(iata);
        });
      });
    }
  }

  /**
   * on destroy remove search objects from local storage
   */
  ngOnDestroy(): void {
    localStorage.removeItem('searchRES');
    localStorage.removeItem('searchForm');
  }

  getSingleHistory() {
    console.log('CACHE_ID', this.cacheId);
    this.flightService.getSingleHistory(this.cacheId).subscribe((res) => {
      console.log('res => ', res);
      delete res.company;
      delete res.createdAt;
      delete res.updatedAt;
      delete res.user;
      this.request = res;
      if (this.request.sections.length === 1) {
        this.request.searchType = 'oneway';
      } else if (this.request.sections.length === 2) {
        this.request.searchType = 'roundtrip';
      } else {
        this.request.searchType = 'multy';
      }
    }, error => {
      this.snackBar.open(this.translate.instant(error.errorMessage), 'X', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      });
    });
  }

  roundNum(value): number{
    return Math.round(value);
  }

}
