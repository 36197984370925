import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-alerts-icon',
  templateUrl: './policy-alerts-icon.component.html',
  styleUrls: ['./policy-alerts-icon.component.scss']
})
export class PolicyAlertsIconComponent implements OnInit {
  @Input() arrayOfAlerts: any[] = [];
  @Input() isB2B?: boolean;
  @Input() isSbt?: boolean;
  @Input() isNdcOrLow?: boolean;
  @Input() isHotel?:boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.filterAlerts()
  }

  filterAlerts(){
    if(this.isNdcOrLow){
      this.arrayOfAlerts = this.arrayOfAlerts.filter((a:any) => a.policy.conditions.type != 'FLIGHT_CABIN');
    }
  }
}
