<custom-title></custom-title>
<div class="padding20">

  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div class="mb-4 buttonrow" *ngIf="!loading && generalStats">
    <button [color]="tab === currentTab ? 'primary' : undefined" *ngFor="let tab of availableTabs" (click)="setTab(tab)" mat-raised-button>
      {{availableSections[tab]|translate}}
    </button>
    <button mat-raised-button (click)="downloadPDF()">{{'PRINT'|translate}}</button>
  </div>

  <div class="row" *ngIf="!loading && currentTab === 'FLIGHT' && generalStats">
    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3 " id="printpdf">
        <mat-card-header>
          <div mat-card-avatar class="all-year-image"></div>
          <mat-card-title>Situazione aggiornata <span *ngIf="company">per {{company.name}}</span></mat-card-title>
          <mat-card-subtitle>
            dal {{fromGeneral|date:'dd/MM/yyyy'}} al {{toGeneral|date:'dd/MM/yyyy'}}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="!generalStats.flight && !generalStats.hotel && !generalStats.car && !generalStats.train">
            {{'NO_DATA_AVAILABLE'|translate}}
          </div>
          <div class="table-responsive" *ngIf="generalStats.flight">
            <table class="table table-bordered table-hover table-sm">
              <tr>
                <th>VOLO</th>
                <th>Da emettere</th>
                <th>Emesse</th>
              </tr>
              <tr>
                <td>N° preontazioni</td>
                <td>{{generalStats.flight.linked}}</td>
                <td>{{generalStats.flight.pnr}}</td>

              </tr>
              <tr>
                <td>N° segmenti</td>
                <td><!-- {{generalStats.flight.linked.segments}} --> nd</td>
                <td>{{generalStats.flight.segments}}</td>
              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Tariffe negoziate</td>
                <td><!-- {{generalStats.flight.linked.negotiated}} --> nd</td>
                <td>{{generalStats.flight.negotiated}}</td>
              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Margini negoziate</td>
                <td><!-- {{generalStats.flight.linked.margin |number:'.2-2'}} € --> nd</td>
                <td>{{generalStats.flight.margins |number:'.2-2'}} €</td>

              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Incentivi</td>
                <td><!-- {{generalStats.flight.linked.incentives |number:'.2-2'}} € --> nd</td>
                <td>{{generalStats.flight.fees |number:'.2-2'}} €</td>
              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Totale economico</td>
                <td><!-- {{generalStats.flight.linked.amount |number:'.2-2'}} € --> nd</td>
                <td>{{generalStats.flight.amount |number:'.2-2'}} €</td>
              </tr>

              <tr *ngIf="pnrsStats && !company">
                <td colspan="3" *ngIf="pnrsStats.flight">
                  Totale pnr generati: {{pnrsStats.flight.pnr}}
                </td>
              </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3">
        <mat-card-header>
          <div mat-card-avatar class="filter-image"></div>
          <mat-card-title>Filtra report</mat-card-title>
          <mat-card-subtitle>
            Filtra per data
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Dal</mat-label>
                <input matInput [max]="toGeneral" [matDatepicker]="picker" [(ngModel)]="fromGeneral">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Al</mat-label>
                <input matInput [min]="fromGeneral" [max]="today" [matDatepicker]="pickerto" [(ngModel)]="toGeneral">
                <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
                <mat-datepicker #pickerto></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="mainComponent.loggedUser.role.superAdmin">
            <mat-form-field class="full-width">
              <mat-label>{{'SEARCH_COMPANY'|translate}}</mat-label>
              <input [ngModelOptions]="{standalone: true}" matInput placeholder="{{'SEARCH_COMPANY'|translate}}" aria-label="Departure"
                     (ngModelChange)="searchCompany($event)" [(ngModel)]="companytext" [matAutocomplete]="cpcomplete">
              <button type="button" *ngIf="companytext" class="iconButtons" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete #cpcomplete="matAutocomplete" (closed)="selectFirst()">
                <mat-option *ngFor="let comp of companiesList" [value]="comp.name" (click)="selectComp(comp)">
                  <span>{{comp.name}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="company && company.name">
              <span class="companySubSpan">
                {{company.name}}
                <button mat-icon-button (click)="removeCompany(company._id)">
                  <mat-icon>close</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="text-right buttonrow">
            <button mat-button (click)="getGeneralStats(true);getPnrsStats(true)" *ngIf="generalStats && pnrsStats">Filtra</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!--
  <div class="pt-2" *ngIf="!loading && currentTab === 'flight' && generalStats && loadedPie">

    <div class="perc">Tariffe negoziate: {{negoPerc}}%</div>
    <div class="perc">Tariffe pubbliche: {{otherPerc}}%</div>
    <hr/>
    <div class="">Pnr generati: {{pnrsStats.flight.pnr}}</div>
    <div class="">Pnr acquistati: {{generalStats.flight.pnr}}</div>
    <div class="">Tasso di conversione: {{flightRoi}}%</div>
  </div>
  -->
  <!--
  <div class="row" *ngIf="!loading && currentTab === 'FLIGHT' && generalStats.flight">

    <div class="col-lg-6">
      <mat-card class="example-card mb-3">
        <mat-card-header>
          <div mat-card-avatar class="map-image"></div>
          <mat-card-title>Report PNR</mat-card-title>
          <mat-card-subtitle>
            Percentuali prenotazioni
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="row mb-3">
            <div class="col-12 "><h5>Prenotazioni</h5></div>
            <div class="col-3 align-self-center">da emettere</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar linked" [style.width.%]="countTotal('linked','pnr')"></div>
                <span>{{countTotal('linked','pnr')}}%</span>
              </div>
            </div>

            <div class="col-3 align-self-center">emessi</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar issued" [style.width.%]="countTotal('issued','pnr')"></div>
                <span>{{countTotal('issued','pnr')}}%</span>
              </div>
            </div>

            <div class="col-3 align-self-center">volati</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar flown" [style.width.%]="countTotal('flown','pnr')"></div>
                <span>{{countTotal('flown','pnr')}}%</span>
              </div>

            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 "><h5>Segmenti</h5></div>
            <div class="col-3 align-self-center">da emettere</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar linked" [style.width.%]="countTotal('linked','segments')"></div>
                <span>{{countTotal('linked','segments')}}%</span>
              </div>
            </div>

            <div class="col-3 align-self-center">emessi</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar issued" [style.width.%]="countTotal('issued','segments')"></div>
                <span>{{countTotal('issued','segments')}}%</span>
              </div>
            </div>
            <div class="col-3 align-self-center">volati</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar flown" [style.width.%]="countTotal('flown','segments')"></div>
                <span>{{countTotal('flown','segments')}}%</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card class="example-card mb-3">
        <mat-card-header>
          <div mat-card-avatar class="map-image"></div>
          <mat-card-title>Report economico</mat-card-title>
          <mat-card-subtitle>
            Percentuali prenotazioni
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="row mb-3">
            <div class="col-12 "><h5>Incentivi</h5></div>
            <div class="col-3 align-self-center">da emettere</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar linked" [style.width.%]="countTotal('linked','incentives')"></div>
                <span>{{countTotal('linked','incentives')}}%</span>
              </div>
            </div>

            <div class="col-3 align-self-center">emessi</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar issued" [style.width.%]="countTotal('issued','incentives')"></div>
                <span>{{countTotal('issued','incentives')}}%</span>
              </div>
            </div>

            <div class="col-3 align-self-center">volati</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar flown" [style.width.%]="countTotal('flown','incentives')"></div>
                <span>{{countTotal('flown','incentives')}}%</span>
              </div>

            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 "><h5>Margini negoziate</h5></div>
            <div class="col-3 align-self-center">da emettere</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar linked" [style.width.%]="countTotal('linked','margin')"></div>
                <span>{{countTotal('linked','margin')}}%</span>
              </div>
            </div>

            <div class="col-3 align-self-center">emessi</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar issued" [style.width.%]="countTotal('issued','margin')"></div>
                <span>{{countTotal('issued','margin')}}%</span>
              </div>
            </div>
            <div class="col-3 align-self-center">volati</div>
            <div class="col-9 align-self-center">
              <div class="statBar">
                <div class="percBar flown" [style.width.%]="countTotal('flown','margin')"></div>
                <span>{{countTotal('flown','margin')}}%</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  -->

  <div class="row" *ngIf="!loading && currentTab === 'HOTEL' && generalStats && !appComponent.isGat">
    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3" id="printpdf">
        <mat-card-header>
          <div mat-card-avatar class="all-year-image"></div>
          <mat-card-title>Situazione aggiornata <span *ngIf="company">per {{company.name}}</span></mat-card-title>
          <mat-card-subtitle>
            dal {{fromGeneral|date:'dd/MM/yyyy'}} al {{toGeneral|date:'dd/MM/yyyy'}}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="table-responsive" *ngIf="generalStats.hotel">
            <table class="table table-bordered table-hover table-sm">
              <tr>
                <th>HOTEL</th>
                <th>Da emettere</th>
                <th>Emesse</th>
              </tr>
              <tr>
                <td>N° preontazioni</td>
                <td>{{generalStats.hotel.linked}}</td>
                <td>{{generalStats.hotel.pnr}}</td>

              </tr>
              <tr>
                <td>N° segmenti</td>
                <td><!-- {{generalStats.flight.linked.segments}} --> nd</td>
                <td>{{generalStats.hotel.segments}}</td>
              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Tariffe negoziate</td>
                <td><!-- {{generalStats.flight.linked.negotiated}} --> nd</td>
                <td>{{generalStats.hotel.negotiated}}</td>
              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Margini negoziate</td>
                <td><!-- {{generalStats.flight.linked.margin |number:'.2-2'}} € --> nd</td>
                <td>{{generalStats.hotel.margins |number:'.2-2'}} €</td>

              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Incentivi</td>
                <td><!-- {{generalStats.flight.linked.incentives |number:'.2-2'}} € --> nd</td>
                <td>{{generalStats.hotel.fees |number:'.2-2'}} €</td>
              </tr>

              <tr *ngIf="mainComponent.loggedUser.company.type === 'A'">
                <td>Totale economico</td>
                <td><!-- {{generalStats.flight.linked.amount |number:'.2-2'}} € --> nd</td>
                <td>{{generalStats.hotel.amount |number:'.2-2'}} €</td>
              </tr>

              <tr *ngIf="pnrsStats">
                <td colspan="3" *ngIf="pnrsStats.hotel && company">
                  Totale pnr generati: {{pnrsStats.hotel.pnr}}
                </td>
              </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6">
      <mat-card appearance="outlined" class="example-card mb-3">
        <mat-card-header>
          <div mat-card-avatar class="filter-image"></div>
          <mat-card-title>Filtra report</mat-card-title>
          <mat-card-subtitle>
            Filtra per data
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Dal</mat-label>
                <input matInput [max]="toGeneral" [matDatepicker]="picker" [(ngModel)]="fromGeneral">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Al</mat-label>
                <input matInput [min]="fromGeneral" [max]="today" [matDatepicker]="pickerto" [(ngModel)]="toGeneral">
                <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
                <mat-datepicker #pickerto></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div>
            <mat-form-field class="full-width">
              <mat-label>{{'SEARCH_COMPANY'|translate}}</mat-label>
              <input matInput aria-label="Departure" [(ngModel)]="companytext" (ngModelChange)="searchCompany($event)" [matAutocomplete]="cpcomplete">
              <button type="button" *ngIf="companytext" class="iconButtons" matSuffix mat-icon-button aria-label="Clear" (click)="clearFrom()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete #cpcomplete="matAutocomplete" (closed)="selectFirst()">
                <mat-option *ngFor="let comp of companiesList" (click)="selectComp(comp)">
                  <span>{{comp.name}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="company && company.name">
              <span class="companySubSpan">
                {{company.name}}
                <button mat-icon-button (click)="removeCompany(company._id)">
                  <mat-icon>close</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="text-right buttonrow">
            <button mat-button (click)="getGeneralStats(true)">Filtra</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
