<div class="padding20" style="height: 300px!important;">
  <div class="p-2 mb-3 border-top-0">
    {{'POLICY.UPDATE_DEFAULT'|translate}}
  </div>
  <mat-dialog-content class="py-0 pt-3">
    <mat-form-field class="full-width" appearance="outline">
      <input matInput placeholder="{{'USERNAME'|translate}}" aria-label="Carrier" (ngModelChange)="searchUser($event)"
             [(ngModel)]="text" [matAutocomplete]="users">
      <button type="button" class="miniIcons" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #users="matAutocomplete" (optionSelected)="getUser($event.option.value)" (closed)="selectFirst()">
        <mat-option *ngFor="let user of userList; let i = index" [value]="user">
          <span>{{user.name}} {{user.surname}}</span>
          <span class="material-icons"> arrow_right_alt </span>
          <small>{{user.company.name}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="buttonrowBis d-flex justify-content-end p-3" style="position: absolute; bottom:0;right: 0;">
    <button mat-stroked-button (click)="dialogRef.close()">{{'CLOSE'|translate}}</button>
    <button (click)="saveDefaultUser()" [disabled]="!userToSet" mat-raised-button color="primary"> {{'SAVE'|translate}} </button>
  </mat-dialog-actions>
</div>
