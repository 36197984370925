/**
 * main component for handling search parametrs
 */
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { CompaniesService } from '../../../../services/profile/companies.service';
import { MainComponent } from '../../../mainmodule/main/main.component';
import { TrenitTraveller } from '../../classes/train.models';

@Component({
  selector: 'app-search-trains',
  styleUrls: ['./search-trains.component.scss'],
  templateUrl: './search-trains.component.html',
})
export class SearchTrainsComponent implements OnInit {
  formVisible : boolean;
  public loading : boolean;
  public trainConfig : any;
  passengers : any;
  show = true;
  travellers : TrenitTraveller[] = [new TrenitTraveller(undefined)];
  tempTravellers : TrenitTraveller[] = [new TrenitTraveller(undefined)];
  maxPax: number = 9;
  constructor(
    private companyService: CompaniesService,
    private mainComponent : MainComponent,
  ){}

  toggleForm(){
    this.formVisible = !this.formVisible;
    $('#searchCard').collapse('toggle');
  }

  ngOnInit() : void{
    this.getCpSetting();
    if (localStorage.getItem('dossierPassengers')) {
      this.travellers = JSON.parse(localStorage.getItem('dossierPassengers'));
    }
    console.log('these are the travellers on init component', this.travellers)
  }

  getCpSetting() {
    const companyId = this.mainComponent.loggedUser.company._id;
    this.loading = true;
    this.companyService.getCpSettings(companyId).subscribe((res) => {
      this.trainConfig = res.addonTrain;
      this.loading = false;
    }, _ => {
      this.loading = false;
    });
  }

  isCorporate(tfName:string){
    let isCorporate = false;
    if (tfName.toLowerCase().includes('corporate')){
      isCorporate = true;
    }
    return isCorporate;
  }

  togglePaxModal(){
    $('#paxModal').modal('toggle');
  }

  confirmPax(){
    this.travellers = this.tempTravellers;
    if (this.travellers.length === 0) this.travellers = [new TrenitTraveller(undefined)];
    this.togglePaxModal();
  }

  setTravellers(e: any){
    this.tempTravellers = JSON.parse(JSON.stringify(e));
  }
  removePax(e: number | any){
    if (e > 0) this.tempTravellers.splice(e, 1);
    else if (e == 0) {
      this.tempTravellers.splice(e, 1);
      this.tempTravellers = [new TrenitTraveller(undefined)];
    }
    this.travellers = this.tempTravellers;
  }
  removeAllPax(){
    this.tempTravellers = [new TrenitTraveller(undefined)];
    this.travellers = this.tempTravellers;
  }
  setMaxPax(e:any | Event){
    this.maxPax = e.maxPax;
    this.setTravellers(e.travellers)
    this.travellers = e.travellers;
  }
}
