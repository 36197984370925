<div mat-dialog-title class="badge text-white text-center my-2 mx-3" *ngIf="authorization.authorisation.status"
    [ngClass]="{'badge-success': authorization.authorisation.status=='approved', 'badge-danger': authorization.authorisation.status == 'declined',
    'badge-warning': authorization.authorisation.status == 'pending'}">
  <span class="h-auto"> {{authorization.authorisation.status|uppercase|translate}} </span>
</div>
<h1 mat-dialog-title class="badge badge-warning text-center text-white" *ngIf="!authorization.authorisation.status">
  {{'PENDING'|translate}}
</h1>

<mat-dialog-content style="color: #000!important;">
  <app-hotel-card *ngIf="data.searchId.ref == 'Hotel'" [search]="data.searchId"></app-hotel-card>
  <app-flight-card *ngIf="data.searchId.ref == 'Flight'" [search]="data.searchId"></app-flight-card>
  <app-vehicle-card *ngIf="data.searchId.ref == 'Car'" [search]="data.searchId"></app-vehicle-card>
  <app-train-card *ngIf="data.searchId.ref == 'TrainReservation'" [search]="data.searchId"></app-train-card>

</mat-dialog-content>
<div mat-dialog-actions *ngIf="authorization.authorisation.status == 'pending';else error" class="d-flex align-self-end justify-content-end buttonrowBis">
  <button (click)="closeDialog()" mat-raised-button class="">{{'ABORT'|translate}}</button>
  <button (click)="openDialog('decline')" mat-raised-button class="bg-danger text-white">{{'POLICY.DECLINE'|translate}}</button>
  <button (click)="openDialog('approve')" mat-raised-button class="bg-success text-white">{{'POLICY.APPROVE'|translate}}</button>
</div>
<ng-template #error>
  <div>
    <span><b>{{'POLICY.ALREADY_RESPONSE'|translate}} :</b> {{authorization.authorisation.status|uppercase|translate}}</span>
  </div>
</ng-template>
