import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as $ from 'jquery';
import { SearchRes } from '../../../../../../classes/flights/search-res';
import { FlightService } from '../../../../../../services/flight/flight.service';
import { AppComponent } from '../../../../../../app.component';
import { IatacompleteService } from '../../../../../../services/iatacomplete/iatacomplete.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainComponent } from '../../../../../mainmodule/main/main.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
//import { stringify } from 'querystring';

@Component({
  selector: 'app-newsearchform',
  templateUrl: './newsearchform.component.html',
  styleUrls: ['./newsearchform.component.scss']
})
export class NewsearchformComponent implements OnInit {
  /**
   *  Local variable for request passed by parent controller
   */
  @Input() request: any;
  /**
   *  Local variable for singleSection passed by parent controller
   */
  @Input() singleSection: any;
  /**
   *  object for selections passed by parent controller
   */
  @Input() selections: {};
  /**
   *  object for selections passed by parent controller
   */
  @Input() selectionsTo: {};
  /**
   *  object for low cost selections passed by parent controller
   */
  @Input() selectionsLow: {};
  /**
   *  object for NDC low cost selections passed by parent controller
   */
  @Input() selectionsLowNDC :{};

  @Input() selectionsNdcAma: {};

  /**
   *  Local variable for searchResults by parent controller
   */
  @Input() searchResults: SearchRes;
  /**
   *  Local variable for searchResults by parent controller
   */
  @Input() searchResultsTo: SearchRes;
  /**
   *  Local variable for lowCost searchResults by parent controller
   */
  @Input() searchResultsLow: SearchRes;
  /**
   *  Local variable for ndc lowCost searchResults by parent controller
   */
  @Input() searchResultsLowNDC: SearchRes;

  @Input() searchResultsNdcAma: SearchRes;
  /**
   *  Output searchResults to parent controller
   */
    // tslint:disable-next-line:no-output-rename
  @Output('update') change: EventEmitter<SearchRes> = new EventEmitter<SearchRes>();

  @Output('updateTo') updateTo: EventEmitter<SearchRes> = new EventEmitter<SearchRes>();
  /**
   *  Output lowCost ßsearchResults to parent controller
   */
  @Output('updatelow') updatelow: EventEmitter<SearchRes> = new EventEmitter<SearchRes>();
  @Output('updatendcama') updatendcama: EventEmitter<SearchRes> = new EventEmitter<SearchRes>();
  /**
   *  Output lowCost ßsearchResults to parent controller
   */
  @Output('updatelowNDC') updatelowNDC: EventEmitter<SearchRes> = new EventEmitter<SearchRes>();
  /**
   *  Output selections to parent controller
   */
    // tslint:disable-next-line:no-output-rename
  @Output('updateSel') updateSel: EventEmitter<any> = new EventEmitter<any>();
  /**
   *  Output request update to parent controller
   */
    // tslint:disable-next-line:no-output-rename
  @Output('updateReq') updateReq: EventEmitter<any> = new EventEmitter<any>();
  /**
   *  Output reset form to parent controller
   */
    // tslint:disable-next-line:no-output-rename no-output-native
  @Output('reset') resetReq: EventEmitter<any> = new EventEmitter<any>();
  /**
   *  Output low cost selections to parent controller
   */
  @Output('updateSelLow') updateSelLow: EventEmitter<any> = new EventEmitter<any>();

  @Output('updateSelTo') updateSelTo: EventEmitter<any> = new EventEmitter<any>();
  /**
   *  Output low cost selections to parent controller
   */
  @Output('updateSelLowNDC') updateSelLowNDC: EventEmitter<any> = new EventEmitter<any>();
  @Output('updateSelNdcAma') updateSelNdcAma: EventEmitter<any> = new EventEmitter<any>();

  /**
   *  Local variable for single section departure array
   */
  from: any = [];
  /**
   *  Local variable for single section arrival array
   */
  to: any = [];
  /**
   *  Local variable for single section by locations array
   */
  via: any = [];
  /**
   *  Local variable for global itinerary carriers array
   */
  carrierToFilter: any = [];
  /**
   *  Local variable for loading
   */
  loading = false;
  /**
   *  Local variable for single carrier
   */
  carrier: string;
  /**
   *  Local variable to check if first flight is in past date
   */
  isInPast: boolean;
  /**
   *  Local variable to know if start corporate fares search
   */
  showCorporate: false;
  /**
   *  Local variable for today date
   */
  today: Date = new Date();
  /**
   *  Local variable for error
   */
  error: any;
  /**
   *  Local variable to set search form visible or not
   */
  formvisible: boolean;
  /**
   *  Local variable to set other form visible or not
   */
  othersOptionsOpen: boolean = false;
  /**
   *  Local variable to activate b2b options
   */
  isB2B: boolean;
  /**
   *  Local variable to active low cost loader
   */
  loadinglow: boolean;
  /**
   *  Local variable to active low cost loader
   */
  cacheId: string;
  /**
   *  Local variable to active low cost loader
   */
  corporateCodes: any=[];
  /**
   *  the constructor
   *  @params {flightService} FlightService
   */
  classSelected: any;

  firstLoad: boolean = true;

  departureGroups:any = [];
  arrivalGroups: any = [];
  constructor(
    private flightService: FlightService,
    public appComponent: AppComponent,
    public iataComplete: IatacompleteService,
    private snackBar: MatSnackBar,
    public mainComponent: MainComponent,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.firstLoad = true;
    if(this.request.searchType === 'roundtrip'){
      this.addFormSection();
      this.addFormSection();
    } else {
      this.firstLoad = false;
    }

    if (!localStorage.getItem('searchForm')) {
      // this.addFormSection();

      if (localStorage.getItem('sarceDossier')) {
        const sarce = JSON.parse(localStorage.getItem('sarceDossier'));
        this.
        addFormSection();
        this.request.sections[0].options.date = sarce.start;
        this.request.sections[1].options.date = sarce.end;
      }

      this.route.queryParams.subscribe(params => {
        if (params['cacheId']) {
          this.cacheId = params['cacheId'];
          this.flightService.sendFlightSearch({ _id:params['cacheId'] }).subscribe(this.manageAmadeusResuls.bind(this));
          setTimeout(() => {
            this.searchLowcost();
          }, 300);
          if(this.mainComponent.loggedUser.role.superAdmin || this.mainComponent.loggedUser.company._id == '5e2715022aeeb50011f53f5d'){
            setTimeout(() => {
              this.searchTo();
            }, 300);
          }

        }
        if (params['searchCalendar']) {
          const form = JSON.parse(params['searchCalendar']);
          console.log('form => ', form)
          this.startSearch(false, form);
        }
      });
    }

    this.route.queryParams.subscribe(params => {
      if (params['searchCalendar']) {
        const form = JSON.parse(params['searchCalendar']);
        console.log('form => ', form)
        this.startSearch(false, form);
      }
    });

    if ( this.searchResults && this.searchResults.itineraries) {
      this.toggleForm();
    }
    if (!this.searchResults && this.request.options.calendar === 'no_calendar') {
      this.request.options.calendar = false;
      this.startSearch(false, undefined);
    }

    this.flightService.getCorporateCode(this.mainComponent.loggedUser.company._id).subscribe((res) => {
      res.list.forEach((r:any) => this.corporateCodes.push({name: r.name, code: r.code}))
    } , error => {
      console.log(error)
    })
  }

  // TYPE SEARCH MANAGEMENT (roundtrip..)

  /**
   *  Change itinerary type (roundtrip, oneway, multy)
   */
  changeType() {
    console.log('this.sections enter => ', this.request.sections);
    if (this.request.searchType === 'oneway') {
      if (this.request.sections.length > 1) {
        this.request.sections.length = 1;
      }
    } else if (this.request.searchType !== 'oneway' && this.request.sections.length === 1) {
      // this.addFormSection();
    } else if (this.request.searchType === 'roundtrip' && this.request.sections.length >= 2 ) {
      this.request.sections.length = 2;
    }
    if(this.request.searchType === 'roundtrip' && this.request.sections.length == 1){
      this.addNewSection();
    }
    console.log('this.sections exit => ', this.request.sections);
  }


  /**
   *  Add carrier to carriers array
   */
  pushCarrier(value) {
    this.request.options.carrierIdentification.push(value.toUpperCase());
    delete this.carrier;
  }
  /**
   *  Remove departure location from departure array
   */
  removeIataFrom(segindex, iataindex) {
    this.request.sections[segindex].from.splice(iataindex, 1);
    this.request.sections[segindex].fromLegend.splice(iataindex, 1);
  }

  invertIata(z) {
    const to = [...this.request.sections[z].to];
    const toLegend = [...this.request.sections[z].toLegend];
    const from = [...this.request.sections[z].from];
    const fromLegend = [...this.request.sections[z].fromLegend];
    this.request.sections[z].to = from;
    this.request.sections[z].toLegend = fromLegend;
    this.request.sections[z].from = to;
    this.request.sections[z].fromLegend = toLegend;
  }
  /**
   *  Remove arrival location from arrival array
   */

  /*****/
  /**
   *  Set default arrival date of single section based on departure date
   */
  setNextDate(index) {
    /*
    if (index + 1 < this.request.sections.length) {
      const beforeDate = new Date(this.request.sections[index].options.date).getTime();
      const current = new Date(this.request.sections[index + 1].options.date).getTime();
      if (beforeDate > current) {
        this.request.sections[index + 1].options.date = new Date(this.request.sections[index].options.date);
      }
    }*/
  }

  /**
   *  Set the minimum date for date picker
   */
  getMinDate(index) {
    if (index === 0) {
      return new Date(this.today);
    } else {
      return new Date(this.request.sections[index - 1].options.date);
    }
  }

  // PAX MANAGEMENT
  /**
   *  Add a pax to search form
   */
  addPax(value) {
    if (value === 'adt') {
      this.request.passengers.adt = this.request.passengers.adt + 1;
    } else if (value === 'chd') {
      this.request.passengers.chd = this.request.passengers.chd + 1;
    } else if (value === 'inf') {
      this.request.passengers.inf = this.request.passengers.inf + 1;
    }
  }

  /**
   *  Remove pax to search form
   */
  removePax(value) {
    if (value === 'adt') {
      this.request.passengers.adt = this.request.passengers.adt - 1;
    } else if (value === 'chd') {
      this.request.passengers.chd = this.request.passengers.chd - 1;
    } else if (value === 'inf') {
      this.request.passengers.inf = this.request.passengers.inf - 1;
    }
  }
  /**
   *  Count passengers and return count
   */
  paxCounter() {
    return this.request.passengers.adt + this.request.passengers.chd + this.request.passengers.inf;
  }

  /**
   *  Check max passengers number
   */
  maxPaxReached() {
    return this.request.passengers.adt + this.request.passengers.chd + this.request.passengers.inf === 6;
  }
  /**
   *  Check max infant number; max one per adult
   */
  maxInfReached() {
    return this.request.passengers.adt + this.request.passengers.chd + this.request.passengers.inf === 6 ||
      this.request.passengers.adt === this.request.passengers.inf;
  }

  // END PAX MANAGEMENT

  clearSearch() {
    localStorage.removeItem('searchRES');
    localStorage.removeItem('searchForm');
    this.searchResults = new SearchRes();
    this.selections = {};
    this.searchResultsLow = new SearchRes();
    this.selectionsLow = {};
    this.searchResultsLowNDC = new SearchRes();
    this.selectionsLowNDC = {};
    this.change.emit(this.searchResults);
    this.updateSel.emit(this.selections);
    this.updatelow.emit(this.searchResultsLow);
    this.updatelowNDC.emit(this.searchResultsLowNDC);
    this.updateSelLow.emit(this.selectionsLow);
    this.updateSelLowNDC.emit(this.selectionsLowNDC);
    this.resetReq.emit();
    setTimeout(() => {
      this.addFormSection();
      this.addFormSection();
    }, 100);

  }

  /**
   *  Add a section to search form
   */
  addFormSection() {
    const sect = JSON.parse(JSON.stringify(this.singleSection));
    this.request.sections.push(sect);
    if (!this.request.sections[0].options.date) {
      this.request.sections[0].options.date = new Date();
    }
    if (this.request.sections.length === 2) {
      // this.request.sections[1].options.date = new Date(this.request.sections[0].options.date);
    }
    const index = this.request.sections.length - 1;
    if (this.request.sections.length > 1 ) {
      if (this.request.sections[index - 1 ].to.length > 0) {
        this.request.sections[index].from = JSON.parse(JSON.stringify(this.request.sections[index - 1].to));
      }
      if (this.request.sections[index - 1].from.length > 0) {
        this.request.sections[index].to = JSON.parse(JSON.stringify(this.request.sections[index - 1].from));
      }
    }
    this.from.push('');
    this.to.push('');
    this.via.push('');
    if(this.request.options.cabin !== ''){
      this.setClassValues(this.request.options.cabin);
    }
    this.firstLoad = false;
  }

  setClassValues(selectedTypeClass){
    for(const s of this.request.sections){
      s.options.cabin = '' + selectedTypeClass;
    }
  }

  singleCabinChange(){
    this.request.options.cabin = '';
  }

  toggleOptions(justOpen = false){
    if ((justOpen && !this.othersOptionsOpen) || !justOpen){
      $('#collapseOptions').collapse('toggle');
      this.othersOptionsOpen = !this.othersOptionsOpen;
    }
  }

  /**
   *  Remove arrival location from arrival array
   */
  removeIataTo(segindex, iataindex) {
    this.request.sections[segindex].to.splice(iataindex, 1);
    this.request.sections[segindex].toLegend.splice(iataindex, 1);
  }
  /**
   *  Remove transit location from transit array
   */
  removeIataVia(segindex, iataindex) {
    this.request.sections[segindex].options.viaAirport.splice(iataindex, 1);
  }
  /**
   *  Remove carrier from carriers array
   */
  removeIataCarrier(iataindex) {
    this.request.options.carrierIdentification.splice(iataindex, 1);
  }

  /**
   *  Set alliance search and modify search form if not compatible with alliance search
   */
  setAlliance(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.family = false;
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    if (event === true) {
      this.toggleOptions(true);
      $('.animationContent').addClass('shaking');
      setTimeout(() => {
        $('.animationContent').removeClass('shaking');
      }, 900);
    }
  }

  /**
   *  Set refund search and modify search form if not compatible with refund search
   */
  setRefound(event) {
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    this.request.options.corporate = '';
  }
  /**
   *  Set tour operator search and modify search form if not compatible with tour operator search
   */
  settourOperator(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.family = false;
    this.request.options.alliance = false;
    this.request.options.refundable = false;
    this.request.options.etnic = false;
    this.request.options.corporate = '';
    if (event === true) {
      $('.animationContent').addClass('shaking');
      setTimeout(() => {
        $('.animationContent').removeClass('shaking');
      }, 900);
    }
  }

  /**
   *  Set corporate fares search and modify search form if not compatible with corporate fares search
   */
  setCorporate(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.alliance = false;
    this.request.options.family = false;
    this.request.options.refundable = false;
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    this.request.passengers.inf = 0;
    this.request.passengers.chd = 0;
    if (event === true) {
      // this.getCorporates();
      // this.request.options.corporate = 'IT30286';
    } else {
      this.request.options.carrierIdentification = [];
      this.request.options.carrierQualifier = '';
      this.request.options.corporate = '';
    }
  }

  setResidents(event){
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.alliance = false;
    this.request.options.family = false;
    this.request.options.refundable = false;
    this.request.options.etnic = false;
    this.request.options.tourOperator = false;
    this.request.options.resident = event;
  }

  checkResidents(){
    const validResidentItinerary = (value) => (
      (value.from.includes('OLB') && value.to.includes('FCO')) ||
      (value.from.includes('FCO') && value.to.includes('OLB')) ||
      (value.from.includes('LIN') && value.to.includes('CAG')) ||
      (value.from.includes('CAG') && value.to.includes('LIN')) ||
      (value.from.includes('LIN') && value.to.includes('AHO')) ||
      (value.from.includes('AHO') && value.to.includes('LIN')) ||
      (value.from.includes('FCO') && value.to.includes('CAG')) ||
      (value.from.includes('FCO') && value.to.includes('AHO')) ||

      (value.from.includes('CAG') && value.to.includes('FCO')) ||
      (value.from.includes('AHO') && value.to.includes('FCO')) ||
      (value.from.includes('LIN') && value.to.includes('OLB')) ||
      (value.from.includes('OLB') && value.to.includes('LIN')) ||
      (value.from.includes('MXP') && value.to.includes('CAG')) ||
      (value.from.includes('CAG') && value.to.includes('MXP')) ||
      (value.from.includes('MXP') && value.to.includes('AHO')) ||
      (value.from.includes('AHO') && value.to.includes('MXP')) ||
      (value.from.includes('OLB') && value.to.includes('MXP'))
    );
    return this.request.sections.every(validResidentItinerary);
  }

  /**
   *  Set etnic search and modify search form if not compatible with etnic search
   */
  setEtnic(event) {
    this.request.options.carrierIdentification = [];
    this.request.options.carrierQualifier = '';
    this.request.options.family = false;
    this.request.options.alliance = false;
    this.request.options.refundable = false;
    this.request.options.tourOperator = false;
    if (event === true) {
      $('.animationContent').addClass('shaking');
      setTimeout(() => {
        $('.animationContent').removeClass('shaking');
      }, 900);
    }
  }

  /**
   *  Show or hide search form
   */
  toggleCollapse(index) {
    $('#collapseF' + index).collapse('toggle');
  }
  toggleCollapseRound() {
    $('#collapseRound').collapse('toggle');
  }

  /**
   *  Add a section to search form
   */
  addNewSection() {
    this.addFormSection();
    // tslint:disable-next-line:max-line-length
    // this.request.sections[this.request.sections.length - 1].options.date = new Date(this.request.sections[this.request.sections.length - 2].options.date);
    if (this.request.sections.length === 1) {
      this.request.searchType = 'oneway';
    }

  }

  /**
   *  Start flight search
   */
  startSearch(value, request) {
    if (request) {
      this.request = request;
    }
    if(this.request.searchType == 'roundtrip'){
      this.request.sections[1].from = JSON.parse(JSON.stringify(this.request.sections[0].to));
      this.request.sections[1].to = JSON.parse(JSON.stringify(this.request.sections[0].from));
      this.request.sections[1].fromLegend = JSON.parse(JSON.stringify(this.request.sections[0].toLegend));
      this.request.sections[1].toLegend = JSON.parse(JSON.stringify(this.request.sections[0].fromLegend));
    }
    this.request.options.calendar = value;
    if (this.searchResults) {
      delete this.searchResults;
      this.searchResults = new SearchRes();
      localStorage.removeItem('searchRES');
      localStorage.removeItem('searchForm');
      this.change.emit(this.searchResults);
    }
    // this.request.sections.forEach(r => r.options.date = r.options.date.toUTCString());
    const offset = new Date(this.request.sections[0].options.date).getTimezoneOffset();
    console.log('offset =>', offset);
    // this.request.sections.forEach(r => r.options.date = r.options.date.toDateString());
    const newReq = JSON.parse(JSON.stringify(this.request));
    newReq.options.cabin = '';
    // newReq.sections.forEach(r => r.options.date = new Date(new Date(r.options.date).setHours(6)));
    let sendTfReq = true;
    newReq.sections.forEach(r => {
      if(r.options.cabin === 'C' || r.options.cabin === 'F'){
        sendTfReq = false;
      }
      console.log('ciclo sulle sections ' + JSON.stringify(r));
      const tempDate = moment(r.options.date).set('hour', 6).set('minute', 0);
      r.options.date = tempDate.format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
    });

    if (this.mainComponent.loggedUser._id !== '604f240ec5ec740012a28f46') {
      this.loading = true;
      console.log('delete?');
      if (newReq._id) {
        delete newReq._id;
      }
      this.router.navigate(['/flight/search']);
      this.flightService.sendFlightSearch(newReq).subscribe(this.manageAmadeusResuls.bind(this), error => {
        this.loading = false;
        this.error = error;
      });
    }

    if (!this.request.options.calendar && this.mainComponent.loggedUser.company.services.flight && this.mainComponent.loggedUser.company.services.addonFlight.lowcost
      && this.request.sections.length <= 2) {
      if(sendTfReq){
        this.searchLowcost();
      } else{
        delete this.searchResultsLow;
        this.updatelow.emit(this.searchResultsLow);
      }
    }

    if (!this.request.options.calendar && this.mainComponent.loggedUser.company.services.flight && !this.appComponent.isB2B) {
      // delete this.searchResultsLow;
      // this.updatelow.emit(this.searchResultsLow);
      // RICECA TO
      if(this.mainComponent.loggedUser.role.superAdmin || this.mainComponent.loggedUser.company._id == '5e2715022aeeb50011f53f5d'){
        setTimeout(() => {
          this.searchTo();
        }, 300);
      }
      /*
      if(this.mainComponent.loggedUser.role.superAdmin){
        setTimeout(() => {
          this.searchNdcAma()
        }, 300);
      }
      */
    }

  }

  searchNdcAma(): void {
    const req  = JSON.parse(JSON.stringify(this.request));
    if(req._id){
      delete req._id;
    }
    req.options.corporate = '';
    req.options.family = false;
    req.options.alliance = false;
    req.options.etnic = false;
    req.options.tourOperator = false;
    req.options.priceToBeat = null;
    req.options.ndc = true;
    req.sections.forEach(r => {
      if(r._id){
        delete r._id;
      }
      console.log('ciclo sulle sections ' + JSON.stringify(r));
      const tempDate = moment(r.options.date).set('hour', 6).set('minute', 0);
      r.options.date = tempDate.format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
    });
    if (this.searchResultsNdcAma) {
      delete this.searchResultsNdcAma;
      
      this.searchResultsNdcAma = new SearchRes();
      localStorage.removeItem('searchRESNdcAma');
      this.updatendcama.emit(this.searchResultsNdcAma);
    }
    
    this.flightService.sendFlightSearchNdcAma(req).subscribe(res => {
      this.selectionsNdcAma = {};
      this.searchResultsNdcAma = res;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.searchResultsNdcAma.recommendations.length; i++) {
        // tslint:disable-next-line:max-line-length
        this.selectionsNdcAma[this.searchResultsNdcAma.recommendations[i].ref] = this.searchResultsNdcAma.recommendations[i].itineraries.map(r => r.sections[0].ref);
      }
      console.log('this.selectionsNdcAma => ', this.selectionsNdcAma);
      this.updatendcama.emit(this.searchResultsNdcAma);
      this.updateSelNdcAma.emit(this.selectionsNdcAma);
      
    }, error => {
      this.error = error;
    });
  }

  searchTo(): void {
    const req  = JSON.parse(JSON.stringify(this.request));
    if(req._id){
      delete req._id;
    }
    req.options.corporate = '';
    req.options.family = false;
    req.options.alliance = false;
    req.options.etnic = false;
    req.options.tourOperator = true;
    req.options.priceToBeat = null;
    req.sections.forEach(r => {
      if(r._id){
        delete r._id;
      }
      console.log('ciclo sulle sections ' + JSON.stringify(r));
      const tempDate = moment(r.options.date).set('hour', 6).set('minute', 0);
      r.options.date = tempDate.format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
    });
    if (this.searchResultsTo) {
      delete this.searchResultsTo;
      this.searchResultsTo = new SearchRes();
      localStorage.removeItem('searchRESTO');
      this.updateTo.emit(this.searchResultsTo);
    }
    this.flightService.sendFlightSearchTo(req).subscribe(res => {
      this.selectionsTo = {};
      this.searchResultsTo = res;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.searchResultsTo.recommendations.length; i++) {
        // tslint:disable-next-line:max-line-length
        this.selectionsTo[this.searchResultsTo.recommendations[i].ref] = this.searchResultsTo.recommendations[i].itineraries.map(r => r.sections[0].ref);
        if ( this.searchResultsTo.recommendations[i].negotiated ) {
          // console.log('boh'); // this.askFares(this.searchResults.recommendations[i].ref,i);
        }
      }
      console.log('this.selections => ', this.selectionsTo);
      this.updateTo.emit(this.searchResultsTo);
      this.updateSelTo.emit(this.selectionsTo);
    }, error => {
      this.error = error;
    });
  }

  searchLowcost(): void {
    const newReq = JSON.parse(JSON.stringify(this.request));
    console.log('New Request', newReq)
    // newReq.sections.forEach(r => r.options.date = new Date(new Date(r.options.date).setHours(6)));
    newReq.sections.forEach(r => {
      const tempDate = moment(r.options.date).set('hour', 6).set('minute', 0);
      r.options.date = tempDate.format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
    });
    this.loadinglow = true;
    if (this.searchResultsLow) {
      delete this.searchResultsLow;
      this.searchResultsLow = new SearchRes();
      this.updatelow.emit(this.searchResultsLow);
    }
    if (this.appComponent.isSbt) {
      newReq.client = 'suitefortravel';
    } else if (this.appComponent.isGat) {
      newReq.client = 'flygattinoni';
    } else if(this.appComponent.isB2B){
      newReq.client = "b2b";
    } else {
      newReq.client = 'leonardotravel';
    }
    this.flightService.sendFlightSearchLowCost(newReq).subscribe((res) => {
      console.log('res => ', res);
      this.selectionsLow = {};
      this.searchResultsLow = res;
      // tslint:disable-next-line:prefer-for-of
      if (this.searchResultsLow.recommendations.length > 0) {
        for (let i = 0; i < this.searchResultsLow.recommendations.length; i++) {
          this.searchResultsLow.recommendations[i].ref = i + 1;
          // tslint:disable-next-line:max-line-length
          this.selectionsLow[this.searchResultsLow.recommendations[i].ref] = this.searchResultsLow.recommendations[i].itineraries.map(r => r.sections[0].ref);
        }
      }
      this.loadinglow = false;
      this.updatelow.emit(this.searchResultsLow);
      this.updateSelLow.emit(this.selectionsLow);
      // .updateReq.emit(this.request);
      // this.toggleForm();
    }, error => {
      this.loadinglow = false;
      this.error = error;
    });
    if(this.mainComponent.loggedUser.company.services.addonFlight.ndc){
      delete this.searchResultsLowNDC;
      this.searchResultsLowNDC = new SearchRes();
      this.updatelowNDC.emit(this.searchResultsLowNDC);
      // call ndc only for leonardotravel
      this.selectionsLowNDC = {};
      this.flightService.sendFlightSearchLowCostNDC(newReq).subscribe((res) => {
        console.log('res => ', res);
        this.searchResultsLowNDC = res;
        // tslint:disable-next-line:prefer-for-of
        if (this.searchResultsLowNDC.recommendations.length > 0) {
          for (let i = 0; i < this.searchResultsLowNDC.recommendations.length; i++) {
            this.searchResultsLowNDC.recommendations[i].ref = i + 1;
            // tslint:disable-next-line:max-line-length
            this.selectionsLowNDC[this.searchResultsLowNDC.recommendations[i].ref] = this.searchResultsLowNDC.recommendations[i].itineraries.map(r => r.sections[0].ref);
          }
        }
        this.loadinglow = false;
        this.updatelowNDC.emit(this.searchResultsLowNDC);
        this.updateSelLowNDC.emit(this.selectionsLowNDC);
        // .updateReq.emit(this.request);
        // this.toggleForm();
      }, error => {
        this.loadinglow = false;
        this.error = error;
      });
    }
  }

  manageAmadeusResuls(res){
    this.location.go(`/flight/search?cacheId=${res._id || this.cacheId}` );
    console.log('res => ', res);
    this.selections = {};
    this.searchResults = res;
    // localStorage.setItem('searchRES', JSON.stringify(res));
    localStorage.setItem('searchForm', JSON.stringify(this.request));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.searchResults.recommendations.length; i++) {
      // tslint:disable-next-line:max-line-length
      this.selections[this.searchResults.recommendations[i].ref] = this.searchResults.recommendations[i].itineraries.map(r => r.sections[0].ref);
      if ( this.searchResults.recommendations[i].negotiated ) {
        // console.log('boh'); // this.askFares(this.searchResults.recommendations[i].ref,i);
      }
    }
    this.loading = false;
    console.log('this.selections => ', this.selections);
    this.change.emit(this.searchResults);
    this.updateSel.emit(this.selections);
    this.updateReq.emit(this.request);
    this.toggleForm();
  }

  /**
   *  toggle form visibility
   */
  toggleForm() {
    this.formvisible = ! this.formvisible;
    $('#searchCard').collapse('toggle');
  }

  /**
   *  CHeck if search form is valid
   */
  isValid() {
    let invalid = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.request.sections.length; i++) {
      const section = this.request.sections[i];
      if (section.from.length === 0 || section.to.length === 0 || !section.options.date) {
        invalid = true;
        return invalid;
      }
    }
    if (this.request.searchType === 'roundtrip' && this.request.sections.length === 1) {
      invalid = true;
      return invalid;
    }
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.request.sections.length; i++) {
      const secDate = new Date(this.request.sections[i].options.date);
      secDate.setHours(0, 0, 0, 0);
      if (secDate < now) {
        this.isInPast = true;
        invalid = true;
        return invalid;
      }
    }

    if (this.showCorporate && !this.request.options.corporate) {
      invalid = true;
      return invalid;
    }
    if (this.request.options.alliance && this.request.options.carrierIdentification.length===0) {
      invalid = true;
      return invalid;
    }
  }

  isValidForm(){
    if(this.request.searchType == 'oneway'){
      if(this.request.sections[0].from.length>0 && this.request.sections[0].to.length>0 && this.request.sections[0].options.date){
        return true;
      }
      return false;
    }
    else if(this.request.searchType == 'roundtrip'){
      if(this.request.sections[0].from.length>0 && this.request.sections[0].to.length>0 && this.request.sections[0].options.date && this.request.sections[1].options.date){
        return true;
      }
      return false;
    }
    else{
      if(
        this.request.sections.every((res : any)=>res.from.length>0) &&
        this.request.sections.every((res : any)=>res.to.length>0) &&
        this.request.sections.every((res : any)=>res.options.date)){
        return true;
      }
      return false;
    }
  }

  /**
   *  Remove flight section search form
   */
  removeSection(index) {
    this.request.sections.splice(index, 1);
    this.from.splice(index, 1);
    this.to.splice(index, 1);
    this.via.splice(index, 1);
  }

  toggleInfoResidents(){
    $('#infoResidentsModal').modal('toggle');
  }
}
