import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-role-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit {
  @Input() groupName? :any;
  @Input() groupsNames: any;
  @Input() updating: boolean;
  @Output() submitted = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() updated = new EventEmitter<any>();

  form = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    if (this.groupName){
      this.form.patchValue({name : this.groupName});
      this.updating = true;
    }
  }

  handleSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.submitted.emit(this.form.value);
  }

  handleUpdate(){
    if (this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }
    this.updated.emit(this.form.value);
  }

  checkIfNameExist(): void {
    const isNameExistent = this.groupsNames.findIndex((item:any) => item.toLowerCase() === this.form.get('name').value.toLowerCase());
    if (isNameExistent != -1) {
      this.form.get('name').setErrors({nameAlreadyExist: true});
    }
  }

  checkErrors(){
    console.log(this.form.get('name').getError('nameAlreadyExist') || this.form.get('name').getError('required'))
    return this.form.get('name').getError('nameAlreadyExist') || this.form.get('name').getError('required');
  }
}
