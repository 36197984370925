import {NgModule} from '@angular/core';
import {FlightCardComponent} from './flight-card.component';
import {PipesModule} from '../../pipes/pipes.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {MatCardModule} from "@angular/material/card";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SharedModule} from "../../../modules/shared/shared.module";


@NgModule({
  declarations: [ FlightCardComponent ],
  exports: [
    FlightCardComponent
  ],
    imports: [
        CommonModule,
        PipesModule,
        TranslateModule,
        MatCardModule,
        MatExpansionModule,
        MatTooltipModule,
        SharedModule
    ]
})
export class FlightCardModule { }
