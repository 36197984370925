import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {CompaniesService} from "../../../../../../services/profile/companies.service";

@Component({
  selector: 'app-b2c-settings',
  templateUrl: './b2c-settings.component.html',
  styleUrls: ['./b2c-settings.component.scss']
})
export class B2cSettingsComponent implements OnInit {
  @Input() cpId: string;
  @Input() b2cSettingsId?: string;
  @Input() cpSetting: any;
  // tslint:disable-next-line:no-output-rename
  @Output('update') change: EventEmitter<any> = new EventEmitter<any>();
  tabs: any[] = ['about','contacts'];
  currentTab: string = 'about';
  b2cSettings: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: 'Arial',
      //defaultFontSize: '1',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },

    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ['fontSize','insertImage','insertVideo','customClasses','toggleEditorMode']
    ]
  };
  constructor(
    private companyService : CompaniesService,
  ) {
  }

  getCpSettings(): void {
    this.companyService.getB2cSettings(this.b2cSettingsId).subscribe((res) => {
      this.b2cSettings = res;
      // this.loading = false;
      console.log('res => ', res);
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  ngOnInit(): void {
    if(!this.b2cSettingsId){
      this.b2cSettings = {
        companyId: this.cpId,
        primaryColor:'#2b71db',
        SecondaryColor:'#2b71db',
        b2cAbout:'',
        b2cWhere: [
          {
            latitude:'',
            longitude:'',
            text:'',
            phone:'',
            email:'',
          }
        ],
      }
    } else {
      this.getCpSettings();
    }

  }

  addWhere(){
    const newWhere = {
      latitude:'',
      longitude:'',
      text:'',
      phone:'',
      email:'',
    }
    this.b2cSettings.b2cWhere.push(newWhere);
  }

  removeWhere(i:number){
    this.b2cSettings.b2cWhere.splice(i,1);
  }

  save(){
    console.log(this.b2cSettings);
    if(!this.b2cSettings._id){
      this.postCpSettings();
    } else {
      this.putCpSettings();
    }
  }

  postCpSettings(): void {
    this.companyService.postB2cSettings(this.b2cSettings).subscribe((res) => {
      // this.company.services = res;
      // this.loading = false;
      console.log('res => ', res);
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  putCpSettings(): void {
    this.companyService.putB2cSettings(this.b2cSettings).subscribe((res) => {
      // this.company.services = res;
      // this.loading = false;
      console.log('res => ', res);
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }
}
