import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-policy-dialog',
  templateUrl: './create-policy-dialog.component.html',
  styleUrls: ['./create-policy-dialog.component.scss']
})
export class CreatePolicyDialogComponent implements OnInit {
  rolesList: any[];
  policyList: any[];
  freeRoles: any;

  createPolicyForm = this.fb.group({
    policyName: ['', Validators.required],
    policyRole: ['', Validators.required],
    hotels: this.fb.group({
      value: [''],
      type: [''],
    }),
    flights: this.fb.group({
      value: [''],
      type: [''],
    }),
    cars: this.fb.group({
      value: [''],
      type: [''],
    }),
    trains: this.fb.group({
      value: [''],
      type: [''],
    }),
    transfer: this.fb.group({
      value: [''],
      type: [''],
    }),
  });
  private isEditable: any;

  constructor(private dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: UntypedFormBuilder

  ) {
  }

  get policyName(): AbstractControl {
    return this.createPolicyForm.get('policyName');
  }

  get policyRole(): AbstractControl {
    return this.createPolicyForm.get('policyRole');
  }

  ngOnInit(): void {
    console.log('this.data => ', this.data);
    this.isEditable = this.data.options.method === 'edit';
    if (this.isEditable) {
      this.policyList = this.data.options.policyList;
      this.rolesList = this.data.options.rolesList;
      const formData = {
        policyName: this.data.name,
        policyRole: this.data.group.name,
        hotels: this.data.tolerance?.hotels ? this.data.tolerance?.hotels : {value: '', type: ''},
        flights: this.data.tolerance?.flights ? this.data.tolerance?.flights : {value: '', type: ''},
        cars: this.data.tolerance?.cars ? this.data.tolerance?.cars : {value: '', type: ''},
        trains: this.data.tolerance?.trains ? this.data.tolerance?.trains : {value: '', type: ''},
        transfer: this.data.tolerance?.transfer ? this.data.tolerance?.transfer : {value: '', type: ''},
      };
      this.createPolicyForm.patchValue(formData);
    } else if(this.data.options.method === 'create'){
      this.rolesList = this.data.rolesList;
      this.policyList = this.data.policies;
      this.freeRoles = this.rolesList;
      this.checkFreeRoles();
    }
  }

  checkFreeRoles(){
    this.policyList.forEach((p:any)=> {
      const found = this.rolesList.findIndex(r => r._id === p.group._id);
      if(found !== -1) this.freeRoles.splice(found, 1);
    })
  }

  checkIfRoleExist(): void {
    let isRoleExistent = false;
    this.policyList.forEach((p:any)=>{
      isRoleExistent = (p.group.name === this.policyRole.value);
    })
    if (isRoleExistent) {
      this.policyRole.setErrors({roleAlreadyExist: true});
    }
  }

  checkIfNameExist(): void {
    const isNameExistent = this.policyList.findIndex(item => item.name.toLowerCase() === this.policyName.value.toLowerCase());
    if (isNameExistent != -1) {
      this.policyName.setErrors({nameAlreadyExist: true});
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeAndOpenNewGroupDialog(){
    this.dialogRef.close({createNewGroupDialog: true});
  }
}
