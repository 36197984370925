<div *ngIf="loading">
  <div class="row">
    <!--
    <div class="col-lg-3 mb-2">
      <app-banner-slide></app-banner-slide>
    </div>
    -->
    <div class="col-lg-12">
      <div class="card mb-2">
        <div class="mb-2 padding20 text-center">
          {{'ELABORATE'|translate}} {{hotelRes.list.length}} {{'RESULTS'|translate}}
        </div>
        <div class="remaining">
          {{remaining}}%
        </div>
        <mat-progress-bar mode="determinate" value="{{remaining}}"></mat-progress-bar>
      </div>
      <!--
      <div class="card mb-2">
        <div class="mb-2 padding20 text-center">
          <div class="mb-2">{{remaining}}</div>
          {{'ELABORATE'|translate}} {{hotelRes.list.length}} {{'RESULTS'|translate}}
        </div>
        <mat-progress-spinner
                              class="mb-2"
                              mode="indeterminate"
                              style="margin:auto;display: block;">
        </mat-progress-spinner>
      </div>
      -->

    </div>
  </div>


</div>


<div *ngIf="results && !loading">
  <div class="row">
    <div class="col-12 d-inline-block d-lg-none my-2">
      <mat-accordion class="" hideToggle>
        <mat-expansion-panel [expanded]="closeExpand" (click)="closeExpand = !closeExpand">
          <mat-expansion-panel-header>
            <mat-panel-title> {{'OPEN_FILTERS'|translate}} </mat-panel-title>
            <mat-panel-description class="d-flex justify-content-end">
              <mat-icon> tune </mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="mb-3">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'SEARCH_BY_NAME' |translate}}</mat-label>
              <input matInput placeholder="Ex. Hilton Hotel" [(ngModel)]="textSearch" (ngModelChange)="filterHotel()">
            </mat-form-field>
          </div>

          <div class="mb-4" *ngIf="availableStar.length > 0">
            <div class="mb-2">STAR RATING</div>
            <div *ngFor="let rating of availableStar">
              <div (click)="elaborateStarFilter(rating)" class="pointer">
                <span *ngIf="includedInStarFilter(rating)" class="material-icons-outlined checkIcon"> check_box </span>
                <span *ngIf="!includedInStarFilter(rating)" class="material-icons checkIcon"> check_box_outline_blank </span>
                <span *ngFor="let star of starCounter(rating)" class="material-icons ratingIcon"> star_rate </span>
                <span *ngIf="rating === '0'"> Altro</span>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">CATEGORY</div>
            <div *ngFor="let category of availableCatFilter">
              <div (click)="elaborateCatFilter(category)" class="pointer">
                <span *ngIf="includedInCategoriesFilter(category)" class="material-icons-outlined checkIcon"> check_box </span>
                <span *ngIf="!includedInCategoriesFilter(category)" class="material-icons checkIcon"> check_box_outline_blank </span>
                <span> {{category}} </span>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">STRUTTURE</div>
            <div (click)="toggleWithPrice()" class="pointer mb-2">
              <span *ngIf="onlyWithPrice" class="material-icons-outlined checkIcon"> check_box </span>
              <span *ngIf="!onlyWithPrice" class="material-icons checkIcon"> check_box_outline_blank </span>
              {{"ONLY_AVAILABLE_STRUCTURES"|translate}}
            </div>
            <div *ngIf="availableFavFilter" (click)="toggleFavorites()" class="pointer">
              <span *ngIf="favFilter" class="material-icons-outlined checkIcon"> check_box </span>
              <span *ngIf="!favFilter" class="material-icons checkIcon"> check_box_outline_blank </span>
              {{"ONLY_FAVORITES"|translate}}
            </div>
          </div>

          <div class="mb-4" *ngIf="onlyWithPrice">
            <div class="mb-2">{{"PRICE"|translate}} €</div>
            <div class="mr-4 position-relative">
              <range-slider [min]="minPriceFound" [max]="maxPriceFound" [(minValue)]="minPriceFilter" [(maxValue)]="maxPriceFilter"
                            [step]="1" (rangeChange)="changePrice($event)">
              </range-slider>
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">{{"DISTANCE"|translate}}</div>
            <div class="mr-4 position-relative">
              <range-slider [min]="minDistance" [max]="maxDistance" [(minValue)]="minDistanceFilter" [(maxValue)]="maxDistanceFilter"
                            [step]="1" (rangeChange)="changeDistance($event)">
              </range-slider>
            </div>
          </div>

          <div class="text-center mapBackground">
            <span class="d-block mb-2 material-icons-outlined"> location_on </span>
            <span class="btnOnMap pointer" (click)="openDialogAllMap()"> {{"LOOK_AT_MAP"|translate}} </span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-lg-3" *ngIf="!mainComponent.opened">

      <mat-card appearance="outlined" class="mr-lg-3 d-none d-lg-block">
        <mat-card-title class="px-4 pt-2">{{'OPEN_FILTERS'|translate}}</mat-card-title>
        <!--<mat-card-subtitle></mat-card-subtitle>-->
        <mat-card-content>
          <div class="mb-3">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'SEARCH_BY_NAME' |translate}}</mat-label>
              <input matInput placeholder="Ex. Hilton Hotel" [(ngModel)]="textSearch" (ngModelChange)="filterHotel()">
            </mat-form-field>
          </div>

          <div class="mb-4" *ngIf="availableStar.length > 0">
            <div class="mb-2">STAR RATING</div>
            <div *ngFor="let rating of availableStar">
              <div (click)="elaborateStarFilter(rating)" class="pointer">
                <span *ngIf="includedInStarFilter(rating)" class="material-icons-outlined checkIcon"> check_box </span>
                <span *ngIf="!includedInStarFilter(rating)" class="material-icons checkIcon"> check_box_outline_blank </span>
                <span *ngFor="let star of starCounter(rating)" class="material-icons ratingIcon"> star_rate </span>
                <span *ngIf="rating === '0'"> Altro</span>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">CATEGORY</div>
            <div *ngFor="let category of availableCatFilter">
              <div (click)="elaborateCatFilter(category)" class="pointer">
                <span *ngIf="includedInCategoriesFilter(category)" class="material-icons-outlined checkIcon"> check_box </span>
                <span *ngIf="!includedInCategoriesFilter(category)" class="material-icons checkIcon"> check_box_outline_blank </span>
                <span> {{category}} </span>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">STRUTTURE</div>
            <div (click)="toggleWithPrice()" class="pointer mb-2">
              <span *ngIf="onlyWithPrice" class="material-icons-outlined checkIcon"> check_box </span>
              <span *ngIf="!onlyWithPrice" class="material-icons checkIcon"> check_box_outline_blank </span>
              {{"ONLY_AVAILABLE_STRUCTURES"|translate}}
            </div>
            <div *ngIf="availableFavFilter" (click)="toggleFavorites()" class="pointer">
              <span *ngIf="favFilter" class="material-icons-outlined checkIcon"> check_box </span>
              <span *ngIf="!favFilter" class="material-icons checkIcon"> check_box_outline_blank </span>
              {{"ONLY_FAVORITES"|translate}}
            </div>
          </div>

          <div class="mb-4" *ngIf="onlyWithPrice">
            <div class="mb-2">{{"PRICE"|translate}} €</div>
            <div class="mr-4 position-relative">
              <range-slider [min]="minPriceFound" [max]="maxPriceFound" [(minValue)]="minPriceFilter" [(maxValue)]="maxPriceFilter"
                            [step]="1" (rangeChange)="changePrice($event)">
              </range-slider>
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">{{"DISTANCE"|translate}}</div>
            <div class="mr-4 position-relative">
              <range-slider [min]="minDistance" [max]="maxDistance" [(minValue)]="minDistanceFilter" [(maxValue)]="maxDistanceFilter"
                            [step]="1" (rangeChange)="changeDistance($event)">
              </range-slider>
            </div>
          </div>

          <div class="text-center mapBackground">
            <span class="d-block mb-2 material-icons-outlined"> location_on </span>
            <span class="btnOnMap pointer" (click)="openDialogAllMap()">
              {{"LOOK_AT_MAP"|translate}}
            </span>
          </div>
        </mat-card-content>
      </mat-card>

    </div>

    <div [ngClass]="{'col-12': mainComponent.opened, 'col-lg-9':!mainComponent.opened}">
      <div class="">
        <div class="row mb-3">
          <div class="col-lg-6">

            <div class="mb-3 pl-2"><span style="color:#4d79f6; font-size:14px;">{{results.list.length}}</span> {{"FINDED_STRUCTS"|translate}}</div>
            <div class="mb-2">
              <div class="btnList">
                <span class="spanBtn mx-sm-2" (click)="toggleSearch()">
                  <span class="updateSearch">Modifica ricerca</span>
                  <span class="material-icons" matTooltip="Modifica ricerca">search</span>
                </span>
              </div>
              <mat-select class="selectOrder" [(ngModel)]="ordering" (ngModelChange)="sort($event)">
                <mat-option *ngIf="onlyWithPrice" [value]="'price'">{{"PRICE"|translate}}</mat-option>
                <mat-option [value]="'distance'">{{"DIST"|translate}}</mat-option>
                <mat-option [value]="'rating'">{{"RATING_STRING"|translate}}</mat-option>
              </mat-select>
              <div class="btnList">
                <span matTooltip="Tipo ordinamento" class="pointer material-icons" *ngIf="sorting === 'asc'" (click)="changeSorting('desc')">
                  expand_less
                </span>
                <span matTooltip="Tipo ordinamento" class="pointer material-icons" *ngIf="sorting === 'desc'" (click)="changeSorting('asc')">
                  expand_more
                </span>
                <span *ngIf="mainComponent.opened" class="material-icons" (click)="mainComponent.toggleSidenav()" matTooltip="Filtra ricerca">tune</span>
                <!-- <span class="material-icons" [ngClass]="{'active': !grid}" matTooltip="Lista" (click)="switchGrid(false)">list</span>
                <span class="material-icons" [ngClass]="{'active': grid}"  matTooltip="Griglia" (click)="switchGrid(true)">apps</span> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6 text-right">
            <div class="mb-2" style="font-size:20px">
              {{"RESULTS_FOR"|translate}}: <span style="color:#4d79f6">{{clonedForm.destination.display}}</span>
            </div>
            <div  class="mb-2">
              {{'ROOMS'|translate}}: {{clonedForm.occupancy.length}} | {{'GUESTS'|translate}}: {{returnTotalGuests()}}
            </div>
            <div class="small">
              {{"DAL"|translate}} {{clonedForm.checkIn|date:'dd/MM/yyyy'}} {{"AL"|translate}} {{clonedForm.checkOut|date:'dd/MM/yyyy'}}
            </div>
          </div>

        </div>

      </div>
      <hr/>


      <div class="row mb-2">
        <div class="col-md-6">
          <div>{{"PAGE"|translate}} {{currentPage}} {{"OF"|translate}} {{totalPages}}</div>
        </div>
        <div class="col-md-6 text-right">
          <div class="btnList">
            <button class="spanBtn material-icons" (click)="prevPage()" [disabled]="currentPage == 1"> chevron_left </button>
            <button class="spanBtn material-icons" (click)="nextPage()" [disabled]="currentPage == totalPages"> chevron_right </button>
          </div>
        </div>
      </div>

      <div *ngIf="!grid">
        <ng-container *ngFor="let hotel of results.list; let i = index">
          <div class="hotelCard mb-2" *ngIf="i >= firstItem && i <= lastItem">
            <div class="row">
              <div class="col-xxl-3 col-lg-3 co-md-3">
                <div class="imageContList" [ngStyle]="{backgroundImage:'url('+returnImg(hotel)+')'}">
                </div>
              </div>
              <div class="col-xxl-9 col-lg-9 col-md-9">
                <div class="mr-2 ml-2">
                  <div>{{hotel.name}}</div>
                  <div>{{hotel.hotelCode}}</div>

                  <div class="mb-1" *ngIf="hotel.rating">
                  <span *ngFor="let star of starCounter(hotel.rating)" class="material-icons ratingIcon"> star_rate </span>
                  </div>
                  <div>{{hotel.address}}</div>
                  <div>{{"TYPE"|translate}}: {{hotel.type}}</div>
                  <div class="row actionButs">
                    <div class="col-8 align-self-center">
                      <span class="btnNew">AWG/NIGHT € 80.00</span>
                    </div>
                    <div class="col-4 text-right align-self-center">
                    <span class="material-icons" style="color: red;"> favorite_border </span>
                    <span class="material-icons" style="color:#4d79f6"> location_on </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row" *ngIf="grid">
        <ng-container *ngFor="let hotel of results.list; let i = index">
          <div class="col-xxl-3 col-md-6 col-lg-4 mb-3" [ngClass]="{'col-xxl-4 col-lg-4':appComponent.isSbt}" *ngIf="i >= firstItem && i <= lastItem">
            <mat-card appearance="outlined" class="example-card">

              <mat-card-title class="px-3 pt-3"> {{hotel.name}} </mat-card-title>
              <mat-card-subtitle class="px-3 pb-3">{{hotel.hotelCode}}</mat-card-subtitle>

              <div (click)="quote(hotel.hotelCode, hotel.rating)" class="imageCont pointer" [ngStyle]="{backgroundImage:'url('+returnImg(hotel)+')'}">
                <span class="noImage" *ngIf="noImg(hotel)">
                  <span class="material-icons"> touch_app </span> Mostra immagini
                </span>
              </div>

              <mat-card-content>
                <div class="mb-1 mt-2" *ngIf="hotel.rating > 0">
                  <span *ngFor="let star of starCounter(hotel.rating)" class="material-icons ratingIcon"> star_rate </span>
                </div>
                <div class="mb-1 mt-2" style="height: 18px!important;width: 100%" *ngIf="hotel.rating == 0">
                  <!--<span style=""></span>-->
                </div>
                <div>{{hotel.address}}</div>
                <div>{{"TYPE"|translate}}: {{hotel.type}}</div>
                <div>{{"DISTANCE_SEARCH_POINT"|translate}}: {{convertDistance(hotel.distanceKm)}}</div>
                <div class="row actionButs">
                  <div class="col-8 align-self-center">
                    <span style="font-size:11px" class="text-danger" *ngIf="!hotel.amount">{{"NO_ROOMS_FOR_SELECTED_DATES" |translate}}</span>
                    <span (click)="quote(hotel.hotelCode)" class="btnNew pointer" *ngIf="hotel.amount">€ {{hotel.amount|number:'.2-2'}}</span>
                    <span *ngIf="(appComponent.isSbt || appComponent.isB2B) && mainComponent.loggedUser.company.services.policy" class="ml-1">
                      <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt" [isHotel]="true"
                                              [arrayOfAlerts]="checkIfOutOfPolicyStars(hotel.rating)">
                      </app-policy-alerts-icon>
                    </span>
                  </div>
                  <div class="col-4 text-right align-self-center">
                    <span *ngIf="!isFavorite(hotel.hotelCode) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)"
                          class="material-icons" style="color: red;" (click)="addToFavorites(hotel)">
                      favorite_border
                    </span>
                    <span *ngIf="isFavorite(hotel.hotelCode) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)"
                          class="material-icons" style="color: red;" (click)="removeToFavorites(hotel)">
                      favorite
                    </span>
                    <span (click)="openDialogMap(hotel)" class="material-icons pointer" style="color:#4d79f6"> location_on </span>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>

        <div class="col-12 text-center" *ngIf="results.list.length === 0">
          <div class="pt-5 pb-5">
            Nessun hotel disponibile in queste date.
            <div class="pt-2 pb-2">
              <button (click)="toggleWithPrice()" mat-flat-button color="primary">Mostra tutte le strutture</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <div>{{"PAGE"|translate}} {{currentPage}} {{"OF"|translate}} {{totalPages}}</div>
        </div>
        <div class="col-md-6 text-right">
          <div class="btnList">
            <button class="spanBtn material-icons" (click)="prevPage()" [disabled]="currentPage == 1"> chevron_left </button>
            <button class="spanBtn material-icons" (click)="nextPage()" [disabled]="currentPage == totalPages"> chevron_right </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
