<div class="padding10">
  <h3 mat-dialog-title>{{'POLICY.LEAVE_NOTE'|translate}} {{data.type|uppercase|translate}}</h3>
  <div mat-dialog-content>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{'POLICY.TYPE_SOME'|translate}}</mat-label>
      <textarea [formControl]="note" matInput></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="d-flex align-self-end justify-content-end buttonrowBis">
    <button (click)="closeDialog(false)" mat-raised-button >{{'POLICY.ABORT'|translate}}</button>
    <button [disabled]="note.invalid" (click)="closeDialog(true)" mat-raised-button class="text-white"
            [ngClass]="{'bg-danger':data.type == 'decline', 'bg-success': data.type == 'approve'}" >
      {{'POLICY.'+data.type|uppercase|translate}}
    </button>
  </div>
</div>

