<mat-card appearance="outlined" class="collapse show" id="searchCard">
  <mat-card-content>

    <form (submit)="onSubmit($event)" [formGroup]="searchForm" id="frmSearch">
      <div class="row my-2 alert alert-warning isVenice d-block" *ngIf="isVenice">
        <span>{{'IS_VENICE_ALERT' | translate}}</span>
        <span><a href="{{'IS_VENICE_URL' | translate}}" target="new">{{'HERE' | translate}}</a>.</span>
      </div>

      <div class="row mb-4">
        <div class="col-md-auto mr-2 align-self-center">
          <section class="example-section">
            <mat-checkbox class="searchTrainLabel" formControlName="roundTrip">
              {{'TRAIN_MODULE.A/R' | translate}}
            </mat-checkbox>
          </section>
        </div>

        <div class="col-md-auto mr-2 align-self-center">
          <section class="example-section">
            <mat-checkbox class="searchTrainLabel" formControlName="use_pit" [disabled]="!pitCodes || pitCodes.length == 0"
                          [matTooltip]="(pitCodes && pitCodes.length > 0 ? '' : 'TRAIN_MODULE.PIT_SETUP') | translate">
              {{'TRAIN_MODULE.USE_PIT' | translate}}
            </mat-checkbox>
          </section>
        </div>

        <div class="col-md-auto align-content-center ml-2 ml-md-0">
          <span class="pointer" [ngClass]="{'text-danger':!travellersLoad()}" (click)="togglePaxModal()">
            {{'PASSENGERS'|translate}} <span *ngIf="travellers.length > 0">: {{travellers.length}}</span>
            <span class="material-icons"> keyboard_arrow_down </span>
          </span>
        </div>
      </div>

      <div class="row searchContainer d-flex justify-content-end">
        <div class="formTopButtons ">
          <div class="formTopAction reset" (click)="resetForm()" matTooltip="{{'CLEAR_FORM'|translate}}">
              <span class="material-icons"> refresh </span>
          </div>
        </div>
      </div>

      <div class="row lightbg">
        <!-- FROM -->
        <div class="col-xs-12 col-lg-3">
          <!-- STAZIONE PARTENZA -->
          <mat-form-field class="full-width">
            <mat-label>{{ 'TRAIN_MODULE.FROM_STATION' | translate}}</mat-label>
            <input [matAutocomplete]="auto_dep" formControlName="departure_station" matInput>
          </mat-form-field>

          <mat-autocomplete #auto_dep="matAutocomplete" [displayWith]="displayFn" class="full-width" autoActiveFirstOption
                            (closed)="selectFirst(true)">
            <mat-option *ngIf="isDepartureStationLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isDepartureStationLoading">
              <mat-option *ngFor="let station of filteredDepartureStation" [value]="station" (click)="selectOption(station, true)">
                <span>{{ station.name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <!-- TO -->
        <div class="col-xs-12 col-lg-3">
          <!-- STAZIONE ARRIVO -->
          <mat-form-field class="full-width">
            <mat-label>{{ 'TRAIN_MODULE.TO_STATION' | translate}}</mat-label>
            <input [matAutocomplete]="auto_arr" formControlName="arrival_station" matInput>
          </mat-form-field>

          <mat-autocomplete #auto_arr="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption (closed)="selectFirst(false)">
            <mat-option *ngIf="isArrivalStationLoading" class="is-loading">
              <mat-spinner diameter="50"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isArrivalStationLoading">
              <mat-option *ngFor="let station of filteredArrivalStation" [value]="station" (click)="selectOption(station, false)">
                <span>{{ station.name }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <!-- DATA PARTENZA-->
        <div [ngClass]="{'col-lg-3': !searchForm.getRawValue()['roundTrip'], 'col-lg-2':searchForm.getRawValue()['roundTrip']}" class="col-xs-12">
          <mat-form-field class="full-width" color="primary">
            <mat-label>{{'TRAIN_MODULE.DEPARTURE_DATE' | translate}}</mat-label>

            <input (click)="departure_date.open()" (ngModelChange)="onDepartureReturnDateChange($event)" [min]="departureStartDate"
                   [ngxMatDatetimePicker]="departure_date" formControlName="departure_date" matInput readonly> <!-- | async-->
            <mat-datepicker-toggle [for]="departure_date" matSuffix></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #departure_date [showSeconds]="false" [showSpinners]="true" [stepHour]="1" [stepMinute]="5"
                                     [stepSecond]="1">
              <ngx-mat-datepicker-actions>
                <button class="applyDateBtn" mat-stroked-button ngxMatDatepickerApply (click)="onDepartureReturnDateChange($event)">
                  <mat-icon> done </mat-icon>
                </button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
            <mat-error>{{ "MIN_DATE_NOT_VALID" | translate }}</mat-error>
          </mat-form-field>
        </div>

        <!-- DATA RITORNO-->
        <div *ngIf="searchForm.getRawValue()['roundTrip']" class="col-xs-12 col-lg-2">
          <mat-form-field class="full-width">
            <mat-label>{{'TRAIN_MODULE.RETURN_DATE' | translate}}</mat-label>

            <input (click)="return_date.open()" (dateChange)="onDepartureReturnDateChange($event)" [min]="returnStartDate"
                   [ngxMatDatetimePicker]="return_date" formControlName="return_date" matInput readonly> <!-- | async-->
            <mat-datepicker-toggle [for]="return_date" matSuffix></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #return_date [showSeconds]="false" [showSpinners]="true" [stepHour]="1" [stepMinute]="5"
                                     [stepSecond]="1">
              <ngx-mat-datepicker-actions>
                <button class="applyDateBtn" mat-stroked-button ngxMatDatepickerApply (click)="onDepartureReturnDateChange($event)">
                  <mat-icon> done </mat-icon>
                </button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
            <mat-error>{{ "MIN_DATE_NOT_VALID" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <!-- PIT -->
        <div class="col-xs-12 col-lg-2" *ngIf="searchForm.getRawValue()['use_pit']">
          <mat-form-field class="full-width">
            <mat-label>{{'TRAIN_MODULE.PIT' | translate}}</mat-label>
            <!-- <input disabled readonly formControlName="pit_code" matInput maxlength="24" placeholder="{{'TRAIN_MODULE.PIT' | translate}}"> -->
            <mat-select formControlName="pit_code" >
              <mat-option [value]="pit.code" *ngFor="let pit of pitCodes">{{pit.name}} - {{pit.code}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- PAX -->
      </div>
      <!--
      <div class="row text-left">
        <div class="col-lg-1">
          <mat-slide-toggle formControlName="roundTrip">
            {{'TRAIN_MODULE.A/R' | translate}}
          </mat-slide-toggle>
        </div>
      </div>
      -->
    </form>
    <!--
    <div class="row text-right search-warn" *ngIf="!travellersLoad()">{{ 'TRAIN_MODULE.FORM.REQUIRED' | translate }}</div>
    -->
    <div class="row pt-2">
      <div class="col-md-6 align-self-center">
        <div class="row">
          <div *ngFor="let t of travellers; let i = index" class="col-md-auto">
            <span *ngIf="t.name" class="singleiata">
                {{t.name}} {{t.surname}}
              <span (click)="removePax(i)" aria-hidden="true" class="cancelicon">&times;</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 align-self-center text-right formActionsButtons">
        <!--<button class="mr-1" color="warn" (click)="resetForm()" mat-raised-button type="button">
          {{'TRAIN_MODULE.RESET'|translate}}
        </button>-->
        <button (click)="toggleSearchForm()" mat-flat-button color="primary" [disabled]="!isFormValid()" form="frmSearch" class="newButtonPrimary"
                type="submit">
          {{'TRAIN_MODULE.FORM.SEARCH' | translate}}
        </button>
        <div class="text-rigth pt-2">
          <span (click)="togglePaxModal()" style="font-size:12px;" class="pointer text-danger" *ngIf="!travellersLoad()">
            {{ 'TRAIN_MODULE.FORM.REQUIRED' | translate }}
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-container *ngIf="searchResults || (searching$ | async)">
  <train-selected-booking-summary *ngIf="currentSearch?.departure_station" [arrivalStation]="currentSearch?.arrival_station.name"
                                  [currentTravel]="trainTravel" [departureStation]="currentSearch?.departure_station.name">
  </train-selected-booking-summary>

  <button class="mainbg mx-2" mat-stroked-button  (click)="toggleFilters()" *ngIf="searchResults" [hidden]="!filterButton">
    {{'Filtra' | translate}}
  </button>
  <train-searchresult *ngIf="!(searching$ | async) else loadingSearchResults" [searchResults]="searchResults"
                      (nextStepEvent)="nextStepEvent.emit($event)" style="margin-top: 10px;">
  </train-searchresult>
</ng-container>
<ng-template #loadingSearchResults>
  <div class="row" style="margin-top: 10px;">
    <div class="col-xs-12 col-lg-12">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</ng-template>
