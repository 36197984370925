<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <div class="cardPnr marginbottom10" *ngIf="!loading && reservation">
    <div class="padding20">
      <div class="row">
        <div class="col-md-4 align-self-center">
          <div class="reservationimage">
            <img class="rentCpIcon" style="margin:0" src="{{reservation.rentCompany.images[0]}}">
          </div>
          <div class="smalltext">{{'RENTAL_DURATION'|translate}}: {{numberOfDays}} <span *ngIf="numberOfDays == 1">{{'DAY'|translate}}</span>
            <span *ngIf="numberOfDays > 1"> {{'DAYS'|translate}} </span>
          </div>
          <div class="reservationPrice marginbottom5">
            <div class="smalltext" *ngFor="let rate of reservation.car.rates">
              {{rate.type}}: {{rate.amount|number:'.2-2'}}€
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center mb-2">
          <div class="mb-3" style="text-transform:uppercase;">{{reservation.car.model}}</div>
          <div class="mb-3 align-self-center">
            <span class="pnrNewStatus initNew" *ngIf="reservation.status==='init'">
              <span class="vertical material-icons mr-1"> warning </span>
              {{'TEMPLATE'|translate}}
            </span>
            <span class="pnrNewStatus linkedNew" *ngIf="reservation.status==='linked'">
              <span class="vertical material-icons mr-1"> check_circle </span>
              {{'LINKED'|translate}}
            </span>
            <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='issued'">
              <span class="vertical material-icons mr-1"> check_circle_outline </span>
              {{'ISSUED'|translate}}
            </span>
            <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='flown'">
              <span class="vertical material-icons mr-1"> check_circle_outline </span>
              {{'FLOWN'|translate}}
            </span>
            <span class="pnrNewStatus expiredNew" *ngIf="reservation.status==='expired'">
              <span class="vertical material-icons mr-1"> block </span>
              {{'EXPIRED'|translate}}
            </span>
            <span class="pnrNewStatus cancelledNew" *ngIf="reservation.status==='cancelled'">
              <span class="vertical material-icons mr-1"> block </span>
              {{'CANCELLED'|translate}}
            </span>
          </div>
          <div class="pnrNewStatus p-0">
            <!--<span class="init" *ngIf="reservation.status==='init' && !reservation.waitingList">{{'TEMPLATE'|translate}}</span>
            <span class="init" *ngIf="reservation.status==='init' && reservation.waitingList">{{'WAITING_CONFIRM'|translate}}</span>
            <span class="linked" *ngIf="reservation.status==='linked'">{{'LINKED'|translate}}</span>
            <span class="issued" *ngIf="reservation.status==='issued'">{{'ISSUED'|translate}}</span>
            <span class="flown" *ngIf="reservation.status==='flown'">{{'FLOWN'|translate}}</span>
            <span class="expired" *ngIf="reservation.status==='expired'">{{'EXPIRED'|translate}}</span>
            <span class="cancelled" *ngIf="reservation.status==='cancelled'">{{'CANCELLED'|translate}}</span>-->
            <div *ngIf="reservation.chainLocators.length !== 0" class="pnrlocatorNew" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
              <span>{{reservation.locator}}</span>
            </div>
            <div *ngIf="reservation.changed" class="variationpnr">
              <mat-icon>warning</mat-icon> {{'CHANGE_PNR'|translate}}
            </div>
          </div>
          <div *ngIf="reservation.chainLocators && reservation.chainLocators[0]" class="smalltext" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
            <span>Provider locator: {{reservation.chainLocators[0].locator}}</span>
          </div>
        </div>
        <div class="col-md-4 align-self-center companydetails">
          <div><a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a></div>
          <div>
            <mat-icon>subdirectory_arrow_right</mat-icon>
            <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
          </div>
          <hr/>
          <div *ngIf="reservation.status=='issued' && reservation.costCenter">
            {{'COST_CENTER'|translate}}: {{reservation.costCenter}}
          </div>
          <div class="costCenterInputing pnrActionsButtons" *ngIf="(reservation.status!='cancelled' && reservation.status!='issued') && costCenter">
            <div class="costTilte mb-2">{{'COST_CENTER_TEXT'|translate}}</div>
            <mat-form-field class="mr-2">
              <mat-label>{{'COST_CENTER'|translate}}</mat-label>
              <mat-select [(ngModel)]="reservation.costCenter">
                <mat-option [value]="null" style="color:#b01818;"> {{'NONE'|translate}} </mat-option>
                <mat-option (click)="openCostCenterDialog()" style="color:#4d79f6;">+ {{'ADD_COST_CENTER'|translate}}</mat-option>
                <mat-option *ngFor="let cost of costCenter" [value]="cost.description">
                  {{cost.tag}} -> {{cost.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="updateCostCenter()" [disabled]="updating">{{'UPDATE'|translate}}</button>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-md-4 align-self-center mb-2">
          <div class="travelinfo">
            <div>
              <div class="carLocations">
                <div class="carLocation">
                  <div class="dateAtLocation">
                    {{returnDate(reservation.car.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                  <span class="material-icons"> gps_fixed </span>
                  {{reservation.car.pickUp}}
                </div>
                <div class="rotateArrow">
                  <span class="material-icons"> arrow_right_alt </span>
                </div>
                <div class="carLocation">
                  <span class="material-icons"> gps_fixed </span>
                  {{reservation.car.dropOff}}
                  <div class="dateAtLocation">
                    {{returnDate(reservation.car.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center">
          <div *ngIf="reservation.passengers.length>0">
            <div *ngFor="let passenger of reservation.passengers" class="listpnrsinglepasseng">
              <span *ngIf="passenger.surname">{{passenger.surname}}</span>
              <span *ngIf="passenger.name"> {{passenger.name}}</span>
              <sup> ({{passenger.type}})</sup>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center pnrActionsButtons">

          <!--<button mat-icon-button [matMenuTriggerFor]="dossierMenu" *ngIf="reservation.paper" matTooltip="{{'DOSSIER'|translate}}">
            <mat-icon>folder</mat-icon>
          </button>
          <mat-menu #dossierMenu="matMenu">
            <button mat-menu-item routerLink="/dossier/list/{{reservation.paper}}">
              {{'GO_TO_DOSSIER'|translate}}
            </button>
            <button mat-menu-item (click)="toggleRemoveDossierModal()">
              {{'REMOVE_FROM_DOSSIER'|translate}}
            </button>
          </mat-menu>
          <button mat-icon-button *ngIf="!reservation.paper" (click)="toggleAddtoTravel()" matTooltip="{{'ADD_TO_FOLDER'|translate}}">
            <mat-icon>create_new_folder</mat-icon>
          </button>
          <button *ngIf="reservation.status!='cancelled'" mat-icon-button (click)="togglePrintCar()" matTooltip="{{'PRINT'|translate}}">
            <mat-icon>print</mat-icon>
          </button>
          <button [disabled]="!reservation.creditcard || booking" mat-raised-button color="primary" (click)="bookCar()" *ngIf="reservation.status==='init' && checkPolicy()"> &lt;!&ndash; && customAuth.canBook(mainComponent.loggedUser.company._id) &ndash;&gt;
            {{'BOOK'|translate}}
          </button>

          <button *ngIf="reservation.status === 'init' && !checkPolicyRequest() && !reservation.approvalId" mat-raised-button
                  color="primary"
                  (click)="requestAuthorization()">
            {{'REQUEST_AUTHORIZATION'|translate}}
            <span class="material-icons">
                    arrow_right_alt
                  </span>
          </button>-->

          <button [disabled]="!reservation.creditcard || booking" mat-raised-button color="primary" (click)="bookCar()"
                  *ngIf="reservation.status==='init' && checkPolicy()">
            {{'BOOK'|translate}}
          </button>
          <button *ngIf="reservation.approvalId && reservation.status === 'init' && !checkPolicy() && transferApproved() && (search && search.status == 'approved')"
                  mat-raised-button color="primary" (click)="bookCar()" [disabled]="!reservation.creditcard || booking">
            {{'BOOK'|translate}}
            <span class="material-icons"> arrow_right_alt </span>
          </button>
          <button *ngIf="!reservation.approvalId && reservation.status === 'init' && !checkPolicy() && !isToNotify()"
                  mat-raised-button color="primary" (click)="requestAuthorization()">
            {{'REQUEST_AUTHORIZATION'|translate}}
            <span class="material-icons"> arrow_right_alt </span>
          </button>
          <button *ngIf="!reservation.approvalId && reservation.status === 'init' && !checkPolicy() && isToNotify()"
                  mat-raised-button color="primary" (click)="bookAndNotify()" matTooltip="{{'POLICY.WILL_NOTIFY'|translate}}"
                  [matTooltipPosition]="'above'" [disabled]="!reservation.creditcard || booking">
            {{'POLICY.BUY_AND_NOTIFY'|translate}}
            <span class="material-icons"> arrow_right_alt </span>
          </button>

          <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt"
                                  [arrayOfAlerts]="reservation.arrayOfAlerts">
          </app-policy-alerts-icon>

          <!--<button mat-raised-button (click)="toggleDeleteModal()" color="warn" *ngIf="reservation.status=='linked' || reservation.status=='issued'">
            {{'DELETE_RESERVATION'|translate}}
            <span class="material-icons">
              delete_outline
            </span>
          </button>-->
          <button mat-icon-button color="warn"  *ngIf="reservation.status=='linked' || reservation.status=='issued'" (click)="toggleDeleteModal()"
                  matTooltip="{{'DELETE_RESERVATION'|translate}}">
            <mat-icon>delete_outline</mat-icon>
          </button>
          <div class="pt-2 mb-1 smalltext text-danger" *ngIf="!transferApproved()">Trasferta non ancora autorizzata</div>
          <div class="mb-1 smalltext text-warning"
               *ngIf="reservation.approvalId && reservation.status === 'init' && !checkPolicy() && (search && search.status == 'pending')">
            {{'POLICY.AUTHORIZATIONS_SENT'|translate}}
          </div>
          <div class="smalltext" *ngIf="search && reservation.approvalId && reservation.status === 'init' && !checkPolicy() && search">
            <div class="mb-1">
              {{'POLICY.RECEIVED_REQUEST.STATUS'|translate}}
              <span class="text-warning" *ngIf="search.status === 'pending'">{{search.status|uppercase|translate}}</span>
              <span class="text-success" *ngIf="search.status === 'approved'">{{search.status|uppercase|translate}}</span>
              <span class="text-danger" *ngIf="search.status === 'declined'">{{search.status|uppercase|translate}}</span>
            </div>
            <div *ngIf="search.status === 'declined'">
              <div *ngFor="let auth of search.userAuthorisations">
                <ng-container *ngIf="auth.status === 'declined'">
                  <div>{{'POLICY.POLICY_NOTE'|translate}} <span class="text-danger">{{auth.note}}</span></div>
                </ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="reservation.status === 'init' && !reservation.creditcard && checkPolicy()" class="pt-2 small text-danger">
            <span class="material-icons">warning_amber</span> {{'PLEASE_SELECT_PAYMENT'|translate}}
          </div>
          <div *ngIf="reservation.status === 'init' && !reservation.creditcard && !checkPolicy() && (search && search.status == 'approved') && reservation.approvalId"
               class="pt-2 small text-danger">
            <span class="material-icons">warning_amber</span> {{'PLEASE_SELECT_PAYMENT'|translate}}
          </div>
          <div *ngIf="booking">
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </div>
          <!--
          <button mat-raised-button *ngIf="reservation.status==='init' && !reservation.waitingList && !customAuth.canBook(mainComponent.loggedUser.company._id)" color="primary" (click)="askBook()">{{'REQUEST_EMISSION'|translate}}</button>
          <p *ngIf="reservation.status==='init' && reservation.waitingList">In attesa di conferma</p>
          <p style="font-size:12px;" *ngIf="reservation.status==='init' && reservation.waitingList">Alla conferma riceverai via mail la prenotazione o sarai contattato in caso di problemi</p>
          -->
          <!--
          <button mat-raised-button color="warn" *ngIf="reservation.status=='linked' || reservation.status=='issued'">
            {{'DELETE_RESERVATION'|translate}}
            <span class="material-icons">
              delete_outline
            </span>
          </button> -->
        </div>
      </div>
    </div>
  </div>

  <div class="newPnrPagination" *ngIf="!loading && reservation">
    <div (click)="setTab(i)" class="singleNewVoice" [ngClass]="{'activeNew':tab.active}" *ngFor="let tab of tabs; let i = index">
      {{tab.name|translate}}
    </div>
    <!--<div (click)="setTab(i)" class="singleVoice" [ngClass]="{'active':tab.active}" *ngFor="let tab of tabs; let i = index">
      <mat-icon *ngIf="tab.name==='CAR_INFOS'">drive_eta</mat-icon>
      <mat-icon *ngIf="tab.name==='DRIVERS'">directions_walk</mat-icon>
      <mat-icon *ngIf="tab.name==='SERVICES'">miscellaneous_services</mat-icon>
      <mat-icon *ngIf="tab.name==='PAYMENT'">credit_card</mat-icon>
      <mat-icon *ngIf="tab.name==='PNR'">format_list_numbered</mat-icon>
      <span class="tabname"> {{tab.name|translate}}</span>
    </div>-->
  </div>
  <div class="newTabHeader" *ngIf="!loading && reservation">
    <div class="row">
      <div class="col-6 align-self-center">{{currentTab|translate}}</div>
      <div class="col-6 align-self-center text-right">
        <div class="newTabButtons">
          <button mat-icon-button [matMenuTriggerFor]="dossierMenu" *ngIf="reservation.paper" matTooltip="{{'DOSSIER'|translate}}">
            <mat-icon>folder</mat-icon>
          </button>
          <mat-menu #dossierMenu="matMenu">
            <button mat-menu-item routerLink="/dossier/list/{{reservation.paper}}">
              {{'GO_TO_DOSSIER'|translate}}
            </button>
            <button mat-menu-item (click)="toggleRemoveDossierModal()">
              {{'REMOVE_FROM_DOSSIER'|translate}}
            </button>
          </mat-menu>
          <button mat-icon-button *ngIf="!reservation.paper" (click)="toggleAddtoTravel()" matTooltip="{{'ADD_TO_FOLDER'|translate}}">
            <mat-icon>create_new_folder</mat-icon>
          </button>
          <button *ngIf="reservation.status!='cancelled'" mat-icon-button (click)="togglePrintCar()" matTooltip="{{'PRINT'|translate}}">
            <mat-icon>print</mat-icon>
          </button>
          <!--
          <button mat-raised-button *ngIf="!reservation.paper && (reservation.status=='linked' || reservation.status=='issued')" (click)="toggleAddtoTravel()">
            {{'ADD_TO_FOLDER'|translate}}
          </button> -->
          <!--<button mat-icon-button *ngIf="!reservation.paper && (reservation.status=='linked' || reservation.status=='issued')  && (appComponent.isSbt || appComponent.isB2B || mainComponent.loggedUser.role.superAdmin) && !appComponent.isGat"
                  (click)="toggleAddtoTravel()"
                  matTooltip="{{'ADD_TO_FOLDER'|translate}}">
            <mat-icon>create_new_folder</mat-icon>
          </button>
          <button *ngIf="!reservation.changed && reservation.status!='init' && reservation.status!='cancelled'" mat-icon-button (click)="togglePrint()" matTooltip="{{'PRINT'|translate}}">
            <mat-icon>print</mat-icon>
          </button>
          <button *ngIf="!reservation.changed && reservation.status!='init' && reservation.status!='cancelled'" mat-icon-button (click)="toggleSendModal()" matTooltip="{{'SEND_BY_MAIL'|translate}}">
            <mat-icon>forward_to_inbox</mat-icon>
          </button>
          <button *ngIf="reservation.status!='init' && reservation.status!='cancelled'" mat-icon-button (click)="toggleNotes()" matTooltip="{{'ADD_COSTUMER_NOTES'|translate}}">
            <mat-icon>note_alt</mat-icon>
          </button>-->
        </div>
      </div>
    </div>
  </div>

  <div class="card marginbottom20 padding10" *ngIf="!loading && reservation">
    <div [hidden]="currentTab!='CAR_INFOS'">
      <!--{{'CAR_INFOS'|translate}}-->
      <div class="row singleStepSr">
        <div class="col-md-6 col-sm-12 text-center align-self-center">
          <div id="map"></div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div>
            <img class="carImage" *ngIf="reservation.car.images[0]!='https://none'" src="{{reservation.car.images[0]}}"/>
            <div class="carImage" *ngIf="reservation.car.images[0]=='https://none'">
              <img class="carImage" src="/assets/img/cars/nophoto.png"/>
            </div>
          </div>
          <div class="middleCarCol">
            <div class="carModel mb-2">
              <div>{{reservation.car.type}}</div>
              <div>
                <b>{{reservation.car.model}}</b> <sup> {{legend.CATHEGORY[reservation.car.type[0]]}}</sup>
              </div>
            </div>
            <div class="veicleIcons">
              <span class="singleIcon">
                <img *ngIf="reservation.car.type[1]=='B' || reservation.car.type[1]=='C' || reservation.car.type[1]=='D'" src="assets/img/cars/doors.svg"/>
                {{legend.TYPE[reservation.car.type[1]]}}
                <span *ngIf="reservation.car.type[1]=='B' || reservation.car.type[1]=='C' || reservation.car.type[1]=='D'"> {{'DOORS'|translate}} </span>
              </span>
              <span class="singleIcon" *ngIf="reservation.car.type[2]=='A' || reservation.car.type[2]=='B' || reservation.car.type[2]=='D'">
                <img src="assets/img/cars/transmission.svg"/>
                <span matTooltip="{{'AUTOMATIC_TRANSMISSION'|translate}}">{{'AUTOMATIC_TRANSMISSION'|translate}}</span>
              </span>
              <span class="singleIcon" *ngIf="reservation.car.type[2]=='C' || reservation.car.type[2]=='M' || reservation.car.type[2]=='N'">
                <img src="assets/img/cars/transmission.svg"/>
                <span matTooltip="{{'MANUAL_TRANSMISSION'|translate}}">{{'MANUAL_TRANSMISSION'|translate}}</span>
              </span>
              <span class="singleIcon" *ngIf="returnAir(reservation.car.type[3])">
                <img src="assets/img/cars/airconditioning.svg"/>
                <span matTooltip="{{'AIR_CONDITIONING'|translate}}">{{'AIR_CONDITIONING'|translate}}</span>
              </span>
              <span class="singleIcon">
                <img src="assets/img/cars/fuel.png"/>
                {{legend.FUEL_AND_AC[reservation.car.type[3]]}}
              </span>
            </div>
            <hr/>
            <div class="carLocations">
              <div class="carLocation">
                <div class="dateAtLocation">
                  {{returnDate(reservation.car.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                </div>
                <span class="material-icons"> gps_fixed </span>
                {{reservation.car.pickUp}}
              </div>
              <div class="rotateArrow">
                <span class="material-icons"> arrow_right_alt </span>
              </div>
              <div class="carLocation">
                <span class="material-icons"> gps_fixed </span>
                {{reservation.car.dropOff}}
                <div class="dateAtLocation">
                  {{returnDate(reservation.car.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='DRIVERS'">
      <!--{{'DRIVERS'|translate}}-->
      <div class="row buttonrow">
        <div class="col-lg-6 mb-3" *ngFor="let tra of reservation.passengers;let i = index">
          <p>{{tra.type|translate}} {{i+1}}</p>
          <div class="fakefield mb-2">
            {{'NAME'|translate}}:
            <span *ngIf="tra.name"> {{tra.name}}</span>
            <span *ngIf="!tra.name"> _ _ _ _</span>
          </div>
          <div class="fakefield mb-2">
            {{'SURNAME'|translate}}:
            <span *ngIf="tra.surname"> {{tra.surname}}</span>
            <span *ngIf="!tra.surname"> _ _ _ _</span>
          </div>
          <div class="fakefield mb-2">
            {{'PHONE'|translate}}:
            <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
            <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
          </div>
          <div class="fakefield mb-2">
            {{'EMAIL'|translate}}:
            <span *ngIf="tra.email"> {{tra.email}}</span>
            <span *ngIf="!tra.email"> _ _ _ _</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='SERVICES'">
      <!--{{'SERVICES'|translate}}-->
      <div class="padding10">
        <ol class="carServices">
          <li class="singleCarService" *ngFor="let service of reservation.car.services">
            <span *ngIf="service.type">{{types[service.type]}} </span>
            <span *ngIf="service.period">{{period[service.period]}} </span>
            <span *ngIf="service.text">{{service.text}} </span>
            <span *ngIf="service.amount"> {{service.amount|number:'.2-2'}}€</span>
          </li>
        </ol>
      </div>
    </div>

    <div *ngIf="currentTab=='PAYMENT'">
      <!--{{'PAYMENT'|translate}}-->
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="onPaymentTabChanged($event);">
        <mat-tab *ngIf="reservation.company.services.paymentMethods" label="{{'FOPS'|translate}}">
          <br/>
          <app-methods [id]="reservation.company._id" [reservation]="reservation" [resType]="'car'"></app-methods>
        </mat-tab>
        <mat-tab *ngIf="reservation.company.services.creditCards" label="{{'CREDIT_CARDS'|translate}}">
          <br/>
          <app-fops [id]="reservation.company._id" [reservation]="reservation"></app-fops>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div *ngIf="currentTab=='PNR'">
      <!--{{'PNR'|translate}}-->
      <app-cartst [reservationId]="reservation._id"></app-cartst>
    </div>
  </div>

  <div *ngIf="reservation && reservation.paper" class="modal fade" id="RemoveDossierModal" tabindex="-1" role="dialog" data-backdrop="static"
       aria-labelledby="RemoveDossierModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="top:70px" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="RemoveDossierModalTitle">{{'REMOVE_FROM_DOSSIER'|translate}} ?</h5>
          <button type="button" class="close" (click)="toggleRemoveDossierModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <div class="mb-3">{{'CONFIRM_REMOVE_DOSSIER'|translate}}?</div>
        </div>
        <hr/>
        <div class="text-right p-2 buttonrowBis">
          <button type="button" mat-button (click)="toggleRemoveDossierModal()">{{'ABORT'|translate}}</button>
          <button type="button" mat-button (click)="confirmDossierRemove()">{{'CONFIRM'|translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="travelListModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="travelListModal" aria-hidden="true">
    <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addGuestModalTitle">
            {{'ADD_TO_FOLDER'|translate}}
          </h5>
          <button type="button" class="close" (click)="toggleAddtoTravel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <app-dossierlist page="car" [idCar]="pnrId" (close)="closeAddtoTravel()"></app-dossierlist>
        </div>
        <hr/>
        <div class="text-right buttonrowBis p-2">
          <button type="button" mat-button (click)="toggleAddtoTravel()">{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deletePnrModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="deletePnrModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="top:70px" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deletePnrModalTitle">{{'DELETE_RESERVATION'|translate}} ?</h5>
          <button *ngIf="!deleting" type="button" class="close" (click)="toggleDeleteModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2" *ngIf="reservation">
          <div class="mb-3">{{'DELETE_RESERVATION_QUESTION'|translate}}</div>
          <!-- <div>{{'DELETE_RESERVATION_TEXT'|translate}}</div> -->
        </div>
        <hr/>
        <div class="text-right p-2 buttonrowBis" *ngIf="!deleting">
          <button type="button" mat-button (click)="toggleDeleteModal()">{{'ABORT'|translate}}</button>
          <button type="button" mat-button (click)="deleteReservation()">{{'DELETE_RESERVATION'|translate}}</button>
        </div>
        <div class="text-right p-2" *ngIf="deleting">
          <div class="mb-2" >{{'LOADING'|translate}}</div>
          <mat-progress-bar style="max-width: 200px;display:inline-block;" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="reservation" class="modal fade" id="PrintModalCar" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="PrintModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'PRINT'|translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
              <button mat-button (click)="print()"> {{'PRINT'|translate}} <mat-icon>print</mat-icon> </button>
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-12">
              Preview
              <div class="padding20" style="overflow-x:auto">
                <div id="toPrint" *ngIf="reservation">
                  <div class="row">
                    <div class="col-4">
                      <img class="companyImage" [src]="reservation.company.image"/>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-4">
                      <div class="mb-1">
                        <mat-icon class="mr-2">store</mat-icon> {{reservation.company.name}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">call</mat-icon> {{reservation.company.phoneNumber}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">forward_to_inbox</mat-icon> {{reservation.company.email}}
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div class="mb-3">
                    <img class="mb-3" style="margin:0" src="{{reservation.rentCompany.images[0]}}">
                    <div class="row">
                      <div class="col-6 align-self-center">
                        <img class="carImage" *ngIf="reservation.car.images[0]!='https://none'" src="{{reservation.car.images[0]}}"/>
                        <div class="carImage" *ngIf="reservation.car.images[0]=='https://none'">
                          <img class="carImage" src="/assets/img/cars/nophoto.png"/>
                        </div>
                      </div>
                      <div class="col-6 align-self-center">
                        <div *ngIf="reservation.chainLocators" class="pnrlocator" [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                          <span>{{reservation.locator}}</span>
                        </div>
                        <div *ngIf="reservation.chainLocators && reservation.chainLocators[0]" class="smalltext"
                             [ngClass]="{'marginbottom5':reservation.status==='linked'}">
                          <span>Provider locator: {{reservation.chainLocators[0].locator}}</span>
                        </div>
                        <div class="carModel mb-2">
                          <div>{{reservation.car.type}}</div>
                          <div><b>{{reservation.car.model}}</b> <sup> {{legend.CATHEGORY[reservation.car.type[0]]}}</sup></div>
                        </div>
                        <div class="veicleIcons">
                          <span class="singleIcon">
                            <img *ngIf="reservation.car.type[1]=='B' || reservation.car.type[1]=='C' || reservation.car.type[1]=='D'"
                                 src="/assets/img/cars/doors.svg"/>
                            {{legend.TYPE[reservation.car.type[1]]}}
                            <span *ngIf="reservation.car.type[1]=='B' || reservation.car.type[1]=='C' || reservation.car.type[1]=='D'">
                              {{'DOORS'|translate}}
                            </span>
                          </span>
                          <span class="singleIcon" *ngIf="reservation.car.type[2]=='A' || reservation.car.type[2]=='B' || reservation.car.type[2]=='D'">
                            <img src="/assets/img/cars/transmission.svg"/>
                            <span matTooltip="{{'AUTOMATIC_TRANSMISSION'|translate}}">{{'AUTOMATIC_TRANSMISSION'|translate}}</span>
                          </span>
                          <span class="singleIcon" *ngIf="reservation.car.type[2]=='C' || reservation.car.type[2]=='M' || reservation.car.type[2]=='N'">
                            <img src="/assets/img/cars/transmission.svg"/>
                            <span matTooltip="{{'MANUAL_TRANSMISSION'|translate}}">{{'MANUAL_TRANSMISSION'|translate}}</span>
                          </span>
                          <span class="singleIcon" *ngIf="returnAir(reservation.car.type[3])">
                            <img src="/assets/img/cars/airconditioning.svg"/>
                            <span matTooltip="{{'AIR_CONDITIONING'|translate}}">{{'AIR_CONDITIONING'|translate}}</span>
                          </span>
                          <span class="singleIcon">
                            <img src="/assets/img/cars/fuel.png"/>
                            {{legend.FUEL_AND_AC[reservation.car.type[3]]}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div class="row">
                    <div class="col-4">
                      <div>
                        <div class="carLocations">
                          <div class="carLocation">
                            <div class="dateAtLocation">
                              {{returnDate(reservation.car.pickUpInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                            </div>
                            <span class="material-icons"> gps_fixed </span>
                            {{reservation.car.pickUp}}
                          </div>
                          <div class="rotateArrow">
                            <span class="material-icons"> arrow_right_alt </span>
                          </div>
                          <div class="carLocation">
                            <span class="material-icons"> gps_fixed </span>
                            {{reservation.car.dropOff}}
                            <div class="dateAtLocation">
                              {{returnDate(reservation.car.dropOffInfo.date)|date:'dd/MM/yyyy - HH:mm'}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div *ngIf="reservation.passengers.length>0">
                        <div class="mb-3">{{'DRIVERS'|translate}}</div>
                        <div *ngFor="let passenger of reservation.passengers" class="listpnrsinglepasseng">
                          <span *ngIf="passenger.surname">{{passenger.surname}}</span>
                          <span *ngIf="passenger.name"> {{passenger.name}}</span>
                          <sup> ({{passenger.type}})</sup>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="reservationPrice marginbottom5">
                          <span class="recoprice">
                            <div class="smalltext">{{'RENTAL_DURATION'|translate}}: {{numberOfDays}}
                              <span *ngIf="numberOfDays == 1">{{'DAY'|translate}}</span>
                              <span *ngIf="numberOfDays > 1"> {{'DAYS'|translate}} </span>
                            </div>
                            <div class="smalltext" *ngFor="let rate of reservation.car.rates">
                              {{rate.type}}: {{rate.amount|number:'.2-2'}}€
                            </div>
                          </span>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div class="mb-2">
                    <h5>{{'SERVICES'|translate}}</h5>
                    <div class="hotelServices">
                      <ol class="carServices">
                        <li class="singleCarService" *ngFor="let service of reservation.car.services">
                          <span *ngIf="service.type">{{types[service.type]}} </span>
                          <span *ngIf="service.period">{{period[service.period]}}</span>
                          <span *ngIf="service.text">{{service.text}}</span>
                          <span *ngIf="service.amount"> {{service.amount|number:'.2-2'}}€</span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
