<div class="row">
  <div class="col-12">
    <p class="flightTitle">{{'POLICY.FLIGHT_.TITLE'|translate}}</p>
    <div class="row newItemPnr itineraryTxt" *ngFor="let itinerary of productDetails.itineraries;let l = last">
      <div class="col-5" *ngIf="itinerary.flights[0]">
        <b>{{itinerary.flights[0].departure.dateTime|date:'dd/MM/yyyy - HH:mm':"+0000"}}</b>
        <br/>
        <span>
          {{airports[itinerary.flights[0].departure.location]}}
          <sup><b>{{itinerary.flights[0].departure.location}}</b></sup>
        </span>
      </div>
      <div class="col-2 align-self-center" *ngIf="itinerary.flights[itinerary.flights.length-1]">
        <span class="material-icons">arrow_right_alt</span>
      </div>
      <div class="col-5" *ngIf="itinerary.flights[itinerary.flights.length-1]">
        <b>{{itinerary.flights[itinerary.flights.length-1].arrival.dateTime|date:'dd/MM/yyyy - HH:mm':"+0000"}}</b>
        <br/>
        <span>
          {{airports[itinerary.flights[itinerary.flights.length-1].arrival.location]}}
          <sup><b>{{itinerary.flights[itinerary.flights.length-1].arrival.location}}</b></sup>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 d-flex align-self-center justify-content-end my-2">
    <b class="mr-2">{{'POLICY.FLIGHT_.PRICE'|translate}}</b>   {{productDetails.fare.amount}}
    <app-policy-alerts-icon [isB2B]="true" [isSbt]="true" [arrayOfAlerts]="search.alerts" ></app-policy-alerts-icon>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <hr *ngIf="search.status == 'declined' || search.status == 'approved'">
    <div class="row" > <!--*ngIf="search.status ==='declined' || search.status == 'approved'"-->
      <div class="col-6" *ngIf="search.authorisations.length > 0; else showAlerts">
        <div *ngIf="search.status === 'approved'">
          <b> {{'POLICY.APPROVED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.status === 'declined' && search.authorisations.length > 0">
          <b> {{'POLICY.DECLINED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.authorisations.length>0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.authorisations">
            <span> - {{authorization.note}} </span><br>
          </span>
        </div>
      </div>
      <ng-template #showAlerts>
        <div class="col-6" *ngIf="search.alerts.length > 0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.alerts">
            <span> - {{authorization.message}} </span><br>
          </span>
        </div>
      </ng-template>
      <div class="col-6">
        <div class="row" *ngIf="search.authorisations.length>0">
          <div class="col-12">
            <b> {{'NOTES'|translate}} : </b>
          </div>
          <div class="col-12" *ngFor="let authorization of search.authorisations">
            <span> - {{authorization.userResponeNote}} </span><br>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 py-2">
    <hr>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5 class="" *ngIf="productDetails.itineraries.length > 1">
              <p class="subtitles">{{'POLICY.FLIGHT_.ITINERARIES'|translate}}</p>
            </h5>
            <h5 class="" *ngIf="productDetails.itineraries.length == 1">
              <p class="subtitles">{{'POLICY.FLIGHT_.ITINERARY'|translate}}</p>
            </h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-6" *ngFor="let itinerary of productDetails.itineraries">
            <p><b>{{'POLICY.FLIGHT_.FLYING_TIME'|translate}}  </b>{{ getFlyingTIme(itinerary.flyingTime) }}  {{'POLICY.FLIGHT_.HOURS'|translate}}</p>
            <div class="my-2 mx-1" *ngFor="let flight of itinerary.flights; let i = index">
              <div>
                <b>{{'POLICY.FLIGHT_.DEPARTURE_LOCATION'|translate}}</b>
                <br>
                {{airports[flight.departure.location]}} - ({{flight.departure.location}})
              </div>
              <div>
                <b >{{'POLICY.FLIGHT_.DEPARTURE_DATE'|translate}}</b>
                {{flight.departure.dateTime|date:'dd/MM/yyyy H:mm':'+0000'}}
              </div>
              <div>
                <b>{{'POLICY.FLIGHT_.ARRIVAL_LOCATION'|translate}}</b>
                <br>
                {{airports[flight.arrival.location]}} - ({{flight.arrival.location}})
              </div>
              <div>
                <b>{{'POLICY.FLIGHT_.ARRIVAL_DATE'|translate}}</b>
                {{flight.arrival.dateTime|date:'dd/MM/yyyy H:mm':'+0000'}}
              </div>
              <hr *ngIf="i !== itinerary.flights.length-1">
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mt-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="subtitles" *ngIf="productDetails.passengers > 1; else singlePax">{{'POLICY.FLIGHT_.PASSENGERS'|translate}}</p>
            <ng-template #singlePax>
              <p class="subtitles">{{'POLICY.FLIGHT_.PASSENGER'|translate}}</p>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
          <div class="row">
            <div class="col-6" *ngFor="let pax of productDetails.passengers; let i = index">
              <div class="">
                <b>{{'POLICY.FLIGHT_.PASSENGER_NUMBER'|translate}}: </b>{{i+1}}
              </div>
              <div class="">
                <b>{{'NAME'|translate}}: </b>{{pax.name}}
              </div>
              <div class="">
                <b>{{'SURNAME'|translate}}: </b>{{pax.surname}}
              </div>
              <div class="">
                <b>{{'EMAIL'|translate}}: </b>{{pax.email}}
              </div>
              <div class="">
                <b>{{'MOBILE_PHONE_NUMBER'|translate}}: </b> {{pax.phoneNumber}}
              </div>
            </div>
          </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
