<div class="row">
  <div class="col-12">
    <p class="HotelName">{{'POLICY.HOTEL_.TITLE'|translate}}: {{productDetails.hotel.name}}</p>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-6">
    <p class="subtitles">{{'POLICY.HOTEL_.INFO.TITLE'|translate}}: </p>
    <div class="">
      <div class="">
        <b>{{'POLICY.HOTEL_.CHECKIN'|translate}}:</b> {{productDetails.hotel.checkIn|date:'EEEE, d MMM y'|titlecase}}
      </div>
      <div class="">
        <b>{{'POLICY.HOTEL_.CHECKOUT'|translate}}:</b> {{productDetails.hotel.checkOut|date:'EEEE, d MMM y'|titlecase}}
      </div>
      <div class="">
        <b>{{'POLICY.HOTEL_.INFO.CITY'|translate}}:</b> {{productDetails.hotel.city}}
      </div>
      <div class="">
        <b>{{'POLICY.HOTEL_.INFO.CODE'|translate}}:</b> {{productDetails.hotel.code}}
      </div>
    </div>
  </div>
  <div class="col-6">
    <p class="subtitles">{{'POLICY.HOTEL_.ROOM.TITLE'|translate}}:</p>
    <div class="">
      <div class="">
        <b>{{'POLICY.HOTEL_.ROOM.PRICE'|translate}}:</b> &euro; {{productDetails.room.amount}}</div>
        <app-policy-alerts-icon [isB2B]="true" [isSbt]="true" [arrayOfAlerts]="search.alerts" ></app-policy-alerts-icon>
      <div class="">
        <b>{{'POLICY.HOTEL_.ROOM.TITLE'|translate}} {{'POLICY.HOTEL_.CHECKIN'|translate}}:</b> {{productDetails.room.startDate|date:'EEEE, d MMM y'|titlecase}}
      </div>
      <div class="">
        <b>{{'POLICY.HOTEL_.ROOM.TITLE'|translate}} {{'POLICY.HOTEL_.CHECKOUT'|translate}}:</b> {{productDetails.room.endDate|date:'EEEE, d MMM y'|titlecase}}
      </div>
    </div>
  </div>

  <div class="col-12">
    <hr *ngIf="search.status == 'declined' || search.status == 'approved'">
    <div class="row">
      <div class="col-6" *ngIf="search.authorisations.length > 0; else showAlerts">
        <div *ngIf="search.status === 'approved'">
          <b> {{'POLICY.APPROVED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.status === 'declined'">
          <b> {{'POLICY.DECLINED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.authorisations.length>0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.authorisations">
            <span> - {{authorization.note}} </span><br>
          </span>
        </div>
      </div>
      <ng-template #showAlerts>
        <div class="col-6" *ngIf="search.alerts.length > 0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.alerts">
            <span> - {{authorization.message}} </span><br>
          </span>
        </div>
      </ng-template>
      <div class="col-6">
        <div class="row" *ngIf="search.authorisations.length>0">
          <div class="col-12">
            <b> {{'NOTES'|translate}} : </b>
          </div>
          <div class="col-6" *ngFor="let authorization of search.authorisations" [ngClass]="{'col-12': search.authorisations.length == 1}">
            <span> - {{authorization.userResponeNote}} </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <hr>
    <mat-accordion>
      <mat-expansion-panel class="my-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="subtitles" *ngIf="productDetails.guests.length > 1; else oneGuestOnly">{{'POLICY.HOTEL_.GUESTS.TITLE'|translate}}:</p>
            <ng-template #oneGuestOnly>
              <p class="subtitles">{{'POLICY.HOTEL_.GUEST'|translate}}:</p>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-6 p-2" *ngFor="let guest of productDetails.guests; let i=index;">
            <div class="">
              <b>{{'POLICY.HOTEL_.GUESTS.NUMBER'|translate}}: </b>{{i+1}}
            </div>
            <div class="">
              <b>{{'NAME'|translate}}: </b>{{guest.name}}
            </div>
            <div class="">
              <b>{{'SURNAME'|translate}}: </b>{{guest.surname}}
            </div>
            <div class="">
              <b>{{'EMAIL'|translate}}: </b>{{guest.email}}
            </div>
            <div class="">
              <b>{{'MOBILE_PHONE_NUMBER'|translate}}: </b> {{guest.phoneNumber}}
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
