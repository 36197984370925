import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class HelperFunctionsService {
  ListSupportedRatesDetails: any[] = [];
  availableLowTarifs:any[] = [];
  currentFee: any = {};
  constructor(
    private readonly translate: TranslateService,
  ) { }

  isSupported(mark){
    for(let i=0; i<this.ListSupportedRatesDetails.length; i++){
      if(mark === this.ListSupportedRatesDetails[i].marketingCarrier){
        return true;
      }
    }
    return false;
  }

  translateString(value:string){
    return this.translate.instant(value);
  }

  getIndex(marketingCarrier){
    for(let i=0; i<this.ListSupportedRatesDetails.length; i++){
      if(marketingCarrier === this.ListSupportedRatesDetails[i].marketingCarrier){
        return this.availableLowTarifs.findIndex(ele => ele.supplier === this.ListSupportedRatesDetails[i].supplier);
      }
    }
  }

  returnAmount(type:string, value:number, paxNumber: number, segments?:number){
    if(this.currentFee){
      if(type === 'flight'){
        if(this.currentFee.flight.feeServiceFor === 'PNR'){
          return value + this.currentFee.flight.amount;
        }
        if(this.currentFee.flight.feeServiceFor === 'SEGMENT'){
          return value + (this.currentFee.flight.amount * segments);
        }
        if(this.currentFee.flight.feeServiceFor === 'PASSENGER'){
          return value + (this.currentFee.flight.amount * paxNumber);
        }
      }
    } else {
      return value;
    }

  }

  returnProfile(){
    return JSON.parse(localStorage.getItem('fl_profile'));
  }

  transformJson(inputJson, passengers) {
    const outputJson = {
      session: {
        SessionId: null,
        SequenceNumber: null,
        SecurityToken: null
      },
      penalties: [],
      refundable: inputJson.refundable || null,
      negotiated: false,
      fromBaggageRequest: false,
      reference: 3,
      message: "", // inizialmente vuoto
      details: [],
      fare: {
        amount: inputJson.totalPrice ? inputJson.totalPrice.amount : null,
        tax: null,
        taxFields: [],
        publicAmount: inputJson.totalPrice ? inputJson.totalPrice.amount : null
      },
      passengers: {},
      bookingClasses: [],
      selected: inputJson.selected || null
    };

    // Riempiamo i dettagli
    /*
    if (inputJson.offerItem) {
      inputJson.offerItem.forEach((item, index) => {
        const detail = {
          tariffClass: null,
          refs: [index + 1],
          name: null,
          qualifier: null,
          fare: null,
          bookingClasses: []
        };
        outputJson.details.push(detail);
      });
    }
    */

    // Riempiamo i passeggeri
    const passengerTypes = ["ADT", "CHD", "INF", 'CNN','CH'];
    passengers.forEach((p) => {
      if (inputJson.baggage && inputJson.baggage[p.type.toLowerCase()]) {
        if(p.type == 'CH' || p.type == 'CNN'){
          p.type = 'CHD';
        }
        outputJson.passengers[p.type.toUpperCase()] = {
          baggage: [
            {
              allowance: inputJson.baggage[p.type.toLowerCase()].qty || 0,
              code: "N"
            },
            {
              allowance: inputJson.baggage[p.type.toLowerCase()].qty || 0,
              code: "N"
            }
          ],
          fare: {
            amount: null,
            tax: null
          },
          refs: p.refs
        };
      } else {
        if(p.type == 'CH' || p.type == 'CNN'){
          p.type = 'CHD';
        }
        outputJson.passengers[p.type.toUpperCase()] = {
          baggage: [
            {
              allowance: 0,
              code: "N"
            },
            {
              allowance: 0,
              code: "N"
            }
          ],
          fare: {
            amount: null,
            tax: null
          },
          refs: p.refs
        };
      }
    });

    // Se ci sono offerte, riempiamo i campi fare e bookingClasses
    if (inputJson.offerItem) {
      inputJson.offerItem.forEach((item, index) => {
        const passengerType = index === 0 ? "ADT" : "CHD";
        if (outputJson.passengers[passengerType]) {
          outputJson.passengers[passengerType].fare.amount = item.price[0].amount;
          outputJson.passengers[passengerType].fare.tax = item.fareDetails[0].price.taxes.total.amount;
        }
        // outputJson.bookingClasses.push("A", "Y");
      });
    }
    let j: any;
    let i: any;
    for (j of inputJson.JourneyPriceClass){
      for (i in j){
        const detail = {
          name:'',
        }
        outputJson.details.push(detail);
        outputJson.bookingClasses.push(j[i].details.tarif.class);
        outputJson.details[i].name =  j[i].details.tarif.name;
        j[i].details.services.forEach(s => outputJson.penalties.push(i +'|'+ s.descId + '|' + s.descText));
      }
    }

    return outputJson;
  }
}

