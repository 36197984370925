import {
  AfterViewInit,
  OnInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  BehaviorSubject,
  of,
} from 'rxjs';
import {
  EvaluateTravelSolutionReq,
  EvaluateTravelSolutionRes,
  SearchBaseResponse,
  SearchTrainsRequest,
  StationResponse,
  TrainOffer,
  TrainTravel,
  TrenitTraveller,
} from '../../../classes/train.models';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  debounceTime,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { TrainMainComponent } from '../../common/train-main/train-main.component';
import * as $ from 'jquery';
import { TrainService } from '../../../train.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TrainUtils } from '../../train.utils';
import {CompaniesService} from "../../../../../services/profile/companies.service";


@Component({
  selector: 'train-search-carnets',
  templateUrl: './train-search-carnets.component.html',
  styleUrls: ['./train-search-carnets.component.scss'],
})
export class TrainSearchCarnetsComponent extends TrainMainComponent implements OnInit, AfterViewInit{

  /**
   *  Constructor
   */
  constructor(
    private fb : UntypedFormBuilder,
    private trainService : TrainService,
    public router : Router,
    public titleService : Title,
    public translate : TranslateService,
    private companiesService : CompaniesService,
  ){
    super(router, titleService, translate);
  }

  @Output() toggle = new EventEmitter<any>();
  @Output() setMaxPax: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeTraveller: EventEmitter<any> = new EventEmitter<any>();
  /*@Input() pax : any;*/

  fetchingInformation$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  evaluateTravelLoading$ : BehaviorSubject<boolean> = new BehaviorSubject(false);

  // -- FORM -- //
  formVisible = true;
  searchForm : UntypedFormGroup;
  isStationLoading = false;
  filteredDepartureStation : StationResponse[];
  filteredArrivalStation : StationResponse[];
  departureStartDate$ = new BehaviorSubject(new Date());
  @Input() travellers : TrenitTraveller[];
  maxPax: number = 1;

  // -- Table -- //
  expandedElement : any;
  selectedOfferId : any;
  selectedClass : any[];
  sortedSegments : any;
  details : SearchBaseResponse;


  // -- Details -- //
  selectedOffer : any = {};
  availableLevels : string[] = [];
  selectedLevel : any = {};
  pitCodes: any[] = [];
  /**
   *  OnInit function is empty for now
   */
  ngOnInit(){
    this.initForm();
  }

  ngAfterViewInit(){
    this.searchForm.patchValue(this.currentSearch);
    if (this.trainTravel?.travellers){
      this.travellers = JSON.parse(JSON.stringify(this.trainTravel.travellers));
    }
    this.travellers = this.travellers.length== 0 ? [new TrenitTraveller(undefined)] : [...this.travellers];
    /*if (this.pax){
      this.travellers = this.pax.map(p => {
        return { ...p, ...new TrenitTraveller(p._id) };
      });
    }*/
  }

  getPitCodes(){
    const companyId = JSON.parse(localStorage.getItem('fl_profile')).company._id;
    this.companiesService.getCorporateCode('train', companyId).subscribe((res:any)=> {
      this.setPitCode(companyId, res.list);
    })
  }

  setPitCode(companyId: string, codes : any){
    codes.forEach((elem:any)=> {
      if (elem.company === companyId) this.pitCodes.push({pitCode: elem.code, name: elem.name});
    })
  }

  onSubmit($event : Event){
    this.travellers.forEach(t => {
      if (!t.xmlId){
        t.xmlId = t._id;
      }
      t.parameters = [];
    });
    this.trainTravel = {
      ...new TrainTravel(),
      travellers: this.travellers,
    };
    super.updateTrainTravel();
    this.fetchingInformation$.next(true);
    const searchTrainRequest = new SearchTrainsRequest();
    searchTrainRequest.departure_station = this.searchForm.getRawValue().departure_station?.id;
    searchTrainRequest.arrival_station = this.searchForm.getRawValue().arrival_station?.id;
    searchTrainRequest.departure_date = new Date().toISOString();
    this.fetchingInformation$.next(true);
    const body = { travellers: this.trainTravel.travellers, pit_code: this.searchForm.getRawValue().pit_code };
    this.trainService.searchCarnets(body, searchTrainRequest).subscribe((response : SearchBaseResponse) => {
      this.currentSearch = this.searchForm.getRawValue();
      super.updateCurrentSearch();
      this.fetchingInformation$.next(false);
      this.trainTravel = {
        ...this.trainTravel,
        travellers: response.travellers,
      };
      super.updateTrainTravel();
      this.sortedSegments = response.segments.slice().map((s : any) => {
        return {
          ...s,
          services: s.services.map((paxServices : any) => {
            return { ...paxServices, services: this.getSortedServices(paxServices) };
          }),
        };
      });
      this.selectedOfferId = [];
      this.selectedClass = [];
      this.buildLevels();
      this.selectedLevel = {};
      this.details = response;
    }, _ => this.fetchingInformation$.next(false));
  }

  // -- Stazioni -- //
  public displayFn(station : StationResponse) : string{
    if (station){
      return station?.name;
    }
    return '';
  }

  getDisabledOffer(){
    const selectedClass = this.selectedClass;
    const offerId = this.selectedOfferId;
    if (!selectedClass || !offerId || offerId.includes(undefined)){
      return true;
    }
    return selectedClass.map(s => {
      return offerId.map(o => {
        return s[o] && s[o].eligibility.level !== 'ELIGIBLE';
      }).includes(true);
    }).includes(true);
  }

  getEligibilityMessage(){
    const selectedClass = this.selectedClass;
    const offerId = this.selectedOfferId;
    if (!selectedClass || !offerId || offerId.includes(undefined)){
      return '';
    }
    const messages = new Set();
    this.selectedClass.forEach(s => {
      this.selectedOfferId.filter((o:any) => !!s[o] && !!s[o].eligibility.messages).forEach((offerName:any) => {
        const msg = Array.isArray(s[offerName].eligibility.messages) ? s[offerName].eligibility.messages : [s[offerName].eligibility.messages];
        msg.forEach((m:any) => messages.add(m));
      });
    });
    return Array.from(messages).join('. ');
  }

  /**
   * set the the selected offer For the trenitalia trip
   * @param travelSolution travelsolution returned from searchbase
   * @param offer
   * @param paxId
   * @param pax
   */
  selectTrenitaliaOffer(travelSolution : any, offer : any, paxId : any, pax : any) : void{
    if (this.isOfferDisabled(pax, offer)){
      return;
    }
    const solutionNodeRef = this.sortedSegments[0].idXml;
    this.selectedOfferId[paxId] = offer.name;
    this.trainTravel = {
      ...this.trainTravel,
      selectedTravelSolution: {
        ...travelSolution,
        offeredServices: this.getFilteredOffers(paxId, travelSolution.offeredServices, solutionNodeRef, this.selectedClass, this.selectedOfferId),
      },
    };
    super.updateTrainTravel();
  }

  evaluateTravelSolution(){
    const request = new EvaluateTravelSolutionReq(this.trainTravel.selectedTravelSolution, this.trainTravel.travellers, this.trainTravel.customerKey);
    this.evaluateTravelLoading$.next(true);
    this.trainService.evaluateTravelSolution(request).subscribe((res : EvaluateTravelSolutionRes) => {
      this.evaluateTravelLoading$.next(false);
      if (!res?.evaluateTravelSolutionsResponse.validSelection){
        return;
      }
      this.trainTravel = {
        ...this.trainTravel,
        evaluatedTravel: res,
      };
      super.updateTrainTravel();
      this.nextStep();
    }, _ => this.evaluateTravelLoading$.next(false));
  }

  toggleSearchForm(){
    this.toggle.emit(event);
  }

  removePax(index : number){
    this.removeTraveller.emit(index)
  }

  setTravellers(pax : TrenitTraveller){
    this.travellers = JSON.parse(JSON.stringify(pax));
  }

  togglePaxModal(){
    this.setMaxPax.emit({maxPax:this.maxPax, travellers: this.travellers})
    $('#paxModal').modal('toggle');
  }

  getTraveller(pax : any){
    return this.trainTravel.travellers.find(t => t.xmlId === pax.paxId || t._id === pax.paxId);
  }

  isOfferDisabled(pax : any, offer : any){
    const travellerParams = this.trainTravel.travellers.find(t => t.xmlId === pax.paxId).parameters.map((p : any) => Number.parseInt(p.typeId, 10));
    return offer?.available === 0 || offer?.eligibility.level !== 'ELIGIBLE' || !offer.requiresParams.every(paramId => travellerParams.includes(paramId));
  }

  isFormValid(){
    return this.searchForm?.valid && this.travellers.length > 0 && this.travellers.every(t => t.name);
  }

  resetForm(){
    delete this.details;
    this.resetStorage();
    this.initForm();
    this.travellers = [new TrenitTraveller(undefined)];
  }

  private initForm(){
    this.searchForm = this.fb.group({
      departure_station: [undefined, [Validators.required]],
      arrival_station: [undefined, [Validators.required]],
      pit_code: ['', []],
    });
    this.getPitCodes()
    /*this.checkPitOrCF()*/
    this.initStationSearch('departure_station');
    this.initStationSearch('arrival_station');
  }

  /**
   *
   * @param field departure_station | arrival_station
   * Init the autocomplete search for the "station" fields
   */
  private initStationSearch(field : string) : void{
    this.searchForm.get(field).valueChanges.pipe(
      debounceTime(500),
      skipWhile((val) => val.toString() === '[object Object]' || val.toString() === ''),
      tap(() => this.isStationLoading = true),
      switchMap((value) => (typeof value === 'object') ? of([null]) : this.trainService.getStations(value)),
    ).subscribe((res : StationResponse[]) => {
      this.isStationLoading = false;
      switch(field){
        case 'arrival_station':
          this.filteredArrivalStation = res;
          break;
        case 'departure_station':
          this.filteredDepartureStation = res;
          break;
      }
    }, _ => this.isStationLoading = false);
  }

  private nextStep(nextStep : string[] = ['trains', 'passengers']){
    this.router.navigate(nextStep);
  }

  setOffer(serviceClass : any, travelSolution : any, paxIndex : any, pax : any) : void{
    if (serviceClass.offers.length === 1 && serviceClass.offers[0].offers.length === 1 && !this.isOfferDisabled(pax, this.selectedOffer[paxIndex])){
      this.selectedOffer[paxIndex] = serviceClass.offers[0]?.offers[0];
      this.selectTrenitaliaOffer(travelSolution, this.selectedOffer[paxIndex], paxIndex, pax);
    }
  }

  buildLevels(){
    const levels = {};
    const a = this.sortedSegments[0].services.forEach(pax => {
      pax.services.forEach(group => {
        group.services.forEach(serviceClass => {
          const levelName = this.getLevelName(serviceClass);
          levels[levelName] = levelName;
        });
      });
    });
    this.availableLevels = Object.keys(levels);
  }

  getServiceName(serviceClass : any){
    return serviceClass.displayName;
  }

  filterByLevel(services : any, i: any){
    return services?.filter(s => this.selectedLevel[i].includes(this.getLevelName(s))) || [];
  }

  getLevelName(serviceClass: any){
    return serviceClass?.level?.map(l => TrainUtils.toArray(l.values).map(v => v.value).join('/')) || '';
  }

}
