import {Component, Inject, Input, OnInit} from '@angular/core';
import {GroupsService} from '../../groups.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-role',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {

  loading = false;
  updating: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddGroupComponent>,
    private groupsService: GroupsService,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
  }

  ngOnInit(): void {
    this.updating = this.data.updating;
  }

  handleSubmit(payload: any): void {
    this.loading = true;
    this.groupsService.create(payload).subscribe(result => {
        this.loading = false;
        this.snackBar.open('Group created successfully', 'Close');
        this.dialogRef.close(result)
      },
      error => {
        this.loading = false;
      }
    );
  }

  handleUpdate(event:any){
    this.loading = true;
    this.groupsService.update(event, this.data.group._id).subscribe(result => {
      this.loading = false;
      this.dialogRef.close(result)
    }, error=> {
      this.loading= false;
      console.error(error);
    })
  }
}
