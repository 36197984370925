<div class="buttonrow text-left mb-3">
  <button class="mainbg" mat-stroked-button (click)="toggleForm()">
    <span *ngIf="!formvisible">{{'EDIT_SEARCH'|translate}}</span>
    <span *ngIf="formvisible">{{'HIDE_FORM'|translate}}</span>
  </button>
  <button (click)="toggleFilters()" class="mainbg" mat-stroked-button>
    <span class="d-inline-block vertical"> {{'OPEN_FILTERS'|translate}}</span>
    <span class="d-inline-block vertical ml-1" *ngIf="filterActive.length > 0"> ({{filterActive.length}})</span>
  </button>
</div>

<div class="filterRecom text-right">
  <button (click)="toggleForm()" mat-mini-fab color="primary" aria-label="Search">
    <mat-icon>search</mat-icon>
  </button>
  <button (click)="toggleFilters()" mat-mini-fab color="primary" aria-label="Search">
    <mat-icon matBadge="{{someFilterSelected()}}" matBadgeColor="accent" [matBadgeHidden]="someFilterSelected() == 0">tune</mat-icon>
  </button>
</div>
<div>
  <div *ngFor="let recommendation of obs | async; let r = index; let l = last;">

    <div class="isRecom" [ngClass]="{'mb-4':!l}">

      <div class="mb-2" *ngIf="iataComplete.carriersOptions[recommendation.marketingCarrier] && !appComponent.isB2B && !appComponent.isSbt">
        <div *ngIf="iataComplete.carriersOptions[recommendation.marketingCarrier].blockIssuance">
          <div *ngIf="iataComplete.carriersOptions[recommendation.marketingCarrier].feePerPax && iataComplete.carriersOptions[recommendation.marketingCarrier].feePerPax > 0">
            L'emissione per questo vettore ha un costo aggiuntivo di {{iataComplete.carriersOptions[recommendation.marketingCarrier].feePerPax| number:'.2-2'}}€ per passeggero<br/>
          </div>
          Non è possibile emettere manualmente questa prenotazione, verrà contattata la biglietteria per l'emissione.
          <div *ngIf="iataComplete.carriersOptions[recommendation.marketingCarrier].feePerPax && iataComplete.carriersOptions[recommendation.marketingCarrier].feePerPax > 0">
            Il costo aggiuntivo è di {{(request.passengers.adt + request.passengers.chd + request.passengers.inf) * iataComplete.carriersOptions[recommendation.marketingCarrier].feePerPax| number:'.2-2'}}€
          </div>
        </div>
      </div>

      <div class="priceDetails">
        <div class="row negotiatedRow" *ngIf="!recommendation.corporate && !tourOp && recommendation.negotiated && !priceservice.isNegoHidden() && !(appComponent.isGat || appComponent.isSbt || appComponent.isB2B)">

          <div class="col-12 text-right">
            <div class="font-weight-bold text-uppercase">{{'NEGOTIATED_FARE'|translate}}</div>
            <span *ngIf="recommendation.fare.publicAmount">
              <span class="font-weight-bold text-uppercase">{{'PUBLIC_FARE'|translate}}: </span> {{recommendation.fare.publicAmount|number:'.2-2'}}€ |
            </span>
            <span *ngIf="recommendation.fare.publicAmount" class="">
              {{'NET_FARE'|translate}}: {{recommendation.fare.amount|number:'.2-2'}}€ |
            </span>
            <span *ngIf="!appComponent.isGat">{{'FLYLEO_FEE'|translate}}: {{recommendation.fare.fees||0|number:'.2-2'}}€ | </span>
            <span *ngIf="appComponent.isGat">{{'FLYGAT_FEE'|translate}}: {{recommendation.fare.fees||0|number:'.2-2'}}€ | </span>
            <span *ngIf="recommendation.fare.publicAmount" class="">
            <span class="font-weight-bold text-uppercase">{{'NET_DISCOUNT'|translate}}: </span>{{calculateMargin(recommendation.fare.publicAmount,recommendation.fare.amount, recommendation.fare.fees)||0|number:'.2-2'}}€
          </span>
          </div>
        </div>

        <div class="row negotiatedRow" *ngIf="!recommendation.corporate && !tourOp && recommendation.negotiated && !priceservice.isNegoHidden() && appComponent.isGat">

          <div class="col-12 text-right">
            <div class="font-weight-bold text-uppercase">{{'NEGOTIATED_FARE'|translate}}</div>
            <span *ngIf="recommendation.fare.publicAmount">
              <span class="font-weight-bold text-uppercase">{{'PUBLIC_FARE'|translate}}: </span> {{recommendation.fare.publicAmount|number:'.2-2'}}€ |
            </span>
            <span *ngIf="recommendation.fare.publicAmount" class="">
              {{'NET_FARE'|translate}}: {{recommendation.fare.amount|number:'.2-2'}}€ |
            </span>
            <span>{{'FLYGAT_FEE'|translate}}: {{recommendation.fare.fees|number:'.2-2'}}€ | </span>
            <span *ngIf="recommendation.fare.publicAmount" class="">
              <span class="font-weight-bold text-uppercase">{{'NET_DISCOUNT'|translate}}: </span>
              {{calculateMargin(recommendation.fare.publicAmount,recommendation.fare.amount, recommendation.fare.fees)|number:'.2-2'}}€
            </span>
          </div>
        </div>

        <div class="row negotiatedRow" *ngIf="!recommendation.corporate && !tourOp && recommendation.negotiated && (appComponent.isSbt || appComponent.isB2B)">

          <div class="col-12 text-right">
            <div>{{'DISCOUNT_FARE'|translate}}</div>
          </div>
        </div>


        <div class="searchNavigation text-right">
          <div class="mb-2">
              <button *ngIf="!this.atc && !ndcAma" class="newTabButton " [ngClass]="{'newTabButtonActive': !recommendation.showFares}" (click)="toggleShowFare(r)">
                <span>{{'SELECT_FLIGHTS'|translate}}</span>
              </button>
              <button *ngIf="recommendation.rates && recommendation.rates.length>0 && !this.atc" class="newTabButton" [ngClass]="{'newTabButtonActive': recommendation.showFares}"
                      (click)="toggleShowFare(r)">
                <span>{{'SELECT_FARES'|translate}}</span>
              </button>
              <button *ngIf="!recommendation.rates && !recommendation.lowCost && !ndcAma" class="newTabButton" [ngClass]="{'newTabButtonActive': recommendation.showFares}" (click)="askFares(recommendation.ref,r+currentPage, false)">
                <span>{{'ASK_TARIFFES'|translate}}</span>
              </button>
              <button *ngIf="!this.atc && ndcAma" class="newTabButton " [ngClass]="{'newTabButtonActive': !recommendation.showFares}" (click)="hideFareNdc(recommendation)">
                <span>{{'SELECT_FLIGHTS'|translate}}</span>
              </button>
              <button *ngIf="ndcAma && recommendation.currentOffer && !recommendation.showFares" class="newTabButton" [ngClass]="{'newTabButtonActive': recommendation.showFares}" (click)="askFaresAmaNdc(recommendation,r+currentPage)">
                <span>{{'ASK_TARIFFES'|translate}}</span>
              </button>

          </div>
          <div class="cmDivider mb-4"></div>
        </div>

        <!-- TARIFFE TOUR OPERATOR -->
        <div class="row negotiatedToRow" *ngIf="tourOp && recommendation.negotiated && !priceservice.isNegoHidden() &&
        !(appComponent.isGat || appComponent.isSbt || appComponent.isB2B)">
          <div class="col-12 text-right">
            <div class="font-weight-bold text-uppercase">{{'TO_FARE'|translate}}</div>
            <span *ngIf="recommendation.fare.publicAmount">
              <span class="font-weight-bold text-uppercase">{{'PUBLIC_FARE'|translate}}: </span> {{recommendation.fare.publicAmount|number:'.2-2'}}€ |
            </span>
            <span *ngIf="recommendation.fare.publicAmount" class=""> {{'NET_FARE'|translate}}: {{recommendation.fare.amount|number:'.2-2'}}€ | </span>
            <span *ngIf="!appComponent.isGat">{{'FLYLEO_FEE'|translate}}: {{recommendation.fare.fees||0|number:'.2-2'}}€ | </span>
            <span *ngIf="appComponent.isGat">{{'FLYGAT_FEE'|translate}}: {{recommendation.fare.fees||0|number:'.2-2'}}€ | </span>
            <span *ngIf="recommendation.fare.publicAmount" class="">
              <span class="font-weight-bold text-uppercase">{{'NET_DISCOUNT'|translate}}: </span>
              {{calculateMargin(recommendation.fare.publicAmount,recommendation.fare.amount, recommendation.fare.fees)||0|number:'.2-2'}}€
            </span>
          </div>
        </div>

        <!-- TARIFFE CORPORATE -->

        <div class="row negotiatedCorporateRow" *ngIf="recommendation.corporate && !tourOp && recommendation.negotiated && !priceservice.isNegoHidden() &&
        !(appComponent.isGat || appComponent.isSbt || appComponent.isB2B)">
          <div class="col-12 text-right">
            <div class="font-weight-bold text-uppercase">{{'CORPORATE_FARE'|translate}}</div>
            <span *ngIf="recommendation.fare.publicAmount">
              <span class="font-weight-bold text-uppercase">{{'PUBLIC_FARE'|translate}}: </span> {{recommendation.fare.publicAmount|number:'.2-2'}}€ |
            </span>
            <span *ngIf="recommendation.fare.publicAmount" class=""> {{'NET_FARE'|translate}}: {{recommendation.fare.amount|number:'.2-2'}}€ | </span>
            <span *ngIf="!appComponent.isGat">{{'FLYLEO_FEE'|translate}}: {{recommendation.fare.fees||0|number:'.2-2'}}€ | </span>
            <span *ngIf="appComponent.isGat">{{'FLYGAT_FEE'|translate}}: {{recommendation.fare.fees||0|number:'.2-2'}}€ | </span>
            <span *ngIf="recommendation.fare.publicAmount" class="">
              <span class="font-weight-bold text-uppercase">{{'NET_DISCOUNT'|translate}}: </span>
              {{calculateMargin(recommendation.fare.publicAmount,recommendation.fare.amount, recommendation.fare.fees)||0|number:'.2-2'}}€
            </span>
          </div>
        </div>

        <div class="row">

          <div class="col-8">
            <div class="text-center" *ngIf="currentLoad == r+currentPage">
              {{'REAL_TIME_PRICING'|translate}}
              <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div *ngIf="!recommendation.showFares && !this.atc" @listItemAnimation>
              <div *ngFor="let fare of recommendation.rates">
                <div *ngIf="fare.selected">
                  {{'SELECTED_FARE'|translate}}:
                  <span *ngFor="let class of fare.details;let l = last;">
                    <span>{{class.name||class.tariffClass}}</span>
                    <span *ngIf="!l"> + </span>
                  </span>
                  <br/>
                  {{'CABINS'|translate}}:
                  <span *ngFor="let class of fare.bookingClasses;let l = last;">
                    <span class="singleClass" [ngClass]="{'mr-1': !l}">{{class}}</span> <!-- <span *ngIf="!l"> -> </span> -->
                  </span>

                  <div class="bagInfo">
                    <div *ngIf="fare.passengers.ADT">
                      <span class="material-icons"> work </span>
                      {{'BAG_FOR_ADULT'|translate}}:
                      <span *ngFor="let bag of fare.passengers.ADT.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                        {{bag.allowance}}
                        <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                    </div>
                    <div *ngIf="fare.passengers.CHD">
                      <span class="material-icons"> work </span>
                      {{'BAG_FOR_CHILD'|translate}}:
                      <span *ngFor="let bag of fare.passengers.CHD.baggage; let i = index;let l = last">
                        <span *ngIf="l && i != 0"> - </span>
                        {{bag.allowance}}
                        <span *ngIf="bag.allowance < 10"> BAG </span>
                        <span *ngIf="bag.allowance >= 10"> Kg </span>
                      </span>
                    </div>
                    <div *ngIf="fare.passengers.INF">
                      <span class="material-icons"> work </span>
                      {{'BAG_FOR_INFANT'|translate}}:
                      <span *ngFor="let bag of fare.passengers.INF.baggage; let i = index;let l = last">
                        <span *ngIf="l && i != 0"> - </span>
                        {{bag.allowance}}
                        <span *ngIf="bag.allowance < 10"> BAG </span>
                        <span *ngIf="bag.allowance >= 10"> Kg </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="ndcAma">

              <div *ngIf="!recommendation.currentOffer">Selezione non disponibile</div>

              <div class="faresResults" *ngIf="recommendation.faresAma && recommendation.showFares" @listItemAnimation>

                <div class="row">

                  <div class="col-lg-4 col-md-6 col-12" *ngFor="let offer of recommendation.faresAma.pricedOffer">
                    <div class="uiFare" [ngClass]="{'selectedRate':offer.selected}">

                      <span class="material-icons absoluteCheck" *ngIf="offer.selected" >
                        radio_button_checked
                      </span>

                      <span class="pointer material-icons absoluteCheck" (click)="slectNdcAmaFare(offer, recommendation)">
                        radio_button_unchecked
                      </span>

                      <div class="fareNameNew">
                        <span *ngFor="let journey of offer.journeyArray; let lastJourney = last;">

                            <span>{{journey.details.tarif.name}}</span>
                            <span *ngIf="!lastJourney"> + </span>

                        </span>
                      </div>

                      <div class="dialogFareNew">
                        <div class="bagInfo">
                          <div *ngIf="offer.baggage.adt">

                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_ADULT'|translate}}: {{offer.baggage.adt.qty}}
                          </div>
                          <div *ngIf="offer.baggage.chd">

                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_CHILD'|translate}}: {{offer.baggage.chd.qty}}
                          </div>
                          <div *ngIf="offer.baggage.inf">

                            <span class="material-icons">
                              work
                            </span>
                            {{'BAG_FOR_INFANT'|translate}}: {{offer.baggage.inf.qty}}
                          </div>
                        </div>

                        <div class="bottomActions">
                          <span *ngIf="!offer.refundable">
                            <span class="material-icons greytxt">
                              cancel
                            </span>
                            {{'NO_REFOUND'|translate}}
                          </span>
                          <span *ngIf="offer.refundable">
                            <span class="material-icons greentxt">
                              check_circle
                            </span> {{'YES_REFOUND'|translate}}
                          </span>
                          <span class="bottomInfoBtn" (click)="openInfoNdcAma(offer)">
                            {{'FARE_INFO'|translate}}
                          </span>


                        </div>
                        <div class="farePrice" (click)="slectNdcAmaFare(offer, recommendation)">
                          <span *ngIf="!offer.negotiated" class="">
                            {{priceservice.returnPrice(offer.totalPrice.amount)|number:'.2-2'}} {{offer.totalPrice.currency}}
                          </span>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>

              </div>
            </div>

            <div class="row" *ngIf="recommendation.lowCost && recommendation.cabins && lowCost">

              <div class="col-lg-4 col-md-6 col-12" *ngIf="!isSupported(recommendation.marketingCarrier)">
                <div class="">
                  <div class="fareNameNew">
                    <span *ngFor="let cabin of recommendation.cabins; let l = last">
                      {{'FARE'|translate}} {{cabin.name}} <span *ngIf="!l">+ </span>
                    </span>
                  </div>
                  <div *ngFor="let cabin of recommendation.cabins| slice:0:1" class="">
                    <!-- {{cabin.description | slice : 0 : 50}}...-->
                    <!-- {{returnLowTariffDescription(recommendation.cabins[0])}} -->
                    <span *ngIf="lowCost" class="bottomInfoBtn tarifButton"
                          (click)="openBottomSheetLow(recommendation.cabins, recommendation.marketingCarrier, recommendation.cabins)">
                      {{'FARE_INFO'|translate}}
                    </span>
                  </div>

                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12" *ngIf="isSupported(recommendation.marketingCarrier)">
                <div class="">
                  <div class="fareNameNew">
                    <span> {{'FARE'|translate}} {{recommendation.cabins[0].name}} </span>
                  </div>
                  <div *ngFor="let cabin of recommendation.cabins| slice:0:1" class="">
                    <!--    <div *ngFor="let service of cabin.services | slice:0:3">
                          {{service.name}} {{service.label}}
                          <span *ngIf="service?.description[0]?.Dimensions">{{'DIMENSIONS'|translate}} {{service?.description[0]?.Dimensions}}</span>
                        </div>
                     -->
                    <span *ngIf="lowCost" class="bottomInfoBtn tarifButton" (click)="openBottomSheetLow(cabin.services, recommendation.marketingCarrier, cabin.name)">
                      {{'FARE_INFO'|translate}}
                    </span>
                    <!-- {{returnLowTariffDescription(recommendation.cabins[0])}} -->
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="company" class="col-4 text-right pnrActionPart buttonrow">
            <div class="mb-2">
              <img *ngIf="!ndcAma" [matTooltipPosition]="'above'" [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="recoCarrier" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
              <img *ngIf="ndcAma" [matTooltipPosition]="'above'" [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="recoCarrier" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>

              <span *ngIf="!recommendation.fare.publicAmount" class="price">
              <span  *ngIf="!company.cauDK">
                <span *ngIf="recommendation.lowCost && !appComponent.isSbt && !appComponent.isGat" [matTooltipPosition]="'above'"
                      matTooltip="{{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}} + 6 €">
                  {{priceservice.returnPrice(recommendation.fare.amount)+6|number:'.2-2'}}
                </span>
                <span *ngIf="recommendation.lowCost && (appComponent.isSbt || appComponent.isGat)">
                  {{priceservice.returnPrice(recommendation.fare.amount)+6|number:'.2-2'}}
                </span>
                <span *ngIf="!recommendation.lowCost && !appComponent.isB2B && !appComponent.isSbt && !appComponent.isGat" [matTooltipPosition]="'above'"
                      matTooltip="{{'NO_FEES' | translate}}" >
                  {{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}
                </span>
                <span *ngIf="(appComponent.isB2B || appComponent.isSbt || appComponent.isGat) && !recommendation.lowCost">
                  {{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}
                </span>
                <span *ngIf="!recommendation.fare.currency">€</span>
                <span *ngIf="recommendation.fare.currency">{{recommendation.fare.currency}}</span>
                <span *ngIf="recommendation.lowCost && !appComponent.isSbt && !appComponent.isGat && !appComponent.isB2B">
                  <span class="material-icons smaler" [matTooltipPosition]="'above'" matTooltip="{{'LOWCOSTFEE' | translate}}"> info </span>
                </span>
              </span>

              <span  *ngIf="company.cauDK">
                <span *ngIf="recommendation.lowCost && !appComponent.isSbt && !appComponent.isGat" [matTooltipPosition]="'above'"
                      matTooltip="{{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}€">
                  {{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}
                </span>
                <span *ngIf="recommendation.lowCost && (appComponent.isSbt || appComponent.isGat)">
                  {{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}
                </span>
                <span *ngIf="!recommendation.lowCost && !appComponent.isB2B && !appComponent.isSbt && !appComponent.isGat" [matTooltipPosition]="'above'"
                      matTooltip="{{'NO_FEES' | translate}}" >
                  {{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}
                </span>
                <span *ngIf="(appComponent.isB2B || appComponent.isSbt || appComponent.isGat) && !recommendation.lowCost">
                  {{priceservice.returnPrice(recommendation.fare.amount)|number:'.2-2'}}
                </span>
                <span *ngIf="!recommendation.fare.currency">€</span>
                <span *ngIf="recommendation.fare.currency">{{recommendation.fare.currency}}</span>
              </span>

            </span>
              <span *ngIf="recommendation.fare.publicAmount && priceservice.isNegoHidden() && !appComponent.isSbt && !appComponent.isB2B" class="price">
                {{priceservice.returnPrice(recommendation.fare.publicAmount)|number:'.2-2'}} €
              </span>

              <span *ngIf="recommendation.fare.publicAmount && !priceservice.isNegoHidden() && !appComponent.isSbt && !appComponent.isB2B" class="price">
                {{priceservice.returnPrice(recommendation.fare.amount + (recommendation.fare.fees||0)) | number:'.2-2'}} €
              </span>
              <span *ngIf="recommendation.fare.publicAmount && (appComponent.isSbt || appComponent.isB2B)" class="price">
                {{priceservice.returnPrice(recommendation.fare.publicAmount - (recommendation.fare.fees||0)) | number:'.2-2'}} €
              </span>
            </div>
            <div class="buttonrow">
              <button mat-stroked-button *ngIf="!atc && (!appComponent.isB2B && !appComponent.isSbt) && !recommendation.lowCost &&
                  recommendation.paxFare && !mainComponent.userSettings.useMarkup" [matMenuTriggerFor]="perPax">
                <span class="material-icons"> euro </span> {{'PER_PASSENGERS'|translate}}
              </button>
              <mat-menu #perPax="matMenu">
                <div mat-menu-item *ngIf="!recommendation.lowCost && recommendation.paxFare && !mainComponent.userSettings.useMarkup && recommendation.paxFare.adt">
                  {{'ADULT'|translate}}:
                  Tot: {{recommendation.paxFare.adt.amount|number:'.2-2'}}€ |
                  Tax: {{recommendation.paxFare.adt.tax|number:'.2-2'}}€
                </div>
                <div mat-menu-item *ngIf="!recommendation.lowCost && recommendation.paxFare && !mainComponent.userSettings.useMarkup && recommendation.paxFare.chd">
                  {{'CHILDREN'|translate}}:
                  Tot: {{recommendation.paxFare.chd.amount|number:'.2-2'}}€ |
                  Tax: {{recommendation.paxFare.chd.tax|number:'.2-2'}}€
                </div>
                <div mat-menu-item *ngIf="!recommendation.lowCost && recommendation.paxFare && !mainComponent.userSettings.useMarkup && recommendation.paxFare.inf">
                  {{'INFANT'|translate}}:
                  Tot: {{recommendation.paxFare.inf.amount|number:'.2-2'}}€ |
                  Tax: {{recommendation.paxFare.inf.tax|number:'.2-2'}}€
                </div>
              </mat-menu>

              <!--
              <button mat-stroked-button color="primary" *ngIf="!recommendation.rates && !recommendation.lowCost" (click)="askFares(recommendation.ref,r+currentPage, false)">
              <span class="material-icons">
                local_offer
              </span>
                {{'ASK_TARIFFES'|translate}}
              </button>
              <button mat-stroked-button (click)="toggleShowFare(r)" *ngIf="recommendation.rates && recommendation.showFares && !this.atc">
            <span class="material-icons">
             flight_takeoff
            </span>
                {{'SELECT_FLIGHTS'|translate}}
              </button>
              <button mat-stroked-button (click)="toggleShowFare(r)" *ngIf="recommendation.rates && recommendation.rates.length>0 && !recommendation.showFares && !this.atc">
            <span class="material-icons">
              local_offer
            </span>
                {{'SELECT_FARES'|translate}}
              </button>

             -->
              <button [disabled]="checkPolicyBlock(recommendation,recommendation.tariff)" mat-raised-button color="primary"
                      *ngIf="!ndcAma && mainComponent.loggedUser.role.flight.taw.post.options.link && !recommendation.lowCost && recommendation.rates &&
                      currentLoad != r && recommendation.rates.length > 0"
                      (click)="openDialog(recommendation.ref, r, undefined, searchResults._id, recommendation.rates)">
                {{'CONTINUE'|translate}}
              </button>
              <button [disabled]="checkPolicyBlockLow(recommendation)" mat-raised-button color="primary"
                      *ngIf="!ndcAma && mainComponent.loggedUser.role.flight.taw.post.options.link && recommendation.lowCost"
                      (click)="openDialog(recommendation.ref,r, searchResults.routingId, searchResults._id, undefined)">
                {{'BUY_RESERVATION'|translate}}
              </button>
              <button mat-raised-button color="primary" *ngIf="mainComponent.loggedUser.role.flight.taw.post.options.link && ndcAma && currentLoad != r && recommendation.fareNdc" (click)="openDialog(recommendation.ref, r, undefined, searchResults._id, undefined, recommendation.faresAma, recommendation.fareNdc, recommendation.tariff, recommendation.fare, recommendation.paxFare)">
                {{'CONTINUE'|translate}}
              </button>
              <app-policy-alerts-icon [arrayOfAlerts]="recommendation.arrayOfAlerts" [isHotel]="false" [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt" [isNdcOrLow]="(ndc || lowCost)"></app-policy-alerts-icon>
            </div>
          </div>
        </div>

      </div>

      <div class="faresResults" *ngIf="recommendation.showFares" @listItemAnimation>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12" *ngFor="let fare of recommendation.rates;let f = index;">

            <div class="uiFare" [ngClass]="{'selectedRate':fare.selected}">
              <span class="material-icons absoluteCheck" *ngIf="fare.selected"> radio_button_checked </span>
              <span (click)="selectFare(f,r+currentPage)" class="pointer material-icons absoluteCheck" *ngIf="!fare.selected"> radio_button_unchecked </span>
              <div class="fareNameNew">
                <span *ngFor="let class of fare.details;let l = last;">
                  <span>{{class.name||class.tariffClass}}</span> <span *ngIf="!l"> + </span>
                </span>
                <app-policy-alerts-icon [isB2B]="appComponent.isB2B" [isSbt]="appComponent.isSbt" [isNdcOrLow]="(ndc || lowCost)"
                                        [arrayOfAlerts]="fare.arrayOfAlerts">
                </app-policy-alerts-icon>
              </div>
              <div class="dialogFareNew">
                <div class="bagInfo">
                  <div *ngIf="!recommendation.lowCost" class="mb-1">
                    {{'CABINS'|translate}}:
                    <span *ngFor="let class of fare.bookingClasses;let l = last;">
                    <span class="singleClass" [ngClass]="{'mr-1': !l}">{{class}}</span> <!-- <span *ngIf="!l"> -> </span> -->
                  </span>
                  </div>
                  <div *ngIf="fare.passengers.ADT">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_ADULT'|translate}}:
                    <span *ngFor="let bag of fare.passengers.ADT.baggage; let i = index;let l = last">
                    <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                  </span>
                  </div>
                  <div *ngIf="fare.passengers.CHD">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_CHILD'|translate}}:
                    <span *ngFor="let bag of fare.passengers.CHD.baggage; let i = index;let l = last">
                      <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                  </div>
                  <div *ngIf="fare.passengers.INF">
                    <span class="material-icons"> work </span>
                    {{'BAG_FOR_INFANT'|translate}}:
                    <span *ngFor="let bag of fare.passengers.INF.baggage; let i = index;let l = last">
                    <span *ngIf="l && i != 0"> - </span>
                      {{bag.allowance}}
                      <span *ngIf="bag.allowance < 10"> BAG </span>
                      <span *ngIf="bag.allowance >= 10"> Kg </span>
                    </span>
                  </div>
                </div>
                <div class="bottomActions">
                  <span *ngIf="!fare.refundable">
                    <span class="material-icons greytxt"> cancel </span>
                    {{'NO_REFOUND'|translate}}
                  </span>
                  <span *ngIf="fare.refundable">
                    <span class="material-icons greentxt"> check_circle </span> {{'YES_REFOUND'|translate}}
                  </span>
                  <span class="bottomInfoBtn" (click)="openBottomSheet(fare)"> {{'FARE_INFO'|translate}} </span>
                  <span class="bottomInfoBtn gatPenalties" *ngIf="appComponent.isGat && mainComponent.loggedUser.role.superAdmin"
                        (click)="openDialogRules(fare,recommendation.ref, r, undefined, searchResults._id, recommendation.rates)">
                    {{'FARE_RULES'|translate}}
                  </span>
                  <span class="bottomInfoBtn flyPenalties" *ngIf="!appComponent.isGat && mainComponent.loggedUser.role.superAdmin"
                        (click)="openDialogRules(fare,recommendation.ref, r, undefined, searchResults._id, recommendation.rates)">
                    {{'FARE_RULES'|translate}}
                  </span>
                  <span class="bottomInfoBtn" *ngIf="!mainComponent.loggedUser.role.superAdmin"
                        (click)="openDialogRules(fare,recommendation.ref, r, undefined, searchResults._id, recommendation.rates)">
                    {{'FARE_RULES'|translate}}
                  </span>
                  <span class="material-icons yellow-text" matTooltip="Negotiated fare: {{fare.fare.amount + fare.fare.fees|number:'.2-2'}}"
                        *ngIf="fare.negotiated">
                    star
                  </span>
                </div>
                <div class="farePrice" (click)="selectFare(f,r+currentPage)">
                  <span *ngIf="!fare.negotiated" class=""> {{priceservice.returnPrice(fare.fare.amount)|number:'.2-2'}} € </span>
                  <span *ngIf="fare.negotiated && priceservice.isNegoHidden() && !appComponent.isSbt && !appComponent.isB2B" class="">
                    {{priceservice.returnPrice(fare.fare.publicAmount)|number:'.2-2'}} €
                  </span>
                  <span *ngIf="fare.negotiated && !priceservice.isNegoHidden() && !appComponent.isSbt && !appComponent.isB2B" class="">
                    {{priceservice.returnPrice(fare.fare.amount + fare.fare.fees)|number:'.2-2'}} €
                  </span>
                  <span *ngIf="fare.negotiated && (appComponent.isSbt || appComponent.isB2B)" class="">
                    <del>{{priceservice.returnPrice(fare.fare.publicAmount)|number:'.2-2'}} €</del>
                    {{priceservice.returnPrice(fare.fare.publicAmount - fare.fare.fees)|number:'.2-2'}} €
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="travelDetails" *ngIf="!recommendation.showFares" @listItemAnimation>
        <div class="mb-3" *ngFor="let itinerary of recommendation.itineraries; let i = index;let la = last;">
          <div [ngClass]="{'nextExist':i<recommendation.itineraries.length && i!=recommendation.itineraries.length-1}">

            <div class="introItinerary" *ngIf="!recommendation.lowCost">
              <p *ngIf="itinerary.sections.length>0" >
                {{searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights[0].departure.date|date:'EEE dd MMM yyyy'}}
              </p>

              {{iataComplete.returnAirport(request.sections[i].from[0])}}
              <!--
              {{iataComplete.returnAirport(searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights[0].departure.location)}}
              <sup>{{searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights[0].departure.location}}</sup>
              -->
              <mat-icon>arrow_right_alt</mat-icon>
              {{iataComplete.returnAirport(request.sections[i].to[0])}}
              <!--
              {{iataComplete.returnAirport(searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights[searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights.length-1].arrival.location)}}
              <sup>{{searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights[searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights.length-1].arrival.location}}</sup>
              -->
              <div class="flightCabin marginbottom10">
                <span *ngIf="recommendation.cabins[i] === 'Y'">Economic</span>
                <span *ngIf="recommendation.cabins[i]=='M'">Economic standard</span>
                <span *ngIf="recommendation.cabins[i]=='W'">Economic premium</span>
                <span *ngIf="recommendation.cabins[i]=='C'">Business</span>
                <span *ngIf="recommendation.cabins[i]=='F'">First</span>
              </div>
            </div>
            <div class="introItinerary marginbottom10" *ngIf="recommendation.lowCost">
              <p *ngIf="itinerary.sections.length>0" >
                {{searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[0].departure.dateTime|date:'EEE dd MMM yyyy':"+0000"}}
              </p>
              {{iataComplete.returnAirport(searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[0].departure.location)}}
              <sup>{{searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[0].departure.location}}</sup>
              <mat-icon>arrow_right_alt</mat-icon>
              {{iataComplete.returnAirport(searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights[searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights.length-1].arrival.location)}}
              <sup>{{searchResults.itineraries[i].sections[itinerary.sections[0].ref-1].flights[searchResults.itineraries[i].sections[itinerary.sections[0].ref -1].flights.length-1].arrival.location}}</sup>
            </div>

            <div class="row" *ngFor="let section of itinerary.sections; let s = index; let z = last" [hidden]="section.hidden || section.hiddenT">
              <div class="col-12 collapse show" id="itinerary{{r}}{{i}}{{s}}">
                <!--
                <div class="outOfFilter" *ngIf="selectedStops < searchResults.itineraries[i].sections[section.ref-1].flights.length-1">
                  {{searchResults.itineraries[i].sections[section.ref-1].flights.length-1}} {{'STOPS'|translate}}
                </div> -->
                <!--
                <div class="outOfFilter" *ngIf="!isInFilterTime(searchResults.itineraries[i].sections[section.ref-1])">
                  {{'OUT_OF_FILTER'|translate}}
                </div>
                -->
                <div class="row">
                  <div class="col-sm-1 d-sm-inline-block d-none text-center">
                    <img *ngIf="!ndcAma" [matTooltip]="iataComplete.returnCarrier(recommendation.marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                    <img *ngIf="ndcAma" [matTooltip]="iataComplete.returnCarrier(searchResults.itineraries[i].sections[section.ref-1].flights[0].marketingCarrier)" class="marketingcarrierimage" src="assets/img/airline/icon/{{searchResults.itineraries[i].sections[section.ref-1].flights[0].marketingCarrier}}.png"/>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.location)}}<br/>
                    {{searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.hours)}}:
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.minutes)}}
                  </div>
                  <div class="col-3">
                    <div>
                      {{returnTime(searchResults.itineraries[i].sections[section.ref-1].flyingTime)}}
                    </div>
                    <div>
                      <span *ngIf="searchResults.itineraries[i].sections[section.ref-1].flights.length>1">
                        {{searchResults.itineraries[i].sections[section.ref-1].flights.length-1}} {{'STOPS'|translate}}
                      </span>
                      <span *ngIf="searchResults.itineraries[i].sections[section.ref-1].flights.length==1">
                        {{'NON_STOPS'|translate}}
                      </span>
                      <span *ngIf="checkTechnicalStops(searchResults.itineraries[i].sections[section.ref-1].flights)">
                        <span *ngIf="checkTechnicalStops(searchResults.itineraries[i].sections[section.ref-1].flights)>1">-
                          {{checkTechnicalStops(searchResults.itineraries[i].sections[section.ref-1].flights)}}
                        </span>
                        <span *ngIf="checkTechnicalStops(searchResults.itineraries[i].sections[section.ref-1].flights)>1">
                          -
                          <span class="material-icons iconTech"> local_gas_station </span>
                          <span class="vertical">{{'TECHNICAL_STOPS'|translate}}</span>
                        </span>
                        <span *ngIf="checkTechnicalStops(searchResults.itineraries[i].sections[section.ref-1].flights)==1">
                          -
                          <span class="material-icons iconTech"> local_gas_station </span>
                          <span class="vertical">{{'TECHNICAL_STOP'|translate}}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col-3">
                    {{iataComplete.returnAirport(searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.location)}}<br/>
                    {{searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.dateTime|date:'dd/MM/yyyy':"+0000"}} -
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.hours)}}
                    {{returnZeroTime(searchResults.itineraries[i].sections[section.ref-1].flights[searchResults.itineraries[i].sections[section.ref-1].flights.length-1].arrival.minutes)}}
                  </div>

                  <div class="col-3 col-sm-2">
                    <button mat-icon-button *ngIf="recommendation.lowCost" (click)="opeSect(r,i,s)">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="!recommendation.lowCost && !ndcAma" (click)="opeSect(r,i,s); askFares(recommendation.ref,r+currentPage, true)">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="!recommendation.lowCost && ndcAma" (click)="opeSect(r,i,s)">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-checkbox class="marginleft5 align-bottom" *ngIf="!ndcAma"
                                  [disabled]="selectionFunction(recommendation.ref,i,itinerary.sections[s].ref)"
                                  (ngModelChange)="changeSelection(recommendation.ref,i,itinerary.sections[s].ref)"
                                  [ngModel]="(recommendation) ? selectionFunction(recommendation.ref,i,itinerary.sections[s].ref) : false">
                      <span>{{'SELECT'|translate}}</span>
                    </mat-checkbox>

                    <mat-checkbox class="marginleft5 align-bottom" *ngIf="ndcAma"
                                  [disabled]="selectionFunction(recommendation.ref,i,itinerary.sections[s].ref)"
                                  (ngModelChange)="changeSelectionAma(recommendation, r, recommendation.ref,i,itinerary.sections[s].ref)"
                                  [ngModel]="(recommendation) ? selectionFunction(recommendation.ref,i,itinerary.sections[s].ref) : false">
                      <span>{{'SELECT'|translate}}</span>
                    </mat-checkbox>

                  </div>
                </div>
              </div>

              <div class="col-12 collapse issection" id="collapse{{r}}{{i}}{{s}}">

                <div class="row" *ngFor="let flight of searchResults.itineraries[i].sections[section.ref-1].flights; let f = index;let fl = last;">

                  <div class="col-12 mb-2" *ngIf="f===0">
                    <div class="row">
                      <div class="col-1 text-center">
                        <img *ngIf="!ndcAma" class="marketingcarrierimage" src="assets/img/airline/icon/{{recommendation.marketingCarrier}}.png"/>
                        <img *ngIf="ndcAma" class="marketingcarrierimage" src="assets/img/airline/icon/{{searchResults.itineraries[i].sections[section.ref-1].flights[0].marketingCarrier}}.png"/>

                      </div>
                      <div class="col-9 align-self-center">
                        <div>{{iataComplete.returnCarrier(flight.marketingCarrier)}}</div>
                        <div class="md-titlebis">{{'DEPARTURE'|translate}}: {{flight.departure.date | date:'dd MMM yyyy'}}</div>
                        <div class="md-subhead">{{returnTime(searchResults.itineraries[i].sections[section.ref-1].flyingTime)}}</div>
                      </div>
                      <div class="col-2">
                        <button mat-icon-button (click)="opeSect(r,i,s)">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                        <mat-checkbox class="marginleft5 align-bottom" [disabled]="selectionFunction(recommendation.ref,i,itinerary.sections[s].ref)"
                                      (ngModelChange)="changeSelection(recommendation.ref,i,itinerary.sections[s].ref)"
                                      [ngModel]="(recommendation) ? selectionFunction(recommendation.ref,i,itinerary.sections[s].ref) : false">
                          <span>{{'SELECT'|translate}}</span>
                        </mat-checkbox>
                      </div>
                    </div>
                    <hr/>
                  </div>

                  <div class="col-md-1 text-center">
                    <img class="marketingcarrierimage" src="/assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-md-11">
                    <div class="flightdet">
                      <p class="headingseg">
                        {{returnZeroTime(flight.departure.hours)}}:{{returnZeroTime(flight.departure.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.departure.location)}} ({{flight.departure.location}})
                      </p>
                      <p class="headingdetails">
                        <!--{{'FLIGHT_TIME'|translate}}: {{calculateFlightTime(flight.departure.dateTime,flight.arrival.dateTime)}}-->
                        <span *ngIf="flight.departure.terminal"> Terminal: {{flight.departure.terminal}}</span>
                      </p>
                      <p class="fldetails">
                        <span *ngIf="flight.detail.technicalStop && flight.detail.technicalStop.from > 0">
                          <span class="material-icons iconTech"> local_gas_station </span>
                          <span class="vertical">
                            {{'TECHNICAL_STOP_IN'|translate}} {{iataComplete.returnAirport(flight.detail.technicalStop.location)}} {{flight.detail.technicalStop.location}}
                          </span><br/>
                          <span class="d-block pl-4">
                            {{'DURATION'|translate}}: {{convertMilliseconds(flight.detail.technicalStop)}}<br/>
                            {{'ARRIVAL'|translate}}:{{returnDate(flight.detail.technicalStop.from) | date:'dd MMM - HH:mm'}} -
                            {{'DEPARTURE'|translate}} {{returnDate(flight.detail.technicalStop.to) | date:'dd MMM - HH:mm'}}
                          </span>
                        </span>
                      </p>
                      <p class="headingseg">
                        <span class="redt" *ngIf="otherday(searchResults.itineraries[i].sections[section.ref-1].flights[0].departure.date | date:'dd',flight.arrival.date | date:'dd')">
                          {{flight.arrival.date | date:'dd MMM yyyy'}} -
                        </span>
                        {{returnZeroTime(flight.arrival.hours)}}:{{returnZeroTime(flight.arrival.minutes)}} -
                        {{'AIRPORT_OF'|translate}} {{iataComplete.returnAirport(flight.arrival.location)}} ({{flight.arrival.location}})
                      </p>
                      <p class="fldetails">
                        <span *ngIf="!recommendation.lowCost">
                          Booking Class: <span *ngIf="recommendation.tariff; else loadAnimation"> {{recommendation.tariff.bookingClasses[f+i]}} - </span>
                          <ng-template #loadAnimation>
                            loading... -
                          </ng-template>
                        </span>
                        {{flight.operatingCarrier}} {{flight.number}}
                        <span *ngIf="flight.detail && flight.detail.equipment"> - {{'EQUIPMENT'|translate}} {{flight.detail.equipment}}</span>
                        <span *ngIf="flight.arrival.terminal"> - Terminal: {{flight.arrival.terminal}}</span>
                      </p>
                    </div>

                    <div class="scalepause" *ngIf="!fl">
                      <span>
                        <i class="fas fa-random"></i> {{'STOP'|translate}} in {{flight.arrival.location}}
                        <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}} <!-- ({{flight.waitingTime}}ms) --> </span>
                      </span>
                      <span class="alert-danger changeairport" *ngIf="flight.arrival.location != searchResults.itineraries[i].sections[section.ref-1].flights[f+1].departure.location">
                      {{'CHANGE'|translate}}: {{searchResults.itineraries[i].sections[section.ref-1].flights[f+1].departure.location}}
                      </span>
                    </div>
                  </div>

                </div>
                <!--
                <div class="row" style="margin: 0;" *ngFor="let flight of searchResults.itineraries[i].sections[section.ref-1].flights; let f = index;let fl = last;">
                  <div class="col-lg-1 col-md-2">
                    <img class="marketingcarrierimage" src="assets/img/airline/icon/{{flight.operatingCarrier}}.png"/>
                  </div>
                  <div class="col-lg-4 col-md-2">
                    {{flight.departure.location}}<br/>
                    {{flight.departure.dateTime|date:'dd/MM/yyyy'}} -
                    {{returnZeroTime(flight.departure.hours)}}:
                    {{returnZeroTime(flight.departure.minutes)}}
                  </div>
                  <div class="col-lg-3 col-md-2">
                    {{flight.marketingCarrier}} {{flight.number}}
                  </div>
                  <div class="col-lg-4 col-md-2">
                    {{flight.arrival.location}}<br/>
                    {{flight.arrival.dateTime|date:'dd/MM/yyyy'}} -
                    {{returnZeroTime(flight.arrival.hours)}}:
                    {{returnZeroTime(flight.arrival.minutes)}}
                  </div>
                  <div class="col-12" *ngIf="flight.waitingTime">
                    <hr/>
                    <div class="text-center whitebg">
                      {{'STOP'|translate}} in {{flight.arrival.location}}
                      <span *ngIf="flight.waitingTime"> {{'WAITING'|translate}}: {{returnTime(flight.waitingTime)}}</span>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="!fl">
                    <hr/>
                  </div>
                </div>
                -->
              </div>

              <div class="col-12" *ngIf="!z">
                <hr/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="overflow-hidden bg-white">
    <div class="row">
      <div class="col-md-6 col-12 go-to-container text-right">
        <span class="smalltext">{{'PAGE'|translate}}</span>
        <mat-form-field>
          <mat-select [(ngModel)]="requestedPage" placeholder="{{currentPage+1}}">
            <mat-option *ngFor="let data of returnPageLength(); let i = index" [value]="i+1" (click)="goToPage(i)">
              {{i+1}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="smalltext">{{'OF'|translate}} {{returnPagesNumber()}}</span>
      </div>
      <div class="col-md-6 col-12">
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)"></mat-paginator>
      </div>
    </div>
  </div>


  <div class="filterSearch">

    <div class="carrierFilter mb-2 pb-2">
      <div class="mb-1"><label>{{'CARRIER'|translate}}</label></div>

      <span class="singleFilterCarrier" *ngFor="let carrier of carriersAvailable; let i = index" [ngClass]="{'greenborder':isIncludedCarr(carrier)}"
            (click)="filterCarrier(carrier)">
        <img [matTooltip]="iataComplete.returnCarrier(carrier)" class="filterCarrier" src="assets/img/airline/icon/{{carrier}}.png"/>
      </span>
    </div>
    <mat-accordion *ngIf="this.lowCost" class="">
      <div *ngIf="carrierToFilter.length > 0" class="my-2 py-2">Tariffe</div>
      <div *ngFor="let filter of this.fareFIlter" class="">
        <mat-expansion-panel hideToggle  *ngIf="carrierToFilter.includes(filter.marketing)">
          <mat-expansion-panel-header>
            <mat-panel-description class="justify-content-between">
              <img [matTooltip]="iataComplete.returnCarrier(filter.marketing)" class="filterCarrier" src="assets/img/airline/icon/{{filter.marketing}}.png"/>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="pt-2 pb-2">
            <div class="mb-1" *ngFor="let fare of filter.fares">
              <mat-checkbox [(ngModel)]="fare.selected" (ngModelChange)="updateFareFilter($event, fare)">
                {{fare.tarif}}
              </mat-checkbox>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
    <hr/>
    <div class="">
      <div class="mb-1"><label>{{'PRICE'|translate}}</label></div>
      <div class="row">
        <div class="col-4">
          {{minPriceModel}},00€
        </div>
        <div class="col-4 text-center">
          <span class="material-icons"> sync_alt </span>
        </div>
        <div class="col-4 text-right">
          {{maxPriceModel}},00€
        </div>
      </div>
      <div class="row px-2" style="overflow-x: hidden;">
        <div class="col-6">
          <mat-slider class="px-2" color="primary" [max]="maxPriceModel" [min]="minPrice" [step]="1" style="width: 90%!important;min-width: 90%;">
            <input matSliderThumb [(ngModel)]="minPriceModel" (change)="doSort()" #slider />
            <!--<input matSliderThumb /><input matSliderThumb />-->
          </mat-slider>
        </div>
        <div class="col-6">
          <mat-slider class="px-2" color="primary" [max]="maxPrice" [min]="minPriceModel" [step]="1" style="width: 90%!important;min-width: 90%;">
            <input matSliderThumb [(ngModel)]="maxPriceModel" (change)="doSort()" #slider />
            <!--<input matSliderThumb /><input matSliderThumb />-->
          </mat-slider>
        </div>
      </div>
    </div>
    <hr/>
    <div class="stopsFilter">
      <div *ngFor="let iti of searchResults.itineraries; let i = index; let l = last">
        <div>
          {{'TIME'|translate}} {{'SECTION'|translate}} {{i+1}}
          <!--
          {{searchResults.itineraries[i].sections[0].flights[0].departure.location}}
          <span class="material-icons">
            arrow_right_alt
          </span>
          {{searchResults.itineraries[i].sections[0].flights[searchResults.itineraries[i].sections[0].flights.length-1].arrival.location}}
          -->
        </div>

        <mat-radio-group aria-labelledby="stops-label" class="stops-radio-group" [(ngModel)]="defaultFilter[i]" color="primary"
                         (ngModelChange)="changeTimeFilter($event, i)">
          <mat-radio-button class="stop-radio-button" *ngFor="let time of filterTime" [value]="time">
            {{time|translate}}
            <span *ngIf="time == 'morning'">(06 - 12)</span>
            <span *ngIf="time == 'afternoon'">(12 - 18)</span>
            <span *ngIf="time == 'evening'">(18 - 24)</span>
            <span *ngIf="time == 'night'">(0 - 6)</span>
          </mat-radio-button>
        </mat-radio-group>
        <hr *ngIf="!l"/>
      </div>


    </div>
    <hr/>
    <div class="mb-1"><label id="stops-label">{{'MAX_STOPS'|translate}}</label></div>
    <div class="stopsFilter" *ngFor="let stops of availableStops; let i = index; let l = last">
      <div>
        {{'SECTION'|translate}} {{i+1}}
      </div>
      <!--
      <div>
        {{searchResults.itineraries[i].sections[0].flights[0].departure.location}}
        <span class="material-icons">
          arrow_right_alt
        </span>
        {{searchResults.itineraries[i].sections[0].flights[searchResults.itineraries[i].sections[0].flights.length-1].arrival.location}}
      </div>
      -->
      <mat-radio-group color="primary" aria-labelledby="stops-label" class="stops-radio-group" [(ngModel)]="selectedStops[i]" (ngModelChange)="changeStops($event, i)">
        <mat-radio-button class="stop-radio-button" *ngFor="let stop of stops" [value]="stop">
          <span *ngIf="stop === 0">{{'NON_STOPS'|translate}}</span>
          <span *ngIf="stop === 1">{{stop}} {{'STOP'|translate}}</span>
          <span *ngIf="stop > 1">{{stop}} {{'STOPS'|translate}}</span>
        </mat-radio-button>
      </mat-radio-group>
      <hr *ngIf="!l"/>
    </div>
    <hr>
  </div>
  <div (click)="toggleFilters()" class="filtersearchBackdrop"></div>
</div>
