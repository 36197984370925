import { Component, Input, OnInit } from '@angular/core';
import { IatacompleteService } from "../../../services/iatacomplete/iatacomplete.service";

@Component({
  selector: 'app-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss']
})
export class FlightCardComponent implements OnInit {
  productDetails: any;
  @Input() search: any;
  departures: string[] = [];
  iataCodes: string[] = [];
  airports: any = {};
  constructor(
    public iataComplete: IatacompleteService
  ) {}

  ngOnInit(): void {
    this.productDetails = this.search.productDetails ;
    this.getCities();
  }

  getCities(){
    this.productDetails.itineraries.forEach((i:any)=> {
      i.flights.forEach((f:any) => {
        this.iataCodes.push(f.departure.location);
        this.iataCodes.push(f.arrival.location);
      })
    })
    this.iataComplete.getAirports({iata : this.iataCodes}).subscribe((res:any)=> {
      Object.keys(res).forEach((key:any)=>{
        this.airports[key] = res[key];
      })
    })
  }

  getFlyingTIme(h:any){
    const fromPNR = new Date(h).getDate();
    return new Date(h).getHours() + (24*(fromPNR-1)) + ':' + new Date(h).getMinutes() + ':00';
  }
}
