import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchesService} from '../../services/searches.service';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";
import {ProductInfoDialogComponent} from "../../../shared/product-info-dialog/product-info-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-all-searches',
  templateUrl: './all-searches.component.html',
  styleUrls: ['./all-searches.component.scss']
})
export class AllSearchesComponent implements OnInit {

  allSearches: any;
  displayedColumns: string[] = ['product','createdAt','updatedAt', 'status', 'actions']
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  constructor(
    private searchesService: SearchesService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll(){
    this.searchesService.getAll().subscribe((res:any)=> {
      this.allSearches = this.mapSearchStatus(res);
      this.dataSource = new MatTableDataSource<any>([...this.allSearches.data]);
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      console.error('Error => ', error);
    })
  }

  mapSearchStatus(payload:any): any {
    const searches = payload.data;
    searches.map((search: any) => {
      if (!search.authorisations) return search;
      searches.map((s:any) => {
        const declined = s.authorisations.find((x:any) => x.status === 'declined');
        if (!!declined) {
          s.status = 'declined';
          return s;
        }
        const approved = s.authorisations.every((x:any) => x.status === 'approved');
        if (!!approved) {
          s.status = 'approved';
          return s;
        }
        s.status = 'pending';
        return s;
      });
      search.authorisations.map((auth:any) => {
        const declined = auth.authorisationUser.find((x:any) => x.status === 'declined');
        if (!!declined) {
          auth.userResponse = declined?.requestUser;
          auth.userResponeNote = declined.note;
          return search;
        }
        const approved = auth.authorisationUser.find((x:any) => x.status === 'approved');
        if (!!approved) {
          auth.userResponse = approved?.requestUser;
          auth.userResponeNote = approved.note;
          return search;
        }
        auth.userResponse = null;
        auth.userResponeNote = null;
        return search;
      });
    });
    return {...payload, data: searches};
  }

  deleteSearch(elem:any){
    this.searchesService.deleteSearch(elem._id).subscribe((res)=>{
      if (res.success) {
        this.getAll();
      } else{
        console.error(res);
      }
    }, error => {
      console.error('Error => ', error);
    })
  }

  continueBooking(elem: any): void {
    if (elem.ref === 'Car') {
      this.router.navigate(['/car-rent/reservations/' + elem.productDetails._id]).then();
    } else if (elem.ref === 'Flight') {
      this.router.navigate(['/flight/reservations/' + elem.productDetails._id]).then();
    } else if (elem.ref === 'Hotel') {
      this.router.navigate(['/hotel/reservations/' + elem.productDetails._id]).then();
    } else if (elem.ref == 'TrainReservation'){
      this.router.navigate(['/trains/reservations/' + elem.productDetails.pnr]).then();
    }
  }

  showProductInfo(elem:any){
    const dialogRef = this.dialog.open(ProductInfoDialogComponent, {
      maxWidth: '650px',
      minWidth: '600px',
      autoFocus: false,
      data: {
        search: elem
      }
    });

    dialogRef.afterClosed().subscribe((res:any)=> {
      if (res){
        if (res.continueBook) this.continueBooking(elem);
        else if (res.delete) {
          this.deleteSearch(elem);
        }
      }
    })
  }
}
