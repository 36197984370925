import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: [ './confirmation-dialog.component.scss' ]
})
export class ConfirmationDialogComponent implements OnInit {

  isDeleteCondition: boolean = false;
  isDeleteSearch: boolean = false;
  is3DS: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data :any) {
  }

  ngOnInit(): void {
    if (this.data.action === 'deleteCondition') this.isDeleteCondition = true;
    else if (this.data.action === 'deleteSearch') this.isDeleteSearch = true;
    else if (this.data.action === '3DS') this.is3DS = true;
  }

}
