<h1 mat-dialog-title>{{'CONFIRM_EMISSION'|translate}}</h1>
<div mat-dialog-content>
  <div class="row mt-1 pt-1" >
    <div class="col-12">
      <app-tf-fare-rules [fareRules]="data.reservation?.Router?.SupplierInfoList?.SupplierInfo || []"></app-tf-fare-rules>
    </div>
  </div>
  <hr class="mt-2 mb-2">
  <div *ngIf="data.priceChange" class="mb-2">
    {{'PRICE_HAS_CHANGED'|translate}}
  </div>
  <p>{{'CONFIRM EMISSION_QUESTION'|translate}} {{data.reservation.Router.GroupList.Group.Price.Amount|number:'.2-2'}} {{data.reservation.Router.GroupList.Group.Price.Currency}}
    <span *ngIf="!data.isSbt && !data.isGat && !data.isCau">
      + 6.00€ fee
      <span class="material-icons smaler" matTooltip="{{'LOWCOSTFEE' | translate}}">
       info
      </span>
    </span>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'ABORT'|translate}}</button>
  <button mat-button *ngIf="!data.isNotify" (click)="data.accept = true" [mat-dialog-close]="data.accept" cdkFocusInitial>{{'BUY_RESERVATION'|translate}}</button>
  <button mat-button *ngIf="data.isNotify" (click)="data.accept = true" [mat-dialog-close]="{accept : data.accept, notify: true}" >{{'POLICY.BUY_AND_NOTIFY'|translate}} e notifica</button>
</div>
