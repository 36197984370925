import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MainComponent } from '../../../../mainmodule/main/main.component';
import * as $ from 'jquery';
import { DossierService } from '../../../../../services/dossier/dossier.service';
import { TkbottomsheetComponent } from '../../../../ticketing/components/tkbottomsheet/tkbottomsheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdmserviceService } from '../../../../../services/adminserv/admservice.service';
import { CompaniesService } from '../../../../../services/profile/companies.service';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import { CustomAuthService } from '../../../../../services/customAuth/custom-auth.service';
import { UsersService } from '../../../../../services/profile/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HotelService } from '../../../hotel.service';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { AppComponent } from '../../../../../app.component';
import { catchError, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TravelPolicyService } from '../../../../../services/travel-policy/travel-policy.service';
import { SearchInformationService } from '../../../../../components/search-information/services/search-information.service';
import { Destination, HotelForm, HotelOptions, Occupancy } from '../../../classes/hotel';
import { CostcenterService } from '../../../../../services/costcenter/costcenter.service';
import { MatDialog } from '@angular/material/dialog';
import { CostCenterDialogComponent } from '../../../../shared/cost-center-dialog/cost-center-dialog.component';
declare var google : any;

@Component({
  selector: 'app-hotelreservation',
  templateUrl: './hotelreservation.component.html',
  styleUrls: ['./hotelreservation.component.scss'],
})
export class HotelreservationComponent implements OnInit{
  loading : boolean;
  param : any;
  hotelId : string;
  currentTab : string;
  reservation : any;
  deleting : boolean;
  tabs : any[];
  hotelsInfos : any;
  booking : boolean;
  /**
   *  local variable for map
   */
  map : any;
  locator : string;
  status : string;
  taxHotel : number;
  numberOfNights : any;
  newuser : any;
  text : string;
  userList : any;
  userToLog : any;
  accepted: boolean;
  fops: any;
  methods: any;
  availablePayments = 0;
  images = [];
  search:any;
  transferId: any;
  policyTransfer: any;
  favorites: any;
  loadedFav = false
  priceConfirmed = true;
  requoting = false;
  hotelForm: any;
  singleOccupancy : Occupancy;
  costCenter: any[];
  loadingCenters: boolean = false;
  updating: boolean = false;
  listCorporate: any[] = [];
  constructor(
    private snackBar : MatSnackBar,
    private route : ActivatedRoute,
    public mainComponent : MainComponent,
    private hotelService : HotelService,
    private dossierService : DossierService,
    private bottomSheet : MatBottomSheet,
    private admServ : AdmserviceService,
    private companyService : CompaniesService,
    private httpservice : HttpserviceService,
    public customAuth : CustomAuthService,
    private usersService : UsersService,
    public appComponent: AppComponent,
    private policyService: TravelPolicyService,
    private searchInformationService: SearchInformationService,
    private router: Router,
    private costCenterService:CostcenterService,
    public dialog: MatDialog,
  ){
    this.locator = '';
    this.taxHotel = 0;
    this.loading = true;
    this.accepted = false;
  }

  openCostCenterDialog() {
    const dialogRef = this.dialog.open(CostCenterDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result){
        console.log(result);
        this.reservation.costCenter = result;
        this.getCostsCenters();
        this.updateCostCenter();
      }
    });
  }

  getCorporates(){
    this.companyService.getCorporateCode('hotel', this.mainComponent.loggedUser.company._id).subscribe(data => {
        this.listCorporate = data.list
      }, error => {
          this.listCorporate = [];
      });
  }

  isCorporate(rate){
    let corporate = false;
    const ind = this.listCorporate.findIndex(c => c.code == rate.code);
    if(ind > -1){
     corporate = true;
    }
    return corporate;
 }

  ngOnInit() : void{
    this.getCorporates();
    this.route.params.subscribe(params => {
      this.param = params.id;
      this.getHotelId(); // reset and set based on new parameter this time
    });
    this.getFavorites();
  }

  getFavorites(){
    this.loadedFav = false;
    // const query = '?skip=' + 0 + '&limit=' + 1000;
    this.hotelService.getFavorites_V3(1000, 0).subscribe((res:any) => {
      this.favorites = res.items;
      this.loadedFav = true;
      console.log('this.favorites => ', this.favorites);
    }, error => {
      this.loadedFav = true;
      console.error('Error => ', error);
    });
  }

  getHotelId(){
    this.hotelId = this.route.snapshot.paramMap.get('id');
    console.log('this.hotelId => ', this.hotelId);
    this.getHotel(this.hotelId);
  }

  setTab(index){
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tabs.length; i ++){
      this.tabs[i].active = false;
    }
    this.tabs[index].active = true;
    this.currentTab = this.tabs[index].name;
    if (index === 0){
      setTimeout(() => {
        this.initMap();
      }, 200);
    }
  }

  getFops(){
    this.companyService.getFops(this.reservation.company._id).subscribe((res) => {
      this.loading = false;
      this.fops = res;
      this.availablePayments = this.availablePayments + this.fops.length;
      this.getMethods();
    }, error => {
      this.loading = false;
      this.getMethods();
      // this.notifier.notify('error', error);
      console.error('Error => ', error);
    });
  }
  getMethods(){
    this.companyService.getMethods(this.reservation.company._id, false).subscribe((res) => {
      this.loading = false;
      this.methods = res;
      this.availablePayments = this.availablePayments + this.methods.length;
      this.generateTabs();
    }, error => {
      this.loading = false;
      this.generateTabs();
      console.error('Error => ', error);
    });
  }

  getHotel(id){
    this.loading = true;
    this.hotelService.getReservation(id).subscribe((res) => {
      this.loading = false;
      this.reservation = res;
      this.status = res.status;
      if (this.reservation.fare.taxHotel){
        this.taxHotel = this.reservation.fare.taxHotel;
      } else{
        this.taxHotel = 0;
      }
      this.getHotelInfos();
      console.log('reservation => ', res);
      this.getCompany(res.company._id);
      this.calculateNights();
      if(this.isPolicyEnabled() && this.reservation.approvalId) {
        this.getInfo()
      }
      if(this.isPolicyEnabled() && this.reservation.paper){
        this.getTransfer();
      }
      // tslint:disable-next-line:max-line-length
      /*if(this.checkPolicy() && (this.appComponent.isSbt || this.appComponent.isB2B) && this.reservation.paper && this.mainComponent.loggedUser.company.services.policy){
        this.getTransfer();
      }*/
      if(!this.reservation.waitingList && this.reservation.status === 'init'){
        if(!this.appComponent.isB2B && !this.mainComponent.loggedUser.role.superAdmin){
          this.getFops();
        }else {
          this.getMethods();
        }
      } else {
        this.generateTabs();
      }
      this.createHotelForm();
      if(this.appComponent.isB2B || this.appComponent.isSbt || this.mainComponent.loggedUser.role.superAdmin){
        console.log('costo');
        this.getCostsCenters();
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getCostsCenters(){
    this.costCenter = [];
    this.loadingCenters = true;
    const query = {
      sort: 'createdAt',
      limit: 1000,
      skip: 0,
      order: 'asc',
      text: '',
    };
    this.costCenterService.getCostCenterList(query, this.reservation.company._id).subscribe((res: any) => {
      this.loadingCenters = false;
      this.costCenter = res.list;
      console.log('this.costCenter', this.costCenter);
    }, error => {
      this.loadingCenters = false;
      console.error('Error => ', error);
    });
  }

  updateCostCenter(){
    this.updating = true;
    const body = {
      costCenter: this.reservation.costCenter,
    }
    this.hotelService.setHotelCostCenter(this.reservation._id, body).subscribe((res: any) => {
      this.updating = false;
      console.log('this.costCenter', this.costCenter);
    }, error => {
      this.updating = false;
      console.error('Error => ', error);
    });
  }

  createHotelForm(){
    // this.formLoaded = false;
    this.hotelForm = new HotelForm();
    this.hotelForm.nameSearch = true;
    this.hotelForm.hotels = [this.reservation.hotel.code];
    this.hotelForm.chain = '';
    this.hotelForm.strict = false;
    this.hotelForm.sort = 'RA';
    this.hotelForm.cache = 'Live'; // Live / LessRecent / VeryRecent
    this.hotelForm.checkIn = new Date(new Date(this.reservation.room.startDate).setHours(6));
    this.hotelForm.checkOut = new Date(new Date(this.reservation.room.endDate).setHours(6));
    this.singleOccupancy = new Occupancy();
    this.singleOccupancy.bedTypes = [];
    this.singleOccupancy.roomCategory = [];
    this.singleOccupancy.guests = [];
    const occupancy = JSON.parse(JSON.stringify(this.singleOccupancy));
    this.hotelForm.occupancy = [];
    this.hotelForm.occupancy.push(occupancy);
    this.hotelForm.options = new HotelOptions();
    this.hotelForm.options.stars = [];
    this.hotelForm.destination = new Destination();
    this.hotelForm.ratePlans = [];
    this.createGuests();
    console.log('form => ', this.hotelForm);
  }

  createGuests(){
    this.reservation.guests.forEach((g:any , i:number) => {
      if(g.type === 'ADT') {
        this.addGuestType(i, 10);
      }
    })
  }

  addGuestType(i, type){
    const newGuest = {
      code: type,
      quantity: 1,
      age: 12,
    };
    if (type !== 5){
      delete newGuest.age;
    }
    const index = this.hotelForm.occupancy[i].guests.findIndex(r => r.code === newGuest.code);
    if (index === - 1 || type === 5){
      this.hotelForm.occupancy[i].guests.push(newGuest);
    } else{
      this.hotelForm.occupancy[i].guests[index].quantity = this.hotelForm.occupancy[i].guests[index].quantity + newGuest.quantity;
    }
  }

  getTransfer(){
    this.policyService.getTransfer(this.reservation.paper).subscribe((res) => {
      console.log('res reprice => ', res);
      this.transferId = res.data._id;
      this.policyTransfer = res.data;
      console.log('transfer => ', res);
    }, error => {

      console.error('Error => ', error);
    });
  }

  transferApproved(){
    let valid = true;
    if (this.policyTransfer && this.reservation.approvalId && this.policyTransfer.arrayOfAlerts.length>0){
      if(this.policyTransfer.authorisations) {
        valid = false;
        const approved = this.policyTransfer.authorisations.every(x => x.status === 'approved');
        if (!!approved) {
          this.policyTransfer.status = 'approved';
          valid = true;
        }
      } else {
        valid = false;
      }
    }
    // console.log(' trf');
    return valid;
  }

  getInfo(){
    this.searchInformationService.getSearch(this.reservation.approvalId).subscribe((res) => {
      console.log('res reprice => ', res);
      this.search = res.data[0];
      this.returnSApproval();
    }, error => {

      console.error('Error => ', error);
    });
  }

  returnSApproval(){
    this.search.status = 'pending';
    const declined = this.search.userAuthorisations.find(x => x.status === 'declined');
    if (!!declined) {
      this.search.status = 'declined';
    }

    const approved = this.search.userAuthorisations.every(x => x.status === 'approved');
    if (!!approved) {
      this.search.status = 'approved';
    }
    // this.search.status = 'pending';
  }

  getCpSettings(){
    this.companyService.getCpSettings(this.reservation.company._id).subscribe((res) => {
      this.reservation.company.services = res;
      // this.loading = false;
    }, (error) => {
      // this.loading = false;
      console.log('error => ', error);
    });
  }

  generateTabs() : void{
    this.currentTab = 'HOTEL_INFOS';
    this.tabs = [
      {
        name: 'HOTEL_INFOS',
        active: true,
      },
      {
        name: 'TARIFFES',
        active: false,
      },
      {
        name: 'GUESTS',
        active: false,
      },
    ];
    // tslint:disable-next-line:max-line-length
    if (!this.reservation.waitingList && this.reservation.status !== 'cancelled' && this.reservation.status !== 'issued' && !(this.mainComponent.loggedUser.role.company === false && !this.customAuth.canBook(this.mainComponent.loggedUser.company._id))){
      const tab = {
        name: 'PAYMENT',
        active: false,
      };
      this.tabs.push(tab);
    }
    // tslint:disable-next-line:max-line-length
    if ((this.reservation.status === 'issued' || this.reservation.status === 'cancelled') && !this.reservation.waitingList && this.mainComponent.loggedUser.role.superAdmin){
      const tab = {
        name: 'PNR',
        active: false,
      };
      this.tabs.push(tab);
    }

    if (this.reservation.waitingList && this.reservation.status === 'init' && this.mainComponent.loggedUser.role.superAdmin){
      const tab = {
        name: 'PAYMENT',
        active: false,
      };
      this.tabs.push(tab);
    }
    if(this.availablePayments === 1){
      console.log('entro fop linked');
      const find = this.tabs.findIndex(r => r.name === 'PAYMENT');
      if(this.methods && this.methods.length > 0){
        // tslint:disable-next-line:max-line-length
        const findType = this.reservation.room.ratePlans[0].guarantee.accepted.findIndex(r => r.cardCode === this.methods[0].card.card.type);
        if (findType !== -1) {
          this.methods[0].accepted = true;
          this.reservation.creditcard = this.methods[0]._id;
          this.tabs.splice(find, 1);
        } else {

        }
      } else {
        this.reservation.creditcard = this.fops[0].indexes.lowcost;
        this.tabs.splice(find, 1);
      }
    }
  }

  isFreeCancellationBis(){
    return this.reservation.room.ratePlans[0].penalty[0] && this.reservation.room.ratePlans[0].penalty[0].absoluteDeadLine;
  }

  removeHr(date){
    date = new Date(date);
    date = date.setHours(date.getHours() - 4);
    return date;
  }

  calculateNights(){
    const oneDay = 1000 * 60 * 60 * 24
    const date1 = new Date(this.reservation.hotel.checkIn);
    const date2 = new Date(this.reservation.hotel.checkOut)
    if (date1.getMonth() === 11 && date1.getDate() > 25)
      date2.setFullYear(date2.getFullYear() + 1)
    const Result = Math.round(date2.getTime() - date1.getTime()) / (oneDay);
    this.numberOfNights = Math.floor(Result)
    console.log('this.numberOfNights =>', this.numberOfNights);
  }

  getCompany(id) : void{
    this.companyService.getCompany(id).subscribe((res) => {
      this.reservation.company = res;
      // tslint:disable-next-line:max-line-length
      this.reservation.company.image = this.httpservice.oldApiUrl + 'static/company/' + this.reservation.company._id + '.png?time=' + new Date().getTime();
      console.log('reservation => ', this.reservation.company);
      this.getCpSettings();
    });
  }

  getHotelInfos(){
    const body = {
      hotels: [this.reservation.hotel.code],
    };
    /*const body = {
     hotels: this.hotelToGetInfos.splice(1, 1),
     };*/
    this.loading = true;
    this.hotelService.askHotelInfos(body).subscribe(res => {
      this.loading = false;
      // console.log('hotelsInfos =>', res);
      this.hotelsInfos = res[0];
      console.log('hotelsInfos =>', this.hotelsInfos);
      if(this.hotelsInfos.info && this.hotelsInfos.info.images) {
        this.hotelsInfos.info.images.forEach(i => {
          this.images.push(new ImageItem({ src: i.list[5].url, thumb: i.list[5].url }),)
        });
      }
      setTimeout(() => {
        this.initMap();
      }, 5900);
    }, error => {
      this.loading = false;
      console.log('error hotelsInfos =>', error);
    });
  }

  cancelReservation(){
    this.deleting = true;
    this.hotelService.cancelReservation(this.reservation._id).subscribe((res : any) => {
      console.log('res => ', res);
      this.deleting = false;
      this.reservation.status = 'cancelled';
      this.toggleDeleteModal();
      this.getHotel(this.hotelId);
      if (this.reservation.paper){
        this.updateDossier();
      }
      // this.router.navigate(['/flight/search']);
    }, error => {
      this.deleting = false;
      console.error('res => ', error);
      this.toggleDeleteModal();
    });
  }

  /**
   *  Toggle modal for cancellation confirm
   */
  toggleDeleteModal(){
    $('#deletePnrModal').modal('toggle');
  }

  toggleRemoveDossierModal(){
    $('#RemoveDossierModal').modal('toggle');
  }

  confirmDossierRemove(){
    const body = {
      hotels: [this.hotelId],
    };
    this.dossierService.removeHotelsFromDossier(this.reservation.paper, body).subscribe((res) => {
      console.log('res => ', res);
      this.getHotel(this.hotelId);
    }, error => {
      console.error('res => ', error);
    });
    $('#RemoveDossierModal').modal('toggle');
  }

  toggleAddtoTravel(){
    if (!this.mainComponent.dossierId){
      $('#travelListModal').modal('toggle');
    } else{
      this.addHotels(this.hotelId);
    }
  }

  addHotels(id){
    const body = {
      hotels: [id],
    };
    this.dossierService.addHotelsToDossier(this.mainComponent.dossierId, body).subscribe((res) => {
      console.log('res => ', res);
      this.getHotel(this.hotelId);
    }, error => {
      console.error('res => ', error);
    });
  }

  closeAddtoTravel(){
    $('#travelListModal').modal('toggle');
    this.getHotel(this.hotelId);
  }

  initMap() : void{
    this.map = new google.maps.Map(document.getElementById('hotelMap') as HTMLElement, {
      center: { lat: this.hotelsInfos.info.position.latitude, lng: this.hotelsInfos.info.position.longitude },
      zoom: 14,
    });
    this.createMarker();
  }

  createMarker() : void{
    let address = '';
    if(this.reservation.hotel.address) {
      address = this.reservation.hotel.address.city + ' ' + this.reservation.hotel.address.line;
    }
    const infoWindow = new google.maps.InfoWindow({
      content: '<h5>' + this.reservation.hotel.name + '</h5>' + '<p style="font-size:12px;">' +
        address + '</p>',
    });
    const marker = new google.maps.Marker({
      position: { lat: this.hotelsInfos.info.position.latitude, lng: this.hotelsInfos.info.position.longitude },
      label: '🛏',
      map: this.map,
      // label: labels[i % labels.length],
    });
    marker.addListener('click', () => {
      infoWindow.open(this.map, marker);
      marker.setZIndex(100);
    });

    return marker;
  }

  openBottomSheetHelp(){
    const dialogRef = this.bottomSheet.open(TkbottomsheetComponent, {
      data: {
        hotelEmission: true,
        emission: false,
        discount: false,
        name: this.reservation.hotel.name,
        text: 'ASK_HOTEL_EMISSION_FOR',
      },
      disableClose: true,
    });
    this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(data => {
      console.log('admServ ', this.admServ.sended);
      if (this.admServ.sended){
        this.status = 'linked';
        this.updateReservation();
      }
      this.admServ.sended = false;
    });
  }

  toggleBookModal(){
    $('#repricePnrModal').modal('toggle');
  }

  onPaymentTabChanged(event){
    delete this.reservation.creditcard;
  }

  quotePnr(){
    this.requoting = true;
    this.booking = true;
    const reservation = {
      hotel: {
        code: this.reservation.hotel.code,
        name: this.reservation.hotel.name,
        provider: this.reservation.hotel.code.slice(0, 2),
        checkIn: this.reservation.room.startDate,
        checkOut: this.reservation.room.endDate,
        city: this.reservation.hotel.city,
        address: this.reservation.hotel.address,
      },
      guests: this.reservation.guests,
      room: this.reservation.room,
      session: this.reservation.session,
      b2b: false,
      // room: this.currentHotel.roomSelected.room,
    };
    if (this.appComponent.isB2B){
      reservation.b2b = true;
    }

    this.hotelService.quoteHotelRoom(reservation).subscribe(resp => {
      this.requoting = false;
      // console.log(resp);
      let text = '';
      if(this.reservation.room.originalAmount && this.reservation.room.originalAmount.amount){
        if(resp.rooms[0].amount !== this.reservation.room.originalAmount.amount){
          text = 'Il prezzo ha subito una variazione';
          this.priceConfirmed = false;
        } else {
          text = 'Il prezzo è stato confermato';
          this.priceConfirmed = true;
          this.askBook();
        }
      } else {
        if(resp.rooms[0].amount !== this.reservation.room.amount){
          text = 'Il prezzo ha subito una variazione';
          this.priceConfirmed = false;
        } else {
          text = 'Il prezzo è stato confermato';
          this.priceConfirmed = true;
          this.askBook();
        }
      }

      this.snackBar.open(text, 'ok', {
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      const newData = {
        hotel: resp.hotels[0],
        room: resp.rooms[0],
        fare: resp.rooms[0].roomRates[0],
      }
      newData.room.chain = newData.hotel.chain;
      // this.roomSelect(newData);
    }, error => {
      this.priceConfirmed = false;
      this.snackBar.open('La camera che hai selezionato non è piu disponibile', 'ok', {
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.requoting = false;
      this.booking = false;
    });
  }

  newQuote(){
    // tslint:disable-next-line:max-line-length
    const url = this.router.serializeUrl(this.router.createUrlTree(['/hotel/quotation'], { queryParams: { code:this.hotelForm.hotels[0], checkIn: this.hotelForm.checkIn, checkOut: this.hotelForm.checkOut, occupancy: JSON.stringify(this.hotelForm.occupancy) } }));
    window.open(url, '_self');
    // tslint:disable-next-line:max-line-length
    // this.router.navigate(['/hotel/quotation'], { queryParams: { code, checkIn: this.clonedForm.checkIn, checkout: this.clonedForm.checkOut, occupancy: JSON.stringify(this.clonedForm.occupancy) } });
  }

  askBook(){
    this.booking = true;
    const body = {
      cc: '',
      text: '',
    };
    body.cc = this.reservation.creditcard;
    delete body.text;
    console.log('body => ', body);
    this.hotelService.bookHotel(body, this.reservation._id).subscribe((res) => {
      console.log('reservation => ', res);
      this.booking = false;
      this.toggleBookModal();
      this.getHotel(this.reservation._id);
      if (this.reservation.paper){
        this.updateDossier();
      }
    }, error => {
      this.booking = false;
      console.error('Error => ', error);
      if (error.errorMessage.includes('Session')){
        this.getReprice();
      }
    });

  }

  getReprice(){
    this.loading = true;
    this.hotelService.getReprice(this.reservation._id).subscribe((res) => {
      this.loading = false;
      this.getHotel(this.reservation._id);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  updateReservation(){
    const body = {
      status: '',
      locator: '',
      taxHotel: 0,
      property: {
        user: '',
        company: '',
      },
    };
    if (this.newuser && this.newuser.user && this.newuser.company){
      body.property = this.newuser;
    } else{
      delete body.property;
    }
    if (this.status !== this.reservation.status){
      body.status = this.status;
    } else{
      delete body.status;
    }
    if (this.locator !== ''){
      body.locator = this.locator;
    } else{
      delete body.locator;
    }
    body.taxHotel = this.taxHotel;
    /*
     setTimeout(() => {
     this.getHotel(this.reservation._id);
     }, 300);*/
    this.admServ.updateHotelRes(body, this.reservation._id).subscribe((res) => {
      console.log('reservation => ', res);
      this.getHotel(this.reservation._id);
      if (this.reservation.paper){
        this.updateDossier();
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  searchUser(value){
    delete this.userList;
    if (value.length >= 2){
      this.getUsersList(value);
    }
  }

  getUsersList(value){
    delete this.userList;
    delete this.userToLog;
    this.usersService.getUsersComplete(value).subscribe((res) => {
      this.loading = false;
      this.userList = res;
      console.log('this.airports => ', this.userList);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  clear(){
    this.text = '';
    delete this.userToLog;
    delete this.userList;
    delete this.newuser;
  }

  getUser(value){
    const user = value;
    delete this.userList;
    this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
    this.newuser = {
      user: user._id,
      company: user.company._id,
    };
    console.log(value);
  }

  selectFirst(){
    if (this.userList && this.userList[0]){
      const user = this.userList[0];
      delete this.userList;
      this.text = user.surname + ' ' + user.name + ' -> ' + user.company.name;
      this.userToLog = user;
      this.newuser = {
        user: user._id,
        company: user.company._id,
      };
    }
  }

  updateDossier(){
    this.admServ.updateDossier(this.reservation.paper).subscribe((res) => {
      console.log('reservation => ', res);
    }, error => {
      console.error('Error => ', error);
    });
  }

  togglePrintHotel() : void{
    $('#PrintModalHotel').modal('toggle');
  }

  checkPolicy() :boolean {
    // tslint:disable-next-line:max-line-length
    if ((this.appComponent.isSbt || this.appComponent.isB2B) && this.reservation.room.arrayOfAlerts?.length > 0 && this.mainComponent.loggedUser.company.services.policy){
      return !this.reservation.room.arrayOfAlerts.every((a:any) => a.block === false); // return false
    }
    return true;
  }

  isToNotify(){
    let isNotify = false;
    if(this.appComponent.isSbt || this.appComponent.isB2B){
      this.reservation.room.arrayOfAlerts.forEach((r:any)=> {
        isNotify = r.policy.isNotify ? r.policy.isNotify : false;
      })
    }
    return isNotify
  }

  bookAndNotify(){
    const travelers:any = [];
    const dossier = this.transferId || undefined;
    travelers.push(this.reservation.user._id);
    this.policyService.createHotelSearch(this.reservation, this.reservation.room, travelers, dossier).pipe(catchError(err => {
      this.snackBar.open(err.message, 'Error', {
        duration: 2000,
      });
      return throwError(err);
    })).subscribe(res => {
      this.saveApprovalId(res.data._id, true);
      this.quotePnr();
      if (this.priceConfirmed){
        this.postAuth(res, dossier, true);
        this.getHotel(this.hotelId);
      }
    });
  }

  requestAuthorization(){
    const travelers:any = [];
    const dossier = this.transferId || undefined;
    travelers.push(this.reservation.user._id);
    this.policyService.createHotelSearch(this.reservation, this.reservation.room, travelers, dossier).pipe(catchError(err => {
      this.snackBar.open(err.message, 'Error', {
        duration: 2000,
      });
      return throwError(err);
    })).subscribe(res => {
      this.saveApprovalId(res.data._id);
      this.postAuth(res, dossier);
    });
  }

  postAuth(res:any, dossier:any, isNotify: boolean = false){
    const authBody = this.policyService.createHotelAuthorization(this.reservation, this.reservation.room, res.data.searchId, dossier);
    authBody.forEach((auth:any) => {
      this.policyService.postAuthorization(auth).pipe(take(1), catchError(err => {
        this.snackBar.open(err.message, 'Error', {
          duration: 2000,
        });
        return throwError(err);
      })).subscribe(authRes => {
        let msg = '';
        if(isNotify) msg = 'POLICY.NOTIFICATION_SENT';
        else msg = 'POLICY.AUTHORIZATIONS_SENT';
        this.snackBar.open( this.mainComponent.appcomponent.translate.instant(msg), 'Close', {duration: 2000});
      });
    });
  }

  saveApprovalId(value: string, isNotify: boolean = false){
    const data = {
      approvalId: value,
    };
    this.hotelService.saveApprovaId(this.reservation._id, data).subscribe((res) => {
      /*console.log('res => ', res);*/
      if (!isNotify) this.getHotel(this.hotelId)
    }, error => {
      console.error('Error => ', error);
    });
  }

  print() : void{
    let printContents;
    let popupWin;
    printContents = document.getElementById('toPrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=900px,width=1200px\'');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
          <title>Hotel-Ticket</title>
          <style>
            #toPrint{
                font-size:18px;
                padding:10px;
                border:1px solid #ccc;
                background-color:#fff;
            }
            .companyImage{
              max-height:80px;
              max-width:400px;
            }
            .reservationimage img{
                width:30px;
                display:inline-block;
                border-radius:50%;
                border:1px solid #ccc;
                margin:0 5px 0 0;
                vertical-align: middle;
            }
            .material-icons{
                vertical-align: middle;
            }
            .pnrdeparture ,.smalltext{
              font-size:14px;
              color:#cfcfcf;
            }
            .locationspan ,.reservationPrice{
              font-size:16px;
            }
            table td{
                width:25%;
            }
            .operatingImage{
                width:30px;
                border-radius: 50%;
                border:1px solid #ccc;
            }
            .backPrint{
                padding:10px;
            }
            .maincabin{
              font-size: 10px;
              text-transform:uppercase;
            }
            .infoTar .material-icons, .infoBag .material-icons{
                font-size:18px;
                margin-right:5px;
                vertical-align: middle;;
            }
            .fakeTable{
              border:1px solid #ccc;
              padding:10px;
            }
            .fakeTable hr{
              margin:5px 0;
            }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`,
    );
    popupWin.document.close();
  }

  addToFavorites(hotel:any){
    this.hotelService.addFavorites_V3(this.reservation.hotel.code).subscribe((res:any) => {
      console.log('this.favorites => ');
      hotel.code = this.reservation.hotel.code;
      this.favorites.push(hotel);
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  isFavorite(value:string){
    return this.favorites !== undefined ? this.hotelService.isFavorite(this.favorites, value) : false;
  }

  removeToFavorites(hotel:any){
    this.hotelService.removeFavorites_V3(this.reservation.hotel.code).subscribe((res:any) => {
      console.log('this.favorites => ');
      const index = this.favorites.findIndex((f:any) => f.code === this.reservation.hotel.code);
      if(index !== -1){
        this.favorites.splice(index,1);
      }
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  isPolicyEnabled():boolean{
    return (this.appComponent.isSbt || this.appComponent.isB2B) && this.mainComponent.loggedUser.company.services.policy;
  }
}
