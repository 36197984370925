import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NoteModalComponent} from '../note-modal/note-modal.component';
import {ProductInfoDialogComponent} from 'src/app/components/shared/product-info-dialog/product-info-dialog.component';

@Component({
  selector: 'app-authorization-card',
  templateUrl: './authorization-card.component.html',
  styleUrls: [ './authorization-card.component.scss' ]
})
export class AuthorizationCardComponent implements OnInit {

  @Input() authorization: any;
  @Output() submitted = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private dialogRef : MatDialogRef<AuthorizationCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
  }

  ngOnInit(): void {
    this.authorization = this.data;
  }

  openDialog(type: string): void {
    const dialogRef = this.dialog.open(NoteModalComponent, {
      width: '300px',
      disableClose: true,
      data: {
        type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirmed) {
        this.dialogRef.close( { id: this.authorization._id, note: result.note, category: this.authorization.authorisation.ref.toLowerCase(), type: result.type });
      } /*else this.dialogRef.close(false)*/
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
