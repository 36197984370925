<div class="row">
  <div class="col-12 d-flex align-items-center">
    <p class="carName">{{'POLICY.CAR_.MODEL'|translate}}: {{productDetails.car.model|uppercase}}</p>
  </div>
</div>
<hr>
<div class="row px-2">
  <div class="col-6">
    <p class="subtitles">{{'POLICY.CAR_.INFOS'|translate}}: </p>
    <div class="">
      <div class="">
        <b>{{'POLICY.CAR_.PICKUP'|translate}}: </b> <br>
        {{productDetails.rentCompany.rentalLocations[0].address}}
      </div>
      <div class="">
        <b>{{'POLICY.CAR_.PICKUP_DATE'|translate}}:  </b> {{productDetails.car.pickUpInfo.date | date: 'dd/MM/yyyy H:m:s'}}
      </div>
      <div class="">
        <b>{{'POLICY.CAR_.DROP_OFF'|translate}}: </b> <br>
        {{productDetails.rentCompany.rentalLocations[1].address}}
      </div>
      <div class="">
        <b>{{'POLICY.CAR_.DROP_OFF_DATE'|translate}}:  </b> {{productDetails.car.dropOffInfo.date | date: 'dd/MM/yyyy H:m:s'}}
      </div>
      <div class="">
        <b>{{'POLICY.CAR_.RENT_COMPANY'|translate}}:  </b> {{productDetails.rentCompany.name}}
      </div>
    </div>
  </div>
  <div class="col-6">
    <p class="subtitles">{{'POLICY.CAR_.PRICE'|translate}} : </p>
    <div class="row">
      <div class="col-12 mt-1" *ngFor="let rate of productDetails.car.rates; let l = last">
        <p class="my-0">
          <b>{{'POLICY.CAR_.'+rate.type|uppercase|translate}}: </b> {{rate.amount}}
          <app-policy-alerts-icon *ngIf="l" [isB2B]="true" [isSbt]="true" [arrayOfAlerts]="search.alerts"></app-policy-alerts-icon>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 px-2">
    <hr ><!--*ngIf="search.status == 'declined' || search.status == 'approved'"-->
    <div class="row">
      <div class="col-6" *ngIf="search.authorisations.length > 0; else showAlerts">
        <div *ngIf="search.status === 'approved'">
          <b> {{'POLICY.APPROVED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.status === 'declined'">
          <b> {{'POLICY.DECLINED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.authorisations.length>0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.authorisations">
            <span> - {{authorization.note}} </span><br>
          </span>
        </div>
      </div>
      <ng-template #showAlerts>
        <div class="col-6" *ngIf="search.alerts.length > 0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.alerts">
            <span> - {{authorization.message}} </span><br>
          </span>
        </div>
      </ng-template>
      <div class="col-6">
        <div class="row" *ngIf="search.authorisations.length>0">
          <div class="col-12">
            <b> {{'NOTES'|translate}} : </b>
          </div>
          <div class="col-6" *ngFor="let authorization of search.authorisations" [ngClass]="{'col-12': search.authorisations.length == 1}">
            <span> - {{authorization.userResponeNote}} </span><br>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <hr>
    <mat-accordion>
      <mat-expansion-panel class="my-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="subtitles" *ngIf="productDetails.passengers.length > 1; else onePaxOnly">{{'POLICY.CAR_.PAXS'|translate}}:</p>
            <ng-template #onePaxOnly>
              <p class="subtitles">{{'POLICY.CAR_.PAX'|translate}}:</p>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-6 p-2" *ngFor="let guest of productDetails.passengers; let i=index;">
            <div class="">
              <b>{{'POLICY.CAR_.PAX_NUMBER'|translate}}: </b>{{i+1}}
            </div>
            <div class="">
              <b>{{'NAME'|translate}}: </b>{{guest.name}}
            </div>
            <div class="">
              <b>{{'SURNAME'|translate}}: </b>{{guest.surname}}
            </div>
            <div class="">
              <b>{{'EMAIL'|translate}}: </b>{{guest.email}}
            </div>
            <div class="">
              <b>{{'MOBILE_PHONE_NUMBER'|translate}}: </b> {{guest.phoneNumber}}
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

