import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationRequestsService } from '../../services/authorization-requests.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { AuthorizationCardComponent } from "../../components/authorization-card/authorization-card.component";

@Component({
  selector: 'app-all-authorization-requests',
  templateUrl: './all-authorization-requests.component.html',
  styleUrls: [ './all-authorization-requests.component.scss' ]
})
export class AllAuthorizationRequestsComponent implements OnInit {

  authorizations: any;
  displayedColumns: string[] = ['product','user' ,'createdAt', 'status', 'actions']
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  constructor(
    private authorizationsService: AuthorizationRequestsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAllAuth();
  }

  handleSubmit(payload: any): void {
    this.authorizationsService.sendAuthorizationResponse(payload).subscribe(result => {
      this.snackBar.open('Authorization sent successfully', 'Close');
        this.getAllAuth();
      },
      error => {
        this.snackBar.open(error.message, 'Close');
      }
    );
  }

  getAllAuth(){
    this.authorizationsService.getAuthorizations().subscribe((res:any)=> {
      this.authorizations = res;
      /*console.log(res)*/
      this.dataSource = new MatTableDataSource<any>([...this.authorizations.data]);
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      console.error('Error => ', error);
    })
  }
  showProductInfo(elem:any){
    let dialogRef = this.dialog.open(AuthorizationCardComponent, {
      minWidth: '600px',
      maxWidth: '650px',
      data: elem
    })
    dialogRef.afterClosed().subscribe((res:any)=> {
      if (res && res != false) {
        this.handleSubmit(res);
      }
    })
  }
}
