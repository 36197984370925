import { Component, OnInit, ViewChild } from '@angular/core';
import { CreatePolicyDialogComponent } from './create-policy-dialog/create-policy-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeletePolicyDialogComponent } from './delete-policy-dialog/delete-policy-dialog.component';
import { TravelPolicyService } from '../../services/travel-policy/travel-policy.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DefaultUserComponent } from './default-user/default-user.component';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";


@Component({
  selector: 'app-create-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  policyList: any[] = [];
  isLoading: boolean;
  displayedColumns: string[] = ['name', 'role', 'buttons']
  private rolesList: any;

  defaultUserLoading: boolean = false;
  defaultUser: any = null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  constructor(private matDialog: MatDialog,
              private router: Router,
              private travelPolicyService: TravelPolicyService
  ) {}

  ngOnInit(): void {
    this.fetchPolicies();
    this.fetchGroups();
    this.getDefaultUser();
  }

  getDefaultUser() {
    this.defaultUserLoading = true;
    this.travelPolicyService.getDefaultUser().pipe(take(1)).subscribe(res => {
      this.defaultUser = res.data.rules.defaultUser;
      this.defaultUserLoading = false;
    }, error => {
      this.defaultUserLoading = false;
    });
  }

  openSelectDefaultUserDialog(): void {
    const dialogRef = this.matDialog.open(DefaultUserComponent, {
      width: '500px',
      minWidth: '350px',
      height: 'auto',
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.getDefaultUser();
    });
  }

  /**
   * Get policies from api
   */
  fetchPolicies() {
    this.travelPolicyService.getPoliciesList().subscribe(response => {
      this.isLoading = false;
      this.policyList = response.data;
      this.generatePagination(this.policyList);
    });
  }

  /**
   * Get groups from api
   */
  fetchGroups() {
    this.travelPolicyService.getGroupsList().subscribe(response => {
      this.isLoading = false;
      this.rolesList = response.data;
    });
  }


  generatePagination(data: any){
    this.dataSource = new MatTableDataSource<any>(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Dialog for creating new policy
   */
  createPolicyDialog(): void {
    const data = {
      options: {
        method : 'create',
        title: 'new_policy'
      },
      policies: this.policyList,
      rolesList: this.rolesList
    };
    let dialogRef = this.matDialog.open(CreatePolicyDialogComponent, {
      width: '500px',
      minWidth: '350px',
      data
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        if (result.policyName && result.policyRole) {
          const selectedRole = this.findRole(result.policyRole.value);
          const newPolicy = {
            name: result.policyName.value,
            role: selectedRole._id,
            configuration: [],
            tolerance: {
              hotels: result.hotels.value,
              flights: result.flights.value,
              cars: result.cars.value,
              trains: result.trains.value,
              transfer: result.transfer.value
            }
          };
          this.travelPolicyService.add(newPolicy).subscribe(()=> {
            this.fetchPolicies();
          }, error => {
            console.error(error);
          });
        } else if (result.createNewGroupDialog){
          this.router.navigate(['/allgroups']);
        }
      }
    });

  }

  findRole(roleName:string): any {
    return this.rolesList.find((role:any) => role.name === roleName);
  }


  deletePolicy(id:string, i: number): void {
    let dialogRef = this.matDialog.open(DeletePolicyDialogComponent, {
      width: '300px',
      minWidth: '280px',
      data:  this.policyList[i]
    });

    dialogRef.afterClosed().subscribe((result = null) => {
      if (result) {
        this.travelPolicyService.remove(id).subscribe(response => {
          this.fetchPolicies();
        });
      }
    });
  }

  editPolicy(id:any, i: number): void {
    const editablePolicy = this.policyList[i];
    editablePolicy.options = {
      policyList : this.policyList,
      rolesList : this.rolesList,
      method :'edit',
      title: 'edit'
    };
    /*console.log('editablePolicy',editablePolicy);*/
    let dialogRef = this.matDialog.open(CreatePolicyDialogComponent, {
      width: '500px',
      minWidth: '350px',
      data: editablePolicy
    });

    dialogRef.afterClosed().subscribe((result = null) => {
      if (result) {
        if (result.policyName && result.policyRole) {
          const role = this.findRole(result.policyRole.value);
          editablePolicy.name = result.policyName.value;
          editablePolicy.role = role._id;

          const newPolicy = {
            name: result.policyName.value,
            group: role._id,
            configuration: editablePolicy.configuration,
            tolerance: {
              hotels: result.hotels.value,
              flights: result.flights.value,
              cars: result.cars.value,
              trains: result.trains.value,
              transfer: result.transfer.value
            }
          };
          this.travelPolicyService.updatePolicy(newPolicy, id).subscribe(() => {
            this.fetchPolicies();
          });
        }
      }
    });
  }
}
