import {
  Component,
  OnInit,
} from '@angular/core';
import { AppComponent } from '../../../app.component';
import { UserLogin } from './login';
import { Md5 } from 'ts-md5/dist/md5';
import { AuthenticationService } from '../../../services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user = new UserLogin('', '');
  hide = true;
  hashed = new UserLogin('', '');
  loading = false;
  forgot = false;
  link: string;
  constructor(
    public appcompo: AppComponent,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {}

  ngOnInit() {
    // localStorage.clear();
    localStorage.removeItem('HotelForm2');
    localStorage.removeItem('hotels2Filters');
    localStorage.removeItem('CURRENT_SEARCH');
    localStorage.removeItem('hotels2');
    localStorage.removeItem('TRAIN_TRAVEL');
      localStorage.removeItem('fl_dossierId');
      localStorage.removeItem('fl_session_id');

    localStorage.removeItem('fl_dossierName');
    localStorage.removeItem('dossierItems');
    localStorage.removeItem('dossierPassengers');
    if (this.authService.isLoggedIn()){
      this.sendToHomeForDomain(this.authService.getUser());
    } else{
      this.authService.logout();
    }
  }

  login() {
    this.loading = true;
    if (!this.user.password) { return; }
    this.hashed.email = this.user.email;
    this.hashed.password = String(Md5.hashStr(String(this.user.password)));
    this.authService.loginForm(this.hashed).subscribe(response => {
      if (response) {
        localStorage.setItem('pwdtmp', JSON.stringify(this.hashed.password));
        this.loading = false;
        this.sendToHomeForDomain(response)
      }
    }, error => {
      this.loading = false;
      this.snackBar.open(this.appcompo.translate.instant('LOGIN_ERROR'), 'ok', {
        duration: 60000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      console.error('Error => ', error);
    });
  }

  sendToHomeForDomain(response){
    const location = window.location.href;
    const domain = (new URL(location)).hostname.replace('', '');
    if (!this.appcompo.isSbt && !this.appcompo.isGat) {
      if (domain.includes('application.flyleo.it') || domain.includes('pwa.lts-srl.it') || domain.includes('demo.lts-srl.it')) {
        if (response.profile.company.type === 'A' || response.profile.role.superAdmin) {
          this.authService.setUser(response);
          this.showAccessMessage(response);
        } else {
          this.link = 'application.b2btravel.it';
          this.openLoginModal();
        }
      } else if (domain.includes('application.b2btravel.it')) {
        if (response.profile.company.type !== 'A' || response.profile.role.superAdmin) {
          this.authService.setUser(response);
          this.showAccessMessage(response);
        } else {
          this.link = 'application.flyleo.it';
          this.openLoginModal();
        }
      } else if (domain.includes('suite4travel.gattinoni.it')) {
        if (response.profile.company.type !== 'A' || response.profile.role.superAdmin) {
          this.authService.setUser(response);
          this.showAccessMessage(response);
        } else {
          this.link = 'flygattinoni.it';
          this.openLoginModal();
        }
      } else if (domain.includes('stage.flyleo.it')) {
        if (response.profile.company.type !== 'A' || response.profile.role.superAdmin) {
          this.authService.setUser(response);
          this.showAccessMessage(response);
        } else {
          this.link = 'stage.flyleo.it';
          this.openLoginModal();
        }
      } else if (domain.includes('localhost')) {
        console.log('test site, logging');
        this.authService.setUser(response);
        // this.link = 'flygattinoni.it';
        this.openLoginModal();
        this.showAccessMessage(response);
      } else if (domain.includes('eataly.lts-srl.it') || domain.includes('b2b.lts-srl.it')) {
        if (response.profile.company.type !== 'A' || response.profile.role.superAdmin) {
          this.authService.setUser(response);
          this.showAccessMessage(response);
        } else {
          this.link = 'application.flyleo.it';
          this.openLoginModal();
        }
      } else if (domain.includes('treni.lts-srl.it') || domain.includes('fly.lts-srl.it')) {
        this.authService.setUser(response);
        this.showAccessMessage(response);
      } else if (domain.includes('gattinoni.lts-srl.it')) {
        this.authService.setUser(response);
        this.showAccessMessage(response);
      }
    } else {
      this.authService.setUser(response);
      this.showAccessMessage(response);
    }
  }

  openLoginModal(): void {
    $('#loginModal').modal('toggle');
  }

  showAccessMessage(response) {
    this.snackBar.open(this.appcompo.translate.instant('ACCESS_PERMITTED') + ' ' + response.profile.surname + ' ' + response.profile.name, 'X', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  sendForgot(): void {
    this.loading = true;
    const body = {
      email: this.user.email,
    };
    this.authService.forgotPassword(body).subscribe(response => {
      this.loading = false;
      this.forgot = false;
      this.snackBar.open(this.appcompo.translate.instant('NEW_PASSWORD_SENDED'), 'X', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }, error => {
      this.loading = false;
      this.snackBar.open(this.appcompo.translate.instant('ERROR'), 'ok', {
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      console.error('Error => ', error);
    });
  }

}
