<custom-title></custom-title>
<div class="padding20">
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="w-100" *ngIf="!allSearches">
          <h4>No Searches Found</h4>
        </div>
        <table mat-table class="w-100 policiesList" [dataSource]="dataSource" *ngIf="allSearches">

          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef>{{'POLICY.REQUEST_LIST.PRODUCT'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{'POLICY.'+element.ref|uppercase|translate}}</td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>{{'POLICY.REQUEST_LIST.CREATED_AT'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.createdAt|date:'EEEE, d MMM y, HH:mm ':'+0200'|titlecase}}</td>
          </ng-container>

          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef>{{'POLICY.REQUEST_LIST.UPDATED_AT'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.updatedAt|date:'EEEE, d MMM  y, HH:mm ':'+0200'|titlecase}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{'POLICY.REQUEST_LIST.STATUS'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <span [ngClass]="{'text-danger': element.status === 'declined', 'text-success': element.status === 'approved', 'text-warning': element.status == 'pending'}">
                {{element.status|uppercase|translate}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <button mat-icon-button class="miniIcons" (click)="getAll()" matTooltip="{{'REFRESH'|translate}}">
                <mat-icon>sync</mat-icon>
              </button>
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div class="buttonrowBis">
                <button (click)="showProductInfo(element)" mat-stroked-button>{{ 'DETAILS'|translate }}</button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <ng-container *ngIf="allSearches && allSearches.data.length <= 0">
          <div class="bg-white p-3 d-flex align-self-center justify-content-center" style="border-bottom: 1px solid #ccc">
            <mat-icon>mood_bad</mat-icon>
            <p class="m-0"> {{'POLICY.RECEIVED_REQUEST.NO_REQUEST_FOUND'|translate}} </p>
          </div>
        </ng-container>
        <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </ng-container>
</div>


<ng-template #loading>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
