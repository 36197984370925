import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AllAuthorizationRequestsComponent} from './containers/all-authorization-requests/all-authorization-requests.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthorizationCardComponent} from './components/authorization-card/authorization-card.component';
import {MatButtonModule} from '@angular/material/button';
import {NoteModalComponent} from './components/note-modal/note-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProductInfoDialogModule} from '../shared/product-info-dialog/product-info-dialog.module';
import {CustomCommonModule} from "../../modules/common/custom-common.module";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {MatPaginatorModule} from "@angular/material/paginator";
import {FlightCardModule} from "../shared/flight-card/flight-card.module";
import {HotelCardModule} from "../shared/hotel-card/hotel-card.module";
import {TrainCardModule} from "../shared/train-card/train-card.module";
import {VehicleCardModule} from "../shared/vehicle-card/vehicle-card.module";

const routes: Routes = [ {
  path: 'all-auth',
  component: AllAuthorizationRequestsComponent
} ];

@NgModule({
  declarations: [ AllAuthorizationRequestsComponent, AuthorizationCardComponent, NoteModalComponent ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ProductInfoDialogModule,
    CustomCommonModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatPaginatorModule,
    FlightCardModule,
    HotelCardModule,
    TrainCardModule,
    VehicleCardModule
  ]
})
export class AuthorizationRequestsModule {
}
