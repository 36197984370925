<div class="padding20">
  <custom-title></custom-title>
  <div class="text-right mb-3">
    <div class="mb-2">
      <a href="https://www.trenitalia.com/it/informazioni/condizioni_generaliditrasporto.html" target="_blank">{{'TRENIT_CONDITION'|translate}}</a>
    </div>
    <div class="mb-2">
      <a href="https://www.italotreno.it/it/offerte-treno/storico-offerte" target="_blank">{{'CONDITION_ITALO'|translate}}</a>
    </div>
  </div>
  <mat-card *ngIf="!loading else loadingBar" class="wpTrain">
    <mat-card-header>
      <div class="search-header-image" mat-card-avatar></div>
      <mat-card-title>{{ 'TRAIN_MODULE.SEARCHTRAINS' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'TRAIN_MODULE.FILLMODULE' | translate }}</mat-card-subtitle>
    </mat-card-header>
    <div *ngIf="formVisible" class="buttonrow mx-2 text-right">
      <button (click)="toggleForm()" class="mainbg" mat-stroked-button color="primary">
        <span *ngIf="formVisible">{{'TRAIN_MODULE.EDIT_SEARCH'|translate}}</span>
        <span *ngIf="!formVisible">{{'TRAIN_MODULE.HIDE_FORM'|translate}}</span>
      </button>
    </div>
    <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="{{'TRAIN_MODULE.TRAIN' | translate}}">
        <div style="overflow-x: hidden;">
          <train-search-trains [travellers]="travellers" (removeTraveller)="removePax($event)" (toggle)="toggleForm()" (removeAllPax)="removeAllPax()"
                               (setMaxPax)="setMaxPax($event)">
          </train-search-trains>
        </div>
      </mat-tab>
      <mat-tab label="{{'TRAIN_MODULE.SEARCH_CARNETS' | translate}}" *ngIf="trainConfig.trenitalia">
        <div style="overflow-x: hidden;">
          <train-search-carnets [travellers]="travellers" (removeTraveller)="removePax($event)" (setMaxPax)="maxPax = $event.maxPax; travellers = $event.travellers"
                                (toggle)="toggleForm()">
          </train-search-carnets>
        </div>
      </mat-tab>
      <mat-tab label="{{'TRAIN_MODULE.CARNET_ENABLED' | translate}}" *ngIf="trainConfig.trenitalia">
        <div style="overflow-x: hidden;">
          <train-use-carnets (toggle)="toggleForm()"></train-use-carnets>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <ng-template #loadingBar>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</div>

<!--{{travellers|json}}-->

<div aria-hidden="true" aria-labelledby="paxModal" class="modal fade pax-modal" data-backdrop="static" id="paxModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paxModalTitle">{{'TRAIN_MODULE.ADD_PAX.TITLE'|translate}}</h5>
        <button (click)="togglePaxModal()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body overflow-auto">
        <div class="">
          <app-passenger-cards [maxPax]="maxPax" [passengers]="travellers" (removePassengers)="removePax($event)" (setPassengers)="setTravellers($event)">
          </app-passenger-cards>
        </div>
        <div class="buttonrow text-right">
          <button (click)="confirmPax()" mat-raised-button color="primary" type="button">
            <span aria-hidden="true">{{'CONFIRM'|translate}}</span>
          </button>
        </div>

      </div>

    </div>
  </div>
</div>
