import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { ListCompanyComponent } from './components/companiescompo/list/list.component';
import { CompanyComponent } from './components/companiescompo/company/company.component';
import { UsersModule } from '../users/users.module';
import { FlightModule } from '../flight/flight.module';
import { FopsModule } from '../fops/fops.module';
import { PoliciesComponent } from './components/policies/policies.component';
import { HotelModule } from '../hotel/hotel.module';
import { CompanyrolesComponent } from './components/roles/companyroles/companyroles.component';
import { CorporateCodesComponent } from './components/companiescompo/company/corporate-codes/corporate-codes.component';
import { ServicesCpComponent } from './components/companiescompo/company/services/services.component';
import { CustomCommonModule } from '../common/custom-common.module';
import {FeesModule} from '../fees/fees.module';
import { RemarkDialogComponent } from './components/companiescompo/company/remark-dialog/remark-dialog.component';
import { CustomFieldsComponent } from './components/companiescompo/company/custom-fields/custom-fields.component';
import { FieldsDialogComponent } from './components/companiescompo/company/customFields/fields-dialog/fields-dialog.component';
import {AdminareaModule} from "../adminarea/adminarea.module";
import { B2cSettingsComponent } from './components/companiescompo/company/b2c-settings/b2c-settings.component';

@NgModule({
  declarations: [
    ListCompanyComponent,
    CompanyComponent,
    PoliciesComponent,
    CompanyrolesComponent,
    CorporateCodesComponent,
    ServicesCpComponent,
    RemarkDialogComponent,
    CustomFieldsComponent,
    FieldsDialogComponent,
    B2cSettingsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    UsersModule,
    FlightModule,
    FopsModule,
    HotelModule,
    CustomCommonModule,
    FeesModule,
    AdminareaModule
  ],
  exports: [
    ListCompanyComponent,
    CorporateCodesComponent,
  ],
})
export class CompaniesModule{}
