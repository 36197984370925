<div class="row">
  <div class="col-12 d-flex align-items-center">
    <p class="trainName">{{'POLICY.TRAIN_.TITLE'|translate}}  :</p> <p class="mx-3" style="font-size: 18px">{{productDetails.carrier|uppercase}}</p>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <p class="subtitles" *ngIf="productDetails.itineraries.length>1">{{'POLICY.TRAIN_.ITINERARIES'|translate}}: </p>
    <p class="subtitles" *ngIf="productDetails.itineraries.length == 1">{{'POLICY.TRAIN_.ITINERARY'|translate}}: </p>
    <div *ngFor="let itinerary of productDetails.itineraries;let l = last" [ngClass]="{'mb-2':!l}" class="row newItemPnr">
      <div class="col-5">
        <b>{{itinerary.from.date|date:'dd/MM/yyyy - HH:mm'}}</b>
        <br/>
        {{itinerary.from.station}}
      </div>
      <div class="col-2" matTooltip="{{ itinerary.trainClassification }} {{ itinerary.trainNumber }}">
        <span class="material-icons">arrow_right_alt</span>
      </div>
      <div class="col-5">
        <b>{{itinerary.to.date|date:'dd/MM/yyyy - HH:mm'}}</b>
        <br/>
        {{itinerary.to.station}}
      </div>
      <div class="col-12 mt-2" *ngIf="productDetails.carrier == 'TRENITALIA'">
        <b>{{'POLICY.TRAIN_.FARE_TYPE'|translate}}</b> : {{itinerary.fareType}} / <b>{{'POLICY.TRAIN_.BOOKING_CLASS'|translate}}</b> :  {{itinerary.bookingClass}}
      </div>
    </div>
  </div>
  <div class="col-12 d-flex align-self-end justify-content-end px-2">
    <b>{{'POLICY.TRAIN_.PRICE'|translate}}</b>  : &euro; {{checkPrice(productDetails.ratedPrice)}}
    <app-policy-alerts-icon [isB2B]="true" [isSbt]="true" [arrayOfAlerts]="search.alerts"></app-policy-alerts-icon>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <hr *ngIf="search.status == 'declined' || search.status == 'approved'">
    <div class="row">
      <div class="col-6" *ngIf="search.authorisations.length > 0; else showAlerts">
        <div *ngIf="search.status === 'approved'">
          <b> {{'POLICY.APPROVED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.status === 'declined'">
          <b> {{'POLICY.DECLINED_BY'|translate}} </b>
          <span matTooltip="{{search.authorisations[0].authorisationUser[0].requestUser.email}}">
            {{search.authorisations[0].authorisationUser[0].requestUser.surname}} {{search.authorisations[0].authorisationUser[0].requestUser.name}}
          </span>
        </div>
        <div *ngIf="search.authorisations.length>0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.authorisations">
            <span> - {{authorization.note}} </span><br>
          </span>
        </div>
      </div>
      <ng-template #showAlerts>
        <div class="col-6" *ngIf="search.alerts.length > 0">
          <b> {{'POLICY.POLICY_NOTE'|translate}} </b>
          <br>
          <span *ngFor="let authorization of search.alerts">
            <span> - {{authorization.message}} </span><br>
          </span>
        </div>
      </ng-template>
      <div class="col-6">
        <div class="row" *ngIf="search.authorisations.length>0">
          <div class="col-12">
            <b> {{'NOTES'|translate}} : </b>
          </div>
          <div class="col-6" *ngFor="let authorization of search.authorisations" [ngClass]="{'col-12': search.authorisations.length == 1}">
            <span> - {{authorization.userResponeNote}} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="travellersList.length>0">
  <div class="col-12">
    <hr>
    <mat-accordion>
      <mat-expansion-panel class="my-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="subtitles" *ngIf="travellersList.length > 1;  else singlePax">{{'POLICY.TRAIN_.PAXS'|translate}}:</p>
            <ng-template #singlePax>
              <p class="subtitles" *ngIf="travellersList.length == 1">{{'POLICY.TRAIN_.PAX'|translate}}:</p>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row" *ngIf="travellersList.length > 0">
          <div class="col-6 p-2" *ngFor="let pax of travellersList; let i=index;">
            <div class="">
              <b>{{'POLICY.TRAIN_.PAX_NUMBER'|translate}}: </b>{{i+1}}
            </div>
            <div class="">
              <b>{{'NAME'|translate}}: </b>{{pax.name}}
            </div>
            <div class="">
              <b>{{'SURNAME'|translate}}: </b>{{pax.surname}}
            </div>
            <div class="">
              <b>{{'EMAIL'|translate}}: </b>{{pax.email}}
            </div>
            <div class="">
              <b>{{'MOBILE_PHONE_NUMBER'|translate}}: </b> {{pax.phoneNumber}}
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

