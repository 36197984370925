import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpserviceService } from '../httpservice.service';
import { Router } from '@angular/router';
import { catchError, retry } from 'rxjs/operators';
import { SnackErrorComponent } from '../../modules/errorNotification/snackerror/snack-error.component';

export const randomID = () => '_' + Math.random().toString(36).substr(2, 12) + Math.random().toString(36).substr(2, 12);
const generateRandomId = () => '_' + Math.random().toString(36).substr(2, 12) + Math.random().toString(36).substr(2, 12);

@Injectable({
  providedIn: 'root',
})
export class TravelPolicyService extends HttpserviceService{
  private policyEndpoint = 'policy/travel-policies/';
  private policyEndpointBis = 'policy/';
  private authorizationEndpoint = this.policyEndpointBis + 'user-authorisation';
  private authorizationApproveEndpoint = this.policyEndpointBis + 'user-authorisation/approve/';
  private authorizationDeclineEndpoint = this.policyEndpointBis + 'user-authorisation/decline/';

  constructor(
    public router : Router,
    public httpClient : HttpClient,
    public snackComponent : SnackErrorComponent,
  ){
    super(router, httpClient, snackComponent);
  }

  /*
   list(): Observable<any> {
   return this.getMany(this.policyEndpoint);
   }
   */

  getPoliciesList() : Observable<any>{
    const policyListUrl = this.apiUrl + this.policyEndpoint;
    return this.http
               .get<any>(policyListUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  getGroupsList() : Observable<any>{
    const groupListUrl = this.apiUrl + 'policy/groups';
    return this.http
               .get<any>(groupListUrl, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  updatePolicy(body, id) : Observable<any>{
    const updatePolicy = this.apiUrl + this.policyEndpoint + id;
    const payload = {
      ...body,
      group: body.group,
      tolerance: body.tolerance,
    };
    /*delete payload.role
    delete payload.hotels
    delete payload.flights
    delete payload.trains
    delete payload.cars
    delete payload.transfer*/
    return this.http
               .put<any>(updatePolicy, payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  updateInPolicy(body, id) : Observable<any>{
    const updatePolicy = this.apiUrl + this.policyEndpoint + id;
    const payload = {
      ...body,
      group: body.group?._id,
      tolerance: {
        hotels: body.tolerance.hotels,
        transfer: body.tolerance.transfer,
        flights: body.tolerance.flights,
        cars: body.tolerance.cars,
        trains: body.tolerance.trains,
      },
    };
    delete payload.role
    delete payload.hotels
    delete payload.flights
    delete payload.trains
    delete payload.cars
    delete payload.transfer
    return this.http
               .put<any>(updatePolicy, payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  /*
   roleList(): Observable<any> {
   return this.getMany('api/groups/');
   }

   getDefaultUser(): Observable<any> {
   const path = `${environment.base_url}api/configurations`;
   return this.http.get(path);
   }

   show(id): Observable<any> {
   return this.getMany(this.policyEndpoint + id);
   }
   */

  getDefaultUser() : Observable<any>{
    const getUsers = this.apiUrl + this.policyEndpointBis + 'configurations';
    return this.http
               .get<any>(getUsers, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  saveDefaultUser(userId : string) : Observable<any>{
    const body = { user: userId };
    const postUser = this.apiUrl + this.policyEndpointBis + 'configurations';
    return this.http
               .post<any>(postUser, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  show(id) : Observable<any>{
    const showPolicy = this.apiUrl + this.policyEndpoint + id;
    return this.http
               .get<any>(showPolicy, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  /*

   update(body, id): Observable<any> {
   console.log(body);
   const payload = {
   ...body, group: body.role
   };
   console.log(payload);
   payload.role = undefined;
   return this.put(this.policyEndpoint + id, body);
   }

   remove(id): Observable<any> {
   return this.delete(this.policyEndpoint + id);
   }
   */

  remove(id) : Observable<any>{
    const updatePolicy = this.apiUrl + this.policyEndpoint + id;
    return this.http
               .delete<any>(updatePolicy, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  /*

   add(body): Observable<any> {
   const payload = {
   ...body, group: body.role, tolerance: {
   hotels: body.hotels,
   transfers: body.transfers,
   flights: body.flights
   }
   };
   console.log(payload);
   payload.role = undefined;
   payload.hotels = undefined;
   payload.flights = undefined;
   payload.transfers = undefined;
   return this.post(this.policyEndpoint, payload);
   }
   */

  add(body) : Observable<any>{
    const payload = {
      ...body,
      group: body.role,
      tolerance: body.tolerance
    };
    /*delete payload.role
    delete payload.hotels
    delete payload.flights
    delete payload.trains
    delete payload.cars
    delete payload.transfer*/
    const addPolicyUrl = this.apiUrl + this.policyEndpoint;
    return this.http
               .post<any>(addPolicyUrl, payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  createSearchTrain(trainReservation, arrayOfAlerts) : Observable<any>{
    const postSearch = this.apiUrl + this.policyEndpointBis + 'search';
    const payload = {
      searchId: randomID(),
      productType: 'train',
      productId: trainReservation._id,
      users: [trainReservation.user],
      transferId: trainReservation.paper,
      alerts: arrayOfAlerts?.length > 0 ? arrayOfAlerts : [],
    };
    return this.http.post<any>(postSearch, payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  createSearch(product, tariff, travelerId, transferId?) : Observable<any>{
    let alerts = [];
    // if (product.arrayOfAlerts.length) {
    //   alerts = [...alerts, ...product.arrayOfAlerts];
    // }

    if (tariff.arrayOfAlerts.length > 0){
      alerts = [...alerts, ...tariff.arrayOfAlerts];
    }
    console.log(product);
    console.log(alerts);
    const payload = {
      searchId: randomID(),
      productType: 'flight',
      productId: product._id,
      users: travelerId,
      transferId,
      alerts,
    };
    console.log(payload);
    const postSearch = this.apiUrl + this.policyEndpointBis + 'search';
    return this.http
               .post<any>(postSearch, payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  createAuthorization(product:any, tariff:any, searchId:string, transferId?:any) : any{

    const authorizationReqBody = [];
    const authorizationReqAlerts = [];
    tariff.arrayOfAlerts.map((a:any) => {
      if (a.policy.isAuthorized || a.policy.isNotify){
        authorizationReqAlerts.push(a);
      }
    });
    console.log(authorizationReqAlerts);
    authorizationReqAlerts.forEach((auth, index) => {
      const authorizationBody = {
        searchId,
        type: auth.policy?.isDefault === 1 ? 'default' : 'standard',
        isDefault : auth.policy.isDefault,
        isNotify: auth.policy.isNotify ? auth.policy.isNotify : false,
        configurationRule: auth.policy,
        policyId: auth.policy?.id,
        product: product._id,
        transferId: transferId || undefined,
        note: auth.message ? auth.message : '',
        authorization_users: auth.policy.authorization_users,
        authorization_roles: auth.policy.authorization_roles,
      };
      authorizationReqBody.push(authorizationBody);
    });
    return authorizationReqBody;
  }

  askAuth(body) : Observable<any>{
    const postAuth = this.apiUrl + this.policyEndpointBis + 'authorisation';
    return this.http
               .post<any>(postAuth, body, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  createVehicleSearch(vehicle:any, travelerId:any, transferId?:any) : Observable<any>{
    let alerts = [];
    if (vehicle.arrayOfAlerts.length){
      alerts = [...alerts, ...vehicle.arrayOfAlerts];
    }

    const payload = {
      searchId: randomID(),
      productType: 'car',
      productId: vehicle._id,
      users: travelerId,
      transferId: transferId || undefined,
      alerts,
    };
    const postAuth = this.apiUrl + this.policyEndpointBis + 'search';
    return this.http
               .post<any>(postAuth, payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  createCarAuthorization(vehicle:any, searchId:string, transferId?:any) : any{

    const authorizationReqBody = [];
    const authorizationReqAlerts = [];
    vehicle.arrayOfAlerts.map(alert => {
      if (alert.policy.isAuthorized || alert.policy.isNotify){
        authorizationReqAlerts.push(alert);
      }
    });

    console.log(authorizationReqAlerts);
    authorizationReqAlerts.forEach((auth, index) => {
      const authorizationBody = {
        searchId,
        type: auth.policy?.isDefault === 1 ? 'default' : 'standard',
        isDefault : auth.policy.isDefault,
        isNotify: auth.policy.isNotify ? auth.policy.isNotify : false,
        configurationRule: auth.policy,
        policyId: auth.policy?.id,
        product: vehicle._id,/*'6107bd54e41da816bc16dffe'*/
        transferId: transferId || undefined,
        note: auth.message,
        authorization_users: auth.policy.authorization_users,
        authorization_roles: auth.policy.authorization_roles,
      };
      authorizationReqBody.push(authorizationBody);
    });
    return authorizationReqBody;
  }

  createHotelSearch(product, room, travelerId, transferId?) : Observable<any>{
    console.log(travelerId);
    let alerts = [];
    if (product.hotel.arrayOfAlerts && product.hotel.arrayOfAlerts.length){
      alerts = [...alerts, ...product.hotel.arrayOfAlerts];
    }
    if (room.arrayOfAlerts && room.arrayOfAlerts.length){
      alerts = [...alerts, ...room.arrayOfAlerts];
    }
    console.log(alerts);
    const payload = {
      searchId: randomID(),
      productType: 'hotel',
      productId: product._id,
      users: travelerId,
      transferId,
      alerts,
    };
    const postSearch = this.apiUrl + this.policyEndpointBis + 'search';
    return this.http
               .post<any>(postSearch, payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  createHotelAuthorization(product, room, searchId, transferId?) : any{

    const authorizationReqBody = [];
    const authorizationReqAlerts = [];
    room.arrayOfAlerts.map(alert => {
      if (alert.policy.isAuthorized || alert.policy.isNotify){
        authorizationReqAlerts.push(alert);
      }
    });
    // flight.arrayOfAlerts.map(alert => {
    //   if (alert.policy.isAuthorized === true) {
    //     authorizationReqAlerts.push(alert);
    //   }
    // });

    console.log(authorizationReqAlerts);
    authorizationReqAlerts.forEach((auth, index) => {
      const authorizationBody = {
        searchId,
        type: auth.policy?.isDefault === 1 ? 'default' : 'standard',
        isDefault : auth.policy.isDefault,
        isNotify: auth.policy.isNotify ? auth.policy.isNotify : false,
        configurationRule: auth.policy,
        policyId: auth.policy?.id,
        product: product._id,
        transferId: transferId || undefined,
        note: auth.message,
        authorization_users: auth.policy.authorization_users,
        authorization_roles: auth.policy.authorization_roles,
      };
      authorizationReqBody.push(authorizationBody);
    });
    return authorizationReqBody;
  }

  postAuthorization(authorizationReqBody) : Observable<any>{
    const postAuth = this.apiUrl + this.policyEndpointBis + 'authorisation';
    return this.http
               .post<any>(postAuth, authorizationReqBody, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }


  sendEmail(searchId : string) : Observable<any>{
    const path = this.apiUrl + this.policyEndpointBis + 'authorisation/book/' + searchId;
    return this.http.post(path, {});
  }

  approve(id) : Observable<any>{
    return this.http
               .post<any>(this.apiUrl + this.authorizationApproveEndpoint, id, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
    // return this.post(this.authorizationApproveEndpoint + id);
  }

  decline(id) : Observable<any>{
    return this.http
               .post<any>(this.apiUrl + this.authorizationDeclineEndpoint, id, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
    // return this.post(this.authorizationDeclineEndpoint + id);
  }

  getAuthorizations() : Observable<any>{
    return this.http
               .get<any>(this.apiUrl + this.authorizationEndpoint, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
    // return this.getList(this.authorizationEndpoint);
  }

  createTransfer(transfer : any, name : any) : Observable<any>{
    const newTransfer = {
      dossierId: transfer._id,
      title: name,
      transferId: generateRandomId(),
      users: [transfer.user],
    };
    return this.http
               .post<any>(this.apiUrl + this.policyEndpointBis + 'transfers', newTransfer, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
    // transfer.users = [this.authService.currentUserSubject.getValue().data.user._id];
    // return this.http.post(this.policyEndpointBis, transfer);
  }

  getTransfer(transferId) : Observable<any>{
    return this.http
               .get<any>(this.apiUrl + this.policyEndpointBis + 'transfers/dossier/' + transferId, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
    // return this.http.get(`${this.url}/${transferId}`);
  }

  getUser(id) : Observable<any>{
    return this.http
               .get<any>(this.apiUrl + this.policyEndpointBis + 'users/' + id, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

  askDossierAuth(payload) : Observable<any>{
    return this.http
               .post<any>(this.apiUrl + this.policyEndpointBis + 'authorisation/transfer', payload, this.setHttpOptions())
               .pipe(
                 retry(0),
                 catchError(err => this.handleError(err)),
               );
  }

}
