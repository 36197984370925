<h1 mat-dialog-title> {{'POLICY.'+data.options.title|uppercase|translate}} </h1>
<mat-dialog-content>
  <div class="dialog__body">
    <form [formGroup]="createPolicyForm" class="dialog__form">
      <div class="dialog__form-group">
        <mat-form-field class="full-width">
          <mat-label>{{'POLICY.SELECT_NAME'|translate}}</mat-label>
          <input (input)="checkIfNameExist()"  formControlName="policyName" matInput placeholder="Ex. Manager" value="">
          <mat-error *ngIf="policyName.hasError('required')">
            {{'POLICY.NAME_REQUIRED'|translate}} <strong>{{'POLICY.REQUIRED'|translate}}</strong>
          </mat-error>
          <mat-error *ngIf="policyName.hasError('nameAlreadyExist')">
            {{'POLICY.NAME_ALREADY_EXIST'|translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dialog__form-group">

        <mat-form-field class="full-width" appearance="fill" *ngIf="freeRoles && freeRoles.length > 0; else createNewRole">
          <mat-label>{{'POLICY.SELECT_GROUP'|translate}}</mat-label>
          <mat-select (selectionChange)="checkIfRoleExist()" formControlName="policyRole">
            <mat-option *ngFor="let role of freeRoles" [value]="role.name">{{role.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="policyRole.hasError('required')">
            {{'POLICY.GROUP_REQUIRED'|translate}} <strong>{{'POLICY.REQUIRED'|translate}}</strong>
          </mat-error>
          <mat-error *ngIf="policyRole.hasError('roleAlreadyExist')">
            {{'POLICY.GROUP_ALREADY_EXIST'|translate}}
          </mat-error>
        </mat-form-field>
        <ng-template #createNewRole>
          <div class="my-2">
              <span class="my-3 buttonrowBis" *ngIf="data.options.method==='create'">
                {{'POLICY.NEED_NEW'|translate}}
                <button mat-stroked-button (click)="closeAndOpenNewGroupDialog()"> {{'POLICY.NEW_GROUP'|translate}} </button>
              </span>
            <span class="my-3" *ngIf="data.options.method==='edit'">
              {{'POLICY.ROLE_USED'|translate}} <br>
              <strong>{{ data.group.name }}</strong>
            </span>
          </div>
        </ng-template>
      </div>
      <div>
        <hr>
        <div class="row">
          <div class="col-12">
            <h4>{{'POLICY.TOLERANCES'|translate}}</h4>
          </div>
        </div>
        <div class="row" formGroupName="flights">
          <div class="col-12">{{'FLIGHT'|translate}}</div>
          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'POLICY.VALUE'|translate}}</mat-label>
              <input formControlName="value" type="number" matInput>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'POLICY.TYPE'|translate}}</mat-label>
              <mat-select formControlName="type">
                <mat-option value="percentage">%</mat-option>
                <mat-option value="euro">Euro</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div>
        <div class="row" formGroupName="hotels">
          <div class="col-12">{{'HOTELS'|translate}}</div>
          <div class="col-6">
            <div>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{'POLICY.VALUE'|translate}}</mat-label>
                <input formControlName="value" type="number" matInput>
              </mat-form-field>
            </div>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'POLICY.TYPE'|translate}}</mat-label>
              <mat-select formControlName="type">
                <mat-option value="percentage">%</mat-option>
                <mat-option value="euro">Euro</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div>
        <div class="row" formGroupName="cars">
          <div class="col-12">{{'CAR'|translate}}</div>
          <div class="col-6">
            <div>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{'POLICY.VALUE'|translate}}</mat-label>
                <input formControlName="value" type="number" matInput>
              </mat-form-field>
            </div>
          </div>

          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'POLICY.TYPE'|translate}}</mat-label>
              <mat-select formControlName="type">
                <mat-option value="percentage">%</mat-option>
                <mat-option value="euro">Euro</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div>
        <div class="row" formGroupName="trains">
          <div class="col-12">{{'TRAINS'|translate}}</div>
          <div class="col-6">
            <div>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{'POLICY.VALUE'|translate}}</mat-label>
                <input formControlName="value" type="number" matInput>
              </mat-form-field>
            </div>
          </div>

          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'POLICY.TYPE'|translate}}</mat-label>
              <mat-select formControlName="type">
                <mat-option value="percentage">%</mat-option>
                <mat-option value="euro">Euro</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div>
        <div class="row" formGroupName="transfer">
          <div class="col-12">{{'POLICY.DOSSIER'|translate}}</div>
          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'POLICY.VALUE'|translate}}</mat-label>
              <input formControlName="value" type="number" matInput>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'POLICY.TYPE'|translate}}</mat-label>
              <mat-select formControlName="type">
                <mat-option value="percentage">%</mat-option>
                <mat-option value="euro">Euro</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex align-self-center justify-content-end">
  <div class="buttonrowBis">
    <button mat-raised-button (click)="onNoClick()">{{'POLICY.CANCEL'|translate}}</button>
    <button mat-raised-button [disabled]="createPolicyForm.invalid" [mat-dialog-close]="createPolicyForm.controls" color="primary" autofocus>
      <span *ngIf="data.options.method==='edit'"> {{'SAVE'|translate}} </span>
      <span *ngIf="data.options.method==='create'"> {{'CREATE'|translate}} </span>
    </button>
  </div>
</mat-dialog-actions>

<!--<div class="dialog px-3 py-1">

</div>-->
