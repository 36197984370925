import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UsersGroupBulkService} from '../services/users-group-bulk.service';

@Component({
  selector: 'app-selected-users-group',
  templateUrl: './selected-users-group.component.html',
  styleUrls: ['./selected-users-group.component.scss']
})
export class SelectedUsersGroupComponent implements OnInit {

  @Input() selection: SelectionModel<any>;
  @Output() removeSelection = new EventEmitter();
  @Output() roleUpdated = new EventEmitter();
  @Output() selectFromGroup = new EventEmitter();
  rolesList = [];
  selectedRole: any;

  constructor(
    private usersGroupBulkService: UsersGroupBulkService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.usersGroupBulkService.rolesList().pipe(take(1)).subscribe(res => {
      this.rolesList = res.data;
      console.log('this.rolesList', res.data);
    })
  }

  remove(user:string): void {
    this.removeSelection.emit(user);
  }

  updateUserRoles(): void {
    if (this.selectedRole !== undefined){
      const roleId = this.selectedRole;
      console.log("Selected", this.selection.selected.length)
      const users = this.selection.selected.map(user => user._id);
      this.usersGroupBulkService.assignGroup({ user: users, group: roleId }).pipe(take(1)).subscribe(() => {
          this.snackBar.open('Users Assigned Successfully', 'Close');
          this.roleUpdated.emit(true);
          this.selectedRole = '';
        },
        error => {
          this.snackBar.open(error.message, 'Close');
        }
      );
    } else {
      this.snackBar.open('Please Select a Group', 'Failed', {
        duration: 2000
      });
    }
  }


}
