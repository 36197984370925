import {Component, Input, OnInit} from '@angular/core';
import {TravelersService} from "../../../services/travelers/travelers.service";

@Component({
  selector: 'app-train-card',
  templateUrl: './train-card.component.html',
  styleUrls: ['./train-card.component.scss']
})
export class TrainCardComponent implements OnInit {
  productDetails: any;
  @Input() search: any;
  travellersList: any[] = [];
  constructor(
    private travelersService: TravelersService,
  ) {
  }

  ngOnInit(): void {
    this.productDetails = this.search.productDetails;
    this.getTravellers();
  }

  getTravellers(){
    this.productDetails.travellers.forEach((i:any)=> {
      this.travelersService.getTraveler(i.doc).subscribe((res:any)=> {
        this.travellersList.push(res);
      })
    })
  }

  checkPrice(x:any){
    return x.toFixed(2);
  }
}
