<custom-title></custom-title>
<div class="padding20">
  <div *ngIf="loading" class="loader">
    <div class="card text-center padding20">
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      <h5>{{'LOADING'|translate}}...</h5>
    </div>
  </div>

  <!-- card start -->

  <div *ngIf="!loading && reservation" class="cardPnr marginbottom10">
    <div class="padding20">
      <div class="row">
        <div class="col-md-4 align-self-center">
          <div class="reservationimage">
            <img class="mb-2" src="assets/img/hotel-icon.png" style="margin:0">
            <div style="text-transform:uppercase;">{{reservation.hotel.name}} <sup *ngIf="mainComponent.loggedUser.role.superAdmin">({{reservation.hotel.provider}})</sup></div>
          </div>
          <div class="smalltext">{{'HOTEL_STAY'|translate}}: {{numberOfNights}} <span *ngIf="numberOfNights == 1">{{'NIGHT'|translate}}</span><span
            *ngIf="numberOfNights > 1">{{'NIGHTS'|translate}}</span></div>
          <div class="reservationPrice marginbottom5">
            <div class="smalltext">
              {{'TARIFFES'|translate}}: {{reservation.room.roomRates[0].amount|number:'.2-2'}}€
            </div>
            <div *ngIf="reservation.fare.taxHotel" class="smalltext">
              {{'TAX'|translate}}: {{reservation.fare.taxHotel|number:'.2-2'}}€
            </div>
            <div *ngIf="mainComponent.loggedUser.role.superAdmin && reservation.room.roomRates[0].amountBeforeTaxes > 0" class="mb-1 smalltext"> Net:
              <span>{{reservation.room.roomRates[0].amountBeforeTaxes|number:'.2-2'}} €</span>
            </div>
            <div class="recoprice">
              {{'TOTAL'|translate}}: {{(reservation.room.roomRates[0].amount + (reservation.fare.taxHotel || 0))|number:'.2-2'}}€
            </div>
            <div *ngIf="mainComponent.loggedUser.role.superAdmin" class="smalltext">{{reservation.room.amount|number:'.2-2'}}€</div>
            <div class="smalltext text-danger" *ngIf="reservation.fare && reservation.fare.manual">
              Il prezzo è cambiato: {{reservation.fare.manual.amount|number:'.2-2'}}€
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center mb-2">

          <!-- new statuses -->
          <div class="row">

            <div class="col-auto align-self-center">
              <div *ngIf="reservation.locator" [ngClass]="{'marginbottom5':reservation.status==='linked'}" class="pnrlocatorNew">
                <span>{{reservation.locator}}</span>
              </div>

              <div *ngIf="reservation.status==='issued' && reservation.chainLocators" class="mt-2 pnrlocatorNew">
                <span *ngFor="let locator of reservation.chainLocators">{{locator.chain}} {{locator.locator}}</span>
              </div>

            </div>
            <div class="col-auto ml-3 align-self-center">
              <span class="pnrNewStatus initNew" *ngIf="reservation.status==='init'">
                <span class="vertical material-icons mr-1"> warning </span>
                {{'TEMPLATE'|translate}}
              </span>
              <span class="pnrNewStatus linkedNew" *ngIf="reservation.status==='linked'">
                <span class="vertical material-icons mr-1"> check_circle </span>
                {{'LINKED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='issued'">
                <span class="vertical material-icons mr-1"> check_circle_outline </span>
                {{'ISSUED'|translate}}
              </span>
              <span class="pnrNewStatus issuedNew" *ngIf="reservation.status==='flown'">
                <span class="vertical material-icons mr-1"> check_circle_outline </span>
                {{'FLOWN'|translate}}
              </span>
              <span class="pnrNewStatus expiredNew" *ngIf="reservation.status==='expired'">
                <span class="vertical material-icons mr-1"> block </span>
                {{'EXPIRED'|translate}}
              </span>
              <span class="pnrNewStatus cancelledNew" *ngIf="reservation.status==='cancelled'">
                <span class="vertical material-icons mr-1"> block </span>
                {{'CANCELLED'|translate}}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-auto align-self-center">
              <div *ngIf="reservation.cancelledAt" class="mt-2 text-danger">
                {{reservation.cancelledAt|date: 'dd/MM/yyyy : HH:mm'}}
              </div>

              <div *ngIf="reservation.status=='issued'" class="mt-2 smalltext">
                {{'ISSUED_ON'|translate}} {{reservation.issuedOn|date:'dd/MM/yyyy'}}
              </div>

              <div *ngIf="reservation.changed" class="variationpnr">
                <mat-icon>warning</mat-icon>
                {{'CHANGE_PNR'|translate}}
              </div>
            </div>
          </div>
          <!-- new statuses -->

          <!--<div class="pnrstatus">

            &lt;!&ndash;<span *ngIf="reservation.status==='init' && !reservation.waitingList" class="init">{{'TEMPLATE'|translate}}</span>
            <span *ngIf="reservation.status==='init' && reservation.waitingList" class="init">{{'WAITING_CONFIRM'|translate}}</span>
            <span *ngIf="reservation.status==='linked'" class="linked">{{'LINKED'|translate}}</span>
            <span *ngIf="reservation.status==='issued'" class="issued">{{'ISSUED'|translate}}</span>
            <span *ngIf="reservation.status==='flown'" class="flown">{{'FLOWN'|translate}}</span>
            <span *ngIf="reservation.status==='expired'" class="expired">{{'EXPIRED'|translate}}</span>
            <span *ngIf="reservation.status==='cancelled'" class="cancelled">{{'CANCELLED'|translate}}</span>&ndash;&gt;

            &lt;!&ndash;<div *ngIf="reservation.locator" [ngClass]="{'marginbottom5':reservation.status==='linked'}" class="pnrlocator">
              <span>{{reservation.locator}}</span>
            </div>

            <div *ngIf="reservation.status==='issued' && reservation.chainLocators" class="mt-2">
              <span *ngFor="let locator of reservation.chainLocators">{{locator.chain}} {{locator.locator}}</span>
            </div>
            <div *ngIf="reservation.cancelledAt" class="mt-2 text-danger">
              {{reservation.cancelledAt|date: 'dd/MM/yyyy : HH:mm'}}
            </div>

            <div *ngIf="reservation.status=='issued'" class="mt-2 smalltext">
              {{'ISSUED_ON'|translate}} {{reservation.issuedOn|date:'dd/MM/yyyy'}}
            </div>

            <div *ngIf="reservation.changed" class="variationpnr">
              <mat-icon>warning</mat-icon>
              {{'CHANGE_PNR'|translate}}
            </div>&ndash;&gt;
          </div>-->

        </div>
        <div class="col-md-4 align-self-center companydetails">
          <div><a routerLink="/companies/list/{{reservation.company._id}}">{{reservation.company.name}}</a></div>
          <div>
            <mat-icon>subdirectory_arrow_right</mat-icon>
            <a routerLink="/users/list/{{reservation.user._id}}">{{reservation.user.surname}} {{reservation.user.name}}</a>
          </div>
          <hr/>
          <div *ngIf="reservation.status=='issued' && reservation.costCenter">
            {{'COST_CENTER'|translate}}: {{reservation.costCenter}}
          </div>
          <div class="costCenterInputing pnrActionsButtons" *ngIf="(this.reservation.status!='cancelled' && reservation.status!='issued') && costCenter">
            <div class="costTilte mb-2">{{'COST_CENTER_TEXT'|translate}}</div>
            <mat-form-field class="mr-2">
              <mat-label>{{'COST_CENTER'|translate}}</mat-label>
              <mat-select [(ngModel)]="reservation.costCenter">
                <mat-option [value]="null" style="color:#b01818;">
                  {{'NONE'|translate}}
                </mat-option>
                <mat-option (click)="openCostCenterDialog()" style="color:#4d79f6;">+ {{'ADD_COST_CENTER'|translate}}</mat-option>
                <mat-option *ngFor="let cost of costCenter" [value]="cost.description">
                  {{cost.tag}} -> {{cost.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="updateCostCenter()" [disabled]="updating || loadingCenters">{{'UPDATE'|translate}}</button>
            <mat-progress-bar mode="buffer" *ngIf="updating || loadingCenters"></mat-progress-bar>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-md-4 align-self-center mb-2">
          <div class="travelinfo">
            <div>

              <div class="pnrdeparture">
                Check-in: {{reservation.hotel.checkIn|date:'dd/MM/yyyy'}}<br/>
                Check-out: {{reservation.hotel.checkOut|date:'dd/MM/yyyy'}}
              </div>
              <span class="locationspan">
                Location:
                <span *ngIf="!reservation.hotel.address">{{reservation.hotel.city}}</span>
                <span *ngIf="reservation.hotel.address">{{reservation.hotel.address.city}}, {{reservation.hotel.address.line}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center">
          <div *ngIf="reservation.guests.length>0">
            <div *ngFor="let passenger of reservation.guests" class="listpnrsinglepasseng">
              <span *ngIf="passenger.surname">{{passenger.surname}}</span>
              <span *ngIf="passenger.name"> {{passenger.name}}</span>
              <sup> ({{passenger.type}})</sup>
            </div>
          </div>
        </div>
        <div class="col-md-4 align-self-center pnrActionsButtons">

          <!--<button *ngIf="reservation.paper" [matMenuTriggerFor]="dossierMenu" mat-icon-button matTooltip="{{'DOSSIER'|translate}}">
            <mat-icon>folder</mat-icon>
          </button>
          <mat-menu #dossierMenu="matMenu">
            <button mat-menu-item routerLink="/dossier/list/{{reservation.paper}}">
              {{'GO_TO_DOSSIER'|translate}}
            </button>
            <button (click)="toggleRemoveDossierModal()" mat-menu-item>
              {{'REMOVE_FROM_DOSSIER'|translate}}
            </button>
          </mat-menu>
          <button (click)="toggleAddtoTravel()" *ngIf="!reservation.paper" mat-icon-button matTooltip="{{'ADD_TO_FOLDER'|translate}}">
            <mat-icon>create_new_folder</mat-icon>
          </button>
          <button (click)="togglePrintHotel()" *ngIf="!reservation.changed && reservation.status!='init' && reservation.status!='cancelled'" mat-icon-button matTooltip="{{'PRINT'|translate}}">
            <mat-icon>print</mat-icon>
          </button>

          <span *ngIf="!isFavorite(reservation.hotel.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)" class="material-icons pointer" style="color: red;" (click)="addToFavorites(reservation.hotel)">
            favorite_border
          </span>
          <span *ngIf="isFavorite(reservation.hotel.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)" class="material-icons pointer" style="color: red;" (click)="removeToFavorites(reservation.hotel)">
            favorite
          </span>-->

          <!-- <button mat-raised-button *ngIf="reservation.status==='init' && !reservation.waitingList" color="primary" (click)="askBook()">{{'REQUEST_EMISSION'|translate}}</button> -->
          <button  (click)="toggleBookModal()" *ngIf="reservation.status==='init' && !reservation.waitingList && checkPolicy()"
                   [disabled]="!mainComponent.loggedUser.role.hotel.taw.post.options.issue || !reservation.creditcard || (!accepted && reservation.hotel.provider === 'XD')"
                   color="primary" mat-raised-button>
            {{'BOOK'|translate}}
          </button>

          <button *ngIf="reservation.approvalId && !isToNotify() && reservation.status === 'init' && !checkPolicy() && (search && search.status == 'approved') && transferApproved()"
                  mat-raised-button color="primary" (click)="toggleBookModal()"
                  [disabled]="!mainComponent.loggedUser.role.hotel.taw.post.options.issue || !reservation.creditcard || (!accepted && reservation.hotel.provider === 'XD')">
            {{'BUY_RESERVATION'|translate}}
            <span class="material-icons"> arrow_right_alt </span>
          </button>

          <div class="col-12">
            <mat-checkbox [(ngModel)]="accepted" *ngIf="reservation.hotel.provider === 'XD' && reservation.status==='init'">
              {{'TAKEN_VISION'| translate}} <a href="https://developer.expediapartnersolutions.com/terms/en" target = "_blank">{{'TERMS_AND_CONDITIONS'| translate}}</a>
            </mat-checkbox>
          </div>

          <button (click)="toggleBookModal()" *ngIf="mainComponent.loggedUser.role.superAdmin && reservation.status==='init' && reservation.waitingList && checkPolicy()"
                  [disabled]="!mainComponent.loggedUser.role.hotel.taw.post.options.issue || !reservation.creditcard" color="primary" mat-raised-button>
            {{'BOOK'|translate}}
          </button>
          <p *ngIf="reservation.status==='init' && reservation.waitingList">In attesa di conferma</p>
          <p *ngIf="reservation.status==='init' && reservation.waitingList" style="font-size:12px;">
            Alla conferma riceverai via mail la prenotazione o sarai contattato in caso di problemi
          </p>

          <button *ngIf="!reservation.waitingList && reservation.status === 'init' && !checkPolicy() && !isToNotify() && !reservation.approvalId" mat-raised-button
                  color="primary" (click)="requestAuthorization()">
            {{'REQUEST_AUTHORIZATION'|translate}}
            <span class="material-icons"> arrow_right_alt </span>
          </button>
          <div class="d-flex align-self-center mt-3">
            <button *ngIf="!reservation.waitingList && reservation.status === 'init' && !checkPolicy() && isToNotify()" mat-raised-button
                    color="primary" (click)="toggleBookModal()" matTooltip="{{'POLICY.WILL_NOTIFY'|translate}}" [matTooltipPosition]="'above'"
                    [disabled]="!mainComponent.loggedUser.role.hotel.taw.post.options.issue || !reservation.creditcard || (!accepted && reservation.hotel.provider === 'XD')">
              {{'POLICY.BUY_AND_NOTIFY'|translate}}
              <span class="material-icons"> arrow_right_alt </span>
            </button>
            <div class="pt-2 mb-1 smalltext text-danger" *ngIf="!transferApproved()">Trasferta non ancora autorizzata</div>
            <div class="smalltext" *ngIf="reservation.approvalId && reservation.status === 'init' && !checkPolicy() && search">
              <div class="mb-1">
                Stato approvazione servizio:
                <span class="text-warning" *ngIf="search.status === 'pending'">{{search.status|uppercase|translate}}</span>
                <span class="text-success" *ngIf="search.status === 'approved'">{{search.status|uppercase|translate}}</span>
                <span class="text-danger" *ngIf="search.status === 'declined'">{{search.status|uppercase|translate}}</span>
              </div>

              <div *ngIf="search.status === 'declined'">
                <div *ngFor="let auth of search.userAuthorisations">
                  <ng-container *ngIf="auth.status === 'declined'">
                    <div>
                      Ragione: <span class="text-danger">{{auth.note}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <app-policy-alerts-icon *ngIf="reservation.room.arrayOfAlerts && reservation.room.arrayOfAlerts.length > 0" [arrayOfAlerts]="reservation.room.arrayOfAlerts"></app-policy-alerts-icon>
          </div>

          <button mat-icon-button color="warn" (click)="toggleDeleteModal()" *ngIf="reservation.status=='linked' || reservation.status=='issued'"
                  [disabled]="!mainComponent.loggedUser.role.hotel.taw.delete.options.issued" matTooltip="{{'DELETE_RESERVATION'|translate}}">
            <mat-icon>delete_outline</mat-icon>
          </button>
          <!--<button (click)="toggleDeleteModal()" *ngIf="reservation.status=='linked' || reservation.status=='issued'" [disabled]="!mainComponent.loggedUser.role.hotel.taw.delete.options.issued" color="warn"
                  mat-raised-button>
            {{'DELETE_RESERVATION'|translate}}
            <span class="material-icons">
              delete_outline
            </span>
          </button>-->
          <p *ngIf="reservation.errorStripe && reservation.status=='issued'" class="text-danger mt-2" style="font-size:12px;">
            <span class="material-icons">warning</span> {{'PAYMENT_ERROR'|translate}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- end card -->


  <!-- start tabs -->

  <!--<div *ngIf="!loading && reservation" class="pnrPagination">
    <div (click)="setTab(i)" *ngFor="let tab of tabs; let i = index" [ngClass]="{'active':tab.active}" class="singleVoice">
      <mat-icon *ngIf="tab.name==='HOTEL_INFOS'">info</mat-icon>
      <mat-icon *ngIf="tab.name==='GUESTS'">directions_walk</mat-icon>
      <mat-icon *ngIf="tab.name==='TARIFFES'">hotel</mat-icon>
      <mat-icon *ngIf="tab.name==='PAYMENT'">credit_card</mat-icon>
      <mat-icon *ngIf="tab.name==='PNR'">format_list_numbered</mat-icon>

      <span class="tabname"> {{tab.name|translate}}</span>
    </div>
  </div>-->


  <div class="newPnrPagination" *ngIf="!loading && reservation">

    <div (click)="setTab(i)" class="singleNewVoice" [ngClass]="{'activeNew':tab.active}" *ngFor="let tab of tabs; let i = index">
      {{tab.name|translate}}
    </div>
  </div>


  <!-- end tabs -->

  <!-- start currentTabs -->

  <div class="newTabHeader" *ngIf="!loading && reservation && hotelsInfos">
    <div class="row">
      <div class="col-6 align-self-center">
        {{currentTab | translate}}
      </div>
      <div class="col-6 align-self-center d-flex justify-content-end">
        <button *ngIf="reservation.paper" [matMenuTriggerFor]="dossierMenu" mat-icon-button matTooltip="{{'DOSSIER'|translate}}">
          <mat-icon class="d-flex vertical">folder</mat-icon>
        </button>
        <mat-menu #dossierMenu="matMenu">
          <button mat-menu-item routerLink="/dossier/list/{{reservation.paper}}">
            {{'GO_TO_DOSSIER'|translate}}
          </button>
          <button (click)="toggleRemoveDossierModal()" mat-menu-item>
            {{'REMOVE_FROM_DOSSIER'|translate}}
          </button>
        </mat-menu>
        <button (click)="toggleAddtoTravel()" *ngIf="!reservation.paper" mat-icon-button matTooltip="{{'ADD_TO_FOLDER'|translate}}">
          <mat-icon class="d-flex vertical">create_new_folder</mat-icon>
        </button>
        <button (click)="togglePrintHotel()" *ngIf="!reservation.changed && reservation.status!='init' && reservation.status!='cancelled'"
                mat-icon-button matTooltip="{{'PRINT'|translate}}">
          <mat-icon>print</mat-icon>
        </button>

        <div class="d-flex align-self-center">
          <span *ngIf="!isFavorite(reservation.hotel.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)"
                class="material-icons pointer" style="color: red;" (click)="addToFavorites(reservation.hotel)">
            favorite_border
          </span>
          <span *ngIf="isFavorite(reservation.hotel.code) && (this.mainComponent.loggedUser.role.company || this.mainComponent.loggedUser.role.superAdmin)"
                class="material-icons pointer" style="color: red;" (click)="removeToFavorites(reservation.hotel)">
            favorite
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && reservation && hotelsInfos" class="card marginbottom20 padding10">

    <div [hidden]="currentTab!='HOTEL_INFOS'">
      <!--{{'HOTEL_INFOS'|translate}}
      <hr/>-->
      <div class="row mb-3">
        <div class="align-self-center col-lg-6">
          <div *ngIf="hotelsInfos" class="carousel slide slideHotel" data-interval="false" data-ride="carousel" id="carousel">

            <div *ngIf="hotelsInfos.info.images.length === 0" class="carousel-inner">
              <div class="carousel-item active">
                <img alt="First slide" class="d-block" src="/assets/img/no-image.jpeg">
                <div class="carousel-caption d-none d-md-block">
                  {{'NO_IMAGES_AVAILABLE'|translate}}
                </div>
              </div>
            </div>
            <!--
            <div *ngIf="hotelsInfos.info.images.length>0" class="carousel-inner">
              <div *ngFor="let image of hotelsInfos.info.images; let im = index" [ngClass]="{'active':im === 0}" class="carousel-item">
                <img alt="{{im+1}} slide" class="d-block" src="{{image.list[5].url}}">
                <div class="carousel-caption d-none d-md-block">
                  {{im + 1}}/{{hotelsInfos.info.images.length}}
                </div>
              </div>
            </div>
            <a *ngIf="hotelsInfos.info.images.length>1" class="carousel-control-prev" data-slide="prev" href="#carousel" role="button">
              <span aria-hidden="true" class="carousel-control-prev-icon"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a *ngIf="hotelsInfos.info.images.length>1" class="carousel-control-next" data-slide="next" href="#carousel" role="button">
              <span aria-hidden="true" class="carousel-control-next-icon"></span>
              <span class="sr-only">Next</span>
            </a> -->
            <div *ngIf="hotelsInfos.info.images.length > 0 && images">
              <gallery gallerize [items]="images"></gallery>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mb-3">
          <div id="hotelMap">

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-12 mb-3">
          <div class="mb-2">
            <h4>{{'SERVICES'|translate}}</h4>
            <div class="hotelServices">
              <span *ngFor="let service of hotelsInfos.info.services; let l = last">
                {{service}} <span *ngIf="!l"> - </span>
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 mb-2">
          <div class="">
            <h4>{{'GENERAL_INFORMATIONS'|translate}}</h4>
            <div *ngFor="let description of hotelsInfos.info.descriptions; let l = last" class="hotelGenInfos">
              <div [innerHTML]="description.text"></div>
              <hr *ngIf="!l"/>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='TARIFFES'">
      <!--{{'TARIFFES'|translate}}
      <hr/>-->
      <div class="singleRoomDetails">
        <div class="row">
          <div class="col-md-1 align-self-center">
            <span class="material-icons"> bed </span>
          </div>

          <div class="col-md-4 align-self-center">
            <div *ngFor="let rate of reservation.room.roomRates" class="mb-2">

              <div class="d-block">
                <span>{{reservation.hotel.provider}} </span>
                <span *ngIf="isCorporate(reservation.room.ratePlans[0])"> - <b class="greentxt"> {{'CORPORATE'|translate}}</b></span>

              </div>

              <div class="rateText">{{rate.text}}</div>
            </div>
          </div>

          <div class="col-md-4 align-self-center">
            <div class="midCol">
              <div *ngIf="!isFreeCancellationBis()" class="">
                <div *ngFor="let plan of reservation.room.ratePlans">

                  <div *ngFor="let penal of plan.penalty; let l = last" class="penalty">
                    <!-- <b *ngIf="!penal.nonRefundable">{{penal.type}}</b>:
                    <span *ngIf="penal.nonRefundable==false">{{'REFUNDABLE_FARE'|translate}} {{'UNTIL'|translate}} </span>
                    <span *ngIf="!penal.absoluteDeadLine">{{room.startDate|date:'dd/MM/yyyy - HH:mm'}}</span>
                    <span *ngIf="penal.absoluteDeadLine">{{penal.absoluteDeadLine|date:'dd/MM/yyyy - HH:mm'}} </span> -->
                    <div class="row mb-2">
                      <div class="col-2 text-center align-self-center">
                        <span class="material-icons greytxt"> highlight_off </span>
                      </div>
                      <div class="col-10 align-self-center">
                        {{'NO_REFOUND'|translate}}
                        <br/>
                        <span *ngIf="penal.amount && !l">{{'CANCELLATION'|translate}} {{penal.amount|number:'.2-2'}}€</span>
                        <span *ngIf="penal.amount && l">{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                        <span *ngIf="!penal.amount">{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf="!reservation.room.ratePlans[0]" class="penalty">
                  <div class="row mb-2">
                    <div class="col-2 text-center align-self-center">
                      <span class="material-icons greytxt"> highlight_off </span>
                    </div>
                    <div class="col-10 align-self-center">
                      {{'NO_REFOUND'|translate}}
                      <br/>
                      <span>{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                    </div>
                  </div>
                </div>

                <div *ngIf="!reservation.room.ratePlans[0].penalty[0]" class="penalty">
                  <div class="row mb-2">
                    <div class="col-2 text-center align-self-center">
                      <span class="material-icons greytxt"> highlight_off </span>
                    </div>
                    <div class="col-10 align-self-center">
                      {{'NO_REFOUND'|translate}}<br/>
                      <span>{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isFreeCancellationBis()" class="">
                <div *ngFor="let plan of reservation.room.ratePlans">
                  <div *ngFor="let penal of plan.penalty;let l = last" class="penalty">
                    <div class="row mb-2">
                      <div class="col-2 text-center align-self-center">
                        <span class="material-icons greentxt"> task_alt </span>
                      </div>
                      <div class="col-10 align-self-center">
                        {{'YES_REFOUND'|translate}}<br/> {{'TILL'|translate}}
                        <span *ngIf="penal.absoluteDeadLine"> {{removeHr(penal.absoluteDeadLine)|date:'dd/MM/yyyy - HH:mm'}} </span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-2 text-center align-self-center">
                        <span class="material-icons"> arrow_right_alt </span>
                      </div>
                      <div class="col-10 align-self-center">
                        {{'CANCELLATION'|translate}} {{'DAL'|translate}}
                        <span *ngIf="penal.absoluteDeadLine"> {{penal.absoluteDeadLine|date:'dd/MM/yyyy'}} </span><br/>
                        <span *ngIf="penal.amount && !l"><b> {{penal.amount|number:'.2-2'}}€</b></span>
                        <span *ngIf="penal.amount && l"><b> {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</b></span>
                        <span *ngIf="!penal.amount"><b>{{reservation.room.roomRates[0].amount|number:'.2-2'}}€</b></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-2 text-center align-self-center">
                  <span class="material-icons"> local_dining </span>
                </div>
                <div class="col-10 align-self-center" style="font-size:12px;">
                  {{reservation.room.ratePlans[0].mealPlan}}
                </div>
              </div>
            </div>

          </div>

          <div class="col-md-3 align-self-center">
            <div *ngFor="let rate of reservation.room.roomRates, let ind = index;" class="mb-2">
              <!-- <div class="roomPrice">{{rate.amount|number:'.2-2'}}€</div> -->
              <div>
                <div class="buttonrow">
                  <p *ngIf="mainComponent.loggedUser.role.superAdmin &&  rate.amountBeforeTaxes > 0" class="mb-1 smalltext"> Net:
                    <span>{{rate.amountBeforeTaxes|number:'.2-2'}} €</span>
                  </p>
                  <p class="mb-1 smalltext">
                    {{numberOfNights}}
                    <span *ngIf="numberOfNights == 1">{{'NIGHT'|translate}}</span>
                    <span *ngIf="numberOfNights > 1">{{'NIGHTS'|translate}}</span>
                  </p>
                  <p>{{rate.amount|number:'.2-2'}}€ </p>
                </div>
              </div>
            </div>

          </div>

          <div *ngIf="reservation.room.Infos" class="col-12">
            <hr *ngIf="reservation.room.Infos.length > 0"/>
            <!-- <p>{{'AMENITIES'|translate}}</p> -->

            <div *ngFor="let info of reservation.room.Infos;let l = last;let i = index">
              <div>
                <span *ngIf="info.name">{{info.name}} - </span>
                <span *ngIf="info.maxOccupancy">Max-occupancy {{info?.maxOccupancy}}</span>
                <span *ngIf="info.maxChild"> - Max-child {{info?.maxChild}}</span>
              </div>
              <span *ngFor="let amenity of info.amenities;let a = index" class="amenity">
                <span class="material-icons">done</span> {{amenity.type}}
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='GUESTS'">
      <!--{{'GUESTS'|translate}}
      <hr/>-->
      <div>
        <div class="row buttonrow">
          <div *ngFor="let tra of reservation.guests;let i = index" class="col-lg-6 mb-3">
            <p>{{tra.type|translate}} {{i + 1}}</p>
            <div class="fakefield mb-2">
              {{'NAME'|translate}}:
              <span *ngIf="tra.name"> {{tra.name}}</span>
              <span *ngIf="!tra.name"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'SURNAME'|translate}}:
              <span *ngIf="tra.surname"> {{tra.surname}}</span>
              <span *ngIf="!tra.surname"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'PHONE'|translate}}:
              <span *ngIf="tra.phoneNumber"> {{tra.phoneNumber}}</span>
              <span *ngIf="!tra.phoneNumber"> _ _ _ _</span>
            </div>
            <div class="fakefield mb-2">
              {{'EMAIL'|translate}}:
              <span *ngIf="tra.email"> {{tra.email}}</span>
              <span *ngIf="!tra.email"> _ _ _ _</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='PAYMENT'">
      <!--{{'PAYMENT'|translate}}
      <hr/>-->
      <mat-tab-group (selectedTabChange)="onPaymentTabChanged($event);" mat-align-tabs="start" mat-stretch-tabs="false">
        <mat-tab *ngIf="reservation.company.services.paymentMethods" label="{{'FOPS'|translate}}">
          <br/>
          <app-methods [id]="reservation.company._id" [resType]="'hotel'" [reservation]="reservation"
                       [corporate]="isCorporate(reservation.room.ratePlans[0])">
          </app-methods>
        </mat-tab>
        <mat-tab *ngIf="reservation.company.services.creditCards && !appComponent.isB2B" label="{{'CREDIT_CARDS'|translate}}">
          <br/>
          <app-fops [id]="reservation.company._id" [reservation]="reservation"></app-fops>
        </mat-tab>
      </mat-tab-group>

    </div>

    <div *ngIf="currentTab=='PNR'">
      <!--{{'PNR'|translate}}
      <hr/>-->
      <app-hoteltst [reservationId]="reservation._id"></app-hoteltst>
    </div>
  </div>

  <div aria-hidden="true" aria-labelledby="deletePnrModal" class="modal fade" data-backdrop="static" id="deletePnrModal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" style="top:70px">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deletePnrModalTitle">{{'DELETE_RESERVATION'|translate}} ?</h5>
          <button (click)="toggleDeleteModal()" *ngIf="!deleting" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="reservation" class="modal-body p-2">
          <div class="mb-3">{{'ISSUED_ON'|translate}} {{reservation.issuedOn|date:'dd/MM/yyyy'}}</div>
          <div class="mb-3">{{'DELETE_RESERVATION_QUESTION'|translate}}</div>
          <!-- <div>{{'DELETE_RESERVATION_TEXT'|translate}}</div> -->
          <div>
            <div *ngIf="!isFreeCancellationBis()" class="">
              <div *ngFor="let plan of reservation.room.ratePlans">

                <div *ngFor="let penal of plan.penalty; let l = last" class="penalty">
                  <!-- <b *ngIf="!penal.nonRefundable">{{penal.type}}</b>:
                  <span *ngIf="penal.nonRefundable==false">{{'REFUNDABLE_FARE'|translate}} {{'UNTIL'|translate}} </span>
                  <span *ngIf="!penal.absoluteDeadLine">{{room.startDate|date:'dd/MM/yyyy - HH:mm'}}</span>
                  <span *ngIf="penal.absoluteDeadLine">{{penal.absoluteDeadLine|date:'dd/MM/yyyy - HH:mm'}} </span> -->
                  <div class="row mb-2">
                    <div class="col-2 text-center align-self-center">
                      <span class="material-icons greytxt"> highlight_off </span>
                    </div>
                    <div class="col-10 align-self-center">
                      {{'NO_REFOUND'|translate}}
                      <br/>
                      <span *ngIf="penal.amount && !l">{{'CANCELLATION'|translate}} {{penal.amount|number:'.2-2'}}€</span>
                      <span *ngIf="penal.amount && l">{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                      <span *ngIf="!penal.amount">{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="!reservation.room.ratePlans[0]" class="penalty">
                <div class="row mb-2">
                  <div class="col-2 text-center align-self-center">
                    <span class="material-icons greytxt"> highlight_off </span>
                  </div>
                  <div class="col-10 align-self-center">
                    {{'NO_REFOUND'|translate}}
                    <br/>
                    <span>{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                  </div>
                </div>
              </div>

              <div *ngIf="!reservation.room.ratePlans[0].penalty[0]" class="penalty">
                <div class="row mb-2">
                  <div class="col-2 text-center align-self-center">
                    <span class="material-icons greytxt"> highlight_off </span>
                  </div>
                  <div class="col-10 align-self-center">
                    {{'NO_REFOUND'|translate}}<br/>
                    <span>{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isFreeCancellationBis()" class="">
              <div *ngFor="let plan of reservation.room.ratePlans">
                <div *ngFor="let penal of plan.penalty; let l = last" class="penalty">
                  <div class="row mb-2">
                    <div class="col-2 text-center align-self-center">
                      <span class="material-icons greentxt"> task_alt </span>
                    </div>
                    <div class="col-10 align-self-center">
                      {{'YES_REFOUND'|translate}}<br/> {{'TILL'|translate}}
                      <span *ngIf="penal.absoluteDeadLine"> {{removeHr(penal.absoluteDeadLine)|date:'dd/MM/yyyy - HH:mm'}} </span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2 text-center align-self-center">
                      <span class="material-icons"> arrow_right_alt </span>
                    </div>
                    <div class="col-10 align-self-center">
                      {{'CANCELLATION'|translate}} {{'DAL'|translate}}
                      <span *ngIf="penal.absoluteDeadLine"> {{penal.absoluteDeadLine|date:'dd/MM/yyyy'}} </span><br/>
                      <span *ngIf="penal.amount && !l"><b> {{penal.amount|number:'.2-2'}}€</b></span>
                      <span *ngIf="penal.amount && l"><b> {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</b></span>
                      <span *ngIf="!penal.amount"><b>{{reservation.room.roomRates[0].amount|number:'.2-2'}}€</b></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <div *ngIf="!deleting" class="text-right p-2 buttonrowBis">
          <button (click)="toggleDeleteModal()" mat-button type="button">{{'ABORT'|translate}}</button>
          <button (click)="cancelReservation()" mat-button type="button">{{'DELETE_RESERVATION'|translate}}</button>
        </div>
        <div *ngIf="deleting" class="text-right p-2">
          <div class="mb-2">{{'LOADING'|translate}}</div>
          <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
        </div>

      </div>
    </div>
  </div>

  <!-- start RemoveDossierModal -->

  <div *ngIf="reservation && reservation.paper" aria-hidden="true" aria-labelledby="RemoveDossierModal" class="modal fade" data-backdrop="static"
       id="RemoveDossierModal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" style="top:70px">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="RemoveDossierModalTitle">{{'REMOVE_FROM_DOSSIER'|translate}} ?</h5>
          <button (click)="toggleRemoveDossierModal()" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <div class="mb-3">{{'CONFIRM_REMOVE_DOSSIER'|translate}}</div>
        </div>

        <hr/>

        <div class="text-right p-2 buttonrowBis">
          <button (click)="toggleRemoveDossierModal()" mat-button type="button">{{'ABORT'|translate}}</button>
          <button (click)="confirmDossierRemove()" mat-button type="button">{{'CONFIRM'|translate}}</button>
        </div>

      </div>
    </div>
  </div>

  <!-- start RemoveDossierModal -->

  <!-- start travelListModal -->

  <div aria-hidden="true" aria-labelledby="travelListModal" class="modal fade" data-backdrop="static" id="travelListModal" role="dialog" tabindex="-1">
    <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addGuestModalTitle">
            {{'ADD_TO_FOLDER'|translate}}
          </h5>
          <button (click)="toggleAddtoTravel()" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <app-dossierlist (close)="closeAddtoTravel()" [idHotel]="hotelId" page="hotel"></app-dossierlist>
        </div>

        <hr/>

        <div class="text-right p-2 buttonrowBis">
          <button (click)="toggleAddtoTravel()" mat-button type="button">{{'CLOSE'|translate}}</button>
        </div>

      </div>
    </div>
  </div>

  <!-- end travelListModal -->

  <div *ngIf="reservation && mainComponent.loggedUser.role.superAdmin" class="row buttonrowBis">

    <!-- start change price component -->

    <div class="col-md-12 mb-3 reservationChanges">
      <app-admin-reservation-changes (update)="getHotel(hotelId)" [reservation]="reservation" [type]="'hotel'"></app-admin-reservation-changes>
    </div>

    <!-- end change price component -->

    <!-- start admin actions -->

    <div class="col-md-4">
      <mat-card appearance="outlined">
        <mat-card-header>
          <div class="example-header-image" mat-card-avatar></div>
          <mat-card-title>{{'ADMIN_ACTIONS'|translate}}</mat-card-title>
          <mat-card-subtitle>{{mainComponent.loggedUser.surname}} {{mainComponent.loggedUser.name}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

          <mat-form-field class="full-width">
            <input (ngModelChange)="searchUser($event)" [(ngModel)]="text" [matAutocomplete]="users" aria-label="Carrier"
                   matInput placeholder="{{'ASSIGN'|translate}}">
            <button (click)="clear()" aria-label="Clear" mat-icon-button matSuffix type="button">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #users="matAutocomplete" (closed)="selectFirst()" (optionSelected)="getUser($event.option.value)">
              <mat-option *ngFor="let user of userList" [value]="user">
                <span>{{user.name}} {{user.surname}}</span>
                <span class="material-icons"> arrow_right_alt </span>
                <small>{{user.company.name}}</small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>LOCATOR</mat-label>
            <input [(ngModel)]="locator" matInput type="text">
            <button (click)="locator=''" *ngIf="locator" aria-label="Clear" mat-icon-button matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Tassa di soggiorno</mat-label>
            <input [(ngModel)]="taxHotel" matInput type="number">
            <button (click)="taxHotel=0" *ngIf="taxHotel" aria-label="Clear" mat-icon-button matSuffix>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>STATUS</mat-label>
            <mat-select [(ngModel)]="status">
              <mat-option value="init"> Non finalizzato </mat-option>
              <mat-option value="linked"> Linked </mat-option>
              <mat-option value="issued"> Emesso </mat-option>
              <mat-option value="cancelled"> Cancellato </mat-option>
            </mat-select>
          </mat-form-field>

          <button (click)="updateReservation()" color="primary" mat-stroked-button>Update</button>
          <button (click)="updateDossier()" *ngIf="reservation.paper" color="warn" mat-stroked-button>Update dossier</button>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- end admin actions -->
  </div>

  <!-- start reprice modal -->

  <div aria-hidden="true" aria-labelledby="repricePnrModal" class="modal fade" data-backdrop="static" id="repricePnrModal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" style="top:70px">

      <div *ngIf="reservation" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="repricePnrModalTitle">{{'CONFIRM_BUY_RESERVATION'|translate}} ?</h5>
          <button (click)="toggleBookModal()" *ngIf="!booking" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body p-2" *ngIf="!priceConfirmed">

          <div class="p-2">
            <div class="mb-2">La camera che hai selezionato non è più disponibile.</div>
            <div>Quotare nuovamente questa struttura?</div>
          </div>
        </div>

        <hr/>

        <div *ngIf="!booking && reservation && !reservation.lowCost && !reservation.approvalId" class="text-right buttonrowBis p-2">
          <button (click)="toggleBookModal()" mat-button type="button">{{'ABORT'|translate}}</button>
          <button *ngIf="priceConfirmed && !isToNotify()" (click)="quotePnr()" mat-button type="button">
            <span>{{'BUY_RESERVATION'|translate}}</span>
          </button>
          <button *ngIf="priceConfirmed && isToNotify()" (click)="bookAndNotify()" mat-button type="button">
            <span>{{'POLICY.BUY_AND_NOTIFY'|translate}}</span>
          </button>
          <button *ngIf="!priceConfirmed" (click)="newQuote()" mat-button type="button">
            <span>{{'QUOTE_HOTEL'|translate}}</span>
          </button>
        </div>
        <div *ngIf="booking" class="text-right p-2">
          <div class="mb-2">{{'LOADING'|translate}}</div>
          <mat-progress-bar mode="indeterminate" style="max-width: 200px;display:inline-block;"></mat-progress-bar>
        </div>

      </div>
    </div>
  </div>

  <!-- end reprice modal -->

  <!-- start print modal -->

  <div *ngIf="reservation" aria-hidden="true" aria-labelledby="PrintModalLabel" class="modal fade" data-backdrop="static" id="PrintModalHotel" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'PRINT'|translate}}</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">

          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
              <button (click)="print()" mat-button> {{'PRINT'|translate}} <mat-icon>print</mat-icon> </button>
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-12">
              Preview
              <div class="padding20" style="overflow-x:auto">
                <div *ngIf="reservation" id="toPrint">
                  <div class="row">
                    <div class="col-4">
                      <img [src]="reservation.company.image" class="companyImage"/>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-4">
                      <div class="mb-1">
                        <mat-icon class="mr-2">store</mat-icon> {{reservation.company.name}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">call</mat-icon> {{reservation.company.phoneNumber}}
                      </div>
                      <div class="mb-1">
                        <mat-icon class="mr-2">forward_to_inbox</mat-icon> {{reservation.company.email}}
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <h5 class="mb-3">{{'Hotel'|translate}}: {{reservation.hotel.name}}</h5>
                  <hr/>

                  <!-- Hotel Reservation Specs -->

                  <div class="row">
                    <div class="col-4">
                      <div>
                        <div class="pnrdeparture">
                          Check-in: {{reservation.hotel.checkIn|date:'dd/MM/yyyy'}}<br/>
                          Check-out: {{reservation.hotel.checkOut|date:'dd/MM/yyyy'}}
                        </div>
                        <span class="locationspan">
                          Location:
                          <span *ngIf="!reservation.hotel.address">{{reservation.hotel.city}}</span>
                          <span *ngIf="reservation.hotel.address">{{reservation.hotel.address.city}}, {{reservation.hotel.address.line}}</span>
                        </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div *ngIf="reservation.guests.length>0">
                        <div *ngFor="let passenger of reservation.guests" class="listpnrsinglepasseng">
                          <span *ngIf="passenger.surname">{{passenger.surname}}</span>
                          <span *ngIf="passenger.name"> {{passenger.name}}</span>
                          <sup> ({{passenger.type}})</sup>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="reservationPrice marginbottom5">
                        <span class="recoprice">
                          {{reservation.room.roomRates[0].amount|number:'.2-2'}}€
                        </span>
                      </div>
                      <div *ngIf="mainComponent.loggedUser.role.superAdmin &&  reservation.room.roomRates[0].amountBeforeTaxes > 0" class="mb-1 smalltext">
                        Net: <span>{{reservation.room.roomRates[0].amountBeforeTaxes|number:'.2-2'}} €</span>
                      </div>
                      <div class="smalltext">
                        {{'HOTEL_STAY'|translate}}: {{numberOfNights}} <span *ngIf="numberOfNights == 1">{{'NIGHT'|translate}}</span>
                        <span *ngIf="numberOfNights > 1">{{'NIGHTS'|translate}}</span>
                      </div>
                      <div class="smalltext text-danger" *ngIf="reservation.fare && reservation.fare.manual">
                        Il prezzo è cambiato: {{reservation.fare.manual.amount|number:'.2-2'}}€
                      </div>
                    </div>
                  </div>
                  <hr/>

                  <!-- Hotel Services -->

                  <div *ngIf="hotelsInfos" class="mb-2">
                    <h5>{{'SERVICES'|translate}}</h5>
                    <div class="hotelServices">
                      <span *ngFor="let service of hotelsInfos.info.services; let l = last">
                        {{service}} <span *ngIf="!l"> - </span>
                      </span>
                    </div>
                  </div>
                  <hr/>

                  <!-- Hotel Tariff -->

                  <div class="">
                    <h5>{{'TARIFFES'|translate}}</h5>
                    <div class="mb-2">
                      {{reservation.room.roomRates[0].name}}
                    </div>
                    <div>
                      {{reservation.room.roomRates[0].text}}
                    </div>
                  </div>

                  <hr/>

                  <div>
                    <div class="midCol">

                      <!-- Cancel Reservation -->

                      <div class="row">
                        <div class="col-6">
                          <h5>{{'CANCELLATION'|translate}}</h5>
                          <hr/>
                          <div *ngIf="!isFreeCancellationBis()" class="">
                            <div *ngFor="let plan of reservation.room.ratePlans">

                              <div *ngFor="let penal of plan.penalty;let l = last" class="penalty">
                                <div class="row mb-2">
                                  <div class="col-2 text-center align-self-center">
                                    <span class="material-icons greytxt"> highlight_off </span>
                                  </div>
                                  <div class="col-10 align-self-center">
                                    {{'NO_REFOUND'|translate}}
                                    <br/>
                                    <span *ngIf="penal.amount && !l">{{'CANCELLATION'|translate}} {{penal.amount|number:'.2-2'}}€</span>
                                    <span *ngIf="penal.amount && l">{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                                    <span *ngIf="!penal.amount">{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div *ngIf="!reservation.room.ratePlans[0]" class="penalty">
                              <div class="row mb-2">
                                <div class="col-2 text-center align-self-center">
                                  <span class="material-icons greytxt"> highlight_off </span>
                                </div>
                                <div class="col-10 align-self-center">
                                  {{'NO_REFOUND'|translate}}
                                  <br/>
                                  <span>{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                                </div>
                              </div>
                            </div>

                            <div *ngIf="!reservation.room.ratePlans[0].penalty[0]" class="penalty">
                              <div class="row mb-2">
                                <div class="col-2 text-center align-self-center">
                                  <span class="material-icons greytxt">
                                    highlight_off
                                  </span>
                                </div>
                                <div class="col-10 align-self-center">
                                  {{'NO_REFOUND'|translate}}<br/>
                                  <span>{{'CANCELLATION'|translate}} {{reservation.room.roomRates[0].amount|number:'.2-2'}}€</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="isFreeCancellationBis()" class="">
                            <div *ngFor="let plan of reservation.room.ratePlans">
                              <div *ngFor="let penal of plan.penalty;let l = last" class="penalty">
                                <div class="row mb-2">
                                  <div class="col-2 text-center align-self-center">
                                    <span class="material-icons greentxt"> task_alt </span>
                                  </div>
                                  <div class="col-10 align-self-center">
                                    {{'YES_REFOUND'|translate}}<br/> {{'TILL'|translate}}
                                    <span *ngIf="penal.absoluteDeadLine"> {{removeHr(penal.absoluteDeadLine)|date:'dd/MM/yyyy - HH:mm'}} </span>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-2 text-center align-self-center">
                                    <span class="material-icons"> arrow_right_alt </span>
                                  </div>
                                  <div class="col-10 align-self-center">
                                    {{'CANCELLATION'|translate}} {{'DAL'|translate}}
                                    <span *ngIf="penal.absoluteDeadLine"> {{penal.absoluteDeadLine|date:'dd/MM/yyyy'}} </span><br/>
                                    <span *ngIf="penal.amount && !l"><b> {{penal.amount|number:'.2-2'}}€</b></span>
                                    <span *ngIf="penal.amount && l"><b> {{reservation.room.roomRates[0].amount|number:'.2-2'}}€ </b></span>
                                    <span *ngIf="!penal.amount"><b>{{reservation.room.roomRates[0].amount|number:'.2-2'}}€</b></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <h5>{{'TYPE'|translate}}</h5>
                          <hr/>
                          <div class="row">
                            <div class="col-2 text-center align-self-center">
                              <span class="material-icons"> local_dining </span>
                            </div>
                            <div class="col-10 align-self-center" style="font-size:12px;">
                              {{reservation.room.ratePlans[0].mealPlan}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr/>

                  <!-- General Infos -->

                  <div *ngIf="hotelsInfos" class="">
                    <h4>{{'GENERAL_INFORMATIONS'|translate}}</h4>
                    <div *ngFor="let description of hotelsInfos.info.descriptions; let l = last" class="hotelGenInfos">
                      {{description.text}}
                      <hr *ngIf="!l"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end print modal -->

</div>
